import React from 'react';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import { format } from 'date-fns';
import { useFileUrl } from 'hooks/common';
import { getFullName } from 'utils/formatters';

import { LastLoginData } from 'interfaces/team';

export interface ItemProps {
  item: LastLoginData;
}

const LastLoginItem = ({ item }: ItemProps) => {
  const { id, loggedInAt, user } = item;
  const { fileUrl } = useFileUrl({ imgKey: user?.avatarKey });

  const loginAt = loggedInAt && format(new Date(loggedInAt), 'dd MMM yyyy');
  const userName = getFullName(user?.firstName, user?.lastName);
  return (
    <Item key={id}>
      <LastLoginLeft>
        <Avatar round name={userName} src={fileUrl} size="32" />
      </LastLoginLeft>

      <LastLoginRight>
        <LastLoginName>{userName}</LastLoginName>
        <LastLoginDate>{loginAt}</LastLoginDate>
      </LastLoginRight>
    </Item>
  );
};

const LastLoginLeft = styled.div`
  display: flex;
  align-items: center;
`;

const LastLoginRight = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  padding: 15px 0;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    & ${LastLoginRight} {
      border-bottom: 1px solid #eeeeee;
    }
  }
`;

const LastLoginName = styled.p`
  font-weight: 500;
`;

const LastLoginDate = styled.p`
  color: var(--darkGray);
`;

export default LastLoginItem;
