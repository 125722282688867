import JumbotronEditTitle from 'components/JumbotronEditTitle/JumbotronEditTitle';
import { ConfirmModal } from 'components/Modals';
import NavigationBar from 'components/NavigationBar';
import ROIReportSelect from 'components/roi/ROIReportSelect';
import { ROINavigationEnum, ROIReportEditNavigationEnum } from 'constants/roi';
import { useModal, useOperation } from 'hooks/common';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import { parse } from 'query-string';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  fetchROI,
  fetchROIDelete,
  fetchROIGenerateReport,
  fetchROIOld,
  fetchROISurvey,
  fetchROISurveyCreate,
  fetchROIUpdate,
} from 'store/ducks/roi/operations';
import { getROI, getROIOld, getROISurveys } from 'store/ducks/roi/selectors';
import styled from 'styled-components';
import PartnerDataIntakeForm from 'components/roi/forms/PartnerDataIntakeForm';
import { UIActionText } from 'components/UI';
import { ReactSVG } from 'react-svg';
import { ImpactReportForm } from 'components/roi/forms';
import LinkSVG from 'assets/images/icons/link.svg';
import { ShareReportModal } from 'components/roi/modals';

const ROIReportEdit = () => {
  const parsed = parse(window.location.search);
  const params = useParams<{ roiId: string }>();

  const { push } = useHistory();

  const [onFetchROI] = useOperation(fetchROI);
  const [onFetchROIUpdate] = useOperation(fetchROIUpdate);
  const [onFetchROIDelete] = useOperation(fetchROIDelete);
  const [onFetchROISurveyCreate] = useOperation(fetchROISurveyCreate);
  const [onFetchROISurvey] = useOperation(fetchROISurvey);
  const [onFetchROIGenerateReport] = useOperation(fetchROIGenerateReport);
  const [onFetchROIOld] = useOperation(fetchROIOld);

  const roi = useSelector(getROI);
  const roiOld = useSelector(getROIOld);
  const surveys = useSelector(getROISurveys);

  const [showConfirmModal, hideConfirmModal] = useModal(
    () => (
      <ConfirmModal
        name="Delete"
        description="Deleting this partnership will remove (with the inability to recover) all associated media tracking information and reporting.  Are you sure you want to delete this partnership?"
        textAccessBtn="Delete"
        textCancelBtn="Back to Page"
        accessHandler={() => {
          if (!roi) return;
          onFetchROIDelete([roi.id]);
          hideConfirmModal();
          push(`/roi?type=${ROINavigationEnum.Partnerships}`);
        }}
        onClose={hideConfirmModal}
        cancelHandler={hideConfirmModal}
      />
    ),
    [roi],
  );

  const [showShareReportModal, hideShareReportModal] = useModal(
    () => (
      <ShareReportModal
        onClose={hideShareReportModal}
        generateUrl={() =>
          `${window.location.host}?redirectTo=/roi/report/${params.roiId}`
        }
      />
    ),
    [params.roiId],
  );

  useEffect(() => {
    if (!roi?.isOld || roi.id !== params.roiId) return;
    onFetchROIOld(params.roiId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.roiId, roi]);

  useEffect(() => {
    onFetchROI(params.roiId);
    onFetchROISurvey(params.roiId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <ContentBlockWrapper
      header={
        <JumbotronEditTitle
          maxWidth="1320px"
          name={roi?.name}
          back
          backPath={`/roi?type=${ROINavigationEnum.Partnerships}`}
          onSave={value => {
            if (!roi) return;
            onFetchROIUpdate(
              { ...roi, name: value },
              undefined,
              'Edit name successfully',
            );
          }}
          onDelete={showConfirmModal}
        />
      }
    >
      <Body>
        <NavigationBar<ROIReportEditNavigationEnum>
          queryKey="type"
          list={[
            {
              label: 'Partner Data Intake',
              value: ROIReportEditNavigationEnum.Partner_Data_Intake,
              replace: 'True',
            },
            {
              label: 'Report',
              value: ROIReportEditNavigationEnum.Report,
              replace: 'True',
            },
          ].concat(
            roi?.isCompany || !roi?.isOld
              ? []
              : [
                  {
                    label: 'Legacy Reports',
                    value: ROIReportEditNavigationEnum.Legacy_Reports,
                    replace: 'True',
                  },
                ],
          )}
          activeTab={ROIReportEditNavigationEnum.Partner_Data_Intake}
        />

        {roi && (
          <>
            {parsed?.type === ROIReportEditNavigationEnum.Partner_Data_Intake && (
              <Panel>
                <PartnerDataIntakeForm
                  roi={roi}
                  surveys={surveys}
                  onSubmit={values => {
                    onFetchROIUpdate(values).then(() => {
                      onFetchROIGenerateReport(params.roiId);
                    });
                    onFetchROISurvey(params.roiId);
                  }}
                  onSurveyCreate={values => {
                    onFetchROISurveyCreate(values).then(() => {
                      onFetchROISurvey(params.roiId);
                    });
                  }}
                />
              </Panel>
            )}
            {parsed?.type === ROIReportEditNavigationEnum.Report && <ROIReportSelect />}
            {parsed?.type === ROIReportEditNavigationEnum.Legacy_Reports && roiOld && (
              <Panel>
                {parsed?.type === ROIReportEditNavigationEnum.Legacy_Reports &&
                  roiOld?.isOld && (
                    <ActionWrapper>
                      <ActionText
                        onClick={() => {
                          push(`/roi/report/${roiOld.id}`);
                          window.scrollTo(0, 0);
                        }}
                      >
                        View Valuation Report
                      </ActionText>
                      <LinkIcon src={LinkSVG} onClick={showShareReportModal} />
                    </ActionWrapper>
                  )}
                <ImpactReportForm
                  roi={roiOld}
                  disabled={true}
                  onSubmit={values =>
                    onFetchROIUpdate(
                      values,
                      undefined,
                      'Valuation Report info saved successfully',
                    )
                  }
                  onGenerate={() => {}}
                />
              </Panel>
            )}
          </>
        )}
      </Body>
    </ContentBlockWrapper>
  );
};

const Body = styled.div``;

const Panel = styled.div`
  padding: 24px;
  background-color: #fff;
  border-radius: 6px;
  margin-top: 24px;
  position: relative;
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  margin-bottom: 24px;
  display: flex;

  & > * {
    cursor: pointer;
  }
`;

const ActionWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  display: flex;
`;

const ActionText = styled(UIActionText)`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const LinkIcon = styled(ReactSVG)`
  margin-left: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;

    path {
      fill: var(--darkGray);
    }
  }
`;

export default ROIReportEdit;
