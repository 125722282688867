import React, { useEffect, useMemo, useState } from 'react';
import Jumbotron from 'components/Jumbotron';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import styled from 'styled-components';
import TotalRow from 'components/TotalRow';
import SearchInput from 'components/SearchInput';
import Pagination from 'components/Pagination';
import SurveyListTable from 'components/roi/tables/SurveyListTable';
import { useModal, useOperation, useSortStatus } from 'hooks/common';
import { ConfirmModal } from 'components/Modals';
import { useHistory, useParams } from 'react-router';
import { ROISurveyListPageType } from 'constants/roi';
import { fetchSurveyDeleteMultiple, fetchSurveySearch } from 'store/ducks/roi/operations';
import { useSelector } from 'react-redux';
import { getROISurveyList } from 'store/ducks/roi/selectors';
import queryString from 'query-string';
import { ROISurveyListReq } from 'interfaces/roi';
import { UIButton } from 'components/UI';
import { capitalizeText } from 'utils/formatters';

const ROISurveyList = () => {
  const params = useParams() as { pageType: ROISurveyListPageType };
  const { push } = useHistory();

  const [searchValue, setSearchValue] = useState('');
  const [deleteSurveyId, setDeleteSurveyId] = useState<string | undefined>(undefined);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedIds, setCheckedId] = useState<string[]>([]);
  const { sortStatus, handleSwitch, setSortStatus } = useSortStatus([], {
    isSort: false,
  });

  const [onFetchROISurveyList, , isLoading] = useOperation(fetchSurveySearch);
  const [onFetchSurveyDeleteMultiple] = useOperation(fetchSurveyDeleteMultiple);

  const { items, meta } = useSelector(getROISurveyList);

  const searchType = useMemo(
    () =>
      params.pageType === ROISurveyListPageType.ConsumerSurvey ? 'consumer' : 'employee',
    [params.pageType],
  );

  const handleSearch = (page: number) => {
    const searchParams: ROISurveyListReq = {
      page,
      q: searchValue,
      type: searchType,
      orderType: sortStatus.status,
      order: sortStatus.name,
    };
    push(`${window.location.pathname}?${queryString.stringify(searchParams)}`);
    onFetchROISurveyList(searchParams);
  };

  const handleClearSearch = () => {
    setSortStatus({ name: '', status: '' });
    setSearchValue('');
    setDeleteSurveyId(undefined);
    setIsCheckedAll(false);
    setCheckedId([]);
    const searchParams: ROISurveyListReq = {
      page: 1,
      type: searchType,
    };
    push(`${window.location.pathname}?${queryString.stringify(searchParams)}`);
    onFetchROISurveyList(searchParams);
  };

  const [showModal, hideModal] = useModal(() => {
    const handleClose = () => {
      setDeleteSurveyId(undefined);
      hideModal();
    };

    const deleteHandler = () => {
      const page = queryString.parse(window.location.search)?.page;

      const deleteIds = deleteSurveyId || checkedIds.join();
      const isMultipleDelete = !deleteSurveyId;

      onFetchSurveyDeleteMultiple(deleteIds).then(() => {
        handleSearch(Number(page) || 1);
      });
      isMultipleDelete && setCheckedId([]);
      handleClose();
    };

    return (
      <ConfirmModal
        name={'Delete'}
        textAccessBtn="Delete"
        description={`Deleting this survey may affect reporting. Are you sure you want to delete this ${capitalizeText(
          searchType,
        )} Survey?`}
        accessHandler={deleteHandler}
        cancelHandler={handleClose}
        onClose={handleClose}
        hideCloseIcon
      />
    );
  }, [deleteSurveyId, checkedIds, searchType]);

  useEffect(() => {
    if (!sortStatus?.name) return;
    const page = queryString.parse(window.location.search)?.page;
    handleSearch(Number(page) || 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  useEffect(() => {
    if (!window.location.search) {
      return handleSearch(1);
    }

    const search = (queryString.parse(
      window.location.search,
    ) as unknown) as ROISurveyListReq;
    search.q && setSearchValue(search.q);
    search.page || (search.page = 1);
    search.order && setSortStatus({ name: search.order, status: search.orderType || '' });
    onFetchROISurveyList(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentBlockWrapper
      loading={isLoading}
      header={
        <Jumbotron
          name={`${capitalizeText(searchType)} Survey`}
          back
          backPath="/roi?type=Dashboard"
        />
      }
    >
      <Body>
        <TotalRow
          title={`${meta.totalItems} ${capitalizeText(searchType)} Survey${
            meta.totalItems > 1 ? 's' : ''
          }`}
        >
          <Search
            searchInputValue={searchValue}
            onChangeInputValue={setSearchValue}
            onSearch={() => handleSearch(Number(meta.currentPage))}
            onClear={handleClearSearch}
          />
        </TotalRow>
        <PaginationWrapper>
          <UIButton
            style={{ marginRight: '40px' }}
            modifiers={'danger'}
            title="Delete"
            disabled={!checkedIds.length}
            handleClick={showModal}
          />
          <Pagination
            total={meta.totalItems}
            pageLimit={Number(meta.itemsPerPage)}
            page={Number(meta.currentPage)}
            submitHandler={handleSearch}
          />
        </PaginationWrapper>
        <SurveyListTable
          selectedAll={isCheckedAll}
          selectedIds={checkedIds}
          onCheckAll={bool => {
            setIsCheckedAll(bool);
            bool ? setCheckedId(items.map(item => item.id)) : setCheckedId([]);
          }}
          onCheckRow={id => {
            if (isCheckedAll) setIsCheckedAll(false);
            checkedIds.includes(id)
              ? setCheckedId(checkedIds.filter(item => item !== id))
              : setCheckedId([...checkedIds, id]);
          }}
          sortStatus={sortStatus}
          onSort={handleSwitch}
          rows={items}
          onDelete={id => {
            showModal();
            setDeleteSurveyId(id);
          }}
        />
      </Body>
    </ContentBlockWrapper>
  );
};

const Body = styled.div`
  max-width: 980px;
`;

const Search = styled(SearchInput)`
  width: 365px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
`;

export default ROISurveyList;
