import { HTTPRequestStatusEnum } from 'constants/common';
import { FieldValidator } from 'final-form';
import queryString from 'query-string';

export const composeValidators = <FieldValue>(
  ...validators: FieldValidator<FieldValue>[]
) => (...args: Parameters<FieldValidator<FieldValue>>) =>
  validators.reduce((error, validator) => error || validator(...args), undefined);

export function transformArrayForOptions(array: Array<string> = [], sp = '') {
  const toUpperCase = (text: string) =>
    text[0].toUpperCase() + text.slice(1).replace(/_/g, ' ');

  return array.map(item => ({
    value: item,
    label: sp
      ? item
          .split(sp)
          .map(i => toUpperCase(i))
          .join(sp)
      : toUpperCase(item),
  }));
}

export function transformEnumForOptions(enums: { [key: string]: string }) {
  return transformArrayForOptions(Object.values(enums));
}

export const callOperationAsync = async <TResponse>(
  operation: (...args: any[]) => (dispatch: any) => Promise<TResponse> | void,
  loaderToggler: Function = () => {},
  loadHandler: Function = () => {},
  errorHandler: Function = () => {},
): Promise<TResponse> => {
  let result;

  try {
    loaderToggler(true);
    loadHandler(true);
    result = await operation();
  } catch (err) {
    errorHandler(err);
    throw err;
  } finally {
    if (result?.message !== HTTPRequestStatusEnum.RE_REQUEST) {
      loaderToggler(false);
      loadHandler(false);
    }
  }

  return result;
};

export const queryToObject = queryString => {
  const pairsString = queryString[0] === '?' ? queryString.slice(1) : queryString;
  const pairs = pairsString.split('&').map(str => str.split('=').map(decodeURIComponent));
  return pairs.reduce((acc, [key, value]) => (key ? { ...acc, [key]: value } : acc), {});
};

export const getURLWithQueryParams = (base, params) => {
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value as any)}`)
    .join('&');

  return `${base}?${query}`;
};

export function paramsToQueryString(hash) {
  const params = queryString.stringify(hash, {
    arrayFormat: 'bracket',
  });

  return params ? `?${params}` : '';
}

export const identityField = (value: any) => value;

export const parseLink = (link: string) => {
  const re = new RegExp('^(http|https)://', 'i');
  const isHttp = re.test(link);
  if (!isHttp) {
    return `https://${link}`;
  }
  return link;
};

export const getFormatScore = (score: number) => Math.floor((score / 1235) * 100);

export function getUTCAsLocalDate(dateString: string) {
  const b = dateString.split(/\D/).map(num => Number(num));
  return new Date(b[0], b[1] - 1, b[2], b[3] || 0, b[4] || 0, b[5] || 0);
}

export function getDateCutOffTime(dateString: string) {
  return dateString.split('T')[0];
}

export const getValueByObj = (obj, key) => key.split('.').reduce((a, b) => a[b], obj);

export const encodeBase64 = (str: string) => window.btoa(encodeURIComponent(str));
export const decodeBase64 = (str: string) => decodeURIComponent(window.atob(str));

export const checkContainsAnotherArrary = (superset: string[], subset: string[]) => {
  return subset.every(item => superset.includes(item));
};

export const removeDuplicates = (array: string[]) => [...new Set(array)];

export const someNotNull = (arr: unknown[]) => arr.some(item => item != null);

export const colorIsLight = (hexColor: string) => {
  if (!hexColor || (hexColor.length !== 4 && hexColor.length !== 7)) {
    throw Error('Incorrect hex color format');
  }
  if (hexColor.length === 4) hexColor = hexColor + hexColor.slice(1);
  const r = Number.parseInt(hexColor.slice(1, 3), 16);
  const g = Number.parseInt(hexColor.slice(3, 5), 16);
  const b = Number.parseInt(hexColor.slice(5, 7), 16);
  return r + g + b > (0xff * 3) / 2 ? true : false;
};

export const getQueryString = (name: string): any => {
  const r = window.location.toString();
  const a = r.split('?');
  if (a.length > 1) {
    const searchStr: any = a[1];
    const re = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
    if (searchStr.match(re) != null) {
      if (name === 'img') {
        return searchStr.match(re)[2];
      } else {
        const unescapes = decodeURIComponent(searchStr.match(re)[2]);
        return unescapes;
      }
    }
  }
  return null;
};

export const setDateGreaterLessDay = (date: string, callType: string) => {
  // callType: greater/less
  if (!date) return false;
  const oldDate = new Date(date);
  const timeZone = new Date().getTimezoneOffset() / 60;

  if (callType === 'less') {
    const newDate = new Date(oldDate.setUTCDate(oldDate.getUTCDate() - 1));
    const zoneDate = newDate.setHours(newDate.getHours() + timeZone);
    return zoneDate;
  }

  const newDate = new Date(oldDate.setUTCDate(oldDate.getUTCDate() + 1));
  const zoneDate = newDate.setHours(newDate.getHours() + timeZone);
  return zoneDate;
};
