import { useMemo, useState } from 'react';

interface Props<T> {
  datasource: T[];
  limit: number;
  initialPage: number;
}

const useFakePaginationStatus = <T>({ datasource, limit, initialPage }: Props<T>) => {
  const [page, setPage] = useState(initialPage || 1);

  const data = useMemo(() => {
    return datasource.slice((page - 1) * limit, page * limit);
  }, [datasource, limit, page]);

  const total = useMemo(() => {
    return datasource.length;
  }, [datasource]);

  return { data, page, total, limit, changePage: setPage };
};

export default useFakePaginationStatus;
