import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { FieldRenderProps, Form, FormSpy } from 'react-final-form';
import { Field } from 'components/FinalForm';
import {
  FieldDatePicker,
  FieldSuggestSingle,
  FieldTextInput,
  MaskedMoneyTextInput,
} from 'components/FormFields';
import { required, webSiteLink } from 'utils/validation';
import { CompanyCreateReq, SearchCompany, SuggestCompany } from 'interfaces/companies';
import { ROIAddCompanyRes, ROICreateReq } from 'interfaces/roi';
import { FormRow } from 'components/UI/form';
import { composeValidators, getDateCutOffTime } from 'utils';
import { useModal } from 'hooks/common';
import ExistingCompanyFoundModal from './ExistingCompanyFoundModal';
import { FormApi } from 'final-form';
import UIAsterisk from 'components/UI/UIAsterisk';

interface Props extends InjectedProps {
  handleAccess: (params: ROICreateReq) => void;
  handleGetCompanies: (val: string) => void;
  companiesSearchList: SuggestCompany[];
  onCompanyCreate: (values: CompanyCreateReq) => Promise<ROIAddCompanyRes>;
  onCompanyCreateByChoose: (values: CompanyCreateReq) => Promise<ROIAddCompanyRes>;
}

interface FormProps {
  name: string;
  company: SearchCompany;
  companyUrl: string;
  CompanyAddress: string;
  annualFundraisingCommitment: string;
  renewalDate: string;
}

const AddNewPartnerModal = ({
  onClose,
  handleGetCompanies,
  companiesSearchList,
  handleAccess,
  isloading,
  onCompanyCreate,
  onCompanyCreateByChoose,
  ...modalProps
}: Props) => {
  useLockBodyScroll();
  const formRef = useRef<FormApi | undefined>(undefined);
  const [list, setList] = useState<SearchCompany[]>([]);

  const [showModal, hideModal] = useModal(
    () => (
      <ExistingCompanyFoundModal
        list={list}
        onAccess={async value => {
          let company = value;
          if (!value.id) {
            const res = await onCompanyCreateByChoose({
              name: value.name,
              website: value.website,
            });
            if (res.exist === undefined) {
              company = res;
            }
          }
          formRef.current?.change('company', company);
          formRef.current?.change('companyUrl', company.website);
          formRef.current?.change(
            'CompanyAddress',
            `${company.street ? `${company.street}, ` : ''}${
              company.city ? `${company.city}, ` : ''
            }${company.state ? `${company.state}, ` : ''}${
              company.country ? `${company.country}, ` : ''
            }${company.continent ? `${company.continent}, ` : ''}`,
          );
          hideModal();
        }}
        onClose={hideModal}
      />
    ),
    [list],
  );

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 0' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '836px',
        overflow: 'unset',
      }}
    >
      <Title>Add New Partner</Title>
      <Form
        onSubmit={(values: FormProps) => {
          if (!values?.company.id) {
            onCompanyCreate({
              name: values.company.name,
              website: values.companyUrl,
            }).then(data => {
              if (data.exist) {
                const companies: SearchCompany[] = data.companies;
                setList(companies);
                showModal();
                return;
              }
              if (data.exist === undefined) {
                handleAccess({
                  ...values,
                  companyId: data.id,
                  companyName: data.name,
                });
              }
            });
            return;
          }
          handleAccess({
            ...values,
            companyId: values?.company?.id,
            companyName: values?.company?.name,
          });
        }}
        render={({ handleSubmit, form, values }) => (
          <FormBody>
            <Field
              name="name"
              label="Partnership Description"
              component={FieldTextInput}
            />
            <FormRow>
              <Field
                name="company"
                validate={v => {
                  return v && v.name ? undefined : 'Required';
                }}
              >
                {(props: FieldRenderProps<any>) => {
                  const input = {
                    ...props.input,
                    onChange: (value: SuggestCompany) => {
                      if (!value) {
                        form.change('companyUrl', undefined);
                        form.change('CompanyAddress', undefined);
                        props.input.onChange(undefined);
                        return;
                      }

                      if (value.id) {
                        form.change('companyUrl', value.website);
                        form.change(
                          'CompanyAddress',
                          `${value.street ? `${value.street}, ` : ''}${
                            value.city ? `${value.city}, ` : ''
                          }${value.state ? `${value.state}, ` : ''}${
                            value.country ? `${value.country}, ` : ''
                          }${value.continent ? `${value.continent}, ` : ''}`,
                        );
                      }
                      props.input.onChange(value);
                    },
                  };
                  return (
                    <FieldSuggestSingle
                      {...props}
                      label={
                        <>
                          Company Name
                          <UIAsterisk />
                        </>
                      }
                      handleGetSuggestions={handleGetCompanies}
                      list={companiesSearchList}
                      placeholder="Search"
                      input={input}
                      isTypeInputToForm
                      disabled={values?.company?.id}
                    />
                  );
                }}
              </Field>
              <Field
                name="companyUrl"
                label={
                  <>
                    Website
                    <UIAsterisk />
                  </>
                }
                component={FieldTextInput}
                validate={composeValidators<string>(required, webSiteLink)}
                disabled={values?.company?.id}
              />
            </FormRow>
            <Field
              name="CompanyAddress"
              label="Address"
              component={FieldTextInput}
              disabled={
                values?.company?.id &&
                (values?.company?.street ||
                  values?.company?.city ||
                  values?.company?.state ||
                  values?.company?.country ||
                  values?.company?.continent)
              }
            />
            <FormRow>
              <Field
                name="annualFundraisingCommitment"
                label="Fundraising Commitment"
                component={MaskedMoneyTextInput}
              />
              <Field
                name="renewalDate"
                label="Renewal Date"
                parse={v => getDateCutOffTime(v)}
                component={FieldDatePicker}
                popperPlacement="top"
              />
            </FormRow>

            <FormSpy subscription={{}}>
              {({ form }) => {
                formRef.current = form;
                return null;
              }}
            </FormSpy>

            <BtnGroup>
              <Btn modifiers="secondary" title="Cancel" handleClick={onClose} />
              <Btn
                modifiers="primary"
                title="Save"
                isLoading={isloading}
                handleClick={handleSubmit}
              />
            </BtnGroup>
          </FormBody>
        )}
      />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 46px;
  text-align: center;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Btn = styled(UIButton)`
  display: block;
  width: 87px;
  font-size: 14px;
  padding: 7px 0;
`;

const FormBody = styled.div`
  margin: 0 32px;
`;

export default AddNewPartnerModal;
