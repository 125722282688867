import LogoImg from 'assets/images/Logo.svg';
import HeaderCompanyDropDown from 'components/HeaderCompanyDropDown';
import { PAGE_WIDTH } from 'constants/common';
import { ROINavigationEnum } from 'constants/roi';
import { Location } from 'history';
import { useLogout } from 'hooks/common';
import { SearCompaniesRequestProps } from 'interfaces/common';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getCompanySubscription } from 'store/ducks/companies/selectors';
import styled from 'styled-components';
import { Form, FormRenderProps } from 'react-final-form';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { encodeBase64 } from 'utils';
import queryString from 'query-string';
import { initialValuesFilter } from 'pages/organizationUser/search/Search/initialValuesFilter';
import { Field } from 'components/FinalForm';
import SearchInput from 'components/SearchInput';
import { filterTypeOrganizationOptions } from 'constants/company';

export interface HeaderProps {
  location: Location;
}

interface FormProps {
  q: string;
  filterType?: SearCompaniesRequestProps['filterType'];
}

const Search = styled(SearchInput)`
  width: 100%;
  margin: 0 40px 0 20px;
`;

const HeaderCompany = ({ location }: HeaderProps) => {
  const { logout } = useLogout();
  const { push, location: local } = useHistory();
  const subscription = useSelector(getCompanySubscription);

  const parsedQuery = queryString.parse(location.search, {
    parseNumbers: true,
  });

  const getParams = useCallback(
    (filters: string) => queryString.stringify({ ...parsedQuery, filters }),
    [parsedQuery],
  );

  const onSearch = (values?: FormProps) => {
    if (values?.q) {
      const base64 = encodeBase64(
        JSON.stringify({
          ...initialValuesFilter,
          ...values,
          filterType: values.filterType,
        }),
      );
      return push(`/search?${getParams(base64)}`);
    }
    return push(`/search`);
  };

  const isVisibleSearch =
    local.pathname !== '/search' && local.pathname !== '/prospecting';

  const initialFilterType = 'Best Match';

  return (
    <HeaderWrapper>
      <HeaderInner>
        <Left>
          <Link to="/">
            <Logo src={LogoImg} alt="Accelerist" />
          </Link>
          <LinkList>
            <LinkElem to="/dashboard">Dashboard</LinkElem>
            {subscription?.isIssue && (
              <LinkElem to="/issue-mapping">Issue Mapping</LinkElem>
            )}
            {subscription?.isGiving && <LinkElem to="/giving">Giving</LinkElem>}
            {subscription?.isRoi && (
              <LinkElem to={`/roi?type=${ROINavigationEnum.Partnerships}`}>ROI</LinkElem>
            )}
            {subscription?.isVetting && <LinkElem to="/search">Vetting</LinkElem>}
            {subscription?.isFundraising && (
              <LinkElem to="/campaign">Fundraising</LinkElem>
            )}
          </LinkList>
        </Left>
        <Right>
          {isVisibleSearch && (
            <Form
              onSubmit={onSearch}
              initialValues={{
                filterType: initialFilterType, // Set the initial value from the URL or use 'Best Match' if not found.
              }}
              render={({ handleSubmit, values, form }: FormRenderProps<FormProps>) => (
                <Field name="q" notRemoveWhenEmpty>
                  {() => (
                    <Search
                      onSearch={handleSubmit}
                      searchInputValue={values.q}
                      onChangeInputValue={val => form.change('q', val)}
                      filterTypesOptions={filterTypeOrganizationOptions}
                      filterTypeValue={values.filterType}
                      onChangeFilterType={value =>
                        form.change(
                          'filterType',
                          value as SearCompaniesRequestProps['filterType'],
                        )
                      }
                      bgColor="#f3fcff"
                    />
                  )}
                </Field>
              )}
            />
          )}
          <HeaderCompanyDropDown location={location} handleLogout={logout} />
        </Right>
      </HeaderInner>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  background: #d5f3ff;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  flex: 0;
  min-width: 730px;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

const HeaderInner = styled.div`
  width: 100%;
  max-width: ${PAGE_WIDTH.LARGE}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.img`
  width: 100%;
  max-width: 200px;
  margin-right: 44px;
`;

const LinkList = styled.div`
  display: flex;
`;

const LinkElem = styled(NavLink)`
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  border-bottom: 2px solid transparent;
  margin-top: 3px;
  padding: 0 3px 3px 3px;

  &:not(:last-child) {
    margin-right: 22px;
  }

  &:hover {
    border-color: var(--black);
  }

  &.active {
    border-color: var(--black);
  }
`;

export default HeaderCompany;
