import React, { useMemo, useState, useRef } from 'react';
import styled from 'styled-components';
import {
  FieldSingleSelect,
  FieldSuggest,
  FieldTextInput,
  FieldTreeSelect,
} from 'components/FormFields';
import { goalAlignmentOptions } from 'constants/goalAlignment';
import FormSection from '../FormSection';

import {
  filterSectionTexts,
  fundraisingContributionOptions,
  revenueRangeOptions,
} from 'constants/search';
import {
  FieldSearchPanelWithCheckBoxes,
  FiledMultiSelectWithCheckBoxes,
} from 'components/FormFields';
import { FormCompanySectionProps } from 'components/Forms/interfaces';
import { formatValueEnum } from 'components/UI/interfaces';
import { Field } from 'components/FinalForm';
import { FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useOperation } from 'hooks/common';
import {
  fetchGetCitesByStates,
  fetchGetParentsRegion,
  fetchGetStateByCountry,
} from 'store/ducks/user/operations';
import { useSelector } from 'react-redux';
import {
  getCitesByStatesOptions,
  getStateByCountryOptions,
} from 'store/ducks/user/selectors';
import { UILink } from 'components/UI';
import NAICSCodeSubCategoryListModal from './NAICSCodeSubCategoryListModal';
import { FormApi } from 'final-form';

const CompanySection = ({
  crsFocus,
  countries,
  states,
  cites,
  handleGetCompanies,
  companiesSearchList,
  indestriesTree,
  naicsCode,
  primaryNaicsCode,
}: FormCompanySectionProps) => {
  const { company } = filterSectionTexts;
  const [isShowNAICSModal, setShowNAICSModal] = useState(false);
  const formRef = useRef<FormApi<any> | null>(null);

  const [onFetchGetStateByCountry] = useOperation(fetchGetStateByCountry);
  const [onfetchGetCitesByStates] = useOperation(fetchGetCitesByStates);
  const [onFetchGetParentsRegion] = useOperation(fetchGetParentsRegion);

  const statesOptionsByCountry = useSelector(getStateByCountryOptions);
  const citesOptionsByStates = useSelector(getCitesByStatesOptions);

  const citesOptions =
    formRef.current?.getFieldState('country')?.value?.length &&
    formRef.current?.getFieldState('location')?.value?.length
      ? citesOptionsByStates
      : cites;

  const goalOptions = useMemo(
    () =>
      Object.values(goalAlignmentOptions).map(({ value, title }) => ({
        value,
        label: title,
      })),
    [],
  );

  return (
    <FormSection {...company}>
      <FormSpy subscription={{}}>
        {({ form }) => {
          if (formRef.current === form) return;
          formRef.current = form;
          return null;
        }}
      </FormSpy>
      <Field
        name="ids"
        label="Company Name"
        component={FieldSuggest}
        handleGetSuggestions={handleGetCompanies}
        placeholder="Search"
        list={companiesSearchList}
      />

      <GridWrapper>
        <Field
          name="country"
          label="Country"
          component={FieldSearchPanelWithCheckBoxes}
          options={countries}
        />
        <OnChange name="country">
          {(value: string[], previous) => {
            if (value === previous) return;
            onFetchGetStateByCountry(value);
          }}
        </OnChange>

        <Field
          name="industry"
          label="Industry"
          component={FieldTreeSelect}
          treeData={indestriesTree}
          parse={(v: string[]) => {
            return indestriesTree
              .filter(item => item.children)
              .map(item => item.children)
              .flat(1)
              .filter(item => item && v.includes(item.value))
              .map(item => item?.label);
          }}
          format={v => {
            if (!v) return null;
            return indestriesTree
              .filter(item => item.children)
              .map(item => item.children)
              .flat(1)
              .filter(item => item && v.includes(item.label))
              .map(item => item?.value);
          }}
        />
        <FormSpy subscription={{ values: true }}>
          {({ form, values }) => (
            <>
              <Field
                name="location"
                label="State/Province/Region"
                component={FieldSearchPanelWithCheckBoxes}
                options={values?.country?.length ? statesOptionsByCountry : states}
                onItemSelect={(value, check) => {
                  if (!check) return;

                  onFetchGetParentsRegion({
                    region: value,
                    type: 'state',
                  }).then(value => {
                    if (!value) return;
                    form.change(
                      'country',
                      values?.country
                        ? [...new Set([...values.country, value.country])]
                        : [value.country],
                    );
                  });
                }}
              />
              <OnChange name="location">
                {(value: string[], previous) => {
                  if (value === previous) return;
                  onfetchGetCitesByStates(value);
                }}
              </OnChange>
            </>
          )}
        </FormSpy>
        <Field
          name="primaryNaicsCode"
          label="NAICS Code Category"
          component={FieldSearchPanelWithCheckBoxes}
          options={primaryNaicsCode}
        />

        <FormSpy subscription={{}}>
          {({ form }) => (
            <Field
              name="city"
              label="City"
              component={FieldSearchPanelWithCheckBoxes}
              options={citesOptions}
              onItemSelect={(value, check) => {
                if (!check) return;

                type OptionValueType = string[] | null;
                const countryValue = form.getFieldState('country')
                  ?.value as OptionValueType;
                const locationValue = form.getFieldState('location')
                  ?.value as OptionValueType;

                onFetchGetParentsRegion({
                  region: value,
                  type: 'city',
                }).then(value => {
                  if (!value) return;
                  form.change(
                    'country',
                    countryValue
                      ? [...new Set([...countryValue, value.country])]
                      : [value.country],
                  );
                  form.change(
                    'location',
                    locationValue
                      ? [...new Set([...locationValue, value.state])]
                      : [value.state],
                  );
                });
              }}
            />
          )}
        </FormSpy>

        <Field
          name="naicsCode"
          label={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              NAICS Code Sub-Category{' '}
              <UILink modifier="blue" onClick={() => setShowNAICSModal(true)}>
                View List
              </UILink>
            </div>
          }
          component={FieldSearchPanelWithCheckBoxes}
          options={naicsCode}
        />
        <FormSpy>
          {({ form, values }) => (
            <NAICSCodeSubCategoryListModal
              isOpen={isShowNAICSModal}
              onClose={() => setShowNAICSModal(false)}
              naicsCode={naicsCode}
              value={values.naicsCode}
              form={form}
            />
          )}
        </FormSpy>
      </GridWrapper>
      <Row>
        <Field
          name="zipCode"
          label="Postal Code"
          component={FieldTextInput}
          placeholder="Enter Postal Code"
        />
        <Field
          name="revenueRange"
          label="Revenue Range"
          component={FiledMultiSelectWithCheckBoxes}
          options={revenueRangeOptions}
          formatValue={formatValueEnum.BASE_ARRAY}
        />
      </Row>
      <Row>
        <Field
          name="csrFocusIds"
          label="CSR Focus"
          component={FiledMultiSelectWithCheckBoxes}
          options={crsFocus}
          formatValue={formatValueEnum.BASE_ARRAY}
        />
        <Field
          name="contributionRange"
          label="Annual Fundraising Contributions"
          component={FiledMultiSelectWithCheckBoxes}
          options={fundraisingContributionOptions}
          formatValue={formatValueEnum.BASE_ARRAY}
        />
      </Row>
      <Row>
        <Field
          name="sdgGoals"
          label="SDG Goals"
          component={FiledMultiSelectWithCheckBoxes}
          options={goalOptions}
          placeholder={'Select'}
          formatValue={formatValueEnum.BASE_ARRAY}
        />
        <Field
          name="corporateFoundation"
          label="Corporate Foundation"
          component={FieldSingleSelect}
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ]}
        />
      </Row>
    </FormSection>
  );
};

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    width: calc(50% - 8px);
  }
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 16px;

  ul {
    max-height: 106px !important;
  }
`;

export default CompanySection;
