import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { UISuggestOption } from './interfaces';
import CloseSVG from 'assets/images/icons/close-toast.svg';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import ReactTooltip from 'react-tooltip';

export interface UISuggestSingleProps {
  list: UISuggestOption[];
  value: UISuggestOption | undefined;
  placeholder: string;
  handleGetSuggestions: (value: string) => void;
  handleSelectSuggest: (value: UISuggestOption | undefined) => void;
  error?: boolean;
  handleBlur?: () => void;
  disabled?: boolean;
  onChangeByType?: (text: string) => void;
  className?: string;
  hideClearIcon?: boolean;
  onEnter?: () => void;
  isShowTitle?: boolean;
}

function UISuggestSingle({
  list,
  value,
  handleGetSuggestions,
  handleSelectSuggest,
  placeholder,
  handleBlur,
  error,
  disabled = false,
  onChangeByType,
  className,
  hideClearIcon,
  isShowTitle = false,
  onEnter,
}: UISuggestSingleProps) {
  const [inputValue, setInputValue] = useState<UISuggestOption>({ name: '', id: '' });
  const onChange = (event, { newValue, method }) => {
    if (method === 'type') {
      onChangeByType?.(newValue);
    }
    setInputValue({ name: newValue, id: newValue });
  };

  const getSuggestionValue = (suggestion: UISuggestOption) => {
    return suggestion.name;
  };

  const renderSuggestion = (val: UISuggestOption) => (
    <div className="autosuggest__item">{val.name}</div>
  );

  const onSuggestionsFetchRequested = ({ value }) => {
    handleGetSuggestions(value);
  };

  const handleKeyDown = e => {
    if (e.key !== 'Enter') return;
    onEnter?.();
  };

  useEffect(() => {
    setInputValue(value || { name: '', id: '' });
    ReactTooltip.rebuild();
    return () => {
      ReactTooltip.hide();
    };
  }, [value]);

  const getSubStr = (str: any) => {
    let subStr = str;
    if (str.length > 38) {
      subStr = str.substr(0, 37) + '...';
    }
    return subStr;
  };
  const inputProps = {
    placeholder,
    value:
      typeof inputValue === 'string'
        ? isShowTitle
          ? getSubStr(inputValue)
          : inputValue
        : inputValue?.name || '',
    onChange,
    onBlur: handleBlur,
    onKeyDown: handleKeyDown,
    disabled,
    title: typeof inputValue === 'string' ? inputValue : inputValue?.name || '',
  };

  return (
    <Root
      className={className}
      $error={error}
      $disabled={disabled}
      $hideClearIcon={hideClearIcon}
    >
      <Autosuggest
        suggestions={list}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={(e, { suggestion }) => handleSelectSuggest(suggestion)}
      />

      {!hideClearIcon && value?.id && (
        <Clear src={CloseSVG} onClick={() => handleSelectSuggest(undefined)} />
      )}
    </Root>
  );
}

const Root = styled.div<{
  $error: UISuggestSingleProps['error'];
  $disabled: UISuggestSingleProps['disabled'];
  $hideClearIcon: UISuggestSingleProps['hideClearIcon'];
}>`
  position: relative;

  ${({ $error }) =>
    $error &&
    `
    .react-autosuggest__input {
      background: #FFF2F2;
      border: 1px solid var(--red);

      &:focus {
        border: 1px solid var(--red);
      }
    } 
  `}

  ${({ $disabled }) =>
    $disabled &&
    `
    .react-autosuggest__input {
      background: #f9f9f9;
      border: #f9f9f9;
      cursor: not-allowed;
    } 
  `}

  ${({ $hideClearIcon }) =>
    $hideClearIcon &&
    `
    .react-autosuggest__input {
      padding: 10px 16px;
    }  
  `}
`;

const Clear = styled(ReactSVG)`
  position: absolute;
  top: 12px;
  right: 8px;
  cursor: pointer;

  & svg {
    path {
      stroke: var(--black);
    }
  }
`;

export default UISuggestSingle;
