import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled, { CSSProp } from 'styled-components';
import { FormError } from 'components/UI/form';
import { FormLabel } from 'components/UI/form';

interface BaseProps {
  labelCss?: CSSProp;
}

type Props = BaseProps &
  FieldRenderProps<string | number | boolean | number[] | string[]>;

function FieldSection({ label, meta, children, labelCss }: Props) {
  const { error, submitError, touched, dirtySinceLastSubmit } = meta;
  const isError = (error && touched) || (submitError && !dirtySinceLastSubmit);

  return (
    <Field>
      {label && <FormLabel css={labelCss}>{label}</FormLabel>}
      {children}
      {isError && <Error meta={meta} />}
    </Field>
  );
}

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const Error = styled(FormError)`
  position: absolute;
`;

export default FieldSection;
