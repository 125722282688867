import React from 'react';
import Jumbotron from 'components/Jumbotron';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import styled from 'styled-components';
import NavigationBar from 'components/NavigationBar';
import { IssueMappingNavigationEnum } from 'constants/IssueMapping';
import queryString from 'query-string';
import { PersonaAnalysisContent } from './PersonaAnalysisContent';
import { SurveysContent } from './SurveysContent';
import { RecommendationsContent } from './RecommendationsContent';

const IssueMapping = () => {
  const search = queryString.parse(window.location.search) as {
    type: IssueMappingNavigationEnum;
  };

  return (
    <ContentBlockWrapper header={<Jumbotron name="Issue Mapping" />}>
      <Body>
        <div style={{ marginBottom: '30px' }}>
          <NavigationBar<IssueMappingNavigationEnum>
            defaultTab={IssueMappingNavigationEnum.Persona_Analysis}
            list={[
              {
                label: 'Surveys',
                value: IssueMappingNavigationEnum.Surveys,
              },
              {
                label: 'Persona Analysis',
                value: IssueMappingNavigationEnum.Persona_Analysis,
              },
              {
                label: 'Recommendations',
                value: IssueMappingNavigationEnum.Recommendations,
              },
            ]}
            queryKey="type"
          />
        </div>
        {search.type === IssueMappingNavigationEnum.Surveys && <SurveysContent />}
        {(!search.type ||
          search.type === IssueMappingNavigationEnum.Persona_Analysis) && (
          <PersonaAnalysisContent />
        )}
        {search.type === IssueMappingNavigationEnum.Recommendations && (
          <RecommendationsContent />
        )}
      </Body>
    </ContentBlockWrapper>
  );
};

const Body = styled.div`
  max-width: 1138px;
`;

export default IssueMapping;
