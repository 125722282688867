import React, { ButtonHTMLAttributes, FC, useMemo } from 'react';
import styled, { CSSProp } from 'styled-components';
import { applyStyleModifiers, ModifiersConfig } from 'styled-components-modifiers';
import { UIButtonProps } from './interfaces';
import UISpinner from './UISpinner';

const MODIFIER_CONFIG: ModifiersConfig = {
  primary: () => `
    font-weight: 500;
    background: var(--blue);
    color: #fff;

    &:hover{
      background: #51C2EE;
    }

    &:active{
      background: #1DA7DC;
    }

    &:disabled {
      background: #CEEDF9;
      color: rgb(43 174 224 / 0.3);
      cursor: not-allowed;
    }
  `,

  secondary: () => `
    color: var(--black);
    background: #fff;
    border-color: #E8E8E8;

    &:hover{
      border-color: #BFBFBF;
    }

    &:active{
      border-color: #2BAEE0;
    }

    &:disabled {
      border-color: #E8E8E8;
      color: #BDBDBD;
      cursor: not-allowed;
    }
  `,

  third: () => `
    color: var(--black);
    background: #fff;
    border-color: #2BAEE0;
    font-size: 12px;
    line-height: 150%;

    &:hover{
      background: #EBF9FF;
      color: #2BAEE0;
    }

    &:active{
      background: #CAF0FF;
      color: #2BAEE0;
    }

    &:disabled {
      background: #CEEDF9;
      border-color: rgba(43, 174, 224, 0.3);
      opacity: 0.5;
      color: #2BAEE0;
      cursor: not-allowed;
    }
  `,

  normal: () => `
    color: var(--black);
    background: #fff;
    font-size: 12px;
    border-color: #E8E8E8;

    &:hover{
      border-color: var(--gray);
    }

    &:active{
      border-color: var(--red);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `,
  danger: () => `
    color: var(--red);
    background: #fff;
    font-size: 12px;
    border-color: #E8E8E8;

    &:hover{
      border-color: var(--gray);
    }

    &:active{
      border-color: var(--red);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `,
  dangerBg: () => `
    color: var(--red);
    font-weight: 500;
    font-size: 12px;
    background: #FFF5F5;

    &:hover{
      border-color: #ffc1c2;
    }

    &:active{
      border-color: var(--red);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
`,

  blue: () => `
  color: var(--black);
  background: #CAF0FF;
  border-color: transparent;

  &:hover{
    background: #DAF5FF;
  }

  &:active{
    background: #B6EBFF;
  }

  &:disabled {
    color: #A8BED2;
    background: #DCF5FF;
    cursor: not-allowed;
  }
`,
  lightBlue: () => `
    color: var(--black);
    background: #fff;
    font-size: 12px;
    border-color: #caf0ff;
    padding: 8px 0;

    &:hover {
      background: #EBF9FF;
      color: #2BAEE0;
    }

    &:active {
      background: #CAF0FF;
      color: #2BAEE0;
      border-color: #2BAEE0;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `,
};

const UIButton: FC<Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'title'> &
  UIButtonProps> = ({
  disabled = false,
  handleClick,
  title,
  type = 'button',
  isLoading,
  propsCss,
  className,
  asBtn,
  ...rest
}: UIButtonProps) => {
  const color = useMemo(() => {
    const { modifiers } = rest;

    if (typeof modifiers === 'string' && modifiers === 'danger') {
      return 'var(--red)';
    }

    if (!modifiers || modifiers === 'secondary' || modifiers === 'third') {
      return 'var(--blue)';
    }

    return '#fff';
  }, [rest]);

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    !isLoading && handleClick?.(e);

  return (
    <StyledButton
      {...rest}
      type={type}
      css={propsCss}
      className={className}
      onClick={onClick}
      disabled={disabled}
      as={asBtn}
    >
      {isLoading ? (
        <LoadingWrapper>
          <Spinner color={color} width={18} height={18} />
          <LoadingText>{title}</LoadingText>
        </LoadingWrapper>
      ) : (
        title
      )}
    </StyledButton>
  );
};

const StyledButton = styled.button<UIButtonProps & { css?: CSSProp }>`
  font-size: 16px;
  line-height: 150%;
  border-radius: 6px;
  border: 1px solid transparent;
  transition: all 0.2s ease;
  padding: 8px 20px;

  ${applyStyleModifiers(MODIFIER_CONFIG)};
  ${({ css }) => css}
`;

const Spinner = styled(UISpinner)`
  padding: 3px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoadingWrapper = styled.div`
  position: relative;
`;

const LoadingText = styled.span`
  opacity: 0.3;
`;

export default UIButton;
