import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { FieldTextInput } from 'components/FormFields';

import { UIButton } from 'components/UI';
import { required, email } from 'utils/validation';
import { AuthData } from 'interfaces/auth';
import { composeValidators } from 'utils';
import styled from 'styled-components';
import { AuthFormProps } from 'interfaces/common';
import { Field } from 'components/FinalForm';

const ForgotPasswordForm = ({ onSubmit }: AuthFormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        submitting,
        pristine,
        hasValidationErrors,
      }: FormRenderProps<AuthData>) => {
        return (
          <form>
            <Title>Password Reset</Title>

            <Desc>
              Enter your email to receive instructions on how to reset your password.
            </Desc>

            <Field
              name="email"
              label="Email"
              component={FieldTextInput}
              placeholder="Enter email"
              validate={composeValidators<string>(required, email)}
            />

            <Btn
              modifiers="primary"
              title="Reset"
              handleClick={handleSubmit}
              type="submit"
              disabled={pristine || submitting || hasValidationErrors}
              isLoading={submitting}
            />
          </form>
        );
      }}
    />
  );
};

const Title = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  margin-bottom: 20px;
`;

const Desc = styled.p`
  font-size: 16px;
  line-height: 155%;
  color: var(--black);
  margin-bottom: 30px;
`;

const Btn = styled(UIButton)`
  width: 100%;
  margin-top: 16px;
`;

export default ForgotPasswordForm;
