import React from 'react';
import styled from 'styled-components';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { ROIDashboardSdgGoals } from 'interfaces/roi';

interface Props extends InjectedProps {
  title: string;
  list: ROIDashboardSdgGoals['noPoverty']['items'];
}

const ROISdgGoalsPartnershipListModal = ({
  onClose,
  title,
  list,
  ...modalProps
}: Props) => {
  useLockBodyScroll();

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 32px' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '559px',
        overflow: 'hidden',
      }}
    >
      <Title>{title}</Title>
      <List>
        {list.map(item => (
          <ListItem key={item.id}>{item.name || 'N/A'}</ListItem>
        ))}
      </List>

      <Footer>
        <UIButton modifiers={'secondary'} title="Close" handleClick={onClose} />
      </Footer>
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 46px;
  text-align: center;
`;

const List = styled.div`
  max-height: 50vh;
  overflow: auto;
`;

const ListItem = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  height: 52px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;
`;

export default ROISdgGoalsPartnershipListModal;
