/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AuthData } from 'interfaces/auth';
import { useOperation } from 'hooks/common';
import { useHistory } from 'react-router';
import { fetchChangePasswordEmail } from 'store/ducks/user/operations';
import AuthFormWrapper from 'components/AuthForms/AuthFormWrapper';
import ForgotPasswordForm from 'components/AuthForms/ForgotPasswordForm';

const ForgotPassword = () => {
  const [onSubmit] = useOperation(fetchChangePasswordEmail);
  const { push } = useHistory();

  const submit = useCallback((values: AuthData) => {
    return onSubmit(values).then(() => {
      push('/login');
    });
  }, []);

  return (
    <>
      <Wrapper>
        <ForgotPasswordForm onSubmit={submit} />
      </Wrapper>
      <LinkElem to="/login">Return to Login</LinkElem>
    </>
  );
};

const Wrapper = styled(AuthFormWrapper)`
  margin-bottom: 40px;
`;

const LinkElem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 138px;
  height: 36px;
  font-size: 12px;
  line-height: 150%;
  background: rgba(18, 36, 52, 0.15);
  border-radius: 6px;
  font-weight: 500;
  color: #fff;
  margin: 0 auto 28px;
  &:hover {
    background: rgba(18, 36, 52, 0.25);
  }
`;

export default ForgotPassword;
