import React from 'react';
import styled from 'styled-components';
import { UIButton } from 'components/UI';
import { AuthFormProps } from 'interfaces/common';

const UnsubscribeForm = ({
  email,
  onSubmit,
  unsubscribed,
}: AuthFormProps & { email: string | null; unsubscribed: boolean }) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onSubmit({});
  };

  return (
    <FormWrapper>
      <Title>Unsubscribe</Title>

      <Desc>
        {unsubscribed
          ? 'You have already unsubscribed from the gameplan.'
          : 'You are about to unsubscribe the following email from the gameplan:'}
      </Desc>

      <EmailDisplay>{email}</EmailDisplay>

      {!unsubscribed && (
        <Btn
          modifiers="primary"
          title="Unsubscribe"
          handleClick={handleClick}
          type="submit"
        />
      )}
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  margin-bottom: 20px;
  text-align: center;
`;

const Desc = styled.p`
  font-size: 16px;
  line-height: 155%;
  color: var(--black);
  margin-bottom: 30px;
  text-align: center;
`;

const EmailDisplay = styled.div`
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
  background: rgba(18, 36, 52, 0.1);
  padding: 10px 15px;
  border-radius: 6px;
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
  max-width: 300px;
`;

const Btn = styled(UIButton)`
  width: 100%;
  max-width: 200px;
  margin-top: 16px;
`;

export default UnsubscribeForm;
