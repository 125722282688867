import React from 'react';
import styled from 'styled-components';

import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import SuccessImage from 'assets/images/icons/success.svg';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';

interface BaseProps {
  handleSubmit: () => void;
}

type Props = BaseProps & InjectedProps;

const GameplanDraftModal = ({ handleSubmit, onClose, ...modalProps }: Props) => {
  useLockBodyScroll();

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '0' }}
      style={{
        width: '100%',
        maxWidth: '333px',
      }}
    >
      <ImgBg>
        <Img src={SuccessImage} alt="success" />
      </ImgBg>
      <Content>
        <Title>Game Plan Save as Draft successfully</Title>
        <Subtitle>{``}</Subtitle>
        <Footer>
          <ButtonDone
            modifiers="normal"
            propsCss={{ background: 'var(--blue)', color: '#fff' }}
            title="OK"
            handleClick={handleSubmit}
          />
        </Footer>
      </Content>
    </UIModal>
  );
};

const ImgBg = styled.div`
  width: 100%;
  height: 186px;
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  width: 109px;
  height: 109px;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  width: 234px;
  margin: 0 auto 8px;
`;
const Subtitle = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  width: 195px;
  margin: 0 auto 32px;
`;

const Content = styled.div`
  margin: 24px;
  text-align: center;
`;

const ButtonDone = styled(UIButton)`
  width: 100%;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export default GameplanDraftModal;
