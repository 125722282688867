import {
  GiveWorxROIReq,
  PartnershipsListReq,
  PartnershipsListRes,
} from 'interfaces/fundraising';
import { TeamCreateReq } from 'interfaces/team';
import { Dispatch } from 'redux';
import Toast from 'services/Toast';
import * as actions from './actions';
import * as api from './api';

export const fetchWorxROI = (params: GiveWorxROIReq) => async () => {
  return api
    .fetchWorxROI(params)
    .then(roi => {
      return roi;
    })
    .catch(err => {
      return err.response.data.statusCode === 400
        ? Toast.error(err.response.data.message)
        : Toast.error('Error create Partnership');
    });
};

export const fetchGiveWorkUpdate = (params: GiveWorxROIReq) => async () => {
  return api
    .fetchGiveWorkUpdate(params)
    .then(roi => {
      return roi;
    })
    .catch(() => Toast.error('Error save Partnership'));
};

export const fetchUpdateWorxROI = (params: GiveWorxROIReq) => async () => {
  return api
    .fetchUpdateWorxROI(params)
    .then(roi => {
      return roi;
    })
    .catch(err => {
      return Toast.error(err.response.data.message);
    });
};

export const fetchPartnershipsList = (params: PartnershipsListReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchPartnershipsList(params)
    .then((value: PartnershipsListRes) => {
      dispatch(actions.setPartnershipsList(value));
      return value;
    })
    .catch(error => {
      Toast.error('Error get Partnerships list');
      return error;
    });
};

export const fetchParListByCompanies = (params: PartnershipsListReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchParListByCompanies(params)
    .then((value: PartnershipsListRes) => {
      dispatch(actions.setPartnershipsList(value));
      return value;
    })
    .catch(error => {
      Toast.error('Error get Partnerships list');
      return error;
    });
};

export const fetchParROISuggest = (q: string) => async (dispatch: Dispatch) => {
  return api.fetchPartnershipsList({ page: 1, q }).then(data => {
    dispatch(actions.setParROISuggest(data.items));
    return data;
  });
};

export const fetchPartnershipsDelete = (ids: string[]) => async () => {
  return api
    .fetchParMultipleDelete(ids.join())
    .then(response => {
      return response;
    })
    .catch(() => Toast.error('Error delete Partnerships'));
};

export const fetchGiveROIAddTeam = (param: TeamCreateReq) => async () => {
  return api
    .fetchGiveROIAddTeam(param)
    .then(data => {
      if (!data.exist) Toast.success('Organization successfully created');
      return data;
    })
    .catch(err => {
      Toast.error('Error create organization');
      throw err;
    });
};

export const fetchGiveROIAddTeamNew = (param: TeamCreateReq) => async () => {
  return api
    .fetchGiveROIAddTeamNew(param)
    .then(data => {
      if (!data.exist) Toast.success('Organization successfully created');
      return data;
    })
    .catch(err => {
      Toast.error('Error create organization');
      throw err;
    });
};
