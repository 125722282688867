/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { useOperation } from 'hooks/common';

import { StateProps } from 'store/interfaces';
import { CardCompanyProps, PaginationMeta } from 'interfaces/common';
import { SearchCompany } from 'interfaces/companies';

import Jumbotron from 'components/Jumbotron';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import CardCompany from 'components/CardCompany';
import Pagination from 'components/Pagination';
import EmptyFavorites from 'components/EmptyFavorites';

import {
  fetchCompaniesFavorites,
  fetchCompanyFavoritesDisLike,
} from 'store/ducks/companies/operations';
import {
  getCompaniesFavorites,
  getCompaniesFavoritesMeta,
} from 'store/ducks/companies/selectors';
import List from 'components/List';
import { PAGE_WIDTH } from 'constants/common';

const CompanyFavorites: FC = () => {
  const { location } = useHistory();
  const [onFetchCompanies, isLoading] = useOperation(fetchCompaniesFavorites);
  const [onfetchCompanyDisLike] = useOperation(fetchCompanyFavoritesDisLike);

  const companies = useSelector<StateProps, SearchCompany[]>(getCompaniesFavorites);
  const meta = useSelector<StateProps, PaginationMeta>(getCompaniesFavoritesMeta);
  const { currentPage, itemsPerPage, totalItems } = meta;

  const getInitialState = () => {
    const pageString = queryString.parse(location.search)?.page;

    if (pageString) {
      return onFetchCompanies(Number(pageString));
    }
    return onFetchCompanies(1);
  };

  useEffect(() => {
    getInitialState();
  }, []);

  const onDisLike = (id: string) => {
    onfetchCompanyDisLike(id).then(() => onFetchCompanies(1));
  };

  return (
    <ContentBlockWrapper header={<Jumbotron name="Favorites" />} loading={isLoading}>
      <HeaderList>
        <Total>{`${totalItems} companies`}</Total>
        {totalItems > 0 && (
          <Pagination
            total={totalItems}
            pageLimit={Number(itemsPerPage)}
            page={Number(currentPage)}
            submitHandler={onFetchCompanies}
          />
        )}
      </HeaderList>

      {companies.length > 0 ? (
        <List<SearchCompany, CardCompanyProps>
          list={companies}
          component={CardCompany}
          isIndexKey
          componentProps={{
            setDisLikeHandler: onDisLike,
          }}
        />
      ) : (
        <Empty />
      )}
    </ContentBlockWrapper>
  );
};

const Total = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  font-family: Rubik;
  font-style: normal;
`;

const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  max-width: ${PAGE_WIDTH.NORMAL}px;
`;

const Empty = styled(EmptyFavorites)`
  height: calc(100vh - 284px);
`;

export default CompanyFavorites;
