import * as types from './types';
import { Prospect, SavedLists, SuggestSavedListRes } from 'interfaces/prospects';

export const setSavedLists = (lists: SavedLists) =>
  ({
    type: types.SET_SAVED_LISTS,
    payload: lists,
  } as const);

export const setSavedList = (list: Prospect) =>
  ({
    type: types.SET_SAVED_LIST,
    payload: list,
  } as const);

export const removeSavedList = (id: string) =>
  ({
    type: types.REMOVE_SAVED_LIST,
    payload: id,
  } as const);

export const setSuggestSavedList = (list: SuggestSavedListRes) =>
  ({
    type: types.SET_SUGGEST_SAVED_LIST,
    payload: list,
  } as const);
