import Pagination from 'components/Pagination';
import SearchInput from 'components/SearchInput';
import { UILoadAvatar, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import { format } from 'date-fns';
import { useOperation } from 'hooks/common';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import EmptyCompany from 'assets/images/icons/empty-company.svg';
import { fetchSentPitch } from 'store/ducks/team/operations';
import { getSentPitch } from 'store/ducks/team/selectors';
import { ExportButton } from 'components/ActionButtons';
import useDownloadFile from 'hooks/common/useDownloadFile';
import { fetchDownLoadPitchHistory } from 'store/ducks/team/api';

type Props = InjectedProps;

const SentPitchModal = ({ onClose, ...modalProps }: Props) => {
  useLockBodyScroll();

  const [searchValue, setSearchValue] = useState('');

  const [onFetchSentPitch] = useOperation(fetchSentPitch);
  const { items, meta } = useSelector(getSentPitch);

  const exportExcel = useDownloadFile(fetchDownLoadPitchHistory);

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '0' }}
      style={{
        width: '100%',
        maxWidth: '513px',
      }}
    >
      <Header>
        <HeaderTitle>Sent Pitches</HeaderTitle>
        <PageNo>
          <ExportButton
            name=""
            style={{ marginRight: '16px' }}
            uploadHandler={() => exportExcel('')}
          />
          {meta.totalItems > 0 && (
            <Pagination
              total={meta.totalItems}
              pageLimit={Number(meta.itemsPerPage)}
              page={Number(meta.currentPage)}
              submitHandler={(page: number) => {
                onFetchSentPitch({
                  page,
                  limit: 12,
                  q: searchValue ? searchValue : undefined,
                });
              }}
            />
          )}
        </PageNo>
      </Header>
      <Content>
        <Search
          onChangeInputValue={val => setSearchValue(val)}
          onSearch={() =>
            onFetchSentPitch({
              limit: 12,
              page: 1,
              q: searchValue ? searchValue : undefined,
            })
          }
          searchInputValue={searchValue}
          bgColor="#f2f2f2"
        />
        <Pitches>
          {items.map(item => (
            <PitchesItem key={item.id}>
              {item?.company?.logo ? (
                <LoadLogo imgKey={item.company.logo} />
              ) : (
                <EmptyLogo src={EmptyCompany} />
              )}
              <ContactItem>
                <ContactItemTitle>
                  <CompanyName>{item.company.name}</CompanyName>
                  <ContactName>
                    {item.contact && `${item.contact.firstName} ${item.contact.lastName}`}
                  </ContactName>
                </ContactItemTitle>
                <ContactItemDesc>
                  <SendBy>
                    {item.contact &&
                      `sender: ${item.sendUser.firstName} ${item.sendUser.lastName}`}
                  </SendBy>
                  <SendDate>
                    {item.pitchAt && format(new Date(item.pitchAt), 'd MMM y')}
                  </SendDate>
                </ContactItemDesc>
              </ContactItem>
            </PitchesItem>
          ))}
        </Pitches>
      </Content>
    </UIModal>
  );
};

const Header = styled.div`
  width: 100%;
  height: 92px;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  padding: 40px 40px 0;
`;

const HeaderTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
`;

const PageNo = styled.div`
  margin-left: auto;
  font-size: 12px;
  display: flex;
`;

const Content = styled.div`
  margin: 24px 30px 24px 40px;
`;

const Pitches = styled.div`
  text-align: center;
  max-height: 580px;
  overflow-x: auto;
`;

const PitchesItem = styled.div`
  display: flex;
  padding-right: 8px;
`;

const LoadLogo = styled(UILoadAvatar)`
  width: 46px;
  height: 46px;
  margin-right: 16px;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  object-fit: contain;
`;

const EmptyLogo = styled.img`
  width: 46px;
  height: 46px;
  margin-right: 16px;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  object-fit: contain;
  padding: 8px;
`;

const ContactItem = styled.div`
  flex: 1;
  height: 52px;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 12px;
`;

const ContactItemTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;

const CompanyName = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
const ContactName = styled.div`
  font-size: 12px;
`;

const ContactItemDesc = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SendBy = styled.div`
  font-size: 12px;
`;
const SendDate = styled.div`
  font-size: 12px;
  color: var(--darkGray);
`;

const Search = styled(SearchInput)`
  margin-bottom: 24px;
`;

export default SentPitchModal;
