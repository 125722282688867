/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { useOperation } from 'hooks/common';
import { CardCompanyProps, PaginationMeta } from 'interfaces/common';
import { SearchCompany } from 'interfaces/companies';
import Jumbotron from 'components/Jumbotron';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import CardCompany from 'components/CardCompany';
import Pagination from 'components/Pagination';
import EmptyFavorites from 'components/EmptyFavorites';
import {
  fetchCompanyDisLike,
  fetchCompanyLike,
  fetchLastMonthCompanies,
} from 'store/ducks/companies/operations';
import { getLastMonthCompanies } from 'store/ducks/companies/selectors';
import List from 'components/List';
import { PAGE_WIDTH } from 'constants/common';

const CompanyRecentlyAdded: FC = () => {
  const { location } = useHistory();
  const [onFetchCompanies, isLoading] = useOperation(fetchLastMonthCompanies);
  const [onFetchCompanyLike] = useOperation(fetchCompanyLike);
  const [onFetchCompanyDisLike] = useOperation(fetchCompanyDisLike);

  const { items, meta } = useSelector(getLastMonthCompanies);
  const { currentPage, itemsPerPage, totalItems } = meta as PaginationMeta;

  const getInitialState = () => {
    const pageString = queryString.parse(location.search)?.page;

    if (pageString) {
      return onFetchCompanies({ page: Number(pageString), limit: 12 });
    }
    return onFetchCompanies({ page: 1, limit: 12 });
  };

  useEffect(() => {
    getInitialState();
  }, []);

  return (
    <ContentBlockWrapper
      header={<Jumbotron name="Recently Added Companies" />}
      loading={isLoading}
    >
      <HeaderList>
        <Total>{`${totalItems} companies`}</Total>
        {totalItems > 0 && (
          <Pagination
            total={totalItems}
            pageLimit={Number(itemsPerPage)}
            page={Number(currentPage)}
            submitHandler={page => onFetchCompanies({ page, limit: 12 })}
          />
        )}
      </HeaderList>

      {items.length > 0 ? (
        <List<SearchCompany, CardCompanyProps>
          list={items}
          component={CardCompany}
          isIndexKey
          componentProps={{
            setLikeHandler: onFetchCompanyLike,
            setDisLikeHandler: onFetchCompanyDisLike,
          }}
        />
      ) : (
        <Empty />
      )}
    </ContentBlockWrapper>
  );
};

const Total = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  font-family: Rubik;
  font-style: normal;
`;

const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  max-width: ${PAGE_WIDTH.NORMAL}px;
`;

const Empty = styled(EmptyFavorites)`
  height: calc(100vh - 284px);
`;

export default CompanyRecentlyAdded;
