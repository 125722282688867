import FilterTeamsForm from 'components/Forms/FilterTeamsForm';
import { FilterTeamsData } from 'components/Forms/interfaces';
import { UISpinner } from 'components/UI';
import { PAGE_WIDTH } from 'constants/common';
import React, { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAdditionalInfoSelect } from 'store/ducks/companies/selectors';
import { getTeamRange } from 'store/ducks/user/selectors';
import { AdditionalInfoProps, StateProps } from 'store/interfaces';
import styled from 'styled-components';

interface Props {
  children?: ReactNode;
  filterSubmitHandler: (values?: FilterTeamsData) => void;
  isLoading: boolean;
  initialValues: FilterTeamsData;
}

function SearchTeamsWrapper({
  children,
  filterSubmitHandler,
  isLoading,
  initialValues,
}: Props) {
  const [isVisibleFilters, setIsVisibleFilters] = useState(false);
  const additionalInfo = useSelector<StateProps, AdditionalInfoProps>(
    getAdditionalInfoSelect(),
  );
  const teamRange = useSelector(getTeamRange);

  return (
    <Wrapper>
      <FilterTeamsForm
        onSubmit={filterSubmitHandler}
        industries={additionalInfo.industries}
        states={additionalInfo.states}
        crsFocus={additionalInfo.crsFocus}
        missions={additionalInfo.missions}
        teamRange={teamRange}
        isVisibleFilters={isVisibleFilters}
        toggleFilter={setIsVisibleFilters}
        initialValues={initialValues}
      />

      <Body>{isLoading ? <Spinner /> : <Content>{children}</Content>}</Body>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const Body = styled.div`
  background: #f9f9f9;
  min-height: calc(100vh - 176px);
`;

const Content = styled.div`
  width: 100%;
  max-width: ${PAGE_WIDTH.LARGE}px;
  margin: 0 auto;
  padding: 32px 0 20px;
`;

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 201px);
`;

export default SearchTeamsWrapper;
