import { FilterData } from 'components/Forms/interfaces';
import { SortStatus } from 'hooks/common/useSortStatus';
import { SearCompaniesRequestProps } from 'interfaces/common';

export const filterDataConvertSearchParams = (
  filter: FilterData,
  sortStatus?: SortStatus,
): Omit<SearCompaniesRequestProps, 'page' | 'limit'> => ({
  ...filter,
  ids: filter?.ids?.map(item => item.id),
  order: sortStatus?.name,
  orderType: sortStatus?.status,
  contact: filter.contact?.map(item => item.name as string).join(),
});
