import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import { CardCompanyProps } from 'interfaces/common';
import { SearchCompany } from 'interfaces/companies';
import { moneyFormatter } from 'utils/formatters';
import { LikeButton, UIButton, UICheckbox, UILoadAvatar } from 'components/UI';
import EmptyCompany from 'assets/images/icons/empty-company.svg';
import { CardCompanyRankText } from 'interfaces/search';
import {
  ShortButton,
  ShortButtonText,
} from 'pages/organizationUser/companies/Company/styled';
import RightSVG from 'assets/images/icons/right.svg';
import { useOperation } from 'hooks/common';
import {
  checkNoContact,
  fetchCompanyDisMeeting,
  fetchCompanyDisPartnership,
  fetchCompanyMeeting,
  fetchCompanyPartnership,
} from 'store/ducks/companies/operations';
import { format, isAfter, subWeeks } from 'date-fns';

const CardCompany = ({
  id,
  name,
  logo,
  street,
  city,
  state,
  country,
  zipCode,
  phone,
  crsFocus,
  revenue,
  like,
  unBlockHandler,
  setLikeHandler,
  setDisLikeHandler,
  isUnBlocked,
  check,
  setSelectedCompany,
  showCheckbox,
  score,
  rankText = CardCompanyRankText['Priority Ranking'],
  isMeeting,
  isPartnership,
  createdAt,
  enrichUpdateDate,
  showUpdateDate,
}: SearchCompany & CardCompanyProps) => {
  const { push } = useHistory();

  const [onFetchMeeting] = useOperation(fetchCompanyMeeting);
  const [onFetchDisMeeting] = useOperation(fetchCompanyDisMeeting);
  const [onFetchCompanyPartnership] = useOperation(fetchCompanyPartnership);
  const [onFetchCompanyDisPartnership] = useOperation(fetchCompanyDisPartnership);
  const [onCheckNoContact] = useOperation(checkNoContact);
  const [checkResult, setCheckResult] = useState(false);

  useEffect(() => {
    if (onCheckNoContact) {
      onCheckNoContact(id).then(result => {
        setCheckResult(Boolean(result));
      });
    }
  }, [onCheckNoContact]);

  const handleLike = (id: string) => {
    if (like) {
      return setDisLikeHandler && setDisLikeHandler(id);
    }
    return setLikeHandler && setLikeHandler(id);
  };

  const csr = crsFocus && crsFocus.length > 0 && crsFocus.map(i => i.name).join('; ');
  const isNew = isAfter(new Date(createdAt), subWeeks(new Date(), 2));

  return (
    <Root>
      {isNew && <New $isCornerPosition={!showCheckbox}>New</New>}
      {showCheckbox && !checkResult && (
        <SelectedCheckbox check={check} handleClick={() => setSelectedCompany?.(id)} />
      )}

      <Left>
        <ImageWrapper>
          {logo ? (
            <LoadImage imgKey={logo} emptyImg={EmptyCompany} />
          ) : (
            <ReactSVG src={EmptyCompany} />
          )}
        </ImageWrapper>

        <RankPriority>
          <RankText>{rankText}</RankText>
          <RankNumber>{score || '-'}</RankNumber>
        </RankPriority>
      </Left>
      <Right>
        <Top>
          <Name to={`/company/${id}`}>{name}</Name>
          <Address>
            {street && `${street} `}
            {city && `${city} `}
            {state && `${state} `}
            {country && `${country} `}
            {zipCode && `${zipCode}`}
            {!street && !city && !state && !country && !zipCode && 'No Address'}
          </Address>
          <Phone>{phone || 'No phone'}</Phone>
        </Top>
        <div>
          <ShortButton
            title={
              <>
                <ReactSVG src={RightSVG} />
                <ShortButtonText>Partnership</ShortButtonText>
              </>
            }
            modifiers={isPartnership ? 'primary' : 'secondary'}
            handleClick={() =>
              isPartnership
                ? onFetchCompanyDisPartnership(id)
                : onFetchCompanyPartnership(id)
            }
          />
          <ShortButton
            title={
              <>
                <ReactSVG src={RightSVG} />
                <ShortButtonText>Meeting</ShortButtonText>
              </>
            }
            modifiers={isMeeting ? 'primary' : 'secondary'}
            handleClick={() => (isMeeting ? onFetchDisMeeting(id) : onFetchMeeting(id))}
          />
        </div>
        <Box>
          <BoxLeft>
            <DarkGrayText>CSR Focus</DarkGrayText>
            <CSRFocusWrapper>
              {csr ? <CSRFocus>{csr}</CSRFocus> : <CSRFocus>No information</CSRFocus>}
            </CSRFocusWrapper>
          </BoxLeft>
          <BoxRight>
            <DarkGrayText textAlign="right">Revenue</DarkGrayText>
            <BlackText>
              {revenue
                ? Number(revenue) === 1000000000
                  ? moneyFormatter.from(Number(1000000000), { symbol: '$ ' }) + '+'
                  : moneyFormatter.from(Number(revenue), { symbol: '$ ' })
                : '-'}
            </BlackText>
          </BoxRight>
        </Box>
        <ButtonsGroup>
          {isUnBlocked ? (
            <UnblockButton
              modifiers={'danger'}
              handleClick={() => unBlockHandler?.(id)}
              title="Unblock"
            />
          ) : (
            <LikeButton isActive={like} setLikeHandler={() => handleLike(id)} />
          )}

          <ProfileLink
            $isUnBlocked={isUnBlocked}
            asBtn={'a'}
            modifiers="third"
            handleClick={() => push(`/company/${id}`)}
            title="Profile"
          />
        </ButtonsGroup>
      </Right>
      {showUpdateDate && enrichUpdateDate && (
        <EnrichUpdateDate>
          Updated on {format(new Date(enrichUpdateDate), 'MMM dd, yyyy')}
        </EnrichUpdateDate>
      )}
    </Root>
  );
};

const SelectedCheckbox = styled(UICheckbox)`
  position: absolute;
  right: 8px;
  top: 8px;
`;

const Root = styled.section`
  width: 536px;
  min-height: 268px;
  background: #ffffff;
  border-radius: 6px;
  margin: 12px 12px;
  padding: 26px 32px;
  display: flex;
  position: relative;
`;

const ButtonsGroup = styled.div`
  display: flex;
  align-items: center;

  & button {
    margin-right: 8px;
  }
`;

const CSRFocusWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ProfileLink = styled(UIButton)<{ $isUnBlocked?: boolean }>`
  flex: 1;
  color: var(--blue);
  text-align: center;

  ${({ $isUnBlocked }) => `font-weight: ${$isUnBlocked ? 500 : 'normal'};`}
`;

const UnblockButton = styled(UIButton)`
  flex: 1;
  font-weight: 500;
`;

const Box = styled.div`
  display: flex;
  margin-bottom: 14px;
  width: 100%;
`;

const Top = styled.div`
  min-height: 86px;
`;

const BoxLeft = styled.div`
  max-width: 170px;
  width: 100%;
  border-right: 1px solid #e8e8e8;
  padding: 0 20px 0 0;
`;
const BoxRight = styled.div`
  padding: 0 0 0 20px;
  width: 100%;
`;

const Name = styled(Link)`
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 285px;
  &:hover {
    text-decoration: underline;
  }
`;

const BlackText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  text-align: right;
`;

const DarkGrayText = styled.p<{ textAlign?: 'center' | 'left' | 'right' }>`
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
  margin-bottom: 4px;

  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`}
`;

const Address = styled(DarkGrayText)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 36px;
`;

const Phone = styled(DarkGrayText)`
  margin-bottom: 12px;
`;

const CSRFocus = styled(BlackText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Right = styled.div`
  width: 100%;
`;

const Left = styled.div`
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 6px;
  width: 168px;
  height: 216px;
  margin-right: 16px;
`;

const ImageWrapper = styled.div`
  min-width: 166px;
  height: 156px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
`;

const LoadImage = styled(UILoadAvatar)`
  width: 104px;
  height: 104px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
`;

const RankPriority = styled.div`
  text-align: center;
`;

const RankText = styled.p`
  color: var(--darkGray);
  margin: 8px 0 2px;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
`;
const RankNumber = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
`;

const New = styled.div<{ $isCornerPosition: boolean }>`
  width: 46px;
  height: 30px;
  background-color: #fcba06;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #fff;
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $isCornerPosition }) =>
    $isCornerPosition &&
    `
    border-radius: 6px;  
    right: 0;
  `}
`;

const EnrichUpdateDate = styled.div`
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  line-height: 150%;
  color: #737373;
`;

export default CardCompany;
