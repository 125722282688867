import { fetch } from 'services/ApiService';
import {
  PricingData,
  PricingListReq,
  PricingListRes,
  ReportData,
} from 'interfaces/pricing';
import { paramsToQueryString } from 'utils';
import StorageService from 'services/Storage';

export const fetchPricingList = ({ teamId, page, limit = 6 }: PricingListReq) =>
  fetch<PricingListRes>(
    'get',
    `pricing/suggested/${teamId}${paramsToQueryString({ page, limit })}`,
  );

export const fetchPricing = (id: string) => fetch<PricingData>('get', `pricing/${id}`);

export const fetchChangeCategory = () => Promise.resolve({});

export const fetchSaveValuation = (param: PricingData) =>
  fetch<PricingData>('patch', 'pricing', { payload: param });

export const fetchDeleteValuation = (id: string) => fetch('delete', `pricing/${id}`);

export const fetchSubmitValuation = (id: string) =>
  fetch('PATCH', `pricing/${id}/submit`);

export const fetchDashboradPricing = () => fetch('get', 'pricing/dashboard');

export const fetchReport = (id: string) => fetch<ReportData>('get', `pricing/${id}`);

export const fetchUploadReportPDF = (id: string, file: File) => {
  const data = new FormData();
  data.append('file', file, file?.name);

  return fetch<{ status: string; fileKey: string }>(
    'post',
    `pricing/upload/${id}`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  );
};

export const fetchRevertReport = (id: string) =>
  fetch<PricingData>('patch', `pricing/${id}/revert`);
