import React from 'react';
import styled from 'styled-components';

import { WrapperProps } from 'interfaces/common';
import { ShowMoreBlock } from 'components/ShowMoreBlock';

export interface Props {
  title: string;
  description?: string;
  isExtend?: boolean;
  hasUnsetHeight?: boolean;
}

const FormSection = ({
  title,
  description,
  children,
  className,
  isExtend,
  hasUnsetHeight = false,
}: Props & WrapperProps) => {
  return (
    <Root className={className}>
      <ShowMoreBlock
        title={title}
        defaultStatus={isExtend}
        hasUnsetHeight={hasUnsetHeight}
      >
        {description && <Description>{description}</Description>}
        {children}
      </ShowMoreBlock>
    </Root>
  );
};

const Root = styled.section``;
const Description = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  margin-bottom: 32px;
`;

export default FormSection;
