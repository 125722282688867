import { OktaAuth, toQueryString } from '@okta/okta-auth-js';
import { fecthGetSupportOktaUser } from 'store/ducks/utility/api';
import { fetchErrorHandler } from 'utils/errorHandlers';

const useJumpToSupport = () => {
  const jumpToSupport = async () => {
    try {
      const { username, password } = await fecthGetSupportOktaUser();

      const config = {
        issuer: 'https://dev-9317934.okta.com/oauth2/default',
      };

      const authClient = new OktaAuth(config);

      const transaction = await authClient.signInWithCredentials({ username, password });
      if (transaction.status === 'SUCCESS') {
        // window.open(
        //   authClient.getIssuerOrigin() +
        //     '/login/sessionCookieRedirect' +
        //     toQueryString({
        //       checkAccountSetupComplete: true,
        //       token: transaction.sessionToken,
        //       redirectUrl:
        //         'https://dev-9317934.okta.com/home/dev-9317934_hubspotsaml_1/0oa7ugjmcbHcHgF9Q5d7/aln7ugnx3rcXIpnFU5d7',
        //     }),
        // );
        window.open('https://support.accelerist.com/knowledge/kb-tickets/new');
      } else {
        throw Error('Okta:We cannot handle the ' + transaction.status + ' status');
      }
    } catch (error) {
      fetchErrorHandler(error);
      console.error(error);
    }
  };

  return { jumpToSupport };
};

export default useJumpToSupport;
