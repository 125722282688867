import { SelectOption } from 'components/UI/interfaces';
import { GiveandShopProps, StateProps } from 'store/interfaces';

export const getCampaignList = (state: StateProps) => state.campaign.campaignList;
export const getReportList = (state: StateProps) => state.campaign.reportList;
export const getCampaign = (state: StateProps) => state.campaign.campaign;
export const getGiveandShopOld = (state: GiveandShopProps) => state.giveandshop;
export const getDonationInfo = (state: StateProps) => state.campaign.donationDetails;
export const getEinSuggest = (state: StateProps) =>
  state.campaign.einSuggest.data?.map(item => ({
    ...item,
    name: `${item.name}, ${item.city} ${item.state} (PPGF ID: ${item.paypalGivingFundId})`,
  }));
export const getAtworkPartnerPreviewUrl = (state: StateProps) =>
  state.campaign.atworkPartnerPreviewUrl;
export const getCampaignSelectOptions = (state: StateProps): SelectOption[] =>
  state.campaign.currentAllCampaigns.map(item => ({
    label: item.campaignName || '',
    value: item.id || '',
  }));
export const getPartnersbyCampaignSelectOptions = (state: StateProps): SelectOption[] =>
  state.campaign.partnersByCampaign.map(item => ({
    label: item.companyName,
    value: item.id,
  }));
export const getCampaignReportList = (state: StateProps) =>
  state.campaign.campaignReportList.map((item, index) => ({
    ...item,
    id: String(index),
  }));
export const getCampaignQRCode = (state: StateProps) => state.campaign.campaignQRCode;
