import { CropText } from 'components/UI';
import { NoContact } from 'interfaces/common';
import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useModal, useOperation } from 'hooks/common';
import { ConfirmModal } from 'components/Modals';
import { deleteCompanyRow } from 'store/ducks/team/operations';

interface Props {
  rows: NoContact[];
}

interface TableRowProps {
  row: NoContact;
  onDeleteRow: (id: string) => void;
}

const noContactTableWidth = {
  oneColumn: 320,
  twoColumn: 200,
  thirdColumn: 200,
  actionColumn: 60,
} as const;

const { oneColumn, twoColumn, thirdColumn, actionColumn } = noContactTableWidth;

const TableRowWrapper = ({ row, onDeleteRow }: TableRowProps) => {
  const [onDeleteCompany, ,] = useOperation(deleteCompanyRow);
  const { id, name, website, country } = row;
  const [showDropdown, setShowDropdown] = React.useState(false);

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const handleDelete = id => {
    onDeleteCompany(id)
      .then(() => {
        console.log(`Company with id: ${id} deleted successfully.`);
        onDeleteRow(id.id);
      })
      .catch(error => {
        console.error('Error deleting template:', error);
      });
  };

  const [showConfirmModal, hideConfirmModal] = useModal(() => {
    return (
      <ConfirmModal
        accessHandler={() => {
          id && handleDelete({ id: id });
          hideConfirmModal();
        }}
        cancelHandler={hideConfirmModal}
        textAccessBtn="Yes"
        textCancelBtn="No"
        name="Are you sure you want to remove this company from the do not contact list?"
        description=""
        onClose={hideConfirmModal}
      />
    );
  }, [id]);

  return (
    <TableRow onClick={toggleDropdown}>
      <TableRowCell>
        <CropText width={oneColumn} text={name} />
      </TableRowCell>
      <TableRowCell>
        <CropText width={twoColumn} text={website} />
      </TableRowCell>
      <TableRowCell>
        <CropText width={thirdColumn} text={country} />
      </TableRowCell>
      <TableRowCell>
        <RemoveButton onClick={() => showConfirmModal()}>Remove</RemoveButton>
      </TableRowCell>
    </TableRow>
  );
};

const NoContactTable = ({ rows: initialRows }: Props) => {
  const [rows, setRows] = React.useState(initialRows);

  const handleDeleteRow = id => {
    setRows(currentRows => currentRows.filter(row => row.id !== id));
  };
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${oneColumn}px` }} />
          <col span={1} style={{ width: `${twoColumn}px` }} />
          <col span={1} style={{ width: `${thirdColumn}px` }} />
          <col span={1} style={{ width: `${actionColumn}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Website</TableHeadCell>
            <TableHeadCell>Country</TableHeadCell>
            <TableHeadCell></TableHeadCell>
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(item => (
            <TableRowWrapper key={item.id} row={item} onDeleteRow={handleDeleteRow} />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow: auto;
  height: 444px;
`;

const RemoveButton = styled.button`
  font-size: 12px;
  line-height: 150%;
  color: var(--red);
  cursor: pointer;
  border: 1px solid var(--red); /* Red border for the button */
  background: transparent;
  padding: 4px 8px; /* Adjust padding to your preference */
  border-radius: 4px; /* Optional: if you want rounded corners */
  outline: none; /* Removes the default focus outline, use with caution */
  &:hover {
    background: var(--red);
    color: white;
  }
`;

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;
const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableRow = styled.tr`
  height: 100%;
  position: relative;
`;

const TableRowCell = styled.td`
  padding: 20px 0px; /* Apply consistent padding */
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;
  white-space: nowrap; /* Keeps the content on a single line */
  text-align: left; /* Ensure text is aligned consistently */

  &:first-child {
    padding-left: 24px;
  }

  &:last-child {
    padding-right: 24px;
  }
`;

export default NoContactTable;
