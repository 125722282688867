import Jumbotron from 'components/Jumbotron';
import { DashBoard } from 'components/roi/dashboard';
import { UIActionButton, UIButton, UISpinner } from 'components/UI';
import { TrackingStatusEnum } from 'constants/roi';
import { format } from 'date-fns';
import { useModal, useOperation } from 'hooks/common';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  fetchROI,
  fetchROIMeltwaterData,
  fetchROIUpdate,
} from 'store/ducks/roi/operations';
import { getROI, getROIMeltwaterData } from 'store/ducks/roi/selectors';
import styled from 'styled-components';
import SlidersSVG from 'assets/images/icons/sliders.svg';
import ArrowExplanatorySVG from 'assets/images/icons/arrow-explanatory.svg';
import { ReactSVG } from 'react-svg';
import { ConfigureTrackingModal } from 'components/roi/modals';

const ROITrackingDashboard = () => {
  const params = useParams<{ roiId: string }>();

  const [showGuide, setShowGuide] = useState(false);

  const [onFetchROI, isExecuting] = useOperation(fetchROI);
  const [onFetchROIMeltwaterData] = useOperation(fetchROIMeltwaterData);
  const [onFetchROIUpdate] = useOperation(fetchROIUpdate);

  const roi = useSelector(getROI);
  const meltwaterData = useSelector(getROIMeltwaterData);

  const [showModal, hideModal] = useModal(
    () => (
      <ConfigureTrackingModal
        disabled={!!roi?.trackingStatus}
        onClose={hideModal}
        onSave={onFetchROIUpdate}
        onTrack={values =>
          onFetchROIUpdate(
            {
              ...values,
              trackingStatus: TrackingStatusEnum.PendingAdmin,
            },
            undefined,
            `Tracking information has been submitted successfully. Once media tracking has been generated, your partnership tracking information will be displayed.`,
          )
        }
        roi={roi!}
      />
    ),
    [roi],
  );

  useEffect(() => {
    if (!roi || isExecuting) return;
    if (
      roi.campaignKeywords ||
      roi.influencerHandles ||
      roi.partnerSocialHandles ||
      roi.nonprofitSocialHandles ||
      roi.startDate ||
      roi.endDate
    )
      return;

    setShowGuide(true);
  }, [roi, isExecuting]);

  useEffect(() => {
    onFetchROI(params.roiId);
    onFetchROIMeltwaterData(params.roiId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentBlockWrapper
      header={
        <Jumbotron
          back
          bodyMaxWidth="none"
          name={
            <JumbotronTitleWrapper>
              {roi?.name} Tracking Dashboard
              {roi?.startDate &&
                roi.endDate &&
                ` (${format(new Date(roi.startDate), 'yyyy-MM-dd')} - ${format(
                  new Date(roi.endDate),
                  'yyyy-MM-dd',
                )})`}
            </JumbotronTitleWrapper>
          }
        >
          <ActionButtonWrapper $showGuide={showGuide}>
            <UIActionButton
              handleClick={() => roi && showModal()}
              icon={SlidersSVG}
              name="Configure Tracking"
            />
            {showGuide && (
              <ExplanatoryText>
                <ArrowExplanatoryIcon src={ArrowExplanatorySVG} />
                <p>
                  To set up media tracking for your partnership, start by clicking &apos;
                  <b>Configure Tracking</b>&apos; to provide information about the
                  partnership.
                </p>
                <br />
                <p>
                  Once you have provided all of the necessary information, you can submit
                  the request to generate the tracking report.
                </p>
                <br />
                <p>
                  When the tracking report has been generated, you will be able to view
                  your partnership tracking data.
                </p>
                <GotItBtn
                  title="Got It"
                  modifiers="primary"
                  handleClick={() => setShowGuide(false)}
                />
              </ExplanatoryText>
            )}
          </ActionButtonWrapper>
        </Jumbotron>
      }
    >
      {!meltwaterData ? (
        <Pending>
          {meltwaterData === '' ? roi?.trackingStatus && 'Pending...' : <UISpinner />}
        </Pending>
      ) : (
        <DashBoard meltwaterData={meltwaterData} />
      )}
    </ContentBlockWrapper>
  );
};

const JumbotronTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Pending = styled.div`
  display: flex;
  justify-content: center;
  height: 300px;
  align-items: center;
  font-size: 48px;
  opacity: 0.5;
`;

const ActionButtonWrapper = styled.div<{ $showGuide: boolean }>`
  position: relative;
  margin-left: auto;
  border-radius: 4px;
  padding: 18px 20px;

  ${({ $showGuide }) => $showGuide && 'box-shadow: 0px 0px 0px 300vw rgb(0 0 0 / 30%);'}
`;

const ExplanatoryText = styled.div`
  position: absolute;
  background: white;
  width: 374px;
  padding: 24px;
  top: calc(100% + 40px);
  right: 100%;
  border-radius: 4px;
  border: 1px dashed var(--blue);
  z-index: 1;
  font-size: 12px;
  line-height: 150%;

  b {
    font-weight: 500;
  }
`;

const ArrowExplanatoryIcon = styled(ReactSVG)`
  position: absolute;
  top: -46px;
  right: 0px;
`;

const GotItBtn = styled(UIButton)`
  padding: 3.5px 12px;
  font-size: 12px;
  line-height: 150%;
  margin-top: 20px;
  float: right;
`;

export default ROITrackingDashboard;
