import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

const GameplanMetricTooltip = () => {
  return (
    <ReactTooltip
      id="gameplan-metric-tooltip"
      className="tooltip-info"
      place="top"
      type="info"
      effect="solid"
    >
      <Content>
        To view engagement metrics, enable Game Plan Tracking by going to Organization
        Profile, then Settings.
      </Content>
    </ReactTooltip>
  );
};

const Content = styled.div`
  width: 274px;
`;

export default GameplanMetricTooltip;
