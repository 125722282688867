import { AudienceAnalysisCreateReq, AudienceListReq } from 'interfaces/audience';
import { Dispatch } from 'redux';
import Toast from 'services/Toast';

import * as actions from './actions';
import * as api from './api';

export const fetchAudienceCreate = (params: AudienceAnalysisCreateReq) => async () => {
  return api
    .fetchAudienceCreate(params)
    .then(() => {
      Toast.success('Your request has been submitted successfully');
    })
    .catch(() => Toast.error('Error request'));
};

export const fetchAudienceList = (params: AudienceListReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchAudienceList(params)
    .then(value => dispatch(actions.setAudienceList(value)))
    .catch(() => Toast.error('Error get Audience list'));
};

export const fetchAudienceDelete = (ids: string[]) => async () => {
  return api
    .fetchAudienceDelete(ids)
    .then(() => {
      Toast.success('Successfully deleted');
    })
    .catch(() => {
      Toast.error('Error delete reports');
    });
};
