import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { FieldPasswordInput } from 'components/FormFields';

import { UIButton } from 'components/UI';
import { required } from 'utils/validation';
import { AuthData } from 'interfaces/auth';
import styled from 'styled-components';
import { AuthFormProps } from 'interfaces/common';
import { Field } from 'components/FinalForm';

const NewPasswordForm = ({ onSubmit }: AuthFormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      validate={values => {
        if (values.passwordConfirmation !== values.password) {
          return { passwordConfirmation: "Those passwords didn't match" };
        }
        return {};
      }}
      render={({
        handleSubmit,
        submitting,
        pristine,
        hasValidationErrors,
      }: FormRenderProps<AuthData>) => {
        return (
          <form>
            <Title>New Password</Title>

            <Desc>Come up with a new password</Desc>

            <Field
              name="password"
              label="Password"
              type="password"
              component={FieldPasswordInput}
              validate={required}
              placeholder="Enter new password"
            />

            <Field
              name="passwordConfirmation"
              label="Password Confirmation"
              type="password"
              component={FieldPasswordInput}
              validate={required}
              placeholder="Enter password confirmation"
            />

            <Btn
              modifiers="primary"
              title="Done"
              handleClick={handleSubmit}
              type="submit"
              disabled={pristine || submitting || hasValidationErrors}
              isLoading={submitting}
            />
          </form>
        );
      }}
    />
  );
};

const Title = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  margin-bottom: 20px;
`;

const Desc = styled.p`
  font-size: 16px;
  line-height: 155%;
  color: var(--black);
  margin-bottom: 30px;
`;

const Btn = styled(UIButton)`
  width: 100%;
  margin-top: 16px;
`;

export default NewPasswordForm;
