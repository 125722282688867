import { UIActionButton } from 'components/UI';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import ArrowIcon from 'assets/images/icons/arrow-left.svg';
import History from 'services/History';
import { useHistory } from 'react-router';
import { PAGE_WIDTH } from 'constants/common';
import queryString from 'query-string';

interface Props {
  name: ReactNode;
  children?: ReactNode;
  isWithName?: boolean;
  back?: boolean;
  backPath?: string;
  id?: string;
  bodyMaxWidth?: string;
}

const Jumbotron = ({
  name,
  children,
  back,
  backPath,
  isWithName = true,
  id,
  bodyMaxWidth,
  ...rest
}: Props) => {
  const { push } = useHistory();

  const search = queryString.parse(window.location.search) as { fromPage?: string };
  if (search.fromPage) backPath = search.fromPage;

  return (
    <Root id={id} {...rest}>
      <Body bodyMaxWidth={bodyMaxWidth}>
        {back && (
          <Back onClick={() => (backPath ? push(backPath) : History.back())}>
            <UIActionButton
              style={{ marginRight: '16px' }}
              handleClick={() => {}}
              icon={ArrowIcon}
            />
            {isWithName && <Name>{name}</Name>}
          </Back>
        )}

        {isWithName && !back && <Name>{name}</Name>}
        {children}
      </Body>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  max-width: ${PAGE_WIDTH.LARGE}px;
  margin: 0 auto;
  background: #ffffff;
`;

const Body = styled.div<{ bodyMaxWidth?: string }>`
  max-width: ${({ bodyMaxWidth = '1120px' }) => bodyMaxWidth};
  display: flex;
  align-items: center;
  height: 96px;
`;

const Back = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Name = styled.h1`
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  color: var(--black);
  margin-right: 80px;
  margin-left: 8px;
`;

export default Jumbotron;
