import React from 'react';
import FormSection from '../FormSection';

import { FiledPriorityRadioButton } from 'components/FormFields';
import { filterSectionTexts } from 'constants/search';
import { Field } from 'components/FinalForm';

const PrioritySection = () => {
  const { priority } = filterSectionTexts;

  return (
    <FormSection {...priority}>
      <Field
        name="priority.crsFocus"
        label="Company CSR Focus"
        component={FiledPriorityRadioButton}
      />
      <Field
        name="priority.sdgGoal"
        label="SDG Goal"
        component={FiledPriorityRadioButton}
      />
      <Field
        name="priority.employeeEngagement"
        label="Employee Engagement"
        component={FiledPriorityRadioButton}
      />
      <Field
        name="priority.partnership"
        label="Type of Partnership"
        component={FiledPriorityRadioButton}
      />
      <Field
        name="priority.geographicLocation"
        label="Geographic Location"
        component={FiledPriorityRadioButton}
      />
      <Field
        name="priority.brandAffinities"
        label="Constituent Affinities"
        component={FiledPriorityRadioButton}
      />
    </FormSection>
  );
};

export default PrioritySection;
