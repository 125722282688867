import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

interface Props {
  value: number;
  fontColor: string;
  fontSize: number;
  bgColor: string;
  circularColor: string;
  circularWidth: number;
  size: number;
}

const DoughnutChart = ({
  value,
  fontSize,
  fontColor,
  bgColor,
  circularColor,
  circularWidth,
  size,
}: Props) => {
  const data = [{ value }, { value: 100 - value }];
  return (
    <PieChart width={size} height={size}>
      <text
        x={size / 2}
        y={size / 2 + fontSize / 2.5}
        textAnchor="middle"
        fill={fontColor}
        fontSize={`${fontSize}px`}
      >
        {`${value}%`}
      </text>
      <Pie
        data={[{ value: 100 }]}
        dataKey="value"
        innerRadius={size / 3}
        outerRadius={size / 3 + circularWidth}
        fill={bgColor}
        stroke={bgColor}
        isAnimationActive={false}
      />
      <Pie
        data={data}
        innerRadius={size / 3}
        outerRadius={size / 3 + circularWidth}
        cornerRadius={8}
        fill={bgColor}
        stroke={bgColor}
        dataKey="value"
      >
        <Cell fill={circularColor} />;
      </Pie>
    </PieChart>
  );
};

export default DoughnutChart;
