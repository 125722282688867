import { AuthStatuses } from 'interfaces/auth';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { selectAuthStatus } from 'store/ducks/user/selectors';
import { Userpilot } from 'userpilot';

const useRedirect = () => {
  const search = queryString.parse(window.location.search);
  const redirectPath = search.redirectTo as string;
  const authStatus = useSelector(selectAuthStatus);
  const { push } = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (authStatus !== AuthStatuses.AUTHOREZED || !redirectPath) return;
    Userpilot.reload();
    push(redirectPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const redirect = () => push(redirectPath);
  return { redirect };
};

export default useRedirect;
