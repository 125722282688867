import {
  Prospect,
  SavedLists,
  UploadCustomProspectingExcelReq,
  UploadCustomProspectingExcelRes,
} from 'interfaces/prospects';
import {
  SavedListRequestProps,
  SavedListCreateRequestProps,
  SavedListExcelRes,
} from 'interfaces/common';
import { fetch } from 'services/ApiService';
import { paramsToQueryString } from 'utils';
import { SuggestGameplansRes } from 'interfaces/gameplan';
import StorageService from 'services/Storage';

export const fetchSavedLists = ({ page, sort, limit = 12 }: SavedListRequestProps) =>
  fetch<SavedLists>(
    'get',
    `saved-list${paramsToQueryString({
      page,
      limit,
      sort,
    })}`,
  );

export const fetchSavedList = (id: string) => fetch<Prospect>('get', `saved-list/${id}`);

export const fetchSavedListExcel = (id: string) =>
  fetch<SavedListExcelRes>('get', `saved-list/${id}/excel`);

export const fetchSavedListCreate = (data: SavedListCreateRequestProps) =>
  fetch<Prospect>('post', `saved-list`, {
    payload: data,
  });

export const fetchSavedListUpdate = (id: string, data: Prospect) =>
  fetch<Prospect>('patch', `saved-list/${id}`, {
    payload: data,
  });

export const fetchSavedLisDestroy = (id: string) =>
  fetch<string>('delete', `saved-list/${id}`);

export const fetchSuggestSavedlist = (name: string) =>
  fetch<SuggestGameplansRes>(
    'get',
    `saved-list/findSavedList${paramsToQueryString({ q: name, page: 1, limit: 12 })}`,
  );

export const fetchUploadCustomProspectingExcel = (
  params: UploadCustomProspectingExcelReq,
) => {
  const data = new FormData();
  params.name && data.append('name', params.name);
  data.append('file', params.file);
  return fetch<UploadCustomProspectingExcelRes>(
    'post',
    'saved-list/upload-excel',
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  );
};

export const fetchDownloadCustomProspectingTemplate = () =>
  fetch<{ name: string; file: string }>('get', 'saved-list/downLoadTemplate');

export const fetchSendNotExistsCompany = (
  companys: UploadCustomProspectingExcelRes['notExistsCompanies'],
) => fetch('post', 'saved-list/sendNotExistsCompany', { payload: companys });
