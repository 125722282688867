import { ProgressBar } from 'components/UI';
import { ROIReport } from 'interfaces/roi';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { moneyNumberFormat } from 'utils/formatters';

interface Props {
  data: ROIReport['intangiblePartnershipBenchmark']['items'][0];
}

const BaselineMetric = ({ data }: Props) => {
  const progressValue = useMemo(() => (data.value / data.benchmark / 4) * 100, [data]);

  return (
    <Root key={data.type}>
      <Label>{data.type}</Label>
      <Value>
        <ProgressBarGroup>
          <BarWrapper>
            <div>Benchmark</div>
            <BenchmarkProgressBar
              value={25}
              text={String(moneyNumberFormat(data.benchmark))}
            />
          </BarWrapper>
          <BarWrapper>
            {data.benchmark < data.value ? (
              <>
                <div>Beyond Benchmark</div>
                <BeyondBenchmarkProgressBar
                  value={progressValue > 100 ? 100 : progressValue}
                  text={String(moneyNumberFormat(data.value))}
                />
              </>
            ) : data.benchmark === data.value ? (
              <>
                <div>On Benchmark</div>
                <OnBenchmarkProgressBar
                  value={progressValue}
                  text={String(moneyNumberFormat(data.value))}
                />
              </>
            ) : (
              <>
                <div>Under Benchmark</div>
                <UnderBenchmarkProgressBar
                  value={progressValue}
                  text={String(moneyNumberFormat(data.value))}
                />
              </>
            )}
          </BarWrapper>
          {data.benchmark > data.value && (
            <ChartCardDesc>{data.prescriptiveAnalytics}</ChartCardDesc>
          )}
        </ProgressBarGroup>
        <LegendList>
          <Legend>
            <LegendColor color="#EEC459" />
            <LegendText>Under Benchmark</LegendText>
          </Legend>
          <Legend>
            <LegendColor color="#2BAEE0" />
            <LegendText>On Benchmark</LegendText>
          </Legend>
          <Legend>
            <LegendColor color="#28759C" />
            <LegendText>Beyond Benchmark</LegendText>
          </Legend>
        </LegendList>
      </Value>
    </Root>
  );
};

const Root = styled.div`
  line-height: 150%;
  display: flex;
  white-space: pre-wrap;
  background-color: #fff;
  width: 100%;
`;

const Label = styled.div`
  width: 246px;
  display: flex;
  font-weight: 500;
`;

const Value = styled.div`
  flex: 1;
`;

const DetailDesc = styled.p`
  font-size: 16px;
  line-height: 148%;
`;

const ChartCardDesc = styled(DetailDesc)`
  color: var(--black);
  margin-top: 16px;
`;

const ProgressBarGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;

  & > div:not(:first-child) {
    margin-top: 4px;
  }
`;

const BarWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div:first-child {
    width: 170px;
    flex-shrink: 0;
  }
`;

const LegendList = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;

  & > div {
    margin-right: 8px;
  }
`;

const LegendColor = styled.div<{ color: string }>`
  width: 44px;
  height: 20px;
  border-radius: 99em;
  background-color: ${({ color }) => color};
`;

const LegendText = styled.div``;

const BenchmarkProgressBar = ({ value, text }: { value: number; text: string }) => (
  <ProgressBar value={value} text={text} color="#B3B3B3" />
);

const OnBenchmarkProgressBar = ({ value, text }: { value: number; text: string }) => (
  <ProgressBar value={value} text={text} color="#2BAEE0" />
);

const UnderBenchmarkProgressBar = ({ value, text }: { value: number; text: string }) => (
  <ProgressBar value={value} text={text} color="#EEC459" />
);

const BeyondBenchmarkProgressBar = ({ value, text }: { value: number; text: string }) => (
  <ProgressBar value={value} text={text} color="#28759C" />
);

export default BaselineMetric;
