import { Field } from 'components/FinalForm';
import {
  FieldSingleSelect,
  FieldTextArea,
  FieldTextInput,
  FiledMultiSelectWithCheckBoxes,
  MaskedMoneyTextInput,
} from 'components/FormFields';
import arrayMutators from 'final-form-arrays';
import { ROIData, ROISurvey } from 'interfaces/roi';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router';
import { ButtonGroup } from './styled';
import {
  FormCollapsible,
  FormRow,
  FormTitle,
  FormTitleSecondary,
  FormThreeRow,
} from 'components/UI/form';
import FormBody from 'components/UI/form/FormBody';
import IconTooltip from 'components/IconTooltip';
import styled from 'styled-components';
import {
  integerValidator,
  maxLength500,
  decimalPositiveValidator,
  percentageOneHundredValidator,
} from 'utils/validation';
import { formatValueEnum } from 'components/UI/interfaces';
import { composeValidators } from 'utils';
import { CreateConsumerSurveyModal, CreateEmployeeSurveyModal } from '../modals';
import { useModal } from 'hooks/common';
import DocumentBulletListSVG from 'assets/images/icons/document-bullet-list.svg';
import CalculatorSVG from 'assets/images/icons/calculator.svg';
import CalculatorBlackSVG from 'assets/images/icons/calculator-black.svg';
import { ReactSVG } from 'react-svg';
import { set } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { UIButton } from 'components/UI';
import { addDays, isAfter } from 'date-fns';
import { FieldArray } from 'react-final-form-arrays';
import { TeamProfileDataForm } from 'interfaces/team';
import Toast from 'services/Toast';

interface Props {
  roi: ROIData;
  surveys?: ROISurvey[];
  onSubmit: (values: ROIData) => void;
  onSurveyCreate: (params: ROISurvey) => void;
}

const onlyNumberLowercaseAndCommaValidator = (value: string) => {
  if (!value) return undefined;
  return /^[a-z0-9., ]+$/.test(value) ? undefined : 'Example: 2.1,2.2';
};

const PartnerDataIntakeForm = ({ onSubmit, roi, surveys, onSurveyCreate }: Props) => {
  const { go } = useHistory();
  const [total, setTotal] = useState(0);
  const [ofGiving, setOfGiving] = useState('');
  const [isThereValue1, setIsThereValue1] = useState(false);
  const [isThereValue2, setIsThereValue2] = useState(false);
  const [isThereValue3, setIsThereValue3] = useState(false);
  const [isThereValue4, setIsThereValue4] = useState(false);
  const [hasSubmit, setHasSubmit] = useState(false);
  const { consumerSurvey, employeeSurvey } = useMemo(() => {
    const consumerSurveys = surveys
      ?.filter(item => item.type === 'consumer')
      .sort((a, b) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime());
    const employeeSurveys = surveys
      ?.filter(item => item.type === 'employee')
      .sort((a, b) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime());

    const lastConsumerSurvey = consumerSurveys?.length
      ? consumerSurveys[consumerSurveys.length - 1]
      : undefined;
    const lastEmployeeSurvey = employeeSurveys?.length
      ? employeeSurveys[employeeSurveys.length - 1]
      : undefined;

    const isLastConsumerSurveyExpired = lastConsumerSurvey
      ? isAfter(new Date(), addDays(new Date(lastConsumerSurvey.endDate), 1))
      : true;
    const isLastEmployeeSurveyExpired = lastEmployeeSurvey
      ? isAfter(new Date(), addDays(new Date(lastEmployeeSurvey.endDate), 1))
      : true;

    return {
      consumerSurvey: isLastConsumerSurveyExpired ? undefined : lastConsumerSurvey,
      employeeSurvey: isLastEmployeeSurveyExpired ? undefined : lastEmployeeSurvey,
    };
  }, [surveys]);

  const [showCreateConsumerSurveyModal, hideCreateConsumerSurveyModal] = useModal(
    () => (
      <CreateConsumerSurveyModal
        roi={roi}
        onClose={hideCreateConsumerSurveyModal}
        survey={consumerSurvey}
        handleAccess={values => {
          onSurveyCreate(values);
          hideCreateConsumerSurveyModal();
        }}
      />
    ),
    [roi, consumerSurvey],
  );

  const [showCreateEmployeeSurveyModal, hideCreateEmployeeSurveyModal] = useModal(
    () => (
      <CreateEmployeeSurveyModal
        roi={roi}
        onClose={hideCreateEmployeeSurveyModal}
        survey={employeeSurvey}
        handleAccess={values => {
          onSurveyCreate(values);
          hideCreateEmployeeSurveyModal();
        }}
      />
    ),
    [roi, employeeSurvey],
  );

  const formInitialValues = useMemo(() => {
    const values = { ...roi };
    if (!values.calculation && !hasSubmit) {
      if (values?.team?.programFocus) {
        const programFocusAndCostPerOutcome: TeamProfileDataForm['programFocusAndCostPerOutcome'] = values?.team?.programFocus
          ?.split(';')
          .map((item, i) => ({
            costPerOutcome: values?.team?.costPerOutcome?.split(',')[i],
            programFocus: item,
            ofGiving: '',
          })) || [{ costPerOutcome: '', programFocus: '', ofGiving: '' }];
        set(values, 'programFocusAndCostPerOutcome', programFocusAndCostPerOutcome);
        setTotal(programFocusAndCostPerOutcome.length);
      }
    } else {
      const programFocusAndCostPerOutcome = values.programFocus;
      set(values, 'programFocusAndCostPerOutcome', programFocusAndCostPerOutcome);
      if (programFocusAndCostPerOutcome) {
        setTotal(programFocusAndCostPerOutcome.length);
        if (programFocusAndCostPerOutcome.length > 0) {
          for (let i = 0; i < programFocusAndCostPerOutcome.length; i++) {
            if (programFocusAndCostPerOutcome[i]['ofGiving']) {
              set(
                values,
                'ofGiving_' + i,
                parseFloat(programFocusAndCostPerOutcome[i]['ofGiving']).toFixed(1),
              );
            } else {
              set(values, 'ofGiving_' + i, '');
            }
          }
        }
      }
      setOfGiving(ofGiving);
    }

    if (values?.fundraising?.totalFundsRaisedFromConsumers) {
      setIsThereValue1(true);
    } else {
      setIsThereValue1(false);
    }
    if (values?.fundraising?.totalFundsRaisedFromEmployees) {
      setIsThereValue2(true);
    } else {
      setIsThereValue2(false);
    }
    if (values?.fundraising?.totalFundsRaisedFromCompany) {
      setIsThereValue3(true);
    } else {
      setIsThereValue3(false);
    }
    if (values?.fundraising?.totalUnderwritingOrAdditionalFunds) {
      setIsThereValue4(true);
    } else {
      setIsThereValue4(false);
    }

    if (!values.calculation && !hasSubmit) {
      set(values, 'beneficiaries', values?.team?.beneficiaries);
    }
    set(
      values,
      'socialMedia.wouldYouLikeToSubmitAdditionalSocialMediaMetricsForThisPartnership',
      true,
    );
    set(values, 'partnerActivation.ownedMedia.isOwnedMedia', true);
    set(values, 'partnerActivation.paidMedia.isPaidMedia', true);
    set(values, 'paidMedia.isPaidMedia', true);
    set(values, 'ownedMedia.isOwnedMedia', true);
    return values;
  }, [roi]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [consumerSurvey, employeeSurvey]);

  const handleCalculate = (values: any, form: any) => {
    if (
      values?.fundraising?.totalFundsRaisedFromCompany ||
      values?.fundraising?.totalFundsRaisedFromConsumers ||
      values?.fundraising?.totalFundsRaisedFromEmployees ||
      values?.fundraising?.totalUnderwritingOrAdditionalFunds
    ) {
      const number =
        Number(values?.fundraising?.totalFundsRaisedFromCompany || 0) +
        Number(values?.fundraising?.totalFundsRaisedFromConsumers || 0) +
        Number(values?.fundraising?.totalFundsRaisedFromEmployees || 0) +
        Number(values?.fundraising?.totalUnderwritingOrAdditionalFunds || 0);

      if (total) {
        const averageValue = number / total;
        const giving = (averageValue / number) * 100;
        for (let i = 0; i < total; i++) {
          form.change('ofGiving_' + i, giving.toFixed(1));
        }
        setOfGiving(giving.toFixed(2));
      }
    }
  };

  return (
    <Form<ROIData>
      initialValues={formInitialValues}
      mutators={{
        ...arrayMutators,
      }}
      onSubmit={values => {
        if (values?.programFocusAndCostPerOutcome) {
          const number =
            Number(values?.fundraising?.totalFundsRaisedFromCompany || 0) +
            Number(values?.fundraising?.totalFundsRaisedFromConsumers || 0) +
            Number(values?.fundraising?.totalFundsRaisedFromEmployees || 0) +
            Number(values?.fundraising?.totalUnderwritingOrAdditionalFunds || 0);
          const programFocusAndCostPerOutcome = values?.programFocusAndCostPerOutcome;
          const updatedData = programFocusAndCostPerOutcome.map((item, index) => ({
            ...item,
            ofGiving: values['ofGiving_' + index] ? values['ofGiving_' + index] : '',
            costPerOutcomeArea: item.programFocus,
            totalOutcome:
              number * (values['ofGiving_' + index] / 100 / item.costPerOutcome),
          }));
          values.programFocus = updatedData;

          const totalOfGiving = values.programFocus.reduce((sum, item) => {
            return sum + Number(item.ofGiving);
          }, 0);
          if (
            Number(totalOfGiving.toFixed(1)) >= 99.9 &&
            Number(totalOfGiving.toFixed(1)) <= 100.1
          ) {
            console.log();
          } else {
            Toast.error('The total of % of Giving values must add up to 100%.');
            return;
          }
        }
        setHasSubmit(true);
        onSubmit(values);
      }}
      render={({ handleSubmit, form }) => (
        <FormBody>
          <FormTitle>Company Social Spend</FormTitle>
          <FormTitleSecondary>Total Giving</FormTitleSecondary>
          <FormRow>
            <Field
              name="fundraising.totalFundsRaisedFromConsumers"
              label={
                <TooltipLabelWrapper>
                  Cash (Consumers) ($)
                  <IconTooltipBlue>Total Funds Raised by Consumers</IconTooltipBlue>
                </TooltipLabelWrapper>
              }
              component={MaskedMoneyTextInput}
              onChange={event => {
                if (event.target.value) {
                  setIsThereValue1(true);
                } else {
                  setIsThereValue1(false);
                }
              }}
              hidePrefix
            />
            <Field
              name="fundraising.totalFundsRaisedFromEmployees"
              label={
                <TooltipLabelWrapper>
                  Cash (Employees) ($)
                  <IconTooltipBlue>Total Funds Raised by Employees</IconTooltipBlue>
                </TooltipLabelWrapper>
              }
              component={MaskedMoneyTextInput}
              onChange={event => {
                if (event.target.value) {
                  setIsThereValue2(true);
                } else {
                  setIsThereValue2(false);
                }
              }}
              hidePrefix
            />
            <Field
              name="fundraising.totalFundsRaisedFromCompany"
              label={
                <TooltipLabelWrapper>
                  Cash ($)
                  <IconTooltipBlue>Total Funds Raised by Company</IconTooltipBlue>
                </TooltipLabelWrapper>
              }
              component={MaskedMoneyTextInput}
              onChange={event => {
                if (event.target.value) {
                  setIsThereValue3(true);
                } else {
                  setIsThereValue3(false);
                }
              }}
              hidePrefix
            />
            <Field
              name="fundraising.totalUnderwritingOrAdditionalFunds"
              label={
                <TooltipLabelWrapper>
                  Non-Cash ($)
                  <IconTooltipBlue>
                    Total Underwriting or Additional Funds
                  </IconTooltipBlue>
                </TooltipLabelWrapper>
              }
              component={MaskedMoneyTextInput}
              onChange={event => {
                if (event.target.value) {
                  setIsThereValue4(true);
                } else {
                  setIsThereValue4(false);
                }
              }}
              hidePrefix
            />
          </FormRow>
          <FormTitleSecondary>
            Good Beyond Giving (Workforce Engagement)
          </FormTitleSecondary>
          <FormRow>
            <Field
              name="givingParticipationRate"
              label={
                <TooltipLabelWrapper>
                  Giving Participation Rate (%)
                  <IconTooltipBlue>
                    Percentage of Total Employees that Participated in the Partnership
                  </IconTooltipBlue>
                </TooltipLabelWrapper>
              }
              component={MaskedMoneyTextInput}
              hidePrefix
              validate={composeValidators(
                integerValidator,
                percentageOneHundredValidator,
              )}
            />
            <Field
              name="typesOfFundsRaised"
              label="Types of Funds Raised"
              component={FiledMultiSelectWithCheckBoxes}
              formatValue={formatValueEnum.BASE_ARRAY}
              options={[
                { value: 'Event', label: 'Event' },
                { value: 'Payroll Deduction', label: 'Payroll Deduction' },
                { value: 'Matching Donation', label: 'Matching Donation' },
                { value: 'Dollars for Doers', label: 'Dollars for Doers' },
                { value: 'Other', label: 'Other' },
              ]}
            />
            <Field
              name="volunteeringParticipationRate"
              label={
                <TooltipLabelWrapper>
                  Volunteering Participation Rate (%)
                  <IconTooltipBlue>
                    Percentage of Total Employees that Volunteered
                  </IconTooltipBlue>
                </TooltipLabelWrapper>
              }
              component={MaskedMoneyTextInput}
              hidePrefix
              validate={composeValidators(
                integerValidator,
                percentageOneHundredValidator,
              )}
            />
            <Field
              name="typesOfVolunteering"
              label="Types of Volunteering"
              component={FiledMultiSelectWithCheckBoxes}
              formatValue={formatValueEnum.BASE_ARRAY}
              options={[
                { value: 'Event', label: 'Event' },
                { value: 'Time', label: 'Time' },
                { value: 'Talent', label: 'Talent' },
              ]}
            />
          </FormRow>
          <FormTitle>Business Value</FormTitle>
          <FormTitleSecondary>Constituent Engagement </FormTitleSecondary>
          <FormRow>
            <Field
              name="ambassadors.didYouHaveAnyAmbassadors"
              label={
                <TooltipLabelWrapper>
                  Engagement from ambassadors, executives, or influencers
                  <IconTooltipBlue>
                    Any engagement from ambassadors, executives, or influencers during
                    this partnership
                  </IconTooltipBlue>
                </TooltipLabelWrapper>
              }
              component={FieldSingleSelect}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
            />

            <Field
              name="partnerActivation.accessToAgencies"
              label={
                <TooltipLabelWrapper>
                  Access to agencies, vendors or other portfolio brands
                  <IconTooltipBlue>
                    Any access to agencies, vendors or other portfolio brand during this
                    partnership
                  </IconTooltipBlue>
                </TooltipLabelWrapper>
              }
              component={FieldSingleSelect}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
            />
            <Field
              name="partnerActivation.executiveChampions"
              label="Executive Champions"
              component={FieldSingleSelect}
              options={[
                { label: 'No Involvement', value: 'No Involvement' },
                { label: 'Somewhat Involved', value: 'Somewhat Involved' },
                { label: 'Very Involved', value: 'Very Involved' },
              ]}
            />

            <Field
              name="partnerActivation.celebrityEngagement"
              label="Celebrity Engagement"
              component={FieldSingleSelect}
              options={[
                { label: 'No Involvement', value: 'No Involvement' },
                { label: 'Somewhat Involved', value: 'Somewhat Involved' },
                { label: 'Very Involved', value: 'Very Involved' },
              ]}
            />

            <Field
              name="partnerActivation.employeeEngagement"
              label="Employee Engagement"
              component={FieldSingleSelect}
              options={[
                { label: 'No Involvement', value: 'No Involvement' },
                { label: 'Somewhat Involved', value: 'Somewhat Involved' },
                { label: 'Very Involved', value: 'Very Involved' },
              ]}
            />
          </FormRow>
          <FormTitleSecondary>Brand Reputation</FormTitleSecondary>
          <FormRow>
            <Field
              name="employeeAwareness"
              label={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>Employee Awareness (%)</div>
                  <ReactSVG
                    style={{ cursor: 'pointer' }}
                    src={DocumentBulletListSVG}
                    onClick={showCreateEmployeeSurveyModal}
                  />
                </div>
              }
              component={MaskedMoneyTextInput}
              disabled={!!employeeSurvey}
              hidePrefix
              validate={composeValidators(
                integerValidator,
                percentageOneHundredValidator,
              )}
              tip={
                employeeSurvey
                  ? 'This field is being populated based on survey responses.<br> To populate these fields manually, the survey and associated responses must be deleted.'
                  : undefined
              }
            />
            <Field
              name="employeeSentiment"
              label="Employee Sentiment (%)"
              component={MaskedMoneyTextInput}
              disabled={!!employeeSurvey}
              hidePrefix
              validate={composeValidators(
                integerValidator,
                percentageOneHundredValidator,
              )}
              tip={
                employeeSurvey
                  ? 'This field is being populated based on survey responses.<br> To populate these fields manually, the survey and associated responses must be deleted.'
                  : undefined
              }
            />
            <Field
              name="consumerAwareness"
              label={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>Consumer Awareness (%)</div>
                  <ReactSVG
                    style={{ cursor: 'pointer' }}
                    src={DocumentBulletListSVG}
                    onClick={showCreateConsumerSurveyModal}
                  />
                </div>
              }
              component={MaskedMoneyTextInput}
              hidePrefix
              disabled={!!consumerSurvey}
              validate={composeValidators(
                integerValidator,
                percentageOneHundredValidator,
              )}
              tip={
                consumerSurvey
                  ? 'This field is being populated based on survey responses.<br> To populate these fields manually, the survey and associated responses must be deleted.'
                  : undefined
              }
            />
          </FormRow>

          <Field name="partnerActivation.paidMedia.isPaidMedia" initialValue={true}>
            {() => (
              <FormCollapsible title="Non-Profit Activation: Paid Media Impressions">
                <FormRow>
                  <Field
                    name="partnerActivation.paidMedia.numberOfPrintPublicationImpressionsnumberOfPrintPublicationImpressions"
                    label="Number of print publication impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="partnerActivation.paidMedia.totalSpentOnPrintMediatotalSpentOnPrintMedia"
                    label="Total spent on print media ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="partnerActivation.paidMedia.numberOfTelevisionImpressionsnumberOfTelevisionImpressions"
                    label="Number of television impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="partnerActivation.paidMedia.totalSpentOnTelevisiontotalSpentOnTelevision"
                    label="Total spent on television ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="partnerActivation.paidMedia.numberOfOutOfHomeImpressionsnumberOfOutOfHomeImpressions"
                    label="Number of out-of-home impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="partnerActivation.paidMedia.totalSpentOnOutOfHomeImpressionstotalSpentOnOutOfHomeImpressions"
                    label="Total spent on out-of-home impressions ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="partnerActivation.paidMedia.numberOfDigitalImpressionsnumberOfDigitalImpressions"
                    label="Number of digital impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="partnerActivation.paidMedia.totalSpentOnDigitalMediatotalSpentOnDigitalMedia"
                    label="Total spent on digital media  ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="partnerActivation.paidMedia.numberOfRadioImpressionsnumberOfRadioImpressions"
                    label="Number of radio impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="partnerActivation.paidMedia.totalSpentOnRadiototalSpentOnRadio"
                    label="Total spent on radio ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
              </FormCollapsible>
            )}
          </Field>
          <Field name="partnerActivation.ownedMedia.isOwnedMedia" initialValue={true}>
            {() => (
              <FormCollapsible title="Non-Profit Activation: Owned Media Impressions">
                <FormRow>
                  <Field
                    name="partnerActivation.ownedMedia.numberOfMagazineImpressionsnumberOfMagazineImpressions"
                    label="Number of magazine impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="partnerActivation.ownedMedia.numberOfWebsiteImpressionsnumberOfWebsiteImpressions"
                    label="Number of website impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="partnerActivation.ownedMedia.numberOfInOfficeImpressionsnumberOfInOfficeImpressions"
                    label="Number of in-office impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="partnerActivation.ownedMedia.numberOfECommunicationsImpressionsnumberOfECommunicationsImpressions"
                    label="Number of e-communications impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="partnerActivation.ownedMedia.numberOfCollateralImpressionsnumberOfCollateralImpressions"
                    label="Number of collateral impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="partnerActivation.ownedMedia.numberOfInStoreImpressionsnumberOfInStoreImpressions"
                    label="Number of in-store impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="partnerActivation.ownedMedia.numberOfEventParticipantsnumberOfEventParticipants"
                    label="Number of event participants"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="partnerActivation.ownedMedia.numberOfEmployeeImpressionsnumberOfEmployeeImpressions"
                    label="Number of employee impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
              </FormCollapsible>
            )}
          </Field>
          <Field name="paidMedia.isPaidMedia" initialValue={true}>
            {() => (
              <FormCollapsible title="Company Activation: Paid Media Impressions">
                <FormRow>
                  <Field
                    name="paidMedia.numberOfPrintPublicationImpressionsnumberOfPrintPublicationImpressions"
                    label="Number of print publication impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="paidMedia.totalSpentOnPrintMediatotalSpentOnPrintMedia"
                    label="Total spent on print media ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="paidMedia.numberOfTelevisionImpressionsnumberOfTelevisionImpressions"
                    label="Number of television impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="paidMedia.totalSpentOnTelevisiontotalSpentOnTelevision"
                    label="Total spent on television ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="paidMedia.numberOfOutOfHomeImpressionsnumberOfOutOfHomeImpressions"
                    label="Number of out-of-home impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="paidMedia.totalSpentOnOutOfHomeImpressionstotalSpentOnOutOfHomeImpressions"
                    label="Total spent on out-of-home impressions ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="paidMedia.numberOfDigitalImpressionsnumberOfDigitalImpressions"
                    label="Number of digital impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="paidMedia.totalSpentOnDigitalMediatotalSpentOnDigitalMedia"
                    label="Total spent on digital media  ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="paidMedia.numberOfRadioImpressionsnumberOfRadioImpressions"
                    label="Number of radio impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="paidMedia.totalSpentOnRadiototalSpentOnRadio"
                    label="Total spent on radio ($)"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
              </FormCollapsible>
            )}
          </Field>
          <Field name="ownedMedia.isOwnedMedia" initialValue={true}>
            {() => (
              <FormCollapsible title="Company Activation: Owned Media Impressions">
                <FormRow>
                  <Field
                    name="ownedMedia.numberOfMagazineImpressionsnumberOfMagazineImpressions"
                    label="Number of magazine impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="ownedMedia.numberOfWebsiteImpressionsnumberOfWebsiteImpressions"
                    label="Number of website impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="ownedMedia.numberOfInOfficeImpressionsnumberOfInOfficeImpressions"
                    label="Number of in-office impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="ownedMedia.numberOfECommunicationsImpressionsnumberOfECommunicationsImpressions"
                    label="Number of e-communications impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="ownedMedia.numberOfCollateralImpressionsnumberOfCollateralImpressions"
                    label="Number of collateral impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="ownedMedia.numberOfInStoreImpressionsnumberOfInStoreImpressions"
                    label="Number of in-store impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="ownedMedia.numberOfEventParticipantsnumberOfEventParticipants"
                    label="Number of event participants"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                  <Field
                    name="ownedMedia.numberOfEmployeeImpressionsnumberOfEmployeeImpressions"
                    label="Number of employee impressions"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
              </FormCollapsible>
            )}
          </Field>

          <Field
            name="socialMedia.wouldYouLikeToSubmitAdditionalSocialMediaMetricsForThisPartnership"
            initialValue={true}
          >
            {() => (
              <FormCollapsible title="Social Media Impressions">
                <FormRow>
                  <Field
                    name="socialMedia.totalNumberOfAdditionalSocialMediaImpressions"
                    label="Additional impressions through Social Media"
                    component={MaskedMoneyTextInput}
                    hidePrefix
                  />
                </FormRow>
              </FormCollapsible>
            )}
          </Field>
          <FormTitleSecondary>Bottom Line Impact</FormTitleSecondary>
          <FormRow>
            <Field
              name="customerLoyalty"
              label="Customer Loyalty (%)"
              component={MaskedMoneyTextInput}
              hidePrefix
              disabled={!!consumerSurvey}
              validate={composeValidators(
                integerValidator,
                percentageOneHundredValidator,
              )}
              tip={
                consumerSurvey
                  ? 'This field is being populated based on survey responses.<br> To populate these fields manually, the survey and associated responses must be deleted.'
                  : undefined
              }
            />

            <Field
              name="customerParticipation"
              label="Customer Participation (%)"
              component={MaskedMoneyTextInput}
              hidePrefix
              validate={composeValidators(
                integerValidator,
                percentageOneHundredValidator,
              )}
            />

            <Field
              name="employeeLoyalty"
              label="Employee Loyalty (%)"
              component={MaskedMoneyTextInput}
              disabled={!!employeeSurvey}
              hidePrefix
              validate={composeValidators(
                integerValidator,
                percentageOneHundredValidator,
              )}
              tip={
                employeeSurvey
                  ? 'This field is being populated based on survey responses.<br> To populate these fields manually, the survey and associated responses must be deleted.'
                  : undefined
              }
            />

            <Field
              name="employeeParticipation"
              label="Employee Participation (%)"
              component={MaskedMoneyTextInput}
              hidePrefix
              validate={composeValidators(
                integerValidator,
                percentageOneHundredValidator,
              )}
            />

            <Field
              name="salesDriven"
              label="Sales Driven ($)"
              component={MaskedMoneyTextInput}
              hidePrefix
              validate={composeValidators(integerValidator)}
            />
            <Field
              name="savedInMitigatedRisks"
              label="Saved in Mitigated Risks ($)"
              component={MaskedMoneyTextInput}
              hidePrefix
              validate={composeValidators(integerValidator)}
            />
          </FormRow>

          <FormTitle>Additional Partnership Benefits</FormTitle>
          <FormRow>
            <Field
              name="stewardship.DidYourPartnerSupportYouWithAnyOfTheFollowingOpportunities"
              label="Stewardship Elements (multi-select)"
              component={FiledMultiSelectWithCheckBoxes}
              formatValue={formatValueEnum.BASE_ARRAY}
              options={[
                { label: 'Stewardship', value: 'Stewardship' },
                { label: 'Communication Plans', value: 'Communication Plans' },
              ]}
            />
            <Field
              name="stewardship.stewardshipitems"
              label="Stewardship Activities (multi-select)"
              component={FiledMultiSelectWithCheckBoxes}
              formatValue={formatValueEnum.BASE_ARRAY}
              options={[
                { label: 'Annual Kick-Off', value: 'Annual Kick-Off' },
                {
                  label: 'Monthly Partner Newsletter',
                  value: 'Monthly Partner Newsletter',
                },
                {
                  label: 'Partner Feature in Monthly Partner Newsletter',
                  value: 'Partner Feature in Monthly Partner Newsletter',
                },
                {
                  label: 'Monthly Partner Meeting',
                  value: 'Monthly Partner Meeting',
                },
                {
                  label: 'Quarterly Partner Meeting',
                  value: 'Quarterly Partner Meeting',
                },
                { label: 'Partner Toolkit', value: 'Partner Toolkit' },
                { label: 'VIP Event', value: 'VIP Event' },
                {
                  label: 'Speaking Engagement at VIP Event',
                  value: 'Speaking Engagement at VIP Event',
                },
                {
                  label: 'Partner Appreciation Event',
                  value: 'Partner Appreciation Event',
                },
                {
                  label: 'Annual Partner Satisfaction Survey',
                  value: 'Annual Partner Satisfaction Survey',
                },
                {
                  label: 'Personalized Executive Thank You',
                  value: 'Personalized Executive Thank You',
                },
                { label: 'Annual Review Meeting', value: 'Annual Review Meeting' },
                {
                  label: 'Additional Custom Stewardship Activation',
                  value: 'Additional Custom Stewardship Activation',
                },
              ]}
            />
          </FormRow>

          <FormTitle>Social Value</FormTitle>
          <FieldArray name="programFocusAndCostPerOutcome">
            {({ fields }) =>
              fields.map((name, index) => (
                <div key={index} style={{ position: 'relative' }}>
                  <FormThreeRow>
                    <Field
                      name={`${name}.programFocus`}
                      label="Program Focus Area"
                      component={FieldTextInput}
                      disabled
                    />
                    <Field
                      name={`${name}.costPerOutcome`}
                      label="Cost Per Outcome"
                      component={MaskedMoneyTextInput}
                      hidePrefix
                      validate={composeValidators<string>(decimalPositiveValidator)}
                    />
                    <Field
                      // name="ofGiving"
                      name={`ofGiving_${index}`}
                      label={
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>% of Giving</div>
                          {index === 0 && (
                            <ReactSVG
                              style={
                                isThereValue1 ||
                                isThereValue2 ||
                                isThereValue3 ||
                                isThereValue4
                                  ? { cursor: 'pointer' }
                                  : {}
                              }
                              src={
                                isThereValue1 ||
                                isThereValue2 ||
                                isThereValue3 ||
                                isThereValue4
                                  ? CalculatorSVG
                                  : CalculatorBlackSVG
                              }
                              onMouseDown={() =>
                                handleCalculate(form.getState().values, form)
                              }
                            />
                          )}
                        </div>
                      }
                      component={FieldTextInput}
                      hidePrefix
                      validate={composeValidators<string>(decimalPositiveValidator)}
                    />
                  </FormThreeRow>
                </div>
              ))
            }
          </FieldArray>
          <Field
            name="beneficiaries"
            label={
              <TooltipLabelWrapper>
                Beneficiaries
                <IconTooltipBlue>
                  Over the last 12 months, what positive change did your organization
                  make?
                </IconTooltipBlue>
              </TooltipLabelWrapper>
            }
            component={FiledMultiSelectWithCheckBoxes}
            formatValue={formatValueEnum.BASE_ARRAY}
            options={[
              { label: 'Men/Boys', value: 'Men/Boys' },
              { label: 'Non-binary', value: 'Non-binary' },
              { label: 'Women/Girls', value: 'Women/Girls' },
              {
                label: 'Adolescents (Secondary)',
                value: 'Adolescents (Secondary)',
              },
              { label: 'Adults', value: 'Adults' },
              {
                label: 'Birth to Young Children (Pre-K)',
                value: 'Birth to Young Children (Pre-K)',
              },
              {
                label: 'Children (Elementary school)',
                value: 'Children (Elementary school)',
              },
              {
                label: 'Early Adolescents (Middle school)',
                value: 'Early Adolescents (Middle school)',
              },
              { label: 'Elderly', value: 'Elderly' },
              {
                label: 'Young Adults (College/Post-Secondary)',
                value: 'Young Adults (College/Post-Secondary)',
              },
              {
                label: 'Asian or Pacific Islander',
                value: 'Asian or Pacific Islander',
              },
              { label: 'Black', value: 'Black' },
              {
                label: 'Hispanic/Latinx',
                value: 'Hispanic/Latinx',
              },
              { label: 'Indigenous', value: 'Indigenous' },
              { label: 'Other Race/Ethnicity', value: 'Other Race/Ethnicity' },
              {
                label: 'Active Military and/or Military Families',
                value: 'Active Military and/or Military Families',
              },
              {
                label: 'English Language Learners (ELL)',
                value: 'English Language Learners (ELL)',
              },
              {
                label: 'First-Generation College Students',
                value: 'First-Generation College Students',
              },
              {
                label: 'Homeless',
                value: 'Homeless',
              },
              {
                label: 'Incarcerated / formerly incarcerated',
                value: 'Incarcerated / formerly incarcerated',
              },
              {
                label: 'Individuals with Disabilities',
                value: 'Individuals with Disabilities',
              },
              {
                label: 'Individuals with Mental Health Condition(s)',
                value: 'Individuals with Mental Health Condition(s)',
              },
              {
                label: 'Individuals with Physical Health Condition(s)',
                value: 'Individuals with Physical Health Condition(s)',
              },
              { label: 'LGBTQ+', value: 'LGBTQ+' },
              {
                label: 'Low-Income or Economically Disadvantaged',
                value: 'Low-Income or Economically Disadvantaged',
              },
              {
                label: 'Military Veterans and/or Veteran Families',
                value: 'Military Veterans and/or Veteran Families',
              },
              {
                label: 'Newcomers, Immigrants, and/or Refugees',
                value: 'Newcomers, Immigrants, and/or Refugees',
              },
              {
                label: 'Unemployed',
                value: 'Unemployed',
              },
            ]}
          />
          {/* <FormTitleSecondary>Qualitative</FormTitleSecondary>
          <Field
            name="missionImpact"
            label={
              <TooltipLabelWrapper>
                Mission Impact
                <IconTooltipBlue>
                  Explain how this partnership has served its mission to impact the
                  intended audience (i.e. lives saved, people fed, acres cleaned, dollars
                  invested on research)
                </IconTooltipBlue>
              </TooltipLabelWrapper>
            }
            component={FieldTextArea}
            placeholder="Maximum limit: 500 characters"
            hidePrefix
            validate={maxLength500}
          /> */}
          <Field
            name="missionAdvancement"
            label={
              <TooltipLabelWrapper>
                Mission Advancement (%)
                <IconTooltipBlue>
                  Over the last 12 months, what positive change did your organization
                  make?
                </IconTooltipBlue>
              </TooltipLabelWrapper>
            }
            component={MaskedMoneyTextInput}
            hidePrefix
            validate={composeValidators(integerValidator, percentageOneHundredValidator)}
          />
          <FormRow>
            <Field
              name="scopeOfImpact"
              label="Scope of Impact"
              component={FiledMultiSelectWithCheckBoxes}
              formatValue={formatValueEnum.BASE_ARRAY}
              options={[
                { label: 'Global', value: 'Global' },
                {
                  label: 'International',
                  value: 'International',
                },
                {
                  label: 'National',
                  value: 'National',
                },
                { label: 'Regional', value: 'Regional' },
                { label: 'State/Province-wide', value: 'State/Province-wide' },
              ]}
            />
            <Field
              name="sdgGoals"
              label="SDG Goals"
              component={FiledMultiSelectWithCheckBoxes}
              formatValue={formatValueEnum.BASE_ARRAY}
              options={[
                { label: '(1) No Poverty', value: 'noPoverty' },
                { label: '(2) Zero Hunger', value: 'zeroHunger' },
                {
                  label: '(3) Good Health & Well-Being',
                  value: 'goodHealthAndWellBeing',
                },
                { label: '(4) Quality Education', value: 'qualityEducation' },
                { label: '(5) Gender Equality', value: 'genderEquality' },
                {
                  label: '(6) Clean Water & Sanitation',
                  value: 'cleanWaterAndSanitation',
                },
                {
                  label: '(7) Affordable & Clean Energy',
                  value: 'affordableAndCleanEnergy',
                },
                {
                  label: '(8) Decent Work & Economic Growth',
                  value: 'decentWorkAndEconomicGrowth',
                },
                {
                  label: '(9) Industry, Innovation & Infrastructure',
                  value: 'industryInnovationAndInfrastructure',
                },
                { label: '(10) Reduced Inequality', value: 'reducedInequalities' },
                {
                  label: '(11) Sustainable Cities & Communities',
                  value: 'sustainableCitiesAndCommunities',
                },
                {
                  label: '(12) Responsible Consumption & Production',
                  value: 'responsibleConsumptionAndProduction',
                },
                { label: '(13) Climate Action', value: 'climateAction' },
                { label: '(14) Life Below Water', value: 'lifeBelowWater' },
                { label: '(15) Life on Land', value: 'lifeOnLand' },
                {
                  label: '(16) Peace & Justice Strong Institutions',
                  value: 'peaceJusticeAndStrongInstitutions',
                },
                {
                  label: '(17) Partnerships to Achieve the Goal',
                  value: 'partnershipsForTheGoals',
                },
              ]}
            />
            <Field
              name="sdgTargets"
              label="SDG Targets"
              component={FieldTextInput}
              validate={onlyNumberLowercaseAndCommaValidator}
            />
            <Field
              name="sdgIndicators"
              label="SDG Indicators"
              component={FieldTextInput}
              validate={onlyNumberLowercaseAndCommaValidator}
            />
          </FormRow>

          <ButtonGroup>
            <UIButton title="Cancel" modifiers="secondary" handleClick={() => go(-1)} />
            <UIButton title="Save" modifiers="secondary" handleClick={handleSubmit} />
          </ButtonGroup>
        </FormBody>
      )}
    />
  );
};

const TooltipLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconTooltipBlue = styled(IconTooltip)`
  margin-left: 6px;

  svg {
    width: 14px;
    height: 14px;
  }

  svg * {
    fill: var(--blue);
  }
`;

export default PartnerDataIntakeForm;
