import React, { useCallback } from 'react';
import useDropdown from 'use-dropdown';
import { ReactSVG } from 'react-svg';
import ArrowDownIcon from 'assets/images/icons/arrow-down.svg';
import CloseIcon from 'assets/images/icons/close-toast.svg';
import { SingleSelectProps, SelectOption } from './interfaces';
import styled from 'styled-components';

const SingleSelect = ({
  options,
  placeholder,
  value,
  handleSelect,
  disabled = false,
  isClearable = true,
  error,
  onBlur,
  className,
}: SingleSelectProps) => {
  const [containerRef, isOpen, open, close] = useDropdown();

  const selectedOption =
    options.find((option: SelectOption) => option.value === value) ||
    ({} as typeof options[0]);

  const { label } = selectedOption;

  const addItem = (item: SelectOption) => {
    handleSelect(item);
    close();
  };

  const toggleDropdown = useCallback(() => {
    if (disabled) return;

    if (isOpen) {
      close();
    } else {
      open();
    }
  }, [disabled, isOpen, open, close]);

  return (
    <Root className={`uiSelect__wrapper ${className}`} ref={containerRef}>
      <Trigger
        onClick={toggleDropdown}
        open={isOpen}
        disabled={disabled}
        error={error}
        tabIndex={-1}
        onBlur={() => onBlur?.()}
        title={label}
      >
        {label ? (
          <Value disabled={disabled}>{label}</Value>
        ) : (
          <Placeholder disabled={disabled}>{placeholder}</Placeholder>
        )}

        <ArrowWrapper>
          {isClearable && value != null && value !== '' && (
            <Clear
              src={CloseIcon}
              onClick={(e: Event) => {
                e.stopPropagation();
                if (disabled) {
                  return;
                }
                handleSelect(null);
              }}
            />
          )}
          <Arrow src={ArrowDownIcon} open={isOpen} disabled={disabled} />
        </ArrowWrapper>
      </Trigger>
      {isOpen && (
        <ListWrapper>
          {options && options.length > 0 ? (
            <ul>
              {options
                .filter(item => !(item.value === selectedOption.value))
                .map((item, index) => {
                  const { label: optionLabel } = item;
                  return (
                    <Item key={index} onClick={() => addItem(item)}>
                      {optionLabel}
                    </Item>
                  );
                })}
            </ul>
          ) : (
            <p className="uiSelect__empty">Empty list</p>
          )}
        </ListWrapper>
      )}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  position: relative;

  .uiSelect__empty {
    padding: 20px 10px;
    text-align: center;
  }
`;

const Trigger = styled.div<{
  open?: boolean;
  disabled?: boolean;
  error?: SingleSelectProps['error'];
}>`
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  ${({ open }) =>
    open && `border-radius: 8px 8px 0 0; border-bottom: 1px solid transparent;`}
  ${({ disabled }) =>
    disabled && `background: #F9F9F9; border-color: #F9F9F9; cursor: not-allowed;`}
  ${({ error }) =>
    error &&
    `
      background: #FFF2F2;
      border: 1px solid var(--red);
      &:focus{
        border: 1px solid var(--red);
      }
    `}
`;

const Arrow = styled(ReactSVG)<{ open?: boolean; disabled?: boolean }>`
  transition: all 0.2s ease;
  ${({ open }) => open && `transform: rotate(-180deg);`}
  ${({ disabled }) => disabled && `svg path { stroke: #BFBFBF }`}
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: 6px;
`;

const Clear = styled(ReactSVG)`
  & svg {
    path {
      stroke: var(--black);
    }
  }
`;

const Value = styled.p<{ disabled?: boolean }>`
  font-size: 16px;
  line-height: 150%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ disabled }) => disabled && `color: #BFBFBF;`}
`;

const Placeholder = styled(Value)<{ disabled?: boolean }>`
  color: var(--darkGray);
  ${({ disabled }) => disabled && `color: #BFBFBF;`}
`;

const ListWrapper = styled.div`
  max-width: 100%;
  max-height: 180px;
  background: #ffffff;
  position: absolute;
  z-index: 9;
  width: 100%;
  top: 45px;
  left: 0;
  overflow-y: auto;
  border: 1px solid #e8e8e8;
  border-top: 0;
  border-radius: 0px 0px 8px 8px;
`;

const Item = styled.li`
  font-size: 16px;
  line-height: 150%;
  padding: 10px 16px;
  transition: all 0.2s ease;
  &:hover {
    cursor: pointer;
    background: #e9f9ff;
  }
`;

export default SingleSelect;
