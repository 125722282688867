/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { useSelector } from 'react-redux';
import { fetchLastLogins, fetchSentPitch } from 'store/ducks/team/operations';
import { getLastLogins, getSentPitch, getTeam } from 'store/ducks/team/selectors';

import { StateProps } from 'store/interfaces';

import { LastLoginData } from 'interfaces/team';
import LastLoginItem from './LastLoginItem';

import ArrowRight from 'assets/images/icons/arrow-right.svg';
import { SearchCompany } from 'interfaces/companies';
import EmptyCompany from 'assets/images/icons/empty-company.svg';
import SentPitchModal from 'components/Modals/SentPitchModal';
import { useModal, useOperation } from 'hooks/common';
import { Link } from 'react-router-dom';
import { UILoadAvatar } from 'components/UI';

interface Props {
  topMatch: SearchCompany[];
}

const Reports = ({ topMatch }: Props) => {
  const [onFetchSentPitch] = useOperation(fetchSentPitch);
  const { meta } = useSelector(getSentPitch);

  const [showModal, hideModal] = useModal(() => (
    <SentPitchModal
      onClose={() => {
        onFetchSentPitch({ page: 1, limit: 12 });
        hideModal();
      }}
    />
  ));
  const [onLastLogins] = useOperation(fetchLastLogins);
  const lastLogins = useSelector<StateProps, LastLoginData[]>(getLastLogins);
  const team = useSelector(getTeam);

  useEffect(() => {
    onLastLogins(undefined);
    onFetchSentPitch({ page: 1, limit: 12 });
  }, []);

  return (
    <Root>
      <Header>
        <HeaderSection>
          <Title>Prospecting Sessions</Title>
          <HeaderSectionInner>
            <HeaderSectionTitle>Total</HeaderSectionTitle>
            <HeaderSectionValue>{team?.savedListCount}</HeaderSectionValue>
          </HeaderSectionInner>
        </HeaderSection>

        <HeaderSection>
          <Title>Sent Pitches</Title>
          <HeaderSectionInner isClick={true} onClick={() => showModal()}>
            <HeaderSectionTitle>Company</HeaderSectionTitle>
            {/* <HeaderSectionValue>{team.pitchCount}</HeaderSectionValue> */}
            <HeaderSectionValue>{meta.totalItems}</HeaderSectionValue>
          </HeaderSectionInner>
        </HeaderSection>
      </Header>

      <Title>AcceleristU</Title>
      <ProspectNavigator href="https://blog.accelerist.com/acceleristu/" target="_blank">
        <ProspectNavigatorTitle>Go to page</ProspectNavigatorTitle>
        <ReactSVG src={ArrowRight} />
      </ProspectNavigator>
      <Title>Top Matched</Title>

      <MatchedList>
        {topMatch.map(item => (
          <MatchedItem key={item.id} to={`/company/${item.id}`}>
            {item.logo ? (
              <LoadLogo imgKey={item.logo} />
            ) : (
              <MatchedNoLogo>
                <ReactSVG src={EmptyCompany} />
                <NoLogoName>{item.name}</NoLogoName>
              </MatchedNoLogo>
            )}
          </MatchedItem>
        ))}
      </MatchedList>

      <Title>Last Login</Title>

      <LastLoginWrapper>
        {lastLogins &&
          lastLogins.length > 0 &&
          lastLogins.map((item: LastLoginData) => {
            return <LastLoginItem key={item.id} item={item} />;
          })}
      </LastLoginWrapper>
    </Root>
  );
};

const Root = styled.section`
  width: 536px;
  min-height: 516px;
  background: #ffffff;
  border-radius: 6px;
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const HeaderSection = styled.div`
  width: calc(50% - 9px);
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 16px;
`;

const HeaderSectionInner = styled.div<{ isClick?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  border-radius: 4px;
  padding: 5px 0;

  ${props => (props.isClick ? 'cursor: pointer;' : null)}
`;

const HeaderSectionTitle = styled.div`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
  margin-bottom: 8px;
`;

const HeaderSectionValue = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
`;

const MatchedList = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const MatchedItem = styled(Link)`
  width: 83px;
  height: 83px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  &:not(:last-child) {
    margin-right: 12px;
  }
`;

const MatchedNoLogo = styled.div`
  height: 100%;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
  }
`;

const LoadLogo = styled(UILoadAvatar)`
  width: 83px;
  height: 83px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const NoLogoName = styled.div`
  display: -webkit-box;
  width: 100%;
  text-align: center;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0 4px;
  height: calc(1.15em * 2);
`;

const LastLoginWrapper = styled.div`
  font-size: 12px;
  line-height: 150%;
`;

const ProspectNavigator = styled.a`
  display: flex;
  justify-content: space-between;
  padding: 24px;
  height: 71px;
  margin-bottom: 24px;
  background: #f9f9f9;
  border-radius: 4px;
  & div {
    display: flex;
    align-items: center;
  }
`;

const ProspectNavigatorTitle = styled.p`
  font-size: 16px;
  line-height: 155%;
  color: var(--black);
`;

export default Reports;
