import { ActionType, createReducer } from 'typesafe-actions';

import { SavedListsDataTeams } from 'interfaces/prospects';

import * as types from './types';
import * as actions from './actions';

type RootAction = ActionType<typeof actions>;

export default createReducer<SavedListsDataTeams, RootAction>({
  savedLists: {
    items: [],
    links: {},
    meta: {
      currentPage: '',
      itemCount: 0,
      itemsPerPage: '',
      totalItems: 0,
      totalPages: 0,
    },
    gameplanInfo: { totalGamePlanCount: '', totalGamePlanOpenCount: '' },
  },
  suggestSavedLists: { items: [], meta: {} },
  batch: [],
  batchTeam: {
    items: [],
    meta: {
      currentPage: '',
      itemCount: 0,
      itemsPerPage: '',
      totalItems: 0,
      totalPages: 0,
    },
  },
})
  .handleType([types.SET_SAVED_LISTS], (state, action) => ({
    ...state,
    savedLists: action.payload,
  }))

  .handleType([types.SET_SAVED_LIST], (state, action) => {
    const list = action.payload;
    if (state.savedLists.items.find(({ id }) => id === list.id))
      return {
        ...state,
        savedLists: {
          ...state.savedLists,
          items: state.savedLists.items.map(item => {
            if (item.id !== list.id) return item;

            return {
              ...item,
              ...list,
            };
          }),
        },
      };

    return {
      ...state,
      savedLists: {
        ...state.savedLists,
        items: [list, ...state.savedLists.items],
      },
    };
  })
  .handleType([types.REMOVE_SAVED_LIST], (state, action) => {
    const listId = action.payload;
    if (state.savedLists.items.find(({ id }) => id === listId))
      return {
        ...state,
        savedLists: {
          ...state.savedLists,
          items: state.savedLists.items.filter(({ id }) => id !== listId),
        },
      };

    return {
      ...state,
      savedLists: {
        ...state.savedLists,
      },
    };
  })
  .handleType([types.SET_SUGGEST_SAVED_LIST], (state, action) => ({
    ...state,
    suggestSavedLists: action.payload,
  }))
  // .handleType([types.SET_BATCH], (state, action) => ({
  //   ...state,
  //   batch: action.payload,
  // }))
  .handleType([types.SET_BATCH_TEAM], (state, action) => ({
    ...state,
    batchTeam: action.payload,
  }));
