import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

import styled from 'styled-components';

interface CircleChartProps {
  className?: string;
  value: number;
}

const CircleThinChart = ({ className, value }: CircleChartProps) => {
  const data = [{ value }, { value: 100 - value }];
  return (
    <Root className={className}>
      <PieChart width={140} height={140}>
        <text x={70} y={72} textAnchor="middle" fill="#122434">
          {`${value}%`}
        </text>
        <Pie
          data={[{ value: 100 }]}
          dataKey="value"
          cx={65}
          cy={65}
          innerRadius={30}
          outerRadius={36}
          fill="#e8e8e8"
          isAnimationActive={false}
        />
        <Pie
          data={data}
          cx={65}
          cy={65}
          innerRadius={30}
          outerRadius={36}
          fill="#e8e8e8"
          dataKey="value"
        >
          <Cell fill={value === 100 ? '#2baee0' : '#8acbe4'} />;
          <Cell fill="transparent" />;
        </Pie>
      </PieChart>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default CircleThinChart;
