import { GivingStoreData } from 'interfaces/giving';
import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as types from './types';

type RootAction = ActionType<typeof actions>;

export default createReducer<GivingStoreData, RootAction>({
  givingList: {
    items: [],
    meta: {
      currentPage: '',
      itemCount: 0,
      itemsPerPage: '',
      totalItems: 0,
      totalPages: 0,
    },
    wallet: '',
  },
  batchList: [],
})
  .handleType([types.SET_GIVING_LIST], (state, action) => ({
    ...state,
    givingList: action.payload,
  }))
  .handleType([types.SET_BATCH_LIST], (state, action) => ({
    ...state,
    batchList: action.payload,
  }));
