import React, { useState } from 'react';
import styled from 'styled-components';
import { format, isValid } from 'date-fns';
import { Scoops, News, ResCompanyMatch } from 'interfaces/companies';
import UITag from 'components/UI/UITag';
import { UIActionText } from 'components/UI';
import { ReactSVG } from 'react-svg';
import ShowLessSVG from 'assets/images/icons/show-less.svg';
import ShowMoreSVG from 'assets/images/icons/show-more.svg';

export interface CompanySideBarProps {
  news: News[];
  scoops?: Scoops[];
  matchs?: ResCompanyMatch['matchItems'];
  organizationName?: string;
}

const CompanySideBar = ({ news, matchs, organizationName }: CompanySideBarProps) => {
  const [isHide, setIsHide] = useState(true);

  return (
    <Root>
      {matchs && matchs.length > 0 && (
        <>
          <Title>Matched with {organizationName}</Title>
          <Tags>
            {matchs.map(item => (
              <UITag key={item.name} title={item.name} />
            ))}
          </Tags>
        </>
      )}

      {news && (
        <>
          <Title>
            <div>News</div>
            {news.length > 3 &&
              (isHide ? (
                <ActionText onClick={() => setIsHide(false)}>
                  <ReactSVG src={ShowMoreSVG} /> View More
                </ActionText>
              ) : (
                <ActionText onClick={() => setIsHide(true)}>
                  <ReactSVG src={ShowLessSVG} /> View Less
                </ActionText>
              ))}
          </Title>

          <ul>
            {news.slice(0, isHide ? 3 : news.length).map((news: News) => {
              const {
                id,
                imageUrl,
                url,
                title,
                pageDate,
                content,
                author,
                siteFrom,
              } = news;
              const date =
                pageDate &&
                isValid(new Date(pageDate)) &&
                format(new Date(pageDate), 'MMM dd, yyyy');
              return (
                <NewsItem key={id}>
                  <ImgWrapper>
                    <NewsImage
                      style={{
                        backgroundImage: `url(${imageUrl})`,
                      }}
                    />
                  </ImgWrapper>
                  <NewsInfo>
                    <NewsTitle href={url} target="_blank">
                      {title}
                    </NewsTitle>
                    <NewsContent>{content}</NewsContent>
                    <NewsContentGray>{author && `By ${author}`}</NewsContentGray>
                    <NewsContentGray>{date || '-'}</NewsContentGray>
                    <FlexBetween>
                      <NewsContent>{siteFrom && `News from ${siteFrom}`}</NewsContent>
                      <FlexBetween></FlexBetween>
                    </FlexBetween>
                  </NewsInfo>
                </NewsItem>
              );
            })}
          </ul>
        </>
      )}
    </Root>
  );
};
const NewsInfo = styled.div``;

const ImgWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
  min-width: 72px;
  height: 187px;
  border-radius: 8px;
  overflow: hidden;
`;

const NewsItem = styled.li`
  font-size: 12px;
  line-height: 150%;
  &:not(:last-child) {
    padding-bottom: 12px;
    margin-bottom: 16px;
    border-bottom: 1px solid #e8e8e8;
  }
`;

const NewsImage = styled.div`
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-size: cover;
`;

const NewsTitle = styled.a`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
  display: inline-block;
  &:hover {
    text-decoration: underline;
  }
`;

const NewsContent = styled.div`
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
`;

const NewsContentGray = styled(NewsContent)`
  color: var(--darkGray);
`;

const Root = styled.div`
  padding: 32px 40px;
  width: 100%;
  max-width: 366px;
  min-width: 296px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActionText = styled(UIActionText)`
  font-weight: normal;
  font-size: 12px;
  color: var(--black);
  display: flex;
  line-height: 150%;

  & > *:first-child {
    margin-right: 4px;
    margin-left: 8px;
  }
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default CompanySideBar;
