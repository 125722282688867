import {
  SearchCompany,
  Companies,
  CompanyContact,
  Scoops,
  News,
  ResCompanyMatch,
  SimilaritySearchCompany,
  AcceptInviteData,
  UploadSearchCompaniesRes,
  CompaniesContacts,
  SubscriptionCompany,
  UpdateCompanyProfileRes,
  CompaniesOnlyIdName,
  ReqSupportEmail,
} from 'interfaces/companies';
import {
  CustomizeFilterRequestProps,
  PaginationReq,
  SearCompaniesRequestProps,
} from 'interfaces/common';
import { fetch } from 'services/ApiService';
import { paramsToQueryString } from 'utils';
import Axios from 'axios';
import { HTTPRequestStatusEnum } from 'constants/common';
import StorageService from 'services/Storage';
import { User } from 'interfaces/auth';

export const fetchCompaniesSearch = (q: string) =>
  fetch<SearchCompany[]>(
    'get',
    `companies/suggested${paramsToQueryString({
      q,
    })}`,
  );

export const fetchCompaniesSearchNoContact = (q: string, teamId: string) =>
  fetch<SearchCompany[]>(
    'get',
    `companies/suggestedNoContact/${teamId}${paramsToQueryString({
      q,
    })}`,
  );

export const fetchCompany = (id: string) =>
  fetch<SearchCompany>('get', `companies/${id}`);

export const fetchCompanyScoops = (id: string) =>
  fetch<Scoops[]>('get', `companies/${id}/scoops`);

export const fetchCompanyNews = (id: string) =>
  fetch<News[]>('get', `companies/${id}/news`);

export const fetchCompanies = (params: SearCompaniesRequestProps) => {
  if (fetchCompanies['source'] && fetchCompanies['source'].cancel) {
    fetchCompanies['source'].cancel(HTTPRequestStatusEnum.RE_REQUEST);
  }
  fetchCompanies['source'] = Axios.CancelToken.source();

  return fetch<Companies | CompaniesContacts>(
    'get',
    `companies${paramsToQueryString({ ...params, limit: 12 })}`,
    undefined,
    undefined,
    fetchCompanies['source'].token,
  );
};

export const fetchCompanyContacts = (id: string) =>
  fetch<CompanyContact[]>('get', `companies/${id}/contacts`);

export const fetchContactPitch = (contactIds: string[]) =>
  fetch('post', `companies/pitchContact`, { payload: { contactIds } });

export const fetchExcelCompanies = (query: SearCompaniesRequestProps) =>
  fetch<{ name: string; file: string }>(
    'get',
    `companies/excel${paramsToQueryString(query)}`,
  );

export const fetchCompanyLike = (id: string) => fetch('get', `companies/${id}/like`);

export const fetchCompanyDisLike = (id: string) =>
  fetch('get', `companies/${id}/dislike`);

export const fetchCompaniesFavorites = (page: number, limit = 12) =>
  fetch<Companies>(
    'get',
    `companies/favorites${paramsToQueryString({
      page,
      limit,
    })}`,
  );

export const fetchCompaniesBlocked = (page: number, limit = 12) =>
  fetch<Companies>(
    'get',
    `companies/blocks${paramsToQueryString({
      page,
      limit,
    })}`,
  );

export const fetchCompanyBlockState = (id: string) =>
  fetch<boolean>('get', `companies/${id}/block`);

export const fetchCompanyBlock = (id: string) =>
  fetch('post', `companies/block`, {
    payload: { companyId: id, block: true },
  });

export const fetchCompanyUnlock = (id: string) =>
  fetch('post', `companies/block`, {
    payload: { companyId: id, block: false },
  });

export const fetchCompaniesTopMatch = () =>
  fetch<SearchCompany[]>('get', `companies/topmatch`);

export const fetchCustomizeFilter = (data: CustomizeFilterRequestProps) =>
  fetch<Companies | CompaniesContacts>('post', 'companies/customizefilter', {
    payload: data,
  });
export const fetchCustomizeFilterIds = (data: CustomizeFilterRequestProps) =>
  fetch<CompaniesOnlyIdName>('post', 'companies/customizefilterIds', {
    payload: data,
  });

export const fetchCompanyMatchDetail = (companyId: string) =>
  fetch<ResCompanyMatch>('post', 'companies/matchDetail', { payload: { companyId } });

export const fetchCompanyMeeting = (companyId: string) =>
  fetch<boolean>('get', `companies/${companyId}/meeting`);

export const fetchCompanyDisMeeting = (companyId: string) =>
  fetch<boolean>('get', `companies/${companyId}/disMeeting`);

export const fetchCompanyPartnership = (companyId: string) =>
  fetch<boolean>('get', `companies/${companyId}/partnership`);

export const fetchCompanyDisPartnership = (companyId: string) =>
  fetch<boolean>('get', `companies/${companyId}/dispartnership`);

export const fetchCompanyCurrent = () => fetch<SearchCompany>('get', 'companies/current');

export const fetchCompanyCurrentUpdate = (data: SearchCompany) =>
  fetch<SearchCompany>('patch', `companies/current`, {
    payload: data,
  });

export const fetchCompanyCurrentLogoUpload = (file: File) => {
  const data = new FormData();
  data.append('file', file, file?.name);

  return fetch<{ status: string }>(
    'post',
    `companies/current/logo_upload`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  );
};

export const fetchSimilaritySearchCompany = (q: string) =>
  fetch<SimilaritySearchCompany[]>(
    'get',
    `companies/similaritysearch${paramsToQueryString({
      q,
    })}`,
  );

export const fetchCompanyCurrentContacts = () =>
  fetch<CompanyContact[]>('get', `companies/current/contacts`);

export const fetchCompanyCurrentContactsCreate = (contact: CompanyContact) =>
  fetch<CompanyContact>('post', `companies/current/contacts`, { payload: contact });

export const fetchCompanyCurrentContactsUpdate = (contact: CompanyContact) =>
  fetch<CompanyContact>('patch', `companies/current/contacts`, { payload: contact });

export const fetchCompanyCurrentContactsDelete = (contactId: string) =>
  fetch<CompanyContact>('delete', `companies/current/contacts/${contactId}`);

export const fetchAddTeamCompanyMembers = (email: string) =>
  fetch('post', `companies/members`, {
    payload: { email },
  });

export const fetchInviteLinkTeamCompany = () => fetch('post', 'companies/invite_link');

export const fetchAcceptInviteCompany = ({ id, token }: AcceptInviteData) => {
  fetch('post', `companies/${id}/invites/accept`, undefined, {
    Authorization: `Bearer ${token}`,
  });
};

export const fetchTeamCompanyMembers = () => fetch<User[]>('get', `companies/members`);

export const fetchDeleteTeamCompanyMembers = (id: string) =>
  fetch('delete', `companies/members/${id}`);

export const fetchDownloadCompanyTemplate = () =>
  fetch<{ name: string; file: string }>('get', 'companies/downLoadTemplate');

export const fetchUploadCompanyXLSX = (file: File): Promise<UploadSearchCompaniesRes> => {
  const data = new FormData();
  data.append('file', file, file?.name);

  return fetch<UploadSearchCompaniesRes>(
    'post',
    `companies/uploadSearchCompany`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  );
};

export const fetchLastWeekCompanies = (lastDate: string): Promise<string[]> =>
  fetch('get', `companies/last_week_companies${paramsToQueryString({ lastDate })}`);

export const fetchCompaniesByIds = (ids: string[]) =>
  fetch<{ id: string; name: string }[]>(
    'get',
    `companies/getCompaniesByIds${paramsToQueryString({ ids })}`,
  );

export const fetchLastMonthCompanies = (params: PaginationReq) =>
  fetch<Companies>(
    'get',
    `companies/getLastMonthCompanies${paramsToQueryString(params)}`,
  );

export const fetchSubscriptionCompany = () =>
  fetch<SubscriptionCompany>('get', `companies/subscription`);

export const fetchUpdateCompanyProfile = (
  companyId: string,
): Promise<UpdateCompanyProfileRes> =>
  Promise.allSettled([
    // fetch<Required<SearchCompany>['typesOfInvestment']>(
    //   'get',
    //   `companies/${companyId}/typesOfInvestment`,
    // ),
    fetch<{
      crsFocus: Required<SearchCompany>['crsFocus'];
      sdgGoals: Required<SearchCompany>['sdgGoals'];
    }>('get', `companies/${companyId}/update-crs`),
  ]).then(([crs]) => {
    return {
      id: companyId,
      // typesOfInvestment:
      //   typesOfInvestment.status === 'fulfilled' ? typesOfInvestment.value : undefined,
      crsFocus: crs.status === 'fulfilled' ? crs.value.crsFocus : undefined,
      sdgGoals: crs.status === 'fulfilled' ? crs.value.sdgGoals : undefined,
    };
  });

export const fetchUpdateEnrich = (companyIds: string[]) =>
  fetch<string[]>('post', 'companies/update-enrich', { payload: companyIds });

export const fetchSupportEmail = (params: ReqSupportEmail) =>
  fetch('post', 'companies/support-email', { payload: params });
