import { ActionType, createReducer } from 'typesafe-actions';

import { GameplanData, GameplanTemplateSingle } from 'interfaces/gameplan';

import * as types from './types';
import * as actions from './actions';
import { GamePlanEmailTemplateTypeEnum } from 'constants/gamePlan';

type RootAction = ActionType<typeof actions>;

export default createReducer<GameplanData, RootAction>({
  savelistContacts: [],
  newGameplan: undefined,
  gameplans: [],
  gameplan: undefined,
  gameplanStatus: undefined,
  gameplansByTeam: undefined,
  suggestGameplans: { items: [], meta: {} },
  customizeTemplate: undefined,
  customizeTemplatePreview: undefined,
  emailAnalyticsContacts: [],
})
  .handleType([types.SET_SAVELIST_CONTACTS], (state, action) => ({
    ...state,
    savelistContacts: action.payload,
  }))
  .handleType([types.SET_NEW_GAMEPLAN], (state, action) => ({
    ...state,
    newGameplan: action.payload,
  }))
  .handleType([types.SET_GAMEPLANS], (state, action) => ({
    ...state,
    gameplans: action.payload,
  }))
  .handleType([types.DELETE_GAMEPLANS_ITEM], (state, action) => ({
    ...state,
    gameplans: state.gameplans.filter(item => item.id !== action.payload),
  }))
  .handleType([types.SET_GAMEPLAN], (state, action) => ({
    ...state,
    gameplan: action.payload,
  }))
  .handleType([types.SET_GAMEPLAN_NAME_AND_CLOSE], (state, action) => ({
    ...state,
    gameplans: state.gameplans.map(item => {
      if (item.id === action.payload.id) {
        return { ...item, ...action.payload };
      }
      return item;
    }),
  }))
  .handleType([types.SET_GAMEPLAN_NAME_AND_CLOSE_SINGLE], (state, action) => ({
    ...state,
    gameplan: state.gameplan && {
      ...state.gameplan,
      id: action.payload.id,
      name: action.payload.name,
      isClose: action.payload.isClose,
    },
  }))
  .handleType([types.DELETE_GAMEPLAN_CONTACT], (state, action) => ({
    ...state,
    gameplan: state.gameplan && {
      ...state.gameplan,
      gamePlanContacts: state.gameplan.gamePlanContacts.filter(
        item => item.id !== action.payload,
      ),
    },
  }))
  .handleType([types.SET_GAMEPLAN_STATUS], (state, action) => ({
    ...state,
    gameplanStatus: action.payload,
  }))
  .handleType([types.SET_GAMEPLANS_BY_TEAM], (state, action) => ({
    ...state,
    gameplansByTeam: action.payload,
  }))
  .handleType([types.SET_GAMEPLAN_NAME_AND_CLOSE_BY_TEAM], (state, action) => ({
    ...state,
    gameplansByTeam: state.gameplansByTeam && {
      ...state.gameplansByTeam,
      items: state.gameplansByTeam.items.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            id: action.payload.id,
            name: action.payload.name,
            isClose: action.payload.isClose,
          };
        }
        return item;
      }),
    },
  }))
  .handleType([types.SET_SUGGEST_GAMEPLANS], (state, action) => ({
    ...state,
    suggestGameplans: action.payload,
  }))
  .handleType([types.SET_GAMEPLAN_CUSTOMIZE_TEMPLATE], (state, action) => ({
    ...state,
    customizeTemplate: action.payload,
  }))
  .handleType([types.SET_EMAIL_CUSTOMIZE_TEMPLATE], (state, action) => ({
    ...state,
    customizeEmailTemplate: action.payload,
  }))
  .handleType([types.SET_GAMEPLAN_CUSTOMIZE_TEMPLATE_PREVIEW], (state, action) => ({
    ...state,
    customizeTemplatePreview: action.payload,
  }))
  .handleType([types.SET_NEW_GAMEPLAN_TEMPLATES], (state, action) => ({
    ...state,
    newGameplan: state.newGameplan && {
      ...state.newGameplan,
      gamePlanTemplates: state.newGameplan.gamePlanTemplates.map((item, i) => {
        switch (action.payload.templateType) {
          case GamePlanEmailTemplateTypeEnum.GetToKnowYou:
            if (i === 0) {
              return {
                ...item,
                body: action.payload.templatePreview.body,
                subject: action.payload.templatePreview.subject,
              };
            }
            return item;
          case GamePlanEmailTemplateTypeEnum.BriefFollowUp:
            if (i === 1) {
              return {
                ...item,
                body: action.payload.templatePreview.body,
                subject: action.payload.templatePreview.subject,
              };
            }
            return item;
          case GamePlanEmailTemplateTypeEnum.MissionContent:
            if (i === 2) {
              return {
                ...item,
                body: action.payload.templatePreview.body,
                subject: action.payload.templatePreview.subject,
              };
            }
            return item;
          default:
            return item;
        }
      }) as GameplanTemplateSingle[],
    },
  }))
  .handleType([types.SET_NEW_GAMEPLAN_TEMPLATES_BY_FORM], (state, action) => ({
    ...state,
    newGameplan: state.newGameplan && {
      ...state.newGameplan,
      gamePlanTemplates: action.payload.gamePlanTemplates,
    },
  }))
  .handleType([types.SET_GAMEPLAN_EMAIL_ANALYTICS_CONTACTS], (state, action) => ({
    ...state,
    emailAnalyticsContacts: action.payload,
  }));
