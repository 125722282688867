import { useModal, useOperation } from 'hooks/common';
import queryString from 'query-string';
import React, { useEffect, useState, ChangeEvent } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { FormRow } from 'components/UI/form';
import { Field } from 'components/FinalForm';
import { FieldSingleSelect, FieldTextArea, FieldTextInput } from 'components/FormFields';
import { Campaigns_COLORS } from 'constants/common';
import { OnChange } from 'react-final-form-listeners';
import { Form } from 'react-final-form';
import { GiveATWorkData } from 'interfaces/fundraising';
import { FieldWrapper } from 'components/roi/forms/styled';
import UploadFileArea from 'components/UploadFileArea';
import {
  CAMPAIN_IMAGE_FILE_EXTENSIONS,
  maxLength,
  notEmptyArray,
  maxLength150,
  webURLSite,
} from 'utils/validation';
import { uploadFile } from 'store/ducks/utility/api';
import Toast from 'services/Toast';
import { UIButton, UICheckbox, UIModal } from 'components/UI';
import UIAsterisk from 'components/UI/UIAsterisk';
import { composeValidators, getQueryString } from 'utils';
import { getFilenameByPath } from 'utils/formatters';
import {
  fetchAtWorkConfig,
  fetchAtworkPartnerPreviewUrl,
  fetchAtworkPartnersList,
  fetchGiveShopInfo,
  saveATWorkConfig,
  updateAtworkPartners,
} from 'store/ducks/campaign/operations';
import { headParTable } from 'interfaces/campaign';
import PartnersListInfo from 'components/Campaign/PartnersListInfo';
import { email } from 'utils/validation';
import { ReactSVG } from 'react-svg';
import AddSVG from 'assets/images/icons/blue-add-icon.svg';
import createDecorator from 'final-form-focus';
import { ContentBlockWrapper } from 'layouts';
import Jumbotron from 'components/Jumbotron';
import { FormApi, ValidationErrors } from 'final-form';
import { useSelector } from 'react-redux';
import {
  getAtworkPartnerPreviewUrl,
  getDonationInfo,
} from 'store/ducks/campaign/selectors';
import warnError from 'assets/images/icons/warning-error.svg';
import FieldColorGroupPickerForEmployeeFundraisign from './components/FieldColorsPickerForEmployeeFundraising';
import { format } from 'date-fns';
import { getUTCAsLocalDate } from 'utils';

const focusOnError = createDecorator();

const GiveATWork = () => {
  const { location, push } = useHistory();
  const initPage = 1;
  const campaignId = getQueryString('campaignId');
  const tabType = getQueryString('type');

  const [getAtWorkConfigInfo, loading] = useOperation(fetchAtWorkConfig);
  const [onFetchCampaign, giveAndShopLoading] = useOperation(fetchGiveShopInfo);
  const [submitWorkConfigInfo, , submitLoading] = useOperation(saveATWorkConfig);
  const [getPartnersList] = useOperation(fetchAtworkPartnersList);
  const [saveUpdatePartners] = useOperation(updateAtworkPartners);
  const [onFetchAtworkPartnerPreviewUrl] = useOperation(fetchAtworkPartnerPreviewUrl);
  const [isLoading, setLoading] = useState(false);
  const [actionArray, setActionArray] = useState<number[]>([initPage]);
  const [contentArray, setContentArray] = useState<number[]>([initPage]);
  const [activeTab, setActiveTab] = useState('Donation_Request');
  const [detailsInfo, setDetailsInfo] = useState<any>(undefined);
  const [partnersInfo, setPartnerInfo] = useState<any>({});
  const [selectedPartnerIds, setSelectedPartnerIds] = useState<string[]>([]);
  const [parLoading, setParLoading] = useState(false);
  const [outerActiveTab, setOuterActiveTab] = useState(tabType);
  const [formVules, setFormValue] = useState<GiveATWorkData>();
  const [isDontShowDonate, setisDontShowDonate] = useState(false);
  const atworkPartnerPreviewUrl = useSelector(getAtworkPartnerPreviewUrl);
  const giveandShopDetails = useSelector(getDonationInfo);
  const [updatedat, setUpdatedat] = useState(new Date());
  const [uploadedLogoOfPartners, setUploadedLogoOfPartners] = useState<
    { id: string; fileKey: string }[]
  >([]);

  const [isShowResidue, setIsShowResidue] = useState(false);
  const [character, setCharacter] = useState(0);

  const [isShowResidue1, setIsShowResidue1] = useState(false);
  const [character1, setCharacter1] = useState(0);

  const [isShowResidue2, setIsShowResidue2] = useState(false);
  const [character2, setCharacter2] = useState(0);

  const getInfo = () => {
    getAtWorkConfigInfo(campaignId).then((e: any) => {
      const info = e.data;
      if (!info) return;
      if (e['data']?.updatedAt) {
        setUpdatedat(e['data']?.updatedAt);
      }
      if (!info.selectCampaignFont) info.selectCampaignFont = 'Rubik';
      const actionNums = [2, 3];
      const contentNums = [2, 3, 4, 5];
      const newAction: number[] = [1];
      const newCon: number[] = [1];
      const checkActionshasValue = (num: number, data: any) =>
        [
          `subject${num}`,
          `body${num}`,
          `buttonText${num}`,
          `buttonURL${num}`,
          `imageName${num}`,
        ].some(item => data[item]);

      const checkContenthasValue = (num: number, data: any) =>
        [
          `content${num}Subject`,
          `content${num}Body`,
          `content${num}ButtonLabel`,
          `content${num}ButtonURL`,
          `content${num}ImageName`,
        ].some(item => data[item]);
      actionNums.forEach(num => {
        if (checkActionshasValue(num, info)) newAction.push(num);
        info[`subject${num}Status`] = 0;
      });
      contentNums.forEach(num => {
        if (checkContenthasValue(num, info)) newCon.push(num);
        info[`content${num}Status`] = 0;
      });
      if (e) setDetailsInfo(e.data);
      setActionArray(newAction);
      setContentArray(newCon);
      setisDontShowDonate(info.donationButtonVisibility == 0 ? true : false);
    });
  };

  const getCamParListInfo = (page: number) => {
    getPartnersList({ page, campaignId }).then((ele: any) => {
      setPartnerInfo(ele);
      const data: string[] = [];
      ele.items.forEach((i: any) => {
        if (i.selected === 1) data.push(i.id);
      });
      setSelectedPartnerIds(data);
    });
  };

  useEffect(() => {
    getInfo();
    getCamParListInfo(initPage);
    onFetchAtworkPartnerPreviewUrl(campaignId);
    onFetchCampaign(campaignId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callBackEvent = () => {
    push('/campaign?type=Manage_Campaigns');
  };

  const saveSelectParInfo = () => {
    setParLoading(true);
    // // const ids = selectedPartnerIds.join(',');
    // saveUpdatePartners({ campaignId, selectedIds: ids, status: detailsInfo.status }).then(
    //   (e: any) => {
    //     if (e.status === 200) Toast.success('Publish Successfully.');
    //     if (e) {
    //       setParLoading(false);
    //       callBackEvent();
    //     }
    //   },
    // );
    saveUpdatePartners({
      campaignId,
      partners: selectedPartnerIds.map(item => ({
        partnerId: item,
        partnerLogo:
          uploadedLogoOfPartners.find(uItem => uItem.id === item)?.fileKey ||
          (partnersInfo &&
            partnersInfo.items.find(pItem => pItem.id === item)?.partnerLogo),
      })),
    }).then((e: any) => {
      setParLoading(false);
      if (e?.status !== 200) {
        // Toast.error('Error save Partners. Missing PPGF information of NPO.');
        // Toast.error(e?.msg);
        return;
      } else {
        Toast.success('The changes have been saved');
      }

      if (e) {
        callBackEvent();
      }
    });
  };

  const handleUploadLogoOfParnter = (id: string, fileKey: string) => {
    setUploadedLogoOfPartners([...uploadedLogoOfPartners, { id, fileKey }]);
  };

  const addEventHTML = (
    form: FormApi<any, Partial<any>>,
    values: GiveATWorkData,
    array: number[],
    setArray: (nums: number[]) => void,
    callType: 'action' | 'content',
  ) => (
    <>
      {array.map(num => {
        const subjectField =
          callType === 'action' ? `subject${num}` : `content${num}Subject`;
        const bodyField = callType === 'action' ? `body${num}` : `content${num}Body`;
        const buttonTextField =
          callType === 'action' ? `buttonText${num}` : `content${num}ButtonLabel`;
        const buttonUrlField =
          callType === 'action' ? `buttonURL${num}` : `content${num}ButtonURL`;
        const imageField =
          callType === 'action' ? `imageName${num}` : `content${num}ImageName`;

        const maxContentLength = callType === 'action' ? 2 : 4;
        const deleteButtonText =
          callType === 'action' ? 'Delete Call to Action' : 'Delete Content';
        return (
          <FormStyled key={num}>
            <div
              style={{ color: 'Black', fontWeight: 500, fontSize: 16, marginBottom: 18 }}
            >
              {callType === 'action' ? `Call to Action 0${num}` : `Content 0${num}`}
            </div>
            <Field
              name={subjectField}
              label={
                <>
                  Subject
                  <UIAsterisk />
                </>
              }
              component={FieldTextInput}
              placeholder="Enter Subject"
              // disabled={detailsInfo?.status === 2}
              validate={maxLength(200)}
              onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.value) {
                  setCharacter(200 - e.target.value.length);
                } else {
                  setCharacter(200);
                }
                setIsShowResidue(true);
              }}
              onBlur={() => {
                setIsShowResidue(false);
              }}
              parse={(v: string) => {
                setCharacter(200 - v.length < 0 ? 0 : 200 - v.length);
                if (v.length >= 200) {
                  return v.substring(0, 200);
                }
                return v;
              }}
            />
            {isShowResidue && (
              <Residue>
                <div></div>
                <div style={{ color: '#656469', fontSize: '12px' }}>
                  {character} characters remaining
                </div>
              </Residue>
            )}

            <OnChange name={subjectField}>
              {(value, previous) => {
                if (value === previous) return;
                if (value && num !== 1) {
                  if (callType === 'action') form.change(`subject${num}Status`, 1);
                  if (callType !== 'action') form.change(`content${num}Status`, 1);
                }
              }}
            </OnChange>
            <Field
              name={bodyField}
              label="Body"
              component={FieldTextArea}
              placeholder="Maxium limit: 150 characters"
              // disabled={detailsInfo?.status === 2}
              validate={composeValidators<string>(maxLength150)}
              onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.value) {
                  setCharacter1(150 - e.target.value.length);
                } else {
                  setCharacter1(150);
                }
                setIsShowResidue1(true);
              }}
              onBlur={() => {
                setIsShowResidue1(false);
              }}
              parse={(v: string) => {
                setCharacter1(150 - v.length < 0 ? 0 : 150 - v.length);
                if (v.length >= 150) {
                  return v.substring(0, 150);
                }
                return v;
              }}
            />
            {isShowResidue1 && (
              <Residue>
                <div></div>
                <div style={{ color: '#656469', fontSize: '12px' }}>
                  {character1} characters remaining
                </div>
              </Residue>
            )}
            <FormRow>
              <Field
                name={buttonTextField}
                label={
                  <>
                    Button Text
                    <UIAsterisk />
                  </>
                }
                component={FieldTextInput}
                placeholder="Enter Button Text"
                // disabled={detailsInfo?.status === 2}
              />
              <Field
                name={buttonUrlField}
                label={
                  <>
                    Button URL
                    <UIAsterisk />
                  </>
                }
                component={FieldTextInput}
                validate={composeValidators<string>(webURLSite)}
                placeholder="Enter Button URL"
                // disabled={detailsInfo?.status === 2}
              />
            </FormRow>
            <FieldTitle>Image</FieldTitle>
            <UploadFileArea
              name="Upload File"
              accept=".jpg,.jpeg,.png,.bmp,.svg"
              inputId={`give-${callType}-${num}-image`}
              filelist={
                (values[imageField] && [
                  {
                    label: getFilenameByPath(values[imageField]),
                    value: values[imageField],
                  },
                ]) ||
                undefined
              }
              desc="You may upload an image in *.png,*.jpeg,*.jpg,*.bmp,*.svg. File size limit is 5 Mb."
              onUpload={files => {
                if (!files.length) return;
                const file = files[0];
                const validationResult = CAMPAIN_IMAGE_FILE_EXTENSIONS.includes(
                  file.name
                    .split('.')
                    .pop()
                    ?.toLowerCase() || '',
                );
                if (!validationResult) return false;
                uploadFile(files[0], 'give-work').then(v => {
                  if (v.status === 'success') {
                    form.change(imageField, v.fileKey);
                    return Toast.success('File uploaded successfully');
                  }
                  Toast.error('Error upload file');
                });
              }}
              onDeleteFile={() => {
                form.change(imageField, '');
              }}
              // disabled={detailsInfo?.status === 2}
            />
            <ActionContentButtons>
              {array.length === num && array.length <= maxContentLength && (
                <ActionContentButton
                  modifiers="secondary"
                  title={
                    <AddBtnIcon>
                      <ReactSVG src={AddSVG} style={{ marginRight: 8 }} />
                      {callType === 'action'
                        ? 'Add Another Call to Action'
                        : 'Add Another Content'}
                    </AddBtnIcon>
                  }
                  handleClick={() => {
                    if (array.length > maxContentLength)
                      return Toast.warn(
                        callType === 'action'
                          ? 'Up to 3 different CTAs.'
                          : 'up to 5 different Content tiles.',
                      );
                    setArray([...array, array.length + 1]);
                  }}
                  // disabled={detailsInfo?.status === 2}
                />
              )}
              {array.length !== 1 && (
                <ActionContentButton
                  modifiers="danger"
                  title={deleteButtonText}
                  handleClick={() => {
                    const fields = [
                      subjectField,
                      bodyField,
                      buttonTextField,
                      buttonUrlField,
                      imageField,
                    ];

                    for (let index = num; index <= maxContentLength + 1; index++) {
                      const fieldsCurr = fields.map(item =>
                        item.replace(/(\d)/, String(index)),
                      );
                      const fieldsNext = fields.map(item =>
                        item.replace(/(\d)/, String(index + 1)),
                      );
                      fieldsCurr.forEach((item, fi) => {
                        if (num === maxContentLength + 1) return form.change(item, '');
                        form.change(item, values[fieldsNext[fi]]);
                        form.change(fieldsNext[fi], '');
                      });
                    }

                    setArray(array.slice(0, array.length - 1));
                  }}
                />
              )}
            </ActionContentButtons>
          </FormStyled>
        );
      })}
    </>
  );

  const list = [
    { label: 'Configure Donation Request', value: 'Donation_Request' },
    { label: 'Call to Action', value: 'Call_Action' },
    { label: 'Content', value: 'Content' },
    { label: 'Social Media', value: 'Social_Media' },
    // { label: 'Partners', value: 'Partners' },
  ];

  const outerList = [
    { label: 'Configure Employee Fundraising', value: 'Fundraising' },
    { label: 'Manage Partners', value: 'Partners' },
    // { label: 'Preview Campaign', value: 'Preview' },
  ];

  const submitConfigInfo = (values: GiveATWorkData) => {
    values = Object.fromEntries(
      Object.entries(values).map(([key, value]) => [key, value === null ? '' : value]),
    ) as GiveATWorkData;
    setLoading(true);
    // values.type 1: Save as Draft 2: submit
    const params = new URLSearchParams();
    params.append('id', campaignId);
    if (values?.selectCampaignType !== Campaigns_COLORS.Custom_colors.title) {
      Object.values(Campaigns_COLORS).forEach(eles => {
        if (eles.title === values.selectCampaignType) {
          values.selectCampaignColors = eles.value;
        }
      });
    }
    Object.keys(values).forEach(ele => {
      params.append(ele, values[`${ele}`]);
    });
    submitWorkConfigInfo(params).then(e => {
      if (e?.status === 200) {
        Toast.success('Saved successfully.');
        getInfo();
        values.type === '2' && values.status === 2 && callBackEvent();
      }
      if (e) setLoading(false);
      if (e?.status !== 200)
        Toast.error('Please fill the required fields on all the Tabs before submit.');
      if (values.type == '2') {
        if (values.status == 0 || values.status == 1) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          showPublishModal();
        }
      }
    });
  };
  const [showPublishModal, hidePublishShopModal] = useModal(() => {
    return (
      <UIModal
        onClose={hidePublishShopModal}
        hideCloseIcon={true}
        propsBodyCss={{ padding: '40px 24px 24px' }}
        style={{
          width: '100%',
          maxWidth: 333,
        }}
      >
        <Title>Employee Fundraising Campaign Published</Title>
        <Description className="description">
          You will now be redirected to the Manage Partners page where you will need to
          add at least 1 fundraising partner.
        </Description>
        <ButtonGroup>
          <Button
            style={{ width: '105px' }}
            handleClick={hidePublishShopModal}
            modifiers={'secondary'}
            title={'Close'}
          />
          <Button
            style={{ width: '93px' }}
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            handleClick={goToManagePartners}
            modifiers={'primary'}
            title={'OK'}
          />
        </ButtonGroup>
      </UIModal>
    );
  }, [formVules]);

  const goToManagePartners = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    hidePublishShopModal();
    const ulr = window.location.href;
    const newUrl = ulr.replace('Fundraising', 'Partners');
    window.location.href = newUrl;
  };

  const [showSubmitModal, hideSubmitShopModal] = useModal(() => {
    return (
      <UIModal
        onClose={hideSubmitShopModal}
        propsBodyCss={{ padding: '40px 0' }}
        hideCloseIcon
        style={{
          width: '100%',
          maxWidth: '516px',
          overflow: 'unset',
        }}
      >
        <ConfirmDiv>
          <HintText>
            Once submitted, you will not be able to edit this campaign further. Please
            confirm all information is correct and you wish you proceed.
          </HintText>
          <BtnGroup>
            <Btn modifiers="secondary" title="Cancel" handleClick={hideSubmitShopModal} />
            <Btn
              modifiers="primary"
              title="Publish"
              handleClick={() => {
                if (!formVules) return;
                submitConfigInfo(formVules);
                hideSubmitShopModal();
              }}
            />
          </BtnGroup>
        </ConfirmDiv>
      </UIModal>
    );
  }, [formVules]);

  const switchOuterSphereTab = key => {
    setOuterActiveTab(key);
    push(
      `${location.pathname}?${queryString.stringify({
        type: key,
        campaignId,
      })}`,
    );
  };

  const focusErrorField = (
    errors: ValidationErrors,
    setActiveTab: (tabKey: string) => void,
    submit?: () => void,
  ) => {
    if (!errors || !Object.keys(errors).length) return submit?.();

    const errorFields = Object.keys(errors);
    const donationRequestFields = [
      'selectCampaignFont',
      'selectCampaignColors',
      'headerSubject',
      'purposeButtonText',
      'headerVideoURL',
      'purposeButtonURL',
      'headerBody',
      'purpose',
      'headerBackgroundImageName',
      'purposeImageName',
      'donateButtonText',
      'footerContact',
      'footerInfo',
    ];
    const callActionFields = ['subject', 'body', 'buttonText', 'buttonURL', 'imageName'];
    const contentFields = ['content'];
    const socialMediaFields = ['facebook', 'linkedIn', 'twitter', 'instagram'];

    const changeActiveTab = (key: string) => {
      setActiveTab(key);
      // TODO: REMOVE SUBMIT
      setTimeout(() => submit?.());
    };

    const checkErrorInFields = (fields: string[]) =>
      fields.some(item => errorFields.some(errItem => errItem.includes(item)));

    if (checkErrorInFields(donationRequestFields))
      return changeActiveTab('Donation_Request');

    if (checkErrorInFields(callActionFields)) return changeActiveTab('Call_Action');

    if (checkErrorInFields(contentFields)) return changeActiveTab('Content');

    if (checkErrorInFields(socialMediaFields)) return changeActiveTab('Social_Media');
  };

  const BtnGroupHTML = (
    values: GiveATWorkData,
    form: FormApi<any>,
    key: string,
    errors: ValidationErrors,
  ) => (
    <BtnGroup>
      <Btn
        modifiers="secondary"
        title="Cancel"
        handleClick={() => {
          form.reset();
          callBackEvent();
        }}
      />
      <div>
        <Btn
          modifiers="secondary"
          title="Preview"
          handleClick={() => window.open(atworkPartnerPreviewUrl)}
          disabled={values.status === 0}
        />
        {detailsInfo?.status != 2 && (
          <Btn
            modifiers="secondary"
            title="Save"
            isLoading={isLoading}
            handleClick={() => {
              form.change('type', '1');
              focusErrorField(errors, setActiveTab, form.submit);
              // form.submit();
            }}
            // disabled={detailsInfo?.status === 2 || giveandShopDetails?.status !== 2}
            disabled={giveandShopDetails?.status !== 2}
          />
        )}

        {key !== 'Donation_Request' && (
          <Btn
            modifiers="secondary"
            title="Next"
            handleClick={() => {
              setActiveTab(key);
            }}
          />
        )}

        <Btn
          modifiers="primary"
          // title="Publish"
          title={detailsInfo?.status === 2 ? 'Republish' : 'Publish'}
          isLoading={isLoading}
          type="submit"
          handleClick={() => {
            form.change('type', '2');
            focusErrorField(errors, setActiveTab, form.submit);
          }}
          // disabled={detailsInfo?.status === 2 || giveandShopDetails?.status !== 2}
          disabled={giveandShopDetails?.status !== 2}
        />
      </div>
    </BtnGroup>
  );

  return (
    <ContentBlockWrapper
      header={
        <Jumbotron
          name="Employee Fundraising"
          back
          backPath="/campaign?type=Manage_Campaigns"
        />
      }
      loading={loading || giveAndShopLoading}
    >
      <Body>
        {giveandShopDetails?.status !== 2 && (
          <EinContent style={{ background: '#F2DAD4' }}>
            <ReactSVG style={{ marginRight: 16 }} src={warnError} />
            Before saving and publishing the Employee Fundraising, you must publish the
            Consumer Fundraising first.
          </EinContent>
        )}

        <Root style={{ marginBottom: 32 }}>
          <List>
            {outerList.map(({ label, value }, index) => {
              if (value === 'Partners' && detailsInfo?.status !== 2) return false;
              return (
                <Item key={index} onClick={() => switchOuterSphereTab(value)}>
                  <StyledLink $isActive={outerActiveTab === value}>{label}</StyledLink>
                </Item>
              );
            })}
            <LastUpdate>
              <span style={{ fontSize: '12px', color: '#737373' }}>Last Update </span>
              <span style={{ fontSize: '12px', color: '#122434' }}>
                &nbsp;
                {/* {format(getUTCAsLocalDate(updatedat), 'MMM dd yyyy')} */}
                {format(new Date(updatedat), 'MMM dd yyyy')}
              </span>
            </LastUpdate>
          </List>
        </Root>
        {outerActiveTab === 'Fundraising' && (
          <Form
            initialValues={detailsInfo}
            onSubmit={(values: GiveATWorkData) => {
              values.donationButtonVisibility = isDontShowDonate ? '0' : '1';
              values.selectCampaignType = Campaigns_COLORS.Custom_colors.title;
              if (values.type === '1') {
                return submitConfigInfo(values);
              }

              const requiredFields = [
                'headerSubject',
                'headerBody',
                'donateButtonText',
                'footerContact',
                'footerInfo',
                ...actionArray.map(item => `subject${item}`),
                ...actionArray.map(item => `buttonText${item}`),
                ...actionArray.map(item => `buttonURL${item}`),
                ...contentArray.map(item => `content${item}Subject`),
                ...contentArray.map(item => `content${item}ButtonLabel`),
                ...contentArray.map(item => `content${item}ButtonURL`),
              ];

              if (isDontShowDonate) {
                const index = requiredFields.indexOf('donateButtonText');
                if (index > -1) {
                  requiredFields.splice(index, 1);
                }
              }

              const validateErrors: { [k: string]: string } = {};
              requiredFields.forEach(item => {
                if (!values[item]) validateErrors[item] = 'Required';
              });

              const selectCampaignColorsNotEmpty = notEmptyArray(2)(
                values.selectCampaignColors,
              );
              if (selectCampaignColorsNotEmpty) {
                validateErrors['selectCampaignColors'] = selectCampaignColorsNotEmpty;
              }

              if (Object.keys(validateErrors).length) {
                focusErrorField(validateErrors, setActiveTab);
                return validateErrors;
              }
              console.log('submit valus:', values);
              setFormValue(values);
              // showSubmitModal();
              submitConfigInfo(values);
            }}
            decorators={[focusOnError]}
            render={({ form, values, errors }) => {
              const disable = false;
              return (
                <form onSubmit={e => e.preventDefault()}>
                  <>
                    <Root>
                      <List>
                        {list.map(({ label, value }, index) => {
                          if (label === 'Partners' && detailsInfo?.status !== 2)
                            return false;
                          return (
                            <Item
                              key={index}
                              onClick={() => {
                                setActiveTab(value);
                              }}
                            >
                              <StyledLink $isActive={activeTab === value}>
                                {label}
                              </StyledLink>
                            </Item>
                          );
                        })}
                      </List>
                    </Root>

                    <TabContentDiv
                      style={{
                        display: activeTab === 'Donation_Request' ? 'block' : 'none',
                      }}
                    >
                      <FormStyled>
                        <FormRow>
                          <Field
                            label={
                              <>
                                Select Campaign Colors
                                <UIAsterisk />
                              </>
                            }
                            name="selectCampaignColors"
                            component={FieldColorGroupPickerForEmployeeFundraisign}
                            // disabled={disable}
                          />

                          <Field
                            name="selectCampaignFont"
                            label="Select Campaign Font"
                            component={FieldSingleSelect}
                            options={[
                              { label: 'Rubik', value: 'Rubik' },
                              { label: 'Arial', value: 'Arial' },
                              { label: 'Helvetica', value: 'Helvetica' },
                              { label: 'Times New Roman', value: 'Times New Roman' },
                              { label: 'Verdana', value: 'Verdana' },
                              { label: 'Calibri', value: 'Calibri' },
                              { label: 'Lato', value: 'Lato' },
                              { label: 'Roboto', value: 'Roboto' },
                              {
                                label: 'Roboto Condensed',
                                value: 'Roboto Condensed',
                              },
                              { label: 'Merriweather', value: 'Merriweather' },
                              { label: 'Nunito', value: 'Nunito' },
                            ]}
                            // disabled={disable}
                          />
                        </FormRow>
                        <FormRow>
                          <Field
                            name="headerSubject"
                            label={
                              <>
                                Header Subject
                                <UIAsterisk />
                              </>
                            }
                            component={FieldTextInput}
                            placeholder="Enter Header Subject"
                            // disabled={disable}
                            autoFocus={true}
                            validate={maxLength(200)}
                            onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value) {
                                setCharacter(200 - e.target.value.length);
                              } else {
                                setCharacter(200);
                              }
                              setIsShowResidue(true);
                            }}
                            onBlur={() => {
                              setIsShowResidue(false);
                            }}
                            parse={(v: string) => {
                              setCharacter(200 - v.length < 0 ? 0 : 200 - v.length);
                              if (v.length >= 200) {
                                return v.substring(0, 200);
                              }
                              return v;
                            }}
                          />
                          <Field
                            name="purposeButtonText"
                            label="Purpose Button Text"
                            component={FieldTextInput}
                            placeholder="Enter Purpose Button Text "
                            // disabled={disable}
                          />
                          {isShowResidue && (
                            <Residue>
                              <div></div>
                              <div style={{ color: '#656469', fontSize: '12px' }}>
                                {character} characters remaining
                              </div>
                            </Residue>
                          )}
                        </FormRow>
                        <FormRow>
                          <Field
                            name="headerVideoURL"
                            label="Header Video URL"
                            component={FieldTextInput}
                            validate={composeValidators<string>(webURLSite)}
                            placeholder="Enter a valid URL"
                            // disabled={disable}
                          />
                          <Field
                            name="purposeButtonURL"
                            label="Purpose Button URL"
                            component={FieldTextInput}
                            validate={composeValidators<string>(webURLSite)}
                            placeholder="Enter a valid URL"
                            // disabled={disable}
                          />
                        </FormRow>
                        <FormRow>
                          <Field
                            name="headerBody"
                            label={
                              <>
                                Header Body
                                <UIAsterisk />
                              </>
                            }
                            component={FieldTextArea}
                            // disabled={disable}
                          />
                          <Field
                            name="purpose"
                            label="Purpose"
                            component={FieldTextArea}
                            // disabled={disable}
                          />
                        </FormRow>
                        <FormRow>
                          <FieldWrapper>
                            <FieldTitle>Header Background Image</FieldTitle>
                            <UploadFileArea
                              name="Upload File"
                              accept=".jpg,.jpeg,.png,.bmp,.svg,.mp4"
                              inputId="give-work-header"
                              filelist={
                                values.headerBackgroundImageName
                                  ? [
                                      {
                                        label: getFilenameByPath(
                                          values.headerBackgroundImageName,
                                        ),
                                        value: values.headerBackgroundImageName,
                                      },
                                    ]
                                  : undefined
                              }
                              desc="You may upload an image in *.png,*.jpeg,*.jpg,*.bmp,*.svg. File size limit is 5 Mb."
                              onUpload={files => {
                                if (!files.length) return;
                                const file = files[0];
                                const validationResult = CAMPAIN_IMAGE_FILE_EXTENSIONS.includes(
                                  file.name
                                    .split('.')
                                    .pop()
                                    ?.toLowerCase() || '',
                                );
                                if (!validationResult) return false;
                                uploadFile(files[0], 'give-work').then(v => {
                                  if (v.status === 'success') {
                                    form.change('headerBackgroundImageName', v.fileKey);
                                    return Toast.success('File uploaded successfully');
                                  }
                                  Toast.error('The uploaded file must be an image.');
                                });
                              }}
                              onDeleteFile={() => {
                                form.change('headerBackgroundImageName', '');
                              }}
                              itemWidth={92}
                              descWidth={80}
                              // disabled={disable}
                            />
                          </FieldWrapper>
                          <FieldWrapper>
                            <FieldTitle>Purpose Image</FieldTitle>
                            <UploadFileArea
                              name="Upload File"
                              accept=".jpg,.jpeg,.png,.bmp,.svg,.mp4"
                              inputId="give-work-purpose-image"
                              filelist={
                                values.purposeImageName
                                  ? [
                                      {
                                        label: getFilenameByPath(values.purposeImageName),
                                        value: values.purposeImageName,
                                      },
                                    ]
                                  : undefined
                              }
                              desc="You may upload an image in *.png,*.jpeg,*.jpg,*.bmp,*.svg. File size limit is 5 Mb."
                              onUpload={files => {
                                if (!files.length) return;
                                const file = files[0];
                                const validationResult = CAMPAIN_IMAGE_FILE_EXTENSIONS.includes(
                                  file.name
                                    .split('.')
                                    .pop()
                                    ?.toLowerCase() || '',
                                );
                                if (!validationResult) return false;
                                uploadFile(files[0], 'give-work').then(v => {
                                  if (v.status === 'success') {
                                    // form.change('customContentType', files[0].type);
                                    form.change('purposeImageName', v.fileKey);
                                    return Toast.success('File uploaded successfully');
                                  }
                                  Toast.error('The uploaded file must be an image.');
                                });
                              }}
                              onDeleteFile={() => {
                                // form.change('customContentType', '');
                                form.change('purposeImageName', '');
                              }}
                              itemWidth={92}
                              descWidth={80}
                              // disabled={disable}
                            />
                          </FieldWrapper>
                        </FormRow>
                        <FormRow>
                          <Field
                            name="donateButtonText"
                            label={
                              <>
                                Donate Button Text
                                <UIAsterisk />
                                <Checkbox
                                  label="Not Show Donate Button"
                                  check={isDontShowDonate}
                                  handleClick={() => {
                                    form.getFieldState('donateButtonText')?.change('');
                                    setisDontShowDonate(
                                      isDontShowDonate => !isDontShowDonate,
                                    );
                                  }}
                                  // disabled={disable}
                                />
                              </>
                            }
                            component={FieldTextInput}
                            placeholder="Enter Donate Button Text"
                            // disabled={disable || isDontShowDonate}
                            disabled={isDontShowDonate}
                            validate={maxLength(20)}
                            onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value) {
                                setCharacter1(20 - e.target.value.length);
                              } else {
                                setCharacter1(20);
                              }
                              setIsShowResidue1(true);
                            }}
                            onBlur={() => {
                              setIsShowResidue1(false);
                            }}
                            parse={(v: string) => {
                              setCharacter1(20 - v.length < 0 ? 0 : 20 - v.length);
                              if (v.length >= 20) {
                                return v.substring(0, 20);
                              }
                              return v;
                            }}
                          />

                          <Field
                            name="footerContact"
                            label={
                              <>
                                Footer Contact
                                <UIAsterisk />
                              </>
                            }
                            component={FieldTextInput}
                            placeholder="Enter a valid email address"
                            validate={composeValidators<string>(email)}
                            // disabled={disable}
                          />
                          {isShowResidue1 && (
                            <Residue>
                              <div></div>
                              <div style={{ color: '#656469', fontSize: '12px' }}>
                                {character1} characters remaining
                              </div>
                            </Residue>
                          )}
                        </FormRow>

                        <div style={{ height: '30px' }}></div>
                        <Field
                          name="footerInfo"
                          label={
                            <>
                              Footer Info
                              <UIAsterisk />
                            </>
                          }
                          component={FieldTextArea}
                          placeholder="Maxium limit: 150 characters"
                          // disabled={disable}
                          validate={composeValidators<string>(maxLength150)}
                          onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value) {
                              setCharacter2(150 - e.target.value.length);
                            } else {
                              setCharacter2(150);
                            }
                            setIsShowResidue2(true);
                          }}
                          onBlur={() => {
                            setIsShowResidue2(false);
                          }}
                          parse={(v: string) => {
                            setCharacter2(150 - v.length < 0 ? 0 : 150 - v.length);
                            if (v.length >= 150) {
                              return v.substring(0, 150);
                            }
                            return v;
                          }}
                        />
                        {isShowResidue2 && (
                          <Residue2>
                            <div></div>
                            <div style={{ color: '#656469', fontSize: '12px' }}>
                              {character2} characters remaining
                            </div>
                          </Residue2>
                        )}
                      </FormStyled>
                      {BtnGroupHTML(values, form, 'Call_Action', errors)}
                    </TabContentDiv>
                    <TabContentDiv
                      style={{
                        display: activeTab === 'Call_Action' ? 'block' : 'none',
                      }}
                    >
                      {addEventHTML(form, values, actionArray, setActionArray, 'action')}
                      {BtnGroupHTML(values, form, 'Content', errors)}
                    </TabContentDiv>
                    <TabContentDiv
                      style={{
                        display: activeTab === 'Content' ? 'block' : 'none',
                      }}
                    >
                      {addEventHTML(
                        form,
                        values,
                        contentArray,
                        setContentArray,
                        'content',
                      )}
                      {BtnGroupHTML(values, form, 'Social_Media', errors)}
                    </TabContentDiv>
                    <TabContentDiv
                      style={{
                        display: activeTab === 'Social_Media' ? 'block' : 'none',
                      }}
                    >
                      <FormStyled>
                        <Field
                          name="facebook"
                          label="Facebook"
                          component={FieldTextInput}
                          validate={composeValidators<string>(webURLSite)}
                          placeholder="Enter URL"
                          // disabled={disable}
                        />
                        <Field
                          name="linkedIn"
                          label="LinkedIn"
                          component={FieldTextInput}
                          validate={composeValidators<string>(webURLSite)}
                          placeholder="Enter URL"
                          // disabled={disable}
                        />
                        <Field
                          name="twitter"
                          label="Twitter"
                          component={FieldTextInput}
                          validate={composeValidators<string>(webURLSite)}
                          placeholder="Enter URL"
                          // disabled={disable}
                        />
                        <Field
                          name="instagram"
                          label="Instagram"
                          component={FieldTextInput}
                          validate={composeValidators<string>(webURLSite)}
                          placeholder="Enter URL"
                          // disabled={disable}
                        />
                      </FormStyled>
                      {BtnGroupHTML(values, form, 'Donation_Request', errors)}
                    </TabContentDiv>
                  </>
                </form>
              );
            }}
          />
        )}
        {outerActiveTab === 'Partners' && (
          <TabContentDiv>
            <PartnersListInfo
              // isPreview={isDisabled}
              callCamParListInfo={getCamParListInfo}
              onPartnerSelect={ids => setSelectedPartnerIds(ids)}
              selectedPartnerIds={selectedPartnerIds}
              headCells={headParTable}
              rows={partnersInfo?.items || []}
              callType="atWork"
              onUploadLogo={handleUploadLogoOfParnter}
            />
            <BtnGroup>
              <Btn
                modifiers="secondary"
                title="Cancel"
                handleClick={() => {
                  callBackEvent();
                }}
              />
              <div>
                <Btn
                  modifiers="primary"
                  title="Save"
                  isLoading={parLoading}
                  handleClick={() => {
                    saveSelectParInfo();
                  }}
                />
              </div>
            </BtnGroup>
          </TabContentDiv>
        )}
      </Body>
    </ContentBlockWrapper>
  );
};
const Root = styled.div`
  display: flex;
  width: 100%;
`;

const List = styled.ul`
  display: flex;
  height: 69px;
  width: 100%;
  background: #fff;
  border-radius: 6px;
  padding: 0 24px;
`;
const Item = styled.li`
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 22px;
  }
`;

const StyledLink = styled.div<{
  $isActive: boolean;
}>`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 155%;
  border-bottom: 3px solid transparent;
  transition: border-color 0.5s ease, color 0.5s ease;

  ${({ $isActive }) =>
    $isActive &&
    `
    color: var(--blue);
    border-color: var(--blue);
  `}

  &:hover {
    color: var(--blue);
    border-color: var(--blue);
  }
`;

const Body = styled.div`
  max-width: 1120px;
  padding-bottom: 64px;
`;
const FormStyled = styled.div`
  /* background: #ffffff; */
  border-radius: 4px;
`;
const FieldTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 145%;
  color: #737373;
  margin: 0px auto 4px;
  font-family: 'Rubik';
`;

const TabContentDiv = styled.div`
  background: #fff;
  border-radius: 6px;
  padding: 24px;
  /* margin-top: 24px; */
  border-radius: 6px;
`;
const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding: 0;

  & > div {
    button:not(:first-child) {
      margin-left: 12px;
    }
  }
`;

const ActionContentButtons = styled.div`
  display: flex;
  margin-bottom: 16px;

  & > button:not(:first-child) {
    margin-left: 16px;
  }
`;

const ActionContentButton = styled(UIButton)`
  font-size: 12px;
`;

const Btn = styled(UIButton)`
  font-size: 14px;
  padding: 7px 24px;
`;

const AddBtnIcon = styled.div`
  display: flex;
  align-items: center;
`;
const ConfirmDiv = styled.div`
  text-align: center;
  width: 86%;
  margin: auto;
`;

const HintText = styled.div`
  margin-bottom: 68px;
`;

const EinContent = styled.div`
  background: #dbf2fb;
  border-radius: 6px;
  margin-bottom: 12px;
  height: 85px;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-left: 24px;
`;

const Checkbox = styled(UICheckbox)`
  position: absolute;
  bottom: -40px;
`;

const LastUpdate = styled.div`
  position: absolute;
  margin-left: 919px;
  margin-top: 24px;
`;

const Description = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  width: 256px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 25px;
  margin-left: 15px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #122434;
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;

  button:first-child {
    margin-right: 8px;
  }
`;
const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  width: 234px;
  margin: 0 auto 8px;
  text-align: center;
  color: Black;
`;
const Button = styled(UIButton)`
  height: 40x;
  font-size: 14px;
  font-weight: 400;
  padding: 7px 0px;
`;

const Residue = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -20px;
  height: 20px;
`;

const Residue2 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -24px;
  height: 0px;
`;
export default GiveATWork;
