import { fetch } from 'services/ApiService';
import {
  AcceptInviteData,
  TeamProfileData,
  SubscriptionProps,
  LastLoginData,
  SentPitch,
  SearchTeamsReq,
  SearchTeamsRes,
  CustomizeFilterTeamsRequestProps,
  UploadSearchTeamRes,
  ImpactGenome,
  UploadTeamXLSXRes,
  UploadSearchTeamReq,
  UploadNoContactReq,
  UploadNoContactRes,
  UploadNoContactXLSXRes,
} from 'interfaces/team';
import StorageService from 'services/Storage';
import FormData from 'form-data';
import { paramsToQueryString } from 'utils';
import { News } from 'interfaces/companies';
import {
  EmailDesignGetReq,
  EmailDesignGetRes,
  SendGridTemplate,
} from 'interfaces/emailDesign';
import { User } from 'interfaces/auth';
import { NoContact, TemplateEmailPreview, TemplatePreview } from 'interfaces/common';

export const fetchTeam = () => fetch<TeamProfileData>('get', `team`);

export const fetchLastLogins = () => fetch<LastLoginData[]>('get', `team/last_logins`);

export const fetchSubscriptionTeams = () =>
  fetch<SubscriptionProps>('get', `team/subscription`);

export const fetchTeamUpdate = (
  data: Omit<TeamProfileData, 'charitablePartners' | 'teamPartnerCompanies'> & {
    charitablePartners: string[];
    teamPartnerCompanies: { companyId: string; companyName: string }[];
  },
) =>
  fetch<TeamProfileData>('patch', `team`, {
    payload: data,
  });

export const fetchAddTeamMembers = (email: string) =>
  fetch('post', `team/members`, {
    payload: { email },
  });

interface Company {
  id: string;
  name?: string;
  website?: string;
  country?: string;
}

export const fetchAddNoContact = (company: Company) =>
  fetch('post', `team/noContact`, {
    payload: company,
  });

export const fetchInviteLinkTeam = () => fetch('post', 'team/invite_link');

export const fetchAcceptInvite = ({ id, token }: AcceptInviteData) =>
  fetch('post', `team/${id}/invites/accept`, undefined, {
    Authorization: `Bearer ${token}`,
  });

export const fetchTeamLogoUpload = (file: File) => {
  const data = new FormData() as any;
  data.append('file', file, file?.name);

  return fetch(
    'put',
    `team/logo_upload`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    },
  );
};

export const fetchTeamMembers = () => fetch<User[]>('get', `team/members`);

export const fetchEmailTemplates = (teamId: string) =>
  fetch<TemplateEmailPreview[]>('get', `savedEmailTemplate/emailTemplates/${teamId}`);

export const fetchNoContact = (teamId: string) =>
  fetch<NoContact[]>('get', `team/noContact/${teamId}`);

export const fetchNoContactCompany = (companyId: string) =>
  fetch<NoContact[]>('get', `team/noContactCompany/${companyId}`);

export const deleteNoContact = (id: string) =>
  fetch<string>('post', 'team/noContact/delete', {
    payload: id,
  });

export const fetchDeleteTeamMembers = (id: string) =>
  fetch('delete', `team/members/${id}`);

export const fetchSentPitch = (page: number, limit: number, q?: string) =>
  fetch<SentPitch>(
    'get',
    `team/pitchHistory${paramsToQueryString({
      page,
      limit,
      q,
    })}`,
  );

export const fetchTeams = (params: SearchTeamsReq) =>
  fetch<SearchTeamsRes>('get', `team/search${paramsToQueryString(params)}`);

export const fetchSearchTeamsWithEin = (params: SearchTeamsReq) =>
  fetch<SearchTeamsRes>('get', `team/searchTeamsWithEin${paramsToQueryString(params)}`);

export const fetchTeamById = (id: string) => fetch<TeamProfileData>('get', `team/${id}`);

export const fetchTeamsCustomizeFilter = (data: CustomizeFilterTeamsRequestProps) =>
  fetch<SearchTeamsRes>('post', 'team/customizefilter', { payload: data });

export const fetchDownloadTeamTemplate = () =>
  fetch<{ name: string; file: string }>('get', 'team/downLoadTemplate');

export const fetchDownloadNoContactTemplate = () =>
  fetch<{ name: string; file: string }>('get', 'team/downloadNoContactTemplate');

export const fetchUploadTeamXLSX = (file: File) => {
  const data = new FormData();
  data.append('file', file, file?.name);
  // const queryParams = new URLSearchParams({ name });

  return fetch<UploadTeamXLSXRes>(
    'post',
    `team/uploadSearchTeam`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  );
};

export const fetchSaveTeamXLSX = (
  payload: UploadSearchTeamReq,
): Promise<UploadSearchTeamRes> => {
  return fetch<UploadSearchTeamRes>('post', `team/saveSearchTeam`, {
    payload,
  });
};

export const fetchNoContactXLSX = (file: File) => {
  const data = new FormData();
  data.append('file', file, file?.name);

  return fetch<UploadNoContactXLSXRes>(
    'post',
    `team/uploadNoContactImport`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  );
};

export const fetchSaveNoContactXLSX = (
  payload: UploadNoContactReq,
): Promise<UploadNoContactRes> => {
  return fetch<UploadNoContactRes>('post', `team/noContactImport`, {
    payload,
  });
};

export const fetchTeamNews = (id: string) => fetch<News[]>('get', `team/${id}/news`);

export const fetchDownLoadPitchHistory = () =>
  fetch<{ name: string; file: string }>('get', `team/downLoadPitchHistory`);

export const fetchTeamNamesByIds = (ids: string[]) =>
  fetch<{ id: string; organizationName: string }[]>(
    'get',
    `team/getTeamsByIds${paramsToQueryString({ ids })}`,
  );

export const fetchEmailDesign = (params: EmailDesignGetReq) =>
  fetch<EmailDesignGetRes>('get', `sendGridTemplate${paramsToQueryString(params)}`);

export const fetchEmailDesignByTemplateType = (
  templateType: EmailDesignGetReq['templateType'],
) => fetch<SendGridTemplate>('get', `sendGridTemplate/byTemplateType${{ templateType }}`);

export const fetchEmailDesignUpdate = (params: SendGridTemplate) =>
  fetch<SendGridTemplate>('patch', `sendGridTemplate/${params.id}`, {
    payload: params,
  });

export const fetchEmailDesignCreate = (params: SendGridTemplate) =>
  fetch<SendGridTemplate>('post', `sendGridTemplate`, {
    payload: params,
  });

export const fetchPayPalGivingFundCanUpdate = () =>
  fetch<boolean>('get', 'campaign/judgeEin/ifPublish');
// fetch<boolean>('get', 'team/canUpdatePayPalGivingFund');

export const fetchPayPalGivingFundIfConfirm = () =>
  fetch<boolean>('get', 'campaign/judgeEin/ifConfirm');

export const fetchImpactGenome = () => fetch<ImpactGenome[]>('get', 'team/impactGenome');
