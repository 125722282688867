import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import Modal from 'react-modal';
import AppRouter from 'routes';
import configureStore from 'store';
import Rememberer from 'react-remember';
import CustomToast from 'components/CustomToast';
import { normalize } from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';
import GlobalTooltip from 'components/GlobalTooltip';
import {
  globalStyles,
  toastStyles,
  suggestStyles,
  toolTipStyles,
  pdfPreviewStyles,
  tabsStyles,
} from 'assets/styles';
import { Userpilot } from 'userpilot';

// Initialize Userpilot plugin
console.info('[Userpilot] Initialize');
console.warn('[Userpilot] Replace {AppToken} with your AppToken');
Userpilot.initialize('NX-dcfdd15b');

const { store } = configureStore();

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${globalStyles}
  ${toastStyles}
  ${suggestStyles}
  ${toolTipStyles}
  ${pdfPreviewStyles}
  ${tabsStyles}
`;

const App = (): JSX.Element => {
  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  return (
    <Rememberer>
      <Provider store={store}>
        <CustomToast />
        <AppRouter />
        <GlobalStyle />
        <GlobalTooltip />
      </Provider>
    </Rememberer>
  );
};

export default App;
