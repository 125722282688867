import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import FieldSection from '../FieldSection';
import { TreeSelect } from 'components/TreeSelect';
import { TreeData } from 'components/TreeSelect/TreeSelect';

interface Props extends FieldRenderProps<string[]> {
  treeData: TreeData;
  onSearchChange?: (search: string) => void;
}

const FieldTreeSelect = ({ input, label, meta, treeData, onSearchChange }: Props) => {
  return (
    <FieldSection input={input} meta={meta} label={label}>
      <TreeSelect
        data={treeData}
        onChange={input.onChange}
        selectedIds={input.value}
        onSearchChange={onSearchChange}
      />
    </FieldSection>
  );
};

export default FieldTreeSelect;
