/* eslint-disable react-hooks/exhaustive-deps */
import { UISpinner } from 'components/UI';
import { useIsCompanyUser, useOperation } from 'hooks/common';
import { AuthStatuses } from 'interfaces/auth';
import querystring from 'query-string';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import StorageService from 'services/Storage';
import { additionalInfoGet, currentUserGet } from 'store/ducks/user/operations';
import { selectAuthStatus, selectUser } from 'store/ducks/user/selectors';
import { StateProps } from 'store/interfaces';
import styled from 'styled-components';
import {
  AuthorizedCompanyRoutes,
  AuthorizedOrganizationRoutes,
  UnauthorizedRoutes,
} from './components';
import { ModalProvider } from 'react-modal-hook';
import { Userpilot } from 'userpilot';
import useIsTSUser from 'hooks/common/useIsTSUser';
import AuthorizedTeamSnapRoutes from './components/AuthorizedTeamSnapRoutes';

function AppRouter() {
  const accessToken = querystring.parse(window.location.search).accessToken || undefined;

  const [getCurrentUser, isLoading] = useOperation(currentUserGet);
  const [getAdditionalInfo] = useOperation(additionalInfoGet);

  const authStatus = useSelector<StateProps, string>(selectAuthStatus);

  const user = useSelector(selectUser);
  const isTSUser = useIsTSUser(user);
  const isCompanyUser = useIsCompanyUser(user);

  useEffect(() => {
    if (accessToken) {
      StorageService.setItem('accessToken', accessToken);
    }
    getCurrentUser(undefined);
    if (authStatus === AuthStatuses.AUTHOREZED) {
      getAdditionalInfo(undefined);

      Userpilot.identify(user.id, {
        name: user.firstName + ' ' + user.lastName,
        email: user.email,
        created_at: user.createdAt,
        role: user.role?.toString(),
        ...(user.companyId !== null ? { company: { id: user.companyId } } : {}),
      });
    } else {
      Userpilot.anonymous();
    }
  }, [getCurrentUser, authStatus]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <ModalProvider>
          {authStatus === AuthStatuses.AUTHOREZED ? (
            isCompanyUser ? (
              <AuthorizedCompanyRoutes />
            ) : isTSUser ? (
              <AuthorizedTeamSnapRoutes />
            ) : (
              <AuthorizedOrganizationRoutes />
            )
          ) : (
            <UnauthorizedRoutes />
          )}
        </ModalProvider>
      </Switch>
    </BrowserRouter>
  );
}

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default AppRouter;
