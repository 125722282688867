import { DashboardROI, ROIDashboardSdgGoals, ROISurveyStatistics } from 'interfaces/roi';
import React, { useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { GoalAlignmentOption, goalAlignmentOptions } from 'constants/goalAlignment';
import { useModal } from 'hooks/common';
import ROISdgGoalsPartnershipListModal from './modals/ROISdgGoalsPartnershipListModal';
import ROITabCharts from './ROITabCharts';
import { ResponseSummaryChart } from './surveyCharts';
import { moneyNumberFormat, numberFormat } from 'utils/formatters';
import { UILink } from 'components/UI';
import { useHistory } from 'react-router';
import { ROISurveyListPageType } from 'constants/roi';

interface Props {
  dashboardROI?: DashboardROI;
  roiSdgGoals?: ROIDashboardSdgGoals;
  roiSurveyStatistics?: ROISurveyStatistics;
}

const ROIDashboard = ({ dashboardROI, roiSdgGoals, roiSurveyStatistics }: Props) => {
  const [modalTitle, setModalTitle] = useState('');
  const [modalList, setModalList] = useState<ROIDashboardSdgGoals['noPoverty']['items']>(
    [],
  );

  const { push } = useHistory();

  const sdgGoals = useMemo(() => {
    if (!roiSdgGoals) return [];
    return Object.keys(roiSdgGoals);
  }, [roiSdgGoals]);

  const [
    showROISdgGoalsPartnershipListModal,
    hideROISdgGoalsPartnershipListModal,
  ] = useModal(() => {
    return (
      <ROISdgGoalsPartnershipListModal
        onClose={hideROISdgGoalsPartnershipListModal}
        list={modalList}
        title={modalTitle}
      />
    );
  }, [modalTitle, modalList]);

  return (
    <Root>
      <PanelTitle>Community Partners</PanelTitle>
      <PanelContent>
        <ROITabCharts data={dashboardROI} />
      </PanelContent>

      <PanelTitle>SDG Goals</PanelTitle>
      <PanelContent>
        {sdgGoals && sdgGoals.length > 0 ? (
          <SDGGoalsWrapper>
            {[...sdgGoals]
              .map(item => goalAlignmentOptions[item] as GoalAlignmentOption)
              .filter(item => item)
              .sort((a, b) => a.id - b.id)
              .map(item => (
                <SDGGoalsItem
                  key={item.id}
                  onClick={() => {
                    setModalTitle(item.title);
                    setModalList(roiSdgGoals?.[item.value].items || []);
                    showROISdgGoalsPartnershipListModal();
                  }}
                >
                  <SDGLogo src={item.img}></SDGLogo>
                  <SDGContent>
                    <SDGTitle>{item.title}</SDGTitle>
                    <SDGFooter>
                      <SDGPartner>
                        {roiSdgGoals?.[item.value].items.length} Partners
                      </SDGPartner>
                      {roiSdgGoals?.[item.value].lift === 'high' && <ArrowIconUp />}
                      {roiSdgGoals?.[item.value].lift === 'lower' && <ArrowIconDown />}
                      {roiSdgGoals?.[item.value].lift === 'flat' && (
                        <div style={{ margin: '0 4px' }}>-</div>
                      )}
                      <SDGDate>last month</SDGDate>
                    </SDGFooter>
                  </SDGContent>
                </SDGGoalsItem>
              ))}
          </SDGGoalsWrapper>
        ) : (
          <div>No SDG Goals have been reported by your partners.</div>
        )}
      </PanelContent>

      <PanelTitle>
        <div>Consumer</div>
        <UILink
          modifier="blue"
          onClick={() => push(`/roi/survey-list/${ROISurveyListPageType.ConsumerSurvey}`)}
        >
          View Consumer Surveys
        </UILink>
      </PanelTitle>
      <SurveyChartWrapper>
        <PanelContent>
          <ValuesGroup>
            <div>
              <SecondTitle>No. of Surveys</SecondTitle>
              <ValueBlock>
                <div>Total</div>
                <div>{numberFormat(roiSurveyStatistics?.consumer.consumerTotal)}</div>
              </ValueBlock>
            </div>
            <div>
              <SecondTitle>No. of Recipients</SecondTitle>
              <ValueBlock>
                <div>Total</div>
                <div>
                  {numberFormat(roiSurveyStatistics?.consumer.consumerTotalRecipents)}
                </div>
              </ValueBlock>
            </div>
            <div>
              <SecondTitle>Response Rate</SecondTitle>
              <ValueBlock>
                <div>Percentage</div>
                <div>
                  {numberFormat(roiSurveyStatistics?.consumer.consumerTotalResponseRate, {
                    symbol: '%',
                    append: true,
                  })}
                </div>
              </ValueBlock>
            </div>
          </ValuesGroup>
          <SecondTitle>Response Summary</SecondTitle>
          <ResponseSummaryChart
            data={[
              {
                name: 'Awareness',
                value: roiSurveyStatistics?.consumer.consumerAwareness || 0,
              },
              {
                name: 'Participation',
                value: roiSurveyStatistics?.consumer.consumerParticipation || 0,
              },
              {
                name: 'Sentiment',
                value: roiSurveyStatistics?.consumer.consumerSentiment || 0,
              },
              {
                name: 'Loyalty',
                value: roiSurveyStatistics?.consumer.consumerLoyalty || 0,
              },
            ]}
          />
        </PanelContent>
      </SurveyChartWrapper>

      <PanelTitle>
        <div>Workforce</div>
        <UILink
          modifier="blue"
          onClick={() => push(`/roi/survey-list/${ROISurveyListPageType.EmployeeSurvey}`)}
        >
          View Employee Surveys
        </UILink>
      </PanelTitle>
      <SurveyChartWrapper>
        <PanelContent>
          <ValuesGroup>
            <div>
              <SecondTitle>No. of Surveys</SecondTitle>
              <ValueBlock>
                <div>Total</div>
                <div>{numberFormat(roiSurveyStatistics?.employee.employeeTotal)}</div>
              </ValueBlock>
            </div>
            <div>
              <SecondTitle>No. of Recipients</SecondTitle>
              <ValueBlock>
                <div>Total</div>
                <div>
                  {numberFormat(roiSurveyStatistics?.employee.employeeTotalRecipents)}
                </div>
              </ValueBlock>
            </div>
            <div>
              <SecondTitle>Response Rate</SecondTitle>
              <ValueBlock>
                <div>Percentage</div>
                <div>
                  {numberFormat(roiSurveyStatistics?.employee.employeeTotalResponseRate, {
                    symbol: '%',
                    append: true,
                  })}
                </div>
              </ValueBlock>
            </div>
          </ValuesGroup>
          <ResponseSummaryChart
            data={[
              {
                name: 'Awareness',
                value: roiSurveyStatistics?.employee.employeeAwareness || 0,
              },
              {
                name: 'Participation',
                value: roiSurveyStatistics?.employee.employeeParticipation || 0,
              },
              {
                name: 'Sentiment',
                value: roiSurveyStatistics?.employee.employeeSentiment || 0,
              },
              {
                name: 'Loyalty',
                value: roiSurveyStatistics?.employee.employeeLoyalty || 0,
              },
            ]}
          />
          <ValuesGroup>
            <div>
              <SecondTitle>Total Volunteer Hours</SecondTitle>
              <ValueBlock>
                <div>Total</div>
                <div>
                  {numberFormat(roiSurveyStatistics?.employee.employeeVolunteerHour, {
                    symbol: ' hrs',
                    append: true,
                  })}
                </div>
              </ValueBlock>
            </div>
            <div>
              <SecondTitle>Volunteer Value</SecondTitle>
              <ValueBlock>
                <div>Total</div>
                <div>
                  {moneyNumberFormat(
                    roiSurveyStatistics?.employee.employeeVolunteerValue,
                  )}
                </div>
              </ValueBlock>
            </div>
          </ValuesGroup>
        </PanelContent>
      </SurveyChartWrapper>
    </Root>
  );
};

const Root = styled.div`
  margin-top: 24px;
`;

const PanelTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 24px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const PanelContent = styled.div`
  margin-bottom: 24px;
  background: #fff;
  padding: 32px;
`;

const ValuesGroup = styled.div`
  display: flex;
  gap: 24px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  & > div {
    flex: 1;
  }
`;

const SecondTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 16px;
`;

const ValueBlock = styled.div`
  background: #f9f9f9;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 53px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;

  & > div:first-child {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 2px;
    color: var(--darkGray);
  }
`;

const SurveyChartWrapper = styled.div`
  & > div:first-child {
    width: 565px;
  }
`;

const SDGGoalsWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

const SDGGoalsItem = styled.div`
  width: 285px;
  height: 80px;
  background: #f5f5f5;
  display: flex;
  padding: 16px 8px;
  border-radius: 6px;
  cursor: pointer;
`;

const SDGLogo = styled(ReactSVG)`
  margin-right: 8px;

  svg {
    width: 47px;
    height: 47px;
    border-radius: 6px;
  }
`;
const SDGContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const SDGTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 1em;
`;
const SDGFooter = styled.div`
  display: flex;
  align-items: center;
`;
const SDGPartner = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #737373;
`;
const SDGDate = styled.div`
  font-weight: 300;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #122434;
`;

const ArrowIconUp = styled.div`
  width: 0px;
  height: 0px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid #4ad78b;
  margin: 0 4px;
`;

const ArrowIconDown = styled(ArrowIconUp)`
  transform: rotate(180deg);
  border-bottom-color: #f05658;
`;

export default ROIDashboard;
