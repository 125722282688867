import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import GivingCharitableOrganizationsTable from './GivingCharitableOrganizationsTable';
import { useModal, useOperation, useSortStatus, useTableSelect } from 'hooks/common';
import {
  fetchBatchList,
  fetchGivingList,
  fetchGivingUploadToSFTP,
} from 'store/ducks/giving/operations';
import { useSelector } from 'react-redux';
import { getBatchListOptions, getGivingList } from 'store/ducks/giving/selectors';
import { SingleSelect, UIButton, UILink, UISpinner } from 'components/UI';
import { NavLink } from 'react-router-dom';
import { moneyNumberFormat } from 'utils/formatters';
import { InfoModal } from 'components/Modals';
import useJumpToSupport from 'hooks/common/useJumpToSupport';
import { fetchUncommongivingLoginOktaUser } from 'store/ducks/giving/api';
import { OktaAuth, OktaAuthOptions, toQueryString } from '@okta/okta-auth-js';
import { fetchErrorHandler } from 'utils/errorHandlers';
import { FormLabel } from 'components/UI/form';
import Toast from 'services/Toast';
import ViewModal from 'components/Modals/ViewModal';
import Storage from 'services/Storage';
import { selectUser } from 'store/ducks/user/selectors';
import { useHistory } from 'react-router';
import { fetchSubscriptionCompany } from 'store/ducks/companies/operations';

interface Props {
  rows: any;
}

const BatchAnalysis = ({ rows }: Props) => {
  const savedListTeamId = rows?.params?.savedListTeamId;
  const [givingNonprofitId, setGivingNonprofitId] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [filterValue, setFilterValue] = useState<string | undefined>(
    savedListTeamId ? savedListTeamId : '',
  );
  const { jumpToSupport } = useJumpToSupport();
  const { sortStatus, handleSwitch } = useSortStatus([], {
    isSort: false,
  });
  const [onFetchGivingList, loading] = useOperation(fetchGivingList);
  const [onFetchBatchList] = useOperation(fetchBatchList);
  const [onFetchGivingUploadToSFTP, , givingUploadToSFTPLoading] = useOperation(
    fetchGivingUploadToSFTP,
  );
  const user = useSelector(selectUser);
  const [onFetchSubscriptionCompany, isLoading] = useOperation(fetchSubscriptionCompany);
  const { push } = useHistory();

  const jumpToUncommonGiving = async ({
    type,
  }: {
    type: 'wallet' | 'donate' | 'viewDonations';
  }) => {
    try {
      const { sessionToken, redirectUrl } = await fetchUncommongivingLoginOktaUser();
      const urlList = redirectUrl.split('.com');
      const config: OktaAuthOptions = {
        issuer: urlList[0] + '.com/oauth2/default',
      };
      const authClient = new OktaAuth(config);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const einStatus = selectedItems[0]?.team?.einStatus;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const organizationName = selectedItems[0]?.team?.organizationName;

      const url = 'give/lookup?ein=' + einStatus + '&name=' + organizationName;
      const donate = encodeURIComponent(url);

      const relayState = {
        wallet: '%2Faccount%2Ffund%2Fwallet',
        // donate: '%2Fgive%2Fnonprofit%2Fb6f5ce854102',
        donate: donate,
        viewDonations: '%2Faccount%2Fgive%2Foverview',
      };

      window.open(
        authClient.getIssuerOrigin() +
          '/login/sessionCookieRedirect' +
          toQueryString({
            checkAccountSetupComplete: true,
            token: sessionToken,
            redirectUrl: `${redirectUrl}?RelayState=${relayState[type]}&code=9lxzuZtK4JqFvFLa90iu`,
          }),
      );
    } catch (error) {
      fetchErrorHandler(error);
    }
  };

  const { items, wallet } = useSelector(getGivingList);
  const batchListOption = useSelector(getBatchListOptions);

  const {
    handleSelectItem,
    selectedIds,
    selectedItems,
    handleSelectAll,
  } = useTableSelect({
    data: items,
  });

  const handleChangeFilter = (value: string | undefined) => {
    setFilterValue(value);
    if (!value)
      return onFetchGivingList({
        order: sortStatus.name,
        orderType: sortStatus.status,
        type: 'batch-list',
      }).then(() => {
        setIsDisabled(true);
        handleSelectAll(false);
      });

    onFetchGivingList({
      order: sortStatus.name,
      orderType: sortStatus.status,
      savedListTeamId: value,
      type: 'batch-list',
    }).then((e: any) => {
      if (e?.payload?.items.length) {
        setIsDisabled(false);
      }
      handleSelectAll(true);
    });
  };

  const [showFundMyWalletModal, hideFundMyWalletModal] = useModal(() => (
    <InfoModal
      onClose={hideFundMyWalletModal}
      title="Fund My Wallet"
      description={
        <>
          You are being redirected to Accelerist&apos;s UncommonGiving Fund. If you have
          any issues, please log a{' '}
          <UILink onClick={jumpToSupport} modifier="blue">
            ticket
          </UILink>
          .
        </>
      }
      onClickOk={async () => {
        await jumpToUncommonGiving({
          type: 'wallet',
        });
        hideFundMyWalletModal();
      }}
    />
  ));

  const [showViewDonationsModal, hideViewDonationsModal] = useModal(() => (
    <>
      <ViewModal
        onClose={hideViewDonationsModal}
        title="View Donations"
        description={
          <>
            You are being redirected to Accelerist&apos;s UncommonGiving Fund. If you have
            any issues, please log a{' '}
            <UILink onClick={jumpToSupport} modifier="blue">
              ticket
            </UILink>
            .
          </>
        }
        onClickOk={async () => {
          await jumpToUncommonGiving({
            // beforeOpenUrl: 'https://uncommongiving.com/account/give/overview',
            type: 'viewDonations',
          });

          hideViewDonationsModal();
        }}
      />
    </>
  ));

  const [showDonateModal, hideDonateModal] = useModal(
    () => (
      <InfoModal
        onClose={hideDonateModal}
        title="Donate"
        description={
          <>
            You are being redirected to Accelerist&apos;s UncommonGiving Fund. If you have
            any issues, please log a{' '}
            <UILink onClick={jumpToSupport} modifier="blue">
              ticket
            </UILink>
            .
          </>
        }
        onClickOk={async () => {
          await jumpToUncommonGiving({
            type: 'donate',
          });
          hideDonateModal();
        }}
      />
    ),
    [givingNonprofitId],
  );

  const [showDonateBatchModal, hideDonateBatchModal] = useModal(
    () => (
      <InfoModal
        onClose={hideDonateBatchModal}
        title="Donate"
        description="The batch donations are in process"
        onClickOk={async () => {
          if (!filterValue) return;
          //onFetchGivingUploadToSFTP(filterValue);
          hideDonateBatchModal();
        }}
      />
    ),
    [filterValue],
  );

  const [showDonateConfirmBatchModal, hideDonateConfirmBatchModal] = useModal(
    () => (
      <InfoModal
        onClose={hideDonateConfirmBatchModal}
        title="Donate"
        isloading={givingUploadToSFTPLoading}
        buttonText="Yes"
        buttonCancelText={true}
        description="Please confirm you would like to process the entire batch of donations"
        onClickOk={() => {
          if (!filterValue) return;
          onFetchGivingUploadToSFTP(filterValue).then(() => {
            hideDonateConfirmBatchModal();
            showDonateBatchModal();
          });
        }}
      />
    ),
    [filterValue, givingUploadToSFTPLoading],
  );

  useEffect(() => {
    if (!sortStatus?.name) return;
    if (!sortStatus?.status) {
      sortStatus.name = '';
    }

    onFetchGivingList({
      order: sortStatus.name,
      orderType: sortStatus.status,
      savedListTeamId: filterValue,
      type: 'batch-list',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  useEffect(() => {
    filterValue
      ? onFetchGivingList({ savedListTeamId: filterValue, type: 'batch-list' }).then(
          () => {
            setIsDisabled(false);
            handleSelectAll(true);
          },
        )
      : onFetchGivingList({ type: 'batch-list' });
    onFetchBatchList(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Spinner />;

  const goVetting = () => {
    onFetchSubscriptionCompany(undefined).then(e => {
      if (e?.payload?.isVetting) {
        push(`/search`);
      } else {
        Toast.error('No subscription for Vetting module.');
      }
    });
  };

  if (!items.length && savedListTeamId === undefined)
    // if (items)
    return (
      <EmptyText>
        There is currently no Batch Analysis created.
        <br /> Please create them in the{' '}
        <span style={{ color: '#2BAEE0', cursor: 'pointer' }} onClick={goVetting}>
          Vetting
        </span>{' '}
        module.
      </EmptyText>
    );
  return (
    <>
      <HeaderActions></HeaderActions>
      {/* <NewActiveButtons>
        <UIButton
          modifiers={'primary'}
          title="Fund My Wallet"
          handleClick={showFundMyWalletModal}
          style={{ width: '200px', padding: '7px 20px' }}
        />
      </NewActiveButtons> */}
      <FilterWrapper>
        <HeaderTitle>
          {/* Your Giving Wallet: {moneyNumberFormat(Number(wallet) || 0)} */}
        </HeaderTitle>
        <ActiveButtons>
          <ViewButton
            title="View Donations"
            modifiers={'third'}
            handleClick={() => {
              const isDontShowModal = Storage.getItem(`hide-view-modal-${user.email}`);

              if (isDontShowModal)
                return jumpToUncommonGiving({
                  type: 'viewDonations',
                });

              showViewDonationsModal();
            }}
          />

          <UIButton
            title="Donate"
            modifiers={'primary'}
            disabled={isDisabled}
            handleClick={() => {
              if (!selectedItems.length)
                return Toast.error('You need to select at least one record.');

              const uncommonGivingId = selectedItems[0].uncommonGivingId;
              setGivingNonprofitId(uncommonGivingId);

              // if (filterValue) return showDonateBatchModal();
              if (filterValue) return showDonateConfirmBatchModal();
              showDonateModal();
            }}
          />
        </ActiveButtons>
      </FilterWrapper>
      {batchListOption.length && (
        <SelectWrapper>
          <FormLabel>Filter:</FormLabel>
          <SingleSelect
            options={batchListOption}
            value={filterValue}
            handleSelect={value => handleChangeFilter(value?.value)}
            placeholder="By Batch Analysis "
          />
        </SelectWrapper>
      )}
      <GivingCharitableOrganizationsTable
        onSort={handleSwitch}
        sortStatus={sortStatus}
        rows={items}
        selectedIds={selectedIds}
        handleSelectItem={handleSelectItem}
        disabledSelect={!!filterValue}
        type={'batchAnalysis'}
        isDisable={true}
      />
    </>
  );
};

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  margin-top: 13px;

  &:has(button:only-child) {
    justify-content: flex-end;
    margin-top: 24px;
  }
`;

const HeaderActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const HeaderTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
`;

const EmptyText = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #122434;
  padding: 258px 0;
  text-align: center;
  max-width: 740px;
  margin: 0 auto;
`;

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 450px);
`;

const SelectWrapper = styled.div`
  min-width: 260px;
  max-width: 270px;
  margin-bottom: 32px;
  margin-top: -65px;
`;

const ActiveButtons = styled.div`
  display: flex;
  gap: 16px;
`;

const ViewButton = styled(UIButton)`
  font-size: 16px;
`;

const NewActiveButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  top: 131px;
  right: 182px;
`;

export default BatchAnalysis;
