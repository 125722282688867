import { UIActionText } from 'components/UI';
import { useModal } from 'hooks/common';
import React, { ReactNode } from 'react';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { useHistory } from 'react-router';

interface Props {
  children: ReactNode;
  className?: string;
  path: string;
}

const JumpAlertLink = ({ children, className, path }: Props) => {
  const { push } = useHistory();
  const [showModal, hideModal] = useModal(() => (
    <ConfirmModal
      accessHandler={() => path && push(path)}
      cancelHandler={hideModal}
      textAccessBtn="OK"
      accessModifiersBtn="third"
      onClose={hideModal}
      name="Your changes will be lost if not saved."
      description=""
    />
  ));
  return (
    <UIActionText className={className} onClick={showModal}>
      {children}
    </UIActionText>
  );
};

export default JumpAlertLink;
