import { useCallback } from 'react';
import downloadFile from 'js-file-download';
import Toast from 'services/Toast';

export default <T = string>(
  getFile: (query: T) => Promise<{ name: string; file: string }>,
  errorMessage?: string,
) => {
  const request = useCallback(
    async (query: T) => {
      try {
        const data = await getFile(query);
        const file = Uint8Array.from(window.atob(data.file), c => c.charCodeAt(0));
        downloadFile(Buffer.from(file), data.name);
        return data;
      } catch (error) {
        errorMessage && Toast.error(errorMessage);
        return error;
      }
    },
    [errorMessage, getFile],
  );

  return request;
};
