/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { AuthenticatedTSWrapper } from 'layouts';

import Dashboard from 'pages/tsUser/dashboard';
import { Prospecting, Prospects, ProspectsList, Search } from 'pages/tsUser/search';
import { Profile, UserProfile, Users, ProfileEdit, Password } from 'pages/tsUser/team';
import Route from './Route';

import Demo from 'pages/demo';

import Campaign from 'pages/tsUser/Fundraising';
import Setting from 'pages/tsUser/team/Setting';
import { useSelector } from 'react-redux';
import { StateProps } from 'store/interfaces';
import { SubscriptionProps } from 'interfaces/team';
import { getSubscription } from 'store/ducks/team/selectors';
import { fetchSubscriptionTeams } from 'store/ducks/team/operations';
import { useOperation } from 'hooks/common';
import { NoSubscriptionsRoute } from '.';
import styled from 'styled-components';
import { UISpinner } from 'components/UI';
import EmailDesign from 'pages/tsUser/team/EmailDesign';
import useRedirect from 'hooks/common/useRedirect';
import { NotFound } from 'components/NotFound';
import CanvaLogin from 'pages/auth/CanvaLogin';
import GiveATWork from 'pages/tsUser/Fundraising/GiveATWork';
import GiveandShop from 'components/Campaign/GiveandShop';
import CampaignTS from 'pages/organizationUser/Fundraising/indexTeamSnap';
import CampaignTSPayout from 'pages/organizationUser/Fundraising/indexTeamSnapPayout';
import { ClaimFundsMfa } from 'pages/claimFunds';

const AuthorizedTeamSnapRoutes = () => {
  const alwaysTrue = true;
  useRedirect();

  return alwaysTrue ? (
    <Switch>
      <Route path="/canva-login" component={CanvaLogin} />

      <Route path="/" layout={AuthenticatedTSWrapper}>
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/search" component={Search} />
        <Route path="/prospecting" component={Prospecting} />
        <Route exact path="/prospects" component={Prospects} />
        <Route path="/prospects/:prospectsId" component={ProspectsList} />
        <Route exact path="/profile" component={Profile} />

        <Route path="/profile/edit" component={ProfileEdit} />
        <Route path="/password" component={Password} />
        <Route path="/setting" component={Setting} />
        <Route path="/email-design" component={EmailDesign} />

        <Route path="/user-profile" component={UserProfile} />

        <Route path="/teams/:teamId" component={Users} />
        <Route path="/teams" component={Users} />

        <Route path="/campaign" exact component={Campaign} />
        <Route path="/campaign/give-work" exact component={GiveATWork} />
        <Route path="/campaign/give-shop" exact component={GiveandShop} />
        <Route path="/campaignTeamsnap" exact component={CampaignTS} />
        <Route path="/funds_mfa" component={ClaimFundsMfa} />
        <Route
          path="/campaignTeamsnapPayout/:giveworxCampaignId"
          exact
          component={CampaignTSPayout}
        />

        {/* {process.env.NODE_ENV === 'development' && ( */}
        <Route path="/demo" component={Demo} />
        {/* )} */}

        <Route path="/404" component={NotFound} />
        <Route path="/" component={() => <Redirect to="/campaignTeamsnap" />} />
      </Route>
    </Switch>
  ) : (
    <NoSubscriptionsRoute />
  );
};

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default AuthorizedTeamSnapRoutes;
