/* eslint-disable react-hooks/exhaustive-deps */
import { InviteData } from 'components/Forms/interfaces';
import InviteForm from 'components/Forms/InviteForm';
import { useOperation } from 'hooks/common';
import React, { useCallback } from 'react';
import {
  fetchAddTeamCompanyMembers,
  fetchInviteLinkTeamCompany,
} from 'store/ducks/companies/operations';
import styled from 'styled-components';

const InviteUser = () => {
  const [onSubmit] = useOperation(fetchAddTeamCompanyMembers);

  const submit = useCallback((values: InviteData) => {
    return onSubmit(values.email);
  }, []);

  return (
    <Root>
      <InviteForm
        onSubmit={submit}
        fetchInviteLinkOperation={fetchInviteLinkTeamCompany}
      />
    </Root>
  );
};

const Root = styled.div`
  background: #ffffff;
  border-radius: 4px;
  padding: 40px 24px;
  margin-bottom: 24px;
`;

export default InviteUser;
