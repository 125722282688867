import Jumbotron from 'components/Jumbotron';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

const CreateGamePlan = () => {
  const params: { prospectsId: string } = useParams();
  return (
    <ContentBlockWrapper
      header={
        <Jumbotron
          name="Create a Game Plan"
          back={true}
          backPath={`/prospects/${params.prospectsId}`}
        />
      }
    >
      <MainCard>
        <Title>Create a game plan associated with prospecting session</Title>
        <CardGroup>
          <Card to={`/gameplan/edit/${params.prospectsId}?type=Recommended`}>
            Recommended Game Plan
          </Card>
          <Card to={`/gameplan/edit/${params.prospectsId}?type=Customized`}>
            Customized Game Plan
          </Card>
        </CardGroup>
      </MainCard>
    </ContentBlockWrapper>
  );
};

const MainCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 1103px;
  height: 505px;
  background: #fff;
`;

const Title = styled.div`
  display: inline-block;
  margin: 130px 0 44px;
  font-weight: 500;
  font-size: 16px;
`;

const CardGroup = styled.div`
  display: flex;
`;

const Card = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  width: 343px;
  height: 124px;
  border: 1px solid #2baee0;
  border-radius: 6px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 24px;
  }
`;

export default CreateGamePlan;
