/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import styled from 'styled-components';
import { animateScroll as scroll } from 'react-scroll';
import { useModal, useSortStatus } from 'hooks/common';
import { useOperation } from 'hooks/common';
import {
  fetchCompanies,
  fetchCompaniesCustomizeFilter,
  fetchSimilaritySearchCompany,
  clearSimilaritySearchCompany,
  fetchLastWeekCompanies,
} from 'store/ducks/companies/operations';
import {
  fetchSavedListCreate,
  fetchSendNotExistsCompany,
  fetchUploadCustomProspectingExcel,
} from 'store/ducks/prospects/operations';
import {
  getCompanies,
  getCompaniesContacts,
  getCompaniesMeta,
} from 'store/ducks/companies/selectors';
import SearchWrapper from 'layouts/SearchWrapper';
import Pagination from 'components/Pagination';
import { ExportButton, SaveListButton, SupportButton } from 'components/ActionButtons';
import EmptyCompany from 'components/EmptyCompany';
import { CannotSavedModal } from 'components/Modals';
import { SearchCompany } from 'interfaces/companies';
import {
  PaginationMeta,
  SavedListCreateRequestProps,
  SearCompaniesRequestProps,
} from 'interfaces/common';
import { StateProps } from 'store/interfaces';
import { FilterData } from 'components/Forms/interfaces';
import { initialValuesFilter } from './initialValuesFilter';
import CannotExportModal from 'components/Modals/CannotExportModal';
import useDownloadFile from 'hooks/common/useDownloadFile';
import { fetchExcelCompanies } from 'store/ducks/companies/api';
import { Field } from 'components/FinalForm';
import { FieldSingleSelect } from 'components/FormFields';
import { OnChange } from 'react-final-form-listeners';
import ContactsSelectedTable from 'pages/organizationUser/companies/Contacts/ContactsSelectedTable';
import { OnCheckParams } from 'components/UI/interfaces';
import { UIButton, UICheckbox } from 'components/UI';
import {
  fetchGameplanCustomizeTemplate,
  fetchGameplanCustomizeTemplatePreview,
  fetchGameplanCustomizeTemplateReset,
  fetchGameplanCustomizeTemplateSave,
} from 'store/ducks/gameplan/operations';
import { GamePlanEmailTemplateTypeEnum } from 'constants/gamePlan';
import CustomizeTemplateModal from 'components/gameplan/CustomizeTemplateModal';
import {
  getGameplanCustomizeTemplate,
  getGameplanCustomizeTemplatePreview,
} from 'store/ducks/gameplan/selectors';
import { ReactSVG } from 'react-svg';
import EditSVG from 'assets/images/icons/edit.svg';
import SearchContactMultPitchButton from 'components/ActionButtons/SearchContactMultPitchButton';
import { fetchVerifyEmailStatus } from 'store/ducks/user/operations';
import { SortStatus } from 'hooks/common/useSortStatus';
import { FormSpy } from 'react-final-form';
import { filterDataConvertSearchParams } from 'utils/companiesUtils';
import { PAGE_WIDTH } from 'constants/common';
import { decodeBase64, encodeBase64 } from 'utils';
import Toast from 'services/Toast';
import { CustomProspectingListModal } from '../components/CustomProspectingListModal';
import { CustomProspectingResultsModal } from '../components/CustomProspectingResultsModal';
import { UploadCustomProspectingExcelRes } from 'interfaces/prospects';
import Filters from 'components/Filters';
import CompanySearchResult from './CompanySearchResult';
import { CardCompanyRankText } from 'interfaces/search';
import _ from 'lodash';

interface SearchParams {
  filters?: FilterData;
  sort?: SortStatus;
  page?: number;
}

type SearchParamsStringify = { [key in keyof SearchParams]: string };

export interface HandleSearchConfigParams {
  limit?: number;
  page?: number;
  isSearchLog?: boolean;
}

const Search: FC = () => {
  const { location, push } = useHistory();
  const isInitialRender = useRef(true);
  const [initialValues, setInitialValues] = useState<FilterData>(initialValuesFilter);
  const [isSelectedAllContacts, setIsSelectedAllContacts] = useState(false);
  const [selectedContactsId, setSelectedContactsId] = useState<string[]>([]);
  const [unselectedContactsId, setUnselectedContactsId] = useState<string[]>([]);
  const { sortStatus, setSortStatus, handleSwitch } = useSortStatus([], {
    isSort: false,
  });
  const [isSelectedAllCompanies, setIsSelectedAllCompanies] = useState(false);
  const [selectedCompaniesId, setSelectedCompaniesId] = useState<string[]>([]);
  const [unselectedCompaniesId, setUnselectedCompaniesId] = useState<string[]>([]);
  const [customProspectingResult, setCustomProspectingResult] = useState<
    undefined | UploadCustomProspectingExcelRes
  >(undefined);

  const [onFetchCompanies, , isLoading] = useOperation(fetchCompanies);
  const [onFetchSavedListCreate] = useOperation(fetchSavedListCreate);
  const [onFetchCustomizeFilter, , isCustomizeLoading] = useOperation(
    fetchCompaniesCustomizeFilter,
  );
  const [onfetchSimilaritySearchCompany] = useOperation(fetchSimilaritySearchCompany);
  const [onClearSimilaritySearchCompany] = useOperation(clearSimilaritySearchCompany);
  const [onFetchLastWeekCompanies] = useOperation(fetchLastWeekCompanies);
  const [onFetchGameplanCustomizeTemplate, , isCustomizeTeamplateLoading] = useOperation(
    fetchGameplanCustomizeTemplate,
  );
  const [onFetchGameplanCustomizeTemplateSave] = useOperation(
    fetchGameplanCustomizeTemplateSave,
  );
  const [onFetchGameplanCustomizeTemplateReset] = useOperation(
    fetchGameplanCustomizeTemplateReset,
  );
  const [onFetchGameplanCustomizeTemplatePreview] = useOperation(
    fetchGameplanCustomizeTemplatePreview,
  );
  const [onFetchVerifyEmailStatus] = useOperation(fetchVerifyEmailStatus);
  const [onFetchUploadCustomProspectingExcel] = useOperation(
    fetchUploadCustomProspectingExcel,
  );
  const [onFetchSendNotExistsCompany] = useOperation(fetchSendNotExistsCompany);

  const companies = useSelector<StateProps, SearchCompany[]>(getCompanies);
  const meta = useSelector<StateProps, PaginationMeta>(getCompaniesMeta);
  const { items: companiesContacts, meta: companiesContactsMeta } = useSelector(
    getCompaniesContacts,
  );
  const gameplanCustomizeTemplate = useSelector(getGameplanCustomizeTemplate);
  const gameplanCustomizeTemplatePreview = useSelector(
    getGameplanCustomizeTemplatePreview,
  );
  const totalRef = useRef<HTMLHeadingElement | null>(null);

  const urlSearchParams = useMemo((): SearchParams => {
    const searchParams = queryString.parse(location.search) as SearchParamsStringify;
    return {
      filters: searchParams.filters && JSON.parse(decodeBase64(searchParams.filters)),
      page: Number(searchParams.page),
      sort: searchParams.sort && JSON.parse(decodeBase64(searchParams.sort)),
    };
  }, [queryString.parse(location.search)]);

  const [showCannotSavedModal, hideCannotSavedModal] = useModal(() => {
    return <CannotSavedModal onClose={hideCannotSavedModal} />;
  });

  const [showCannotExportModal, hideCannotExportModal] = useModal(() => {
    return <CannotExportModal onClose={hideCannotExportModal} />;
  });

  const [showCustomizeTemplateModal, hideCustomizeTemplateModal] = useModal(() => {
    return (
      <CustomizeTemplateModal
        noSubject
        template={gameplanCustomizeTemplate}
        templatePreview={gameplanCustomizeTemplatePreview}
        isloading={isCustomizeTeamplateLoading}
        onClose={hideCustomizeTemplateModal}
        onReset={templateType => onFetchGameplanCustomizeTemplateReset(templateType)}
        onSave={values => onFetchGameplanCustomizeTemplateSave(values)}
        onPreview={templateType => onFetchGameplanCustomizeTemplatePreview(templateType)}
      />
    );
  }, [
    gameplanCustomizeTemplate,
    isCustomizeTeamplateLoading,
    gameplanCustomizeTemplatePreview,
  ]);

  const [
    showCustomProspectingResultsModal,
    hideCustomProspectingResultsModal,
  ] = useModal(() => {
    return (
      <CustomProspectingResultsModal
        customProspectingResult={customProspectingResult}
        handleAccess={() => {
          if (!customProspectingResult) return;
          onFetchSendNotExistsCompany(customProspectingResult.notExistsCompanies).then(
            () => {
              setTimeout(() => {
                if (customProspectingResult.status) {
                  return push(`/prospects/${customProspectingResult.id}`);
                }
                hideCustomProspectingResultsModal();
                setCustomProspectingResult(undefined);
              }, 1500);
            },
          );
        }}
        onClose={() => {
          if (!customProspectingResult) return;
          if (customProspectingResult.status) {
            return push(`/prospects/${customProspectingResult.id}`);
          }
          hideCustomProspectingResultsModal();
          setCustomProspectingResult(undefined);
        }}
      />
    );
  }, [customProspectingResult]);

  const [
    showCustomProspectingListModal,
    hideCustomProspectingListModal,
  ] = useModal(() => {
    return (
      <CustomProspectingListModal
        handleAccess={formvalue => {
          onFetchUploadCustomProspectingExcel(formvalue).then(value => {
            if (!value) return;
            if (value.notExistsCompanies.length) {
              if (!value.status) Toast.warn('There is no matching company in the list');
              setCustomProspectingResult(value);
              hideCustomProspectingListModal();
              setTimeout(showCustomProspectingResultsModal);
            } else {
              hideCustomProspectingListModal();
            }
          });
        }}
        onClose={hideCustomProspectingListModal}
      />
    );
  }, []);

  const { currentPage, itemsPerPage, totalItems } = meta;

  const checkedContacts = useMemo(() => {
    const contacts = companiesContacts.filter(item => item.usedIn60Days === '0');

    if (isSelectedAllContacts) return contacts;
    if (selectedContactsId.length)
      return contacts.filter(item => selectedContactsId.includes(item.contact_id));
    if (unselectedContactsId.length)
      return contacts.filter(item => !unselectedContactsId.includes(item.contact_id));
    return [];
  }, [isSelectedAllContacts, selectedContactsId, unselectedContactsId]);

  const onCheckRow = ({ check, id }: OnCheckParams) => {
    if (isSelectedAllContacts) {
      setIsSelectedAllContacts(false);
      setUnselectedContactsId([id]);
      return;
    }
    if (unselectedContactsId.length) {
      if (check) {
        setUnselectedContactsId(unselectedContactsId.filter(value => value !== id));
      } else {
        setUnselectedContactsId([...unselectedContactsId, id]);
      }
      return;
    }
    if (selectedContactsId.includes(id)) {
      setSelectedContactsId(selectedContactsId.filter(value => value !== id));
    } else {
      setSelectedContactsId([...selectedContactsId, id]);
    }
  };

  const onCheckAll = (bool: boolean) => {
    setIsSelectedAllContacts(bool);
    setSelectedContactsId([]);
    setUnselectedContactsId([]);
  };

  const saveHandler = () => {
    if (urlSearchParams.filters) {
      const param: FilterData = urlSearchParams.filters;
      let body: SavedListCreateRequestProps | undefined;
      if (param.dataType === 'contact') {
        if (
          !isSelectedAllContacts &&
          !selectedContactsId.length &&
          !unselectedContactsId.length
        ) {
          Toast.warn(
            'Please select the contacts you want to save to your prospecting list.',
          );
          return;
        }
        body = {
          prospectsAvailable: isSelectedAllContacts
            ? companiesContactsMeta.totalItems
            : selectedContactsId.length
            ? selectedContactsId.length
            : companiesContactsMeta.totalItems - unselectedContactsId.length,
          filters: {
            ...filterDataConvertSearchParams(param),
            deleteIds: unselectedContactsId,
            selectedIds: selectedContactsId,
          },
          selectAll: isSelectedAllContacts,
        };
      } else {
        if (
          !isSelectedAllCompanies &&
          !selectedCompaniesId.length &&
          !unselectedCompaniesId.length
        ) {
          Toast.warn(
            'Please select the companies you want to save to your prospecting list.',
          );
          return;
        }
        body = {
          prospectsAvailable: isSelectedAllCompanies
            ? totalItems
            : selectedCompaniesId.length
            ? selectedCompaniesId.length
            : totalItems - unselectedCompaniesId.length,
          filters: {
            ...filterDataConvertSearchParams(param),
            deleteIds: unselectedCompaniesId,
            selectedIds: selectedCompaniesId,
          },
          selectAll: isSelectedAllCompanies,
        };
      }

      return onFetchSavedListCreate(body).then(result => {
        return push(`prospects/${result?.id}`);
      });
    }

    return showCannotSavedModal();
  };

  const exportToExcel = useDownloadFile<SearCompaniesRequestProps>(
    fetchExcelCompanies,
    'The size of the excel file is too big, please save the prospecting list and export in the saved prospecting list.',
  );

  const downloadExcel = useCallback(() => {
    const filtersString = queryString.parse(location.search)?.filters;
    const pageString = queryString.parse(location.search)?.page;

    if (filtersString) {
      const param = JSON.parse(
        decodeBase64(filtersString?.toString()),
      ) as SearCompaniesRequestProps;

      if (param.dataType === 'contact' && companiesContactsMeta.totalItems > 10000) {
        Toast.warn('You have exceeded the limit of 10,000 records.');
        return;
      }
      if (param.dataType === 'company' && meta.totalItems > 10000) {
        Toast.warn('You have exceeded the limit of 10,000 records.');
        return;
      }
      param.ids = (param?.ids || []).map((x: any) => {
        return x.id;
      });
      return exportToExcel({ page: Number(pageString) || 1, ...param });
    }

    return showCannotExportModal();
  }, [location, companiesContactsMeta, meta]);

  const onSearch = (formValues?: FilterData, config?: HandleSearchConfigParams) => {
    const { page = 1, limit = 12, isSearchLog } = config || {};
    // filter falsy field
    const notEmptyValues = Object.fromEntries(
      Object.entries(formValues || {}).filter(item => item[1]),
    );
    const values: FilterData = {
      ...initialValuesFilter,
      ...notEmptyValues,
      q: formValues?.q || '',
    };

    if (values?.q && values.filterType === 'Best Match') {
      onfetchSimilaritySearchCompany(values?.q);
    }

    const filtersBase64 = encodeBase64(JSON.stringify(values));
    const sortBase64 = encodeBase64(JSON.stringify(sortStatus));
    const urlBySeachParam: SearchParamsStringify = {
      sort: sortBase64,
      page: String(page),
      ...(_.isEqual(values, initialValuesFilter) ? {} : { filters: filtersBase64 }),
    };
    push(`${location.pathname}?${queryString.stringify(urlBySeachParam)}`);

    const offsetTop = totalRef.current?.offsetTop || 0;
    const isNeedScroll = offsetTop > 1600;

    if (values?.variantForm === 'Customize') {
      const { priority, ...rest } = values;
      onFetchCustomizeFilter({
        filters: {
          ...filterDataConvertSearchParams(rest, sortStatus),
          limit,
          page,
          searchLog: isSearchLog ? 'true' : undefined,
        },
        ranks: { ...priority },
      }).then(() => {
        if (isNeedScroll) offsetTop && scroll.scrollTo(offsetTop - 32);
      });
    } else {
      onFetchCompanies({
        ...filterDataConvertSearchParams(values, sortStatus),
        page,
        searchLog: isSearchLog ? 'true' : undefined,
      }).then(() => {
        if (isNeedScroll) offsetTop && scroll.scrollTo(offsetTop - 32);
      });
    }
  };

  const submitHandlerPagination = (page: number) => {
    const filtersString = queryString.parse(location.search)?.filters;
    if (filtersString) {
      const param: FilterData = JSON.parse(decodeBase64(filtersString?.toString()));
      return onSearch(param, { page });
    }
    return onSearch(undefined, { page });
  };

  const getInitialState = async () => {
    const typeString = queryString.parse(location.search)?.type;
    const lastDateString = queryString.parse(location.search)?.lastdate;
    if (typeString === 'CompaniesMatch') {
      let ids: string[] = [];
      if (typeof lastDateString === 'string') {
        ids = await onFetchLastWeekCompanies(lastDateString);
      } else {
        ids = await onFetchLastWeekCompanies(new Date().toISOString());
      }

      return onSearch({ q: '', ids: ids.map(item => ({ id: item })) as SearchCompany[] });
    }

    const filtersString = queryString.parse(location.search)?.filters;
    const filter: FilterData =
      filtersString && JSON.parse(decodeBase64(filtersString.toString()));
    const pageString = queryString.parse(location.search)?.page;
    const page = (pageString && Number(pageString)) || 1;
    const sortString = queryString.parse(location.search)?.sort;
    const sort: SortStatus =
      sortString && JSON.parse(decodeBase64(sortString.toString()));

    if (sort) setSortStatus(sort);
    if (filter) {
      setInitialValues(filter);
    } else {
      setInitialValues(initialValuesFilter);
    }

    setTimeout(() => {
      onSearch(filter, { page });
    });
  };

  useEffect(() => {
    if (isInitialRender.current) return;
    const filtersString = queryString.parse(location.search)?.filters;
    const filter: FilterData =
      filtersString && JSON.parse(decodeBase64(filtersString.toString()));
    const pageString = queryString.parse(location.search)?.page;
    const page = (pageString && Number(pageString)) || 1;
    onSearch(filter, { page });
  }, [sortStatus]);

  useEffect(() => {
    getInitialState();
    onFetchGameplanCustomizeTemplate({
      templateType: GamePlanEmailTemplateTypeEnum.PitchTemplate,
    });
    onFetchVerifyEmailStatus(undefined);
    return () => {
      onClearSimilaritySearchCompany(undefined);
    };
  }, []);

  useEffect(() => {
    if (!isInitialRender.current) return;
    isInitialRender.current = false;
  }, []);

  return (
    <SearchWrapper
      filterSubmitHandler={values => onSearch(values, { isSearchLog: true })}
      isLoading={isLoading || isCustomizeLoading}
      initialValues={initialValues}
    >
      <FormSpy subscription={{ values: true }}>
        {({ values, form }) => (
          <>
            {values.dataType === 'company' ? (
              <>
                <Total ref={totalRef}>
                  <div>{`Found ${totalItems} ${
                    totalItems > 1 ? 'companies' : 'company'
                  }`}</div>
                  <div>
                    <UIButton
                      title="View Saved Lists"
                      modifiers="third"
                      style={{ marginRight: '14px' }}
                      handleClick={() =>
                        push(`/prospects?sort=alphabet&fromPage=/search`)
                      }
                    />
                    <UploadCustomListButton
                      modifiers="blue"
                      title="Upload Custom List"
                      handleClick={showCustomProspectingListModal}
                    />
                  </div>
                </Total>
                {urlSearchParams.filters && (
                  <Filters
                    marginList="0 14px"
                    filters={filterDataConvertSearchParams(urlSearchParams.filters)}
                    companiesNames={
                      urlSearchParams.filters?.ids?.map(item => item.name) || []
                    }
                  />
                )}
                <HeaderList>
                  <SuggestionActions>
                    {companies.length > 0 && (
                      <>
                        <SaveListButton saveHandler={saveHandler} />
                        <ExportButton uploadHandler={downloadExcel} />
                      </>
                    )}
                    <SupportButton />
                  </SuggestionActions>
                  {totalItems > 0 && (
                    <Pagination
                      total={totalItems}
                      pageLimit={Number(itemsPerPage)}
                      page={Number(currentPage)}
                      submitHandler={(page: number) => submitHandlerPagination(page)}
                    />
                  )}
                </HeaderList>
              </>
            ) : (
              <>
                <Total ref={totalRef}>{`Found ${companiesContactsMeta.totalItems} ${
                  companiesContactsMeta.totalItems > 1 ? 'contacts' : 'contact'
                }`}</Total>
                {urlSearchParams.filters && (
                  <Filters
                    marginList="0 14px"
                    filters={filterDataConvertSearchParams(urlSearchParams.filters)}
                    companiesNames={
                      urlSearchParams.filters?.ids?.map(item => item.name) || []
                    }
                  />
                )}
                <ContactsHeaderList>
                  <SuggestionActions>
                    {companiesContacts.length > 0 && (
                      <>
                        <SaveListButton saveHandler={saveHandler} />
                        <ExportButton uploadHandler={downloadExcel} />
                      </>
                    )}
                    <SupportButton />
                  </SuggestionActions>
                  {companiesContactsMeta.totalItems > 0 && (
                    <Pagination
                      total={companiesContactsMeta.totalItems}
                      pageLimit={Number(companiesContactsMeta.itemsPerPage)}
                      page={Number(companiesContactsMeta.currentPage)}
                      submitHandler={(page: number) => submitHandlerPagination(page)}
                    />
                  )}
                </ContactsHeaderList>
              </>
            )}

            <SecondHeader $width={values.dataType !== 'contact' ? '1096px' : undefined}>
              <div>
                <Field
                  name="dataType"
                  // eslint-disable-next-line @typescript-eslint/no-use-before-define
                  component={DisplaySelect}
                  options={[
                    { label: 'By Company', value: 'company' },
                    { label: 'By Contact', value: 'contact' },
                  ]}
                  isClearable={false}
                />
                <OnChange name="dataType">
                  {(value, previous) => {
                    if (value !== previous) form.submit();
                  }}
                </OnChange>
              </div>
              <Actions>
                {!!companies.length && values.dataType === 'company' && (
                  <UICheckbox
                    check={isSelectedAllCompanies}
                    handleClick={() => {
                      setIsSelectedAllCompanies(!isSelectedAllCompanies);
                      setSelectedCompaniesId([]);
                      setUnselectedCompaniesId([]);
                    }}
                    label="Select All"
                  />
                )}

                {values.dataType === 'contact' && (
                  <>
                    <CustomizeTeamplateButton
                      title={
                        <TextIconWarpper>
                          <EditIcon src={EditSVG} /> Edit Email Template
                        </TextIconWarpper>
                      }
                      modifiers="third"
                      handleClick={() => {
                        onFetchGameplanCustomizeTemplate({
                          templateType: GamePlanEmailTemplateTypeEnum.PitchTemplate,
                        });
                        showCustomizeTemplateModal();
                      }}
                    />
                    {(isSelectedAllContacts ||
                      !!selectedContactsId.length ||
                      !!unselectedContactsId.length) && (
                      <SearchContactMultPitchButton
                        contact={checkedContacts}
                        customizeTemplate={gameplanCustomizeTemplate}
                        onSendCallback={() => setSelectedContactsId([])}
                      />
                    )}
                  </>
                )}
              </Actions>
            </SecondHeader>

            {companies.length || companiesContacts.length ? (
              values.dataType === 'company' ? (
                <CompanySearchResult
                  companies={companies}
                  isSelectedAllCompanies={isSelectedAllCompanies}
                  setIsSelectedAllCompanies={setIsSelectedAllCompanies}
                  selectedCompaniesId={selectedCompaniesId}
                  setSelectedCompaniesId={setSelectedCompaniesId}
                  unselectedCompaniesId={unselectedCompaniesId}
                  setUnselectedCompaniesId={setUnselectedCompaniesId}
                  rankText={
                    urlSearchParams.filters?.variantForm === 'Customize'
                      ? CardCompanyRankText['Customize Ranking']
                      : undefined
                  }
                />
              ) : (
                <ContactsSelectedTable
                  rows={companiesContacts}
                  selectedContactsIds={selectedContactsId}
                  unselectedContactsIds={unselectedContactsId}
                  selectedAll={isSelectedAllContacts}
                  onCheckAll={onCheckAll}
                  onCheckRow={onCheckRow}
                  sortStatus={sortStatus}
                  onSort={col => handleSwitch(col)}
                />
              )
            ) : (
              <Empty />
            )}
          </>
        )}
      </FormSpy>
    </SearchWrapper>
  );
};

const SuggestionActions = styled.div`
  display: flex;

  button:nth-child(-n + 2) {
    margin-right: 40px;
  }
`;

const Empty = styled(EmptyCompany)`
  height: calc(100vh - 330px);
`;

const Total = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  font-family: Rubik;
  font-style: normal;
  margin-bottom: 26px;
  display: flex;
  justify-content: space-between;
  max-width: ${PAGE_WIDTH.NORMAL}px;
`;

const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 27px;
  max-width: ${PAGE_WIDTH.NORMAL}px;
`;

const ContactsHeaderList = styled(HeaderList)`
  max-width: unset;
`;

const DisplaySelect = styled(FieldSingleSelect)`
  width: 172px;
`;

const SecondHeader = styled.div<{ $width?: string }>`
  width: ${({ $width }) => ($width ? $width : '100%')};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const CustomizeTeamplateButton = styled(UIButton)`
  margin: 0 16px;
`;

const EditIcon = styled(ReactSVG)`
  margin-right: 8px;
`;

const TextIconWarpper = styled.div`
  display: flex;
`;

const UploadCustomListButton = styled(UIButton)`
  font-size: 12px;
`;

export default Search;
