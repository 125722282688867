import { SendEmailReq } from 'interfaces/utility';
import { fetch } from 'services/ApiService';
import StorageService from 'services/Storage';
import { paramsToQueryString } from 'utils';

export const uploadFile = (file: File, type: string) => {
  const data = new FormData();
  data.append('file', file, file?.name);

  return fetch<{ status: string; fileKey: string }>(
    'post',
    `utility/${type}`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  );
};

export const sendEmail = (param: SendEmailReq[]) =>
  fetch<{ status: string }>('post', 'utility/sendEmail', { payload: param });

export const fetchSignedUrl = (key: string) =>
  fetch<{ result: string }>('post', `auth/get_signed_url`, {
    payload: { key },
  });

export const fetchImage2Base64 = (imgKey: string) =>
  fetch<string>(
    'post',
    `utility/image2Base64${paramsToQueryString({ imageKey: imgKey })}`,
  );

export const fetchEmailPreview = (payload: SendEmailReq) =>
  fetch<string>('post', `utility/emailPreview`, { payload });

export const fetchCanvaLogin = (params: { canvaUserId: string; state: string }) =>
  fetch<string>('post', 'canva/login', { payload: params });

export const fecthGetSupportOktaUser = () =>
  fetch<{
    username: string;
    password: string;
  }>('get', 'canva/get-support-okta-user');
