/* eslint-disable react-hooks/exhaustive-deps */
import { UISpinner } from 'components/UI';
import { useOperation } from 'hooks/common';
import { SendGridTemplate } from 'interfaces/emailDesign';
import OrganizationWrapper from 'layouts/OrganizationWrapper';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  clearEmailDesign,
  fetchEmailDesign,
  fetchEmailDesignCreate,
  fetchEmailDesignUpdate,
} from 'store/ducks/team/operations';
import { getEmailDesign } from 'store/ducks/team/selectors';
import styled from 'styled-components';
import EmailDesignForm from './components/EmailDesignForm';
import EmailDesignSelects from './components/EmailDesignSelects';

const EmailDesign = () => {
  const [onFetchEmailDesign, , isLoading] = useOperation(fetchEmailDesign);
  const [onFetchEmailDesignUpdate] = useOperation(fetchEmailDesignUpdate);
  const [onFetchEmailDesignCreate] = useOperation(fetchEmailDesignCreate);
  const [onClearEmailDesign] = useOperation(clearEmailDesign);

  const [selectValues, setSelectValues] = useState<
    Pick<SendGridTemplate, 'emailType' | 'templateId'>
  >({
    emailType: 'Pitch',
    templateId: '',
  });

  const { attribute, template } = useSelector(getEmailDesign);

  useEffect(
    () => () => {
      onClearEmailDesign(undefined);
    },
    [],
  );

  return (
    <>
      <OrganizationWrapper>
        <Body>
          {isLoading && <Spinner />}
          <FormWrapper>
            <EmailDesignSelects
              handleFetchEmailDesign={onFetchEmailDesign}
              handleChange={v => setSelectValues(v)}
            />
            <EmailDesignForm
              initialValues={attribute as SendGridTemplate}
              selectValues={selectValues}
              handleSubmit={values => {
                if (values.id) {
                  onFetchEmailDesignUpdate({ ...values, status: true }).then(() => {
                    onFetchEmailDesign({
                      templateType: values.emailType,
                      templateId: values.templateId,
                    });
                  });
                  return;
                }

                onFetchEmailDesignCreate({
                  ...values,
                  ...selectValues,
                  status: true,
                }).then(() => {
                  onFetchEmailDesign({
                    templateType: selectValues.emailType,
                    templateId: selectValues.templateId,
                  });
                });
              }}
            />
          </FormWrapper>

          <PreviewHTML sandbox="allow-same-origin" srcDoc={template} />
        </Body>
      </OrganizationWrapper>
    </>
  );
};

const Body = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 40px 40px 80px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  min-height: 670px;
  position: relative;
`;

const PreviewHTML = styled.iframe`
  width: 345px;
  height: 433px;
  border: 1px solid var(--blue);
  border-radius: 6px;
  margin: 100px auto 0;
`;

const Spinner = styled(UISpinner)`
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default EmailDesign;
