/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { fetchCanvaLogin } from 'store/ducks/utility/api';
import queryString from 'query-string';
import styled from 'styled-components';

const CanvaLogin = () => {
  const [loading, setLoading] = useState(false);
  const { state, user } = queryString.parse(window.location.search) as {
    state: string;
    user: string;
  };

  useEffect(() => {
    setLoading(true);
    fetchCanvaLogin({ canvaUserId: user, state })
      .then(url => {
        window.location.href = url;
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Root>
      <Description>{loading ? 'Authentication...' : 'Success'}</Description>
    </Root>
  );
};

const Root = styled.div`
  height: 100%;
`;

const Description = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default CanvaLogin;
