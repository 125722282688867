/* eslint-disable react-hooks/exhaustive-deps */
import CardCompany from 'components/CardCompany';
import Jumbotron from 'components/Jumbotron';
import List from 'components/List';
import Pagination from 'components/Pagination';
import { PAGE_WIDTH } from 'constants/common';
import { useOperation } from 'hooks/common';
import { CardCompanyProps, PaginationMeta } from 'interfaces/common';
import { SearchCompany } from 'interfaces/companies';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  fetchCompaniesBlocked,
  fetchCompaniesUnlock,
} from 'store/ducks/companies/operations';
import {
  getCompaniesBlocked,
  getCompaniesBlockedMeta,
} from 'store/ducks/companies/selectors';
import { StateProps } from 'store/interfaces';
import styled from 'styled-components';

const BlockedCompanies = () => {
  const companies = useSelector<StateProps, SearchCompany[]>(getCompaniesBlocked);
  const meta = useSelector<StateProps, PaginationMeta>(getCompaniesBlockedMeta);
  const [onFetchCompanies, isLoading] = useOperation(fetchCompaniesBlocked);
  const [onFetchUnblock] = useOperation(fetchCompaniesUnlock);

  useEffect(() => {
    onFetchCompanies(1);
  }, []);

  const onUnblock = (id: string) => {
    onFetchUnblock(id).then(() => onFetchCompanies(1));
  };

  return (
    <ContentBlockWrapper header={<Jumbotron name="Blocked" back />} loading={isLoading}>
      <HeaderList>
        <Total>{`${meta.totalPages} companies`}</Total>
        <Pagination
          total={meta.totalItems}
          page={Number(meta.currentPage)}
          pageLimit={Number(meta.itemsPerPage)}
          submitHandler={page => {
            onFetchCompanies(page);
          }}
        />
      </HeaderList>
      <List<SearchCompany, CardCompanyProps>
        list={companies}
        component={CardCompany}
        isIndexKey
        componentProps={{
          isUnBlocked: true,
          unBlockHandler: onUnblock,
        }}
      />
    </ContentBlockWrapper>
  );
};

const Total = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  font-family: Rubik;
  font-style: normal;
`;

const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  max-width: ${PAGE_WIDTH.NORMAL}px;
`;

export default BlockedCompanies;
