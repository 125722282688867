/* eslint-disable react-hooks/exhaustive-deps */
import { useDownloadFile, useOperation } from 'hooks/common';
import OrganizationWrapper from 'layouts/OrganizationWrapper';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchDownloadEmailIntroduce } from 'store/ducks/gameplan/api';
import { fetchTeam, fetchTeamUpdate } from 'store/ducks/team/operations';
import { getTeam } from 'store/ducks/team/selectors';
import SettingForm from './components/SettingForm';

const Setting = () => {
  const [onFetchTeam, isLoading] = useOperation(fetchTeam);
  const [onUpdateTeam] = useOperation(fetchTeamUpdate);

  const downloadEmailIntroduce = useDownloadFile(fetchDownloadEmailIntroduce);

  const team = useSelector(getTeam);

  useEffect(() => {
    onFetchTeam(undefined);
  }, []);

  if (!team) return null;
  return (
    <OrganizationWrapper isLoading={isLoading}>
      <SettingForm
        initialValues={team}
        downloadEmailIntroduce={downloadEmailIntroduce}
        onSubmit={values =>
          onUpdateTeam({
            ...values,
            charitablePartners: values.charitablePartners.map(partner => partner.id),
          })
        }
      />
    </OrganizationWrapper>
  );
};

export default Setting;
