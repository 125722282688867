/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { SingleSelect } from 'components/UI';
import styled from 'styled-components';
import { FormError } from 'components/UI/form';
import { SingleSelectProps } from 'components/UI/interfaces';
import { FormLabel } from 'components/UI/form';

interface FieldSelectProps
  extends SingleSelectProps,
    FieldRenderProps<string | number | boolean | object | null> {
  objectField: boolean;
  onFieldChange?: (value: FieldSelectProps['options'][0]['value']) => void;
}

const FieldSingleSelect = ({
  input,
  label,
  meta,
  objectField = false,
  style,
  placeholder = 'Select',
  onFieldChange,
  ...selectProps
}: FieldSelectProps) => {
  const { error, submitError, touched, dirtySinceLastSubmit } = meta;
  const isError =
    (!selectProps.disabled && error && touched) || (submitError && !dirtySinceLastSubmit);

  const handleClick = useCallback(
    valueType => {
      input.onChange(objectField ? valueType : valueType?.value);
      onFieldChange?.(objectField ? valueType : valueType?.value);
    },
    [input],
  );

  return (
    <Field style={style}>
      {label && <FormLabel>{label}</FormLabel>}
      <SingleSelect
        {...selectProps}
        placeholder={placeholder}
        value={input.value}
        handleSelect={handleClick}
        error={isError}
        onBlur={input.onBlur}
      />
      {isError && <Error meta={meta} />}
    </Field>
  );
};

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const Error = styled(FormError)`
  position: absolute;
`;

export default FieldSingleSelect;
