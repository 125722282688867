import React, { ReactNode, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { UIActionText } from 'components/UI';

interface Props {
  title: string;
  defaultStatus?: boolean;
  className?: string;
  children?: ReactNode;
  hasUnsetHeight?: boolean;
}

const ShowMoreBlock = ({
  title,
  children,
  className,
  defaultStatus = false,
  hasUnsetHeight = false,
}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [showState, setShowState] = useState(defaultStatus);
  const [contentHeight, setContentHeight] = useState(0);
  const [unsetHeightState, setUnsetHeightState] = useState(hasUnsetHeight);

  const handleToggleShow = () => {
    setShowState(!showState);
    setUnsetHeightState(unsetHeightState);

    if (!contentRef.current) return;
    const el = contentRef.current;
    if (!showState) {
      setTimeout(() => {
        el.style.overflow = 'visible';
      }, 600);
      return;
    }
    el.style.overflow = 'hidden';
  };

  useEffect(() => {
    if (!contentRef.current) return;
    if (defaultStatus) {
      const el = contentRef.current;
      setTimeout(() => {
        el.style.overflow = 'visible';
      }, 600);
    }
    setContentHeight(contentRef.current.scrollHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      <TitleWrapper>
        <Title>{title}</Title>
        <ActionText onClick={handleToggleShow}>
          {showState ? 'see less' : 'see more'}
        </ActionText>
      </TitleWrapper>
      <Content
        ref={contentRef}
        $height={contentHeight}
        $hasUnsetHeight={unsetHeightState}
        $show={showState}
      >
        {children}
      </Content>
    </div>
  );
};

const TitleWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
`;

const Content = styled.div<{ $show: boolean; $height: number; $hasUnsetHeight: boolean }>`
  transition: all 0.5s;
  overflow: hidden;

  max-height: ${({ $show, $height, $hasUnsetHeight }) =>
    $show ? ($hasUnsetHeight ? 'unset' : `${$height}px`) : '0px'};
  margin-bottom: ${({ $show }) => ($show ? `24px` : '0px')};
`;

const ActionText = styled(UIActionText)`
  font-weight: 400;
  font-size: 12px;
`;

export default ShowMoreBlock;
