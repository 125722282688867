/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { AuthenticatedWrapper } from 'layouts';

import Dashboard from 'pages/organizationUser/dashboard';
import Membership from 'pages/organizationUser/membership';
import {
  Prospecting,
  Prospects,
  ProspectsList,
  Search,
} from 'pages/organizationUser/search';
import {
  Profile,
  UserProfile,
  Users,
  ProfileEdit,
  Password,
} from 'pages/organizationUser/team';
import {
  BlockedCompanies,
  Company,
  Contacts,
  CompanyFavorites,
} from 'pages/organizationUser/companies';

import Route from './Route';
import {
  CreateGamePlan,
  EditGamePlan,
  GamePlanListBySavelist,
  Gameplans,
  ViewGamePlanTemplate,
} from 'pages/organizationUser/gameplan';
import Demo from 'pages/demo';
import {
  Pricing,
  EditValuationDetail,
  ValuationReport,
} from 'pages/organizationUser/pricing';
import {
  ROI,
  ROIEdit,
  ROIReport,
  ROISlimReport,
  ROITrackingDashboard,
} from 'pages/organizationUser/roi';
import Campaign from 'pages/organizationUser/Fundraising/index';
import Setting from 'pages/organizationUser/team/Setting';
import CompanyRecentlyAdded from 'pages/organizationUser/companies/CompanyRecentlyAdded';
import EditDraftGameplan from 'pages/organizationUser/gameplan/EditDraftGameplan/EditDraftGameplan';
import { useSelector } from 'react-redux';
import { StateProps } from 'store/interfaces';
import { SubscriptionProps } from 'interfaces/team';
import { getSubscription } from 'store/ducks/team/selectors';
import { fetchSubscriptionTeams } from 'store/ducks/team/operations';
import { useOperation } from 'hooks/common';
import { NoSubscriptionsRoute } from '.';
import styled from 'styled-components';
import { UISpinner } from 'components/UI';
import EmailDesign from 'pages/organizationUser/team/EmailDesign';
import ROITracking from 'pages/organizationUser/roi/ROITracking';
import useRedirect from 'hooks/common/useRedirect';
import ROIReportEdit from 'pages/organizationUser/roi/ROIReportEdit';
import { NotFound } from 'components/NotFound';
import ConsumerSurvey from 'pages/survey/ConsumerSurvey';
import EmployeeSurvey from 'pages/survey/EmployeeSurvey';
import CanvaLogin from 'pages/auth/CanvaLogin';
import { ROISurveyList } from 'pages/organizationUser/roi/ROISurveyList';
import { ROISurveyResponse } from 'pages/organizationUser/roi/ROISurveyResponse';
import GiveATWork from 'pages/organizationUser/Fundraising/GiveATWork';
import { Audience } from 'pages/organizationUser/audience/Audience';
import GiveandShop from 'components/Campaign/GiveandShop';
import { ROIViewCompany } from 'pages/organizationUser/roi/ROIViewCompany';
import IssueMappingEmployeeSurvey from 'pages/survey/IssueMappingEmployeeSurvey';
import EmailTemplates from 'pages/organizationUser/team/EmailTemplates';
import NoContactList from 'pages/organizationUser/team/NoContactList';

const AuthorizedOrganizationRoutes = () => {
  const [onFetchSubscriptionTeams, isLoadingSubscription] = useOperation(
    fetchSubscriptionTeams,
  );

  const subscription = useSelector<StateProps, SubscriptionProps>(getSubscription);

  useRedirect();

  useEffect(() => {
    onFetchSubscriptionTeams(undefined);
  }, []);

  if (isLoadingSubscription) {
    return <Spinner />;
  }

  return subscription.name ? (
    <Switch>
      <Route path="/canva-login" component={CanvaLogin} />

      <Route path="/" layout={AuthenticatedWrapper}>
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/search" component={Search} />
        <Route path="/prospecting" component={Prospecting} />
        <Route exact path="/prospects" component={Prospects} />
        <Route path="/prospects/:prospectsId" component={ProspectsList} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/gameplan/create/:prospectsId" component={CreateGamePlan} />
        <Route exact path="/gameplan/edit/:prospectsId" component={EditGamePlan} />
        <Route
          exact
          path="/gameplan/edit/:prospectsId/:gameplanId"
          component={EditDraftGameplan}
        />
        <Route
          exact
          path="/gameplan/list-by-savelist/:prospectsName/:prospectsId"
          component={GamePlanListBySavelist}
        />
        <Route
          exact
          path="/gameplan/view-template/:gameplanName/:gameplanId"
          component={ViewGamePlanTemplate}
        />
        <Route exact path="/gameplan/gameplans" component={Gameplans} />

        <Route path="/profile/edit" component={ProfileEdit} />
        <Route path="/password" component={Password} />
        <Route path="/setting" component={Setting} />
        <Route path="/email-design" component={EmailDesign} />
        <Route path="/email-templates" component={EmailTemplates} />
        <Route path="/no-contact" component={NoContactList} />

        <Route path="/user-profile" component={UserProfile} />

        <Route path="/teams/:teamId" component={Users} />
        <Route path="/teams" component={Users} />
        <Route path="/companies/blocked" component={BlockedCompanies} />
        <Route path="/company/:companyId" component={Company} />
        <Route path="/membership" component={Membership} />
        <Route path="/company-contacts/:companyId" component={Contacts} />
        <Route path="/company-favorites" component={CompanyFavorites} />
        <Route path="/companies-recently-added" component={CompanyRecentlyAdded} />

        <Route path="/audience" component={Audience} />

        <Route path="/pricing" exact component={Pricing} />
        <Route path="/pricing/edit/:valuationId" component={EditValuationDetail} />
        <Route path="/pricing/report/:valuationId" component={ValuationReport} />

        <Route path="/roi" exact component={ROI} />
        <Route path="/roi/edit/:roiId" component={ROIEdit} />
        <Route path="/roi/report/:roiId" component={ROIReport} />
        <Route path="/roi/tracking-dashboard/:roiId" component={ROITrackingDashboard} />
        <Route path="/roi/tracking/:roiId" component={ROITracking} />
        <Route path="/roi/report-edit/:roiId" component={ROIReportEdit} />
        <Route path="/roi/slim-report/:roiId" component={ROISlimReport} />
        <Route path="/roi/survey-list/:pageType" component={ROISurveyList} />
        <Route
          path="/roi/survey-response/:surveyId/:roiId/:surveyType"
          component={ROISurveyResponse}
        />
        <Route path="/roi/view-company/:companyId" component={ROIViewCompany} />

        <Route path="/campaign" exact component={Campaign} />
        <Route path="/campaign/give-work" exact component={GiveATWork} />
        <Route path="/campaign/give-shop" exact component={GiveandShop} />

        {/* {process.env.NODE_ENV === 'development' && ( */}
        <Route path="/demo" component={Demo} />
        {/* )} */}

        <Route path="/survey/consumer" component={ConsumerSurvey} />
        <Route path="/survey/employee" component={EmployeeSurvey} />
        <Route
          path="/survey/issuemapping-employee"
          component={IssueMappingEmployeeSurvey}
        />

        <Route path="/404" component={NotFound} />
        <Route path="/" component={() => <Redirect to="/dashboard" />} />
      </Route>
    </Switch>
  ) : (
    <NoSubscriptionsRoute />
  );
};

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default AuthorizedOrganizationRoutes;
