import React from 'react';

import { UIActionButton } from 'components/UI';
import { ActionButtonProps as InjectedProps } from 'interfaces/common';
import DownloadIcon from 'assets/images/icons/download.svg';

interface BaseProps {
  uploadHandler: () => void;
}

type Props = BaseProps & Partial<InjectedProps>;

const ExportButton = ({
  uploadHandler,
  name = 'Export to Excel',
  icon = DownloadIcon,
  isloading = false,
  ...rest
}: Props) => {
  return (
    <UIActionButton
      {...rest}
      handleClick={uploadHandler}
      icon={icon}
      name={name}
      isloading={isloading}
    />
  );
};

export default ExportButton;
