import React, { useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { parseLink } from 'utils';
import {
  Root,
  Body,
  Header,
  HeaderInfo,
  LoadImage,
  OrganizationTitle,
  OrganizationType,
  SocialLink,
  DescTitle,
  ContactItem,
  ContactIcon,
  ContactLink,
  AlignmentList,
  AlignmentItem,
  InfoTitle,
  InfoValue,
  MissionWrapper,
  MissionTitle,
  MissionSubTitle,
  MissionValue,
  SocialWrapper,
  EmptyAlignment,
  Content,
  ImageEmpty,
  ContributionsWrapper,
  DescLabel,
  DescValue,
  PartnersLogoWrapper,
  PartnersImg,
  PartnersEmptyImg,
  EmptyPartners,
  EmptyPartnersImgWrapper,
  OpportunitiesValueWrapper,
  OpportunitiesValue,
} from 'pages/organizationUser/team/Profile/styled';
import UserIcon from 'assets/images/icons/user.svg';
import PhoneIcon from 'assets/images/icons/phone.svg';
import EmailIcon from 'assets/images/icons/email.svg';
import WebSiteIcon from 'assets/images/icons/website.svg';
import LinkedInIcon from 'assets/images/icons/linkedIn.svg';
import TwitterIcon from 'assets/images/icons/twitter.svg';
import { goalAlignmentOptions } from 'constants/goalAlignment';
import { CropText, UIDollarNumber } from 'components/UI';
import EmptyCompany from 'assets/images/icons/empty-company.svg';
import { useSelector } from 'react-redux';
import { useOperation } from 'hooks/common';
import { getTeam } from 'store/ducks/team/selectors';
import { fetchTeamById } from 'store/ducks/team/operations';
import { useParams } from 'react-router';
import { ContentBlockWrapper } from 'layouts';
import Jumbotron from 'components/Jumbotron';
import styled from 'styled-components';
import EmptyPartnerIcon from 'assets/images/empty-partner-img.svg';
import DownloadFileTag from 'components/UI/DownloadFileTag';
import { moneyNumberFormat } from 'utils/formatters';

const ROIViewTeam = () => {
  const params: { teamId: string } = useParams();
  const [onfetchTeam, isLoading] = useOperation(fetchTeamById);

  const team = useSelector(getTeam);

  useEffect(() => {
    onfetchTeam(params.teamId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.teamId]);

  if (!team) return null;

  const {
    organizationName,
    twitterLink,
    linkedinLink,
    annualTotalContributions,
    numberConstituents,
    contactName,
    contactPhone,
    contactWebsite,
    goalAlignment,
    employeeEngagementOpportunities,
    impactStory,
    teamPartnerCompanies,
    aieldActivity,
    email,
    primaryMissionFocus,
    secondaryMissionFocus,
    logoKey,
    address,
    additionalMaterials,
    opportunities,
    programFocus,
    costPerOutcome,
  } = team;

  const cropWebSite = contactWebsite && parseLink(contactWebsite);

  const twitterLinkParse = twitterLink && parseLink(twitterLink);

  const linkedinLinkParse = linkedinLink && parseLink(linkedinLink);

  const programFocusAndCostPerOutcome = programFocus?.split(',').map((item, i) => ({
    costPerOutcome: costPerOutcome?.split(',')[i],
    programFocus: item,
  }));

  return (
    <ContentBlockWrapper
      header={<Jumbotron name={team.organizationName} back />}
      loading={isLoading}
    >
      <Root>
        <Header>
          <HeaderInfo>
            {logoKey ? (
              <LoadImage imgKey={logoKey} />
            ) : (
              <ImageEmpty>
                <ReactSVG src={EmptyCompany} />
              </ImageEmpty>
            )}

            <div>
              <OrganizationTitle>
                {organizationName || 'No information'}
              </OrganizationTitle>
              <OrganizationType>{aieldActivity || 'No information'}</OrganizationType>
              <SocialWrapper>
                {twitterLink && (
                  <SocialLink href={twitterLinkParse} target="_blank">
                    <ReactSVG src={TwitterIcon} />
                  </SocialLink>
                )}

                {linkedinLink && (
                  <SocialLink href={linkedinLinkParse} target="_blank">
                    <ReactSVG src={LinkedInIcon} />
                  </SocialLink>
                )}
              </SocialWrapper>
            </div>
          </HeaderInfo>
        </Header>

        <Body>
          <Content>
            <Section>
              <Desc>
                <DescSection>
                  <DescTitle>Organization</DescTitle>

                  <ContributionsWrapper>
                    <DescLabel>Annual Total Contributions</DescLabel>
                    <DescValue>
                      <CropText
                        width={200}
                        text={
                          annualTotalContributions ? (
                            <UIDollarNumber value={annualTotalContributions} />
                          ) : (
                            'No information'
                          )
                        }
                      />
                    </DescValue>
                  </ContributionsWrapper>

                  <ContributionsWrapper>
                    <DescLabel>Number of Constituents</DescLabel>
                    <DescValue> {numberConstituents || 'No information'}</DescValue>
                  </ContributionsWrapper>
                </DescSection>

                <DescSection>
                  <DescTitle>Partners</DescTitle>

                  {teamPartnerCompanies && teamPartnerCompanies.length > 0 ? (
                    <PartnersList>
                      {teamPartnerCompanies.slice(0, 15).map(partner => (
                        <PartnersLogoWrapper key={partner.id} title={partner.companyName}>
                          {partner?.company?.logo ? (
                            <PartnersImg imgKey={partner.company.logo} />
                          ) : (
                            <PartnersEmptyImg
                              style={{
                                backgroundImage: `url(${EmptyCompany})`,
                              }}
                            />
                          )}
                        </PartnersLogoWrapper>
                      ))}
                    </PartnersList>
                  ) : (
                    <EmptyPartners>
                      <EmptyPartnersImgWrapper>
                        <img src={EmptyPartnerIcon} alt="empty partner icon" />
                      </EmptyPartnersImgWrapper>
                      <p>No partners yet</p>
                    </EmptyPartners>
                  )}
                </DescSection>
                <DescSection>
                  <DescTitle>Contact</DescTitle>
                  <ul>
                    <ContactItem>
                      <ContactIcon src={UserIcon} />
                      {contactName || 'No Fullname'}
                    </ContactItem>
                    <ContactItem>
                      <ContactIcon src={PhoneIcon} />
                      {contactPhone ? (
                        <ContactLink href={`tel:${contactPhone}`}>
                          {contactPhone}
                        </ContactLink>
                      ) : (
                        <CropText width={200} text="No phone number" />
                      )}
                    </ContactItem>

                    <ContactItem>
                      <ContactIcon src={EmailIcon} />
                      {email ? (
                        <ContactLink href={`mailto:${email}`}>{email}</ContactLink>
                      ) : (
                        <CropText width={200} text="No email" />
                      )}
                    </ContactItem>

                    <ContactItem>
                      <ContactIcon src={WebSiteIcon} />
                      {contactWebsite ? (
                        <ContactLink href={cropWebSite} target="_blank">
                          {cropWebSite}
                        </ContactLink>
                      ) : (
                        <CropText width={200} text="No website" />
                      )}
                    </ContactItem>
                  </ul>
                </DescSection>
              </Desc>
            </Section>

            <Section>
              <DescTitle>SDG Goal Alignment</DescTitle>
              {goalAlignment && goalAlignment.length > 0 ? (
                <AlignmentList>
                  {[...goalAlignment]
                    .sort(
                      (g1, g2) =>
                        goalAlignmentOptions[g1].id - goalAlignmentOptions[g2].id,
                    )
                    .map(sdg => (
                      <AlignmentItem
                        key={sdg}
                        src={goalAlignmentOptions[sdg].img}
                        alt={goalAlignmentOptions[sdg].title}
                      />
                    ))}
                </AlignmentList>
              ) : (
                <EmptyAlignment>No selected goal</EmptyAlignment>
              )}
            </Section>

            <Section>
              <InfoTitle>Program Focus</InfoTitle>
              <InfoValue>
                {programFocusAndCostPerOutcome?.map(item => (
                  <>
                    {item.programFocus && (
                      <div style={{ marginBottom: '8px', fontWeight: 500 }}>
                        {item.programFocus}
                      </div>
                    )}
                    {item.costPerOutcome && (
                      <div style={{ marginBottom: '12px' }}>
                        Cost per Outcome: {moneyNumberFormat(item.costPerOutcome)}
                      </div>
                    )}
                  </>
                ))}
              </InfoValue>
            </Section>

            <Section>
              <InfoTitle>Address</InfoTitle>
              <InfoValue>{address || 'No information'}</InfoValue>
            </Section>

            <Section>
              {(primaryMissionFocus ||
                (secondaryMissionFocus && secondaryMissionFocus.length > 0)) && (
                <MissionWrapper>
                  <MissionTitle>Mission</MissionTitle>
                  {primaryMissionFocus && (
                    <>
                      <MissionSubTitle>Primary</MissionSubTitle>
                      <MissionValue>{primaryMissionFocus.label}</MissionValue>
                    </>
                  )}

                  {secondaryMissionFocus && secondaryMissionFocus.length > 0 && (
                    <>
                      <MissionSubTitle>Secondary</MissionSubTitle>
                      <MissionValue>
                        {secondaryMissionFocus?.map(
                          (item: { value: string | number; label: string }, index) => (
                            <span key={index}>
                              {item.label}
                              {index + 1 < secondaryMissionFocus.length && `, `}
                            </span>
                          ),
                        )}
                      </MissionValue>
                    </>
                  )}
                </MissionWrapper>
              )}
            </Section>

            <Section>
              <InfoTitle>Employee Engagement Opportunities</InfoTitle>
              <InfoValue>{employeeEngagementOpportunities ? 'Yes' : 'No'}</InfoValue>
            </Section>

            <Section>
              {impactStory && (
                <>
                  <InfoTitle>Impact Story</InfoTitle>
                  <InfoValue>{impactStory}</InfoValue>
                </>
              )}
            </Section>

            <Section>
              {opportunities && opportunities.length > 0 && (
                <>
                  <InfoTitle>Opportunities</InfoTitle>
                  <OpportunitiesValueWrapper>
                    {opportunities.map((item: { text: string }, index) => (
                      <OpportunitiesValue key={index}>{item.text}</OpportunitiesValue>
                    ))}
                  </OpportunitiesValueWrapper>
                </>
              )}
            </Section>
            <Section>
              {additionalMaterials && (
                <>
                  <InfoTitle>Additional Materials</InfoTitle>
                  <InfoValue>
                    {additionalMaterials.map(item => (
                      <DownloadFileTag key={item} fileKey={item} />
                    ))}
                  </InfoValue>
                </>
              )}
            </Section>
          </Content>
        </Body>
      </Root>
    </ContentBlockWrapper>
  );
};

const Section = styled.div`
  margin-bottom: 46px;
`;

const Desc = styled.div`
  display: flex;
  gap: 100px;
  & > div {
    flex-shrink: 0;
  }
`;

const PartnersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
`;

const DescSection = styled.div`
  width: 190px;

  &:last-child {
    width: 100%;
  }
`;

export default ROIViewTeam;
