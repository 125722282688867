/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import AuthFormWrapper from 'components/AuthForms/AuthFormWrapper';
import SignUpForm from 'components/AuthForms/SignUpForm';

import { AuthData } from 'interfaces/auth';
import { fetchSignUp } from 'store/ducks/user/operations';

import { useOperation } from 'hooks/common';
import { useHistory } from 'react-router';

const SignUp = () => {
  const { location } = useHistory();
  const [onSignUp] = useOperation(fetchSignUp);
  const token = queryString.parse(location.search)?.hash || undefined;
  const email = queryString.parse(location.search)?.email || undefined;

  const signUp = useCallback((values: AuthData) => {
    const newValues = token ? { ...values, token } : values;

    return onSignUp(newValues);
  }, []);

  return (
    <Wrapper>
      <SignUpForm onSubmit={signUp} initialValues={email} />
    </Wrapper>
  );
};

const Wrapper = styled(AuthFormWrapper)`
  margin-bottom: 40px;
`;

export default SignUp;
