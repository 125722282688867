import { fetch } from 'services/ApiService';
import {
  AuthData,
  AuthResponseData,
  User,
  AdditionalInfo,
  AuthMFA,
  AuthEmail,
  FundsAuthData,
} from 'interfaces/auth';
import StorageService from 'services/Storage';
import { UserProfile } from 'components/Forms/interfaces';
import { GetParentsRegionRes } from 'interfaces/companies';

export const fetchSignIn = (data: AuthData) =>
  fetch<AuthResponseData>(
    'post',
    `auth/sign_in`,
    {
      payload: data,
    },
    {},
  );

export const fetchFundsSignIn = (data: FundsAuthData) =>
  fetch<AuthResponseData>(
    'post',
    `auth/sign_in_funds`,
    {
      payload: data,
    },
    {},
  );

export const fetchSignUp = (data: AuthData) =>
  fetch<AuthResponseData>(
    'post',
    `auth/sign_up`,
    {
      payload: data,
    },
    {},
  );

export const fetchTsSignUp = (data: AuthData) =>
  fetch<AuthResponseData>(
    'post',
    `auth/ts-signup`,
    {
      payload: data,
    },
    {},
  );

export const getCurrentUser = () => fetch<User>('get', 'user');

export const getAdditionalInfo = () =>
  fetch<AdditionalInfo>('get', 'user/additional_info');

export const fetchChangePasswordEmail = (data: AuthData) =>
  fetch(
    'post',
    `auth/change_password/send_mail`,
    {
      payload: data,
    },
    {},
  );

export const fetchVerifyToken = (data: AuthMFA) =>
  fetch(
    'post',
    `auth/mfa`,
    {
      payload: data,
    },
    {},
  );

export const sendEmailCode = (data: AuthEmail) =>
  fetch(
    'post',
    `auth/send_code`,
    {
      payload: data,
    },
    {},
  );

export const fetchChangePassword = (
  data: AuthData,
  token: string | undefined | string[],
) => {
  return fetch(
    'post',
    'auth/change_password/change',
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${token}`,
    },
  );
};

export const fetchUpdatePassword = (data: AuthData) => {
  return fetch('post', 'auth/change_password', {
    payload: data,
  });
};

export const fetchUploadUserAvatar = (file: File) => {
  const data = new FormData() as any;
  data.append('file', file, file?.name);

  const options = {
    payload: data,
  };

  const headers = {
    Authorization: `Bearer ${StorageService.getAccessToken()}`,
    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
  };

  return fetch('put', 'user/avatar_upload', options, headers);
};

export const fetchUpdatePersonalInfo = (data: UserProfile) =>
  fetch<User>('patch', 'user', {
    payload: data,
  });

export const fetchSignedUrl = (key: string) =>
  fetch('post', `auth/get_signed_url`, {
    payload: { key },
  });

export const fetchVerifyEmailStatus = () =>
  fetch<boolean>('get', 'user/verifyEmailIdentity');

export const fetchVerifyEmail = () => fetch('patch', 'user/verifyEmailIdentity');

export const fetchGetStateByCountry = (params: { countries: string[] }) =>
  fetch<string[]>('post', 'user/getStateByCountry', { payload: params });

export const fetchGetCitesByStates = (params: { states: string[] }) =>
  fetch<string[]>('post', 'user/getCitesByStates', { payload: params });

export const fetchGetParentsRegion = (params: {
  region: string;
  type: 'state' | 'city';
}) => fetch<GetParentsRegionRes>('post', 'user/getParentsRegion', { payload: params });

export const fetchContactInfo = (id: string) => {
  return fetch<{ id: string; email: string; alreadyUnsubscribed: boolean }>(
    'get',
    `unsubscribe/contact/${id}`,
    { payload: {} },
    {},
  );
};

export const fetchUnsubscribeContact = (id: string) => {
  return fetch(
    'post',
    `unsubscribe/contact/${id}`,
    { payload: { id } }, // Pass the ID in the payload for POST requests
    {},
  );
};
