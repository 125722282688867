/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useOperation } from 'hooks/common';
import {
  fetchCompanyCurrent,
  fetchCompanyCurrentContacts,
  fetchCompanyCurrentContactsDelete,
} from 'store/ducks/companies/operations';
import { getCompanyCurrentContacts } from 'store/ducks/companies/selectors';
import Jumbotron from 'components/Jumbotron';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import ContactsTable from 'components/ContactsTable';
import { StateProps } from 'store/interfaces';
import { CompanyContact } from 'interfaces/companies';

const ProfileContacts = () => {
  const [onFetchContacts, isLoading] = useOperation(fetchCompanyCurrentContacts);
  const [onFetchContactsDelete] = useOperation(fetchCompanyCurrentContactsDelete);
  const [onFetchCompany] = useOperation(fetchCompanyCurrent);

  const contacts = useSelector<StateProps, CompanyContact[]>(getCompanyCurrentContacts);

  useEffect(() => {
    onFetchContacts(undefined);
    onFetchCompany(undefined);
  }, []);

  return (
    <ContentBlockWrapper
      loading={isLoading}
      header={<Jumbotron name="Company Contacts" back />}
    >
      {contacts && (
        <>
          <ContactsCount>{contacts.length} contacts</ContactsCount>
          <ContactsTable rows={[...contacts]} onDeleteContact={onFetchContactsDelete} />
        </>
      )}
    </ContentBlockWrapper>
  );
};

const ContactsCount = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 16px;
`;
export default ProfileContacts;
