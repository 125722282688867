import React, { useMemo } from 'react';
import { SelectPanel } from 'react-multi-select-component';
import { Option } from 'react-multi-select-component/dist/lib/interfaces';
import styled from 'styled-components';
import {
  DefaultItemRendererProps,
  SelectPanelProps as InjectedProps,
} from '../interfaces';

import ItemSearchSelectPanel from './ItemSearchSelectPanel';

interface BaseProps {
  values?: string[];
  changeHandler?: (values: any) => void;
  visibleNumberToTooltip?: number;
  onItemSelect?: (current: string, status: boolean) => void;
}

type Props = BaseProps & Partial<InjectedProps>;

const SearchPanelWithCheckBoxes = ({
  options = [],
  values = [],
  changeHandler,
  disableSearch = false,
  hasSelectAll = false,
  value,
  onItemSelect,
  ...rest
}: Props) => {
  const selected = useMemo(
    () => options.filter(option => values.includes(option.value)),
    [options, values],
  );

  const handleChange = (options: Option[]) => {
    changeHandler?.(options.map(({ value }) => value));
  };

  const itemRender = (params: DefaultItemRendererProps) => (
    <ItemSearchSelectPanel
      {...params}
      onClick={() => {
        params.onClick();
        onItemSelect?.(params.option.value, !params.checked);
      }}
    />
  );

  const filterOptions = (options: Option[], filter: string) => {
    if (!filter) {
      return options;
    }

    return options.filter(item =>
      item.label.toLowerCase().includes(filter.toLowerCase()),
    );
  };

  return (
    <SelectPanelWrapper>
      <SelectPanel
        options={options}
        value={selected}
        onChange={handleChange}
        hasSelectAll={hasSelectAll}
        disableSearch={disableSearch}
        ItemRenderer={itemRender}
        focusSearchOnOpen={false}
        debounceDuration={800}
        filterOptions={filterOptions}
        {...rest}
      />
    </SelectPanelWrapper>
  );
};

const SelectPanelWrapper = styled.div`
  --rmsc-main: #e8e8e8;
  --rmsc-hover: none;
  --rmsc-selected: none;
  --rmsc-border: #e8e8e8;
  --rmsc-gray: var(--black);
  --rmsc-bg: #fff;
  --rmsc-p: 0;
  --rmsc-radius: 6px;
  --rmsc-h: 46px;

  & .select-panel > div {
    border: none;

    & input {
      border: 1px solid var(--rmsc-border);
      border-radius: var(--rmsc-radius);
      padding: 0 31px 0 16px;
    }

    & button {
      padding: 0 8px 0;
    }
  }

  & .select-panel > ul {
    margin-top: 10px;
    max-height: 177px;
    overflow-y: auto;
  }

  & .select-item {
    &:focus {
      background: none;
    }

    &:hover {
      background: var(--rmsc-hover);
    }
  }
`;

export default SearchPanelWithCheckBoxes;
