import { StateProps } from 'store/interfaces';
import { createSelector } from 'reselect';

export const getSavedListsTeams = (state: StateProps) =>
  state.prospectsTeams.savedLists.items;
export const getSavedListsTeamsMeta = (state: StateProps) =>
  state.prospectsTeams.savedLists.meta;

export const getSavedListTeamsById = (listId: string) =>
  createSelector([getSavedListsTeams], lists => lists.find(({ id }) => listId === id));

export const getTotalGameplanInfoTeams = (state: StateProps) =>
  state.prospectsTeams.savedLists.gameplanInfo;

export const getSuggestSavedListTeams = (state: StateProps) =>
  state.prospectsTeams.suggestSavedLists.items.map(item => ({
    id: item.id,
    name: item.name,
  }));
export const getBatchTeam = (state: StateProps) => state.prospectsTeams.batchTeam;
