export enum FilterVariant {
  ADVANCED = 'Advanced',
  CUSTOMIZE = 'Customize',
}

export enum Income {
  'Less than $20k' = 'Less than $20k',
  '$20k - $29k' = '$20k - $29k',
  '$30k - $39k' = '$30k - $39k',
  '$40k - $49k' = '$40k - $49k',
  '$50k - $74k' = '$50k - $74k',
  '$75k - $99k' = '$75k - $99k',
  '$100k - $124k' = '$100k - $124k',
  '$125k or More' = '$125k or More',
}

export enum AgeRanges {
  '18-20' = '18-20',
  '21-25' = '21-25',
  '26-30' = '26-30',
  '31-35' = '31-35',
  '36-40' = '36-40',
  '41-45' = '41-45',
  '46-50' = '46-50',
  '51-55' = '51-55',
  '56-60' = '56-60',
  '61-65' = '61-65',
  '66-70' = '66-70',
  '71-75' = '71-75',
  '75 +' = '75 +',
}

export enum CardCompanyRankText {
  'Priority Ranking' = 'Priority Ranking',
  'Customize Ranking' = 'Customize Ranking',
}
