import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';

import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { UICheckbox } from 'components/UI';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/ducks/user/selectors';
import Storage from 'services/Storage';

interface BaseProps {
  title?: ReactNode;
  description?: ReactNode;
  onClickOk?: () => void;
}

export type Props = BaseProps & InjectedProps;

const InfoModal = ({ title, description, onClose, onClickOk, ...modalProps }: Props) => {
  useLockBodyScroll();
  const user = useSelector(selectUser);

  const [isDontShowModal, setIsDontShowModal] = useState(false);

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 24px 24px' }}
      style={{
        width: '100%',
        maxWidth: '380px',
      }}
      hideCloseIcon
    >
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
      <CheckBox
        label="Don't show this message again"
        check={isDontShowModal}
        handleClick={() => {
          setIsDontShowModal(prevIsDontShowModal => !prevIsDontShowModal);
        }}
      />
      <Button
        modifiers="primary"
        title="OK"
        handleClick={() => {
          if (isDontShowModal) Storage.setItem(`hide-view-modal-${user.email}`, 'true');
          onClickOk?.();
        }}
      />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0 auto 8px;
  text-align: center;
`;

const Description = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: var(--black);
  text-align: center;
  margin-bottom: 24px;
`;

const Button = styled(UIButton)`
  width: 100%;
  font-size: 12px;
`;

const CheckBox = styled(UICheckbox)`
  margin: 20px 20px 20px 35px;

  & > label {
    font-size: 14px;
  }
`;

export default InfoModal;
