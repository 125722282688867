import { UIButton, UILoadAvatar, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import { format } from 'date-fns';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { ObjNoteData } from 'interfaces/objNote';
import React from 'react';
import styled from 'styled-components';

interface Props extends InjectedProps {
  notes: ObjNoteData[];
}

const AdminNoteModal = ({ onClose, notes, ...modalProps }: Props) => {
  useLockBodyScroll();

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '26px 40px' }}
      hideCloseIcon
      style={{
        width: '844px',
        height: 'max-content',
        overflow: 'hidden',
      }}
      header={<ModalTitle>Admin Note</ModalTitle>}
    >
      <NoteList>
        {notes.map(item => (
          <NoteListItem key={item.id}>
            <NoteListItemHead>
              <NoteListItemAvatar imgKey={item.createUser.avatarKey} />
              <div>{`${item.createUser.firstName} ${item.createUser.lastName}`}</div>
              <NoteListItemDate>
                {format(new Date(item.createdAt), 'MMM d, yyyy h:mm aaa')}
              </NoteListItemDate>
            </NoteListItemHead>
            <NoteListItemContent>{item.message}</NoteListItemContent>
          </NoteListItem>
        ))}
      </NoteList>

      <BtnGroup>
        <Btn modifiers="secondary" title="Close" handleClick={onClose} />
      </BtnGroup>
    </UIModal>
  );
};

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Btn = styled(UIButton)`
  display: block;
  width: 87px;
  font-size: 14px;
  padding: 7px 0;
`;

const ModalTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  padding: 20px 0 16px;
`;

const NoteList = styled.div`
  height: 320px;
  overflow: auto;
`;

const NoteListItem = styled.div`
  margin-bottom: 24px;
  margin-right: 4px;
`;
const NoteListItemHead = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 150%;
`;
const NoteListItemAvatar = styled(UILoadAvatar)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
`;
const NoteListItemDate = styled.div`
  margin-left: auto;
  color: #868686;
`;

const NoteListItemContent = styled.pre`
  font-size: 16px;
  line-height: 155%;
  margin-left: 40px;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

export default AdminNoteModal;
