import React, { FC, useEffect, useRef } from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled from 'styled-components';
import { FormLabel } from 'components/UI/form';

interface Props extends FieldRenderProps<string> {
  height?: number;
  disabled?: boolean;
  toolbarHidden?: boolean;
  toolbarCustomButtons?: { id: string; iconSVG: string; onClick: () => void }[];
  id?: string;
}
const FieldWYSIWYGEditor: FC<Props> = ({
  input,
  height = 280,
  disabled,
  toolbarHidden,
  toolbarCustomButtons = [],
  label,
  id = 'div-rich-text-editor',
}) => {
  const editorRef = useRef<any>();
  const { value, onChange } = input;

  useEffect(() => {
    const editor = editorRef.current;
    if (!editor) return;
    if (editor.getHTMLCode() === value) return;
    editor.setHTMLCode(value);
  }, [value]);

  useEffect(() => {
    const customButtonsToolbarText = toolbarCustomButtons?.map(item => item.id).join();

    const editorConfig = {
      editorResizeMode: 'none',
      readOnly: disabled,
      showSelectedBlock: false,
      showFloatParagraph: false,
      enterKeyTag: 'div',
      toolbar_default: toolbarHidden
        ? ''
        : '{bold,italic,underline,forecolor,backcolor}|{justifyleft,justifycenter,justifyright,justifyfull}|{insertorderedlist,insertunorderedlist,indent,outdent,insertblockquote,insertemoji}' +
          '{paragraphs:toggle,fontname:toggle,fontsize:toggle,inlinestyle,lineheight}|{removeformat}|{insertlink,inserttable,insertimage}' +
          `#{${customButtonsToolbarText},toggleborder,fullscreenenter,fullscreenexit,undo,redo}`,
    };

    toolbarCustomButtons?.forEach(item => {
      editorConfig[`svgCode_${item.id}`] = item.iconSVG;
    });

    const editor = new (window as any).RichTextEditor(`#${id}`, editorConfig);
    editorRef.current = editor;

    toolbarCustomButtons?.forEach(item => {
      editor.attachEvent(`exec_command_${item.id}`, item.onClick);
    });

    editor.document.body.style.overflow = 'hidden';
    editor.document.body.style.fontFamily = "'Arial',sans-serif";
    editor.document.body.style.fontSize = '12pt';
    editor.setHTMLCode(value);

    editor.attachEvent('change', function() {
      onChange(editor.getHTMLCode());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EditorWrapper>
      {label && <FormLabel>{label}</FormLabel>}
      <div id={id} style={{ minWidth: 'unset', height }}>
        <p>Initial Document Content</p>
      </div>
    </EditorWrapper>
  );
};

const EditorWrapper = styled.div`
  margin-bottom: 24px;
`;

export default FieldWYSIWYGEditor;
