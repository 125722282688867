import React, { useState } from 'react';
import styled from 'styled-components';
import { moneyFormatter } from 'utils/formatters';
import { useModal, useOperation } from 'hooks/common';
import {
  fetchCampaignUpdateTS,
  clearCampaignQRCode,
  fetchCampaignByIdQRCode,
  fetchCampaignShareQrCode,
} from 'store/ducks/campaign/operations';
import { CampaignDataTS } from 'interfaces/campaign';
import iStock1 from 'assets/images/teamSnap/TSDefaultImage1.jpg';
import iStock2 from 'assets/images/teamSnap/TSDefaultImage2.jpg';
import iStock3 from 'assets/images/teamSnap/TSDefaultImage3.jpg';
import iStock4 from 'assets/images/teamSnap/TSDefaultImage4.jpg';
import iStock5 from 'assets/images/teamSnap/TSDefaultImage5.jpg';
import iStock6 from 'assets/images/teamSnap/TSDefaultImage6.jpg';
import iStock7 from 'assets/images/teamSnap/TSDefaultImage7.jpg';
import iStock8 from 'assets/images/teamSnap/TSDefaultImage8.jpg';
import iStock9 from 'assets/images/teamSnap/TSDefaultImage9.jpg';
import iStock10 from 'assets/images/teamSnap/TSDefaultImage10.jpg';
import iStock11 from 'assets/images/teamSnap/TSDefaultImage11.jpg';
import { useHistory } from 'react-router-dom';
import FieldColorsPickerForTeamsnap from './components/FieldColorsPickerForTeamsnap';
import { FundsAuthData } from 'interfaces/auth';
import { fetchFundsSignIn } from 'store/ducks/user/operations';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/ducks/user/selectors';

import { fetchCampaignPreviewUrl } from 'store/ducks/campaign/operations';
import { getCampaignQRCode } from 'store/ducks/campaign/selectors';
import CampaignQRCodeModal from './modals/CampaignQRCodeModal';
import { UISpinner } from 'components/UI';

interface CampaignProps {
  id: string;
  campaignName: string;
  giveworxCampaignId: string;
  startDate: string;
  endDate: string;
  goal: string;
  description: string;
  campaignColors?: string[];
  customContentImageName: string;
}

interface MobileCampaignsProps {
  campaigns: CampaignProps[];
}

interface ExpandedProps {
  expanded: boolean;
}

//css and styled components
const Wrapper = styled.div`
  max-width: 100%; // max width of the component
  width: 100%;
`;

const DropdownCard = styled.div`
  padding: 10px;
  border: 1px solid #e5e5e5;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: white;
  width: 100%;
`;

const formatDate = isoDate => {
  // Extract year, month, and day from the ISO date string
  const [year, month, day] = isoDate.split('-');

  // Create a date object for formatting the month name
  // Using UTC date to avoid timezone issues
  const dateForMonth = new Date(Date.UTC(year, month - 1, day));

  // Format the month name
  const monthName = dateForMonth.toLocaleString('default', {
    month: 'long',
    timeZone: 'UTC',
  });

  // Return the formatted date string
  return `${monthName} ${parseInt(day)}, ${year}`;
};

const CampaignTitle = styled.span`
  font-weight: bold;
  font-size: 24px;
  max-width: 100%;
  width: 90vw;
`;

const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); // Three equal columns
  gap: 20px;
`;

const DetailsGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  word-wrap: break-word;
`;

const DetailsTitle = styled.span`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px; // increased from 5px to 10px
`;

const DetailsValue = styled.span`
  font-size: 14px;
  margin-bottom: 10px; // added this line to give space below each value
`;

const EditableInput = styled.input`
  border: 1px solid #ddd;
  font-size: 16px;
  margin-bottom: 10px;
`;

const EyeIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4.5C7.201 4.5 2.7 7.273 1 12C2.7 16.727 7.201 19.5 12 19.5C16.799 19.5 21.3 16.727 23 12C21.3 7.273 16.799 4.5 12 4.5ZM12 17C9.238 17 7 14.762 7 12C7 9.238 9.238 7 12 7C14.762 7 17 9.238 17 12C17 14.762 14.762 17 12 17ZM12 9C10.346 9 9 10.346 9 12C9 13.654 10.346 15 12 15C13.654 15 15 13.654 15 12C15 10.346 13.654 9 12 9Z"
      fill="#007BFF"
    />
  </svg>
);

const QRCodeIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 4H10V10H4V4ZM5 5V9H9V5H5ZM4 14H10V20H4V14ZM5 15V19H9V15H5ZM14 14H20V20H14V14ZM15 15V19H19V15H15ZM14 4H20V10H14V4ZM15 5V9H19V5H15Z"
      fill="#007BFF"
    />
  </svg>
);

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 2%;
  right: 1%;
  gap: 12px; // Provides spacing between the buttons
`;

const IconButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #007bff;
`;

const SaveButton = styled(IconButton)`
  align-self: flex-end;
  color: #007bff;
`;

const EditableTextArea = styled.textarea`
  border: 1px solid #ddd;
  font-size: 16px;
  resize: vertical; // Allows vertical resizing only
  width: 100%; // To make it full width
  padding: 5px;
  margin-bottom: 10px;
`;

const DropdownIcon = styled.span<ExpandedProps>`
  transform: ${props => (props.expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s;
  font-size: 24px; // made icon larger
`;

const CampaignDetails = styled.div<ExpandedProps>`
  display: ${props => (props.expanded ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid #e5e5e5;
  background-color: white;
  position: relative;
  margin-bottom: 10px;
  width: 100%;
`;

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  justify-content: center;
`;

const CarouselImages = styled.div`
  position: relative;
  height: 100wh;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BulletContainer = styled.div`
  display: block;
  margin: 12px auto -6px;
  text-align: center;
  white-space: nowrap;
  z-index: 10;
`;

const Bullet = styled.a`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 4px 0 4px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0);
  border: 1px solid #6e7c7f;

  &.active {
    box-shadow: inset 0 0 0 1px #12171b; /* Separates color fill from border */
    background-color: #d5f3ff;
  }
`;

const CarouselImage = styled.img`
  width: 100%;
  max-width: 400px;
  height: auto;
  margin: 0 2%;
  opacity: 0.3;
  transition: opacity 0.3s;
  transition-duration: 1s ease;
  border-radius: 15px;
  display: none;

  &.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
    display: block;
  }
`;

const Arrow = styled.span`
  cursor: pointer;
  position: absolute;
  z-index: 1;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #19191969;

  &:before {
    position: absolute;
    content: '';
    padding: 8px; /* Arrow size */
    box-shadow: 1px -1px 0 1px #d5f3ff inset;
    -webkit-box-shadow: 2px -2px #d5f3ff inset;
    -moz-box-shadow: 2px -2px #d5f3ff inset;
    border: solid transparent;
    border-width: 0 0 2rem 2rem;
  }

  &.left {
    left: 10px;
  }

  &.left:before {
    margin: -4px 0 0 -24px;
    transform: rotate(45deg);
  }

  &.right {
    right: 10px;
  }

  &.right:before {
    transform: rotate(-135deg);
    margin: -4px 0 0 16px;
  }
`;

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Residue = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -24px;
  height: 24px;
`;

const formatISODate = (isoDate: string) => {
  const date = new Date(isoDate);
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
    date.getDate(),
  ).padStart(2, '0')}`;
};

const stockImages = [
  iStock1,
  iStock2,
  iStock3,
  iStock4,
  iStock5,
  iStock6,
  iStock7,
  iStock8,
  iStock9,
  iStock10,
  iStock11,
];

const Campaign = ({
  id,
  campaignName,
  giveworxCampaignId,
  startDate,
  endDate,
  goal,
  description,
  campaignColors,
  customContentImageName,
}: CampaignProps) => {
  const intiIndex = stockImages.findIndex(x =>
    x.includes(
      customContentImageName
        .split('.')
        .slice(0, -1)
        .join('.'),
    ),
  );

  const [expanded, setExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editableCampaignName, setEditableCampaignName] = useState(campaignName);
  const [editableDescription, setEditableDescription] = useState(description);
  const [editableGoal, setEditableGoal] = useState(goal);
  const [editableStartDate, setEditableStartDate] = useState(formatISODate(startDate));
  const [editableEndDate, setEditableEndDate] = useState(formatISODate(endDate));
  const [selectedColors, setSelectedColors] = useState<string[]>(campaignColors || []);
  const [currentImageIndex, setCurrentImageIndex] = useState(intiIndex);
  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const [onFetchCampaignUpdateTS, isUpdating] = useOperation(fetchCampaignUpdateTS);
  const [onfetchCampaignPreviewUrl, loading] = useOperation(fetchCampaignPreviewUrl);
  const [onFetchCampaignQRCode, isQRCodeLoading] = useOperation(fetchCampaignByIdQRCode);
  const [onFetchCampaignShareCode, , isShareCodeLoading] = useOperation(
    fetchCampaignShareQrCode,
  );
  const [onClearCampaignQRCode] = useOperation(clearCampaignQRCode);

  const history = useHistory();

  const user = useSelector(selectUser);

  const [onLogin] = useOperation(fetchFundsSignIn);

  const twofactor = useCallback((values: FundsAuthData) => {
    return onLogin(values, history);
  }, []);

  const redirectToPayout = giveworxCampaignId => {
    twofactor({ email: user.email, giveworxCampaignId: giveworxCampaignId });
  };

  const QRCodeBase64 = useSelector(getCampaignQRCode);

  const dt = new Date(formatISODate(endDate));
  const today = new Date();

  const isCampaignEnded = dt < today;

  const [showQRCodeModal, hideQRCodeModal] = useModal(() => {
    const closeModal = () => {
      hideQRCodeModal();
      // setCampaignPartnerId('');
      onClearCampaignQRCode(undefined);
    };
    return (
      <CampaignQRCodeModal
        qrCodeBase64={QRCodeBase64}
        isloading={isShareCodeLoading}
        isQRCodeLoading={isQRCodeLoading}
        campaignName={campaignName}
        onClose={closeModal}
        onSend={values => {
          onFetchCampaignShareCode({ ...values, campaignId: id });
        }}
      />
    );
  }, [QRCodeBase64, isQRCodeLoading, id, isShareCodeLoading, campaignName]);

  const redirectToGiveworx = () => {
    onfetchCampaignPreviewUrl(id).then((previewUrl: any) => {
      if (previewUrl) {
        window.location.href = previewUrl;
      } else {
        console.log('');
      }
    });
  };

  const handleColorChange = (newColors: string[]) => {
    setSelectedColors(newColors);
  };

  const nextImage = () => {
    if (currentImageIndex < stockImages.length - 1)
      setCurrentImageIndex(prev => prev + 1);
  };

  const prevImage = () => {
    if (currentImageIndex > 0) setCurrentImageIndex(prev => prev - 1);
  };

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    setTouchStartX(event.touches[0].clientX);
  };

  const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    const touchEndX = event.changedTouches[0].clientX;
    const difference = touchStartX! - touchEndX;

    if (difference > 50) {
      // Swiped left
      nextImage();
    } else if (difference < -50) {
      // Swiped right
      prevImage();
    }

    setTouchStartX(null);
  };

  const handleDropdownClick = () => {
    setExpanded(!expanded);
  };

  const handleEditClick = () => setIsEditing(true);
  const handleCancelClick = () => {
    setIsEditing(false);
    setEditableCampaignName(campaignName);
    setEditableDescription(description);
    setEditableGoal(goal);
    setEditableStartDate(formatISODate(startDate));
    setEditableEndDate(formatISODate(endDate));
    setSelectedColors(campaignColors || []);
    setCurrentImageIndex(intiIndex);
  };
  const handleSaveClick = () => {
    const campaignData: CampaignDataTS = {
      id: id,
      campaignName: editableCampaignName,
      giveworxCampaignId: giveworxCampaignId,
      campaignDescription: editableDescription,
      startDate: editableStartDate,
      endDate: editableEndDate,
      fundraisingGoal: editableGoal,
      selectCampaignColors: selectedColors,
      selectedImage: `TSDefaultImage${currentImageIndex + 1}.jpg`,
    };
    onFetchCampaignUpdateTS(campaignData).then(() => {
      setIsEditing(false);
    });
  };

  const formattedGoal = isEditing
    ? editableGoal
    : moneyFormatter.from(Number(goal), { symbol: '$' });

  // if (isUpdating) {
  //   return <Spinner />;
  // }

  return (
    <Wrapper>
      <DropdownCard onClick={handleDropdownClick}>
        <CampaignTitle>{campaignName}</CampaignTitle>
        <DropdownIcon expanded={expanded}>&lt;</DropdownIcon>
      </DropdownCard>
      <CampaignDetails expanded={expanded}>
        {!isEditing && (
          <ButtonContainer>
            {isCampaignEnded && (
              <IconButton onClick={() => redirectToPayout(giveworxCampaignId)}>
                $
              </IconButton>
            )}
            <IconButton
              onClick={() => {
                onFetchCampaignQRCode(id);
                showQRCodeModal();
              }}
            >
              <QRCodeIcon />
            </IconButton>
            <IconButton onClick={redirectToGiveworx}>
              <EyeIcon />
            </IconButton>
            <IconButton onClick={handleEditClick}>&#x270E;</IconButton>
          </ButtonContainer>
        )}

        <DetailsTitle>Campaign Name</DetailsTitle>
        {isEditing ? (
          <EditableInput
            value={editableCampaignName}
            onChange={e => setEditableCampaignName(e.target.value)}
          />
        ) : (
          <DetailsValue>{campaignName}</DetailsValue>
        )}

        <DetailsContainer>
          <DetailsGroup>
            <DetailsTitle>Launch Date</DetailsTitle>
            {isEditing ? (
              <EditableInput
                type="date"
                value={editableStartDate}
                onChange={e => setEditableStartDate(e.target.value)}
              />
            ) : (
              <DetailsValue>{formatDate(editableStartDate)}</DetailsValue>
            )}
          </DetailsGroup>
          <DetailsGroup>
            <DetailsTitle>End Date</DetailsTitle>
            {isEditing ? (
              <EditableInput
                type="date"
                value={editableEndDate}
                onChange={e => setEditableEndDate(e.target.value)}
              />
            ) : (
              <DetailsValue>{formatDate(editableEndDate)}</DetailsValue>
            )}
          </DetailsGroup>
          <DetailsGroup>
            {!isEditing && (
              <>
                <DetailsTitle>Fundraising Goal</DetailsTitle>
                <DetailsValue>{formattedGoal}</DetailsValue>
              </>
            )}
          </DetailsGroup>
        </DetailsContainer>

        {isEditing && (
          <>
            <DetailsTitle>Fundraising Goal</DetailsTitle>
            <EditableInput
              type="number"
              min="0"
              value={editableGoal}
              onChange={e => setEditableGoal(e.target.value)}
              placeholder="Enter amount"
            />
          </>
        )}

        {/* <DetailsTitle>Description</DetailsTitle>
        {isEditing ? (
          <EditableTextArea
            value={editableDescription}
            onChange={e => setEditableDescription(e.target.value)}
            rows={5}
          />
        ) : (
          <DetailsValue>{description}</DetailsValue>
        )} */}
        <DetailsTitle>Description</DetailsTitle>
        {isEditing ? (
          <>
            <EditableTextArea
              value={editableDescription}
              onChange={e => {
                let newValue = e.target.value;
                if (newValue.length > 255) {
                  newValue = newValue.slice(0, 255);
                }
                setEditableDescription(newValue);
                const remainingCharacters = 255 - newValue.length;
              }}
              onKeyPress={e => {
                if (
                  e.key === 'Enter' &&
                  editableDescription.length >= 255
                  // editableDescription.length >= 255 ||
                  // e.charCode === 13
                ) {
                  e.preventDefault();
                }
              }}
              placeholder="Maximum limit: 255 characters"
              rows={5}
            />
            <Residue>
              <div></div>
              <div style={{ color: '#656469', fontSize: '12px' }}>
                {255 - editableDescription.length} characters remaining
              </div>
            </Residue>
          </>
        ) : (
          <DetailsValue>{description}</DetailsValue>
        )}

        <DetailsTitle>Campaign Colors</DetailsTitle>
        {isEditing ? (
          <FieldColorsPickerForTeamsnap
            input={{
              value: selectedColors,
              onChange: handleColorChange,
              onBlur: () => {},
              name: 'campaignColors', // Added this line
              onFocus: () => {}, // Added this line
            }}
            meta={{}}
          />
        ) : (
          <DetailsValue>
            <FieldColorsPickerForTeamsnap
              disabled={true}
              input={{
                value: selectedColors,
                onChange: handleColorChange,
                onBlur: () => {},
                name: 'campaignColors', // Added this line
                onFocus: () => {}, // Added this line
              }}
              meta={{}}
            />
          </DetailsValue>
        )}

        <DetailsTitle>Campaign Header Image</DetailsTitle>
        <CarouselContainer onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
          {isEditing ? (
            <CarouselImages>
              {stockImages.map((img, index) => (
                <CarouselImage
                  key={index}
                  src={img}
                  alt={`Stock Image ${index + 1}`}
                  className={index === currentImageIndex ? 'active' : ''}
                />
              ))}
              <>
                {currentImageIndex !== 0 && (
                  <Arrow onClick={prevImage} className="left"></Arrow>
                )}

                {currentImageIndex < stockImages.length - 1 && (
                  <Arrow onClick={nextImage} className="right"></Arrow>
                )}
              </>
            </CarouselImages>
          ) : (
            <CarouselImage
              key={currentImageIndex}
              src={stockImages[currentImageIndex]}
              alt={`Stock Image ${currentImageIndex + 1}`}
              className={'active'}
            />
          )}
        </CarouselContainer>

        {isEditing && (
          <BulletContainer>
            {stockImages.map((img, index) => (
              <Bullet
                key={index}
                className={index === currentImageIndex ? 'active' : ''}
              />
            ))}
          </BulletContainer>
        )}

        {isEditing && (
          <ButtonContainer>
            <SaveButton onClick={handleCancelClick}>Cancel</SaveButton>
            <SaveButton onClick={handleSaveClick}>Save</SaveButton>
          </ButtonContainer>
        )}
      </CampaignDetails>
    </Wrapper>
  );
};

const MobileCampaigns = ({ campaigns }: MobileCampaignsProps) => {
  return (
    <div>
      {campaigns.map((campaign, index) => (
        <Campaign
          key={index}
          id={campaign.id}
          giveworxCampaignId={campaign.giveworxCampaignId}
          campaignName={campaign.campaignName}
          startDate={campaign.startDate}
          endDate={campaign.endDate}
          goal={campaign.goal}
          description={campaign.description}
          campaignColors={campaign.campaignColors}
          customContentImageName={campaign.customContentImageName}
        />
      ))}
    </div>
  );
};

export default MobileCampaigns;
