import React, { FC } from 'react';
import { getFilenameByPath } from 'utils/formatters';
import UITag from './UITag';
import DownloadSVG from 'assets/images/icons/download.svg';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { useFileUrl } from 'hooks/common';

interface Props {
  fileKey: string;
}

const DownloadFileTag: FC<Props> = ({ fileKey }) => {
  const { fileUrl } = useFileUrl({ imgKey: fileKey });
  return (
    <UITag
      title={
        <>
          {getFilenameByPath(fileKey)}
          <DownloadLink target="_blank" rel="noreferrer" href={fileUrl}>
            <DownloadIcon src={DownloadSVG} />
          </DownloadLink>
        </>
      }
    />
  );
};

const DownloadLink = styled.a`
  margin-left: 8px;
`;

const DownloadIcon = styled(ReactSVG)`
  svg {
    height: 18px;
    width: 18px;

    path {
      fill: #fff;
    }
  }
`;

export default DownloadFileTag;
