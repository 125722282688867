import React, { useMemo } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import styled from 'styled-components';
import { THEME_COLORS } from 'constants/common';
import { SearchCompany } from 'interfaces/companies';
import { BrandKitSection } from 'pages/organizationUser/team/Setting/components';

export interface FormProps {
  initialValues?: SearchCompany;
  onSubmit: (values: SearchCompany) => OnSubmitReturnType;
}

const SettingForm = ({ initialValues, onSubmit }: FormProps) => {
  const initial = useMemo(() => {
    return {
      ...initialValues,
      brandTheme: initialValues?.brandTheme || THEME_COLORS.Accelerist_theme.title,
      brandFont: initialValues?.brandFont || 'Rubik',
    };
  }, [initialValues]);

  return (
    <Form
      initialValues={initial}
      onSubmit={values => {
        onSubmit(values);
      }}
      render={({ handleSubmit, values, form }: FormRenderProps<SearchCompany>) => {
        return (
          <FormStyled>
            <BrandKitSection form={form} values={values} handleSubmit={handleSubmit} />
          </FormStyled>
        );
      }}
    />
  );
};

const FormStyled = styled.form`
  background: #ffffff;
  border-radius: 4px;
  padding: 40px;
`;

export default SettingForm;
