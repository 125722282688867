import { FormatNumber } from 'components/UI/format';
import ProgressBar from 'components/UI/ProgressBar';
import { DashboardROI } from 'interfaces/roi';
import React, { useMemo } from 'react';
import styled from 'styled-components';

interface Props {
  data: DashboardROI['roi'] | undefined;
}

const ROITabContent = ({ data }: Props) => {
  const maxValue = useMemo(() => {
    if (!data) return 0;

    const maxCorpValue =
      [...data].sort((a, b) => Number(b.corpValue) - Number(a.corpValue))?.[0]
        ?.corpValue || 0;

    const maxNpoValue =
      [...data].sort((a, b) => Number(b.npoValue) - Number(a.npoValue))?.[0]?.npoValue ||
      0;

    return maxCorpValue > maxNpoValue ? maxCorpValue : maxNpoValue;
  }, [data]);

  return (
    <Root>
      {data &&
        data.map(item => (
          <Item key={item.id}>
            <Name title={item.name}>{item.name}</Name>
            <ProgressBarGroup>
              <StyleProgressBar
                color="#2BAEE0"
                text={<FormatNumber value={item.npoValue * 100} suffix="%" />}
                value={(Number(item.npoValue) / maxValue) * 100}
              />
              <StyleProgressBar
                color="#F6921E"
                text={<FormatNumber value={item.corpValue * 100} suffix="%" />}
                value={(Number(item.corpValue) / maxValue) * 100}
              />
            </ProgressBarGroup>
          </Item>
        ))}
      <LegendList>
        <Legend>
          <LegendColor color="#2BAEE0" />
          <LegendText>ROI (NPO)</LegendText>
        </Legend>
        <Legend>
          <LegendColor color="#F6921E" />
          <LegendText>ROI (Corp)</LegendText>
        </Legend>
      </LegendList>
    </Root>
  );
};

const Root = styled.div``;

const StyleProgressBar = styled(ProgressBar)`
  margin-bottom: 4px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  max-width: 800px;
`;

const Name = styled.div`
  font-size: 16px;
  line-height: 145%;
  color: #122434;
  margin-right: 40px;
  width: 170px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ProgressBarGroup = styled.div`
  width: 100%;
`;

const LegendList = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;

  & > div {
    margin-right: 8px;
  }
`;

const LegendColor = styled.div<{ color: string }>`
  width: 44px;
  height: 20px;
  border-radius: 99em;
  background-color: ${({ color }) => color};
`;

const LegendText = styled.div``;

export default ROITabContent;
