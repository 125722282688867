import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const AuthNavigation = () => {
  return (
    <Root>
      <LinkElem to="/signup">Register</LinkElem>
      <LinkElem to="/login">Login</LinkElem>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  background: #f8f8f8;
  border-radius: 6px;
  padding: 2px 3px;
  margin-bottom: 20px;
`;

const LinkElem = styled(NavLink)`
  border-radius: 6px;
  color: var(--darkGray);
  background: #f8f8f8;
  text-align: center;
  flex: 1;
  font-size: 12px;
  padding: 9px 0;
  line-height: 150%;
  &.active {
    color: var(--black);
    background: var(--secondaryBlue);
  }
`;

export default AuthNavigation;
