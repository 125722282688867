import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { SelectPanelProps } from 'components/UI/interfaces';
import { SearchPanelWithCheckBoxes } from 'components/UI/select';
import FieldSection from '../FieldSection';

interface BaseMultiSelectProps {
  values?: string[];
  changeHandler?: (values: any) => void;
  visibleNumberToTooltip?: number;
}

type Props = BaseMultiSelectProps & SelectPanelProps & FieldRenderProps<string[]>;

const FieldSearchPanelWithCheckBoxes = ({
  input,
  label,
  meta,
  ...selectProps
}: Props) => {
  return (
    <FieldSection input={input} meta={meta} label={label}>
      <SearchPanelWithCheckBoxes
        values={input.value}
        changeHandler={input.onChange}
        {...selectProps}
      />
    </FieldSection>
  );
};

export default FieldSearchPanelWithCheckBoxes;
