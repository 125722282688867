import React from 'react';
import styled from 'styled-components';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { Form } from 'react-final-form';
import { Field } from 'components/FinalForm';
import {
  FieldDatePicker,
  FieldTextArea,
  FieldTextInput,
  FieldUploadFile,
} from 'components/FormFields';
import { imageFileValidator, required } from 'utils/validation';
import UploadFileArea from 'components/UploadFileArea';
import { FormLabel } from 'components/UI/form';
import UIAsterisk from 'components/UI/UIAsterisk';
import {
  fetchDownloadROISurveyTeamTemplate,
  fetchUploadROISurveyTemplate,
} from 'store/ducks/roi/api';
import Toast from 'services/Toast';
import { ROIData, ROISurvey } from 'interfaces/roi';
import { fetchErrorHandler } from 'utils/errorHandlers';
import DownloadTemplate from 'components/DownloadTemplate';

interface Props extends InjectedProps {
  handleAccess: (param: ROISurvey) => void;
  roi: ROIData;
  survey?: ROISurvey;
}

const CreateConsumerSurveyModal = ({
  onClose,
  handleAccess,
  roi,
  survey,
  ...modalProps
}: Props) => {
  useLockBodyScroll();
  const isCompany = roi.isCompany;
  // const corporationName = roi.companyName;
  // const organizationName = roi.isCompany ? roi.company?.name : roi.team?.organizationName;

  const corporationName = isCompany
    ? roi.isCompany
      ? roi.company?.name
      : roi.team?.organizationName
    : roi.companyName;
  const organizationName = isCompany
    ? roi.companyName
    : roi.isCompany
    ? roi.company?.name
    : roi.team?.organizationName;

  const disabled = !!survey;

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 0' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '673px',
        height: '80vh',
        overflow: 'auto',
      }}
    >
      <Title>Create Consumer Survey</Title>
      <Form<ROISurvey>
        initialValues={survey}
        onSubmit={values => {
          if (!values.contacts?.length) {
            Toast.warn('Please upload consumer list.');
            return;
          }
          handleAccess({ ...values, roiId: roi.id, type: 'consumer' });
        }}
        render={({ handleSubmit, form }) => (
          <FormBody>
            <Field
              label={
                <div>
                  Survey Title <UIAsterisk />
                </div>
              }
              name="title"
              component={FieldTextInput}
              validate={required}
              disabled={disabled}
            />
            <Field
              label={
                <div>
                  Survey End Date <UIAsterisk />
                </div>
              }
              name="endDate"
              component={FieldDatePicker}
              validate={required}
              disabled={disabled}
              minDate={new Date()}
            />
            <FormTitle>Survey Email</FormTitle>
            <Field
              label={
                <div>
                  Survey Email Subject <UIAsterisk />
                </div>
              }
              name="emailSubject"
              component={FieldTextInput}
              validate={required}
              disabled={disabled}
            />
            <Field
              label={
                <div>
                  Survey Email Message <UIAsterisk />
                </div>
              }
              name="emailMessage"
              component={FieldTextArea}
              validate={required}
              disabled={disabled}
            />
            <Field
              name="logo"
              label="Company Logo"
              component={FieldUploadFile}
              hideIcon
              title="Upload"
              category="consumer-survey"
              htmlId="consumer-survey-email-logo"
              isSingleFile
              disabled={disabled}
              accept="image/*"
              acceptValidator={imageFileValidator}
            />
            <Field name="uploadConsumerList" label="Upload Consumer List">
              {() => {
                return (
                  <>
                    <FormLabel>
                      Upload Consumer List <UIAsterisk />
                    </FormLabel>
                    <DownloadTemplate fetchFile={fetchDownloadROISurveyTeamTemplate} />
                    <UploadFileArea
                      name="Upload"
                      accept=".xlsx"
                      inputId="create-consumer-survey-form-upload"
                      desc="File size limit is 5 Mb. Only Excel files allowed."
                      disabled={disabled}
                      onUpload={files => {
                        if (!files.length) return;

                        fetchUploadROISurveyTemplate(files[0])
                          .then(v => {
                            if (!v) return;
                            form.change('contacts', v);
                            return Toast.success('File uploaded successfully');
                          })
                          .catch(err => {
                            fetchErrorHandler(err);
                          });
                      }}
                      onDeleteFile={() => {}}
                    />
                  </>
                );
              }}
            </Field>
            <FormTitle>Survey Questions</FormTitle>
            <QuestionText>
              1. Have you heard of {corporationName}&apos;s partnership with{' '}
              {organizationName}?
            </QuestionText>
            <FakeRadioWrapper>
              <FakeRadio>Yes</FakeRadio>
              <FakeRadio>No</FakeRadio>
            </FakeRadioWrapper>
            <QuestionText>
              2. Do you provide your time by volunteering with {organizationName}?
            </QuestionText>
            <FakeRadioWrapper>
              <FakeRadio>Yes</FakeRadio>
              <FakeRadio>No</FakeRadio>
            </FakeRadioWrapper>
            <QuestionText>
              3. Do you donate your money to {organizationName}?
            </QuestionText>
            <FakeRadioWrapper>
              <FakeRadio>Yes</FakeRadio>
              <FakeRadio>No</FakeRadio>
            </FakeRadioWrapper>
            <QuestionText>
              4. How do you feel about {corporationName} supporting {organizationName}?
            </QuestionText>
            <FakeRadioWrapper>
              <FakeRadio>Negative</FakeRadio>
              <FakeRadio>Neutral</FakeRadio>
              <FakeRadio>Positive</FakeRadio>
            </FakeRadioWrapper>
            <QuestionText>
              5. Do you feel more loyal to {corporationName} because of their support of{' '}
              {organizationName}?
            </QuestionText>
            <FakeRadioWrapper>
              <FakeRadio>Yes</FakeRadio>
              <FakeRadio>No</FakeRadio>
              <FakeRadio>Not Sure</FakeRadio>
            </FakeRadioWrapper>

            <BtnGroup>
              <Btn modifiers="secondary" title="Cancel" handleClick={onClose} />
              {!disabled && (
                <Btn modifiers="primary" title="Send" handleClick={handleSubmit} />
              )}
            </BtnGroup>
          </FormBody>
        )}
      />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 46px;
  text-align: center;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Btn = styled(UIButton)`
  display: block;
  width: 87px;
  font-size: 14px;
  padding: 7px 0;
`;

const FormBody = styled.div`
  margin: 0 92px;
`;

const FormTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: #000000;
  margin-bottom: 16px;
`;

const QuestionText = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.02em;
  margin-bottom: 24px;
`;

const FakeRadioWrapper = styled.div`
  display: flex;
  column-gap: 32px;
  margin-bottom: 16px;
`;

const FakeRadio = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #889199;

  &::before {
    content: '';
    border: 1px solid #c4c4c4;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    margin-bottom: 5px;
  }
`;

export default CreateConsumerSurveyModal;
