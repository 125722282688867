/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ContentBlockWrapper } from 'layouts';
import { useOperation } from 'hooks/common';
import { useParams } from 'react-router-dom';
import {
  fetchCompanyCurrentContactsUpdate,
  fetchCompanyCurrentContactsCreate,
} from 'store/ducks/companies/operations';
import { StateProps } from 'store/interfaces';
import { getContactById } from 'store/ducks/companies/selectors';
import { CompanyContact } from 'interfaces/companies';
import CompanyContactForm from 'components/CompanyContactForm';
import Jumbotron from 'components/Jumbotron';
import History from 'services/History';

const EditCompanyContact = () => {
  const [onFetchCompanyCurrentContactsUpdate] = useOperation(
    fetchCompanyCurrentContactsUpdate,
  );
  const [onFetchCompanyCurrentContactsCreate] = useOperation(
    fetchCompanyCurrentContactsCreate,
  );
  const params: { contactId: string } = useParams();

  const contact = useSelector<StateProps, CompanyContact | undefined>(
    getContactById(params.contactId),
  );

  const onSubmit = useCallback(
    async (values: CompanyContact) => {
      if (params.contactId) {
        await onFetchCompanyCurrentContactsUpdate(values);
      } else {
        await onFetchCompanyCurrentContactsCreate(values);
      }

      History.back();
    },
    [params],
  );

  return (
    <ContentBlockWrapper header={<Jumbotron name="Company Contact" back />}>
      <CompanyContactForm initialValues={contact || {}} onSubmit={onSubmit} />
    </ContentBlockWrapper>
  );
};

export default EditCompanyContact;
