export const companiesTabsOptions = [
  {
    label: 'Similar Companies',
    value: 'similar',
  },
  {
    label: 'Parent Company',
    value: 'parent',
  },
];

export const mockCompanyContacts = [
  {
    name: 'Ronald Richards',
    department: 'Human Resources',
    telNumber: '(270) 555-0117',
    email: 'ronald23@gmail.com',
  },
  {
    name: 'Ronald Richards',
    department: 'Marketing',
    telNumber: '(270) 555-0117',
    email: 'ronald23@gmail.com',
  },
  {
    name: 'Ronald Richards',
    department: 'Social Impact',
    telNumber: '(270) 555-0117',
    email: 'ronald23@gmail.com',
  },
];

export const reasonOptions = [
  { label: 'Profile Update Request', value: 'Profile Update Request' },
  { label: 'System Bug', value: 'System Bug' },
  { label: 'Question for Expert', value: 'Question for Expert' },
  { label: 'System Navigation', value: 'System Navigation' },
  { label: 'Data Sourcing or Reporting', value: 'Data Sourcing or Reporting' },
  { label: 'Team Management', value: 'Team Management' },
];

export const filterTypeOptions = [
  { label: 'Company', value: 'Best Match' },
  { label: 'Keyword', value: 'Priority Match' },
];

export const filterTypeOrganizationOptions = [
  { label: 'Organization', value: 'Best Match' },
  { label: 'Keyword', value: 'Priority Match' },
];
