import React, { useMemo } from 'react';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';
import { ROIMeltwaterData } from 'interfaces/roi';
import { Cell, Pie, PieChart, Tooltip, Legend } from 'recharts';
import styled from 'styled-components';

interface Props {
  data?: ROIMeltwaterData['analyticsData']['analytics']['sentiment'];
}

const COLORS = ['#4AD78B', '#CCCACA', '#E92D30'];

const SentimentMetric = ({ data }: Props) => {
  const d = [
    { name: 'Positive', value: data?.positive.percent },
    { name: 'Negative', value: data?.negative.percent },
    { name: 'Neutral', value: data?.neutral.percent },
  ];

  const dataPercentage = useMemo(() => {
    if (!data) return null;
    const total = data.positive.percent + data.negative.percent + data.neutral.percent;
    return {
      positive: ((data.positive.percent / total) * 100).toFixed(0),
      negative: ((data.negative.percent / total) * 100).toFixed(0),
      neutral: ((data.neutral.percent / total) * 100).toFixed(0),
    };
  }, [data]);

  return (
    <MetricRoot>
      <MetricTitle>Sentiment</MetricTitle>
      <Content>
        <PieChart width={320} height={150}>
          <Pie
            dataKey="value"
            data={d}
            cx={150}
            cy={60}
            innerRadius={25}
            outerRadius={50}
          >
            {d.map((entry, index) => {
              return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />;
            })}
          </Pie>
          <Legend
            iconType="circle"
            iconSize={6}
            wrapperStyle={{ fontSize: '12px', fontWeight: 500 }}
          />
          <Tooltip formatter={value => `${Math.floor(value * 100)}%`} />
        </PieChart>
        {dataPercentage && (
          <Percentage>
            <div>{dataPercentage.positive}% Positive</div>
            <div>{dataPercentage.negative}% Negative</div>
            <div>{dataPercentage.neutral}% Neutral</div>
          </Percentage>
        )}
      </Content>
    </MetricRoot>
  );
};

const Content = styled(MetricContent)`
  position: relative;
`;

const Percentage = styled.div`
  position: absolute;
  right: 36px;
  top: 58px;
  font-weight: 500;
  font-size: 12px;

  & > div {
    position: relative;
  }

  & > div::before {
    content: '';
    position: absolute;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    left: -10px;
    top: 3px;
  }

  & > div:nth-child(1)::before {
    background-color: #4ad78b;
  }
  & > div:nth-child(2)::before {
    background-color: #cccaca;
  }
  & > div:nth-child(3)::before {
    background-color: #e92d30;
  }
`;

export default SentimentMetric;
