import React from 'react';
import { UIModal } from 'components/UI';
import styled from 'styled-components';
import InfoIconSVG from 'assets/images/icons/info-blue.svg';
import { ReactSVG } from 'react-svg';
import ReactTooltip from 'react-tooltip';
import { Gameplan } from 'interfaces/gameplan';
import { GamePlanEmailStatusEnum } from 'constants/gamePlan';

interface Props {
  contacts: Gameplan['gamePlanContacts'];
  onClose: () => void;
}

const TableRowWrapper = ({ row }: { row: Gameplan['gamePlanContacts'][number] }) => {
  const { contact, emailStatus, openNum, clickNum, replyNum, autoReplyNum } = row;
  return (
    <TableRow>
      <TableRowCell>
        <div>{contact.email}</div>
        <div>{`${contact.firstName || ''} ${contact.lastName || ''}`}</div>
      </TableRowCell>
      <TableRowCell>
        {emailStatus && <StatusTag status={emailStatus}>{emailStatus}</StatusTag>}
      </TableRowCell>
      <TableRowCell>{openNum}</TableRowCell>
      <TableRowCell>{clickNum}</TableRowCell>
      <TableRowCell>{replyNum}</TableRowCell>
      <TableRowCell>{autoReplyNum}</TableRowCell>
    </TableRow>
  );
};

const GameplanRecipientsModal = ({ onClose, contacts }: Props) => {
  return (
    <UIModal
      onClose={onClose}
      propsBodyCss={{ padding: '28px 40px' }}
      style={{
        width: '100%',
        maxWidth: '1241px',
        top: '100px',
      }}
      overlayStyle={{
        overflow: 'auto',
      }}
      header={<ModalTitle>View Recipients</ModalTitle>}
    >
      <TableWrapper>
        <Table>
          <colgroup>
            <col style={{ width: `320px` }} />
            <col style={{ width: `230px` }} />
            <col style={{ width: `160px` }} />
            <col style={{ width: `160px` }} />
            <col style={{ width: `160px` }} />
            <col style={{ width: `160px` }} />
          </colgroup>
          <TableHead>
            <TableHeadRow>
              <TableHeadCell>Recipients</TableHeadCell>
              <TableHeadCell>
                Status <InfoIcon src={InfoIconSVG} data-tip data-for="status-tooltip" />
              </TableHeadCell>
              <TableHeadCell>Opens</TableHeadCell>
              <TableHeadCell>Clicks</TableHeadCell>
              <TableHeadCell>Replies</TableHeadCell>
              <TableHeadCell>Auto-Replies</TableHeadCell>
            </TableHeadRow>
          </TableHead>
          <TableBody>
            {contacts.map(item => (
              <TableRowWrapper key={item.id} row={item} />
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
      <ReactTooltip id="status-tooltip" place="bottom" type="light" effect="solid">
        <StatusTooltipBody>
          <StatusTooltipBodyTitle>Active</StatusTooltipBodyTitle>
          <StatusTooltipBodyDesc>
            Email delivered successfully and will continue to receive emails from the Game
            Plan.
          </StatusTooltipBodyDesc>
          <StatusTooltipBodyTitle>Bounced</StatusTooltipBodyTitle>
          <StatusTooltipBodyDesc>Email was undeliverable. </StatusTooltipBodyDesc>
          <StatusTooltipBodyTitle>Manually Removed </StatusTooltipBodyTitle>
          <StatusTooltipBodyDesc>
            The contact has been removed by the user from the Game Plan.
          </StatusTooltipBodyDesc>
          <StatusTooltipBodyTitle>Completed</StatusTooltipBodyTitle>
          <StatusTooltipBodyDesc>
            Recipient has received all scheduled emails in the Game Plan.
          </StatusTooltipBodyDesc>
          <StatusTooltipBodyTitle>Connected</StatusTooltipBodyTitle>
          <StatusTooltipBodyDesc>
            The recipient has replied to the email (except an auto-reply).
          </StatusTooltipBodyDesc>
        </StatusTooltipBody>
      </ReactTooltip>
    </UIModal>
  );
};

const ModalTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  padding: 40px 0 16px;
`;

const TableWrapper = styled.div`
  overflow: auto;
  margin-bottom: 30px;
  height: 600px;
  position: relative;
`;

const Table = styled.table``;

const TableHead = styled.thead`
  background: #f4f4f4;
  position: sticky;
  top: 0;
`;

const TableHeadRow = styled.tr`
  height: 39px;
  position: sticky;
  top: 0;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);
  position: sticky;
  top: 0;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-right: 24px;
  }
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;

const TableRow = styled.tr`
  height: 100%;
`;

const TableRowCell = styled.td`
  padding: 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }
`;

const InfoIcon = styled(ReactSVG)`
  display: inline-block;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const StatusTooltipBody = styled.div`
  width: 345px;
  height: 290px;
`;

const StatusTooltipBodyTitle = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  color: var(--black);
  margin-bottom: 4px;
`;
const StatusTooltipBodyDesc = styled.div`
  font-size: 10px;
  line-height: 150%;
  color: var(--black);
  margin-bottom: 10px;
`;

const StatusTag = styled.div<{ status: GamePlanEmailStatusEnum }>`
  padding: 6px 10px;
  border: 1px solid;
  border-radius: 6px;
  width: max-content;

  ${({ status }) => {
    switch (status) {
      case GamePlanEmailStatusEnum.Active:
        return 'color: #279B48;';
      case GamePlanEmailStatusEnum.Bounced:
        return 'color: #C31F33;';
      case GamePlanEmailStatusEnum.ManuallyRemoved:
        return 'color: #FDB713;';
      case GamePlanEmailStatusEnum.Completed:
        return 'color: var(--black);';
      case GamePlanEmailStatusEnum.Connected:
        return 'color: #007DBC;';
    }
  }};
`;

export default GameplanRecipientsModal;
