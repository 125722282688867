/* eslint-disable react-hooks/exhaustive-deps */
import Jumbotron from 'components/Jumbotron';
import Pagination from 'components/Pagination';
import TotalRow from 'components/TotalRow';
import { PAGE_WIDTH } from 'constants/common';
import { useOperation } from 'hooks/common';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchPricingList, fetchSaveValuation } from 'store/ducks/pricing/operations';
import { getPricingList } from 'store/ducks/pricing/selectors';
import { fetchTeam } from 'store/ducks/team/operations';
import { getTeam } from 'store/ducks/team/selectors';
import styled from 'styled-components';
import ValuationCard from '../components/ValuationCard';

const Pricing: FC = () => {
  const [onFetchPricingList, isLoading] = useOperation(fetchPricingList);
  const [onFetchSaveValuation] = useOperation(fetchSaveValuation);
  const [onFetchTeam] = useOperation(fetchTeam);

  const { items: pricingList, meta } = useSelector(getPricingList);
  const team = useSelector(getTeam);

  useEffect(() => {
    if (!team?.id) {
      onFetchTeam(undefined);
      return;
    }
    onFetchPricingList({ teamId: team.id, page: 1, limit: 6 });
  }, [team]);

  const changeCategroy = ({ id, categorize }) => {
    const pricing = pricingList.find(item => item.id === id);
    if (!pricing) {
      return;
    }
    onFetchSaveValuation({ ...pricing, categorize: categorize });
  };

  if (!team) return null;

  return (
    <ContentBlockWrapper header={<Jumbotron name="Pricing" />} loading={isLoading}>
      <TotalRow
        title={`Found ${meta.totalItems} Asset${meta.totalItems > 1 ? 's' : ''}`}
      />
      <PaginationWrapper>
        <Pagination
          page={Number(meta.currentPage)}
          pageLimit={Number(meta.itemsPerPage)}
          total={meta.totalItems}
          submitHandler={page => onFetchPricingList({ teamId: team.id, page })}
        />
      </PaginationWrapper>
      <CardList>
        {pricingList.map(item => (
          <ValuationCard
            key={item.id}
            id={item.id}
            name={item.name}
            status={item.status}
            categorize={item.categorize}
            formProgress={
              item.progressPercentage ? Math.floor(item.progressPercentage * 100) : 0
            }
            suggestedMinimumThreshold={item.suggestedMinimumThreshold}
            totalAssetValue={item.totalAssetValue}
            onSelectCategory={(id, value) => changeCategroy({ id, categorize: value })}
          />
        ))}
      </CardList>
    </ContentBlockWrapper>
  );
};

const CardList = styled.div`
  max-width: ${PAGE_WIDTH.LARGE}px;
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: 0 24px 24px 0;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 4px 0;
  max-width: 1150px;
`;

export default Pricing;
