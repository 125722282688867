import { UIDivider } from 'components/UI';
import React from 'react';
import styled from 'styled-components';
import { moneyNumberFormat } from 'utils/formatters';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';

interface Props {
  nonprofitValue: number;
  companyValue: number;
  themeColor?: string;
}

const OverallPartnershipValueMetric = ({
  nonprofitValue,
  companyValue,
  themeColor,
}: Props) => {
  return (
    <Root $themeColor={themeColor}>
      <MetricTitle>Overall Partnership Value</MetricTitle>
      <Content>
        <Value>
          <div>To Nonprofit</div>
          <div>{moneyNumberFormat(nonprofitValue)}</div>
        </Value>
        <Desc>
          This is an aggregate of the value that the company contributed to the
          partnership in the following ways: monetary or in-kind contributions, marketing
          and media support, and brand value.
        </Desc>
        <Divider type="horizontal" />
        <Value>
          <div>To Company</div>
          <div>{moneyNumberFormat(companyValue)}</div>
        </Value>
        <Desc>
          This is an aggregate of the value that the nonprofit contributed to the
          partnership in the following ways: marketing, media support, and brand value.
        </Desc>
      </Content>
    </Root>
  );
};

const Root = styled(MetricRoot)`
  /* background: #1992b9; */
`;

const Value = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;

  & > div:first-child {
    flex: 1;
    white-space: nowrap;
  }
`;

const Content = styled(MetricContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Divider = styled(UIDivider)`
  margin: 14px 0;
  flex-shrink: 0;
`;

const Desc = styled.div`
  font-size: 12px;
  margin-top: 8px;
  line-height: 1;
`;

export default OverallPartnershipValueMetric;
