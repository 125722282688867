import EditSVG from 'assets/images/icons/edit.svg';
import { ConfirmModal } from 'components/Modals';
import Pagination from 'components/Pagination';
import { CropText, UIButton, UICheckbox } from 'components/UI';
import { format } from 'date-fns';
import { useModal, useOperation } from 'hooks/common';
import { PaginationMeta } from 'interfaces/common';
import React, { useCallback, useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { moneyFormatter } from 'utils/formatters';
import { WorxROIData } from 'interfaces/fundraising';
import { ROIData } from 'interfaces/roi';
import { fetchPartnershipsDelete } from 'store/ducks/fundraising/operations';
import { TableSortHeaderCell } from 'components/UI/table';
import { SortStatus } from 'hooks/common/useSortStatus';
import Toast from 'services/Toast';
import { isAssociationParn } from 'store/ducks/campaign/operations';
import EditGivePartnerModal from './modals/EditGivePartnerModal';

interface Props {
  meta: PaginationMeta | undefined;
  partnershipsList: WorxROIData[];
  selectedPartnership: string[];
  onSearch?: any;
  onPartnershipSelect: (campaignIds: string[]) => void;
  onPartnershipsDelete: () => void;
  rows: WorxROIData[];
  searchValue?: string;
  sortStatus: SortStatus;
  onSort: (colName: string) => void;
  isCompanyUser?: boolean;
}

interface TableRowProps {
  row: WorxROIData;
  selectedPartnership: string[];
  handleRowSelect: (id: string) => void;
}

const tableWidth = {
  checkColumn: 10,
  oneColumn: 130,
  twoColumn: 224,
  thirdColumn: 100,
  fourColumn: 80,
  GiveShopColumn: 100,
  EditColumn: 88,
} as const;

const {
  checkColumn,
  oneColumn,
  twoColumn,
  thirdColumn,
  fourColumn,
  GiveShopColumn,
  EditColumn,
} = tableWidth;

const PartnersList = ({
  meta,
  partnershipsList,
  selectedPartnership,
  onPartnershipSelect,
  onSearch,
  onPartnershipsDelete,
  rows,
  searchValue,
  sortStatus,
  onSort,
  isCompanyUser,
}: Props) => {
  const [fetchAssociationParn] = useOperation(isAssociationParn);
  const [deletePartnerships] = useOperation(fetchPartnershipsDelete);

  const [isSelectAll, setIsSelectAll] = useState(false);
  const [parItemDetails, setParItemDetails] = useState<ROIData>();
  const [deleLoading, setDeleLoading] = useState(false);

  const handleRowSelect = useCallback(
    (id: string) => {
      if (selectedPartnership.includes(id))
        return onPartnershipSelect(selectedPartnership.filter(item => item !== id));

      onPartnershipSelect([...selectedPartnership, id]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPartnership],
  );

  const removeEvent = (ids: string[]) => {
    deletePartnerships(ids).then((e: any) => {
      if (e.status === 'success') {
        Toast.success('Deleted Successfully.');
        onPartnershipsDelete();
      }
      if (e) setDeleLoading(false);
    });
  };

  const [showConfirmModal, hideConfirmModal] = useModal(
    () => (
      <ConfirmModal
        name="Delete"
        description={`${
          selectedPartnership?.length > 1
            ? 'One or more partners are associated with at least one Fundraising campaign. Deleting them will remove them from all campaigns. Are you sure you want to continue?'
            : 'The partner is associated with at least one Fundraising campaign. Deleting them will remove them from all campaigns. Are you sure you want to continue?'
        }`}
        textAccessBtn="Yes"
        textCancelBtn="No"
        accessHandler={() => {
          // onPartnershipsDelete(selectedPartnership);
          setDeleLoading(true);
          removeEvent(selectedPartnership);
          hideConfirmModal();
        }}
        onClose={hideConfirmModal}
        cancelHandler={hideConfirmModal}
      />
    ),
    [selectedPartnership],
  );

  const [showEditPartnerModal, hideEditPartnerModal] = useModal(
    () => (
      <EditGivePartnerModal
        handleAccess={() => {
          onSearch({
            page: 1,
            q: searchValue,
            sortType: '',
            sortBy: '',
            operation: '',
          });
          hideEditPartnerModal();
        }}
        onClose={hideEditPartnerModal}
        itemDetails={parItemDetails}
        // callType="edit"
      />
    ),
    [parItemDetails],
  );

  const deteleConfirm = async (ids: string[]) => {
    const msg: any = await fetchAssociationParn(ids);
    if (msg?.data) {
      showConfirmModal();
      onPartnershipSelect(ids);
    }
    if (!msg?.data) {
      removeEvent(ids);
      setDeleLoading(true);
    }
  };

  const TableRowWrapper = ({
    row,
    selectedPartnership,
    handleRowSelect,
  }: TableRowProps) => {
    const {
      id,
      companyName,
      name,
      startDate,
      endDate,
      annualFundraisingCommitment,
    } = row;

    return (
      <TableRow>
        <TableRowCell>
          <Checkbox
            check={selectedPartnership.includes(id)}
            handleClick={() => handleRowSelect(id)}
          />
        </TableRowCell>
        <TableRowCell isAddWeight>
          <CropText text={companyName} width={oneColumn} />
        </TableRowCell>
        <TableRowCell>
          <CropText width={twoColumn} text={name} />
        </TableRowCell>
        <TableRowCell>
          <CropText
            width={thirdColumn - 20}
            text={startDate ? format(new Date(startDate), 'MMM dd, yyyy') : 'N/A'}
          />
        </TableRowCell>
        <TableRowCell>
          <CropText
            width={thirdColumn - 20}
            text={endDate ? format(new Date(endDate), 'MMM dd, yyyy') : 'N/A'}
          />
        </TableRowCell>
        <TableRowCell>
          <CropText
            text={moneyFormatter.from(Number(annualFundraisingCommitment), {
              symbol: '$',
            })}
            width={fourColumn}
          />
        </TableRowCell>
        <TableRowCell>
          <UIButton
            style={{
              height: '35px',
              width: '82px',
              paddingLeft: '8px',
              margin: '0 8px',
            }}
            title={
              <IconWrapper>
                <ReactSVG src={EditSVG} /> Edit
              </IconWrapper>
            }
            modifiers={'third'}
            handleClick={() => {
              setParItemDetails(row);
              showEditPartnerModal();
            }}
          />
        </TableRowCell>
      </TableRow>
    );
  };

  return (
    <Root>
      <SelectWrapper>
        <SelectAll>
          <Checkbox
            check={isSelectAll}
            handleClick={() => {
              setIsSelectAll(!isSelectAll);
              isSelectAll
                ? onPartnershipSelect([])
                : onPartnershipSelect(partnershipsList.map(item => item.id));
            }}
          />
          Select All
        </SelectAll>
        <Actions>
          <DeleteButton
            title="Delete"
            modifiers="danger"
            disabled={!selectedPartnership?.length}
            // handleClick={showConfirmModal}
            handleClick={() => deteleConfirm(selectedPartnership)}
            isLoading={deleLoading}
          />
          {meta && meta.itemCount > 0 && (
            <Pagination
              total={meta.totalItems}
              pageLimit={Number(meta.itemsPerPage)}
              page={Number(meta.currentPage)}
              submitHandler={(page: number) =>
                onSearch({
                  page,
                  q: searchValue,
                  sortType: '',
                  sortBy: '',
                  operation: '',
                })
              }
            />
          )}
        </Actions>
      </SelectWrapper>
      <Body>
        <TableWrapper>
          <Table>
            <colgroup>
              <col span={1} style={{ width: `${checkColumn}px` }} />
              <col span={1} style={{ width: `${oneColumn}px` }} />
              <col span={1} style={{ width: `${twoColumn}px` }} />
              <col span={1} style={{ width: `${thirdColumn}px` }} />
              <col span={1} style={{ width: `${fourColumn}px` }} />
              <col span={1} style={{ width: `${GiveShopColumn}px` }} />
              <col span={1} style={{ width: `${EditColumn}px` }} />
            </colgroup>
            <TableHead>
              <TableHeadRow>
                <TableHeadCell />
                <TableSortHeaderCell
                  colName="companyName"
                  onClick={onSort}
                  sortStatus={sortStatus}
                >
                  {`${isCompanyUser ? 'Organization' : 'Company'} Name`}
                </TableSortHeaderCell>
                <TableSortHeaderCell
                  colName="name"
                  onClick={onSort}
                  sortStatus={sortStatus}
                >
                  Description
                </TableSortHeaderCell>
                <TableSortHeaderCell
                  colName="startDate"
                  onClick={onSort}
                  sortStatus={sortStatus}
                >
                  Start Date
                </TableSortHeaderCell>
                <TableSortHeaderCell
                  colName="endDate"
                  onClick={onSort}
                  sortStatus={sortStatus}
                >
                  End Date
                </TableSortHeaderCell>
                <TableSortHeaderCell
                  colName="annualFundraisingCommitment"
                  onClick={onSort}
                  sortStatus={sortStatus}
                >
                  Potential ($)
                </TableSortHeaderCell>
                <TableHeadCell />
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {rows.map(item => (
                <TableRowWrapper
                  key={item.id}
                  row={item}
                  selectedPartnership={selectedPartnership}
                  handleRowSelect={handleRowSelect}
                />
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </Body>
    </Root>
  );
};

const Root = styled.div``;

const Body = styled.div`
  overflow: auto;
`;

const Checkbox = styled(UICheckbox)`
  width: 20px;
  height: 20px;
  margin-right: 16px;
`;

const Actions = styled.div`
  display: flex;
`;

const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const SelectAll = styled.div`
  display: flex;
  user-select: none;
`;

const DeleteButton = styled(UIButton)`
  width: 76px;
  margin-right: 24px;
`;

const TableWrapper = styled.div`
  overflow: auto;
  height: 444px;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;
const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);
  background: #f4f4f4;
  position: sticky;
  top: 0;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableRow = styled.tr`
  height: 100%;
`;

const TableRowCell = styled.td<{ isAddWeight?: boolean; isNoAddBorder?: boolean }>`
  padding: 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }

  ${({ isAddWeight }) => isAddWeight && 'font-weight: 500;'}
  ${({ isNoAddBorder }) => isNoAddBorder && 'border-bottom: none;'}
`;

const IconWrapper = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: 9px;
  }
`;

export default PartnersList;
