import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { FieldInput } from 'components/FormFields';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import { UIButton } from 'components/UI';
import { required } from 'utils/validation';
import { InviteData } from '../interfaces';
import styled from 'styled-components';
import { Field } from 'components/FinalForm';
import { fetchCompaniesSearchNew } from 'store/ducks/companies/operations';
import { useModal, useOperation } from 'hooks/common';
import { useDispatch } from 'react-redux';
import { ExportButton } from 'components/ActionButtons';
import Toast from 'services/Toast';
import NoContactModal from 'components/Modals/ImportNoContactModal';
import { fetchErrorHandler } from 'utils/errorHandlers';
import { UploadNoContactReq } from 'interfaces/team';
import { fetchSaveNoContactXLSX } from 'store/ducks/team/api';

export interface FormProps {
  onSubmit: (values: Company) => OnSubmitReturnType;
}

interface Company {
  id: string;
  name?: string;
  website?: string;
  country?: string;
}

const FieldInputStyled = styled(FieldInput)`
  flex-grow: 1; // Allows input to fill the available space
  height: 40px; // Ensures the input field has a fixed height
  margin: 0; // Removes any default margin
  padding: 10px; // Adjust padding as necessary to center text
  box-sizing: border-box; // Includes padding and border in the height calculation
`;

const ContactForm = ({ onSubmit }: FormProps) => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [value, setValue] = useState('');
  const [onSearchCompanies] = useOperation(fetchCompaniesSearchNew);
  const [submitLoading, setsubmitLoading] = useState(false);
  const uploadXLSX = useCallback(
    async (data: UploadNoContactReq) =>
      await fetchSaveNoContactXLSX(data)
        .then(value => {
          return value;
        })
        .catch(fetchErrorHandler),
    [],
  );

  const [showImportCompaniesModal, hideImportCompaniesModal] = useModal(
    () => (
      <NoContactModal
        title="No Contact"
        handleAccess={formValues => {
          setsubmitLoading(true);
          if (!formValues.rows?.length) {
            Toast.warn('File not uploaded');
            setsubmitLoading(false);
            return;
          }
          uploadXLSX(formValues).then(value => {
            if (!value) {
              setsubmitLoading(false);
              return;
            }

            if (value.msg) {
              Toast.info(value.msg);
              setsubmitLoading(false);
            } else {
              setsubmitLoading(false);
              Toast.success('save successfully');
              window.location.reload();
            }

            hideImportCompaniesModal();
          });
        }}
        submitLoading={submitLoading}
        onClose={hideImportCompaniesModal}
      />
    ),
    [submitLoading],
  );

  useEffect(() => {
    if (value.length >= 2) {
      onSearchCompanies(value)
        .then(companies => {
          setCompanies(companies); // Update the state with the companies data
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      setCompanies([]);
    }
  }, [value, onSearchCompanies]);

  const autocompleteRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        event.target instanceof Node &&
        !autocompleteRef.current?.contains(event.target)
      ) {
        setCompanies([]);
      }
    };

    if (companies.length > 0) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [companies]);

  return (
    <Form
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        hasValidationErrors,
      }: FormRenderProps<Company>) => {
        return (
          <>
            <FormStyled>
              <AutocompleteContainer ref={autocompleteRef}>
                <Field
                  name="name"
                  component={FieldInputStyled}
                  placeholder="Enter the name of the company"
                  autoComplete="off"
                  onChange={event => {
                    setValue(event.target.value); // Update the value state
                  }}
                />
                {companies.length > 0 && (
                  <AutocompleteList>
                    {companies.map(company => (
                      <AutocompleteItem
                        key={company.id}
                        onClick={() => {
                          form.change('name', company.name); // Update the value of the company field
                          form.change('id', company.id); // Update the value of the id field
                          form.change('country', company.country); // Update the value of the country field
                          form.change('website', company.website); // Update the value of the website field
                          setCompanies([]); // Optionally clear the suggestions
                        }}
                      >
                        {company.name}
                      </AutocompleteItem>
                    ))}
                  </AutocompleteList>
                )}
                <Field name="id" component="input" type="hidden" />
                <Field name="country" component="input" type="hidden" />
                <Field name="website" component="input" type="hidden" />
              </AutocompleteContainer>

              <Btn
                modifiers="primary"
                title="Add"
                handleClick={handleSubmit}
                type="submit"
                disabled={pristine || submitting || hasValidationErrors}
                isLoading={submitting}
              />
            </FormStyled>
            <ExportButton
              name="Import"
              iconCss={{ transform: 'rotate(180deg)' }}
              uploadHandler={showImportCompaniesModal}
            />
          </>
        );
      }}
    />
  );
};

const FormStyled = styled.form`
  display: flex;
  align-items: center; // Ensures vertical center alignment of children
  justify-content: flex-start; // Aligns children at the start of the container
  gap: 16px; // Adjust the gap as needed
`;

const Btn = styled(UIButton)`
  height: 40px; // Ensures the button has the same height as the input field
  margin: 0; // Removes any default margin
  padding: 10px 20px; // Adjust horizontal padding to your design, vertical padding to center text
  box-sizing: border-box; // Includes padding and border in the height calculation
  align-self: stretch; // Makes the button stretch to fill the parent's height
`;

const AutocompleteContainer = styled.div`
  position: relative;
  width: 100%;
`;

const AutocompleteList = styled.ul`
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: calc(100% - 23px);
  left: 0;
  right: 0;
  max-height: 200px; // Limit the size of the dropdown and make it scrollable
  overflow-y: auto;
  background-color: white;
  list-style-type: none;
  padding: 0;
  margin: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const AutocompleteItem = styled.li`
  padding: 10px;
  cursor: pointer;
  background-color: white;
  border-bottom: 1px solid #d4d4d4;

  &:hover {
    background-color: #e9e9e9;
  }
`;

export default ContactForm;
