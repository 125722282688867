/* eslint-disable react-hooks/exhaustive-deps */
import { InviteUser, UserTable } from 'components/teamCompany';
import { headTableUsers } from 'constants/team';
import { useOperation } from 'hooks/common';
import useAcceptInviteCompany from 'hooks/common/useAcceptInviteCompany';
import { UserPreview } from 'interfaces/common';
import { CompanyWrapper } from 'layouts';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchTeamCompanyMembers } from 'store/ducks/companies/operations';
import { getTeamCompanyMembers } from 'store/ducks/companies/selectors';
import { StateProps } from 'store/interfaces';

const UsersCompany = () => {
  useAcceptInviteCompany();
  const [loadTeamMembers, isStartLoading] = useOperation(fetchTeamCompanyMembers);
  const users = useSelector<StateProps, UserPreview[]>(getTeamCompanyMembers);

  useEffect(() => {
    loadTeamMembers(undefined);
  }, []);

  return (
    <CompanyWrapper isLoading={isStartLoading}>
      <InviteUser />
      <UserTable rows={users} headCells={headTableUsers} />
    </CompanyWrapper>
  );
};

export default UsersCompany;
