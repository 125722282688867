import React from 'react';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';
import { ROIMeltwaterData } from 'interfaces/roi';
import styled from 'styled-components';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import geoJson from 'assets/mapjson/world-110m.json';

interface Props {
  data?: ROIMeltwaterData['analyticsData']['analytics']['top_countries'];
}

const HeatMapMetric = ({ data }: Props) => {
  return (
    <MetricRoot>
      <MetricTitle>Heat Map</MetricTitle>
      <Content>
        {data && (
          <ComposableMap
            projectionConfig={{
              scale: 120,
            }}
            projection="geoEquirectangular"
          >
            <Geographies geography={geoJson}>
              {({ geographies }) =>
                geographies.map(geo => {
                  const country = data.find(
                    item => item.country_code === geo.properties.ISO_A2,
                  );
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={
                        country
                          ? country.document_count >
                            data[Math.floor(data.length / 3)].document_count
                            ? '#f6921e'
                            : '#facb57'
                          : '#f5e5bb'
                      }
                      style={{
                        default: {
                          outline: 'none',
                          stroke: '#868686',
                          strokeWidth: 0.2,
                        },
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ComposableMap>
        )}
      </Content>
    </MetricRoot>
  );
};

const Content = styled(MetricContent)`
  position: relative;
  margin-top: -45px;
`;

export default HeatMapMetric;
