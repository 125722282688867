import React from 'react';
import styled from 'styled-components';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';

interface Props {
  typesOfFundsRaised: string[];
  themeColor?: string;
}

const TypesOfFundsRaisedMetric = ({ typesOfFundsRaised, themeColor }: Props) => {
  return (
    <MetricRoot>
      <Title $themeColor={themeColor}>Types of Funds Raised</Title>
      <MetricContent>
        {typesOfFundsRaised.map((item, i) => (
          <ImpactValue key={i}>{item}</ImpactValue>
        ))}
      </MetricContent>
    </MetricRoot>
  );
};

const Title = styled(MetricTitle)`
  /* background-color: #caf0ff; */
`;

const ImpactValue = styled.p`
  font-size: 14px;
  line-height: 155%;
  position: relative;
  padding-left: 14px;

  &:before {
    content: '';
    width: 6px;
    min-width: 6px;
    height: 6px;
    background: var(--blue);
    border-radius: 100%;
    position: absolute;
    left: 0px;
    top: 6px;
  }
`;

export default TypesOfFundsRaisedMetric;
