import { UIDivider } from 'components/UI';
import React from 'react';
import styled from 'styled-components';
import { moneyNumberFormat, numberFormat } from 'utils/formatters';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';

interface Props {
  type: string;
  nonprofitImpressions: number;
  companyImpressions: number;
  nonprofitValue: number;
  companyValue: number;
  themeColor?: string;
}

const OwnedMediaImpressionsMetric = ({
  type,
  nonprofitImpressions,
  companyImpressions,
  nonprofitValue,
  companyValue,
  themeColor,
}: Props) => {
  return (
    <MetricRoot>
      <MetricTitle $themeColor={themeColor || '#1992B9'}>
        Owned Media Impressions
      </MetricTitle>
      <Content>
        <ContentTitle>Non-Profit Activation</ContentTitle>
        <LabelValueGroup>
          <div>
            <div>Impressions</div>
            <div>{numberFormat(nonprofitImpressions)}</div>
          </div>
          <div>
            <div>Value</div>
            <div>{moneyNumberFormat(nonprofitValue)}</div>
          </div>
          <div>
            <div>Type</div>
            <div>{type}</div>
          </div>
        </LabelValueGroup>
        <Divider type="horizontal" />
        <ContentTitle>Company Activation</ContentTitle>
        <LabelValueGroup>
          <div>
            <div>Impressions</div>
            <div>{numberFormat(companyImpressions)}</div>
          </div>
          <div>
            <div>Value</div>
            <div>{moneyNumberFormat(companyValue)}</div>
          </div>
          <div>
            <div>Type</div>
            <div>{type}</div>
          </div>
        </LabelValueGroup>
      </Content>
    </MetricRoot>
  );
};

const Content = styled(MetricContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContentTitle = styled.div`
  color: #737373;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 12px;
`;

const Divider = styled(UIDivider)`
  margin: 16px 0;
  flex-shrink: 0;
`;

const LabelValueGroup = styled.div`
  display: flex;
  justify-content: space-between;

  & > div > div:first-child {
    font-size: 12px;
    color: #737373;
    margin-bottom: 4px;
  }

  & > div > div:last-child {
    font-weight: 500;
    font-size: 14px;
    color: #122434;
  }
`;

export default OwnedMediaImpressionsMetric;
