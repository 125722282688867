/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useOperation } from 'hooks/common';
import { StateProps } from 'store/interfaces';
import { CompanyWrapper } from 'layouts';
import {
  fetchCompanyCurrent,
  fetchCompanyNews,
  fetchCompanyScoops,
} from 'store/ducks/companies/operations';
import { getCompanyCurrent, getCompanyNews } from 'store/ducks/companies/selectors';
import { News, SearchCompany } from 'interfaces/companies';
import CompanyView from 'components/CompanyView';

const Profile = () => {
  const [onFetchCompanyCurrent, isLoading] = useOperation(fetchCompanyCurrent);
  const [onFetchCompanyNews] = useOperation(fetchCompanyNews);
  const [onfetchCompanyScoops] = useOperation(fetchCompanyScoops);

  const company = useSelector<StateProps, SearchCompany | undefined>(getCompanyCurrent);
  const news = useSelector<StateProps, News[]>(getCompanyNews);

  useEffect(() => {
    if (!company) return;
    onFetchCompanyNews(company.id);
    onfetchCompanyScoops(company.id);
  }, [company]);

  useEffect(() => {
    onFetchCompanyCurrent(undefined);
  }, []);

  return (
    <CompanyWrapper isLoading={isLoading}>
      {company && <CompanyView company={company} news={news} />}
    </CompanyWrapper>
  );
};

export default Profile;
