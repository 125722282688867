import React, { useState } from 'react';
import styled from 'styled-components';
import { UIButton, UICheckbox, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import {
  UITable,
  UITableBody,
  UITableHead,
  UITableHeadCell,
  UITableHeadRow,
  UITableRow,
  UITableRowCell,
  UITableWrapper,
} from 'components/UI/table/styled';
import { TeamProfileData } from 'interfaces/team';

interface Props extends InjectedProps {
  onAccess: (componies: TeamProfileData) => void;
  list: TeamProfileData[];
}

interface TableRowProps {
  row: TeamProfileData;
  check: boolean;
  onCheckRow: (company: TeamProfileData) => void;
}

const TableRowWrapper = ({ row, check, onCheckRow }: TableRowProps) => {
  const { organizationName, contactWebsite, address, id } = row;

  return (
    <UITableRow>
      <UITableRowCell style={{ width: `${70}px` }}>
        <UICheckbox check={check} handleClick={() => onCheckRow(row)} />
      </UITableRowCell>
      <UITableRowCell>{organizationName + (id ? '' : '(new)')}</UITableRowCell>
      <UITableRowCell>{contactWebsite}</UITableRowCell>
      <UITableRowCell>{address}</UITableRowCell>
    </UITableRow>
  );
};

const ExistingTeamFoundModal = ({ onClose, onAccess, list, ...modalProps }: Props) => {
  useLockBodyScroll();

  const [team, setTeam] = useState<TeamProfileData | undefined>(undefined);

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 24px' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '888px',
        overflow: 'unset',
        minHeight: '400px',
      }}
    >
      <Title>Existing Organization Found</Title>
      <SecondaryTitle>
        We have found a match in our system based on the information you provided. Please
        make a selection below to continue.
      </SecondaryTitle>
      <TableWrapper>
        <UITable>
          <colgroup>
            <col style={{ width: `${70}px` }} />
            <col style={{ width: `${200}px` }} />
            <col style={{ width: `${200}px` }} />
            <col style={{ width: `${150}px` }} />
            <col />
          </colgroup>
          <UITableHead>
            <UITableHeadRow>
              <UITableHeadCell key="checkbox" style={{ width: `${70}px` }} />
              <UITableHeadCell>Organization</UITableHeadCell>
              <UITableHeadCell>Webiste</UITableHeadCell>
              <UITableHeadCell>Location</UITableHeadCell>
              <UITableHeadCell></UITableHeadCell>
            </UITableHeadRow>
          </UITableHead>
          <UITableBody>
            {list.map(item => (
              <TableRowWrapper
                key={item.id}
                row={item}
                check={item.id === team?.id}
                onCheckRow={row =>
                  team?.id === row.id ? setTeam(undefined) : setTeam(row)
                }
              />
            ))}
          </UITableBody>
        </UITable>
      </TableWrapper>
      <BtnGroup>
        <Btn modifiers="secondary" title="Cancel" handleClick={onClose} />
        <Btn
          modifiers="primary"
          title="Continue"
          disabled={!team}
          handleClick={() => team && onAccess(team)}
        />
      </BtnGroup>
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 8px;
  text-align: center;
`;

const SecondaryTitle = styled.h3`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  margin: 0px auto 26px;
  text-align: center;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
  justify-self: flex-end;
`;

const Btn = styled(UIButton)`
  display: block;
  width: 87px;
  font-size: 14px;
  padding: 7px 0;
`;

const TableWrapper = styled(UITableWrapper)`
  height: 184px;
`;

export default ExistingTeamFoundModal;
