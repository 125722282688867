import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';

interface BaseProps {
  title?: ReactNode;
  content?: ReactNode;
}

export type Props = BaseProps & InjectedProps;

const TextModal = ({ title, content, onClose, ...modalProps }: Props) => {
  useLockBodyScroll();

  return (
    <UIModal
      onClose={onClose}
      header={title}
      headerStyle={{
        padding: '0px 24px',
        lineHeight: '58px',
        background: '#fff',
        borderBottom: '1px solid #f2f2f2',
        display: 'flex',
        alignItems: 'center,',
        fontSize: '20px',
      }}
      propsBodyCss={{ padding: '12px 24px', height: '580px', overflow: 'auto' }}
      style={{
        width: '100%',
        maxWidth: '870px',
        color: '#78828a',
      }}
      {...modalProps}
    >
      <Content>{content}</Content>
    </UIModal>
  );
};

const Content = styled.div`
  color: #666666;
  font-size: 17px;
  font-weight: 200;
  line-height: 150%;

  h2 {
    font-size: 20px;
  }

  p {
    margin: 12px 0;
  }

  table {
    margin: 12px 0;
  }

  table,
  th,
  td {
    border: thin solid #666666;
    padding: 2px 8px;
  }
`;

export default TextModal;
