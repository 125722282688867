import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { UIButton } from 'components/UI';
import EmptyIcon from 'assets/images/empty-support.svg';
import useJumpToSupport from 'hooks/common/useJumpToSupport';

export interface EmptyFavoritesProps {
  className?: string;
}

const EmptySubscription = ({ className }: EmptyFavoritesProps) => {
  const { jumpToSupport } = useJumpToSupport();

  return (
    <EmptyWrapper className={className}>
      <Icon src={EmptyIcon} />

      <Title>Organization account missing</Title>
      <SubTitle>
        We’re sorry, it doesn’t look like you or your organization has an account
        <br /> with Accelerist. Please contact your organization’s administrator for
        <br />
        an official invitation to your account or to troubleshoot. If you would
        <br /> like to become a new member, contact us directly
        {` `}
        <ContactLink href="mailto:info@accelerist.com">info@accelerist.com</ContactLink>
        {` `}
      </SubTitle>

      <SearchBtn modifiers="third" handleClick={jumpToSupport} title="Write to support" />
    </EmptyWrapper>
  );
};

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 6px;
  padding: 40px 0 34px;
`;

const Icon = styled(ReactSVG)`
  margin-bottom: 10px;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 10px;
`;

const SubTitle = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--gray);
  margin-bottom: 30px;
`;

const SearchBtn = styled(UIButton)`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 12px;
  line-height: 150%;
  max-width: 244px;
  padding: 8px 0;
`;

export const ContactLink = styled.a`
  color: var(--blue);
  &:hover {
    text-decoration: underline;
  }
`;
export default EmptySubscription;
