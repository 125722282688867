/* eslint-disable react-hooks/exhaustive-deps */
import { PasswordData } from 'components/Forms/interfaces';
import PasswordForm from 'components/Forms/PasswordForm';
import { useOperation } from 'hooks/common';
import OrganizationWrapper from 'layouts/OrganizationWrapper';
import React, { useCallback } from 'react';
import { fetchUpdatePassword } from 'store/ducks/user/operations';

const Password = () => {
  const [submit] = useOperation(fetchUpdatePassword);

  const onSubmit = useCallback((values: PasswordData) => {
    return submit(values);
  }, []);

  return (
    <OrganizationWrapper>
      <PasswordForm onSubmit={onSubmit} />
    </OrganizationWrapper>
  );
};

export default Password;
