import { OktaAuth, toQueryString } from '@okta/okta-auth-js';
import React, { useCallback } from 'react';

const Demo = () => {
  const handleLoginOkta = useCallback(async () => {
    const config = {
      issuer: 'https://dev-7768080.okta.com/oauth2/default',
    };

    const authClient = new OktaAuth(config);

    authClient
      .signInWithCredentials({
        username: 'members@accelerist.com',
        password: 'U7wCMAFRVThn',
      })
      .then(transaction => {
        if (transaction.status === 'SUCCESS') {
          window.open(
            authClient.getIssuerOrigin() +
              '/login/sessionCookieRedirect' +
              toQueryString({
                checkAccountSetupComplete: true,
                token: transaction.sessionToken,
                redirectUrl:
                  'https://dev-7768080.okta.com/home/dev-7768080_hubspotsaml_1/0oa7ru1ma2GvcDsWS5d7/aln7ru9058XW4vhNU5d7',
              }),
          );
        } else {
          throw Error('Okta:We cannot handle the ' + transaction.status + ' status');
        }
      })
      .catch(err => {
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <button onClick={handleLoginOkta}>login okta</button>
    </div>
  );
};

export default Demo;
