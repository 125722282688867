// This sets the values of the array for the gross receipts filter slider
const vettingFilterValues = [
  0,
  10000,
  20000,
  30000,
  40000,
  50000,
  60000,
  70000,
  80000,
  90000,
  100000,
  150000,
  200000,
  250000,
  300000,
  350000,
  400000,
  450000,
  500000,
  550000,
  600000,
  650000,
  700000,
  750000,
  800000,
  850000,
  900000,
  950000,
  1000000,
  2000000,
  3000000,
  4000000,
  5000000,
  6000000,
  7000000,
  8000000,
  9000000,
  10000000,
  11000000,
  12000000,
  13000000,
  14000000,
  15000000,
  16000000,
  17000000,
  18000000,
  19000000,
  20000000,
  21000000,
  22000000,
  23000000,
  24000000,
  25000000,
  26000000,
  27000000,
  28000000,
  29000000,
  30000000,
  31000000,
  32000000,
  33000000,
  34000000,
  35000000,
  36000000,
  37000000,
  38000000,
  39000000,
  40000000,
  41000000,
  42000000,
  43000000,
  44000000,
  45000000,
  46000000,
  47000000,
  48000000,
  49000000,
  50000000,
  51000000,
  52000000,
  53000000,
  54000000,
  55000000,
  56000000,
  57000000,
  58000000,
  59000000,
  60000000,
  61000000,
  62000000,
  63000000,
  64000000,
  65000000,
  66000000,
  67000000,
  68000000,
  69000000,
  70000000,
  71000000,
  72000000,
  73000000,
  74000000,
  75000000,
  76000000,
  77000000,
  78000000,
  79000000,
  80000000,
  81000000,
  82000000,
  83000000,
  84000000,
  85000000,
  86000000,
  87000000,
  88000000,
  89000000,
  90000000,
  91000000,
  92000000,
  93000000,
  94000000,
  95000000,
  96000000,
  97000000,
  98000000,
  99000000,
  100000000,
  200000000,
  300000000,
  400000000,
  500000000,
  600000000,
  700000000,
  800000000,
  900000000,
  1000000000,
  5000000000,
  10000000000,
  15000000000,
  20000000000,
  25000000000,
  30000000000,
  35000000000,
  40000000000,
  45000000000,
  50000000000,
  55000000000,
  60000000000,
  70000000000,
  80000000000,
  90000000000,
  1000000000000,
];
export default vettingFilterValues;
