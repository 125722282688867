import { Field } from 'components/FinalForm';
import { FieldSingleSelect, FieldTextInput } from 'components/FormFields';
import FieldUploadFile from 'components/FormFields/FieldUploadFile';
import FieldWYSIWYGEditor from 'components/FormFields/FieldWYSIWYGEditor';
import { UIButton } from 'components/UI';
import { deploymentDayOptions, GamePlanEmailTemplateTypeEnum } from 'constants/gamePlan';
import { CustomizeTemplateGetReq, Gameplan } from 'interfaces/gameplan';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FieldArray } from 'react-final-form-arrays';
import { TeamProfileData } from 'interfaces/team';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  type?: string;
  isReadonly?: boolean;
  emailAnalytics?: Gameplan['emailTemplateAnalytics'];
  team: TeamProfileData;
  showCustomizeTemplateModal?: () => void;
  showPreviewModal?: (body: string) => void;
  onFetchGameplanCustomizeTemplate?: (values: CustomizeTemplateGetReq) => void;
  gamePlanTemplates?: Gameplan['gamePlanTemplates'];
}

export type GameplanFormData = Gameplan;

const titles = [
  '1st Email: Get to Know You',
  '2nd Email: Brief Follow-up',
  '3rd Email: Mission Content',
];

const GamePlanTemplate = (props: Props) => {
  const { type, emailAnalytics, team, gamePlanTemplates } = props;

  const templateInfo =
    gamePlanTemplates && gamePlanTemplates.length > 0 ? gamePlanTemplates : null;

  const initialDeploymentDay =
    templateInfo && templateInfo[0]?.deploymentDay ? templateInfo[0].deploymentDay : 1;

  // const referenceDate =
  //   templateInfo && templateInfo[0]?.scheduledTime
  //     ? new Date(templateInfo[0].scheduledTime)
  //     : new Date();
  // referenceDate.setDate(referenceDate.getDate() - initialDeploymentDay);
  // referenceDate.setHours(0, 0, 0, 0);
  // console.log(referenceDate);

  const [referenceDate, setReferenceDate] = useState(
    templateInfo && templateInfo[0]?.scheduledTime
      ? new Date(templateInfo[0].scheduledTime)
      : new Date(),
  );

  useEffect(() => {
    setReferenceDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() - initialDeploymentDay);
      newDate.setHours(0, 0, 0, 0);
      return newDate;
    });
  }, [initialDeploymentDay]);

  // const referenceDate = new Date();
  // referenceDate.setDate(referenceDate.getDate() - 1);
  // // console.log(referenceDate);
  // referenceDate.setHours(0, 0, 0, 0);

  function deploymentDayToDate(deploymentDay) {
    const date = new Date(referenceDate);
    date.setDate(date.getDate() + deploymentDay);
    return date;
  }

  const currentDay = new Date();
  currentDay.setDate(currentDay.getDate() - 1);
  currentDay.setHours(0, 0, 0, 0);

  function dateToDeploymentDay(date) {
    const diff = date.getTime() - currentDay.getTime();
    return Math.round(diff / (1000 * 60 * 60 * 24));
  }

  const CustomInputButton = React.forwardRef<
    HTMLButtonElement,
    { value?: string; onClick?: () => void; disabled?: boolean }
  >(({ value, onClick }, ref) => (
    <StyledButton
      className="custom-datepicker-input"
      onClick={onClick}
      ref={ref}
      disabled={props.isReadonly}
    >
      {value || 'Select a date'}
    </StyledButton>
  ));

  CustomInputButton.displayName = 'CustomInputButton';

  const GamePlanDatePicker = ({ input: { onChange, value }, meta, label }) => {
    const initialDate = deploymentDayToDate(value);
    // const [selectedDate, setSelectedDate] = useState(deploymentDayToDate(value));

    // useEffect(() => {
    //   setSelectedDate(deploymentDayToDate(value));
    // }, [value]);

    return (
      <div style={{ marginBottom: '16px' }}>
        {label && <FormLabel>{label}</FormLabel>}
        <DatePicker
          selected={initialDate}
          // selected={selectedDate}
          onChange={date => {
            // setSelectedDate(date);
            const newDeploymentDay = dateToDeploymentDay(date);
            const updatedReferenceDate = new Date(date);
            updatedReferenceDate.setDate(
              updatedReferenceDate.getDate() - newDeploymentDay,
            );
            updatedReferenceDate.setHours(0, 0, 0, 0);
            setReferenceDate(updatedReferenceDate);
            onChange(newDeploymentDay);
          }}
          minDate={new Date()}
          openToDate={new Date()}
          showYearDropdown
          showMonthDropdown
          // onKeyDown={e => {
          //   e.preventDefault();
          // }}
          customInput={<CustomInputButton />}
        />
      </div>
    );
  };

  const isLockDate = type === 'Recommended';

  const onClickCustomizeTemplateBtn = (index: number) => {
    switch (index) {
      case 0:
        props.onFetchGameplanCustomizeTemplate?.({
          templateType: GamePlanEmailTemplateTypeEnum.GetToKnowYou,
        });
        props.showCustomizeTemplateModal?.();
        break;
      case 1:
        props.onFetchGameplanCustomizeTemplate?.({
          templateType: GamePlanEmailTemplateTypeEnum.BriefFollowUp,
        });
        props.showCustomizeTemplateModal?.();
        break;
      case 2:
        props.onFetchGameplanCustomizeTemplate?.({
          templateType: GamePlanEmailTemplateTypeEnum.MissionContent,
        });
        props.showCustomizeTemplateModal?.();
        break;
      case 3:
        break;
    }
  };

  return (
    <Root>
      <FieldArray name="gamePlanTemplates">
        {({ fields }) => {
          return fields.map((name, index) => {
            const {
              replyNum,
              deliveredNum,
              emailNum,
              onceReplyNum,
              onceOpenNum,
              onceClickNum,
            } = emailAnalytics?.[fields.value[index].id] || {};
            const replyRate =
              emailNum && onceReplyNum
                ? ((onceReplyNum / emailNum) * 100).toFixed() + '%'
                : '-';
            const openRate =
              emailNum && onceOpenNum
                ? ((onceOpenNum / emailNum) * 100).toFixed() + '%'
                : '-';
            const clickRate =
              emailNum && onceClickNum
                ? ((onceClickNum / emailNum) * 100).toFixed() + '%'
                : '-';

            return (
              <div key={index} style={{ position: 'relative' }}>
                <>
                  <Title>{titles[index]}</Title>
                  {props.isReadonly && team.isAnalyticsGamePlan && (
                    <Metrics>
                      <MetricsItem>
                        <MetricsItemTitle>Reply Rate</MetricsItemTitle>
                        <MetricsItemValue>{replyRate}</MetricsItemValue>
                      </MetricsItem>
                      <MetricsItem>
                        <MetricsItemTitle>Open Rate</MetricsItemTitle>
                        <MetricsItemValue>{openRate}</MetricsItemValue>
                      </MetricsItem>
                      <MetricsItem>
                        <MetricsItemTitle>Click Rate</MetricsItemTitle>
                        <MetricsItemValue>{clickRate}</MetricsItemValue>
                      </MetricsItem>
                      <MetricsItem>
                        <MetricsItemTitle>Delivered</MetricsItemTitle>
                        <MetricsItemValue>{deliveredNum}</MetricsItemValue>
                      </MetricsItem>
                      <MetricsItem>
                        <MetricsItemTitle>Replied</MetricsItemTitle>
                        <MetricsItemValue>{replyNum}</MetricsItemValue>
                      </MetricsItem>
                    </Metrics>
                  )}
                  <SelectRow>
                    <Field
                      name={`${name}.deploymentDay`}
                      label="Deployment Date"
                      component={GamePlanDatePicker}
                      placeholder="Select Day"
                      disabled={props.isReadonly}
                    />
                  </SelectRow>
                  <SelectRow>
                    <Field
                      name={`${name}.subject`}
                      label="subject"
                      component={FieldTextInput}
                      placeholder="Enter Email Subject"
                      disabled={props.isReadonly}
                    />
                  </SelectRow>

                  <ButtonPosition>
                    <GameplanTemplateButtonGroup>
                      <TemplateButton
                        title="Preview"
                        modifiers="secondary"
                        handleClick={() =>
                          props.showPreviewModal?.(fields.value[index].body)
                        }
                      />
                      {!isLockDate && !props.isReadonly && (
                        <TemplateButton
                          title="customize template"
                          modifiers="secondary"
                          handleClick={() => onClickCustomizeTemplateBtn(index)}
                        />
                      )}
                    </GameplanTemplateButtonGroup>
                  </ButtonPosition>

                  <Field
                    id={`gameplan-template-body-editor-${index}`}
                    name={`${name}.body`}
                    label="Text"
                    component={FieldWYSIWYGEditor}
                    placeholder="Enter Email Template"
                    height={482}
                    disabled={props.isReadonly}
                  />

                  <Field
                    name={`${name}.attachments`}
                    label="Attachs"
                    component={FieldUploadFile}
                    disabled={props.isReadonly}
                    htmlId={`attachments${index}-input-file`}
                    category={'gameplan'}
                  />
                </>
              </div>
            );
          });
        }}
      </FieldArray>
    </Root>
  );
};

const Root = styled.div`
  width: 1314px;
  background: #ffffff;
  margin-bottom: 32px;
  padding: 0 32px 1px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  padding: 24px 0;
`;

const SelectRow = styled.div`
  width: 374px;
`;

const ButtonPosition = styled.div`
  position: relative;
`;
const TemplateButton = styled(UIButton)`
  margin-left: 16px;
`;

const GameplanTemplateButtonGroup = styled.div`
  position: absolute;
  right: 0;
  top: -54px;
`;

const Metrics = styled.div`
  background: #f9f9f9;
  border-radius: 4px;
  height: 72px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
`;

const MetricsItem = styled.div`
  margin-top: 6px;
`;

const MetricsItemTitle = styled.div`
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: var(--darkGray);
`;
const MetricsItemValue = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  text-align: center;
  color: var(--black);
`;
const StyledButton = styled.button<{ disabled?: boolean }>`
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  display: inline-block;
  margin: 0;
  transition: background-color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border-color: #bfbfbf;
  }
  &:active {
    background-color: #e0e0e0;
    border-color: #888;
  }
  ${({ disabled }) =>
    disabled &&
    `
      background: #F9F9F9;
      color: var(--gray);
      border: 1px solid transparent;
      cursor: not-allowed;
      &:focus{
        border: 1px solid transparent;
      }
    `}
`;
const FormLabel = styled.label`
  display: block;
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
  margin-bottom: 4px;
`;

export default GamePlanTemplate;
