import { FieldRadioButtons } from 'components/FormFields';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import CompanySection from './CompanySection';
import ContactsSection from './ContactsSection';
import { Field } from 'components/FinalForm';
import PrioritySection from './PrioritySection';
import CustomerSection from './CustomerSection';
import { UISuggestOption } from 'components/UI/interfaces';
import { debounce } from 'lodash';
import { useOperation } from 'hooks/common';
import { fetchCompaniesSearch } from 'store/ducks/companies/operations';
import {
  getAdditionalInfoSelect,
  getCompaniesSearch,
} from 'store/ducks/companies/selectors';
import { useSelector } from 'react-redux';
import { SuggestCompany } from 'interfaces/companies';
import { AdditionalInfoProps, StateProps } from 'store/interfaces';
import { getSearchJobTitles } from 'store/ducks/contacts/selectors';
import { fetchSearchJobTitle } from 'store/ducks/contacts/operations';

interface Props {
  className?: string;
}

const FilterFormSection = ({ className }: Props) => {
  const [onSearchCompanies] = useOperation(fetchCompaniesSearch);
  const [onSearchJobTitles] = useOperation(fetchSearchJobTitle);

  const companiesSearchList = useSelector<StateProps, SuggestCompany[]>(
    getCompaniesSearch,
  );
  const searchJobTitles = useSelector(getSearchJobTitles);
  const additionalInfo = useSelector<StateProps, AdditionalInfoProps>(
    getAdditionalInfoSelect(),
  );

  const handleSearchCompanies = (val: string) => {
    return onSearchCompanies(val);
  };

  const handleSearchCompaniesDebounce = useCallback(
    debounce(handleSearchCompanies, 800),
    [],
  );

  const handleSearchJobTitles = (val: string) => {
    return onSearchJobTitles(val);
  };

  const handleSearchJobTitleDebounce = useCallback(
    debounce(handleSearchJobTitles, 800),
    [],
  );

  const jobTitlesSearchList = useMemo(
    (): UISuggestOption[] =>
      searchJobTitles.map(item => ({ id: item.jobTitle, name: item.jobTitle })),
    [searchJobTitles],
  );

  return (
    <Root className={className}>
      <Title>Filters</Title>
      <RadioWrapper>
        <div>Use the following parameter for all criteria</div>
        <Field
          name="logicType"
          component={FieldRadioButtons}
          options={[
            { label: 'AND', value: 'and' },
            { label: 'OR', value: 'or' },
          ]}
        />
      </RadioWrapper>

      <CompanySection
        crsFocus={additionalInfo.crsFocus}
        indestriesTree={additionalInfo.industriesTree}
        countries={additionalInfo.countries}
        naicsCode={additionalInfo.naicsCode}
        primaryNaicsCode={additionalInfo.primaryNaicsCode}
        states={additionalInfo.states}
        cites={additionalInfo.cites}
        handleGetCompanies={handleSearchCompaniesDebounce}
        companiesSearchList={companiesSearchList}
      />
      <ContactsSection
        handleGetJobTitleSuggestions={handleSearchJobTitleDebounce}
        jobTitlesSearchList={jobTitlesSearchList}
      />
      <CustomerSection />
      <PrioritySection />
    </Root>
  );
};

const Root = styled.form`
  background: #ffffff;
  width: 100%;
  max-width: 1095px;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  margin-bottom: 18px;
`;

const RadioWrapper = styled.div`
  display: flex;
  white-space: nowrap;

  & > :first-child {
    margin-right: 34px;
  }
`;

export default FilterFormSection;
