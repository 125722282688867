import React from 'react';
import { Route as ReactRouterRoute, Switch } from 'react-router-dom';
import { RouteProps } from 'react-router-dom';

export interface MyRouteProps extends RouteProps {
  layout?: React.ElementType;
  [key: string]: any;
}

const PassThrough = ({ children }: MyRouteProps) => <>{children}</>;

export const Route = ({
  layout: Layout = PassThrough,
  component: Component = PassThrough,
  children,
  ...rest
}: MyRouteProps) => {
  return (
    <ReactRouterRoute
      {...rest}
      render={props => (
        <Layout {...props}>
          <Component {...props}>
            <Switch>{children}</Switch>
          </Component>
        </Layout>
      )}
    />
  );
};

export default Route;
