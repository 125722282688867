import React, { useMemo } from 'react';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';
import { ROIMeltwaterData } from 'interfaces/roi';
import styled from 'styled-components';
import { BarChart, Bar, XAxis, Legend, YAxis, LabelList } from 'recharts';
import { numberFormat } from 'utils/formatters';

interface Props {
  data?: ROIMeltwaterData['analyticsData']['top_sources']['sources'];
}

const renderCustomizedLabel = props => {
  const { value, y, height, index } = props;
  if (index === 0) {
    return (
      <text x={200} y={y + height / 2 + 4} fontSize={12} fill="#fff">
        {numberFormat(value) + ' Followers'}
      </text>
    );
  }
  return (
    <text x={200} y={y + height / 2 + 4} fontSize={12}>
      {numberFormat(value) + ' Followers'}
    </text>
  );
};

const TopAuthorsMetric = ({ data }: Props) => {
  const maxValue = useMemo(
    () =>
      data?.sort((a, b) => b.author.twitter.followers - a.author.twitter.followers)[0]
        .author.twitter.followers,
    [data],
  );

  return (
    <MetricRoot>
      <MetricTitle>Top Authors</MetricTitle>
      <Content>
        {data && (
          <BarChart
            width={320}
            height={156}
            data={data}
            layout="vertical"
            margin={{ top: 5 }}
            barCategoryGap={8}
          >
            <XAxis type="number" domain={[0, maxValue]} hide />
            <YAxis type="category" dataKey="author.display_name" hide />
            <Legend
              iconType="circle"
              formatter={() => 'followers'}
              iconSize={6}
              wrapperStyle={{ fontSize: '12px', fontWeight: 500, bottom: '-4px' }}
            />
            <Bar
              isAnimationActive={false}
              dataKey="author.twitter.followers"
              fill="#28759C"
              radius={4}
              maxBarSize={32}
              label={({ y, index }) => {
                return (
                  <text y={y} fill="#666" textAnchor="start" dy={-4} fontSize="12px">
                    {data[index].author.display_name}
                  </text>
                );
              }}
            >
              <LabelList position="right" content={renderCustomizedLabel} />
            </Bar>
          </BarChart>
        )}
      </Content>
    </MetricRoot>
  );
};

const Content = styled(MetricContent)`
  position: relative;
`;

export default TopAuthorsMetric;
