import React, { useState } from 'react';
import styled from 'styled-components';
import { UIButton, UIModal, UITextInput } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { ColorPicker, useColor, toColor, Color } from 'react-color-palette';
import { FormLabel, FormRow } from 'components/UI/form';
import _ from 'lodash';

interface BaseProps {
  value: string[];
  defaultSelectedColorIndex: number;
  handleSubmit: (value: string[]) => void;
}

enum ActiveInputEnum {
  Header_Background,
  Header_Text,
  Donate_Button_Background,
  Donate_Button_Text,
}

type Props = BaseProps & InjectedProps;

const SelectColorModalTS = ({
  handleSubmit,
  onClose,
  value,
  defaultSelectedColorIndex,
  ...modalProps
}: Props) => {
  useLockBodyScroll();
  const [colors, setColors] = useState(value?.length ? [...value] : ['', '', '', '']);
  const [activeInput, setActiveInput] = useState(defaultSelectedColorIndex);
  const [color, setColor] = useColor(
    'hex',
    colors[defaultSelectedColorIndex] || '#ffffff',
  );

  const handleChangeColorsThrottle = _.throttle((color: Color) => {
    const newColors = [...colors];
    newColors[activeInput] = color.hex;
    setColors(newColors);
  }, 100);

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '0' }}
      style={{
        width: '100%',
        maxWidth: '520px',
      }}
    >
      <Content>
        <Title>Select Color</Title>
        <ColorPicker
          width={456}
          height={228}
          color={color}
          hideHSV
          dark
          onChange={color => {
            setColor(color);
            handleChangeColorsThrottle(color);
          }}
        />
        <InputGroup>
          <FormRow>
            <FieldWrapper $color={colors[0]}>
              <FormLabel>Header Background</FormLabel>
              <TextInput
                $active={activeInput === ActiveInputEnum.Header_Background}
                readOnly
                value={colors[0].toUpperCase()}
                onFocus={() => {
                  setColor(toColor('hex', colors[0] || '#ffffff'));
                  setActiveInput(ActiveInputEnum.Header_Background);
                }}
              />
            </FieldWrapper>
            <FieldWrapper $color={colors[1]}>
              <FormLabel>Header Text</FormLabel>
              <TextInput
                $active={activeInput === ActiveInputEnum.Header_Text}
                readOnly
                value={colors[1].toUpperCase()}
                onFocus={() => {
                  setColor(toColor('hex', colors[1] || '#ffffff'));
                  setActiveInput(ActiveInputEnum.Header_Text);
                }}
              />
            </FieldWrapper>
          </FormRow>
          <FormRow>
            <FieldWrapper $color={colors[2]}>
              <FormLabel>Donate Button Background</FormLabel>
              <TextInput
                $active={activeInput === ActiveInputEnum.Donate_Button_Background}
                readOnly
                value={colors[2].toUpperCase()}
                onFocus={() => {
                  setColor(toColor('hex', colors[2] || '#ffffff'));
                  setActiveInput(ActiveInputEnum.Donate_Button_Background);
                }}
              />
            </FieldWrapper>
            <FieldWrapper $color={colors[3]}>
              <FormLabel>Donate Button Text</FormLabel>
              <TextInput
                $active={activeInput === ActiveInputEnum.Donate_Button_Text}
                readOnly
                value={colors[3].toUpperCase()}
                onFocus={() => {
                  setColor(toColor('hex', colors[3] || '#ffffff'));
                  setActiveInput(ActiveInputEnum.Donate_Button_Text);
                }}
              />
            </FieldWrapper>
          </FormRow>
        </InputGroup>

        <Actions>
          <UIButton modifiers="secondary" title="Cancel" handleClick={onClose} />
          <UIButton
            modifiers="primary"
            title="Select"
            handleClick={() => {
              handleSubmit(colors);
              onClose();
            }}
          />
        </Actions>
      </Content>
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0 auto 8px;
`;

const Content = styled.div`
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const TextInput = styled(UITextInput)<{ $active: boolean }>`
  padding-left: 42px;

  ${({ $active }) =>
    $active &&
    `
    border: 1px solid #2baee0;
  `}
`;

const FieldWrapper = styled.div.attrs(({ $color }: { $color: string }) => ({
  $color,
}))`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 16px;
    top: 32px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: ${({ $color }) => $color};
  }
`;

const InputGroup = styled.div`
  margin: 24px 0 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default SelectColorModalTS;
