import ArrowIcon from 'assets/images/icons/arrow-left.svg';
import { StewaredForm } from 'components/roi/forms';
import StewardPlanTable from 'components/roi/tables/StewardPlanTable';
import { UIActionButton, UITextInput } from 'components/UI';
import { ROIEditNavigationEnum } from 'constants/roi';
import { useOperation } from 'hooks/common';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  fetchROI,
  fetchROIChangeStatus,
  fetchROIGenerateTemplates,
  fetchROITemplate,
  fetchROITemplateCompiled,
  fetchROITemplateListSave,
  fetchROITemplatePreview,
  fetchROITemplateReset,
  fetchROITemplates,
  fetchROITemplateSave,
  fetchROIUpdate,
  toggleROITemplatesDisable,
} from 'store/ducks/roi/operations';
import {
  getROI,
  getRoiTemplatePreview,
  getROITemplates,
} from 'store/ducks/roi/selectors';
import styled from 'styled-components';

const Steward = () => {
  const [isShowTemplates, setIsShowTemplates] = useState(false);
  const [stewardshipTreatmentValue, setStewardshipTreatmentValue] = useState<
    string | undefined
  >('');

  const [onFetchGenerateTemplates] = useOperation(fetchROIGenerateTemplates);
  const [onFetchROITemplatePreview] = useOperation(fetchROITemplatePreview);
  const [onFetchROITemplateCompiled] = useOperation(fetchROITemplateCompiled);
  const [onFetchROITemplateSave] = useOperation(fetchROITemplateSave);
  const [onFetchROITemplates] = useOperation(fetchROITemplates);
  const [onToggleROITemplateDisable] = useOperation(toggleROITemplatesDisable);
  const [onFetchROITemplateListSave] = useOperation(fetchROITemplateListSave);
  const [onFetchROITemplateReset] = useOperation(fetchROITemplateReset);
  const [onFetchROIUpdate] = useOperation(fetchROIUpdate);
  const [onFetchROITemplate] = useOperation(fetchROITemplate);
  const [onFetchROI] = useOperation(fetchROI);
  const [onFetchROIChangeStatus] = useOperation(fetchROIChangeStatus);

  const roi = useSelector(getROI);
  const roiTemplates = useSelector(getROITemplates);
  const templatePreview = useSelector(getRoiTemplatePreview);

  useEffect(() => {
    setStewardshipTreatmentValue(roi?.stewardshipTreatment);
  }, [roi]);

  return (
    <>
      {isShowTemplates ? (
        <div>
          <Title onClick={() => setIsShowTemplates(false)}>
            <UIActionButton
              style={{ marginRight: '16px' }}
              handleClick={() => {}}
              icon={ArrowIcon}
            />
            <span>Stewardship</span>
          </Title>
          <TextInput
            value={stewardshipTreatmentValue}
            onChange={e => setStewardshipTreatmentValue(e.target.value)}
          />
          <ContentTitle>Recommendation</ContentTitle>
          <ContentDescribe>
            These partners require quarterly or bi-monthly support and management.
            Self-sustaining resources are helpful, but they will also need executive
            engagement and levels of custom reporting. The goal for this group is to
            manage expectations, but also encourage consistent support and growth
            opportunities.
          </ContentDescribe>
          <StewardPlanTable
            onFetchROITemplatePreview={onFetchROITemplatePreview}
            headCells={['Outputs', 'Status', 'Completion', 'Note']}
            rows={roiTemplates}
            templatePreview={templatePreview}
            onFetchROITemplateSave={onFetchROITemplateSave}
            onCancel={() => setIsShowTemplates(false)}
            onToggleROITemplateDisable={onToggleROITemplateDisable}
            onFetchROITemplateListSave={templates => {
              onFetchROITemplateListSave(templates).then(
                () =>
                  roi &&
                  onFetchROIUpdate(
                    {
                      ...roi,
                      stewardshipTreatment: stewardshipTreatmentValue,
                    },
                    true,
                  ),
              );
            }}
            onFetchROITemplateReset={onFetchROITemplateReset}
            onFetchROITemplateCompiled={onFetchROITemplateCompiled}
            onFetchROITemplate={onFetchROITemplate}
          />
        </div>
      ) : (
        roi && (
          <StewaredForm
            roi={roi}
            disabled={false}
            onChangeRoiStatus={check =>
              onFetchROIChangeStatus({
                roiId: roi.id,
                type: ROIEditNavigationEnum.Steward,
                bool: check,
              })
            }
            onSubmit={values =>
              onFetchROIUpdate(values, undefined, 'Stewardship info saved successfully')
            }
            onGenerate={values =>
              onFetchROIUpdate(values, true).then(() => {
                onFetchGenerateTemplates(roi.id).then(value => {
                  if (value === false) return;
                  onFetchROI(roi.id);
                  setIsShowTemplates(true);
                });
              })
            }
            onViewTemplates={roiId => {
              onFetchROITemplates(roiId);
              setIsShowTemplates(true);
            }}
          />
        )
      )}
    </>
  );
};

const ContentTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--block);
  margin-bottom: 12px;
`;

const ContentDescribe = styled.div`
  font-size: 12px;
  line-height: 150%;
  color: var(--block);
  margin-bottom: 24px;
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  margin-bottom: 24px;
  display: flex;

  & > * {
    cursor: pointer;
  }
`;

const TextInput = styled(UITextInput)`
  margin-bottom: 20px;
`;

export default Steward;
