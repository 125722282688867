import { UIDivider } from 'components/UI';
import React from 'react';
import styled from 'styled-components';
import { moneyNumberFormat } from 'utils/formatters';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';

interface Props {
  totalFundsRaised: number;
  consumers: number;
  employees: number;
  nonCash: number;
  themeColor?: string;
}

const FundRaisedMetric = ({
  totalFundsRaised,
  consumers,
  employees,
  nonCash,
  themeColor,
}: Props) => {
  return (
    <MetricRoot>
      <MetricTitle $themeColor={themeColor || '#1992B9'}>Funds Raised</MetricTitle>
      <Content>
        <Value>
          <span>Total Funds Raised</span>
          <span>{moneyNumberFormat(totalFundsRaised)}</span>
        </Value>
        <Divider type="horizontal" />
        <LabelValueGroup>
          <div>
            <div>Consumers</div>
            <div>{moneyNumberFormat(consumers)}</div>
          </div>
          <div>
            <div>Employees</div>
            <div>{moneyNumberFormat(employees)}</div>
          </div>
          <div>
            <div>Non-Cash</div>
            <div>{moneyNumberFormat(nonCash)}</div>
          </div>
        </LabelValueGroup>
      </Content>
    </MetricRoot>
  );
};

const Value = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
`;

const Content = styled(MetricContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Divider = styled(UIDivider)`
  margin: 25px 0;
  flex-shrink: 0;
`;

const LabelValueGroup = styled.div`
  display: flex;
  justify-content: space-between;

  & > div > div:first-child {
    font-size: 12px;
    color: #737373;
    margin-bottom: 4px;
  }

  & > div > div:last-child {
    font-weight: 500;
    font-size: 14px;
    color: #122434;
  }
`;

export default FundRaisedMetric;
