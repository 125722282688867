import React, { useCallback } from 'react';
import styled from 'styled-components';
import { FormError, FormLabel } from 'components/UI/form';
import { FieldRenderProps } from 'react-final-form';

interface RenderFieldInputProps extends FieldRenderProps<string | number> {
  renderInput: (inputProps) => React.ReactNode;
}

function FieldInput(props: RenderFieldInputProps) {
  const { input, meta, onBlur, label, renderInput, onFocus, ...componentProps } = props;
  const { error, submitError, touched, dirtySinceLastSubmit } = meta;
  const isError = (error && touched) || (submitError && !dirtySinceLastSubmit);

  const handleBlur = useCallback(() => {
    input.onBlur();
    if (onBlur) onBlur();
  }, [input, onBlur]);

  return (
    <Field>
      {label && <FormLabel>{label}</FormLabel>}
      {renderInput({
        ...componentProps,
        ...input,
        error: isError,
        onBlur: handleBlur,
        onFocus: onFocus,
      })}
      {isError && <Error meta={meta} />}
    </Field>
  );
}

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const Error = styled(FormError)`
  position: absolute;
`;

export default FieldInput;
