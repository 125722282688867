import { THEME_COLORS } from 'constants/common';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCompanyCurrent } from 'store/ducks/companies/selectors';
import { getTeam } from 'store/ducks/team/selectors';
import { selectUser } from 'store/ducks/user/selectors';
import useIsCompanyUser from './useIsCompanyUser';

const useBrandKits = () => {
  const team = useSelector(getTeam);
  const company = useSelector(getCompanyCurrent);
  const user = useSelector(selectUser);
  const isCompanyUser = useIsCompanyUser(user);

  const themeColor = useMemo(() => {
    let colors: string[] | undefined = undefined;

    if (isCompanyUser) {
      if (!company) return;
      colors =
        company.brandTheme === THEME_COLORS.Custom_colors.title
          ? company.brandCustomColors
          : Object.values(THEME_COLORS).find(item => item.title === company.brandTheme)
              ?.value;
    } else {
      if (!team) return;
      colors =
        team.brandTheme === THEME_COLORS.Custom_colors.title
          ? team.brandCustomColors
          : Object.values(THEME_COLORS).find(item => item.title === team.brandTheme)
              ?.value;
    }

    if (!colors) colors = THEME_COLORS.Accelerist_theme.value;

    return {
      Primary: colors[0],
      Secondary: colors[1],
      Tertiary: colors[2],
      Complementary: colors[3],
    };
  }, [company, isCompanyUser, team]);

  const themeFont = useMemo(() => {
    if (isCompanyUser) {
      if (!company) return;
      return company.brandFont || 'Rubik';
    }

    if (!team) return;
    return team.brandFont || 'Rubik';
  }, [company, isCompanyUser, team]);

  return { themeColor, themeFont };
};

export default useBrandKits;
