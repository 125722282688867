import React, { useCallback, useEffect } from 'react';
import OrganizationWrapper from 'layouts/OrganizationWrapper';
import { debounce } from 'lodash';
import ProfileForm from 'components/Forms/ProfileForm';
import { UISpinner } from 'components/UI';
import { useSelector } from 'react-redux';
import { useOperation } from 'hooks/common';
import { useHistory } from 'react-router';
import { TeamProfileDataForm } from 'interfaces/team';
import { SuggestCompany } from 'interfaces/companies';
import {
  getImpactGenome,
  getPayPalGivingFundCanUpdate,
  getPayPalGivingFundIfConfirm,
  getProgramFocusOptions,
  getTeam,
} from 'store/ducks/team/selectors';
import {
  fetchTeam,
  fetchTeamUpdate,
  fetchTeamLogoUpload,
  fetchPayPalGivingFundCanUpdate,
  fetchPayPalGivingFundIfConfirm,
  fetchImpactGenome,
} from 'store/ducks/team/operations';
import { fetchCompaniesSearch } from 'store/ducks/companies/operations';
import {
  getCompaniesSearch,
  getAdditionalInfoSelect,
} from 'store/ducks/companies/selectors';
import styled from 'styled-components';
import { StateProps, AdditionalInfoProps } from 'store/interfaces';
import { getEinSuggest } from 'store/ducks/campaign/selectors';
import { fetchChangePpgf, fetchEinList } from 'store/ducks/campaign/operations';
import Toast from 'services/Toast';

const ProfileEdit = () => {
  const [updateTeam] = useOperation(fetchTeamUpdate);
  const [onfetchTeam, isLoading] = useOperation(fetchTeam);
  const [onLogoUpload] = useOperation(fetchTeamLogoUpload);
  const [onSearchCompanies] = useOperation(fetchCompaniesSearch);
  const [onSearchEin] = useOperation(fetchEinList);
  const [onFetchPayPalGivingFundCanUpdate] = useOperation(fetchPayPalGivingFundCanUpdate);
  const [onFetchPayPalGivingFundIfConfirm] = useOperation(fetchPayPalGivingFundIfConfirm);
  const [onFetchChangePpgf] = useOperation(fetchChangePpgf);
  const [onFetchImpactGenome] = useOperation(fetchImpactGenome);

  const einSearchList = useSelector(getEinSuggest);
  const payPalGivingFundCanUpdate = useSelector(getPayPalGivingFundCanUpdate);
  const payPalGivingFundIfConfirm = useSelector(getPayPalGivingFundIfConfirm);
  const programFocusOptions = useSelector(getProgramFocusOptions);
  const impactGenome = useSelector(getImpactGenome);

  const { push } = useHistory();

  useEffect(() => {
    onfetchTeam(undefined);
    onFetchPayPalGivingFundCanUpdate(undefined);
    onFetchPayPalGivingFundIfConfirm(undefined);
    onFetchImpactGenome(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const team = useSelector(getTeam);

  const additionalInfo = useSelector<StateProps, AdditionalInfoProps>(
    getAdditionalInfoSelect(),
  );

  const companiesSearchList = useSelector<StateProps, SuggestCompany[]>(
    getCompaniesSearch,
  );

  const handleUpdateTeam = useCallback(
    (values: TeamProfileDataForm) => {
      if (!team) return;
      if (!values?.paypalGivingFundId && values?.paypalGivingFundName) {
        // values.paypalGivingFundName = '';
        Toast.warn('Please select a PayPal Giving Fund account from the drop-down list.');
        return;
      }
      if (
        !payPalGivingFundIfConfirm &&
        !values.paypalGivingFundName &&
        team.paypalGivingFundName
      ) {
        Toast.warn(
          "The 'PayPal Giving Fund' field can't be blank because there are already published campaigns.",
        );
        return;
      }

      return updateTeam({
        ...values,
        charitablePartners: values.charitablePartners.map(partner => partner.id),
        teamPartnerCompanies: values.teamPartnerCompanies.map(({ id, name }) => ({
          companyId: id,
          companyName: name,
        })),
      }).then(() => {
        if (
          payPalGivingFundIfConfirm &&
          payPalGivingFundCanUpdate &&
          values.paypalGivingFundId
        )
          onFetchChangePpgf({
            paypalGivingFundId: values.paypalGivingFundId,
            teamId: team.id,
          });
        push('/profile');
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [payPalGivingFundCanUpdate, payPalGivingFundIfConfirm, team],
  );

  const handleSearchCompanies = (val: string) => {
    return onSearchCompanies(val);
  };

  const handleSearchCompaniesDebounce = useCallback(
    debounce(handleSearchCompanies, 800),
    [],
  );

  const handleSearchList = (val: string) => {
    return onSearchEin(val);
  };

  const handleSearchListDebounce = useCallback(debounce(handleSearchList, 800), []);

  return (
    <OrganizationWrapper>
      {isLoading ? (
        <Spinner />
      ) : (
        <ProfileForm
          initialValues={team}
          onSubmit={handleUpdateTeam}
          handleGetCompanies={handleSearchCompaniesDebounce}
          companiesSearchList={companiesSearchList}
          missions={additionalInfo.missions}
          handleLogoUpload={async (file: File) => {
            return onLogoUpload(file);
          }}
          handleSearchFund={handleSearchListDebounce}
          einSearchList={einSearchList}
          payPalGivingFundCanUpdate={payPalGivingFundCanUpdate}
          payPalGivingFundIfConfirm={payPalGivingFundIfConfirm}
          programFocusOptions={programFocusOptions}
          impactGenome={impactGenome}
        />
      )}
    </OrganizationWrapper>
  );
};

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 242px);
`;

export default ProfileEdit;
