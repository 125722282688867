import React, { useState } from 'react';
import styled from 'styled-components';
import { UIActionText, UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { Form } from 'react-final-form';
import { Field } from 'components/FinalForm';
import { FieldDatePicker, FieldTextInput } from 'components/FormFields';
import { ROIData } from 'interfaces/roi';
import { useModal } from 'hooks/common';
import { ConfirmModal } from 'components/Modals';
import { format, isAfter, subMonths } from 'date-fns';
import { getUTCAsLocalDate } from 'utils';
import { notQuote, required } from 'utils/validation';
import ReactTooltip from 'react-tooltip';
import UIAsterisk from 'components/UI/UIAsterisk';
import { FormRow } from 'components/UI/form';
import useJumpToSupport from 'hooks/common/useJumpToSupport';

interface Props extends InjectedProps {
  disabled: boolean;
  roi: ROIData;
  onSave: (values: ROIData) => void;
  onTrack: (values: ROIData) => void;
}

const ConfigureTrackingModal = ({
  onClose,
  disabled,
  roi,
  onSave,
  onTrack,
  ...modalProps
}: Props) => {
  useLockBodyScroll();
  const [formValues, setFormValues] = useState<ROIData | undefined>(undefined);

  const getSubmitData = (values: ROIData) => {
    return {
      ...values,
      startDate: values.startDate && values.startDate.split('T')[0],
      endDate: values.endDate && values.endDate.split('T')[0],
    };
  };

  const [showModal, hideModal] = useModal(
    () => (
      <ConfirmModal
        name="Track Partnership"
        description="Once tracking information has been submitted, it cannot be changed. Are you sure you are ready to track partnership?"
        accessHandler={hideModal}
        cancelHandler={() => {
          formValues && onTrack(getSubmitData(formValues));
          hideModal();
        }}
        accessModifiersBtn="secondary"
        cancelModifiersBtn="primary"
        textAccessBtn="Cancel"
        textCancelBtn="Continue"
        onClose={hideModal}
      />
    ),
    [formValues],
  );

  const { jumpToSupport } = useJumpToSupport();

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 0 24px' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '836px',
        overflow: 'unset',
      }}
    >
      <Title>Configure Tracking</Title>
      <Form
        initialValues={{
          ...roi,
          startDate:
            roi.startDate && format(getUTCAsLocalDate(roi.startDate), 'yyyy-MM-dd'),
          endDate: roi.endDate && format(getUTCAsLocalDate(roi.endDate), 'yyyy-MM-dd'),
        }}
        onSubmit={(values: ROIData) => {
          setFormValues(values);
          showModal();
        }}
        render={({ handleSubmit, form, values }) => (
          <FormBody>
            {disabled && (
              <AlertText>
                Media Tracking for this partnership cannot be edited. Please contact&nbsp;
                <ActionText onClick={jumpToSupport}>Accelerist Support</ActionText>
                &nbsp;for help.
              </AlertText>
            )}
            <Field
              name="campaignKeywords"
              label="Campaign Keywords"
              component={FieldTextInput}
              placeholder="separate keywords with comma"
              placeholderSize={13}
              validate={notQuote}
              disabled={disabled}
            />
            <Field
              name="influencerHandles"
              label="Influencer Handles (include celebrities, bloggers, experts, etc.) "
              component={FieldTextInput}
              placeholder="separate handles with comma"
              placeholderSize={13}
              validate={notQuote}
              disabled={disabled}
            />
            <FormRow>
              <Field
                name="partnerSocialHandles"
                label="Partner Social Handles (include all networks) "
                component={FieldTextInput}
                placeholder="separate handles with comma"
                placeholderSize={13}
                validate={notQuote}
                disabled={disabled}
              />
              <Field
                name="nonprofitSocialHandles"
                label="Nonprofit Social Handles (include all networks)"
                component={FieldTextInput}
                placeholder="separate handles with comma"
                placeholderSize={13}
                validate={notQuote}
                disabled={disabled}
              />
            </FormRow>

            <FormRow>
              <Field name="startDate" validate={required}>
                {({ input, meta }) => (
                  <FieldDatePicker
                    label={
                      <>
                        Start Date
                        <UIAsterisk />
                      </>
                    }
                    input={{
                      ...input,
                      onChange: value => {
                        const isStartAfterEnd = isAfter(
                          new Date(value),
                          new Date(String(values.endDate)),
                        );

                        if (isStartAfterEnd) {
                          form.change('endDate', undefined);
                        }
                        input.onChange(value);
                      },
                    }}
                    meta={meta}
                    disabled={disabled}
                    showMonthDropdown
                    dateFormat="dd MMM yyyy"
                    minDate={subMonths(new Date(), 13)}
                  />
                )}
              </Field>
              <Field
                name="endDate"
                label={
                  <>
                    End Date
                    <UIAsterisk />
                  </>
                }
                component={FieldDatePicker}
                placeholder="select"
                disabled={disabled}
                showMonthDropdown
                dateFormat="dd MMM yyyy"
                minDate={new Date(String(values.startDate))}
                validate={required}
              />
            </FormRow>

            <ButtonGroup>
              <Button title="Cancel" modifiers="secondary" handleClick={onClose} />
              {!roi.trackingStatus && (
                <div>
                  <Button
                    data-tip="save now and come back later to submit for tracking"
                    title="Save as Draft"
                    modifiers="secondary"
                    handleClick={() => onSave(getSubmitData(values))}
                    disabled={disabled}
                  />
                  <Button
                    title="Submit"
                    modifiers="primary"
                    handleClick={handleSubmit}
                    disabled={disabled}
                  />
                </div>
              )}
              <ReactTooltip
                className="tooltip-info"
                place="top"
                type="info"
                effect="solid"
              />
            </ButtonGroup>
          </FormBody>
        )}
      />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 46px;
  text-align: center;
`;

const FormBody = styled.div`
  margin: 0 32px;
`;

const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 70px;
`;

const Button = styled(UIButton)`
  font-size: 12px;
  padding: 8px 24px;
  &:not(:first-child) {
    margin-left: 18px;
  }
`;

const AlertText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--blue);
  margin-bottom: 24px;
`;

const ActionText = styled(UIActionText)`
  display: inline;
  text-decoration: underline;
`;

export default ConfigureTrackingModal;
