import React, { useMemo } from 'react';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components/macro';
import queryString from 'query-string';
import { ROIReportEditReportRoutePathsEnum } from 'constants/roi';
import ROICreateCustomizeReport from 'pages/organizationUser/roi/ROICreateCustomizeReport';
import ROIReportResults from 'pages/organizationUser/roi/ROIReportResults';
import { UIButton } from 'components/UI';
import { useSelector } from 'react-redux';
import { getSubscription } from 'store/ducks/team/selectors';
import { selectUser } from 'store/ducks/user/selectors';
import { getCompanySubscription } from 'store/ducks/companies/selectors';
import { useIsCompanyUser } from 'hooks/common';
import Toast from 'services/Toast';

const ROIReportSelect = () => {
  const params: { roiId: string } = useParams();
  const search = queryString.parse(window.location.search);
  const { path, url } = useRouteMatch();
  const { go, push } = useHistory();

  const user = useSelector(selectUser);
  const subscriptionTeam = useSelector(getSubscription);
  const subscriptionCompany = useSelector(getCompanySubscription);

  const isCompanyUser = useIsCompanyUser(user);

  const subscription = useMemo(
    () => (isCompanyUser ? subscriptionCompany : subscriptionTeam),
    [isCompanyUser, subscriptionCompany, subscriptionTeam],
  );

  return (
    <Root>
      <Switch>
        <Route path={`${path}`} exact>
          <ROIReportResults />
        </Route>
        <Route path={`${path}/${ROIReportEditReportRoutePathsEnum.Select_Report}`}>
          <SelectReportRoot>
            <Content>
              <Title>Generate Partner Report</Title>
              <CardGroup>
                <Card to={`/roi/slim-report/${params.roiId}`}>
                  Default Valuation Report
                </Card>
                {subscription?.isCanva && (
                  <Card
                    as="div"
                    onClick={() =>
                      user.isCanva
                        ? push(
                            `${url}/${
                              ROIReportEditReportRoutePathsEnum.Create_Customize_Report
                            }?${queryString.stringify(search)}`,
                          )
                        : Toast.info(
                            'You do not have access to Custom Reports. Please contact Accelerist Support. ',
                          )
                    }
                  >
                    Customize Partner Report
                  </Card>
                )}
              </CardGroup>
            </Content>
            <UIButton modifiers="secondary" title="Back" handleClick={() => go(-1)} />
          </SelectReportRoot>
        </Route>
        <Route
          path={`${path}/${ROIReportEditReportRoutePathsEnum.Create_Customize_Report}`}
        >
          <ROICreateCustomizeReport />
        </Route>
      </Switch>
    </Root>
  );
};

const Root = styled.div``;

const SelectReportRoot = styled.div`
  position: relative;
`;

const Title = styled.div`
  display: inline-block;
  margin: 130px 0 44px;
  font-weight: 500;
  font-size: 16px;
`;

const CardGroup = styled.div`
  display: flex;
`;

const Card = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  width: 343px;
  height: 124px;
  border: 1px solid #2baee0;
  border-radius: 6px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 24px;
  }
`;

const Content = styled.div`
  padding: 24px;
  background-color: #fff;
  border-radius: 6px;
  margin-top: 24px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 505px;
  margin-bottom: 24px;
`;

export default ROIReportSelect;
