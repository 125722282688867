/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from 'react';
import { UIButton, UIModal } from 'components/UI';
import { SelectOption, UIModalProps } from 'components/UI/interfaces';
import styled from 'styled-components';
import { SearchPanelWithCheckBoxes } from 'components/UI/select';
import { FormApi } from 'final-form';

interface Props extends UIModalProps {
  naicsCode: SelectOption[];
  value: string[];
  form: FormApi;
}

const NAICSCodeSubCategoryListModal = ({
  onClose,
  isOpen,
  naicsCode,
  value,
  form,
  ...modalProps
}: Props) => {
  const [selected, setSelected] = useState<string[] | undefined>(value);

  useEffect(() => {
    if (value?.join() === selected?.join()) return;
    setSelected(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      hideCloseIcon
      isOpen={isOpen}
      style={{
        width: '100%',
        maxWidth: '599px',
        height: '660px',
      }}
      propsBodyCss={{
        padding: '40px 66px',
      }}
    >
      <Title>NAICS Code Sub-Category List</Title>
      <FieldWrapper>
        <SearchPanelWithCheckBoxes
          values={selected}
          changeHandler={setSelected}
          options={naicsCode}
        />
      </FieldWrapper>
      <Actions>
        <UIButton title="Cancle" modifiers="secondary" handleClick={onClose} />
        <UIButton
          title="Select"
          modifiers="primary"
          disabled={!selected?.length}
          handleClick={() => {
            form.change('naicsCode', selected);
            onClose();
          }}
        />
      </Actions>
    </UIModal>
  );
};

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin-bottom: 28px;
`;
const FieldWrapper = styled.div`
  margin-bottom: 57px;

  ul {
    max-height: 353px !important;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    font-size: 12px;
    font-weight: 400;
  }
`;

export default NAICSCodeSubCategoryListModal;
