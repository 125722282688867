import { ActionType, createReducer } from 'typesafe-actions';

import * as types from './types';
import * as actions from './actions';
import { ContactsData } from 'interfaces/contacts';

type RootAction = ActionType<typeof actions>;

export default createReducer<ContactsData, RootAction>({
  contactsJobTitles: [],
  searchJobTitles: [],
})
  .handleType([types.SET_CONTACTS_JOBTITLES], (state, action) => ({
    ...state,
    contactsJobTitles: action.payload,
  }))
  .handleType([types.SET_SEARCH_CONTACTS], (state, action) => ({
    ...state,
    searchJobTitles: action.payload,
  }));
