import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { getFullName } from 'utils/formatters';
import { PitchButton } from 'components/ActionButtons';
import { CropText } from 'components/UI';
import { CompanyContact } from 'interfaces/companies';
import LinkedInIcon from 'assets/images/icons/linkedIn.svg';
import { parseLink } from 'utils';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/ducks/user/selectors';
import { useIsCompanyUser, useModal, useSortStatus } from 'hooks/common';
import { Link } from 'react-router-dom';
import { ConfirmModal } from 'components/Modals';
import { TableSortHeaderCell } from 'components/UI/table';

interface Props {
  rows: CompanyContact[];
  onDeleteContact?: (contactId: string) => void;
}

interface TableRowProps {
  row: CompanyContact;
  handleDeleteContact?: (contactId: string) => void;
}

const userTableWidth = {
  nameWidth: 165,
  linkedinWidth: 88,
  jobtitleWidth: 260,
  jobFunctionWidth: 260,
  managementLevelWidth: 260,
  phoneWidth: 150,
  emailWidth: 150,
  scoreWidth: 120,
  actionsWidth: 151,
} as const;

const {
  nameWidth,
  linkedinWidth,
  jobtitleWidth,
  phoneWidth,
  emailWidth,
  scoreWidth,
  actionsWidth,
  jobFunctionWidth,
  managementLevelWidth,
} = userTableWidth;

const TableRowWrapper = ({ row, handleDeleteContact }: TableRowProps) => {
  const {
    firstName,
    lastName,
    jobTitle,
    phone,
    email,
    contactAccuracyScore,
    linkedinUrl,
    id,
    jobFunction,
    managementLevel,
  } = row;

  const user = useSelector(selectUser);
  const isCompanyUser = useIsCompanyUser(user);
  const userName = getFullName(firstName, lastName);

  const [showModal, hideModal] = useModal(() => {
    const deleteHandler = () => {
      handleDeleteContact?.(id);
      hideModal();
    };

    return (
      <ConfirmModal
        name={'Delete Company Contact'}
        textAccessBtn="Delete"
        description={'Are you sure you want to delete the Company Contact?'}
        accessHandler={deleteHandler}
        cancelHandler={hideModal}
        onClose={hideModal}
        hideCloseIcon
      />
    );
  });

  return (
    <TableRow>
      <TableRowCell isNoAddBorder isAddWeight>
        {userName}
      </TableRowCell>
      <TableRowCell>{jobTitle}</TableRowCell>
      <TableRowCell>{jobFunction || 'N/A'}</TableRowCell>
      <TableRowCell>{managementLevel || 'N/A'}</TableRowCell>

      <TableRowCell>
        {phone ? (
          <ContactLink href={`tel:${phone}`}>{phone}</ContactLink>
        ) : (
          <CropText width={phoneWidth} text="No phone" />
        )}
      </TableRowCell>
      <TableRowCell>
        {email ? (
          <ContactLink href={`mailto:${email}`}>{email}</ContactLink>
        ) : (
          <CropText width={emailWidth} text="No email" />
        )}
      </TableRowCell>

      <TableRowCell>
        {linkedinUrl ? (
          <SocialLink href={parseLink(linkedinUrl)} target="_blank">
            <ReactSVG src={LinkedInIcon} />
          </SocialLink>
        ) : (
          <SocialLinkEmpty>-</SocialLinkEmpty>
        )}
      </TableRowCell>

      <TableRowCell>{contactAccuracyScore}</TableRowCell>

      <TableRowCell>
        {!isCompanyUser && <PitchButton contact={row} />}
        {isCompanyUser && (
          <Center>
            <EditLink to={`/profile/contacts/edit/${id}`}>Edit</EditLink>
            <DelLink onClick={showModal}>Delete</DelLink>
          </Center>
        )}
      </TableRowCell>
    </TableRow>
  );
};

const ContactsTable = ({ rows, onDeleteContact }: Props) => {
  const { data, sortStatus, handleSwitch } = useSortStatus(rows);

  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col style={{ width: `${nameWidth}px` }} />
          <col style={{ width: `${jobtitleWidth}px` }} />
          <col style={{ width: `${jobFunctionWidth}px` }} />
          <col style={{ width: `${managementLevelWidth}px` }} />
          <col style={{ width: `${phoneWidth}px` }} />
          <col style={{ width: `${emailWidth}px` }} />
          <col style={{ width: `${linkedinWidth}px` }} />
          <col style={{ width: `${scoreWidth}px` }} />
          <col style={{ width: `${actionsWidth}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            <TableSortHeaderCell
              colName="firstName"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Full Name
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="jobTitle"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Title
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="jobFunction"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Job Function
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="managementLevel"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Management Level
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="phone"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Phone
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="email"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Email
            </TableSortHeaderCell>
            <TableHeadCell>LinkedIn</TableHeadCell>
            <TableSortHeaderCell
              colName="contactAccuracyScore"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Accuracy Score
            </TableSortHeaderCell>
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {data.map(item => (
            <TableRowWrapper
              key={item.id}
              row={item}
              handleDeleteContact={onDeleteContact}
            />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow: auto;
  margin-bottom: 30px;
`;

const Table = styled.table``;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-right: 24px;
  }
`;

const TableRow = styled.tr`
  height: 100%;
`;

const TableRowCell = styled.td<{ isAddWeight?: boolean; isNoAddBorder?: boolean }>`
  box-sizing: border-box;
  padding: 20px 6px 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }

  ${({ isAddWeight }) => isAddWeight && 'font-weight: 500;'}
  ${({ isNoAddBorder }) => isNoAddBorder && 'border-bottom: none;'}
`;

export const ContactLink = styled.a`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
  &:hover {
    text-decoration: underline;
  }
`;

export const SocialLink = styled.a`
  display: block;
  width: 100%;
  & > div {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const SocialLinkEmpty = styled.p`
  margin: none;
  width: 100%;
`;

const EditLink = styled(Link)`
  color: var(--blue);
`;

const DelLink = styled.a`
  margin-left: 12px;
  color: var(--red);
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export default ContactsTable;
