import { UIButton, UICheckbox } from 'components/UI';
import { useModal } from 'hooks/common';
import { ROITemplate } from 'interfaces/roi';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  PDFTemplateModal,
  TemplateModal,
  TemplateStewardshipActivationModal,
} from '../modals';

type Row = ROITemplate[];
interface Props {
  rows: Row;
  headCells: string[];
  onFetchROITemplatePreview: (templateId: string) => void;
  onFetchROITemplateCompiled: (template: ROITemplate) => void;
  templatePreview: string;
  onFetchROITemplateSave: (template: ROITemplate) => void;
  onCancel: () => void;
  onToggleROITemplateDisable: (templateId: string) => void;
  onFetchROITemplateListSave: (templates: ROITemplate[]) => void;
  onFetchROITemplateReset: (templateId: string) => void;
  onFetchROITemplate: (templateId: string) => Promise<ROITemplate>;
}

interface TableRowProps {
  row: Row[0];
  onClickSeeTemplate: (row: ROITemplate) => void;
  onChangeCheckbox: (id: string) => void;
}

const userTableWidth = {
  column1: 350,
  column2: 140,
  column3: 150,
  column4: 100,
} as const;

const { column1, column2, column3, column4 } = userTableWidth;

const TableRowWrapper = ({
  row,
  onClickSeeTemplate,
  onChangeCheckbox,
}: TableRowProps) => {
  const { id, StewardshipName, disabled, Completion, notSendEmail } = row;

  return (
    <TableRow>
      <TableRowCell bold>{StewardshipName}</TableRowCell>

      <TableRowCell>
        <UICheckbox check={!disabled} handleClick={() => onChangeCheckbox(id)} />
      </TableRowCell>

      <TableRowCell>{Completion}</TableRowCell>

      <TableRowCell>
        {(!notSendEmail ||
          StewardshipName === 'Additional Custom Stewardship Activation') && (
          <ActionText onClick={() => onClickSeeTemplate(row)}>See Template</ActionText>
        )}
      </TableRowCell>

      <TableRowCell />
    </TableRow>
  );
};

const StewardPlanTable = ({
  rows,
  headCells,
  onFetchROITemplatePreview,
  onFetchROITemplateCompiled,
  templatePreview,
  onFetchROITemplateSave,
  onCancel,
  onToggleROITemplateDisable,
  onFetchROITemplateListSave,
  onFetchROITemplateReset,
  onFetchROITemplate,
}: Props) => {
  const [roiTemplate, setRoiTemplate] = useState<ROITemplate | undefined>(undefined);

  const [showTemplateModal, hideTemplateModal] = useModal(() => {
    if (!roiTemplate) return null;
    return (
      <TemplateModal
        onClose={hideTemplateModal}
        roiTemplate={roiTemplate}
        templatePreview={templatePreview}
        onSubmit={values => {
          onFetchROITemplateSave(values);
          hideTemplateModal();
        }}
        onReset={onFetchROITemplateReset}
        onPreview={onFetchROITemplateCompiled}
      />
    );
  }, [roiTemplate, templatePreview]);

  const [showPDFTemplateModal, hidePDFTemplateModal] = useModal(() => {
    if (!roiTemplate) return null;
    return (
      <PDFTemplateModal
        onClose={hidePDFTemplateModal}
        roiTemplate={roiTemplate}
        templatePreview={templatePreview}
        onSubmit={values => {
          onFetchROITemplateSave(values);
          hidePDFTemplateModal();
        }}
      />
    );
  }, [roiTemplate, templatePreview]);

  const [
    showTemplateStewardshipActivationModal,
    hideTemplateStewardshipActivationModal,
  ] = useModal(() => {
    if (!roiTemplate) return null;
    return (
      <TemplateStewardshipActivationModal
        onClose={hideTemplateStewardshipActivationModal}
        roiTemplate={roiTemplate}
        onSubmit={values => {
          onFetchROITemplateSave(values);
          hideTemplateStewardshipActivationModal();
        }}
      />
    );
  }, [roiTemplate, templatePreview]);

  useEffect(() => {
    if (!roiTemplate) return;

    const template = rows.find(item => item.id === roiTemplate.id);
    if (!template) return;

    setRoiTemplate(template);
  }, [rows, roiTemplate]);

  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${column1}px` }} />
          <col span={1} style={{ width: `${column2}px` }} />
          <col span={1} style={{ width: `${column3}px` }} />
          <col span={1} style={{ width: `${column4}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            {headCells.map((item, index) => (
              <TableHeadCell key={index}>{item}</TableHeadCell>
            ))}
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map((item, index) => (
            <TableRowWrapper
              key={index}
              row={item}
              onClickSeeTemplate={template => {
                onFetchROITemplate(template.id).then(template =>
                  setRoiTemplate(template),
                );
                onFetchROITemplatePreview(template.id);
                if (
                  template.StewardshipName === 'Additional Custom Stewardship Activation'
                ) {
                  showTemplateStewardshipActivationModal();
                  return;
                }
                if (template.extension) {
                  showPDFTemplateModal();
                  return;
                }
                showTemplateModal();
              }}
              onChangeCheckbox={id => onToggleROITemplateDisable(id)}
            />
          ))}
        </TableBody>
      </Table>
      <BtnGroup>
        <Button title="Cancel" modifiers="secondary" handleClick={onCancel} />
        <Button
          title="Save"
          modifiers="primary"
          handleClick={() => onFetchROITemplateListSave(rows)}
        />
      </BtnGroup>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow: auto;
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: #fff;
  background: rgba(0, 90, 158, 0.7);
  position: sticky;
  top: 0;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;

const TableRow = styled.tr`
  height: 100%;
  background-color: #f4f7f9;
`;

const TableRowCell = styled.td<{ bold?: boolean }>`
  padding: 18px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  ${({ bold }) => bold && 'font-weight: 500;'}

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }
`;

const ActionText = styled.div`
  color: #1d98c1;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  cursor: pointer;
`;

const BtnGroup = styled.div`
  display: flex;
  margin-top: 30px;

  & > button:not(:first-child) {
    margin-left: 8px;
  }
`;

const Button = styled(UIButton)`
  width: 254px;
`;

export default StewardPlanTable;
