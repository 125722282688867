import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import BasketSVG from 'assets/images/icons/basket.svg';
import { UICheckbox, UILink } from 'components/UI';
import { ReactSVG } from 'react-svg';
import { TableSortHeaderCell } from 'components/UI/table';
import { SortStatus } from 'hooks/common/useSortStatus';
import { useHistory } from 'react-router';
import { ROISurveyListItem } from 'interfaces/roi';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/ducks/user/selectors';
import { useIsCompanyUser } from 'hooks/common';
import { getUTCAsLocalDate } from 'utils';

type Row = ROISurveyListItem[];
interface Props {
  rows: Row;
  onDelete: (id: string) => void;
  selectedAll: boolean;
  selectedIds: string[];
  onCheckRow: (id: string) => void;
  onCheckAll: (bool: boolean) => void;
  sortStatus: SortStatus;
  onSort: (colName: string) => void;
}

interface TableRowProps {
  row: Row[0];
  check: boolean;
  onCheckRow: Props['onCheckRow'];
  onDelete: Props['onDelete'];
}

const userTableWidth = {
  titleWidth: 230,
  descriptionWidth: 240,
  nameWidth: 240,
  dateWidth: 140,
  actionWidth: 120,
} as const;

const {
  titleWidth,
  descriptionWidth,
  nameWidth,
  dateWidth,
  actionWidth,
} = userTableWidth;

const TableRowWrapper = ({ row, check, onDelete, onCheckRow }: TableRowProps) => {
  const { push } = useHistory();

  return (
    <TableRow>
      <TableRowCell style={{ width: `${70}px` }}>
        <UICheckbox check={check} handleClick={() => onCheckRow(row.id)} />
      </TableRowCell>
      <TableRowCell>
        <ActionText
          onClick={() => push(`/roi/survey-response/${row.id}/${row.roiId}/${row.type}`)}
        >
          {row.title}
        </ActionText>
      </TableRowCell>
      <TableRowCell>{row.roi.name}</TableRowCell>
      <TableRowCell>{row.roi.companyName}</TableRowCell>
      <TableRowCell>
        {format(getUTCAsLocalDate(row.endDate), 'MMM dd, yyyy')}
      </TableRowCell>
      <TableRowCell>
        <ActionText onClick={() => onDelete(row.id)}>
          <ActionIcon src={BasketSVG} />
          Delete
        </ActionText>
      </TableRowCell>
    </TableRow>
  );
};

const SurveyListTable = ({
  rows,
  selectedAll,
  selectedIds,
  sortStatus,
  onCheckAll,
  onCheckRow,
  onSort,
  onDelete,
}: Props) => {
  const user = useSelector(selectUser);
  const isCompany = useIsCompanyUser(user);

  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col style={{ width: `${70}px` }} />
          <col span={1} style={{ width: `${titleWidth}px` }} />
          <col span={1} style={{ width: `${descriptionWidth}px` }} />
          <col span={1} style={{ width: `${nameWidth}px` }} />
          <col span={1} style={{ width: `${dateWidth}px` }} />
          <col span={1} style={{ width: `${actionWidth}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell key="checkbox" style={{ width: `${70}px` }}>
              <UICheckbox
                check={selectedAll}
                handleClick={() => onCheckAll(!selectedAll)}
              />
            </TableHeadCell>
            <TableSortHeaderCell colName="title" onClick={onSort} sortStatus={sortStatus}>
              Survey Title
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="parterDescription"
              onClick={onSort}
              sortStatus={sortStatus}
            >
              Partner Description
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="companyName"
              onClick={onSort}
              sortStatus={sortStatus}
            >
              {isCompany ? 'Organization' : 'Company'} Name
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="endDate"
              onClick={onSort}
              sortStatus={sortStatus}
            >
              Survey End Date
            </TableSortHeaderCell>
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map((item, index) => (
            <TableRowWrapper
              key={index}
              row={item}
              check={selectedIds.includes(item.id)}
              onDelete={onDelete}
              onCheckRow={onCheckRow}
            />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow: auto;
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 36px;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 12px;
  line-height: 150%;
  position: sticky;
  top: 0;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;

const TableRow = styled.tr`
  height: 100%;
  background-color: #fff;
`;

const TableRowCell = styled.td`
  padding: 14px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }
`;

const ActionIcon = styled(ReactSVG)`
  svg {
    width: 14px;
    height: 14px;
  }
  margin-right: 12px;
`;

const ActionText = styled(UILink)`
  display: flex;
  align-items: center;
  font-weight: 400;
`;

export default SurveyListTable;
