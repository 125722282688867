import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useModal, useOperation } from 'hooks/common';
import {
  fetchCompany,
  fetchCompanyLike,
  fetchCompanyDisLike,
  fetchCompanyNews,
  fetchCompanyScoops,
  fetchCompanyMatchDetail,
} from 'store/ducks/companies/operations';
import {
  getCompanyById,
  getCompanyScoops,
  getCompanyNews,
  getCompanyMatchDetail,
} from 'store/ducks/companies/selectors';

import Jumbotron from 'components/Jumbotron';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import { StateProps } from 'store/interfaces';
import { SearchCompany, Scoops, News, ResCompanyMatch } from 'interfaces/companies';
import { getTeam } from 'store/ducks/team/selectors';
import { fetchVerifyEmailStatus } from 'store/ducks/user/operations';
import {
  fetchGameplanCustomizeTemplate,
  fetchGameplanCustomizeTemplatePreview,
  fetchGameplanCustomizeTemplateReset,
  fetchGameplanCustomizeTemplateSave,
} from 'store/ducks/gameplan/operations';
import {
  getGameplanCustomizeTemplate,
  getGameplanCustomizeTemplatePreview,
} from 'store/ducks/gameplan/selectors';
import CustomizeTemplateModal from 'components/gameplan/CustomizeTemplateModal';
import CompanyView from 'components/CompanyView';

const Company = () => {
  const [onFetchCompany, isLoading] = useOperation(fetchCompany);
  const [onfetchCompanyScoops, isScoopsLoading] = useOperation(fetchCompanyScoops);
  const [onfetchCompanyNews, isNewsLoading] = useOperation(fetchCompanyNews);
  const [onfetchCompanyLike] = useOperation(fetchCompanyLike);
  const [onfetchCompanyDisLike] = useOperation(fetchCompanyDisLike);
  const [onFetchCompanyMatchDetail] = useOperation(fetchCompanyMatchDetail);
  const [onFetchVerifyEmailStatus] = useOperation(fetchVerifyEmailStatus);
  const [onFetchGameplanCustomizeTemplate, , isCustomizeLoading] = useOperation(
    fetchGameplanCustomizeTemplate,
  );
  const [onFetchGameplanCustomizeTemplateSave] = useOperation(
    fetchGameplanCustomizeTemplateSave,
  );
  const [onFetchGameplanCustomizeTemplateReset] = useOperation(
    fetchGameplanCustomizeTemplateReset,
  );
  const [onFetchGameplanCustomizeTemplatePreview] = useOperation(
    fetchGameplanCustomizeTemplatePreview,
  );

  const params: { companyId: string } = useParams();
  const company = useSelector<StateProps, SearchCompany>(
    getCompanyById(params.companyId),
  );
  const scoops = useSelector<StateProps, Scoops[]>(getCompanyScoops);
  const news = useSelector<StateProps, News[]>(getCompanyNews);
  const { matchItems } = useSelector<StateProps, ResCompanyMatch>(getCompanyMatchDetail);
  const team = useSelector(getTeam);
  const gameplanCustomizeTemplate = useSelector(getGameplanCustomizeTemplate);
  const gameplanCustomizeTemplatePreview = useSelector(
    getGameplanCustomizeTemplatePreview,
  );

  const [showCustomizeTemplateModal, hideCustomizeTemplateModal] = useModal(() => {
    return (
      <CustomizeTemplateModal
        noSubject
        template={gameplanCustomizeTemplate}
        templatePreview={gameplanCustomizeTemplatePreview}
        isloading={isCustomizeLoading}
        onClose={hideCustomizeTemplateModal}
        onReset={templateType => onFetchGameplanCustomizeTemplateReset(templateType)}
        onSave={values => onFetchGameplanCustomizeTemplateSave(values)}
        onPreview={templateType => onFetchGameplanCustomizeTemplatePreview(templateType)}
      />
    );
  }, [gameplanCustomizeTemplate, isCustomizeLoading, gameplanCustomizeTemplatePreview]);

  useEffect(() => {
    const id = params.companyId;
    onFetchCompany(id);
    onfetchCompanyScoops(id);
    onfetchCompanyNews(id);
    onFetchCompanyMatchDetail(id);
    onFetchVerifyEmailStatus(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <ContentBlockWrapper
      loading={isLoading || isScoopsLoading || isNewsLoading}
      header={<Jumbotron name="Corporate Profile" back />}
    >
      <CompanyView
        company={company}
        handleLike={onfetchCompanyLike}
        handleDisLike={onfetchCompanyDisLike}
        scoops={scoops}
        news={news}
        matchs={matchItems}
        organizationName={team?.organizationName}
        onFetchGameplanCustomizeTemplate={onFetchGameplanCustomizeTemplate}
        showCustomizeTemplateModal={showCustomizeTemplateModal}
        onFetchCompany={onFetchCompany}
        onfetchCompanyNews={onfetchCompanyNews}
      />
    </ContentBlockWrapper>
  );
};

export default Company;
