import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import default1 from 'assets/images/Logo.png';
import default2 from 'assets/images/preview/default2.png';
import paypal2 from 'assets/images/preview/paypal2.svg';
import backSvg from 'assets/images/preview/back.svg';
import walletSvg from 'assets/images/preview/wallet.svg';
import { useIsCompanyUser, useOperation } from 'hooks/common';
import { fetchCamPartnersList, fetchPreviewInfo } from 'store/ducks/campaign/operations';
import { useSelector } from 'react-redux';
import { getQueryString } from 'utils';
import { selectUser } from 'store/ducks/user/selectors';
import { SingleSelect, UISpinner } from 'components/UI';
import { UIActionButton } from 'components/UI';
import { UITextFieldProps } from 'components/UI/interfaces';
import { getTeam } from 'store/ducks/team/selectors';

export type UITextInputProps = UITextFieldProps<HTMLInputElement>;

const NewPreviewContent = () => {
  const team = useSelector(getTeam);
  const [onFetchCampaign] = useOperation(fetchPreviewInfo);
  const [getCamPartnersList] = useOperation(fetchCamPartnersList);
  const campaignId = getQueryString('campaignId');

  const user = useSelector(selectUser);
  const isCompanyUser = useIsCompanyUser(user);

  const [details, setDetails] = useState<any>();
  const [partnerList, setPartnerList] = useState<string[]>([]);
  const [isloading, setLoading] = useState(false);
  const [selectValue, setSelectValue] = useState(undefined);

  const get = partnerId => {
    setLoading(true);
    onFetchCampaign({
      id: campaignId,
      partnerId,
    }).then((e: any) => {
      setDetails(e?.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!campaignId) return;
    get('');
    const page = 1;
    getCamPartnersList({ page, campaignId }).then((ele: any) => {
      const data: string[] = [];
      ele.items.forEach((i: any) => {
        if (i.selected === 1) data.push(i);
      });
      setPartnerList(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, onFetchCampaign]);

  if (isloading) return <Spinner />;

  function rgb(
    arg0: number,
    arg1: number,
    arg2: number,
  ): import('csstype').Property.BackgroundColor | undefined {
    throw new Error('Function not implemented.');
  }

  return (
    <>
      <div
        style={{
          padding: 32,
          background: '#F9F9F9',
          marginTop: 0,
          marginLeft: '-30px',
        }}
      >
        <div>
          <div style={{ color: '#737373', fontSize: 14, marginBottom: 4 }}>
            Select a Partner
          </div>
          <SelectGroup>
            <SingleSelect
              placeholder="Please Select"
              handleSelect={(v: any) => {
                if (!v?.value) return;
                setSelectValue(v.value);
                get(v.value);
              }}
              value={selectValue}
              options={partnerList?.map((item: any) => ({
                label: item.companyName,
                value: item.id,
                id: isCompanyUser ? item.teamId : item.companyId,
              }))}
              isClearable={false}
            />
          </SelectGroup>
        </div>
        <Root style={{ width: '1400px' }}>
          <ItemCon
            style={{
              height: '635px',
            }}
          >
            <TitleText>Donation Request</TitleText>
            <Content
              style={{
                backgroundColor: details?.selectCampaignColors?.[0],
                fontFamily: details?.selectCampaignFont,
                transform: 'scale(0.8)',
                marginLeft: '-80px',
                marginTop: '-50px',
                width: '820px',
                height: '630px',
              }}
            >
              <HederBorder>
                <LogoImg
                  style={{ marginLeft: '50px' }}
                  src={details?.teamLogo || (isCompanyUser ? default1 : default2)}
                />
                <LogoImg
                  style={{ marginLeft: '363px' }}
                  src={details?.companyLogo || (isCompanyUser ? default2 : default1)}
                />
              </HederBorder>
              {/* header 2 */}
              <HeaderLogo fontLength={details?.donationRequestMessage}>
                <LogoLeft
                  style={{
                    color: details?.selectCampaignColors?.[1],
                    textAlign: 'left',
                  }}
                >
                  {details?.donationRequestMessage}
                </LogoLeft>
                <LogoRight>
                  <img
                    alt=""
                    src={details?.newCustomContentImageName}
                    style={{ height: 200, OObjectFit: 'none', width: 'auto' }}
                  />
                </LogoRight>
              </HeaderLogo>
              {/* content */}
              <ContentDiv>
                <SelectAmount
                  style={{
                    color: details?.selectCampaignColors?.[1],
                    fontWeight: 600,
                    fontSize: '16pt',
                  }}
                >
                  Select Amount
                </SelectAmount>
                <div
                  style={{
                    width: '100%',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <HeaderData1
                    style={{
                      color: details?.selectCampaignColors?.[1],
                      marginLeft: '40px',
                      marginTop: '10px',
                      filter: 'brightness(70%)',
                      fontWeight: 600,
                      fontSize: '16pt',
                    }}
                  >
                    <RadioInput></RadioInput>&nbsp;{' '}
                    <span
                      style={
                        details?.selectCampaignFont === 'Oswald' ||
                        details?.selectCampaignFont === 'DIN OT'
                          ? {
                              marginTop: '-4px',
                            }
                          : {}
                      }
                    >
                      ${details?.setDonation1}
                    </span>
                  </HeaderData1>
                  <HeaderData1
                    style={{
                      color: details?.selectCampaignColors?.[1],
                      marginLeft: '40px',
                      marginTop: '10px',
                      filter: 'brightness(70%)',
                      fontWeight: 600,
                      fontSize: '16pt',
                    }}
                  >
                    <RadioInput></RadioInput>&nbsp;{' '}
                    <span
                      style={
                        details?.selectCampaignFont === 'Oswald' ||
                        details?.selectCampaignFont === 'DIN OT'
                          ? {
                              marginTop: '-4px',
                            }
                          : {}
                      }
                    >
                      ${details?.setDonation2}
                    </span>
                  </HeaderData1>
                  <HeaderData1
                    style={
                      details?.selectCampaignFont === 'Oswald'
                        ? {
                            marginTop: '5px',
                            marginLeft: '122px',
                            color: details?.selectCampaignColors?.[1],
                            filter: 'brightness(70%)',
                            fontWeight: 600,
                            fontSize: '16pt',
                          }
                        : {
                            marginTop: '5px',
                            marginLeft: '127px',
                            color: details?.selectCampaignColors?.[1],
                            filter: 'brightness(70%)',
                            fontWeight: 600,
                            fontSize: '16pt',
                          }
                    }
                  >
                    <RadioInput style={{ marginTop: '6px' }}></RadioInput>
                    <span
                      style={
                        details?.selectCampaignFont === 'Oswald' ||
                        details?.selectCampaignFont === 'DIN OT'
                          ? { marginTop: '2px' }
                          : { marginTop: '6px' }
                      }
                    >
                      &nbsp;$
                    </span>
                    &nbsp; {/* <img alt="" src={selectAmout} style={{ width: 145 }} /> */}
                    {/* <input
                      type="number"
                      min="0"
                      max="100"
                      step="1"
                      placeholder="Enter Custom Value"
                      style={{
                        width: 140,
                        height: '28px',
                        marginTop: '4px',
                        backgroundColor: details?.selectCampaignColors?.[0],
                        border: '1px solid #55565B',
                      }}
                    /> */}
                    <Input
                      type="number"
                      placeholder="Enter Custom Value"
                      min="0"
                      max="100"
                      $color={details?.selectCampaignColors?.[0]}
                    />
                  </HeaderData1>
                </div>
              </ContentDiv>

              <ContentChex1
                style={{
                  color: details?.selectCampaignColors?.[1],
                  marginTop: '20px',
                  fontSize: '12pt',
                  filter: 'brightness(70%)',
                }}
              >
                <CheckBoxDiv style={{ padding: 2, border: '1px solid #696C6F' }} />
                &nbsp;{' '}
                <span style={{ color: details?.selectCampaignColors?.[1] }}>
                  {details?.shareDonorInformation ||
                    "I'd like to receive more information about this organization"}
                </span>
              </ContentChex1>

              {/* buuton */}
              <ButtonDIV
                style={{
                  padding: '6px 10px',
                  backgroundColor: '#173E76',
                  color: 'white',
                  width: '49%',
                  fontSize: '14px',
                  filter: 'brightness(80%)',
                  textAlign: 'center',
                  lineHeight: 2,
                }}
              >
                Continue
              </ButtonDIV>
              <FooterText
                style={{
                  color: details?.selectCampaignColors?.[1],
                  fontSize: '10pt',
                  filter: 'brightness(60%)',
                  textAlign: 'left',
                  marginBottom: '50px',
                  marginTop: '5px',
                  marginLeft: '209px',
                  width: '400px',
                }}
              >
                This transaction is occurring outside of the Battle.net environment. If
                you have any concerns, please reach out to{' '}
                <span style={{ color: '#418DF7', textDecoration: 'underline' }}>
                  battlenet@accelerist.com
                </span>{' '}
                or visit{' '}
                <span style={{ color: '#418DF7', textDecoration: 'underline' }}>
                  www.Accelerist.com
                </span>
                .
              </FooterText>
            </Content>
          </ItemCon>
          <ItemCon
            style={{
              height: '635px',
            }}
          >
            <TitleText>Donation Confirmation</TitleText>
            <Content
              style={{
                backgroundColor: details?.selectCampaignColors?.[0],
                height: '100px',
                fontFamily: details?.selectCampaignFont,
                transform: 'scale(0.8)',
                marginLeft: '-80px',
                marginTop: '-50px',
                width: '820px',
              }}
            >
              <HederBorder
                style={{
                  backgroundColor: details?.selectCampaignColors?.[0],
                  height: '10px',
                }}
              />
              <div
                style={{
                  width: '100%',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <HeaderData>
                  <span
                    style={{
                      fontWeight: 600,
                      color: details?.selectCampaignColors?.[1],
                      margin: '24px',
                      fontSize: '20pt',
                    }}
                  >
                    {details?.donationConfirmation}
                  </span>
                </HeaderData>

                <HeaderData>
                  <span
                    style={{
                      color: details?.selectCampaignColors?.[1],
                      fontSize: '12px',
                      filter: 'brightness(90%)',
                      fontWeight: 600,
                    }}
                  >
                    {'YOU ARE DONATING'}
                  </span>
                </HeaderData>

                <HeaderData1
                  style={{
                    color: details?.selectCampaignColors?.[1],
                    marginLeft: '15px',
                    marginTop: '8px',
                  }}
                >
                  <Back
                    onClick={() => {}}
                    style={{ marginLeft: '-130px', fontSize: '12px' }}
                  >
                    <UIActionButton handleClick={() => {}} icon={backSvg} />
                    {'Back'}
                  </Back>
                  <span style={{ marginLeft: '48px', fontSize: '16pt', fontWeight: 600 }}>
                    ${details?.setDonation1}
                  </span>
                </HeaderData1>
                {details?.donorCoveredFees === 'true' && (
                  <ContentChex1
                    style={{
                      color: details?.selectCampaignColors?.[1],
                      marginTop: '30px',
                      fontSize: '12pt',
                      filter: 'brightness(70%)',
                    }}
                  >
                    <CheckBoxDiv
                      style={{
                        padding: 2,
                        border: '1px solid #696C6F',
                      }}
                    />
                    &nbsp;I’d like to help cover the transaction fees
                  </ContentChex1>
                )}

                <PaypalButton
                  style={{
                    background: '#3172D8',
                    lineHeight: 10,
                    color: '#FFF',
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '20px',
                    fontSize: '14px',
                  }}
                >
                  PayPal
                </PaypalButton>
                <PaypalButton
                  style={{
                    background: '#313339',
                    lineHeight: 10,
                    color: '#FFF',
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '10px',
                    fontSize: '14px',
                  }}
                >
                  <img alt="" style={{ width: 20, height: 15 }} src={walletSvg} /> &nbsp;
                  <span>Debit or credit card</span>
                </PaypalButton>
                <PaypalFooter
                  style={{
                    color: details?.selectCampaignColors?.[1],
                    filter: 'brightness(60%)',
                  }}
                >
                  Powered by &nbsp;
                  <img alt="" src={paypal2} style={{ width: 52 }} />
                </PaypalFooter>
                <FooterText
                  style={{
                    color: details?.selectCampaignColors?.[1],
                    filter: 'brightness(60%)',
                    fontSize: '10pt',
                    marginTop: '15px',
                    marginBottom: '20px',
                    width: '50%',
                  }}
                >
                  {/* Your donation is to PayPal Giving Fund (PPGF), an IRS-registered
                  501(c)(3) charity, subject to its{' '}
                  <span style={{ color: '#418DF7', textDecoration: 'underline' }}>
                    terms
                  </span>
                  . PPGF receives the donation, which is typically tax deductible, minus
                  payment processing fees of 4.9% .PPGF typically grants funds to
                  recommended charities within{' '}
                  <span style={{ color: '#418DF7', textDecoration: 'underline' }}>
                    15-45 days
                  </span>
                  . In the rare event PPGF{' '}
                  <span style={{ color: '#418DF7', textDecoration: 'underline' }}>
                    can’t fund your chosen charity
                  </span>
                  , it will seek to consult you before reassigning the funds to a similar
                  charity. */}
                  Your donation is to PayPal Giving Fund (PPGF), an IRS-registered
                  501(c)(3) charity, subject to its{' '}
                  <span style={{ color: '#418DF7', textDecoration: 'underline' }}>
                    terms
                  </span>
                  . PPGF receives the donation, which is typically tax deductible, minus
                  payment processing fees of 1.99 % + $.49, minus a 3% Accelerist platform
                  fee . PPGF typically grants funds to recommended charities within{' '}
                  <span style={{ color: '#418DF7', textDecoration: 'underline' }}>
                    15-45 days
                  </span>
                  . In the rare event PPGF{' '}
                  <span style={{ color: '#418DF7', textDecoration: 'underline' }}>
                    can’t fund your chosen charity
                  </span>
                  , it will seek to consult you before reassigning the funds to a similar
                  charity.
                </FooterText>
              </div>
            </Content>
          </ItemCon>
          <ItemCon style={{ height: '565px' }}>
            <TitleText>Donation Receipt and Recognition</TitleText>
            <Content
              style={{
                backgroundColor: details?.selectCampaignColors?.[0],
                fontFamily: details?.selectCampaignFont,
                transform: 'scale(0.8)',
                marginLeft: '-80px',
                marginTop: '-50px',
                width: '820px',
              }}
            >
              <HederBorder>
                <LogoImg
                  style={{ marginLeft: '50px' }}
                  src={details?.teamLogo || (isCompanyUser ? default1 : default2)}
                />
                <LogoImg
                  style={{ marginLeft: '363px' }}
                  src={details?.companyLogo || (isCompanyUser ? default2 : default1)}
                />
              </HederBorder>

              <HeaderLogo fontLength={details?.receiptMessage}>
                <LogoLeft
                  style={{
                    color: details?.selectCampaignColors?.[1],
                  }}
                >
                  {details?.receiptMessage ||
                    'Together we have made an impact in the community.'}
                </LogoLeft>
                <LogoRight>
                  <img
                    alt=""
                    src={
                      details?.newDonationReceiptImage
                        ? details?.newDonationReceiptImage
                        : details?.newCustomContentImageName
                    }
                    style={{ height: 200, OObjectFit: 'none', width: 'auto' }}
                  />
                </LogoRight>
              </HeaderLogo>

              <div
                style={{
                  width: '100%',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <HeaderData>
                  <div
                    style={{
                      color: details?.selectCampaignColors?.[1],
                      fontSize: '12px',
                      filter: 'brightness(90%)',
                      marginTop: '20px',
                      fontWeight: 600,
                    }}
                  >
                    {/* {details?.donationConfirmation} */}
                    {'YOU DONATED'}
                  </div>
                </HeaderData>
                <span
                  style={{
                    // width: '100%',
                    fontWeight: 600,
                    // textAlign: 'center',
                    color: details?.selectCampaignColors?.[1],
                    marginTop: '5px',
                    fontSize: '16pt',
                  }}
                >
                  ${details?.setDonation1}
                </span>
                <ContentChex1
                  style={{
                    color: details?.selectCampaignColors?.[1],
                    marginTop: '10px',
                    filter: 'brightness(70%)',
                    textAlign: 'center',
                  }}
                >
                  <span
                    style={{
                      width: '400px',
                      fontSize: '12pt',
                      textAlign: 'left',
                      marginLeft: '10px',
                    }}
                  >
                    {details?.customMessage ||
                      'The Call of Duty Endowment is Building A Community Committed ToGetting Vets Back To Work.'}
                    <span style={{ color: '#346EC0' }}> Learn More</span>
                  </span>
                </ContentChex1>

                <PaypalButton
                  style={{
                    background: '#3172D8',
                    lineHeight: 10,
                    color: '#FFF',
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '15px',
                    fontSize: '14px',
                    width: '49%',
                    // marginBottom: '50px',
                  }}
                >
                  Close
                </PaypalButton>
                {/* <span
                  style={{
                    color: details?.selectCampaignColors?.[1],
                    fontSize: '10px',
                    filter: 'brightness(60%)',
                    marginLeft: '-106px',
                    marginBottom: '50px',
                    marginTop: '5px',
                  }}
                >
                  {details?.couponConfirmationMessage ||
                    'Check your email for your donation receipt.'}
                </span> */}
                <FooterText
                  style={{
                    color: details?.selectCampaignColors?.[1],
                    fontSize: '10pt',
                    filter: 'brightness(60%)',
                    textAlign: 'center',
                    marginBottom: '50px',
                    marginTop: '5px',
                  }}
                >
                  {details?.receiptEmailMessage ||
                    'Check your email for your donation receipt.'}
                  <br />
                  {details?.couponConfirmationMessage}
                </FooterText>
              </div>
            </Content>
          </ItemCon>
        </Root>
      </div>
    </>
  );
};
const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 201px);
`;
const Root = styled.div`
  margin-top: 28px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 24px;
`;

const SelectGroup = styled.div`
  display: flex;

  & > div {
    width: 590px;
  }

  & > div:not(:first-child) {
    margin-left: 18px;
  }
`;

const TitleText = styled.div`
  color: #000;
  font-weight: 600;
  font-size: 17px;
  padding-bottom: 12px;
`;
const Content = styled.div`
  flex: 1;
`;
const ItemCon = styled.div`
  // border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

const HederBorder = styled.div`
  height: 110px;
  display: flex;
  align-items: center;
  margintop: 20px;
`;

const HederBorder2 = styled.div`
  margin-top: 35px;
  font-weight: 600;
`;
const HeaderLogo = styled.div<{ fontLength?: any }>`
  display: flex;
  // align-items: center;
  justify-content: right;
  height: 200px;
  ${({ fontLength }) => {
    const strLength = fontLength?.length;
    if (strLength) {
      if (strLength <= 180) {
        return 'font-size: 20pt';
      } else if (strLength <= 248) {
        return 'font-size: 18px;';
      } else if (strLength <= 300) {
        return 'font-size: 16px;';
      } else {
        return 'font-size: 16px;';
      }
    }
  }}
`;

const LogoLeft = styled.div`
  width: 100%;
  // background-color: rgb(35, 37, 43);
  // background-color: rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.065);
  // background-color: rgba(255, 255, 255, 0.4);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  text-align: left;
  color: white;
  filter: brightness(90%);
  padding-left: 35px;
  padding-right: 4px;
`;
const LogoRight = styled.div`
  // width: 43%;
`;
const ContentDiv = styled.div``;
const SelectAmount = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const ContentChex1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const CheckBoxDiv = styled.div`
  padding: 2px;
  border: 1px solid rgb(108 101 101);
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  margin-right: 2px;
`;
const ButtonDIV = styled.div`
  padding: 6px 10px;
  background-color: rgb(214, 95, 0);
  width: 40%;
  margin: auto;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  margin-top: 12px;
`;

const HeaderData = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderData1 = styled.div`
  display: flex;
  align-items: left;
`;

const PaypalButton = styled.div`
  height: 38px;
  width: 49%;
  background: rgb(0, 106, 177);
  border-radius: 5px;
  text-align: center;
  margin-bottom: 12px;
  margin-top: 24px;
`;
const PaypalFooter = styled.div`
  font-style: italic;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6px;
  font-size: 12px;
`;
const FooterText = styled.div`
  font-size: 14px;
  width: 49%;
  line-height: 1.5;
`;

const LogoImg = styled.img`
  width: 180px;
  height: 80px;
  object-fit: contain;
`;

const RadioInput = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid #858589;
  border-radius: 10px;
  margin-left: -92px;
`;

const Back = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Input = styled.input<{
  $color: string;
}>`
  width: 145px;
  height: 29px;
  border: 1px solid #55565b;
  border-radius: 3px;
  background-color: ${({ $color }) => $color};

  &::placeholder {
    font-size: 13px;
  }
`;
export default NewPreviewContent;
