import { SearchProps } from 'store/interfaces';
import { createReducer } from 'typesafe-actions';

import { ActionType } from 'typesafe-actions';

import { searchInput } from './actions';

export type RootAction = ActionType<typeof import('./actions')>;

const search = createReducer<SearchProps, RootAction>({
  input: '',
}).handleAction(searchInput, (state, action) => {
  return {
    ...state,
    input: action.payload,
  };
});

export default search;
