import React, { FocusEvent } from 'react';
import styled, { CSSProp } from 'styled-components';
import { UITextFieldProps } from './interfaces';

export type UITextInputProps = UITextFieldProps<HTMLInputElement>;

const UITextInput = (props: UITextInputProps, ref) => {
  const {
    onBlur,
    onFocus,
    onChange,
    onKeyUp,
    onKeyDown,
    value,
    placeholder,
    required,
    type = 'text',
    min,
    autofocus = false,
    disabled = false,
    inputCss,
    className,
    error,
    placeholderSize,
  } = props;

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    onBlur?.(e);
    const str = e.target.value;
    if (str.startsWith(' ') || str.endsWith(' ')) {
      e.target.value = e.target.value.trim();
      onChange?.(e);
    }
  };

  return (
    <Input
      {...props}
      type={type}
      ref={ref}
      placeholder={placeholder}
      required={required}
      value={value}
      onChange={onChange}
      onBlur={handleBlur}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      min={min}
      css={inputCss}
      className={className}
      autoFocus={autofocus}
      disabled={disabled}
      error={error}
      placeholderSize={placeholderSize}
    />
  );
};

const Input = styled.input<{
  css?: CSSProp;
  disabled?: boolean;
  error?: boolean;
  placeholderSize?: UITextInputProps['placeholderSize'];
}>`
  width: 100%;
  color: var(--black);
  font-size: 16px;
  line-height: 155%;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px 16px;
  outline: none;

  &:focus {
    border: 1px solid #2baee0;
  }

  &::placeholder {
    color: var(--darkGray);
    ${({ placeholderSize }) => placeholderSize && `font-size: ${placeholderSize}px`};
  }

  ${({ disabled }) =>
    disabled &&
    `
      background: #F9F9F9;
      color: var(--gray);
      border: 1px solid transparent;
      cursor: not-allowed;
      &:focus{
        border: 1px solid transparent;
      }
    `}

  ${({ error }) =>
    error &&
    `
      background: #FFF2F2;
      border: 1px solid var(--red);
      &:focus{
        border: 1px solid var(--red);
      }
    `}

  ${({ css }) => css}
`;

export default React.forwardRef(UITextInput);
