import { UIDivider } from 'components/UI';
import React from 'react';
import styled from 'styled-components';
import { moneyNumberFormat } from 'utils/formatters';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';

interface Props {
  cash: number;
  nonCash: number;
  themeColor?: string;
}

const FundraisingMetric = ({ cash, nonCash, themeColor }: Props) => {
  return (
    <Root $themeColor={themeColor}>
      <MetricTitle>Fundraising</MetricTitle>
      <Content>
        <Value>
          <div>{moneyNumberFormat(cash)}</div>
          <div>Cash</div>
        </Value>
        <Divider type="vertical" />
        <Value>
          <div>{moneyNumberFormat(nonCash)}</div>
          <div>Non-Cash</div>
        </Value>
      </Content>
    </Root>
  );
};

const Root = styled(MetricRoot)`
  /* background-color: #1992b9; */
`;

const Content = styled(MetricContent)`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Divider = styled(UIDivider)`
  height: 130px;
  margin: 0;
  flex-shrink: 0;
`;

const Value = styled.div`
  font-size: 14px;
  width: 50%;

  & > div {
    text-align: center;
    font-weight: 500;
    margin-bottom: 8px;
  }
`;

export default FundraisingMetric;
