import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { UIButton } from 'components/UI';
import EmptyIcon from 'assets/images/icons/big-heart.svg';

export interface EmptyFavoritesProps {
  className?: string;
}

const EmptyFavorites = ({ className }: EmptyFavoritesProps) => {
  const { push } = useHistory();

  return (
    <EmptyWrapper className={className}>
      <Icon src={EmptyIcon} />

      <Title>No favorite company</Title>
      <SubTitle>Go to the search page and add to favorites</SubTitle>

      <SearchBtn
        asBtn={'a'}
        modifiers="third"
        handleClick={() => push(`search`)}
        title="Go to Prospecting"
      />
    </EmptyWrapper>
  );
};

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 6px;
`;

const Icon = styled(ReactSVG)`
  margin-bottom: 40px;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 8px;
`;

const SubTitle = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--gray);
  margin-bottom: 32px;
`;

const SearchBtn = styled(UIButton)`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 244px;
`;

export default EmptyFavorites;
