import { ConfirmModal } from 'components/Modals';
import Pagination from 'components/Pagination';
import { UICheckbox, UISpinner } from 'components/UI';
import { SdgGoals } from 'constants/sdgGoals';
import { useModal, useOperation, useTableSelect, useSortStatus } from 'hooks/common';
import { Charitable } from 'interfaces/issueMapping';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {
  fetchCharitables,
  fetchGivingCreate,
  fetchGivingDelete,
  fetchRecommendation,
} from 'store/ducks/issueMapping/operations';
import { getCharitables, getRecommendation } from 'store/ducks/issueMapping/selectors';
import styled from 'styled-components';
import { moneyNumberFormat } from 'utils/formatters';
import CharitablesTable from './CharitablesTable';
import MaterialIssuesTable from './MaterialIssuesTable';
import queryString from 'query-string';
import { useHistory } from 'react-router';

const RecommendationsContent = () => {
  const { push } = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const [createCharitables, setCreateCharitables] = useState<Charitable[]>([]);
  const { sortStatus, handleSwitch } = useSortStatus([], {
    isSort: false,
  });

  const [onFetchRecommendation, loading] = useOperation(fetchRecommendation);
  const [onFetchCharitables] = useOperation(fetchCharitables);
  const [onFetchGivingCreate] = useOperation(fetchGivingCreate);
  const [onFetchGivingDelete] = useOperation(fetchGivingDelete);

  const recommendation = useSelector(getRecommendation);
  const { items: charitables, meta } = useSelector(getCharitables);
  const checked = useMemo(
    () => charitables.filter(item => item.checked).map(item => item.id),
    [charitables],
  );

  const { isSelectedAll, selectedIds } = useTableSelect({
    data: charitables,
    defaultSelected: checked,
  });

  const [showModal, hideModal] = useModal(() => {
    const handleClose = () => {
      setCreateCharitables([]);
      hideModal();
    };
    return (
      <ConfirmModal
        accessHandler={handleClose}
        cancelHandler={() => {
          onFetchGivingCreate(createCharitables).then(() => {
            onFetchCharitables({
              order: sortStatus.name,
              orderType: sortStatus.status,
              page: 1,
            });
          });
          handleClose();
        }}
        name="Giving"
        description="Do you want to accept the recommendations?"
        onClose={handleClose}
        textAccessBtn="No"
        textCancelBtn="Yes"
      />
    );
  }, [createCharitables, checked]);

  const handleSelect = (id: string) => {
    const row = charitables.find(item => item.id === id);
    if (!row) return;

    const isCheck = selectedIds.includes(id);
    if (isCheck) {
      onFetchGivingDelete([
        { recommendationId: row.recommendationId, teamId: row.teamId },
      ]).then(() => {
        onFetchCharitables({
          order: sortStatus.name,
          orderType: sortStatus.status,
          page: 1,
        });
      });
      return;
    }

    setCreateCharitables([row]);
    showModal();
  };

  const handleSelectAll = () => {
    if (isSelectedAll) {
      onFetchGivingDelete(
        charitables.map(item => ({
          recommendationId: item.recommendationId,
          teamId: item.teamId,
        })),
      ).then(() => {
        onFetchCharitables({
          order: sortStatus.name,
          orderType: sortStatus.status,
          page: 1,
        });
      });
      return;
    }

    setCreateCharitables(charitables);
    showModal();
  };

  const handleSelectTab = (tab: number) => {
    setTabIndex(tab);

    const newSearchValue = {
      ...queryString.parse(window.location.search),
      tabIndex: tab,
    };
    push(`${window.location.pathname}?${queryString.stringify(newSearchValue)}`);
  };

  useEffect(() => {
    if (!sortStatus?.name) return;
    onFetchCharitables({ order: sortStatus.name, orderType: sortStatus.status, page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  useEffect(() => {
    onFetchRecommendation(undefined);
    onFetchCharitables({ order: sortStatus.name, orderType: sortStatus.status, page: 1 });
    const searchTabIndex = queryString.parse(window.location.search)?.tabIndex;
    if (searchTabIndex) setTabIndex(Number(searchTabIndex));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Spinner />;

  if (!recommendation)
    return <EmptyText>There are currently no Recommendations created</EmptyText>;

  return (
    <>
      <Section>
        <Tabs selectedIndex={tabIndex} onSelect={handleSelectTab}>
          <TabList>
            <Tab>Material Issues</Tab>
            <Tab>Charitable Organizations</Tab>
          </TabList>
          <TabPanel>
            <MaterialIssuesTable rows={(recommendation?.sdgGoals as SdgGoals[]) || []} />
          </TabPanel>
          <TabPanel>
            <CharitablesTitle>
              Total investment:{' '}
              {moneyNumberFormat(Number(recommendation?.overallInvestment) || 0)}
            </CharitablesTitle>
            <HeaderActions>
              <UICheckbox
                label="Select All"
                check={isSelectedAll}
                handleClick={handleSelectAll}
              />
              <Pagination
                page={Number(meta.currentPage)}
                pageLimit={Number(meta.itemsPerPage)}
                submitHandler={page =>
                  onFetchCharitables({
                    order: sortStatus.name,
                    orderType: sortStatus.status,
                    page,
                  })
                }
                total={meta.totalItems}
              />
            </HeaderActions>
            <CharitablesTable
              onCheckRow={handleSelect}
              onSort={handleSwitch}
              rows={charitables}
              selectedIds={selectedIds}
              sortStatus={sortStatus}
            />
          </TabPanel>
        </Tabs>
      </Section>

      {tabIndex === 0 && (
        <>
          <SectionTitle>Core Issues</SectionTitle>
          <Section>
            {recommendation?.mission && recommendation.mission.length > 0 ? (
              recommendation.mission.map(({ label, value }) => (
                <ImpactValue key={value}>{label}</ImpactValue>
              ))
            ) : (
              <ImpactValue>No Information</ImpactValue>
            )}
          </Section>
        </>
      )}
    </>
  );
};

const Section = styled.div`
  padding: 24px;
  background-color: white;
  border-radius: 6px;
  margin-bottom: 40px;
`;

const SectionTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 16px;
`;

const ImpactValue = styled.p`
  font-size: 16px;
  line-height: 155%;
  display: flex;
  align-items: center;
  &:not(:nth-last-child(n - 2)) {
    margin-bottom: 12px;
  }
  &:before {
    content: '';
    width: 6px;
    min-width: 6px;
    height: 6px;
    background: var(--blue);
    border-radius: 100%;
    margin-right: 8px;
  }
`;

const CharitablesTitle = styled.div`
  font-weight: 500;
  line-height: 145%;
  margin: 10px 0 32px;
`;

const HeaderActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const EmptyText = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #122434;
  padding: 258px 0;
  text-align: center;
`;

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 450px);
`;

export default RecommendationsContent;
