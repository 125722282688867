import Toast from 'services/Toast';

// eslint-disable-next-line no-useless-escape
const websiteRegExp = /^(http(s?):\/\/)?[a-zA-Z0-9\.\-\_]+(\.[a-zA-Z]{2,10})+(\/[a-zA-Z0-9\_\-\s\.\/\?\%\#\&\=]*)?$/;
const urlRegExp = /^http(s?):\/\/[a-zA-Z0-9.-_]+(\.[a-zA-Z]{2,10})+(\/[a-zA-Z0-9_-\s./?%#&=]*)?$/;
const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const digitsAndCharsRegExp = /^[A-Za-z0-9]+$/i;
const atleastDigitRegexp = /^(?=.*?[0-9]).+$/i;
const nameRegExp = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
const einRegExp = /^\d{9}$|(^\d\d-\d{7}$)/;
const MAX_FILE_SIZE_MB = 5;
const integerRegExp = /^\d*$/;
const specialReg =
  '[`~!@#$^&*()=|{}:;,""\\[\\]<>/?%~！@#￥……&*（）——|{}【】+‘；：”“。，、？]|[\\\\/]';
const textRules = /^[-_&. ,\w]+$/;
const batchRename = /^[-_&. ,\w]{1,25}$/;
const notMark = (str: string) => new RegExp(`^[^${str}]+$`);
const negative = /^\d+(\.\d+)?$/;

const IMAGE_FILE_EXTENSIONS = [
  'tiff',
  'pjp',
  'jfif',
  'bmp',
  'gif',
  'svg',
  'png',
  'xbm',
  'dib',
  'jxl',
  'jpeg',
  'svgz',
  'jpg',
  'webp',
  'ico',
  'tif',
  'pjpeg',
  'avif',
];

export const CAMPAIN_IMAGE_FILE_EXTENSIONS = ['bmp', 'svg', 'png', 'jpeg', 'jpg'];

/** Begin form validators */

export const lessThan5MB = (bytes: number) => {
  const sizeInMB = bytes / 10 ** 6;

  return sizeInMB > MAX_FILE_SIZE_MB ? 'File size must be less than 5 mb' : undefined;
};

export const required = <FieldValue>(value?: FieldValue) =>
  value || (typeof value === 'number' && !Number.isNaN(value)) ? undefined : 'Required';

export const maxLength = (length: number) => <FieldValue extends string>(
  value?: FieldValue,
) =>
  value && value.length > length
    ? `Maximum length of this field is ${length} characters.`
    : undefined;

export const maxLength100 = maxLength(100);
export const maxLength80 = maxLength(80);
export const maxLength500 = maxLength(500);
export const maxLength50 = maxLength(50);
export const maxLength250 = maxLength(250);
export const maxLength150 = maxLength(150);
export const maxLength10 = maxLength(10);
export const maxLength65 = maxLength(65);
export const maxLength165 = maxLength(165);
export const maxLength450 = maxLength(450);

export const maxLength9 = maxLength(9);

export const notOnlySpaces = <FieldValue extends string>(value?: FieldValue) =>
  value && value.trim().length === 0
    ? 'The field must not contain only spaces'
    : undefined;

export const atleastDigit = <FieldValue extends string>(value?: FieldValue) =>
  value && !atleastDigitRegexp.test(value)
    ? 'Password must have atleast one digit'
    : undefined;

export const passwordLength = <FieldValue extends string>(value?: FieldValue) =>
  value && value.length < 6 ? 'Minimum password length is 6 characters' : undefined;

export const onlyDigitsAndChars = <FieldValue extends string>(value?: FieldValue) =>
  value && !digitsAndCharsRegExp.test(value)
    ? 'Only digits and characters allowed'
    : undefined;

export const password = <FieldValue extends string>(value?: FieldValue) => {
  const validationErrors = [
    atleastDigit(value),
    passwordLength(value),
    onlyDigitsAndChars(value),
  ].filter(result => result);

  return validationErrors.length ? validationErrors.join('\n') : undefined;
};

export const confirmPassword = <FieldValue extends string>(
  value: FieldValue,
  formValues,
) =>
  formValues.password !== value
    ? 'Password and confirmation password do not match'
    : undefined;

export const email = <FieldValue extends string>(value?: FieldValue) =>
  value && !emailRegExp.test(value) ? 'Incorrect email format' : undefined;

export const name = <FieldValue extends string>(value?: FieldValue) =>
  value && !nameRegExp.test(value) ? 'Incorrect name' : undefined;

export const phoneNumberLength = <FieldValue extends string>(value?: FieldValue) =>
  value && value.replace(/\+/, '').length < 11
    ? 'Phone number must be 11 digits length'
    : undefined;

export const webSiteLink = <FieldValue extends string>(value?: FieldValue) =>
  value && !websiteRegExp.test(value) ? 'Invalid website' : undefined;

export const webURLSite = <FieldValue extends string>(value?: FieldValue) =>
  value && !websiteRegExp.test(value) ? 'Please enter a valid URL' : undefined;

export const urlLink = <FieldValue extends string>(value?: FieldValue) =>
  value && !urlRegExp.test(value) ? 'Invalid URL' : undefined;

export const notQuote = <FieldValue extends string>(value?: FieldValue) =>
  value && !notMark('"').test(value)
    ? 'Invalid format: include Quotation mark'
    : undefined;

export const notAtSign = <FieldValue extends string>(value?: FieldValue) =>
  value && !notMark('@').test(value) ? 'Invalid format: include At sign' : undefined;

export const notNumberSign = <FieldValue extends string>(value?: FieldValue) =>
  value && !notMark('#').test(value) ? 'Invalid format: include Number sign' : undefined;

export const integerValidator = (value: string) => {
  if (!value) {
    return undefined;
  }
  return integerRegExp.test(value) ? undefined : 'Must be an integer';
};

export const decimalValidator = (value: string) => {
  if (!value) {
    return undefined;
  }
  return negative.test(value) ? undefined : 'Must be a number';
};

export const decimalPositiveValidator = (value: string) => {
  if (!value) {
    return undefined;
  }
  return negative.test(value) ? undefined : 'Must be a positive number';
};

export const percentageOneHundredValidator = (value: string) => {
  if (!value) return undefined;
  if (Number.isNaN(Number(value))) return undefined;
  return Number(value) > 100 ? 'The maximum value cannot exceed 100' : undefined;
};

export const numericalValidator = (value: string, maxVal: number) => {
  if (!value) return undefined;
  if (Number.isNaN(Number(value))) return undefined;
  return Number(value) > maxVal ? `The maximum value cannot exceed ${maxVal}` : undefined;
};

export const einValidator = (value: string) => {
  if (!value) return undefined;
  return einRegExp.test(value) ? undefined : 'EIN must be nine numbers';
};

export const anotherNotNullRequired = (fieldName: string) => (
  value: unknown,
  values?: object,
) => (!values?.[fieldName] ? undefined : required(value));

export const rankAllSelectedValidator = (optionsLength: number) => (value: unknown[]) =>
  value.length < optionsLength ? 'All options must be sorted' : undefined;

export const notEmptyArray = (length: number) => (value: unknown[]) => {
  return !value || value.length < length || value.some(item => !item)
    ? 'All values must be filled in'
    : undefined;
};

/** End Form validators */

export const imageFileValidator = (filename: string, hideMessage?: boolean): boolean => {
  const validationResult = IMAGE_FILE_EXTENSIONS.includes(
    filename
      .split('.')
      .pop()
      ?.toLowerCase() || '',
  );

  if (!validationResult && !hideMessage) Toast.warn('Must be image files');

  return validationResult;
};

export const fileExtensionValidator = (extList: string[], warnMessage: string) => (
  filename: string,
): boolean => {
  const validationResult = extList.includes(
    filename
      .split('.')
      .pop()
      ?.toLowerCase() || '',
  );

  if (!validationResult) Toast.warn(warnMessage);

  return validationResult;
};

export const imageCampainFileValidator = (filename: string): boolean => {
  const validationResult = CAMPAIN_IMAGE_FILE_EXTENSIONS.includes(
    filename
      .split('.')
      .pop()
      ?.toLowerCase() || '',
  );
  // if (!validationResult) Toast.warn('Must be image files');
  return validationResult;
};

export const specialCharacters = <FieldValue extends string>(value?: FieldValue) => {
  const validate = new RegExp(specialReg);
  return value && validate.test(value)
    ? "Campaign Name can only contain alphanumeric and special characters . ' - _"
    : undefined;
};

export const stringRules = (value: string) => {
  return value && textRules.test(value)
    ? undefined
    : 'Special charaters are not allowed in Batch Name.';
};

export const LengthbatchRename = (value: string) => {
  return value && batchRename.test(value)
    ? undefined
    : 'Maximum length of this field is 25 characters.';
};

export const negatives = (value: string) => {
  return value && negative.test(value) ? undefined : 'Negative numbers are not allowed.';
};

export const MaximumValue = (value: string) => {
  if (value != undefined && value.includes('.')) {
    if (value.split('.')[1].length > 2) {
      return `Keep two decimal places.`;
    }
  } else {
    if (value != undefined && value.length > 4) {
      return `Maximum length of this field is 4 characters.`;
    }
  }
};
