import { GamePlanEmailTemplateTypeEnum } from 'constants/gamePlan';
import {
  Gameplan,
  ContactCombineCompany,
  NewGameplan,
  GameplanStatus,
  GameplanByTeamRes,
  SuggestGameplansRes,
  CustomizeTemplateGetRes,
  GameplanBase,
  CustomizeEmailTemplateGetRes,
} from 'interfaces/gameplan';
import { GameplanFormData } from 'pages/organizationUser/gameplan/components/GamePlanTemplate';
import * as types from './types';

export const setSaveListContacts = (savelistContacts: ContactCombineCompany[]) =>
  ({
    type: types.SET_SAVELIST_CONTACTS,
    payload: savelistContacts,
  } as const);

export const setNewGameplan = (newGameplan: NewGameplan | undefined) =>
  ({
    type: types.SET_NEW_GAMEPLAN,
    payload: newGameplan,
  } as const);

export const setGameplans = (gameplans: Gameplan[]) =>
  ({
    type: types.SET_GAMEPLANS,
    payload: gameplans,
  } as const);

export const setGameplansByTeam = (gameplans: GameplanByTeamRes) =>
  ({
    type: types.SET_GAMEPLANS_BY_TEAM,
    payload: gameplans,
  } as const);

export const deleteGameplan = (gameplanId: string) =>
  ({
    type: types.DELETE_GAMEPLANS_ITEM,
    payload: gameplanId,
  } as const);

export const setGameplan = (gameplan: Gameplan | undefined) =>
  ({
    type: types.SET_GAMEPLAN,
    payload: gameplan,
  } as const);

export const setGameplanNameAndClose = (param: {
  id: string;
  name: string;
  isClose: boolean;
}) =>
  ({
    type: types.SET_GAMEPLAN_NAME_AND_CLOSE,
    payload: param,
  } as const);

export const setGameplanNameAndCloseSingle = (param: {
  id: string;
  name: string;
  isClose: boolean;
}) =>
  ({
    type: types.SET_GAMEPLAN_NAME_AND_CLOSE_SINGLE,
    payload: param,
  } as const);

export const setGameplanNameAndCloseByTeam = (param: {
  id: string;
  name: string;
  isClose: boolean;
}) =>
  ({
    type: types.SET_GAMEPLAN_NAME_AND_CLOSE_BY_TEAM,
    payload: param,
  } as const);

export const deleteGameplanContact = (gameplanContactId: string) =>
  ({
    type: types.DELETE_GAMEPLAN_CONTACT,
    payload: gameplanContactId,
  } as const);

export const setGameplanStatus = (gameplanStatus: GameplanStatus | undefined) =>
  ({
    type: types.SET_GAMEPLAN_STATUS,
    payload: gameplanStatus,
  } as const);

export const setSuggestGameplans = (suggestGameplans: SuggestGameplansRes) =>
  ({
    type: types.SET_SUGGEST_GAMEPLANS,
    payload: suggestGameplans,
  } as const);

export const setGameplanCustomizeTemplate = (
  customizeTemplate: CustomizeTemplateGetRes,
) =>
  ({
    type: types.SET_GAMEPLAN_CUSTOMIZE_TEMPLATE,
    payload: customizeTemplate,
  } as const);

export const setEmailCustomizeTemplate = (
  customizeTemplate: CustomizeEmailTemplateGetRes,
) =>
  ({
    type: types.SET_EMAIL_CUSTOMIZE_TEMPLATE,
    payload: customizeTemplate,
  } as const);

export const setGameplanCustomizeTemplatePreview = (
  customizeTemplate: CustomizeTemplateGetRes,
) =>
  ({
    type: types.SET_GAMEPLAN_CUSTOMIZE_TEMPLATE_PREVIEW,
    payload: customizeTemplate,
  } as const);

export const setNewGameplanTemplates = (
  templatePreview: CustomizeTemplateGetRes,
  templateType: GamePlanEmailTemplateTypeEnum,
) =>
  ({
    type: types.SET_NEW_GAMEPLAN_TEMPLATES,
    payload: { templatePreview, templateType },
  } as const);

export const setNewGameplanTemplateByForm = (formValue: GameplanFormData) =>
  ({
    type: types.SET_NEW_GAMEPLAN_TEMPLATES_BY_FORM,
    payload: formValue,
  } as const);

export const setGameplanEmailAnalyticsContacts = (
  emailAnalyticsContacts: GameplanBase['gamePlanContacts'],
) =>
  ({
    type: types.SET_GAMEPLAN_EMAIL_ANALYTICS_CONTACTS,
    payload: emailAnalyticsContacts,
  } as const);
