import React, { FC } from 'react';
import styled from 'styled-components';
import { ValuationStatusEnum } from 'interfaces/pricing';

interface Props {
  type: ValuationStatusEnum | null;
}

const ValuationStatusTag: FC<Props> = ({ type }) => {
  switch (type) {
    case ValuationStatusEnum.Completed:
      return <GreenTag>Completed</GreenTag>;
    case ValuationStatusEnum.CustomerDataRequired:
      return <BlueTag>Customer Data Required</BlueTag>;
    case ValuationStatusEnum.PendingAdminReview:
      return <GrayTag>Pending Admin Review</GrayTag>;
    default:
      return null;
  }
};

const StatusTag = styled.div`
  font-size: 12px;
  height: 27px;
  border-radius: 99em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GreenTag = styled(StatusTag)`
  background: #cff4d9;
  color: #324a34;
  width: 91px;
`;

const BlueTag = styled(StatusTag)`
  background: #dbf2fb;
  color: #1a4d61;
  width: 172px;
`;

const GrayTag = styled(StatusTag)`
  background: #e8e8e8;
  color: #605e5c;
  width: 178px;
`;

export default ValuationStatusTag;
