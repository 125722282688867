import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { UIButton } from 'components/UI';
import EmptyIcon from 'assets/images/folder-plus.svg';

export interface EmptyFavoritesProps {
  className?: string;
}

const EmptyProspecting = ({ className }: EmptyFavoritesProps) => {
  const { push } = useHistory();

  return (
    <EmptyWrapper className={className}>
      <Icon src={EmptyIcon} />

      <Title>No lists saved</Title>
      <SubTitle>Go to search page and add to saved list</SubTitle>

      <SearchBtn
        asBtn={'a'}
        modifiers="third"
        handleClick={() => push(`search`)}
        title="Search"
      />
    </EmptyWrapper>
  );
};

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 6px;
  padding: 40px 0 34px;
`;

const Icon = styled(ReactSVG)`
  margin-bottom: 40px;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 6px;
`;

const SubTitle = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--gray);
  margin-bottom: 30px;
`;

const SearchBtn = styled(UIButton)`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 12px;
  line-height: 150%;
  max-width: 244px;
  padding: 8px 0;
`;

export default EmptyProspecting;
