import {
  DashboardROI,
  ROIData,
  ROIListRes,
  ROIListSuggest,
  ROIMeltwaterData,
  ROIReport,
  ROIDashboardSdgGoals,
  ROITemplate,
  ROISurvey,
  ROISurveyResult,
  ROICanva,
  ROISurveyStatistics,
  ROISurveyListRes,
  ROISurveyStatistic,
  ROICanvaTemplatesRes,
} from 'interfaces/roi';
import * as types from './types';

export const setROIList = (roiList: ROIListRes) =>
  ({
    type: types.SET_ROI_LIST,
    payload: roiList,
  } as const);

export const createROI = (roi: ROIData) =>
  ({
    type: types.CREATE_ROI,
    payload: roi,
  } as const);

export const deleteROI = (ids: string[]) =>
  ({ type: types.DELETE_ROI, payload: ids } as const);

export const setROI = (roi: ROIData) =>
  ({
    type: types.SET_ROI,
    payload: roi,
  } as const);

export const setROITemplates = (templates: ROITemplate[]) =>
  ({
    type: types.SET_ROI_TEMPLATES,
    payload: templates,
  } as const);

export const setROITemplatePreview = (preview: string) =>
  ({
    type: types.SET_ROI_TEMPLATE_PREVIEW,
    payload: preview,
  } as const);

export const setROITemplatesItem = (template: ROITemplate) =>
  ({
    type: types.SET_ROI_TEMPLATES_ITEM,
    payload: template,
  } as const);

export const toggleROITemplatesDisable = (id: string) =>
  ({
    type: types.TOGGLE_ROI_TEMPLATES_DISABLE,
    payload: id,
  } as const);

export const setRoiReport = (report: ROIReport) =>
  ({
    type: types.SET_ROI_REPORT,
    payload: report,
  } as const);

export const setROIMeltwaterData = (data: ROIMeltwaterData) =>
  ({
    type: types.SET_ROI_MELTWATER_DATA,
    payload: data,
  } as const);

export const setROIDashboard = (data: DashboardROI) =>
  ({
    type: types.SET_ROI_DASHBOARD,
    payload: data,
  } as const);

export const setROISuggest = (teams: ROIListSuggest) =>
  ({
    type: types.SET_ROI_SUGGEST,
    payload: teams,
  } as const);

export const setROISdgGoals = (sdgGoals: ROIDashboardSdgGoals) =>
  ({
    type: types.SET_ROI_SDGGOALS,
    payload: sdgGoals,
  } as const);

export const setROISurvey = (survey: ROISurvey) =>
  ({
    type: types.SET_ROI_SURVEY,
    payload: survey,
  } as const);

export const setROISurveys = (surveys: ROISurvey[]) =>
  ({
    type: types.SET_ROI_SURVEYS,
    payload: surveys,
  } as const);

export const setROISurveyResult = (result: ROISurveyResult) =>
  ({
    type: types.SET_ROI_SURVEY_RESULT,
    payload: result,
  } as const);

export const setROIOld = (roi: ROIData) =>
  ({
    type: types.SET_ROI_OLD,
    payload: roi,
  } as const);

export const setROICanva = (data: ROICanva) =>
  ({
    type: types.SET_ROI_CANVA,
    payload: data,
  } as const);

export const deleteROICanva = (id: string) =>
  ({
    type: types.DELETE_ROI_CANVA,
    payload: id,
  } as const);

export const setROICanvaTemplates = (templates: ROICanvaTemplatesRes) =>
  ({
    type: types.SET_ROI_CANVA_TEMPLATE,
    payload: templates,
  } as const);

export const setROICanvaTemplatesPrivate = (templates: ROICanvaTemplatesRes) =>
  ({
    type: types.SET_ROI_CANVA_TEMPLATE_PRIVATE,
    payload: templates,
  } as const);

export const setROISurveyStatistics = (statistics: ROISurveyStatistics) =>
  ({
    type: types.SET_ROI_SURVEY_STATISTICS,
    payload: statistics,
  } as const);

export const setROISurveyList = (list: ROISurveyListRes) =>
  ({
    type: types.SET_ROI_SURVEY_LIST,
    payload: list,
  } as const);

export const setROISurveyStatisticById = (statistic: ROISurveyStatistic) =>
  ({
    type: types.SET_ROI_SURVEY_STATISTIC_BY_ID,
    payload: statistic,
  } as const);

export const deleteROISurveyStatistic = (deleteIds: string) =>
  ({
    type: types.DELETE_ROI_SURVEY_STATISTIC,
    payload: deleteIds,
  } as const);
