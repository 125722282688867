import React from 'react';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import styled from 'styled-components';

interface Props {
  data: { name: string; value: number }[];
}

const ResponseSummaryChart = ({ data }: Props) => {
  return (
    <Root>
      <ResponsiveContainer width={486} height={336}>
        <BarChart data={data} barSize={20}>
          <XAxis dataKey="name" scale="point" padding={{ left: 16, right: 16 }} />
          <YAxis domain={[0, 100]} />
          <Tooltip />
          <CartesianGrid strokeDasharray="3 3" />
          <Bar dataKey="value" fill="#56A4CE" />
        </BarChart>
      </ResponsiveContainer>
    </Root>
  );
};

const Root = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export default ResponseSummaryChart;
