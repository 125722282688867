import { ExportButton } from 'components/ActionButtons';
import { ImportPartnersModal } from 'components/Modals';
import SuccessfullyModal from 'components/Modals/SuccessfullyModal';
import SearchSuggest from 'components/SearchSuggest';
import TotalRow from 'components/TotalRow';
import { SingleSelect, UIButton } from 'components/UI';
import { TimerRangeSelectOptions } from 'constants/roi';
import { useModal, useOperation } from 'hooks/common';
import { PaginationMeta } from 'interfaces/common';
import { SuggestCompany } from 'interfaces/companies';
import { ROIListReq, ROIUIType } from 'interfaces/roi';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Toast from 'services/Toast';
import { fetchCompaniesSearch } from 'store/ducks/companies/operations';
import { getCompaniesSearch } from 'store/ducks/companies/selectors';
import { fetchUploadROIXLSX, fetchUploadROIXLSXByCompany } from 'store/ducks/roi/api';
import {
  fetchROIAddCompany,
  fetchROIAddCompanyNew,
  fetchROIAddTeam,
  fetchROIAddTeamNew,
  fetchROICreate,
  fetchROISuggest,
} from 'store/ducks/roi/operations';
import { getROIsSuggest } from 'store/ducks/roi/selectors';
import { fetchTeamsSuggest } from 'store/ducks/team/operations';
import { getTeamsSuggest } from 'store/ducks/team/selectors';
import { selectUser } from 'store/ducks/user/selectors';
import { StateProps } from 'store/interfaces';
import styled from 'styled-components';
import {
  AddNewPartnerByTeamModal,
  AddNewPartnerModal,
  UPloadROIProgressModal,
} from './modals';

interface Props {
  meta: PaginationMeta;
  searchValue: string;
  type: ROIUIType;
  timerRange: string;
  onSearch: (req: ROIListReq) => void;
  onChangeSearchValue: (str: string) => void;
  onChangeTimerRange: (str: string) => void;
  onFetchROIList: (args: ROIListReq) => void;
}

const ROISearch = ({
  type,
  meta,
  onSearch,
  searchValue,
  timerRange,
  onChangeSearchValue,
  onChangeTimerRange,
  onFetchROIList,
}: Props) => {
  let filesTotal = 0;
  const [uploadProgressValue, setUploadProgressValue] = useState(0);
  const [uploadingFileName, setUploadingFileName] = useState('');

  const [onSearchCompanies] = useOperation(fetchCompaniesSearch);
  const [onSearchTeams] = useOperation(fetchTeamsSuggest);
  const [onFetchROICreate, , roiCreateLoading] = useOperation(fetchROICreate);
  const [onFetchCompanyCreate, , companyCreateLoading] = useOperation(fetchROIAddCompany);
  const [onFetchTeamCreate, , teamCreateLoading] = useOperation(fetchROIAddTeam);
  const [onFetchCompanyCreateNew] = useOperation(fetchROIAddCompanyNew);
  const [onFetchTeamCreateNew] = useOperation(fetchROIAddTeamNew);
  const [onFetchROIs] = useOperation(fetchROISuggest);

  const user = useSelector(selectUser);
  const companiesSearchList = useSelector<StateProps, SuggestCompany[]>(
    getCompaniesSearch,
  );
  const teamsSearchList = useSelector(getTeamsSuggest);
  const roisSearchList = useSelector(getROIsSuggest);

  const handleSearchCompanies = (val: string) => {
    return onSearchCompanies(val);
  };

  const handleSearchCompaniesDebounce = useCallback(
    debounce(handleSearchCompanies, 800),
    [],
  );

  const handleSearchTeams = (val: string) => {
    return onSearchTeams(val);
  };

  const handleSearchTeamsDebounce = useCallback(debounce(handleSearchTeams, 800), []);

  const handleSearchROIs = (val: string) => {
    return onFetchROIs(val);
  };

  const handleSearchROIsDebounce = useCallback(debounce(handleSearchROIs, 800), []);

  const [showSuccessfullyModal, hideSuccessfullyModal] = useModal(
    () => (
      <SuccessfullyModal
        title="Import successfully"
        handleSubmit={() => {
          onFetchROIList({ page: 1, q: searchValue, timerRange });
          hideSuccessfullyModal();
        }}
        onClose={hideSuccessfullyModal}
      />
    ),
    [uploadProgressValue, uploadingFileName],
  );

  const uploadROIXLSX = useCallback(async (files: File[]) => {
    const uploadFunc =
      type === 'company' ? fetchUploadROIXLSXByCompany : fetchUploadROIXLSX;

    if (!files.length) {
      setUploadProgressValue(0);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      hideUPloadROIProgressModal();
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      showSuccessfullyModal();
      return;
    }
    setUploadProgressValue(((filesTotal - files.length) * 100 + 100) / filesTotal);
    setUploadingFileName(files[0].name);
    await uploadFunc(files[0])
      .then(() => {
        uploadROIXLSX(files.slice(1));
      })
      .catch(err => {
        err.response.data.statusCode === 400
          ? Toast.error(err.response.data.message)
          : Toast.error('Error import Partnership');
        setUploadProgressValue(0);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        hideUPloadROIProgressModal();
        onFetchROIList({ page: 1, q: searchValue, timerRange });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showAddNewPartnerModal, hideAddNewPartnerModal] = useModal(
    () => (
      <AddNewPartnerModal
        handleAccess={values => {
          onFetchROICreate({ ...values, accountOwner: user.id, authorId: user.id });
          hideAddNewPartnerModal();
        }}
        onCompanyCreate={onFetchCompanyCreate}
        onCompanyCreateByChoose={onFetchCompanyCreateNew}
        onClose={hideAddNewPartnerModal}
        handleGetCompanies={handleSearchCompaniesDebounce}
        companiesSearchList={companiesSearchList}
        isloading={companyCreateLoading || roiCreateLoading}
      />
    ),
    [companiesSearchList, companyCreateLoading, roiCreateLoading],
  );

  const [showAddNewPartnerByCompanyModal, hideAddNewPartnerByCompanyModal] = useModal(
    () => (
      <AddNewPartnerByTeamModal
        handleAccess={values => {
          onFetchROICreate({
            ...values,
            isCompany: type === 'company' ? true : false,
            accountOwner: user.id,
            authorId: user.id,
          });
          hideAddNewPartnerByCompanyModal();
        }}
        onTeamCreate={onFetchTeamCreate}
        onClose={hideAddNewPartnerByCompanyModal}
        handleGetTeams={handleSearchTeamsDebounce}
        teamsSearchList={teamsSearchList}
        onTeamCreateByChoose={onFetchTeamCreateNew}
        isloading={teamCreateLoading || roiCreateLoading}
      />
    ),
    [teamsSearchList, teamCreateLoading, roiCreateLoading],
  );

  const [showUPloadROIProgressModal, hideUPloadROIProgressModal] = useModal(
    () => (
      <UPloadROIProgressModal
        progressValue={uploadProgressValue}
        fileName={uploadingFileName}
        onClose={hideUPloadROIProgressModal}
      />
    ),
    [uploadProgressValue, uploadingFileName],
  );

  const [showImportPartnersModal, hideImportPartnersModal] = useModal(
    () => (
      <ImportPartnersModal
        title="Import Partners"
        multiple
        handleAccess={files => {
          filesTotal = files.length;
          hideImportPartnersModal();
          showUPloadROIProgressModal();
          return uploadROIXLSX(files);
        }}
        onClose={hideImportPartnersModal}
        type={type}
      />
    ),
    [type],
  );

  return (
    <Root>
      <Header>
        <TotalRow
          title={`${meta.totalItems} partnership${meta.totalItems > 1 ? 's' : ''}`}
          maxWidth="unset"
        >
          <Actions>
            <ExportButton
              name="Import Partners"
              iconCss={{ transform: 'rotate(180deg)' }}
              uploadHandler={showImportPartnersModal}
            />
            <Button
              title="Add New Partner"
              modifiers="primary"
              handleClick={
                type === 'organization'
                  ? showAddNewPartnerModal
                  : showAddNewPartnerByCompanyModal
              }
            />
          </Actions>
        </TotalRow>
      </Header>

      <Filter>
        <Name>Filters</Name>
        <FieldGroup>
          <SelectGroup>
            <SingleSelect
              placeholder="Renewal Date"
              handleSelect={(v: any) => {
                if (!v?.value) return;
                onChangeTimerRange(v?.value);
                onChangeSearchValue('');
                onSearch({ page: 1, timerRange: v?.value });
              }}
              value={timerRange}
              options={TimerRangeSelectOptions}
              isClearable={false}
            />
          </SelectGroup>
          <SearchPartner
            handleGetSuggestions={handleSearchROIsDebounce}
            list={roisSearchList}
            placeholder="Search Partners"
            searchValue={searchValue}
            onSearch={value => {
              onSearch({ page: 1, q: value });
              onChangeTimerRange('');
              onChangeSearchValue(value);
            }}
          />
        </FieldGroup>
      </Filter>
    </Root>
  );
};

const Root = styled.div`
  margin-top: 28px;
`;

const Header = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Filter = styled.section`
  margin-bottom: 24px;
`;

const Name = styled.div`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
  margin-bottom: 8px;
`;

const FieldGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SelectGroup = styled.div`
  display: flex;

  & > div {
    width: 180px;
  }

  & > div:not(:first-child) {
    margin-left: 18px;
  }
`;

const Actions = styled.div`
  margin-left: auto;
  display: flex;
`;

const Button = styled(UIButton)`
  margin-left: 24px;
  width: 220px;
`;

const SearchPartner = styled(SearchSuggest)`
  width: 436px;
  margin-left: 36px;
`;

export default ROISearch;
