import React from 'react';
import { Link } from 'react-router-dom';
import { UIButton } from 'components/UI';
import { SupportModal } from 'components/Modals';
import useModal from 'hooks/common/useModal';
import styled from 'styled-components';
import AuthFormWrapper from 'components/AuthForms/AuthFormWrapper';

const PopupResetPassword = () => {
  const [openSupportModal, closeSupportModal] = useModal(() => (
    <SupportModal onClose={closeSupportModal} />
  ));

  return (
    <>
      <Wrapper>
        <Inner>
          <Title>Password Reset</Title>

          <Desc>
            A link was sent to your email to confirm password reset and create a new one
          </Desc>

          <UIButton
            modifiers="primary"
            title="Resend"
            propsCss={{ width: '100%' }}
            handleClick={() => {}}
          />
        </Inner>

        <Btn onClick={openSupportModal}>Contact Support</Btn>
      </Wrapper>
      <LinkElem to="/login">Return to Login</LinkElem>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Inner = styled(AuthFormWrapper)`
  margin-bottom: 32px;
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  margin-bottom: 20px;
`;

const Desc = styled.p`
  font-size: 16px;
  line-height: 155%;
  color: var(--black);
  margin-bottom: 40px;
`;

const Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 138px;
  height: 36px;
  font-size: 12px;
  line-height: 150%;
  background: rgba(18, 36, 52, 0.15);
  border-radius: 6px;
  font-weight: 500;
  color: #fff;
  &:hover {
    background: rgba(18, 36, 52, 0.25);
  }
`;

const LinkElem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 138px;
  height: 36px;
  font-size: 12px;
  line-height: 150%;
  background: rgba(18, 36, 52, 0.15);
  border-radius: 6px;
  font-weight: 500;
  color: #fff;
  margin: 0 auto 28px;
  &:hover {
    background: rgba(18, 36, 52, 0.25);
  }
`;

export default PopupResetPassword;
