/* eslint-disable react-hooks/exhaustive-deps */
import EmptyCompany from 'assets/images/icons/empty-company.svg';
import LinkedinImage from 'assets/images/icons/linkedIn-square.svg';
import { ExportButton } from 'components/ActionButtons';
import { UIButton, UILoadAvatar, UISpinner, UISwitch } from 'components/UI';
import UILink from 'components/UI/UILink';
import useDownloadFile from 'hooks/common/useDownloadFile';
import { Gameplan, GameplanBase, CompanyCombineContact } from 'interfaces/gameplan';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Toast from 'services/Toast';
import { fetchGameplanActivity } from 'store/ducks/gameplan/api';
import styled from 'styled-components';
import { parseLink } from 'utils';
import PencilSVG from 'assets/images/icons/pencil.svg';
import { ReactSVG } from 'react-svg';
import InfoIconSVG from 'assets/images/icons/info-blue.svg';
import { useGameplanMetric } from 'hooks/gameplan';
import { TeamProfileData } from 'interfaces/team';
import Avatar from 'react-avatar';
import { useFileUrl } from 'hooks/common';
import { format } from 'date-fns';
import { capitalizeText, getFullName } from 'utils/formatters';

interface Props {
  gameplan: GameplanBase;
  team: TeamProfileData;
  fetchGameplanApi: (gameplanId: string) => Promise<Gameplan>;
  fetchDeleteGameplanContactApi: (
    gameplanId: string,
  ) => Promise<{
    status: string;
  }>;
  onFetchSetGameplanNameAndClose: (param: {
    id: string;
    isClose: boolean;
    name: string;
  }) => void;
}

const CardGameplan: FC<Props> = ({
  gameplan,
  fetchGameplanApi,
  fetchDeleteGameplanContactApi,
  onFetchSetGameplanNameAndClose,
  team,
}) => {
  const { push } = useHistory();
  const [gameplanDetail, setGameplanDetail] = useState<Gameplan | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { avatarKey, updatedAt, firstName, lastName, role } =
    gameplanDetail?.lastAuthor || {};
  const { fileUrl } = useFileUrl({ imgKey: avatarKey });

  const lastActivityStr = updatedAt && format(new Date(updatedAt), 'dd MMM yyyy');
  const userName = getFullName(firstName, lastName);
  const userRole = capitalizeText(role);

  const onFetchDeleteGameplanContact = (id: string) => {
    fetchDeleteGameplanContactApi(id)
      .then(({ status }) => {
        if (status !== 'success') {
          Toast.error('Fail delete gameplan contact');
          return;
        }
        if (!gameplanDetail) {
          return;
        }
        const newGameplanDetail = {
          ...gameplanDetail,
          gamePlanContacts: gameplanDetail.gamePlanContacts.filter(
            item => item.id !== id,
          ),
        };
        setGameplanDetail(newGameplanDetail);
        Toast.success('Gameplan contact successfully deleted ');
      })
      .catch(() => Toast.error('Error delete gameplan contact'));
  };

  useEffect(() => {
    setIsLoading(true);
    fetchGameplanApi(gameplan.id)
      .then(value => {
        setGameplanDetail(value);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [gameplan]);

  const companyContacts = useMemo(() => {
    if (!gameplanDetail?.gamePlanContacts) {
      return;
    }
    return (gameplanDetail?.gamePlanContacts).reduce((prev, curr) => {
      if (curr.ownContact) {
        const companyId = curr.ownContact.companyId || 'other';
        const company = prev.get(companyId);
        prev.set(companyId, {
          ...curr.ownContact.company,
          id: curr.ownContact.companyId,
          contacts: company
            ? [...company.contacts, { ...curr.ownContact, gameplanContactId: curr.id }]
            : [{ ...curr.ownContact, gameplanContactId: curr.id }],
        });
        return prev;
      }

      if (curr.contact) {
        const companyId = curr.contact.companyId || 'other';
        const company = prev.get(companyId);
        prev.set(companyId, {
          ...curr.contact.company,
          contacts: company
            ? [...company.contacts, { ...curr.contact, gameplanContactId: curr.id }]
            : [{ ...curr.contact, gameplanContactId: curr.id }],
        });
      }

      return prev;
    }, new Map<string, CompanyCombineContact>());
  }, [gameplanDetail]);

  const exportExcel = useDownloadFile(fetchGameplanActivity);

  const toRouteViewGameplanTemplate = () => {
    if (gameplan.isDraft)
      return push(
        `/gameplan/edit/${gameplan.savedListId}/${gameplan.id}?type=${gameplan.gamePlanType}`,
      );

    push(`/gameplan/view-template/${gameplan?.name}/${gameplan?.id}`);
  };

  const gameplanMetric = useGameplanMetric(
    team.isAnalyticsGamePlan ? gameplanDetail : undefined,
  );

  return (
    <Root>
      <PanelTop>
        <PanelTitle onClick={toRouteViewGameplanTemplate}>{gameplan.name}</PanelTitle>
        <PanelAction>
          {gameplan.isDraft ? (
            <DraftText onClick={toRouteViewGameplanTemplate}>
              <ReactSVG src={PencilSVG} />
              Draft
            </DraftText>
          ) : (
            <>
              <ExportButton
                name=""
                iconCss={{ marginRight: '18px' }}
                uploadHandler={() => exportExcel(gameplan.id)}
              />
              <UISwitch
                isText
                checked={!gameplan.isClose}
                onChange={bool =>
                  onFetchSetGameplanNameAndClose({
                    id: gameplan.id,
                    isClose: !bool,
                    name: gameplan.name,
                  })
                }
              />
            </>
          )}
        </PanelAction>
      </PanelTop>
      <PanelBody>
        {gameplan.savedListName && (
          <>
            <ProspectingSession>Prospecting Session</ProspectingSession>
            <ProspectingSessionName>{gameplan.savedListName}</ProspectingSessionName>
          </>
        )}
        <GameplanStatusWrapper>
          <GameplanStatusCard>
            <MetricsInfoIcon
              src={InfoIconSVG}
              data-tip
              data-for="gameplan-metric-tooltip"
            />
            <GameplanStatusTitle>Day</GameplanStatusTitle>
            <GameplanStatusValue>
              {gameplan.currentDays &&
                gameplan.totalDays &&
                `${gameplan.currentDays}/${gameplan.totalDays}`}
            </GameplanStatusValue>
          </GameplanStatusCard>
          <GameplanStatusCard>
            <GameplanStatusTitle>Email Sent</GameplanStatusTitle>
            <GameplanStatusValue>
              {gameplan.currentStep}/{gameplan.totalStepCount}
            </GameplanStatusValue>
          </GameplanStatusCard>
          {team.isAnalyticsGamePlan && (
            <>
              <GameplanStatusCard>
                <GameplanStatusTitle>Total Recipients</GameplanStatusTitle>
                <GameplanStatusValue>
                  {gameplanDetail?.emailAnalytics.recipientNum}
                </GameplanStatusValue>
              </GameplanStatusCard>
              <GameplanStatusCard>
                <GameplanStatusTitle>Open %</GameplanStatusTitle>
                <GameplanStatusValue>{gameplanMetric.openRate}</GameplanStatusValue>
              </GameplanStatusCard>
              <GameplanStatusCard>
                <GameplanStatusTitle>Link Clicks % </GameplanStatusTitle>
                <GameplanStatusValue>{gameplanMetric.clickRate}</GameplanStatusValue>
              </GameplanStatusCard>
              <GameplanStatusCard>
                <GameplanStatusTitle>Email Replies %</GameplanStatusTitle>
                <GameplanStatusValue>{gameplanMetric.replyRate}</GameplanStatusValue>
              </GameplanStatusCard>
            </>
          )}
        </GameplanStatusWrapper>
        <ContactList>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              {companyContacts &&
                [...companyContacts.values()].map(value => (
                  <CompanyItem key={value.id}>
                    <CompanyInfo>
                      {value.logo ? (
                        <LoadLogo imgKey={value.logo} />
                      ) : (
                        <Logo src={EmptyCompany} />
                      )}

                      {value.name}
                    </CompanyInfo>
                    <CompanyContacts>
                      {value.contacts.map(contact => (
                        <CompanyContactItem key={contact.email}>
                          <CompanyContactInfo>
                            <CompanyContactItemTitle>
                              <span>{`${contact.firstName} ${contact.lastName}`}</span>
                              {contact.linkedinUrl ? (
                                <LinkedlnIconWrapper
                                  href={parseLink(contact.linkedinUrl)}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <LinkedlnIcon src={LinkedinImage} />
                                </LinkedlnIconWrapper>
                              ) : null}
                            </CompanyContactItemTitle>
                            <CompanyContactItemDesc>
                              <UILink href={`tel:${contact.phone}`}>
                                {contact.phone}
                              </UILink>
                              {contact.email && contact.phone && (
                                <CompanyContectItemPoint />
                              )}
                              <UILink href={`mailto:${contact.email}`}>
                                {contact.email}
                              </UILink>
                            </CompanyContactItemDesc>
                          </CompanyContactInfo>
                          <CompanyContactItemAction>
                            <DeleteBtn
                              title="Delete"
                              modifiers="danger"
                              handleClick={() =>
                                onFetchDeleteGameplanContact(contact.gameplanContactId)
                              }
                            />
                          </CompanyContactItemAction>
                        </CompanyContactItem>
                      ))}
                    </CompanyContacts>
                  </CompanyItem>
                ))}
            </>
          )}
        </ContactList>
      </PanelBody>
      <Footer>
        <UserWrapper>
          <Avatar round name={userName} src={fileUrl} size="40" />
          <UserInfo>
            <UserName>{userName}</UserName>
            <UserRole>{userRole}</UserRole>
          </UserInfo>
        </UserWrapper>

        <div>
          <LastActivity>Last Activity</LastActivity>
          <LastActivityDate>{lastActivityStr}</LastActivityDate>
        </div>
      </Footer>
    </Root>
  );
};

const Root = styled.div`
  width: 552px;
  height: 730px;
  background-color: #fff;
  border-radius: 6px;
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

const PanelTop = styled.div`
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 9px;
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
`;

const PanelTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  width: max-content;
  cursor: pointer;
`;

const PanelAction = styled.div`
  display: flex;
`;

const PanelBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const CompanyItem = styled.div`
  display: flex;
`;

const CompanyInfo = styled.div`
  width: 118px;
  flex-shrink: 0;
  // height: 41px;
  height: auto;
  display: flex;
  align-items: center;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 16px;
`;

const Logo = styled.img`
  width: 28px;
  height: 28px;
  margin: 0 8px;
  object-fit: contain;
`;

const LoadLogo = styled(UILoadAvatar)`
  width: 28px;
  height: 28px;
  margin: 0 8px;
  object-fit: contain;
`;

const CompanyContacts = styled.div`
  flex: 1;
`;

const CompanyContactInfo = styled.div`
  width: 272px;
`;

const CompanyContactItem = styled.div`
  display: flex;
  justify-content: space-between;
  height: 52px;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 12px;
`;

const CompanyContactItemTitle = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
  display: flex;
`;

const CompanyContactItemDesc = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const CompanyContectItemPoint = styled.div`
  width: 4px;
  height: 4px;
  background-color: #c4c4c4;
  border-radius: 50%;
  margin: 0 4px 0 8px;
`;

const LinkedlnIcon = styled.img`
  height: 16px;
  width: 16px;
`;

const LinkedlnIconWrapper = styled.a`
  margin-left: 8px;
`;

const CompanyContactItemAction = styled.div`
  margin-right: 4px;
`;

const DeleteBtn = styled(UIButton)`
  width: 60px;
  padding: 4px 0;
`;

const ContactList = styled.div`
  overflow-y: auto;
`;

const GameplanStatusWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 18px;
  margin-bottom: 20px;
`;

const GameplanStatusCard = styled.div`
  background: #f9f9f9;
  border-radius: 4px;
  height: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const GameplanStatusTitle = styled.div`
  color: var(--darkGray);
  font-size: 12px;
  line-height: 150%;
  margin-top: 10px;
`;

const GameplanStatusValue = styled.div`
  color: var(--black);
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
`;

const ProspectingSession = styled.div`
  color: var(--darkGray);
  font-size: 12px;
  line-height: 150%;
  margin-bottom: 10px;
`;

const ProspectingSessionName = styled(ProspectingSession)`
  font-weight: 500;
  font-size: 14px;
  color: var(--dark);
`;

const Spinner = styled(UISpinner)`
  margin-top: 32px;
`;

const DraftText = styled.div`
  font-size: 12px;
  line-height: 150%;
  color: #1a4d61;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }
`;

const MetricsInfoIcon = styled(ReactSVG)`
  position: absolute;
  left: 8px;
  top: 8px;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

const UserInfo = styled.div`
  margin-left: 12px;
`;

const UserWrapper = styled.div`
  display: flex;
`;

const UserName = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  margin-bottom: 4px;
`;

const UserRole = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
`;

const LastActivity = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
`;

const LastActivityDate = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
`;

export default CardGameplan;
