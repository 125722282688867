import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { UIButton, UIModal } from 'components/UI';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { Form, FormSpy } from 'react-final-form';
import { Field } from 'components/FinalForm';
import {
  FieldDatePicker,
  FieldTextInput,
  MaskedMoneyTextInput,
} from 'components/FormFields';
import {
  required,
  webSiteLink,
  fileExtensionValidator,
  integerValidator,
  maxLength9,
} from 'utils/validation';
import { ROIData } from 'interfaces/roi';
import { FormRow } from 'components/UI/form';
import { getDateCutOffTime, setDateGreaterLessDay, composeValidators } from 'utils';
import { useIsCompanyUser, useOperation } from 'hooks/common';
import { FormApi } from 'final-form';
import { FieldWrapper } from 'components/roi/forms/styled';
import UploadFileArea from 'components/UploadFileArea';
import { getFilenameByPath } from 'utils/formatters';
import Toast from 'services/Toast';
import { uploadFile } from 'store/ducks/utility/api';
import { GiveWorxROIReq } from 'interfaces/fundraising';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/ducks/user/selectors';
import { compareAsc } from 'date-fns';
import { fetchGiveWorkUpdate } from 'store/ducks/fundraising/operations';

interface Props {
  onClose: () => void;
  isLoading?: boolean;
  handleAccess: () => void;
  itemDetails: ROIData | undefined;
}

const EditGivePartnerModal = ({
  onClose,
  handleAccess,
  isLoading,
  itemDetails,
  ...modalProps
}: Props) => {
  useLockBodyScroll();
  const formRef = useRef<FormApi | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const user = useSelector(selectUser);
  const isCompanyUser = useIsCompanyUser(user);
  const [onFetchWorkUpdate] = useOperation(fetchGiveWorkUpdate);

  const submitEvent = (values: GiveWorxROIReq) => {
    const params = { ...values, isCompany: isCompanyUser };
    onFetchWorkUpdate(params).then((e: any) => {
      if (e?.companyId) {
        Toast.success('Your form is saved successfully');
        handleAccess();
      }
      if (e) setLoading(false);
    });
  };

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 0' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '836px',
        overflow: 'unset',
        top: '12%',
      }}
    >
      <Title>Edit New Partner</Title>
      <Form
        initialValues={itemDetails}
        onSubmit={values => {
          setLoading(true);
          submitEvent({ ...values });
        }}
        render={({ handleSubmit, form, values }) => (
          <FormBody>
            <Field
              name="companyName"
              label={`${isCompanyUser ? 'Organization' : 'Company'} Name`}
              component={FieldTextInput}
              disabled
            />
            <FieldTitle>Partner image/Logo</FieldTitle>
            {
              <FieldWrapper>
                <UploadFileArea
                  name="Upload File"
                  accept=".jpg,.jpeg,.png,.bmp,.svg"
                  inputId="campaign-custom-content"
                  acceptValidator={fileExtensionValidator(
                    ['jpg', 'jpeg', 'png', 'bmp', 'svg'],
                    'Must be jpg, jpeg, png, bmp, svg files',
                  )}
                  filelist={
                    values.partnerLogo
                      ? [
                          {
                            label: getFilenameByPath(values.partnerLogo),
                            value: values.partnerLogo,
                          },
                        ]
                      : undefined
                  }
                  desc="You may upload an image in *.png.*.jpeg.*.jpg.*.bmp.*.svg. File size limit is 5 Mb."
                  onUpload={files => {
                    if (!files.length) return;
                    uploadFile(files[0], 'fundraising-partners').then(v => {
                      if (v.status === 'success') {
                        form.change('partnerLogo', v.fileKey);
                        return Toast.success('File uploaded successfully');
                      }
                      Toast.error('Error upload file');
                    });
                  }}
                  onDeleteFile={fileKey => {
                    form.change('partnerLogo', '');
                  }}
                />
              </FieldWrapper>
            }
            <Field
              placeholder="Enter Partner Description"
              name="name"
              label="Partner Description"
              component={FieldTextInput}
            />
            <Field
              name="companyUrl"
              label={isCompanyUser ? 'Organization Website' : 'Company Website'}
              component={FieldTextInput}
              validate={webSiteLink}
              disabled
            />
            <Field name="CompanyAddress" label="Address" component={FieldTextInput} />
            <DollarInputWrapper>
              <Field
                name="annualFundraisingCommitment"
                label="Fundraising Commitment"
                component={MaskedMoneyTextInput}
                hidePrefix
                validate={composeValidators<string>(
                  required,
                  integerValidator,
                  maxLength9,
                )}
              />
              <DollarSubfix>$</DollarSubfix>
            </DollarInputWrapper>
            <FormRow>
              <Field
                name="startDate"
                label="Start Date"
                parse={v => getDateCutOffTime(v)}
                maxDate={setDateGreaterLessDay(values.endDate, 'less')}
                component={FieldDatePicker}
                popperPlacement="top"
                validate={required}
                disabled={compareAsc(new Date(String(values.startDate)), new Date()) < 0}
              />
              <Field
                name="endDate"
                label="End Date"
                parse={v => getDateCutOffTime(v)}
                minDate={setDateGreaterLessDay(values.startDate, 'greater')}
                component={FieldDatePicker}
                popperPlacement="top"
                validate={required}
              />
            </FormRow>

            <FormSpy subscription={{}}>
              {({ form }) => {
                formRef.current = form;
                return null;
              }}
            </FormSpy>

            <BtnGroup>
              <Btn modifiers="secondary" title="Cancel" handleClick={onClose} />
              <Btn
                modifiers="primary"
                title="Save"
                isLoading={loading}
                handleClick={handleSubmit}
              />
            </BtnGroup>
          </FormBody>
        )}
      />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 46px;
  text-align: center;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Btn = styled(UIButton)`
  display: block;
  width: 87px;
  font-size: 14px;
  padding: 7px 0;
`;

const FormBody = styled.div`
  margin: 0 32px;
`;

const FieldTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 145%;
  color: #737373;
  margin: 0px auto 4px;
  font-family: 'Rubik';
`;

const DollarInputWrapper = styled.div`
  position: relative;
`;

const DollarSubfix = styled.div`
  position: absolute;
  right: 12px;
  top: 34px;
`;

export default EditGivePartnerModal;
