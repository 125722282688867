import { UIButton } from 'components/UI';
import React, { FC } from 'react';
import styled from 'styled-components';
import ValuationStatusTag from './ValuationStatusTag';
import CircleThinChart from 'components/CircleThinChart';
import { useHistory } from 'react-router-dom';
import { PricingData, ValuationStatusEnum } from 'interfaces/pricing';
import { moneyFormatter } from 'utils/formatters';

interface Props {
  id: string;
  status: ValuationStatusEnum | null;
  formProgress: number;
  categorize: PricingData['categorize'];
  name: string;
  suggestedMinimumThreshold: number;
  totalAssetValue: number;
  onSelectCategory: (id, value) => void;
}
interface SelectCategoryProps {
  onSelect: (value) => void;
}

const ValuationCard: FC<Props> = props => {
  const { id, status, categorize, name, onSelectCategory } = props;
  const { push } = useHistory();

  return (
    <Card onClick={() => push(`/pricing/edit/${id}`)}>
      <CardHeader>
        <HeaderTitle>{name}</HeaderTitle>
        <ValuationStatusTag type={status} />
      </CardHeader>
      <CardBody>
        {!categorize ? (
          <SelectCategory onSelect={value => onSelectCategory(id, value)} />
        ) : (
          <CardBodyContent {...props} />
        )}
      </CardBody>
    </Card>
  );
};

const CardBodyContent: FC<Props> = ({
  id,
  formProgress,
  categorize,
  status,
  suggestedMinimumThreshold,
  totalAssetValue,
  onSelectCategory,
}) => {
  const { push } = useHistory();
  const isShowResult = status === ValuationStatusEnum.Completed;
  const isShowPropertyBtn =
    status !== ValuationStatusEnum.Completed || categorize === 'Property';
  const isShowOrganizationBtn =
    status !== ValuationStatusEnum.Completed || categorize === 'Organization';

  return (
    <>
      <CategoryTitle>Category</CategoryTitle>
      <CategoryList>
        {isShowPropertyBtn && (
          <Btn
            handleClick={() => onSelectCategory(id, 'Property')}
            modifiers={categorize === 'Property' ? `primary` : 'lightBlue'}
            title="Property"
          />
        )}
        {isShowOrganizationBtn && (
          <Btn
            handleClick={() => onSelectCategory(id, 'Organization')}
            modifiers={categorize === 'Organization' ? `primary` : 'lightBlue'}
            title="Organization"
          />
        )}
      </CategoryList>
      <ValuationBody>
        {isShowResult ? (
          <>
            <Explain>
              Accelerist&apos;s proprietary asset valuation has calculated your suggested
              minimum threshold, assets, and opportunities for growth. These will serve as
              crucial pieces of information for you and your potential partners when
              pitching and evaluating opportunities.
            </Explain>
            <ValueBlockWrapper>
              <ValueBlock>
                <ValueBlockTitle>Suggested Minimum Threshold</ValueBlockTitle>
                <ValueBlockAmount>
                  {moneyFormatter.from(Number(suggestedMinimumThreshold), {
                    symbol: '$',
                  })}
                </ValueBlockAmount>
              </ValueBlock>
              <ValueBlock>
                <ValueBlockTitle>Total Asset Value</ValueBlockTitle>
                <ValueBlockAmount>
                  {moneyFormatter.from(Number(totalAssetValue), { symbol: '$' })}
                </ValueBlockAmount>
              </ValueBlock>
            </ValueBlockWrapper>
          </>
        ) : (
          <div>
            <CircleThinChart value={formProgress} />
          </div>
        )}
      </ValuationBody>

      {status === ValuationStatusEnum.CustomerDataRequired ? (
        <CardFooterBtn
          modifiers="third"
          title="Enter Details"
          handleClick={e => {
            e.stopPropagation();
            push(`/pricing/edit/${id}`);
          }}
        />
      ) : (
        <CardFooterBtn
          modifiers="third"
          title="View Asset Valuation"
          disabled={status === ValuationStatusEnum.PendingAdminReview}
          handleClick={e => {
            e.stopPropagation();
            push(`/pricing/report/${id}`);
          }}
        />
      )}
    </>
  );
};

const SelectCategory: FC<SelectCategoryProps> = ({ onSelect }) => (
  <SelectCategoryWrapper>
    <SelectCategoryExplain>
      <div>Before start, please select a category</div>
      <div>
        <Btn
          modifiers="lightBlue"
          title="Property"
          handleClick={() => onSelect('Property')}
        />
        <Btn
          modifiers="lightBlue"
          title="Organization"
          handleClick={() => onSelect('Organization')}
        />
      </div>
    </SelectCategoryExplain>
  </SelectCategoryWrapper>
);

const Card = styled.div`
  width: 565px;
  height: 338px;
  background-color: #fff;
  padding: 0 25px;
  border-radius: 6px;
  cursor: pointer;
`;

const CardHeader = styled.div`
  display: flex;
  height: 55px;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 8px;
  border-bottom: 2px solid #e8e8e8;
`;

const HeaderTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  display: flex;
`;

const CardBody = styled.div`
  font-size: 12px;
  font-weight: 400;
  height: calc(100% - 72px);
`;

const CategoryTitle = styled.div`
  color: var(--darkGray);
  margin: 8px 0;
`;

const CategoryList = styled.div``;

const Btn = styled(UIButton)`
  width: max-content;
  height: 30px;
  font-size: inherit;
  font-weight: inherit;
  padding: 6px 10px;

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const ValuationBody = styled.div`
  margin-top: 12px;
  height: 140px;
`;
const Explain = styled.p`
  height: 62px;
  line-height: 18px;
  color: #122434;
`;

const ValueBlockWrapper = styled.div`
  display: flex;
  height: 70px;
  margin-top: 8px;
`;

const ValueBlock = styled.div`
  background-color: #f9f9f9;
  width: 254px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const ValueBlockTitle = styled.div`
  color: var(--darkGray);
  line-height: 1.5;
`;

const ValueBlockAmount = styled.div`
  color: var(--black);
  font-weight: 500;
  font-size: 24px;
  line-height: 1.5;
`;

const CardFooterBtn = styled(UIButton)`
  width: 100%;
  margin-top: 12px;
  height: 36px;
  padding: 6px 0;
`;

const SelectCategoryWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SelectCategoryExplain = styled.div`
  color: var(--darkGray);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export default ValuationCard;
