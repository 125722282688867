/* eslint-disable react-hooks/exhaustive-deps */
import Jumbotron from 'components/Jumbotron';
import ROIList from 'components/roi/ROIList';
import ROISearch from 'components/roi/ROISearch';
import { useModal, useOperation } from 'hooks/common';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  fetchROIDashboard,
  fetchROIDelete,
  fetchROIList,
  fetchROISdgGoals,
  fetchROISurveyStatistics,
} from 'store/ducks/roi/operations';
import {
  getROIDashboard,
  getROIList,
  getROISdgGoals,
  getROISurveyStatistics,
} from 'store/ducks/roi/selectors';
import { fetchTeamUpdate } from 'store/ducks/team/operations';
import { getTeam } from 'store/ducks/team/selectors';
import styled from 'styled-components';
import { SetupThresholdAmountModal } from 'components/roi/modals';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { ROIListReq } from 'interfaces/roi';
import NavigationBar from 'components/NavigationBar';
import { ROINavigationEnum } from 'constants/roi';
import ROIDashboard from 'components/roi/ROIDashboard';

const ROI = () => {
  const [selectedROI, setSelectedROI] = useState<string[]>([]);
  const [timerRange, setTimerRange] = useState<string>('');
  const [q, setQ] = useState<string>('');

  const { location, push } = useHistory();

  const [onFetchROIList, isloading] = useOperation(fetchROIList);
  const [onFetchTeamUpdate] = useOperation(fetchTeamUpdate);
  const [onFetchROIDelete] = useOperation(fetchROIDelete);
  const [onFetchROIDashboard] = useOperation(fetchROIDashboard);
  const [onFetchROISdgGoals] = useOperation(fetchROISdgGoals);
  const [onFetchROISurveyStatistics] = useOperation(fetchROISurveyStatistics);

  const { items, meta } = useSelector(getROIList);
  const team = useSelector(getTeam);
  const dashboardROI = useSelector(getROIDashboard);
  const roiSdgGoals = useSelector(getROISdgGoals);
  const roiSurveyStatistics = useSelector(getROISurveyStatistics);

  const search = queryString.parse(window.location.search) as { type: ROINavigationEnum };

  const handleSearch = useCallback((values: ROIListReq) => {
    onFetchROIList(values);
    push(
      `${location.pathname}?${queryString.stringify({
        ...values,
        type: ROINavigationEnum.Partnerships,
      })}`,
    );
  }, []);

  const [showSetupThresholdAmountModal, hideSetupThresholdAmountModal] = useModal(() => (
    <SetupThresholdAmountModal
      handleAccess={({ minimumCommitment }) => {
        if (!team) return;
        onFetchTeamUpdate({
          ...team,
          charitablePartners: team.charitablePartners
            ? team.charitablePartners.map(partner => partner.id)
            : [],
          minimumCommitment,
        });
        hideSetupThresholdAmountModal();
      }}
      onClose={hideSetupThresholdAmountModal}
    />
  ));

  useEffect(() => {
    if (team && Object.keys(team).length && !team.minimumCommitment) {
      showSetupThresholdAmountModal();
    }
  }, [team]);

  useEffect(() => {
    const operation = queryString.parse(location.search)?.operation?.toString() || '';
    const timerRange = queryString.parse(location.search)?.timerRange?.toString() || '';
    const q = queryString.parse(location.search)?.q?.toString() || '';
    const pageString = queryString.parse(location.search)?.page?.toString() || '';
    setTimerRange(timerRange);
    setQ(q);
    onFetchROIList({ page: Number(pageString) || 1, q, operation, timerRange });
    onFetchROIDashboard(undefined);
    onFetchROISdgGoals(undefined);
    onFetchROISurveyStatistics(undefined);
  }, []);

  return (
    <ContentBlockWrapper
      header={<Jumbotron name="ROI Measurement and Reporting" />}
      loading={isloading}
    >
      <Body>
        <NavigationBar<ROINavigationEnum>
          list={[
            { label: 'Partnerships', value: ROINavigationEnum.Partnerships },
            { label: 'Dashboard', value: ROINavigationEnum.Dashboard },
          ]}
          queryKey="type"
          activeTab={ROINavigationEnum.Partnerships}
        />
        {(!search.type || search.type === ROINavigationEnum.Partnerships) && (
          <>
            <ROISearch
              type="organization"
              onSearch={handleSearch}
              meta={meta}
              timerRange={timerRange}
              searchValue={q}
              onChangeTimerRange={setTimerRange}
              onChangeSearchValue={setQ}
              onFetchROIList={onFetchROIList}
            />
            <ROIList
              type="organization"
              meta={meta}
              roiList={items}
              selectedROI={selectedROI}
              timerRange={timerRange}
              searchValue={q}
              onROIDelete={onFetchROIDelete}
              onROISelect={ids => setSelectedROI(ids)}
              onSearch={handleSearch}
            />
          </>
        )}
        {search.type === ROINavigationEnum.Dashboard && (
          <ROIDashboard
            dashboardROI={dashboardROI}
            roiSdgGoals={roiSdgGoals}
            roiSurveyStatistics={roiSurveyStatistics}
          />
        )}
      </Body>
    </ContentBlockWrapper>
  );
};

const Body = styled.div``;

export default ROI;
