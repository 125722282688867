import styled from 'styled-components';
import { colorIsLight } from 'utils';

export const MetricRoot = styled.div<{ $themeColor?: string }>`
  width: 352px;
  height: 218px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  background-color: ${({ $themeColor }) => $themeColor || '#fff'};
  display: flex;
  flex-direction: column;
  color: ${({ $themeColor }) =>
    $themeColor && (colorIsLight($themeColor) ? 'black' : 'white')};
`;

export const MetricTitle = styled.div<{ $themeColor?: string }>`
  height: 38px;
  border-bottom: 1px solid #e8e8e8;
  font-weight: 500;
  font-size: 14px;
  padding-left: 14px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  background-color: ${({ $themeColor }) => $themeColor || 'transparent'};
  color: ${({ $themeColor }) =>
    $themeColor && (colorIsLight($themeColor) ? 'black' : 'white')};
`;

export const MetricContent = styled.div`
  padding: 14px;
  flex: 1;
  overflow: hidden;
`;
