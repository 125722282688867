import React, { useEffect, useMemo, useState } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import styled from 'styled-components';
import { composeValidators } from 'utils';
import { integerValidator, required, webSiteLink } from 'utils/validation';
import History from 'services/History';
import {
  FieldTextInput,
  MaskedTextInput,
  MaskedMoneyTextInput,
  FieldCheckBoxGroup,
  FieldCheckBoxGroupById,
  FieldInputArray,
  FieldTextArea,
  FieldSuggest,
  FieldSingleSelect,
} from 'components/FormFields';
import { UIActionText, UIButton } from 'components/UI';

import { phoneNumberMaskedProps } from 'utils/formatters';

import { SearchCompany, SuggestCompany } from 'interfaces/companies';
import { OnSubmitReturnType } from 'interfaces/finalForm';

import { CRSFocus } from 'store/interfaces';

import { goalAlignmentOptions } from 'constants/goalAlignment';
import { typesOfInvestmentOptions } from 'constants/typesOfInvestment';
import UploadProfilePhoto from 'components/UploadProfilePhoto';
import { Field } from 'components/FinalForm';
import { SelectOption, UISuggestOption } from 'components/UI/interfaces';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import CloseIcon from 'assets/images/icons/close.svg';
import { FormArrayDeleteIcon } from 'components/UI/form';
import { SocialMediaUrlsTypeOptions } from 'constants/socialMediaTypes';

export interface FormProps {
  onSubmit: (values: SearchCompany) => OnSubmitReturnType;
  initialValues?: Partial<SearchCompany>;
  isLoading?: boolean;
  crsFocus: CRSFocus[];
  companiesSearchList: SuggestCompany[];
  industries: SelectOption[];
  handleGetCompanies: (val: string) => void;
  handleUploadLogo?: (file: File) => Promise<string | number | void>;
}

const CompanyProfileForm = ({
  onSubmit,
  initialValues,
  crsFocus,
  companiesSearchList,
  industries,
  handleGetCompanies,
  handleUploadLogo,
}: FormProps) => {
  const [suggestIndustries, setSuggestIndustries] = useState<UISuggestOption[]>([]);

  const readonlySuggestIndustries = useMemo(
    () =>
      industries.map(item => ({
        name: item.label,
        id: item.value || '',
      })),
    [industries],
  );

  useEffect(() => {
    setSuggestIndustries(readonlySuggestIndustries.slice(0, 12));
  }, [readonlySuggestIndustries]);

  return (
    <Form
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        submitting,
        form,
        values,
      }: FormRenderProps<SearchCompany>) => {
        return (
          <form>
            {initialValues?.id && handleUploadLogo ? (
              <Header>
                <UploadProfilePhoto
                  handleUpload={handleUploadLogo}
                  imgKey={initialValues?.logo}
                />
              </Header>
            ) : null}

            <Body>
              <FieldSection marginBottom="8px">
                <TitleSection>Company Info</TitleSection>
                <Row>
                  <Field
                    name="name"
                    label="Name"
                    component={FieldTextInput}
                    placeholder="Enter name"
                    validate={required}
                  />
                  <Field
                    name="phone"
                    label="Phone"
                    mask={phoneNumberMaskedProps.mask}
                    component={MaskedTextInput}
                    placeholder="Enter phone"
                  />
                </Row>

                <Row>
                  <Field
                    name="website"
                    label="Website"
                    component={FieldTextInput}
                    placeholder="Enter website"
                    validate={composeValidators<string>(required, webSiteLink)}
                  />

                  <Field
                    name="street"
                    label="Street"
                    component={FieldTextInput}
                    placeholder="Enter street"
                  />
                </Row>

                <Row>
                  <Field
                    name="city"
                    label="City"
                    component={FieldTextInput}
                    placeholder="Enter city"
                  />

                  <Field
                    name="state"
                    label="State"
                    component={FieldTextInput}
                    placeholder="Enter state"
                  />
                </Row>
                <Row>
                  <Field
                    name="country"
                    label="Country"
                    component={FieldTextInput}
                    placeholder="Enter country"
                  />
                  <Field
                    name="zipCode"
                    label="Postal Code"
                    component={FieldTextInput}
                    placeholder="Enter Postal Code"
                  />
                </Row>
                {/* <Row>
                  <Field
                    name="continent"
                    label="Continent"
                    component={FieldTextInput}
                    placeholder="Enter continent"
                  />

                  <Field
                    name="fax"
                    label="Fax"
                    placeholder="Enter fax"
                    mask={phoneNumberMaskedProps.mask}
                    component={MaskedTextInput}
                  />
                </Row> */}
                <Row>
                  <Field
                    name="revenue"
                    label="Revenue Reported"
                    component={FieldTextInput}
                    placeholder="Enter Revenue Reported"
                    validate={integerValidator}
                    parse={v => v || '0'}
                    format={(v: string) =>
                      v?.length > 1 && v.startsWith('0') ? v.slice(1) : v
                    }
                  />

                  <Field
                    name="employeeCount"
                    label="Employees Reported"
                    placeholder="Enter Employees Reported"
                    component={FieldTextInput}
                    validate={integerValidator}
                    parse={v => v || '0'}
                    format={(v: string) =>
                      v?.length > 1 && v.startsWith('0') ? v.slice(1) : v
                    }
                  />
                </Row>
                <Row>
                  <Field
                    name="ticker"
                    label="Company Ticker"
                    component={FieldTextInput}
                    placeholder="Enter Company Ticker"
                  />
                  <Field
                    name="twitterHandle"
                    label="Twitter Handle"
                    component={FieldTextInput}
                    placeholder="Enter Twitter handle"
                  />
                </Row>
                <Row>
                  <Field
                    name="competitors"
                    label="Similar Companies"
                    component={FieldSuggest}
                    handleGetSuggestions={handleGetCompanies}
                    placeholder="Search"
                    list={companiesSearchList}
                    parse={v =>
                      v.map((item, i) => ({
                        employeeCount: item.employeeCount,
                        id: Number(item.zoomInfoId || item.id),
                        name: item.name,
                        rank: item.rank || i,
                        website: item.website,
                      }))
                    }
                  />
                  <Field
                    name="industries"
                    label="Industry"
                    component={FieldSuggest}
                    handleGetSuggestions={(v: string) => {
                      setSuggestIndustries(
                        readonlySuggestIndustries
                          .filter(item => item.name.toLowerCase().includes(v))
                          .slice(0, 12),
                      );
                    }}
                    placeholder="Search"
                    list={suggestIndustries}
                    parse={(v: UISuggestOption[]) => v.map(item => item.name)}
                    format={(v: string[]) =>
                      v ? v.map(item => ({ name: item, id: item })) : []
                    }
                  />
                </Row>
                <Row>
                  <Field
                    name="parentName"
                    label="Parent Company"
                    component={FieldSuggest}
                    handleGetSuggestions={handleGetCompanies}
                    placeholder="Search"
                    isMulti={false}
                    list={companiesSearchList}
                    parse={v =>
                      Array.isArray(v) && v.length ? v[v.length - 1].name : ''
                    }
                    format={v => v && [{ name: v, id: 1 }]}
                  />
                </Row>
                <Field
                  name="descriptionList"
                  label="Description"
                  format={v => {
                    if (Array.isArray(v)) {
                      return v.map(item => item.description).join('\n\n');
                    }
                    return v;
                  }}
                  parse={(v: string) => {
                    const parseValue = v.split('\n\n');
                    if (parseValue.length === 1) {
                      return parseValue[0];
                    }
                    return parseValue.map(item => ({
                      description: item,
                    }));
                  }}
                  component={FieldTextArea}
                />
                <Field
                  name="productsBrandDescription"
                  label="Products & Brand Descriptions"
                  component={FieldTextArea}
                />
              </FieldSection>

              <FieldSection>
                <TitleSection>Corporate Foundation</TitleSection>
                <Row>
                  <Field
                    name="corporateFoundation"
                    label="Corporate Foundation"
                    component={FieldSingleSelect}
                    defaultValue={false}
                    options={[
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' },
                    ]}
                  />
                </Row>
                {values.corporateFoundation && (
                  <>
                    <Row>
                      <Field
                        name="foundationName"
                        label="Foundation Name"
                        component={FieldTextInput}
                      />
                    </Row>
                    <Row>
                      <Field
                        name="foundationDescription"
                        label="Description"
                        component={FieldTextArea}
                        placeholder="Describe the Foundation's guidelines for grant submission and timeline requirements."
                      />
                    </Row>
                  </>
                )}
              </FieldSection>

              <FieldSection>
                <TitleSection>Social Media</TitleSection>
                <FieldArray name="socialMediaUrls">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <div key={index} style={{ position: 'relative' }}>
                        <div style={{ display: 'none' }}>
                          <Field
                            name={`${name}.followerCount`}
                            component={FieldTextInput}
                            validate={integerValidator}
                          />
                        </div>
                        <Row>
                          <Field
                            name={`${name}.type`}
                            label={`Media Type`}
                            component={FieldSingleSelect}
                            options={SocialMediaUrlsTypeOptions}
                            isClearable={false}
                          />
                          <Field
                            name={`${name}.url`}
                            label={`Media url`}
                            component={FieldTextInput}
                            validate={webSiteLink}
                          />
                        </Row>
                        <DeleteBtn src={CloseIcon} onClick={() => fields.remove(index)} />
                      </div>
                    ))
                  }
                </FieldArray>
                <ActionText
                  onClick={() => form.mutators.push('socialMediaUrls', undefined)}
                >
                  Add Social Media
                </ActionText>
              </FieldSection>

              <FieldSection>
                <TitleSection>Types Of Investment</TitleSection>
                <Field
                  name="typesOfInvestment"
                  component={FieldCheckBoxGroup}
                  options={typesOfInvestmentOptions}
                />
              </FieldSection>

              <FieldSection>
                <TitleSection>CSR Focus</TitleSection>
                <Field
                  name="crsFocus"
                  component={FieldCheckBoxGroupById}
                  options={crsFocus}
                />
              </FieldSection>

              <FieldSection>
                <TitleSection>Seeking Opportunities</TitleSection>
                <Field
                  name="seekingOpportunities"
                  label="Seeking Opportunities"
                  format={v => v && v.lenght && v[0].text}
                  parse={v => v && [{ text: v }]}
                  component={FieldTextArea}
                />
              </FieldSection>

              <FieldSection>
                <TitleSection>Impact Goals</TitleSection>
                <Field
                  name="impactGoals"
                  label="Impact Goals"
                  format={v => v && v.lenght && v[0].text}
                  parse={v => v && [{ text: v }]}
                  component={FieldTextArea}
                />
              </FieldSection>

              <FieldSection>
                <TitleSection>SDG Goal Alignment</TitleSection>
                <Field
                  name="sdgGoals"
                  component={FieldCheckBoxGroup}
                  options={Object.values(goalAlignmentOptions)}
                  parse={value => value.map(({ value: sdg }) => sdg)}
                  format={value =>
                    value?.map(sdg => goalAlignmentOptions[sdg])?.filter(item => item) ||
                    []
                  }
                />
              </FieldSection>

              {/* <FieldSection>
                <TitleSection>Charitable Focus & Programs</TitleSection>
                <Row>
                  <Field
                    name="partnershipLink"
                    parse={identityField}
                    label="Enter charitable focus link"
                    component={FieldTextInput}
                    validate={webSiteLink}
                  />
                </Row>
              </FieldSection> */}

              <FieldSection marginBottom="8px">
                <TitleSection>Contributions</TitleSection>
                <Row>
                  <Field
                    name="cashContributions"
                    label="Cash Contributions"
                    component={MaskedMoneyTextInput}
                    placeholder="Enter cash contributions"
                  />

                  <Field
                    name="employeeContributions"
                    label="Employee Contributions"
                    component={MaskedMoneyTextInput}
                    placeholder="Enter employee contributions"
                  />
                </Row>

                <Row>
                  <Field
                    name="annualContributions"
                    label="Total Social Contributions"
                    component={MaskedMoneyTextInput}
                    placeholder="Enter cash contributions"
                  />

                  <Field
                    name="inKindContributions"
                    label="In Kind Contributions"
                    component={MaskedMoneyTextInput}
                    placeholder="Enter employee contributions"
                  />
                </Row>
              </FieldSection>

              {/* <FieldSection>
                <TitleSection>GRI Compliant</TitleSection>
                <Field
                  name="employeeEngagementOpportunities"
                  component={FieldRadioButtons}
                />
              </FieldSection> */}

              <FieldSection>
                <TitleSection>Charitable partners</TitleSection>
                <Field
                  name="charitablePartners"
                  label="Partners"
                  component={FieldInputArray}
                  placeholder="Search"
                />
              </FieldSection>

              <BtnGroup>
                <UIButton
                  handleClick={() => History.back()}
                  modifiers="secondary"
                  title="Cancel"
                />
                <UIButton
                  title="Save"
                  modifiers="primary"
                  handleClick={handleSubmit}
                  type="submit"
                  disabled={submitting}
                  isLoading={submitting}
                />
              </BtnGroup>
            </Body>
          </form>
        );
      }}
    />
  );
};

const BtnGroup = styled.div`
  display: flex;
  margin-top: 40px;

  button:first-child {
    margin-right: 8px;
  }

  & button {
    width: 136px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    width: calc(50% - 8px);
  }
`;

const FieldSection = styled.div<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '32px'};
`;

const TitleSection = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  margin-bottom: 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f2f2f2;
  padding: 40px;
  align-items: center;
`;

const Body = styled.div`
  background: #fff;
  padding: 40px;
  margin-bottom: 40px;
`;

const ActionText = styled(UIActionText)`
  margin-bottom: 24px;
`;

const DeleteBtn = styled(FormArrayDeleteIcon)`
  cursor: pointer;
  position: absolute;
  right: -28px;
  top: 30px;
`;

export default CompanyProfileForm;
