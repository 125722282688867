import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { NoSubscriptionsWrapper } from 'layouts';

import NoSubscription from 'pages/organizationUser/subscription';

import Route from './Route';

const NoSubscriptionsRoute = () => {
  return (
    <Switch>
      <Route path="/" layout={NoSubscriptionsWrapper}>
        <Route path="/no-subscription" component={NoSubscription} />
        <Route path="/" component={() => <Redirect to="/no-subscription" />} />
      </Route>
    </Switch>
  );
};

export default NoSubscriptionsRoute;
