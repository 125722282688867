import EditIcon from 'assets/images/icons/edit.svg';
import Jumbotron from 'components/Jumbotron/Jumbotron';
import { UIActionButton, UIButton } from 'components/UI';
import React, { FC, useEffect, useRef, useState, ReactText } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import ArrowIcon from 'assets/images/icons/arrow-left.svg';
import { useHistory } from 'react-router';
import History from 'services/History';
import { PAGE_WIDTH } from 'constants/common';

interface Props {
  name?: string;
  isEdit?: boolean;
  onSave: (value: string) => void | string | ReactText;
  onEdit?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
  disableDeleteBtn?: boolean;
  back?: boolean;
  backPath?: string;
  maxWidth?: string;
  editBtnName?: string;
  isShowEdit?: boolean;
  newName?: (value: string) => void | string | ReactText;
}

const JumbotronEditTitle: FC<Props> = ({
  name = '',
  isEdit = false,
  onSave,
  onEdit,
  onCancel,
  onDelete = () => {},
  disableDeleteBtn,
  back,
  backPath,
  maxWidth,
  editBtnName,
  isShowEdit = true,
  newName = () => name,
}) => {
  const [isActiveEdit, setIsActiveEdit] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [title, setTitle] = useState<string>(name);

  const { push } = useHistory();

  const editHandler = () => {
    if (isActiveEdit) {
      const isError = onSave(title);
      if (isError) return;

      return setIsActiveEdit(false);
    }
    onEdit && onEdit();
    return setIsActiveEdit(true);
  };

  const editInputValue = () => {
    setIsActiveEdit(true);
  };

  const cancelHandler = () => {
    setIsActiveEdit(false);
    onCancel && onCancel();
    setTitle(name);
  };

  useEffect(() => {
    if (!name) {
      setIsActiveEdit(true);
    } else {
      setIsActiveEdit(false);
    }
    setTitle(name);
  }, [name]);

  useEffect(() => {
    setIsActiveEdit(isEdit);
  }, [isEdit]);

  useEffect(() => {
    if (isActiveEdit) {
      inputRef.current?.focus?.();
    }
  }, [isActiveEdit]);

  return (
    <Jumbotron name="" isWithName={false} bodyMaxWidth="unset">
      <JumbotronInner maxWidth={maxWidth}>
        <Back onClick={() => (backPath ? push(backPath) : History.back())}>
          <UIActionButton
            style={{ marginRight: '16px' }}
            handleClick={() => {}}
            icon={ArrowIcon}
          />
        </Back>

        <Title>
          {/* {back && (
            <Back onClick={() => (backPath ? push(backPath) : History.back())}>
              <UIActionButton
                style={{ marginRight: '16px' }}
                handleClick={() => {}}
                icon={ArrowIcon}
              />
            </Back>
          )} */}
          {isActiveEdit ? (
            <Input
              ref={inputRef}
              value={title}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setTitle(event.target.value);
                if (!isShowEdit) {
                  newName(event.target.value);
                }
              }}
              disabled={!isActiveEdit}
              placeholder="No name"
              autoFocus
            />
          ) : isShowEdit ? (
            <InputValue>{title}</InputValue>
          ) : (
            <InputValue onClick={editInputValue}>{title}</InputValue>
          )}
        </Title>

        <GroupBtn>
          {isShowEdit ? (
            <EditButton onClick={editHandler}>
              {!isActiveEdit ? (
                <>
                  <Icon src={EditIcon} /> {editBtnName || 'Edit'}
                </>
              ) : (
                'Save'
              )}
            </EditButton>
          ) : (
            ''
          )}

          {isActiveEdit && isShowEdit ? (
            <DeleteButton
              modifiers="danger"
              title="Cancel"
              handleClick={cancelHandler}
              disabled={!title}
            />
          ) : (
            disableDeleteBtn || (
              <DeleteButton modifiers="danger" title="Delete" handleClick={onDelete} />
            )
          )}
        </GroupBtn>
      </JumbotronInner>
    </Jumbotron>
  );
};

const Title = styled.div`
  display: flex;
`;

const GroupBtn = styled.div`
  display: flex;
`;

const Icon = styled(ReactSVG)`
  margin-right: 9px;
`;

const EditButton = styled.button`
  border-radius: 6px;
  border: 1px solid #2baee0;
  transition: all 0.2s ease;
  padding: 8px 12px;
  min-width: 82px;
  color: var(--black);
  font-size: 12px;
  line-height: 150%;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #ebf9ff;
    color: #2baee0;
  }

  &:active {
    background: #caf0ff;
    color: #2baee0;
  }

  &:disabled {
    background: #ceedf9;
    border-color: rgba(43, 174, 224, 0.3);
    opacity: 0.5;
    color: #2baee0;
    cursor: not-allowed;
  }
`;

const DeleteButton = styled(UIButton)`
  width: 76px;
`;

const JumbotronInner = styled.div<{ maxWidth?: string }>`
  width: ${({ maxWidth = `${PAGE_WIDTH.NORMAL}px` }) => maxWidth};

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Input = styled.input`
  width: 920px;
  height: 48px;
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  border: none;
  color: var(--black);

  &:disabled {
    color: var(--black);
    background: transparent;
    text-overflow: ellipsis;
    pointer-events: none;
  }

  &:-webkit-input-placeholder {
    color: var(--darkGray);
  }

  &:-moz-placeholder {
    color: var(--darkGray);
  }

  &:-ms-input-placeholder {
    color: var(--darkGray);
  }

  &:-moz-placeholder {
    color: var(--darkGray);
  }
`;

const InputValue = styled.p`
  width: 920px;
  height: 48px;
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  border: none;
  color: var(--black);
  padding: 0px 2px;
  cursor: pointer;
`;

const Back = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export default JumbotronEditTitle;
