import React, { ChangeEvent, InputHTMLAttributes, useState } from 'react';
import styled from 'styled-components';

import AttachmentIcon from 'assets/images/icons/attachment.svg';
import CloseIcon from 'assets/images/icons/close.svg';
import { ReactSVG } from 'react-svg';

interface Props {
  name?: string;
  uploadHandler?: (files: File[]) => void;
}

const AddAttachmentsButton = ({
  name = 'Attach',
  uploadHandler,
  ...rest
}: Props & InputHTMLAttributes<HTMLInputElement>) => {
  const [attaches, setAttaches] = useState<File[]>([]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    const files = Array.from(event.target.files);

    setAttaches(files);
    uploadHandler?.(files);
    event.target.value = '';
  };

  const deleteAttachmentHandler = (index: number) =>
    setAttaches(attaches.filter((_, inx) => index !== inx));

  return (
    <>
      <Input id="file" multiple onChange={handleChange} type="file" {...rest} />
      <Label htmlFor="file">
        <Icon src={AttachmentIcon} />
        {name}
      </Label>
      {attaches.length > 0 && (
        <Attachments>
          {attaches.map(({ name }, index) => (
            <Attachment key={index}>
              {name}
              <button onClick={() => deleteAttachmentHandler(index)} type="button">
                <SecondIcon src={CloseIcon} />
              </button>
            </Attachment>
          ))}
        </Attachments>
      )}
    </>
  );
};

const Attachment = styled.span`
  display: inline-flex;
  border: 1px solid #caf0ff;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  margin: 0 9px 9px 0;
`;

const Attachments = styled.article`
  margin-bottom: 31px;
`;

const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  display: inline-block;
`;

const Icon = styled(ReactSVG)`
  margin-right: 9px;
`;

const SecondIcon = styled(ReactSVG)`
  margin-left: 7px;

  & svg {
    width: 10px;
    height: 10px;
  }
`;

const Label = styled.label`
  display: inline-flex;
  width: 96px;
  height: 36px;
  background: var(--secondaryBlue);
  border-radius: 6px;
  transition: all 0.2s ease;
  align-items: center;
  justify-content: center;
  color: var(--black);
  font-size: 12px;
  line-height: 150%;
  margin-bottom: 24px;

  &:hover {
    cursor: pointer;
    text-shadow: 0 0 0.01px var(--black), 0 0 0.01px var(--black);
  }
`;

export default AddAttachmentsButton;
