import { ROIStoreData } from 'interfaces/roi';
import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as types from './types';

type RootAction = ActionType<typeof actions>;

export default createReducer<ROIStoreData, RootAction>({
  roiList: {
    items: [],
    meta: {
      currentPage: '',
      itemCount: 0,
      itemsPerPage: '',
      totalItems: 0,
      totalPages: 0,
    },
  },
  roi: undefined,
  roiTemplates: [],
  templatePreview: '',
  roiReport: undefined,
  roiMeltwaterData: undefined,
  roiDashboard: undefined,
  roiSuggest: [],
  roiSdgGoals: undefined,
  roiSurveys: [],
  roiSurveyResult: undefined,
  roiOld: undefined,
  roiCanvaTemplates: {
    items: [],
    meta: {
      currentPage: '',
      itemCount: 0,
      itemsPerPage: '',
      totalItems: 0,
      totalPages: 0,
    },
  },
  roiCanvaTemplatesPrivate: {
    items: [],
    meta: {
      currentPage: '',
      itemCount: 0,
      itemsPerPage: '',
      totalItems: 0,
      totalPages: 0,
    },
  },
  roiSurveyStatistics: undefined,
  roiSurveyList: {
    items: [],
    meta: {
      currentPage: '',
      itemCount: 0,
      itemsPerPage: '',
      totalItems: 0,
      totalPages: 0,
    },
  },
  roiSurveyStatistic: undefined,
})
  .handleType([types.SET_ROI_LIST], (state, action) => ({
    ...state,
    roiList: action.payload,
  }))
  .handleType([types.CREATE_ROI], (state, action) => ({
    ...state,
    roiList: {
      items: [action.payload, ...state.roiList.items],
      meta: { ...state.roiList.meta, totalItems: state.roiList.meta.totalItems + 1 },
    },
  }))
  .handleType([types.DELETE_ROI], (state, action) => ({
    ...state,
    roiList: {
      items: state.roiList.items.filter(item => !action.payload.includes(item.id)),
      meta: {
        ...state.roiList.meta,
        totalItems: state.roiList.meta.totalItems - action.payload.length,
      },
    },
  }))
  .handleType([types.SET_ROI], (state, action) => ({
    ...state,
    roi: action.payload,
    roiList: {
      ...state.roiList,
      items: state.roiList.items.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      }),
    },
  }))
  .handleType([types.SET_ROI_TEMPLATES], (state, action) => ({
    ...state,
    roiTemplates: action.payload,
  }))
  .handleType([types.SET_ROI_TEMPLATE_PREVIEW], (state, action) => ({
    ...state,
    templatePreview: action.payload,
  }))
  .handleType([types.SET_ROI_TEMPLATES_ITEM], (state, action) => ({
    ...state,
    roiTemplates: state.roiTemplates.map(item =>
      item.id === action.payload.id ? action.payload : item,
    ),
  }))
  .handleType([types.TOGGLE_ROI_TEMPLATES_DISABLE], (state, action) => ({
    ...state,
    roiTemplates: state.roiTemplates.map(item =>
      item.id === action.payload ? { ...item, disabled: !item.disabled } : item,
    ),
  }))
  .handleType([types.SET_ROI_REPORT], (state, action) => ({
    ...state,
    roiReport: action.payload,
  }))
  .handleType([types.SET_ROI_MELTWATER_DATA], (state, action) => ({
    ...state,
    roiMeltwaterData: action.payload,
  }))
  .handleType([types.SET_ROI_DASHBOARD], (state, action) => ({
    ...state,
    roiDashboard: action.payload,
  }))
  .handleType([types.SET_ROI_SUGGEST], (state, action) => ({
    ...state,
    roiSuggest: action.payload,
  }))
  .handleType([types.SET_ROI_SDGGOALS], (state, action) => ({
    ...state,
    roiSdgGoals: action.payload,
  }))
  .handleType([types.SET_ROI_SURVEY], (state, action) => ({
    ...state,
    roiSurveys: state.roiSurveys.map(item =>
      item.type === action.payload.type ? action.payload : item,
    ),
  }))
  .handleType([types.SET_ROI_SURVEYS], (state, action) => ({
    ...state,
    roiSurveys: action.payload,
  }))
  .handleType([types.SET_ROI_SURVEY_RESULT], (state, action) => ({
    ...state,
    roiSurveyResult: action.payload,
  }))
  .handleType([types.SET_ROI_OLD], (state, action) => ({
    ...state,
    roiOld: action.payload,
  }))
  .handleType([types.SET_ROI_CANVA], (state, action) => ({
    ...state,
    roi: state.roi && {
      ...state.roi,
      ROICanva: state.roi?.ROICanva?.map(item => {
        if (item.id === action.payload.id)
          return { ...item, title: action.payload.title };
        return item;
      }),
    },
  }))
  .handleType([types.DELETE_ROI_CANVA], (state, action) => ({
    ...state,
    roi: state.roi && {
      ...state.roi,
      ROICanva: state.roi.ROICanva?.filter(item => item.id !== action.payload),
    },
  }))
  .handleType([types.SET_ROI_CANVA_TEMPLATE], (state, action) => ({
    ...state,
    roiCanvaTemplates: action.payload,
  }))
  .handleType([types.SET_ROI_CANVA_TEMPLATE_PRIVATE], (state, action) => ({
    ...state,
    roiCanvaTemplatesPrivate: action.payload,
  }))
  .handleType([types.SET_ROI_SURVEY_STATISTICS], (state, action) => ({
    ...state,
    roiSurveyStatistics: action.payload,
  }))
  .handleType([types.SET_ROI_SURVEY_LIST], (state, action) => ({
    ...state,
    roiSurveyList: action.payload,
  }))
  .handleType([types.SET_ROI_SURVEY_STATISTIC_BY_ID], (state, action) => ({
    ...state,
    roiSurveyStatistic: action.payload,
  }))
  .handleType([types.DELETE_ROI_SURVEY_STATISTIC], (state, action) => ({
    ...state,
    roiSurveyList: {
      ...state.roiSurveyList,
      items: state.roiSurveyList.items.filter(
        item => !action.payload.split(',').includes(item.id),
      ),
    },
  }));
