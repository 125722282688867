import styled from 'styled-components';

export const UITableWrapper = styled.div`
  overflow: auto;
  margin-bottom: 30px;
`;

export const UITable = styled.table`
  width: 100%;
`;

export const UITableHead = styled.thead`
  background: #f4f4f4;
`;

export const UITableHeadRow = styled.tr`
  height: 39px;
`;

export const UITableBody = styled.tbody`
  background: #ffffff;
`;

export const UITableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);
  vertical-align: middle;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-right: 24px;
  }
`;

export const UITableRow = styled.tr`
  height: 100%;
`;

export const UITableRowCell = styled.td<{
  isAddWeight?: boolean;
  isNoAddBorder?: boolean;
}>`
  box-sizing: border-box;
  padding: 20px 6px 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }

  ${({ isAddWeight }) => isAddWeight && 'font-weight: 500;'}
  ${({ isNoAddBorder }) => isNoAddBorder && 'border-bottom: none;'}
`;
