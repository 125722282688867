import { CropText } from 'components/UI';
import { TemplateEmailPreview, TemplatePreview } from 'interfaces/common';
import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useModal, useOperation } from 'hooks/common';
import {
  deleteEmailCustomizeTemplate,
  fetchCustomizeEmailTemplateSave,
  fetchEmailCustomizeTemplate,
} from 'store/ducks/gameplan/operations';
import { ConfirmModal } from 'components/Modals';
import CustomizeEmailTemplateModalEdit from './CustomizeEmailTemplateModalEdit';
import { GamePlanEmailTemplateTypeEnum } from 'constants/gamePlan';
import { useSelector } from 'react-redux';
import { getEmailCustomizeTemplate } from 'store/ducks/gameplan/selectors';
import { CustomizeEmailTemplateVariables } from 'interfaces/gameplan';
import { ReactSVG } from 'react-svg';
import EllipseSVG from 'assets/images/icons/ellipse.svg';
import DropdownList from 'components/DropdownList/DropdownList';

interface Props {
  rows: TemplateEmailPreview[];
}

interface TableRowProps {
  row: TemplateEmailPreview;
  onDeleteRow: (id: string) => void;
}

const templateTableWidth = {
  oneColumn: 360,
  twoColumn: 180,
  thirdColumn: 180,
  actionColumn: 60,
} as const;

const formatDate = dateString => {
  const date = new Date(dateString);
  if (!dateString) {
    return 'N/A';
  }
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

const { oneColumn, twoColumn, thirdColumn, actionColumn } = templateTableWidth;

const TableRowWrapper = ({ row, onDeleteRow }: TableRowProps) => {
  const [onDeleteEmailTemplate, ,] = useOperation(deleteEmailCustomizeTemplate);
  const { id, name, createdAt, updatedAt } = row;
  const [showDropdown, setShowDropdown] = React.useState(false);
  const emailCustomizeTemplate = useSelector(getEmailCustomizeTemplate);

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const history = useHistory();

  const [onFetchCustomizeEmailTemplateSave] = useOperation(
    fetchCustomizeEmailTemplateSave,
  );

  const [onFetchEmailCustomizeTemplate, , isCustomizeLoading] = useOperation(
    fetchEmailCustomizeTemplate,
  );

  const [selectedEditData, setSelectedEditData] = React.useState<
    CustomizeEmailTemplateVariables | undefined
  >(undefined);

  const handleDelete = id => {
    onDeleteEmailTemplate(id)
      .then(() => {
        console.log(`Template with id: ${id} deleted successfully.`);
        onDeleteRow(id.id);
      })
      .catch(error => {
        console.error('Error deleting template:', error);
      });
  };

  const [showConfirmModal, hideConfirmModal] = useModal(() => {
    return (
      <ConfirmModal
        accessHandler={() => {
          id && handleDelete({ id: id });
          hideConfirmModal();
        }}
        cancelHandler={hideConfirmModal}
        textAccessBtn="Yes"
        textCancelBtn="No"
        name="Are you sure you want to delete this template?"
        onClose={hideConfirmModal}
      />
    );
  }, [id]);

  const [showCustomizeTemplateModal, hideCustomizeTemplateModal] = useModal(() => {
    return (
      <CustomizeEmailTemplateModalEdit
        template={emailCustomizeTemplate}
        isloading={isCustomizeLoading}
        inputValues={selectedEditData}
        onClose={hideCustomizeTemplateModal}
        onSave={values => {
          onFetchCustomizeEmailTemplateSave(values).then(() => {
            const currentPath = history.location.pathname;
            history.push('/temporary-route');
            history.replace(currentPath);
          });
        }}
      />
    );
  }, [emailCustomizeTemplate, isCustomizeLoading]);

  const handleEdit = (row: TemplateEmailPreview) => {
    const editData: CustomizeEmailTemplateVariables = {
      id: row.id,
      name: row.name,
      subject: row.subject, // Ensure these are defined in your row or have default values
      body: row.body,
    };

    // Set the selected row data
    setSelectedEditData(editData);

    onFetchEmailCustomizeTemplate({
      templateType: GamePlanEmailTemplateTypeEnum.PitchTemplate,
    });
    showCustomizeTemplateModal();
  };

  const handleDropdownListClick = (option, row) => {
    switch (option) {
      case 'edit':
        handleEdit(row);
        break;
      case 'delete':
        showConfirmModal();
        break;
      default:
        console.log('No action found for:', option);
    }
  };

  return (
    <TableRow onClick={toggleDropdown}>
      <TableRowCell>
        <CropText width={oneColumn} text={name} />
      </TableRowCell>
      <TableRowCell>
        <CropText width={twoColumn} text={formatDate(createdAt)} />
      </TableRowCell>
      <TableRowCell>
        <CropText width={thirdColumn} text={formatDate(updatedAt)} />
      </TableRowCell>
      <TableRowCell>
        <DropdownList
          title={<EllipseIcon src={EllipseSVG} />}
          options={[
            { label: 'Edit', value: 'edit' },
            { label: 'Delete', value: 'delete' },
          ]}
          disableArrow
          onClick={option => handleDropdownListClick(option, row)}
        />
      </TableRowCell>
    </TableRow>
  );
};

const TemplateTable = ({ rows: initialRows }: Props) => {
  const [rows, setRows] = React.useState(initialRows);

  const handleDeleteRow = id => {
    setRows(currentRows => currentRows.filter(row => row.id !== id));
  };
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${oneColumn}px` }} />
          <col span={1} style={{ width: `${twoColumn}px` }} />
          <col span={1} style={{ width: `${thirdColumn}px` }} />
          <col span={1} style={{ width: `${actionColumn}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Created Date</TableHeadCell>
            <TableHeadCell>Updated Date</TableHeadCell>
            <TableHeadCell></TableHeadCell>
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(item => (
            <TableRowWrapper key={item.id} row={item} onDeleteRow={handleDeleteRow} />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow: auto;
  height: 444px;
`;

const EllipseIcon = styled(ReactSVG)`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 30px;
`;

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;
const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableRow = styled.tr`
  height: 100%;
  position: relative;
`;

const TableRowCell = styled.td`
  padding: 20px 0px; /* Apply consistent padding */
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;
  white-space: nowrap; /* Keeps the content on a single line */
  text-align: left; /* Ensure text is aligned consistently */

  &:first-child {
    padding-left: 24px;
  }

  &:last-child {
    padding-right: 24px;
  }
`;

export default TemplateTable;
