/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import styled from 'styled-components';
import Jumbotron from 'components/Jumbotron';
import SearchWithFiltersInput from 'components/SearchWithFiltersInput';
import { FieldRadioButtonsLine } from 'components/FormFields';
import { UIButton } from 'components/UI';
import { variantFilterOptions } from 'constants/search';
import CompanySection from './CompanySection';
import CustomerSection from './CustomerSection';
import { useHistory } from 'react-router';
import { SelectOption } from 'components/UI/interfaces';
import { FilterTeamsData } from '../interfaces';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import PrioritySection from './PrioritySection';
import { Field } from 'components/FinalForm';
import { goalAlignmentOptions } from 'constants/goalAlignment';
import { typesOfInvestmentOptions } from 'constants/typesOfInvestment';
import { AdditionalInfo } from 'interfaces/auth';
import { useModal } from 'hooks/common';
import { fetchSaveTeamXLSX } from 'store/ducks/team/api';
import Toast from 'services/Toast';
import BatchAnalysisModal from 'pages/companyUser/search/components/BatchAnalysisModal';
import { PAGE_WIDTH } from 'constants/common';
import { fetchErrorHandler } from 'utils/errorHandlers';
import { UploadSearchTeamReq } from 'interfaces/team';
import { useOperation } from 'hooks/common';
import { fetchSubscriptionCompany } from 'store/ducks/companies/operations';
import { useSelector } from 'react-redux';
import { StateProps } from 'store/interfaces';
import { SimilaritySearchCompany } from 'interfaces/companies';
import { getSimilaritySearchCompany } from 'store/ducks/companies/selectors';
import CompanyContactsTable from 'pages/organizationUser/companies/Company/CompanyContactsTable';

const SDGGoalsOptions: SelectOption[] = Object.values(goalAlignmentOptions).map(item => ({
  value: item.value,
  label: item.title,
}));

const investmentTypesOptions: SelectOption[] = Object.values(
  typesOfInvestmentOptions,
).map(item => ({
  value: item.id,
  label: item.title,
}));

export interface FormProps {
  onSubmit: (values?: FilterTeamsData) => OnSubmitReturnType;
  industries: SelectOption[];
  states: SelectOption[];
  crsFocus: SelectOption<number>[];
  missions: SelectOption<number>[];
  isVisibleFilters: boolean;
  toggleFilter: (val: boolean) => void;
  initialValues: FilterTeamsData;
  teamRange: AdditionalInfo['teamRange'];
}

const FilterTeamsForm = ({
  onSubmit,
  isVisibleFilters,
  toggleFilter,
  initialValues,
  states,
  missions,
  teamRange,
}: FormProps) => {
  const [onFetchSubscriptionCompany, isLoading] = useOperation(fetchSubscriptionCompany);
  const [isBestMath, setIsBestMath] = useState<boolean>(true);
  const [submitLoading, setsubmitLoading] = useState(false);
  const { push } = useHistory();
  const uploadXLSX = useCallback(
    async (data: UploadSearchTeamReq) =>
      await fetchSaveTeamXLSX(data)
        .then(value => {
          return value;
        })
        // .catch(err => {
        //   err.response.data.statusCode === 400
        //     ? Toast.error(err.response.data.message)
        //     : Toast.error('Error Batch Analysis');
        //   return null;
        // })
        .catch(fetchErrorHandler),
    [],
  );
  const [loading] = useState(false);

  const similaritySearchCompany = useSelector<StateProps, SimilaritySearchCompany[]>(
    getSimilaritySearchCompany,
  );
  const [showBatchAnalysisModal, hideBatchAnalysisModal] = useModal(
    () => (
      <BatchAnalysisModal
        title="Batch Analysis"
        handleAccess={formValues => {
          setsubmitLoading(true);
          // console.log(formValues, 'formValues');
          if (!formValues.rows?.length) {
            Toast.warn('File not uploaded');
            setsubmitLoading(false);
            return;
          }
          uploadXLSX(formValues).then(value => {
            if (!value) {
              setsubmitLoading(false);
              return;
            }

            if (value.msg) {
              Toast.info(value.msg);
              setsubmitLoading(false);
            } else {
              setsubmitLoading(false);
              Toast.success('save successfully');
              const SearchBatchId = value; // Assuming the first ID is the batch ID
              onFetchSubscriptionCompany(undefined).then(e => {
                const newLocation = `/SearchBatch/${SearchBatchId}?isImport=${e?.payload?.isGiving}`;
                push(newLocation);
              });
            }

            hideBatchAnalysisModal();
          });
        }}
        submitLoading={submitLoading}
        onClose={hideBatchAnalysisModal}
      />
    ),
    [submitLoading],
  );

  return (
    <Form
      onSubmit={values =>
        onSubmit({
          ...values,
          numberOfSocialFollowersMin: values.numberOfSocialFollowers?.[0] || undefined,
          numberOfSocialFollowersMax: values.numberOfSocialFollowers?.[1],
          minimumCommitmentMin: values.minimumCommitment?.[0] || undefined,
          minimumCommitmentMax: values.minimumCommitment?.[1],
          grossReceiptsRangeMin: values.grossReceiptsRange?.[0] || undefined,
          grossReceiptsRangeMax: values.grossReceiptsRange?.[1],
        })
      }
      initialValues={{ ...initialValues, filterType: 'Best Match' }}
      render={({
        handleSubmit,
        submitting,
        values,
        form,
        pristine,
      }: FormRenderProps<FilterTeamsData>) => {
        const isCustomize = values.variantForm === 'Customize';
        return (
          <Wrapper>
            <Header>
              <Jumbotron name="Search">
                <>
                  <Field name="q" notRemoveWhenEmpty>
                    {() => (
                      <SearchWithFiltersInput
                        searchHandler={handleSubmit}
                        toggleFilter={() => toggleFilter(!isVisibleFilters)}
                        searchInputValue={values.q}
                        filterTypeValue={values.filterType}
                        handleChangeInputValue={val => form.change('q', val)}
                        isOrganization={true}
                        handleResetForm={() => {
                          form.reset();
                          form.getFieldState('q')?.change('');
                        }}
                        handleChangeFilterType={type => {
                          form.change('filterType', type);
                          setIsBestMath(type === 'Best Match');
                          if (type === 'Best Match') {
                            setIsBestMath(true);
                          } else {
                            setIsBestMath(false);
                          }
                        }}
                      />
                    )}
                  </Field>
                  {/* {isBestMath && similaritySearchCompany.length > 0 && (
                    <SugggestDiv>
                      Do you mean
                      {similaritySearchCompany.map((x, i) => (
                        <React.Fragment key={x.name}>
                          <SugggestURL
                            onClick={() => {
                              form.getFieldState('q')?.change(x.name);
                              handleSubmit();
                            }}
                          >
                            {x.name}
                          </SugggestURL>
                          {similaritySearchCompany.length !== i + 1 && ','}
                        </React.Fragment>
                      ))}
                      ?
                    </SugggestDiv>
                  )} */}
                  <BatchAnalysisButton
                    title="Batch Analysis"
                    modifiers="primary"
                    handleClick={showBatchAnalysisModal}
                  />
                </>
              </Jumbotron>
            </Header>
            {isVisibleFilters && (
              <RootWrapper>
                <Root>
                  <Title>Filters</Title>
                  <Field
                    name="variantForm"
                    component={FieldRadioButtonsLine}
                    options={variantFilterOptions}
                  />

                  {isCustomize && <PrioritySection />}

                  <CompanySection
                    missions={missions}
                    sdgGoals={SDGGoalsOptions}
                    states={states}
                    typeOfInvestment={investmentTypesOptions}
                    isCustomize={isCustomize}
                    teamRange={teamRange}
                  />

                  {!isCustomize && <CustomerSection />}

                  <ButtonsGroup>
                    <UIButton
                      modifiers="secondary"
                      title="Cancel"
                      handleClick={() => {
                        if (!pristine) {
                          form.reset();
                          handleSubmit();
                        }
                        toggleFilter(false);
                      }}
                      type="button"
                    />
                    <UIButton
                      modifiers="primary"
                      title="Search"
                      handleClick={handleSubmit}
                      type="submit"
                      disabled={submitting}
                      isLoading={submitting}
                    />
                  </ButtonsGroup>
                </Root>
              </RootWrapper>
            )}
          </Wrapper>
        );
      }}
    />
  );
};

const Wrapper = styled.div`
  background: #f9f9f9;
`;

const Header = styled.div`
  background: #fff;
`;

const ButtonsGroup = styled.div`
  margin-top: 16px;
  display: flex;
  width: 308px;

  > button {
    margin-right: 8px;
  }

  & button {
    flex: 1;
  }
`;

const Root = styled.form`
  background: #ffffff;
  padding: 40px 40px 32px;
  width: 100%;
  max-width: 1095px;
`;

const RootWrapper = styled.div`
  width: 100%;
  max-width: ${PAGE_WIDTH.LARGE}px;
  padding: 32px 20px 20px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  margin-bottom: 18px;
`;

const BatchAnalysisButton = styled(UIButton)`
  width: 203px;
  margin-left: 20px;
`;
const SugggestDiv = styled.div`
  display: flex;
  max-width: ${PAGE_WIDTH.NORMAL}px;
  align-items: center;
  position: absolute;
  margin-top: 62px;
  margin-left: 190px;
`;
const SugggestURL = styled.a`
  font-size: 12px;
  color: var(--blue);
  margin-left: 6px;
  position: relative;
  bottom: -1px;
`;
export default FilterTeamsForm;
