import { Gameplan } from 'interfaces/gameplan';
import { useMemo } from 'react';

function useGameplanMetric(gameplanDetail: Gameplan | undefined) {
  const gameplanMetric = useMemo((): {
    total: string;
    openRate: string;
    clickRate: string;
    replyRate: string;
  } => {
    if (!gameplanDetail)
      return { total: '-', openRate: '-', clickRate: '-', replyRate: '-' };

    const total = String(gameplanDetail.emailAnalytics.emailNum);

    const openRateNum =
      gameplanDetail.emailAnalytics.onceOpenNum / gameplanDetail.emailAnalytics.emailNum;
    const openRate = Number.isNaN(openRateNum) ? '-' : (openRateNum * 100).toFixed();

    const clickRateNum =
      gameplanDetail.emailAnalytics.onceClickNum / gameplanDetail.emailAnalytics.emailNum;
    const clickRate = Number.isNaN(clickRateNum) ? '-' : (clickRateNum * 100).toFixed();

    const replyRateNum =
      gameplanDetail.emailAnalytics.onceReplyNum / gameplanDetail.emailAnalytics.emailNum;
    const replyRate = Number.isNaN(replyRateNum) ? '-' : (replyRateNum * 100).toFixed();

    return { total, openRate, clickRate, replyRate };
  }, [gameplanDetail]);

  return gameplanMetric;
}

export default useGameplanMetric;
