import { goalAlignmentOptions } from 'constants/goalAlignment';
import { SdgGoals } from 'constants/sdgGoals';
import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

type Row = SdgGoals[];
interface Props {
  rows: Row;
}

interface TableRowProps {
  row: Row[0];
}

const userTableWidth = {
  sdgWidth: 480,
  descWidth: 480,
} as const;

const { sdgWidth, descWidth } = userTableWidth;

const TableRowWrapper = ({ row }: TableRowProps) => {
  return (
    <TableRow>
      <TableRowCell>
        <SDGTitleWrapper>
          <div>
            {goalAlignmentOptions[row].id}. {goalAlignmentOptions[row].title}
          </div>
          <SDGIcon src={goalAlignmentOptions[row].img} />
        </SDGTitleWrapper>
      </TableRowCell>
      <TableRowCell>{goalAlignmentOptions[row].discription}</TableRowCell>
    </TableRow>
  );
};

const MaterialIssuesTable = ({ rows }: Props) => {
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${sdgWidth}px` }} />
          <col span={1} style={{ width: `${descWidth}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell>SDGs</TableHeadCell>
            <TableHeadCell>Description</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows
            .sort((a, b) => goalAlignmentOptions[a].id - goalAlignmentOptions[b].id)
            .map((item, index) => (
              <TableRowWrapper key={index} row={item} />
            ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow: auto;
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 36px;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 12px;
  line-height: 150%;
  position: sticky;
  top: 0;
  color: var(--darkGray);
  vertical-align: middle;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;

const TableRow = styled.tr`
  height: 100%;
  background-color: #fff;
`;

const TableRowCell = styled.td<{ $bold?: boolean }>`
  padding: 14px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  ${({ $bold }) => $bold && `font-weight: 500;`}

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }
`;

const SDGTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
`;

const SDGIcon = styled(ReactSVG)`
  svg {
    width: 42px;
    height: 42px;
  }
`;

export default MaterialIssuesTable;
