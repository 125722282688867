import { UIDivider } from 'components/UI';
import React from 'react';
import styled from 'styled-components';
import { numberFormat } from 'utils/formatters';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';

interface Props {
  totalMediaImpressions: number;
}

const ImpressionsMetric = ({ totalMediaImpressions }: Props) => {
  return (
    <Root>
      <MetricTitle>Impressions</MetricTitle>
      <Content>
        <Value>
          <span>Total Media Impressions</span>
          <span>{numberFormat(totalMediaImpressions)}</span>
        </Value>
        <Divider type="horizontal" />
      </Content>
    </Root>
  );
};

const Root = styled(MetricRoot)``;

const Value = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
`;

const Content = styled(MetricContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Divider = styled(UIDivider)`
  margin: 30px 0;
  flex-shrink: 0;
`;

export default ImpressionsMetric;
