import {
  BatchListItem,
  GivingListReq,
  GivingListRes,
  UncommongivingOktaUser,
} from 'interfaces/giving';
import { fetch } from 'services/ApiService';
import { paramsToQueryString } from 'utils';

export const fetchGivingList = (params: GivingListReq) =>
  fetch<GivingListRes>('get', `giving${paramsToQueryString(params)}`);

export const fetchUncommongivingLoginOktaUser = () =>
  fetch<UncommongivingOktaUser>('get', 'canva/login-okta-user');

export const fetchBatchList = () => fetch<BatchListItem[]>('get', 'giving/batchList');
// Promise.resolve([
//   { id: '1', name: 'AAA' },
//   { id: '2', name: 'BBB' },
//   { id: '3', name: 'CCC' },
// ] as BatchListItem[]);

export const fetchGivingUploadToSFTP = (savedListTeamId: string) =>
  fetch('post', 'giving/uploadToSFTP', { payload: { savedListTeamId } });
