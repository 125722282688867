import { ActionType, createReducer } from 'typesafe-actions';
import { CompaniesStoreData } from 'interfaces/companies';
import * as types from './types';
import * as actions from './actions';

type RootAction = ActionType<typeof actions>;

export default createReducer<CompaniesStoreData, RootAction>({
  companies: {
    items: [],
    links: {},
    meta: {},
  },
  companiesSearch: [],
  contacts: [],
  favoritesCompanies: {
    items: [],
    links: {},
    meta: {},
  },
  companyScoops: [],
  companyNews: [],
  blockedCompanies: {
    items: [],
    links: {},
    meta: {},
  },
  topMatch: [],
  matchDetail: {},
  companyCurrent: undefined,
  similaritySearchCompany: [],
  companyCurrentContacts: [],
  teamCompanyMembers: [],
  lastMonthCompanies: {
    items: [],
    links: {},
    meta: {},
  },
  companiesContacts: {
    items: [],
    meta: {
      currentPage: '',
      itemCount: 0,
      itemsPerPage: '',
      totalItems: 0,
      totalPages: 0,
    },
  },
  subscription: undefined,
  companiesOnlyIdName: {
    items: [],
    links: {},
    meta: {},
  },
})
  .handleType([types.SET_SEARCH_COMPANIES], (state, action) => ({
    ...state,
    companiesSearch: action.payload.map(item => ({
      id: item.id,
      name: item.name,
      city: item.city,
      continent: item.continent,
      country: item.country,
      state: item.state,
      street: item.street,
      website: item.website,
      sdgGoals: item.sdgGoals,
      logo: item.logo,
    })),
  }))
  .handleType([types.SET_COMPANIES], (state, action) => ({
    ...state,
    companies: action.payload,
  }))
  .handleType([types.SET_COMPANY_SCOOPS], (state, action) => ({
    ...state,
    companyScoops: action.payload,
  }))
  .handleType([types.SET_COMPANY_NEWS], (state, action) => ({
    ...state,
    companyNews: action.payload,
  }))
  .handleType([types.SET_CONTACTS], (state, action) => ({
    ...state,
    contacts: action.payload,
  }))
  .handleType([types.SET_COMPANY], (state, action) => {
    const company = action.payload;
    if (state.companies.items.find(({ id }) => id === company.id))
      return {
        ...state,
        companies: {
          ...state.companies,
          items: state.companies.items.map(item =>
            item.id === company.id ? { ...item, ...company } : item,
          ),
        },
      };

    return {
      ...state,
      companies: {
        ...state.companies,
        items: [company, ...state.companies.items],
      },
    };
  })
  .handleType([types.SET_COMPANY_FAVORITE], (state, action) => {
    const companyId = action.payload;
    if (state.companies.items.find(({ id }) => id === companyId))
      return {
        ...state,
        companies: {
          ...state.companies,
          items: state.companies.items.map(item => {
            if (item.id !== companyId) return item;

            return {
              ...item,
              like: !item.like,
            };
          }),
        },
      };

    return {
      ...state,
      companies: {
        ...state.companies,
        items: [...state.companies.items],
      },
    };
  })
  .handleType([types.SET_COMPANIES_FAVORITES], (state, action) => ({
    ...state,
    favoritesCompanies: action.payload,
  }))
  .handleType([types.SET_COMPANIES_BLOCKED], (state, action) => ({
    ...state,
    blockedCompanies: action.payload,
  }))
  .handleType([types.SET_COMPANY_BLOCKED], (state, action) => ({
    ...state,
    companies: {
      ...state.companies,
      items: state.companies.items.map(item => {
        if (item.id !== action.payload) {
          return item;
        }
        return {
          ...item,
          block: !item.block,
        };
      }),
    },
  }))
  .handleType([types.SET_COMPANIES_TOPMATCH], (state, action) => ({
    ...state,
    topMatch: action.payload,
  }))
  .handleType([types.SET_COMPANY_MATCHDETAIL], (state, action) => ({
    ...state,
    matchDetail: action.payload,
  }))
  .handleType([types.SET_CONTACTS_USED_IN_60DAYS], (state, action) => ({
    ...state,
    companiesContacts: {
      ...state.companiesContacts,
      items: state.companiesContacts.items.map(item => {
        if (!action.payload.contactId.includes(item.contact_id)) return item;

        return { ...item, usedIn60Days: action.payload.usedIn60Days };
      }),
    },
    companies: {
      ...state.companies,
      items: state.companies.items.map(item => {
        if (item.id !== action.payload.companyId[0]) {
          return item;
        }

        return {
          ...item,
          contacts: item.contacts?.map(contact => {
            if (contact.id !== action.payload.contactId[0]) {
              return contact;
            }

            return {
              ...contact,
              usedIn60Days: action.payload.usedIn60Days,
            };
          }),
        };
      }),
    },
    contacts: state.contacts.map(item => {
      if (item.id !== action.payload.contactId[0]) {
        return item;
      }
      return {
        ...item,
        usedIn60Days: action.payload.usedIn60Days,
      };
    }),
  }))
  .handleType([types.SET_COMPANY_CURRENT], (state, action) => ({
    ...state,
    companyCurrent: action.payload,
  }))
  .handleType([types.SET_SIMILARITYSEARCH], (state, action) => ({
    ...state,
    similaritySearchCompany: action.payload,
  }))
  .handleType([types.SET_COMPANY_CURRENT_CONTACTS], (state, action) => ({
    ...state,
    companyCurrentContacts: action.payload,
  }))
  .handleType([types.CREATE_COMPANY_CURRENT_CONTACT], (state, action) => ({
    ...state,
    companyCurrentContacts: [...state.companyCurrentContacts, action.payload],
  }))
  .handleType([types.UPDATE_COMPANY_CURRENT_CONTACT], (state, action) => ({
    ...state,
    companyCurrentContacts: state.companyCurrentContacts.map(item => {
      if (item.id === action.payload.id) {
        return action.payload;
      }
      return item;
    }),
  }))
  .handleType([types.DELETE_COMPANY_CURRENT_CONTACT], (state, action) => ({
    ...state,
    companyCurrentContacts: state.companyCurrentContacts.filter(
      item => item.id !== action.payload,
    ),
  }))
  .handleType([types.CLEAR_SIMILARITYSEARCHCOMPANY], state => ({
    ...state,
    similaritySearchCompany: [],
  }))
  .handleType(types.GET_TEAM_MEMBERS, (state, action) => {
    return {
      ...state,
      teamCompanyMembers: action.payload,
    };
  })
  .handleType([types.DELETE_TEAM_MEMBERS], (state, action) => {
    return {
      ...state,
      teamCompanyMembers: state.teamCompanyMembers.filter(
        item => item.id !== action.payload,
      ),
    };
  })
  .handleType([types.SET_LAST_MONTH_COMPANIES], (state, action) => ({
    ...state,
    lastMonthCompanies: action.payload,
  }))
  .handleType([types.SET_LAST_MONTH_COMPANY_MEETING], (state, action) => ({
    ...state,
    lastMonthCompanies: {
      ...state.lastMonthCompanies,
      items: state.lastMonthCompanies.items.map(item => ({
        ...item,
        isMeeting: action.payload === item.id ? !item.isMeeting : item.isMeeting,
      })),
    },
  }))
  .handleType([types.SET_LAST_MONTH_COMPANY_PARTNERSHIP], (state, action) => ({
    ...state,
    lastMonthCompanies: {
      ...state.lastMonthCompanies,
      items: state.lastMonthCompanies.items.map(item => ({
        ...item,
        isPartnership:
          action.payload === item.id ? !item.isPartnership : item.isPartnership,
      })),
    },
  }))
  .handleType([types.SET_FAVORITES_COMPANY_MEETING], (state, action) => ({
    ...state,
    favoritesCompanies: {
      ...state.favoritesCompanies,
      items: state.favoritesCompanies.items.map(item => ({
        ...item,
        isMeeting: action.payload === item.id ? !item.isMeeting : item.isMeeting,
      })),
    },
  }))
  .handleType([types.SET_FAVORITES_COMPANY_PARTNERSHIP], (state, action) => ({
    ...state,
    favoritesCompanies: {
      ...state.favoritesCompanies,
      items: state.favoritesCompanies.items.map(item => ({
        ...item,
        isPartnership:
          action.payload === item.id ? !item.isPartnership : item.isPartnership,
      })),
    },
  }))
  .handleType([types.SET_LAST_MONTH_COMPANY_LIKE], (state, action) => ({
    ...state,
    lastMonthCompanies: {
      ...state.lastMonthCompanies,
      items: state.lastMonthCompanies.items.map(item => ({
        ...item,
        like: action.payload === item.id ? !item.like : item.like,
      })),
    },
  }))
  .handleType([types.SET_COMPANIESE_CONTACTS], (state, action) => ({
    ...state,
    companiesContacts: action.payload,
  }))
  .handleType([types.SET_SUBSCRIPTION], (state, action) => ({
    ...state,
    subscription: action.payload,
  }))
  .handleType([types.UPDATE_COMPANY_PROFILE], (state, action) => ({
    ...state,
    companies: {
      ...state.companies,
      items: state.companies.items.map(company =>
        company.id === action.payload.id ? { ...company, ...action.payload } : company,
      ),
    },
  }))
  .handleType([types.SET_COMPANIES_ONLY_ID_NAME], (state, action) => ({
    ...state,
    companiesOnlyIdName: action.payload,
  }));
