import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import iconGiveworx from 'assets/images/preview/icon-giveworx-guy.png';
// import iconGiveworxBlack from 'assets/images/preview/icon-giveworx-black.png';
import default1 from 'assets/images/Logo.png';
import default2 from 'assets/images/preview/default2.png';
import paypal from 'assets/images/preview/paypal.svg';
import paypal1 from 'assets/images/preview/paypal1.svg';
import { useIsCompanyUser, useOperation } from 'hooks/common';
import { fetchCamPartnersList, fetchPreviewInfo } from 'store/ducks/campaign/operations';
import { useSelector } from 'react-redux';
import { getQueryString } from 'utils';
import { selectUser } from 'store/ducks/user/selectors';
import { SingleSelect, UISpinner } from 'components/UI';
import { getTeam } from 'store/ducks/team/selectors';
import { PaypalTypeEnum } from 'constants/team';
import NewPreviewContent from 'components/Fundraising/NewPreviewContent';
import { fetchCompanyCurrent } from 'store/ducks/companies/api';

const PreviewContent = () => {
  const [onFetchCampaign] = useOperation(fetchPreviewInfo);
  const [getCamPartnersList] = useOperation(fetchCamPartnersList);
  const campaignId = getQueryString('campaignId');

  const user = useSelector(selectUser);
  const isCompanyUser = useIsCompanyUser(user);
  const team = useSelector(getTeam);

  const [details, setDetails] = useState<any>();
  const [partnerList, setPartnerList] = useState<string[]>([]);
  const [isloading, setLoading] = useState(false);
  const [selectValue, setSelectValue] = useState(undefined);
  const [isBattleNet, setIsBattleNet] = useState(false);
  const [returnBattle, setReturnBattle] = useState(false);

  const get = partnerId => {
    setLoading(true);
    onFetchCampaign({
      id: campaignId,
      partnerId,
    }).then((e: any) => {
      setDetails(e?.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!campaignId) return;
    fetchCompanyCurrent()
      .then((ele: any) => {
        if (ele?.isBattleNet) {
          setIsBattleNet(true);
        } else {
          setIsBattleNet(false);
        }
        setReturnBattle(true);

        // setIsBattleNet(true);
      })
      .catch(() => {
        setReturnBattle(true);
      });
    get('');
    const page = 1;
    getCamPartnersList({ page, campaignId }).then((ele: any) => {
      const data: string[] = [];
      ele.items.forEach((i: any) => {
        if (i.selected === 1) data.push(i);
      });
      setPartnerList(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, onFetchCampaign]);

  if (isloading) return <Spinner />;
  if (isBattleNet) {
    return <NewPreviewContent />;
  } else if (returnBattle) {
    return (
      <>
        <div style={{ padding: 32, background: '#F9F9F9', marginTop: 0 }}>
          <div>
            <div style={{ color: '#737373', fontSize: 14, marginBottom: 4 }}>
              Select a Partner
            </div>
            <SelectGroup>
              <SingleSelect
                placeholder="Please Select"
                handleSelect={(v: any) => {
                  if (!v?.value) return;
                  setSelectValue(v.value);
                  get(v.value);
                }}
                value={selectValue}
                options={partnerList?.map((item: any) => ({
                  label: item.companyName,
                  value: item.id,
                  id: isCompanyUser ? item.teamId : item.companyId,
                }))}
                isClearable={false}
              />
            </SelectGroup>
          </div>
          <Root style={{ fontFamily: details?.selectCampaignFont }}>
            <ItemCon>
              <TitleText>Donation Request</TitleText>
              <Content>
                <HederBorder
                  style={{ backgroundColor: details?.selectCampaignColors?.[0] }}
                />
                {/* header 2 */}
                <HeaderLogo
                  fontLength={
                    details?.donationRequestMessage.length >= 260
                      ? details?.donationRequestMessage.length
                      : 0
                  }
                >
                  <LogoLeft
                    style={{
                      backgroundColor: details?.selectCampaignColors?.[0],
                      color: details?.selectCampaignColors?.[1],
                    }}
                  >
                    {/* <img
                    alt=""
                    src={iconGiveworx}
                    style={{ objectFit: 'contain', width: 18, marginRight: 10 }}
                  /> */}
                    {details?.donationRequestMessage}
                  </LogoLeft>
                  <LogoRight style={{ width: '40%' }}>
                    <img
                      alt=""
                      src={details?.newCustomContentImageName}
                      style={{ height: 132, objectFit: 'cover' }}
                    />
                  </LogoRight>
                </HeaderLogo>
                {/* content */}
                <ContentDiv style={{ display: 'flex', justifyContent: 'center' }}>
                  <ContentItem
                    style={{
                      backgroundColor: details?.selectCampaignColors?.[2],
                      color: details?.selectCampaignColors?.[3],
                    }}
                  >
                    Select amount
                  </ContentItem>
                  <ContentItem
                    style={{
                      backgroundColor: details?.selectCampaignColors?.[2],
                      margin: '0 6px',
                      color: details?.selectCampaignColors?.[3],
                    }}
                  >
                    $ {details?.setDonation1}
                  </ContentItem>
                  <ContentItem
                    style={{
                      backgroundColor: details?.selectCampaignColors?.[2],
                      color: details?.selectCampaignColors?.[3],
                    }}
                  >
                    $ {details?.setDonation2}
                  </ContentItem>
                  <ContentItem
                    style={{
                      backgroundColor: details?.selectCampaignColors?.[2],
                      marginLeft: 6,
                      color: details?.selectCampaignColors?.[3],
                    }}
                  >
                    $ Other
                  </ContentItem>
                </ContentDiv>

                {/* chex */}
                {details && (
                  <ContentChex>
                    <input
                      type="checkbox"
                      id="ShareContactInfoCheckBox"
                      // defaultChecked={details?.donorCoveredFees === 'true'}
                      defaultChecked={true}
                      style={{ marginRight: 6 }}
                    />
                    {details?.shareDonorInformation ||
                      'I would like to receive more information about your organization'}
                  </ContentChex>
                )}
                {/* buuton */}
                <ButtonDIV
                  style={{
                    padding: '6px 10px',
                    backgroundColor: details?.selectCampaignColors?.[2],
                    color: details?.selectCampaignColors?.[3],
                  }}
                >
                  Continue
                </ButtonDIV>
                {/* footer */}
                <FooterDiv style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <LogoImg
                    src={details?.companyLogo || (isCompanyUser ? default2 : default1)}
                  />
                  <LogoImg
                    src={details?.teamLogo || (isCompanyUser ? default1 : default2)}
                  />
                </FooterDiv>
              </Content>
            </ItemCon>
            <ItemCon style={{ gridRow: '1 / 3', gridColumn: '2 / 3' }}>
              <TitleText>Donation Confirmation</TitleText>
              <Content>
                <HederBorder
                  style={{ backgroundColor: details?.selectCampaignColors?.[0] }}
                />
                <div
                  style={{
                    width: '100%',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <HeaderData>
                    {/* <img
                    alt=""
                    src={iconGiveworxBlack}
                    style={{ width: 20, height: 30, marginRight: 10 }}
                  /> */}
                    <span style={{ fontWeight: 500 }}>
                      {details?.donationConfirmation}
                    </span>
                  </HeaderData>
                  <span
                    style={{
                      width: '100%',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      margin: '15px 0',
                    }}
                  >
                    One-Time Donation
                  </span>
                  <ContentItem
                    style={{
                      backgroundColor: details?.selectCampaignColors?.[2],
                      color: details?.selectCampaignColors?.[3],
                    }}
                  >
                    $ {details?.setDonation1}
                  </ContentItem>
                  {details?.donorCoveredFees === 'true' && (
                    <ContentChex1 style={{ fontWeight: 500, marginTop: '20px' }}>
                      <CheckBoxDiv style={{ padding: 2, border: '1px solid #000' }} />
                      I’d like to help cover the transaction fees
                    </ContentChex1>
                  )}
                  {/* <ContentChex1 style={{ fontWeight: 500, marginTop: '20px' }}>
                  <CheckBoxDiv style={{ padding: 2, border: '1px solid #000' }} />
                  I’d like to help cover the transaction fees
                </ContentChex1> */}
                  <PaypalButton>
                    <img
                      alt=""
                      src={paypal}
                      style={{ marginTop: 12, objectFit: 'contain', height: 20 }}
                    />
                  </PaypalButton>
                  <PaypalButton
                    style={{
                      background: '#353738',
                      lineHeight: 10,
                      color: '#FFF',
                      margin: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      alt=""
                      style={{ width: 20, height: 15 }}
                      src={`/icons/config-techstack.png`}
                    />
                    <span>Debit or credit card</span>
                  </PaypalButton>
                  <PaypalFooter>
                    Technical Support:
                    <img alt="" src={paypal1} style={{ width: 52 }} />
                  </PaypalFooter>
                  {/* {team?.paypalType === PaypalTypeEnum.PayPal_Giving_Fund && (
                  <FooterText>
                    Your donation is to PayPal Giving Fund (PPGF), an IRS-registered
                    501(c)(3) charity, subject to its terms. PPGF receives the donation,
                    which is typically tax deductible, minus payment processing fees of{' '}
                    {details?.donorCoveredFees === 'true'
                      ? details?.donorCurrencySetting === 'VariableFee'
                        ? `${details?.donorFixedFees}%`
                        : details?.donorCurrencySetting === 'FixedFee'
                        ? `$${details?.donorFixedFees}`
                        : ''
                      : '1.99 % + $.49'}{' '}
                    . PPGF typically grants funds to recommended charities within 15-45
                    days. In the rare event PPGF can’t fund your chosen charity, it will
                    seek to consult you before reassigning the funds to a similar charity.
                  </FooterText>
                )} */}
                  <FooterText>
                    {/* Your donation is to PayPal Giving Fund (PPGF), an IRS-registered
                    501(c)(3) charity, subject to its terms. PPGF receives the donation,
                    which is typically tax deductible, minus payment processing fees of{' '}
                    {details?.donorCoveredFees === 'true'
                      ? details?.donorCurrencySetting === 'VariableFee'
                        ? `${details?.donorFixedFees}%`
                        : details?.donorCurrencySetting === 'FixedFee'
                        ? `$${details?.donorFixedFees}`
                        : ''
                      : '1.99 % + $.49'}{' '}
                    . PPGF typically grants funds to recommended charities within 15-45
                    days. In the rare event PPGF can’t fund your chosen charity, it will
                    seek to consult you before reassigning the funds to a similar charity. */}
                    Your donation is to PayPal Giving Fund (PPGF), an IRS-registered
                    501(c)(3) charity, subject to its terms. PPGF receives the donation,
                    which is typically tax deductible, minus payment processing fees of
                    1.99 % + $.49, minus a 3% Accelerist platform fee . PPGF typically
                    grants funds to recommended charities within 15-45 days. In the rare
                    event PPGF can’t fund your chosen charity, it will seek to consult you
                    before reassigning the funds to a similar charity.
                  </FooterText>
                  {team?.paypalType === PaypalTypeEnum.PayPal_Commerce && (
                    <FooterText>
                      {team.organizationName} rityName receives the donation, which is
                      typically tax deductible, minus payment processing fees of{' '}
                      {details?.donorCoveredFees === 'true'
                        ? details?.donorCurrencySetting === 'VariableFee'
                          ? `${details?.donorFixedFees}%`
                          : details?.donorCurrencySetting === 'FixedFee'
                          ? `$${details?.donorFixedFees}`
                          : ''
                        : '1.99 % + $.49'}
                      .
                    </FooterText>
                  )}
                </div>
              </Content>
            </ItemCon>
            <ItemCon>
              <TitleText>Donation Receipt and Recognition</TitleText>
              <Content>
                <HederBorder1
                  style={{ backgroundColor: details?.selectCampaignColors?.[0] }}
                />
                <HeaderTitle
                  fontLength={
                    details?.donationReceiptHeader.length >= 220
                      ? details?.donationReceiptHeader.length
                      : 0
                  }
                  style={{
                    backgroundColor: details?.selectCampaignColors?.[0],
                    color: details?.selectCampaignColors?.[1],
                  }}
                >
                  {/* <img
                  alt=""
                  src={iconGiveworx}
                  style={{ objectFit: 'contain', width: 16, margin: '0 24px' }}
                /> */}
                  {details?.donationReceiptHeader}
                </HeaderTitle>
                <HeaderLogo1>
                  <LogoLeft
                    style={{
                      backgroundColor: '#FFF',
                      fontWeight: 'bold',
                      fontSize: 18,
                      color: details?.selectCampaignColors?.[1],
                      padding: '10px 24px',
                      textAlign: 'center',
                    }}
                  >
                    <div
                      style={{
                        color: details?.receiptMessageFontColor
                          ? details?.receiptMessageFontColor
                          : 'black',
                        overflowWrap: 'break-word',
                        width: '300px',
                      }}
                    >
                      {details?.receiptMessage ||
                        'Together we have made an impact in the community.'}
                    </div>
                  </LogoLeft>
                  <LogoRight style={{ width: '40%' }}>
                    <img
                      alt=""
                      src={details?.teamLogo || (isCompanyUser ? default1 : default2)}
                      style={{ height: 132, objectFit: 'contain' }}
                    />
                  </LogoRight>
                </HeaderLogo1>
                <ContentText1>
                  {details?.newDonationReceiptImage && (
                    <LogoLeft style={{ width: '30%' }}>
                      <img
                        alt=""
                        src={details?.newDonationReceiptImage}
                        style={{ width: 100, objectFit: 'cover' }}
                      />
                    </LogoLeft>
                  )}
                  <div style={{ marginTop: '10px' }}>
                    {details?.customMessage ||
                      'The nonprofit organization is dedicated to improving the quality of life for others through outreach, education, and training.'}
                    <span style={{ color: '#337ab7', marginLeft: 6 }}>Learn More</span>
                  </div>
                </ContentText1>
                {details?.receiptEmailMessage && (
                  <ContentText>
                    <span>✉</span>
                    <span
                      style={{ marginLeft: 6, color: details?.selectCampaignColors?.[1] }}
                    >
                      {details?.receiptEmailMessage}
                    </span>
                  </ContentText>
                )}
                {details?.couponConfirmationMessage && (
                  <ContentText>
                    <span>✉</span>
                    <span
                      style={{ marginLeft: 6, color: details?.selectCampaignColors?.[1] }}
                    >
                      {details?.couponConfirmationMessage}
                    </span>
                  </ContentText>
                )}
              </Content>
            </ItemCon>
          </Root>
        </div>
      </>
    );
  }
  return <Spinner />;
};
const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 201px);
`;
const Root = styled.div`
  margin-top: 28px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 24px;
`;

const SelectGroup = styled.div`
  display: flex;

  & > div {
    width: 380px;
  }

  & > div:not(:first-child) {
    margin-left: 18px;
  }
`;

const TitleText = styled.div`
  color: #000;
  font-weight: 600;
  font-size: 17px;
  padding-bottom: 12px;
`;
const Content = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 10px;
  flex: 1;
`;
const ItemCon = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

const HederBorder = styled.div`
  background-color: rgb(221, 45, 74);
  height: 32px;
  border-radius: 10px 10px 0 0;
`;
const HederBorder1 = styled.div`
  background-color: rgb(221, 45, 74);
  height: 30px;
  border-radius: 10px 10px 0 0;
  text-aline: center;
`;

const HeaderLogo = styled.div<{ fontLength?: any }>`
  display: flex;
  align-items: center;
  ${({ fontLength }) => (fontLength >= 260 ? '' : 'height: 132px')}
`;

const HeaderLogo1 = styled.div`
  top: 10px;
  display: flex;
  align-items: center;
`;

const LogoLeft = styled.div`
  width: 60%;
  // background-color: rgb(242, 106, 141);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  text-align: center;
`;
const LogoRight = styled.div`
  width: 43%;
`;
const ContentDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
`;
const ContentItem = styled.div`
  background-color: #880d1e;
  width: 80px;
  border-radius: 4px;
  height: 60px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;
const ContentChex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContentChex1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &::before {
    position: absolute;
    content: '?';
    display: inline-block;
    font-weight: bold;
    text-align: center;
    width: 10px;
    height: 11px;
    font-size: 8px;
    line-height: 8px;
    border-radius: 1.7ex;
    background-color: #fff;
    border: 1px solid;
    vertical-align: super;
    right: -14px;
  }
`;
const CheckBoxDiv = styled.div`
  padding: 2px;
  border: 1px solid rgb(108 101 101);
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  margin-right: 2px;
`;
const ButtonDIV = styled.div`
  padding: 6px 10px;
  background-color: rgb(214, 95, 0);
  width: 40%;
  margin: auto;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  margin-top: 12px;
`;
const FooterDiv = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  padding-bottom: 18px;
`;
const HeaderData = styled.div`
  display: flex;
  margin: 24px;
  align-items: center;
`;
const PaypalButton = styled.div`
  height: 38px;
  width: 60%;
  background: rgb(0, 106, 177);
  border-radius: 5px;
  text-align: center;
  margin-bottom: 12px;
  margin-top: 24px;
`;
const PaypalFooter = styled.div`
  font-style: italic;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6px;
  font-size: 12px;
`;
const FooterText = styled.div`
  font-size: 14px;
  width: 60%;
  line-height: 1.5;
`;
const HeaderTitle = styled.div<{ fontLength?: any }>`
  background-color: rgb(0, 137, 149);
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  // height: 78px;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  text-aline: center;
  ${({ fontLength }) => (fontLength >= 220 ? '' : 'height: 78px')}
`;
const ContentText1 = styled.div`
  font-size: 13px;
  width: 93%;
  margin: auto;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
`;
const ContentText = styled.div`
  font-size: 13px;
  width: 80%;
  margin: auto;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 700;
  color: #808285 !important;
`;

const LogoImg = styled.img`
  width: 200px;
  height: 100px;
  object-fit: contain;
`;

export default PreviewContent;
