import { UserPreview } from 'interfaces/common';

import UserIcon from 'assets/images/ava.png';

export const users: UserPreview[] = Array(10).fill({
  id: '1',
  firstName: 'Frank',
  lastName: 'Lampard',
  avatar: UserIcon,
  email: 'frank_lampard@cfc.com',
  linkedIn: '2746637',
  role: 'Admin',
  lastLogin: '2020-08-12',
});

export const headTableUsers = ['Full Name', 'Email', 'Linkedin', 'Role', 'Last Login'];

export enum GuidestarTransparencyRankingEnum {
  None = 'None',
  Bronze = 'Bronze',
  Silver = 'Silver',
  Gold = 'Gold',
  Platinum = 'Platinum',
}

export const paypalTypeOptions = [
  { label: 'PayPal Commerce', value: '1' },
  { label: 'PayPal Giving Fund', value: '0' },
];

export enum PaypalTypeEnum {
  PayPal_Commerce = '1',
  PayPal_Giving_Fund = '0',
}
