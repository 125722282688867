import LogoImg from 'assets/images/Logo.svg';
import HeaderCompanyDropDown from 'components/HeaderCompanyDropDown';
import { PAGE_WIDTH } from 'constants/common';
import { Location } from 'history';
import { useLogout } from 'hooks/common';
import { SearCompaniesRequestProps } from 'interfaces/common';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getCompanySubscription } from 'store/ducks/companies/selectors';
import styled from 'styled-components';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { encodeBase64 } from 'utils';
import queryString from 'query-string';
import { initialValuesFilter } from 'pages/organizationUser/search/Search/initialValuesFilter';
import SearchInput from 'components/SearchInput';

export interface HeaderProps {
  location: Location;
}

interface FormProps {
  q: string;
  filterType?: SearCompaniesRequestProps['filterType'];
}

const Search = styled(SearchInput)`
  width: 100%;
  margin: 0 40px 0 20px;
`;

const HeaderTS = ({ location }: HeaderProps) => {
  const { logout } = useLogout();
  const { push, location: local } = useHistory();
  const subscription = useSelector(getCompanySubscription);

  const parsedQuery = queryString.parse(location.search, {
    parseNumbers: true,
  });

  const getParams = useCallback(
    (filters: string) => queryString.stringify({ ...parsedQuery, filters }),
    [parsedQuery],
  );

  const onSearch = (values?: FormProps) => {
    if (values?.q) {
      const base64 = encodeBase64(
        JSON.stringify({
          ...initialValuesFilter,
          ...values,
          filterType: values.filterType,
        }),
      );
      return push(`/search?${getParams(base64)}`);
    }
    return push(`/search`);
  };

  const isVisibleSearch =
    local.pathname !== '/search' && local.pathname !== '/prospecting';

  const initialFilterType = 'Best Match';

  return (
    <HeaderWrapper>
      <HeaderInner>
        <Left>
          <Link to="/campaignTeamsnap">
            <Logo src={LogoImg} alt="Accelerist-TeamSnap" />
          </Link>
        </Left>
        <Right>
          <Logout onClick={logout}>Log out</Logout>
        </Right>
      </HeaderInner>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  background: #d5f3ff;
  width: 100%;
`;

const HeaderInner = styled.div`
  width: 100%;
  max-width: ${PAGE_WIDTH.LARGE}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; // Allows content to wrap to the next line on smaller screens
  padding: 0 10px; // Add some padding to ensure content doesn't touch the screen edge on mobile
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  flex: 1; // Allow the left side to grow and shrink
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1; // Allow the right side to grow and shrink
  flex-shrink: 0; // Prevents the right side from shrinking too much, ensuring it stays visible
`;

const Logo = styled.img`
  width: 100%;
  max-width: 200px;
  margin-right: 44px;
`;

const LinkList = styled.div`
  display: flex;
`;

const LinkElem = styled(NavLink)`
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  border-bottom: 2px solid transparent;
  margin-top: 3px;
  padding: 0 3px 3px 3px;

  &:not(:last-child) {
    margin-right: 22px;
  }

  &:hover {
    border-color: var(--black);
  }

  &.active {
    border-color: var(--black);
  }
`;
const Logout = styled.div`
  font-size: 12px;
  color: var(--red);
  &:hover {
    color: #eb1c2d;
    cursor: pointer;
  }
`;

export default HeaderTS;
