/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { take, upperFirst } from 'lodash';
import { useSelector } from 'react-redux';
import { Prospect } from 'interfaces/prospects';
import { revenueRangeOptions, fundraisingContributionOptions } from 'constants/search';
import { ShowMoreText } from 'components/ShowMoreText';
import { goalAlignmentOptions } from 'constants/goalAlignment';
import { getAdditionalInfoSelect } from 'store/ducks/companies/selectors';
import { selectUser } from 'store/ducks/user/selectors';
import { useIsCompanyUser } from 'hooks/common';

interface Props {
  filters: Prospect['filters'];
  marginList?: string;
  take?: number;
  companiesNames?: string[];
  title?: ReactNode;
  width?: number;
  isShowEdit?: boolean;
}

const formatFieldName = {
  // Company section
  Company_Name: 'ids',
  Country: 'country',
  State: 'location',
  City: 'city',
  Postal_Code: 'zipCode',
  Industry: 'industry',
  NAICS_Code_Category: 'primaryNaicsCode',
  NAICS_Code_SubCategory: 'naicsCode',
  Revenue_Range: 'revenueRange',
  CSR_Focus: 'csrFocusIds',
  Annual_Fundraising_Contributions: 'contributionRange',
  Corporate: 'corporateFoundation',
  SDG_Goals: 'sdgGoals',
  // Contacts
  Contact_Title: 'contact',
  Job_Function: 'jobFunction',
  Management_Level: 'managementLevel',
  // Customer Demographics
  Gender: 'gender',
  Household_Income: 'income',
  Ethnicity: 'ethnicities',
  Age: 'ageRanges',
};

const p = <T extends unknown>(v: T, cb: (value: T) => void) =>
  Array.isArray(v) ? v?.length && cb(v) : v && cb(v);

const Filters = ({
  filters,
  marginList,
  take: takeEntries,
  companiesNames,
  title = 'Filters',
  width = 1096,
  isShowEdit = false,
}: Props) => {
  const user = useSelector(selectUser);
  const isCompany = useIsCompanyUser(user);
  const additionalInfo = useSelector(getAdditionalInfoSelect());

  const containsDisplayFields = Object.values(formatFieldName).some(item => {
    switch (item) {
      case formatFieldName.Gender:
        if (filters[item] === 'both') return false;
        return filters[item];
      default:
        return Array.isArray(filters[item]) ? filters[item].length : filters[item];
    }
  });
  if (isShowEdit === false) {
    if (!containsDisplayFields) return null;
  }
  const getFilters = () => {
    const arr: string[] = [];

    p(
      filters[formatFieldName.Company_Name],
      () =>
        companiesNames?.length &&
        arr.push(
          isCompany
            ? `organization(s): ${companiesNames.join(', ')}`
            : `Company: ${companiesNames.join(', ')}`,
        ),
    );

    p(filters[formatFieldName.Country], v => arr.push(`Country: ${v.join(', ')}`));

    p(filters[formatFieldName.State], v => arr.push(`State: ${v.join(', ')}`));

    p(filters[formatFieldName.City], v => arr.push(`City: ${v.join(', ')}`));

    p(filters[formatFieldName.Postal_Code], v => arr.push(`Postal Code: ${v}`));

    p(filters[formatFieldName.Industry], v => arr.push(`Industry: ${v.join(', ')}`));

    p(filters[formatFieldName.NAICS_Code_Category], v =>
      arr.push(
        `NAICS Code Category: ${additionalInfo.primaryNaicsCode
          .filter(item => v.includes(item.value))
          .map(item => item.label)
          .join(', ')}`,
      ),
    );

    p(filters[formatFieldName.NAICS_Code_SubCategory], v =>
      arr.push(
        `NAICS Code Sub-Category: ${additionalInfo.naicsCode
          .filter(item => v.includes(item.value))
          .map(item => item.label)
          .join(', ')}`,
      ),
    );

    p(filters[formatFieldName.Revenue_Range], v =>
      arr.push(
        `Revenue Range: ${revenueRangeOptions
          .filter(item => v.includes(item.value))
          .map(item => item.label)
          .join(', ')}`,
      ),
    );

    p(filters[formatFieldName.CSR_Focus], v =>
      arr.push(
        `CSR Focus: ${additionalInfo.crsFocus
          .filter(item => v.includes(item.value))
          .map(item => item.label)
          .join(', ')}`,
      ),
    );

    p(filters[formatFieldName.Annual_Fundraising_Contributions], v =>
      arr.push(
        `Annual Fundraising Contributions: ${fundraisingContributionOptions
          .filter(item => v.includes(item.value))
          .map(item => item.label)
          .join(', ')}`,
      ),
    );

    p(filters[formatFieldName.Corporate], v =>
      arr.push(`Corporate Foundation: ${v ? 'Yes' : 'No'}`),
    );

    p(filters[formatFieldName.SDG_Goals], v =>
      arr.push(
        `SDG Goals: ${v.map(item => goalAlignmentOptions[item].title).join(', ')}`,
      ),
    );

    p(filters[formatFieldName.Contact_Title], v => arr.push(`Contact Title: ${v}`));

    p(filters[formatFieldName.Job_Function], v => arr.push(`Job Function: ${v}`));

    p(filters[formatFieldName.Management_Level], v => arr.push(`Management Level: ${v}`));

    p(
      filters[formatFieldName.Gender],
      v => v !== 'both' && arr.push(`Demographic: ${upperFirst(v)}`),
    );

    p(filters[formatFieldName.Household_Income], v =>
      arr.push(`Household Income: ${v.join(', ')}`),
    );

    p(filters[formatFieldName.Ethnicity], v => arr.push(`Ethnicity: ${v.join(', ')}`));

    p(filters[formatFieldName.Age], v => arr.push(`Age: ${v.join(', ')}`));

    return arr;
  };

  const formatFilters = getFilters();

  return (
    <Root $width={width}>
      <Name>{title}</Name>
      <List marginList={marginList}>
        {take(formatFilters, takeEntries || formatFilters.length).map((filter, index) => (
          <Item key={index}>
            <ShowMoreText width={width && width - 52}>{filter}</ShowMoreText>
          </Item>
        ))}
      </List>
    </Root>
  );
};

const Root = styled.section<{ $width?: number }>`
  margin-bottom: 24px;
  ${({ $width }) => $width != null && `width: ${$width}px;`}
`;

const Name = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const List = styled.ul<{ marginList?: string }>`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ marginList }) => marginList && marginList};
  margin-bottom: -8px;
  min-height: 40px;
`;

const Item = styled.li`
  border: 1px solid var(--secondaryBlue);
  box-sizing: border-box;
  border-radius: 6px;
  background: #fff;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  padding: 6px 10px;
  margin-bottom: 8px;
  width: fit-content;

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

export default Filters;
