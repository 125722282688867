import { User } from './auth';
import { PaginationMeta, PaginationReq } from './common';
import { ObjNoteData } from './objNote';
import { TeamProfileData } from './team';

export interface ValuationDetailData {
  currentMinimumThreshold: string;
  industryRanking: string;
  unaidedBrandAwareness: string;
  aidedBrandAwareness: string;
  constituentData: string;
  communityImpact: string;
  senseOfUrgencyCurable: true;
  senseOfUrgencyPreventable: true;
  audienceLoyalty: string;
  audienceActivation: string;
  fundraisingIncome: string[];
  operationalExpenses: string;
  geographicScope: string[];
  corporatePartners: string;
  corporatePartnershipStrategy: string;
  influencerSupport: string;
  brandExposure: string;
  organizationSize: string;
  paidMedia: {
    totalSpentOnRadio: string;
    totalRadioImpressions: string;
    totalSpentOnOutOfHome: string;
    totalSpentOnPrintMedia: string;
    totalSpentOnTelevision: string;
    totalDigitalImpressions: string;
    totalSpentOnDigitalMedia: string;
    totalOutOfHomeImpressions: string;
    totalTelevisionImpressions: string;
    totalPrintPublicationImpressions: string;
  };
  earnedMedia: {
    totalEarnedMediaImpressionsInTheLast12Months: string;
  };
  ownedMedia: {
    whatIsYourOverallEmailOpenRate: string;
    totalDigitalPublicationImpressions: string;
    totalWebsiteImpressionsInThePast12Months: string;
    areSponsorLogosPlacedAboveTheFoldOnYourWebsite: string;
    doesYourEventHaveVideoContentThatIsSponsorable: string;
    dosponsorLogosOnYourWebsiteLinkToThePartnerSSite: string;
    howManyPeopleParticipateInYourEventOrVisitYourPropertyEachYear: string;
  };
  totalPiecesOfEventCollateral: {
    eventSignage: string;
    participantBibs: string;
    eventInfoPackets: string;
    eventBrochureFlyer: string;
  };
  livePartnerActivation: {
    booth10x10: string;
    swagBagSampling: string;
    liveAnnoucements: string;
    signatureExperience: string;
  };
  shared: {
    howManyTwitterFollowersDoYouHave: string;
    howManySnapchatFollowersDoYouHave: string;
    howManyInstagramFollowersDoYouHave: string;
    howManyPinterestFollowersDoYouHave: string;
    howManyYouTubeSubscribersDoYouHave: string;
    owManyFacebookFollowersPageLikesDoYouHave: string;
  };
  customAssets: {
    name: string;
    totalNumberOfImpressions: string;
  }[];
}

export interface PricingData {
  id: string;
  name: string;
  status: ValuationStatusEnum;
  teamId: string;
  authorId: string;
  lastModifyUserId: null;
  campaignStart: null;
  campaignEnd: null;
  dateSubmitted: null;
  dateCompleted: null;
  createdAt: string;
  updatedAt: string;
  categorize: 'Property' | 'Organization';
  progressPercentage: number;
  suggestedMinimumThreshold: number;
  totalAssetValue: number;
  currentMinimumThreshold: string;
  industryRanking: string;
  unaidedBrandAwareness: string;
  aidedBrandAwareness: string;
  constituentData: string;
  communityImpact: string;
  senseOfUrgencyCurable: true;
  senseOfUrgencyPreventable: true;
  audienceLoyalty: string;
  audienceActivation: string;
  fundraisingIncome: string[];
  operationalExpenses: string;
  geographicScope: string[];
  corporatePartners: string;
  corporatePartnershipStrategy: string;
  influencerSupport: string;
  brandExposure: string;
  organizationSize: string;
  paidMedia: {
    totalSpentOnRadio: string;
    totalRadioImpressions: string;
    totalSpentOnOutOfHome: string;
    totalSpentOnPrintMedia: string;
    totalSpentOnTelevision: string;
    totalDigitalImpressions: string;
    totalSpentOnDigitalMedia: string;
    totalOutOfHomeImpressions: string;
    totalTelevisionImpressions: string;
    totalPrintPublicationImpressions: string;
  };
  earnedMedia: {
    totalEarnedMediaImpressionsInTheLast12Months: string;
  };
  ownedMedia: {
    whatIsYourOverallEmailOpenRate: string;
    totalDigitalPublicationImpressions: string;
    totalWebsiteImpressionsInThePast12Months: string;
    areSponsorLogosPlacedAboveTheFoldOnYourWebsite: string;
    doesYourEventHaveVideoContentThatIsSponsorable: string;
    dosponsorLogosOnYourWebsiteLinkToThePartnerSSite: string;
    howManyPeopleParticipateInYourEventOrVisitYourPropertyEachYear: string;
  };
  totalPiecesOfEventCollateral: {
    eventSignage: string;
    participantBibs: string;
    eventInfoPackets: string;
    eventBrochureFlyer: string;
  };
  livePartnerActivation: {
    booth10x10: string;
    swagBagSampling: string;
    liveAnnoucements: string;
    signatureExperience: string;
  };
  shared: {
    howManyTwitterFollowersDoYouHave: string;
    howManySnapchatFollowersDoYouHave: string;
    howManyInstagramFollowersDoYouHave: string;
    howManyPinterestFollowersDoYouHave: string;
    howManyYouTubeSubscribersDoYouHave: string;
    owManyFacebookFollowersPageLikesDoYouHave: string;
  };
  customAssets: {
    name: string;
    totalNumberOfImpressions: string;
  }[];
}

export interface ReportData extends PricingData {
  calIntangibleAssetValues: {
    type: string;
    benchmark: number;
    prescriptiveAnalytics: string;
    value: number;
  }[];
  calTangibleAssetValue: {
    groupType: string;
    type: string;
    valuePerImpression: number;
    impression: string;
    value: number;
  }[];
  potentialAssetValue: 500000;
  tierOne: 30;
  tierTwo: 18;
  tierThree: 12;
  calculation: {
    IntangibleAssetValue: 55;
    AverageMultipleRating: 3.4375;
    SuggestedMinimumThreshold: 150000;
    TangibleAssetValue: 5;
    TotalAssetValue: 60;
    PaidMediaValue: 5;
    EarnedMediaValue: 0;
    OwnedMediaValue: 0;
    SharedMediaValue: 0;
    PotentialMinimumThreshold: 500000;
    AreasOfGrowth: {
      type: string;
      benchmark: number;
      prescriptiveAnalytics: string;
      value: number;
    }[];
    AssetGroups: {
      children: {
        Asset: string;
        Value: number;
        TierOne: boolean;
        TierThree: boolean;
        TierTwo: boolean;
      }[];
      name: string;
    }[];
    SponsorshipLevels: {
      SponsorshipLevel: string;
      TierOne: string;
      TierThree: string;
      TierTwo: string;
    }[];
    RecommendedAssetDevelopment: {
      current: number;
      type: string;
      precent: number;
      recommend: string[];
    }[];
    TangibleAssetValueDetail: {
      type: string;
      benchmark: number;
      value: number;
      impression: number;
      percentage: number;
    }[];
    PrescriptiveAnalytics: string;
  };
  file: string | null;
  team: TeamProfileData;
  author: User;
}

export interface PricingListRes {
  items: PricingData[];
  meta: PaginationMeta;
}

export interface DashboardPricing {
  TotalAssetValue: {
    id: string;
    name: string;
    totalAssetValue: string;
  }[];
  PotentialAssetValue: {
    id: string;
    name: string;
    potentialAssetValue: string;
  }[];
  RecommendedPricing: {
    id: string;
    name: string;
    tierOne: string;
    tierThree: string;
    tierTwo: string;
  }[];
  TopAreasForGrowth: {
    benchmark: number;
    diffValue: number;
    prescriptiveAnalytics: string;
    type: string;
    value: number;
  }[];
}

export interface PricingListReq extends PaginationReq {
  teamId: string;
}

export interface PricingStoreData {
  pricingList: PricingListRes;
  pricing: PricingData | undefined;
  dashboardPricing: DashboardPricing;
  report?: ReportData;
  adminNotes: ObjNoteData[];
}

export enum ValuationStatusEnum {
  New = 'New',
  CustomerDataRequired = 'Customer Data Required',
  Completed = 'Completed',
  InProgress = 'In Progress',
  PendingAdminReview = 'Pending Admin Review',
}
