import CardProspect from 'components/CardProspect';
import EmptyProspecting from 'components/EmptyProspecting';
import List from 'components/List';
import { Prospect } from 'interfaces/prospects';
import React from 'react';

interface Props {
  data: Prospect[];
}

const ProspectingDashboard = ({ data }: Props) => {
  return data && data.length > 0 ? (
    <List<Prospect, object> list={data.slice(0, 2)} component={CardProspect} />
  ) : (
    <EmptyProspecting />
  );
};

export default ProspectingDashboard;
