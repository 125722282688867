import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { UISuggestProps } from './interfaces';

type InputValue = string;

function UISuggest({
  list,
  handleGetSuggestions,
  handleSetValue,
  placeholder,
}: UISuggestProps) {
  const [value, onChangeInputValue] = useState<InputValue>('');

  const onChange = (event, { newValue }) => {
    onChangeInputValue(newValue);
  };

  const getSuggestionValue = (suggestion: any) => {
    return suggestion.name;
  };

  const renderSuggestion = (val: any) => (
    <div className="autosuggest__item">{val.name}</div>
  );

  const onSuggestionsFetchRequested = ({ value }) => {
    handleGetSuggestions(value.trim().toLowerCase());
  };

  const inputProps = {
    placeholder,
    value,
    onChange: onChange,
  };

  return (
    <Autosuggest
      suggestions={list}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={() => {}}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      onSuggestionSelected={(e, { suggestion }) => {
        handleSetValue(suggestion);
        onChangeInputValue('');
      }}
    />
  );
}

export default UISuggest;
