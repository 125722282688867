import { FilterData } from 'components/Forms/interfaces';

export const initialValuesFilter: FilterData = {
  q: '',
  gender: 'both',
  variantForm: 'Advanced',
  dataType: 'company',
  logicType: 'and',
  filterType: 'Priority Match',
};
