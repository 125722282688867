import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { formatValueEnum } from 'components/UI/interfaces';
import CheckBoxCollapsible from './components/CheckBoxCollapsible';
import {
  AmbassadorsMetric,
  BaselineMetric,
  BottomLineImpactMetric,
  BusinessValueMetric,
  CashMetric,
  ConstituentEngagementMetric,
  ContentStreamMetric,
  CorporateIntangibleAssetMetric,
  EarnedMediaImpressionsMetric,
  FundRaisedMetric,
  FundraisingMetric,
  HeatMapMetric,
  ImpressionsMetric,
  LoyaltyMetric,
  MediaExposureMetric,
  MissionAdvancementMetric,
  MissonImpactMetric,
  OverallPartnershipValueMetric,
  OwnedMediaImpressionsMetric,
  PaidMediaImpressionsMetric,
  ParticipationMetric,
  PotentialCustomerValueMetric,
  PotentialTurnoverCostsSavedMetric,
  ReturnOnPartnershipMetric,
  ScopeOfImpactMetric,
  SDGAlignmentMetric,
  SentimentMetric,
  SocialMediaImpressionsMetric,
  StewardshipActivitiesMetric,
  TopAuthorsMetric,
  TopRetweetedMetric,
  TotalGivingMetric,
  TotalPotentialReachMetric,
  TrendingThemesMetric,
  TypesOfFundsRaisedMetric,
  TypesOfVolunteeringMetric,
  ValueMetric,
  VolunteeringParticipationRateMetric,
} from './metrics';
import CheckBoxMetric from './components/CheckBoxMetric';
import {
  ROICanvaMetricsCategoryOptions,
  ROICanvaMetricElId,
  ROICanvaMetricsCategory,
  ROICanvaMetricElIdList,
} from 'constants/roi';
import { FieldRenderProps } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Field } from 'components/FinalForm';
import { FieldCheckbox, FiledMultiSelectWithCheckBoxes } from 'components/FormFields';
import { FormProps } from './ROICreateCustomizeReport';
import { ROIData, OwnedMediaTotalItem, ROIMeltwaterData } from 'interfaces/roi';
import { FormApi } from 'final-form';
import { someNotNull } from 'utils';
import { useBrandKits } from 'hooks/common';
import { ROIReport } from 'interfaces/roi';

interface Props {
  roi?: ROIData;
  form: FormApi<FormProps, Partial<FormProps>>;
  values: FormProps;
  meltwaterData?: ROIMeltwaterData | '';
}

const SelectCanvaMetricsSection = ({ roi, form, values, meltwaterData }: Props) => {
  const [selectedMetricsCategory, setSelectedMetricsCategory] = useState<string[]>(
    ROICanvaMetricsCategoryOptions.map(item => item.value),
  );

  const { themeColor } = useBrandKits();

  const ownedMediaValue = useMemo(() => {
    const ownedMediaActivation = roi?.calculation?.partnerActivationItems.find(
      item => item.group === 'OWNED MEDIA',
    );
    if (!ownedMediaActivation) return;

    type OwnedMediaValueType = {
      [k in OwnedMediaTotalItem['asset']]: {
        totalValue: number;
        totalImpressions: number;
      };
    };

    return {
      nonprofit: ownedMediaActivation.nonprofitChildren?.reduce((prev, curr) => {
        prev[curr.asset] = {
          totalValue: curr.totalValue,
          totalImpressions: curr.totalImpressions,
        };
        return prev;
      }, {} as OwnedMediaValueType),
      partner: ownedMediaActivation.partnerChildren?.reduce((prev, curr) => {
        prev[curr.asset] = {
          totalValue: curr.totalValue,
          totalImpressions: curr.totalImpressions,
        };
        return prev;
      }, {} as OwnedMediaValueType),
    };
  }, [roi]);

  const baseline = useMemo(() => {
    const data = roi?.calculation?.intangiblePartnershipBenchmark.items.reduce(
      (prev, curr) => {
        prev[curr.type] = curr;
        return prev;
      },
      {} as { [k: string]: ROIReport['intangiblePartnershipBenchmark']['items'][0] },
    );

    return {
      'Brand Value': data?.['Brand Value'],
      Stewardship: data?.['Stewardship'],
      'Ambassador Engagement': data?.['Ambassador Engagement'],
      'Paid/Donated Media': data?.['Paid/Donated Media'],
      'Owned Media': data?.['Owned Media'],
      'Social Media': data?.['Social Media'],
      'Earned Media': data?.['Earned Media'],
    };
  }, [roi]);

  return (
    <>
      <Desc>Please select the data points you would like to include.</Desc>
      <Title>Customize Partner Report</Title>
      <div style={{ width: '351px' }}>
        <Field
          name="metricsCategory"
          component={FiledMultiSelectWithCheckBoxes}
          options={ROICanvaMetricsCategoryOptions}
          hasSelectAll
          selectAllLabel="All Data Points"
          formatValue={formatValueEnum.BASE_ARRAY}
        />
      </div>
      <OnChange name="metricsCategory">
        {(
          value: FormProps['metricsCategory'],
          previous: FormProps['metricsCategory'],
        ) => {
          if (value === previous) return;
          setSelectedMetricsCategory(value);
        }}
      </OnChange>
      <div style={{ marginBottom: '30px' }}>
        <Field name="isAll" label="Select All" component={FieldCheckbox} />
      </div>
      <OnChange name="isAll">
        {(value: FormProps['isAll'], previous: FormProps['isAll']) => {
          if (value === previous) return;
          if (value) return form.change('chartIds', ROICanvaMetricElIdList);
          if (values.chartIds.length === ROICanvaMetricElIdList.length)
            form.change('chartIds', []);
        }}
      </OnChange>
      <Field name="chartIds">
        {({ input }: FieldRenderProps<string[]>) => {
          return (
            <>
              {(selectedMetricsCategory || []).includes(
                ROICanvaMetricsCategory.PartnershipValue,
              ) && (
                <CheckBoxCollapsible
                  title={ROICanvaMetricsCategory.PartnershipValue}
                  input={input}
                  listenMetricElIds={[
                    ROICanvaMetricElId.OverallPartnershipValueMetric,
                    ROICanvaMetricElId.ReturnOnPartnershipMetric,
                  ]}
                >
                  {someNotNull([
                    roi?.calculation?.intangiblePartnershipBenchmark
                      .overallPartnershipValuetoNonprofit,
                    roi?.calculation?.intangiblePartnershipBenchmark
                      .overallPartnershipValuetoCompany,
                  ]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.OverallPartnershipValueMetric}
                      input={input}
                    >
                      <OverallPartnershipValueMetric
                        themeColor={themeColor?.Secondary}
                        companyValue={Number(
                          roi?.calculation?.intangiblePartnershipBenchmark
                            .overallPartnershipValuetoCompany,
                        )}
                        nonprofitValue={Number(
                          roi?.calculation?.intangiblePartnershipBenchmark
                            .overallPartnershipValuetoNonprofit,
                        )}
                      />
                    </CheckBoxMetric>
                  )}
                  {someNotNull([
                    roi?.calculation?.intangiblePartnershipBenchmark
                      .returnOnPartnershiptoNonprofit,
                    roi?.calculation?.intangiblePartnershipBenchmark
                      .returnOnPartnershiptoCompany,
                  ]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.ReturnOnPartnershipMetric}
                      input={input}
                    >
                      <ReturnOnPartnershipMetric
                        themeColor={themeColor?.Primary}
                        nonprofitValue={Number(
                          roi?.calculation?.intangiblePartnershipBenchmark
                            .returnOnPartnershiptoNonprofit,
                        )}
                        companyValue={Number(
                          roi?.calculation?.intangiblePartnershipBenchmark
                            .returnOnPartnershiptoCompany,
                        )}
                        isCompany={!!roi?.isCompany}
                      />
                    </CheckBoxMetric>
                  )}
                </CheckBoxCollapsible>
              )}

              {(selectedMetricsCategory || []).includes(
                ROICanvaMetricsCategory.Fundraising,
              ) && (
                <CheckBoxCollapsible
                  title={ROICanvaMetricsCategory.Fundraising}
                  input={input}
                  listenMetricElIds={[
                    ROICanvaMetricElId.FundraisingMetric,
                    ROICanvaMetricElId.CashMetric,
                    ROICanvaMetricElId.TypesOfFundsRaisedMetric,
                    ROICanvaMetricElId.FundRaisedMetric,
                  ]}
                >
                  {someNotNull([roi?.typesOfFundsRaised]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.TypesOfFundsRaisedMetric}
                      input={input}
                    >
                      <TypesOfFundsRaisedMetric
                        themeColor={themeColor?.Tertiary}
                        typesOfFundsRaised={roi?.typesOfFundsRaised || []}
                      />
                    </CheckBoxMetric>
                  )}
                  {someNotNull([
                    roi?.fundraising?.totalFundsRaisedFromConsumers,
                    roi?.fundraising?.totalFundsRaisedFromEmployees,
                  ]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.CashMetric}
                      input={input}
                    >
                      <CashMetric
                        themeColor={themeColor?.Primary}
                        consumers={Number(
                          roi?.fundraising?.totalFundsRaisedFromConsumers,
                        )}
                        employees={Number(
                          roi?.fundraising?.totalFundsRaisedFromEmployees,
                        )}
                      />
                    </CheckBoxMetric>
                  )}
                  {someNotNull([
                    roi?.fundraising?.totalFundsRaisedFromCompany,
                    roi?.fundraising?.totalUnderwritingOrAdditionalFunds,
                  ]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.FundraisingMetric}
                      input={input}
                    >
                      <FundraisingMetric
                        themeColor={themeColor?.Secondary}
                        cash={Number(roi?.fundraising?.totalFundsRaisedFromCompany)}
                        nonCash={Number(
                          roi?.fundraising?.totalUnderwritingOrAdditionalFunds,
                        )}
                      />
                    </CheckBoxMetric>
                  )}
                  {someNotNull([
                    roi?.calculation?.totalGiving,
                    roi?.fundraising?.totalFundsRaisedFromConsumers,
                    roi?.fundraising?.totalFundsRaisedFromEmployees,
                    roi?.fundraising?.totalUnderwritingOrAdditionalFunds,
                  ]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.FundRaisedMetric}
                      input={input}
                    >
                      <FundRaisedMetric
                        themeColor={themeColor?.Secondary}
                        totalFundsRaised={Number(roi?.calculation?.totalGiving)}
                        consumers={Number(
                          roi?.fundraising?.totalFundsRaisedFromConsumers,
                        )}
                        employees={Number(
                          roi?.fundraising?.totalFundsRaisedFromEmployees,
                        )}
                        nonCash={Number(
                          roi?.fundraising?.totalUnderwritingOrAdditionalFunds,
                        )}
                      />
                    </CheckBoxMetric>
                  )}
                </CheckBoxCollapsible>
              )}

              {(selectedMetricsCategory || []).includes(
                ROICanvaMetricsCategory.BusinessValue,
              ) && (
                <CheckBoxCollapsible
                  title={ROICanvaMetricsCategory.BusinessValue}
                  input={input}
                  listenMetricElIds={[
                    ROICanvaMetricElId.PaidMediaImpressionsMetricTelevision,
                    ROICanvaMetricElId.PaidMediaImpressionsMetricPublication,
                    ROICanvaMetricElId.PaidMediaImpressionsMetricOutofHome,
                    ROICanvaMetricElId.PaidMediaImpressionsMetricDigital,
                    ROICanvaMetricElId.PaidMediaImpressionsMetricRadio,
                    ROICanvaMetricElId.OwnedMediaImpressionsMetricMagazine,
                    ROICanvaMetricElId.OwnedMediaImpressionsMetricWebsite,
                    ROICanvaMetricElId.OwnedMediaImpressionsMetricInOffice,
                    ROICanvaMetricElId.OwnedMediaImpressionsMetricECommunication,
                    ROICanvaMetricElId.OwnedMediaImpressionsMetricCollateral,
                    ROICanvaMetricElId.OwnedMediaImpressionsMetricInStore,
                    ROICanvaMetricElId.OwnedMediaImpressionsMetricEventParticipants,
                    ROICanvaMetricElId.OwnedMediaImpressionsMetricEmployee,
                    ROICanvaMetricElId.SocialMediaImpressionsMetric,
                    ROICanvaMetricElId.EarnedMediaImpressionsMetric,
                    ROICanvaMetricElId.ImpressionsMetric,
                    ROICanvaMetricElId.ValueMetric,
                  ]}
                >
                  {someNotNull([
                    roi?.paidMedia
                      ?.numberOfTelevisionImpressionsnumberOfTelevisionImpressions,
                    roi?.paidMedia?.totalSpentOnTelevisiontotalSpentOnTelevision,
                    roi?.partnerActivation?.paidMedia
                      ?.numberOfTelevisionImpressionsnumberOfTelevisionImpressions,
                    roi?.partnerActivation?.paidMedia
                      ?.totalSpentOnTelevisiontotalSpentOnTelevision,
                  ]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.PaidMediaImpressionsMetricTelevision}
                      input={input}
                    >
                      <PaidMediaImpressionsMetric
                        themeColor={themeColor?.Primary}
                        type={'Television'}
                        companyImpressions={Number(
                          roi?.paidMedia
                            ?.numberOfTelevisionImpressionsnumberOfTelevisionImpressions,
                        )}
                        companySpend={Number(
                          roi?.paidMedia?.totalSpentOnTelevisiontotalSpentOnTelevision,
                        )}
                        nonprofitImpressions={Number(
                          roi?.partnerActivation?.paidMedia
                            ?.numberOfTelevisionImpressionsnumberOfTelevisionImpressions,
                        )}
                        nonprofitSpend={Number(
                          roi?.partnerActivation?.paidMedia
                            ?.totalSpentOnTelevisiontotalSpentOnTelevision,
                        )}
                      />
                    </CheckBoxMetric>
                  )}
                  {someNotNull([
                    roi?.paidMedia
                      ?.numberOfPrintPublicationImpressionsnumberOfPrintPublicationImpressions,
                    roi?.paidMedia?.totalSpentOnPrintMediatotalSpentOnPrintMedia,
                    roi?.partnerActivation?.paidMedia
                      ?.numberOfPrintPublicationImpressionsnumberOfPrintPublicationImpressions,
                    roi?.partnerActivation?.paidMedia
                      ?.totalSpentOnPrintMediatotalSpentOnPrintMedia,
                  ]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.PaidMediaImpressionsMetricPublication}
                      input={input}
                    >
                      <PaidMediaImpressionsMetric
                        themeColor={themeColor?.Primary}
                        type={'Publication'}
                        companyImpressions={Number(
                          roi?.paidMedia
                            ?.numberOfPrintPublicationImpressionsnumberOfPrintPublicationImpressions,
                        )}
                        companySpend={Number(
                          roi?.paidMedia?.totalSpentOnPrintMediatotalSpentOnPrintMedia,
                        )}
                        nonprofitImpressions={Number(
                          roi?.partnerActivation?.paidMedia
                            ?.numberOfPrintPublicationImpressionsnumberOfPrintPublicationImpressions,
                        )}
                        nonprofitSpend={Number(
                          roi?.partnerActivation?.paidMedia
                            ?.totalSpentOnPrintMediatotalSpentOnPrintMedia,
                        )}
                      />
                    </CheckBoxMetric>
                  )}
                  {someNotNull([
                    roi?.paidMedia
                      ?.numberOfOutOfHomeImpressionsnumberOfOutOfHomeImpressions,
                    roi?.paidMedia
                      ?.totalSpentOnOutOfHomeImpressionstotalSpentOnOutOfHomeImpressions,
                    roi?.partnerActivation?.paidMedia
                      ?.numberOfOutOfHomeImpressionsnumberOfOutOfHomeImpressions,
                    roi?.partnerActivation?.paidMedia
                      ?.totalSpentOnOutOfHomeImpressionstotalSpentOnOutOfHomeImpressions,
                  ]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.PaidMediaImpressionsMetricOutofHome}
                      input={input}
                    >
                      <PaidMediaImpressionsMetric
                        themeColor={themeColor?.Primary}
                        type={'Out-of-Home'}
                        companyImpressions={Number(
                          roi?.paidMedia
                            ?.numberOfOutOfHomeImpressionsnumberOfOutOfHomeImpressions,
                        )}
                        companySpend={Number(
                          roi?.paidMedia
                            ?.totalSpentOnOutOfHomeImpressionstotalSpentOnOutOfHomeImpressions,
                        )}
                        nonprofitImpressions={Number(
                          roi?.partnerActivation?.paidMedia
                            ?.numberOfOutOfHomeImpressionsnumberOfOutOfHomeImpressions,
                        )}
                        nonprofitSpend={Number(
                          roi?.partnerActivation?.paidMedia
                            ?.totalSpentOnOutOfHomeImpressionstotalSpentOnOutOfHomeImpressions,
                        )}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([
                    roi?.paidMedia?.numberOfDigitalImpressionsnumberOfDigitalImpressions,
                    roi?.paidMedia?.totalSpentOnDigitalMediatotalSpentOnDigitalMedia,
                    roi?.partnerActivation?.paidMedia
                      ?.numberOfDigitalImpressionsnumberOfDigitalImpressions,
                    roi?.partnerActivation?.paidMedia
                      ?.totalSpentOnDigitalMediatotalSpentOnDigitalMedia,
                  ]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.PaidMediaImpressionsMetricDigital}
                      input={input}
                    >
                      <PaidMediaImpressionsMetric
                        themeColor={themeColor?.Primary}
                        type={'Digital'}
                        companyImpressions={Number(
                          roi?.paidMedia
                            ?.numberOfDigitalImpressionsnumberOfDigitalImpressions,
                        )}
                        companySpend={Number(
                          roi?.paidMedia
                            ?.totalSpentOnDigitalMediatotalSpentOnDigitalMedia,
                        )}
                        nonprofitImpressions={Number(
                          roi?.partnerActivation?.paidMedia
                            ?.numberOfDigitalImpressionsnumberOfDigitalImpressions,
                        )}
                        nonprofitSpend={Number(
                          roi?.partnerActivation?.paidMedia
                            ?.totalSpentOnDigitalMediatotalSpentOnDigitalMedia,
                        )}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([
                    roi?.paidMedia?.numberOfRadioImpressionsnumberOfRadioImpressions,
                    roi?.paidMedia?.totalSpentOnRadiototalSpentOnRadio,
                    roi?.partnerActivation?.paidMedia
                      ?.numberOfRadioImpressionsnumberOfRadioImpressions,
                    roi?.partnerActivation?.paidMedia?.totalSpentOnRadiototalSpentOnRadio,
                  ]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.PaidMediaImpressionsMetricRadio}
                      input={input}
                    >
                      <PaidMediaImpressionsMetric
                        themeColor={themeColor?.Primary}
                        type={'Radio'}
                        companyImpressions={Number(
                          roi?.paidMedia
                            ?.numberOfRadioImpressionsnumberOfRadioImpressions,
                        )}
                        companySpend={Number(
                          roi?.paidMedia?.totalSpentOnRadiototalSpentOnRadio,
                        )}
                        nonprofitImpressions={Number(
                          roi?.partnerActivation?.paidMedia
                            ?.numberOfRadioImpressionsnumberOfRadioImpressions,
                        )}
                        nonprofitSpend={Number(
                          roi?.partnerActivation?.paidMedia
                            ?.totalSpentOnRadiototalSpentOnRadio,
                        )}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([
                    ownedMediaValue?.partner?.Magazines,
                    ownedMediaValue?.nonprofit?.Magazines,
                  ]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.OwnedMediaImpressionsMetricMagazine}
                      input={input}
                    >
                      <OwnedMediaImpressionsMetric
                        themeColor={themeColor?.Secondary}
                        type={'Magazine'}
                        companyImpressions={Number(
                          ownedMediaValue?.partner?.Magazines?.totalImpressions,
                        )}
                        nonprofitImpressions={Number(
                          ownedMediaValue?.nonprofit?.Magazines?.totalImpressions,
                        )}
                        companyValue={Number(
                          ownedMediaValue?.partner?.Magazines?.totalValue,
                        )}
                        nonprofitValue={Number(
                          ownedMediaValue?.nonprofit?.Magazines?.totalValue,
                        )}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([
                    ownedMediaValue?.partner?.Website,
                    ownedMediaValue?.nonprofit?.Website,
                  ]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.OwnedMediaImpressionsMetricWebsite}
                      input={input}
                    >
                      <OwnedMediaImpressionsMetric
                        themeColor={themeColor?.Secondary}
                        type={'Website'}
                        companyImpressions={Number(
                          ownedMediaValue?.partner?.Website?.totalImpressions,
                        )}
                        nonprofitImpressions={Number(
                          ownedMediaValue?.nonprofit?.Website?.totalImpressions,
                        )}
                        companyValue={Number(
                          ownedMediaValue?.partner?.Website?.totalValue,
                        )}
                        nonprofitValue={Number(
                          ownedMediaValue?.nonprofit?.Website?.totalValue,
                        )}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([
                    ownedMediaValue?.partner?.['In-office signage'],
                    ownedMediaValue?.nonprofit?.['In-office signage'],
                  ]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.OwnedMediaImpressionsMetricInOffice}
                      input={input}
                    >
                      <OwnedMediaImpressionsMetric
                        themeColor={themeColor?.Secondary}
                        type={'In-Office'}
                        companyImpressions={Number(
                          ownedMediaValue?.partner?.['In-office signage']
                            ?.totalImpressions,
                        )}
                        nonprofitImpressions={Number(
                          ownedMediaValue?.nonprofit?.['In-office signage']
                            ?.totalImpressions,
                        )}
                        companyValue={Number(
                          ownedMediaValue?.partner?.['In-office signage']?.totalValue,
                        )}
                        nonprofitValue={Number(
                          ownedMediaValue?.nonprofit?.['In-office signage']?.totalValue,
                        )}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([
                    ownedMediaValue?.partner?.['E-Communications'],
                    ownedMediaValue?.nonprofit?.['E-Communications'],
                  ]) && (
                    <CheckBoxMetric
                      metricId={
                        ROICanvaMetricElId.OwnedMediaImpressionsMetricECommunication
                      }
                      input={input}
                    >
                      <OwnedMediaImpressionsMetric
                        themeColor={themeColor?.Secondary}
                        type={'e-Communication'}
                        companyImpressions={Number(
                          ownedMediaValue?.partner?.['E-Communications']
                            ?.totalImpressions,
                        )}
                        nonprofitImpressions={Number(
                          ownedMediaValue?.nonprofit?.['E-Communications']
                            ?.totalImpressions,
                        )}
                        companyValue={Number(
                          ownedMediaValue?.partner?.['E-Communications']?.totalValue,
                        )}
                        nonprofitValue={Number(
                          ownedMediaValue?.nonprofit?.['E-Communications']?.totalValue,
                        )}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([
                    ownedMediaValue?.partner?.Collateral,
                    ownedMediaValue?.partner?.Collateral,
                  ]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.OwnedMediaImpressionsMetricCollateral}
                      input={input}
                    >
                      <OwnedMediaImpressionsMetric
                        themeColor={themeColor?.Secondary}
                        type={'Collateral'}
                        companyImpressions={Number(
                          ownedMediaValue?.partner?.Collateral?.totalImpressions,
                        )}
                        nonprofitImpressions={Number(
                          ownedMediaValue?.nonprofit?.Collateral?.totalImpressions,
                        )}
                        companyValue={Number(
                          ownedMediaValue?.partner?.Collateral?.totalValue,
                        )}
                        nonprofitValue={Number(
                          ownedMediaValue?.nonprofit?.Collateral?.totalValue,
                        )}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([
                    ownedMediaValue?.partner?.['In-store POS & displays'],
                    ownedMediaValue?.nonprofit?.['In-store POS & displays'],
                  ]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.OwnedMediaImpressionsMetricInStore}
                      input={input}
                    >
                      <OwnedMediaImpressionsMetric
                        themeColor={themeColor?.Secondary}
                        type={'In-Store'}
                        companyImpressions={Number(
                          ownedMediaValue?.partner?.['In-store POS & displays']
                            ?.totalImpressions,
                        )}
                        nonprofitImpressions={Number(
                          ownedMediaValue?.nonprofit?.['In-store POS & displays']
                            ?.totalImpressions,
                        )}
                        companyValue={Number(
                          ownedMediaValue?.partner?.['In-store POS & displays']
                            ?.totalValue,
                        )}
                        nonprofitValue={Number(
                          ownedMediaValue?.nonprofit?.['In-store POS & displays']
                            ?.totalValue,
                        )}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([
                    ownedMediaValue?.partner?.['Number of event participants'],
                    ownedMediaValue?.nonprofit?.['Number of event participants'],
                  ]) && (
                    <CheckBoxMetric
                      metricId={
                        ROICanvaMetricElId.OwnedMediaImpressionsMetricEventParticipants
                      }
                      input={input}
                    >
                      <OwnedMediaImpressionsMetric
                        themeColor={themeColor?.Secondary}
                        type={'Event Participants'}
                        companyImpressions={Number(
                          ownedMediaValue?.partner?.['Number of event participants']
                            ?.totalImpressions,
                        )}
                        nonprofitImpressions={Number(
                          ownedMediaValue?.nonprofit?.['Number of event participants']
                            ?.totalImpressions,
                        )}
                        companyValue={Number(
                          ownedMediaValue?.partner?.['Number of event participants']
                            ?.totalValue,
                        )}
                        nonprofitValue={Number(
                          ownedMediaValue?.nonprofit?.['Number of event participants']
                            ?.totalValue,
                        )}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([
                    ownedMediaValue?.partner?.['Employee communications'],
                    ownedMediaValue?.nonprofit?.['Employee communications'],
                  ]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.OwnedMediaImpressionsMetricEmployee}
                      input={input}
                    >
                      <OwnedMediaImpressionsMetric
                        themeColor={themeColor?.Secondary}
                        type={'Employee'}
                        companyImpressions={Number(
                          ownedMediaValue?.partner?.['Employee communications']
                            ?.totalImpressions,
                        )}
                        nonprofitImpressions={Number(
                          ownedMediaValue?.nonprofit?.['Employee communications']
                            ?.totalImpressions,
                        )}
                        companyValue={Number(
                          ownedMediaValue?.partner?.['Employee communications']
                            ?.totalValue,
                        )}
                        nonprofitValue={Number(
                          ownedMediaValue?.nonprofit?.['Employee communications']
                            ?.totalValue,
                        )}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([
                    roi?.calculation?.socialMediaImpression,
                    roi?.calculation?.socialOwnedMediaValue,
                  ]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.SocialMediaImpressionsMetric}
                      input={input}
                    >
                      <SocialMediaImpressionsMetric
                        themeColor={themeColor?.Complementary}
                        total={Number(roi?.calculation?.socialMediaImpression)}
                        value={Number(roi?.calculation?.socialOwnedMediaValue)}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([
                    roi?.calculation?.earnedMediaImpression,
                    roi?.calculation?.earnedMediaValue,
                  ]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.EarnedMediaImpressionsMetric}
                      input={input}
                    >
                      <EarnedMediaImpressionsMetric
                        themeColor={themeColor?.Secondary}
                        total={Number(roi?.calculation?.earnedMediaImpression)}
                        value={Number(roi?.calculation?.earnedMediaValue)}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([roi?.calculation?.totalMediaImpression]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.ImpressionsMetric}
                      input={input}
                    >
                      <ImpressionsMetric
                        totalMediaImpressions={Number(
                          roi?.calculation?.totalMediaImpression,
                        )}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([roi?.calculation?.totalMediaValue]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.ValueMetric}
                      input={input}
                    >
                      <ValueMetric
                        totalMediaValue={Number(roi?.calculation?.totalMediaValue)}
                      />
                    </CheckBoxMetric>
                  )}
                </CheckBoxCollapsible>
              )}

              {(selectedMetricsCategory || []).includes(
                ROICanvaMetricsCategory.CorporateIntangibleAssets,
              ) && (
                <CheckBoxCollapsible
                  title={ROICanvaMetricsCategory.CorporateIntangibleAssets}
                  input={input}
                  listenMetricElIds={[
                    ROICanvaMetricElId.VolunteeringParticipationRateMetric,
                    ROICanvaMetricElId.TypesOfVolunteeringMetric,
                    ROICanvaMetricElId.ConstituentEngagementMetric,
                    ROICanvaMetricElId.LoyaltyMetric,
                    ROICanvaMetricElId.TotalGivingMetric,
                    ROICanvaMetricElId.CorporateIntangibleAssetMetric,
                  ]}
                >
                  {someNotNull([roi?.volunteeringParticipationRate]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.VolunteeringParticipationRateMetric}
                      input={input}
                    >
                      <VolunteeringParticipationRateMetric
                        themeColor={themeColor?.Primary}
                        rate={Number(roi?.volunteeringParticipationRate)}
                      />
                    </CheckBoxMetric>
                  )}
                  {someNotNull([roi?.calculation?.totalGiving]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.TotalGivingMetric}
                      input={input}
                    >
                      <TotalGivingMetric
                        themeColor={themeColor?.Secondary}
                        totalGiving={Number(roi?.givingParticipationRate)}
                      />
                    </CheckBoxMetric>
                  )}
                  {someNotNull([roi?.typesOfVolunteering]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.TypesOfVolunteeringMetric}
                      input={input}
                    >
                      <TypesOfVolunteeringMetric
                        themeColor={themeColor?.Tertiary}
                        typesOfVolunteering={roi?.typesOfVolunteering || []}
                      />
                    </CheckBoxMetric>
                  )}
                  {someNotNull([roi?.calculation?.constituentEngagement]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.ConstituentEngagementMetric}
                      input={input}
                    >
                      <ConstituentEngagementMetric
                        constituentEngagement={Number(
                          roi?.calculation?.constituentEngagement,
                        )}
                      />
                    </CheckBoxMetric>
                  )}
                  {someNotNull([roi?.customerLoyalty, roi?.employeeLoyalty]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.LoyaltyMetric}
                      input={input}
                    >
                      <LoyaltyMetric
                        themeColor1={themeColor?.Secondary}
                        themeColor2={themeColor?.Primary}
                        customerLoyalty={Number(roi?.customerLoyalty)}
                        employeeLoyalty={Number(roi?.employeeLoyalty)}
                      />
                    </CheckBoxMetric>
                  )}
                  {someNotNull([roi?.customerParticipation, roi?.employeeLoyalty]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.ParticipationMetric}
                      input={input}
                    >
                      <ParticipationMetric
                        themeColor1={themeColor?.Complementary}
                        themeColor2={themeColor?.Secondary}
                        customerParticipation={Number(roi?.customerParticipation)}
                        employeeParticipation={Number(roi?.employeeParticipation)}
                      />
                    </CheckBoxMetric>
                  )}
                  {someNotNull([roi?.calculation?.corporateIntangibleAssetsItems]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.CorporateIntangibleAssetMetric}
                      input={input}
                    >
                      <CorporateIntangibleAssetMetric
                        corporateIntangibleAssets={
                          roi?.calculation?.corporateIntangibleAssetsItems?.filter(item =>
                            [
                              'Consumer Awareness',
                              'Consumer Sentiment',
                              'Employee Awareness',
                              'Employee Sentiment',
                            ].includes(item.label),
                          ) || []
                        }
                      />
                    </CheckBoxMetric>
                  )}
                </CheckBoxCollapsible>
              )}

              {(selectedMetricsCategory || []).includes(
                ROICanvaMetricsCategory.NonProfitIntangibleAssets,
              ) && (
                <CheckBoxCollapsible
                  title={ROICanvaMetricsCategory.NonProfitIntangibleAssets}
                  input={input}
                  listenMetricElIds={[
                    ROICanvaMetricElId.AmbassadorsMetric,
                    ROICanvaMetricElId.StewardshipActivitiesMetric,
                  ]}
                >
                  {someNotNull([
                    roi?.ambassadors?.didYouHaveAnyAmbassadors,
                    roi?.partnerActivation?.accessToAgencies,
                  ]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.AmbassadorsMetric}
                      input={input}
                    >
                      <AmbassadorsMetric
                        themeColor={themeColor?.Tertiary}
                        didYouHaveAnyAmbassadors={
                          !!roi?.ambassadors?.didYouHaveAnyAmbassadors
                        }
                        accessToAgencies={!!roi?.partnerActivation?.accessToAgencies}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([roi?.stewardship?.stewardshipitems]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.StewardshipActivitiesMetric}
                      input={input}
                    >
                      <StewardshipActivitiesMetric
                        themeColor={themeColor?.Tertiary}
                        stewardshipActivities={roi?.stewardship?.stewardshipitems || []}
                      />
                    </CheckBoxMetric>
                  )}
                </CheckBoxCollapsible>
              )}

              {(selectedMetricsCategory || []).includes(
                ROICanvaMetricsCategory.BottomLineImpact,
              ) && (
                <CheckBoxCollapsible
                  title={ROICanvaMetricsCategory.BottomLineImpact}
                  input={input}
                  listenMetricElIds={[
                    ROICanvaMetricElId.BottomLineImpactMetric,
                    ROICanvaMetricElId.PotentialCustomerValueMetric,
                    ROICanvaMetricElId.PotentialTurnoverCostsSavedMetric,
                    ROICanvaMetricElId.BusinessValueMetric,
                  ]}
                >
                  {someNotNull([roi?.salesDriven, roi?.savedInMitigatedRisks]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.BottomLineImpactMetric}
                      input={input}
                    >
                      <BottomLineImpactMetric
                        themeColor={themeColor?.Primary}
                        salesDriven={Number(roi?.salesDriven)}
                        savedInMitigatedRisk={Number(roi?.savedInMitigatedRisks)}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([roi?.calculation?.potentialConsumerValue]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.PotentialCustomerValueMetric}
                      input={input}
                    >
                      <PotentialCustomerValueMetric
                        themeColor={themeColor?.Secondary}
                        value={Number(roi?.calculation?.potentialConsumerValue)}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([roi?.calculation?.potentialTurnoverCostsSaved]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.PotentialTurnoverCostsSavedMetric}
                      input={input}
                    >
                      <PotentialTurnoverCostsSavedMetric
                        themeColor={themeColor?.Complementary}
                        value={Number(roi?.calculation?.potentialTurnoverCostsSaved)}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([roi?.calculation?.bottomLineValue]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.BusinessValueMetric}
                      input={input}
                    >
                      <BusinessValueMetric
                        themeColor={themeColor?.Secondary}
                        bottomLineValue={Number(roi?.calculation?.bottomLineValue)}
                      />
                    </CheckBoxMetric>
                  )}
                </CheckBoxCollapsible>
              )}

              {(selectedMetricsCategory || []).includes(
                ROICanvaMetricsCategory.SocialValue,
              ) && (
                <CheckBoxCollapsible
                  title={ROICanvaMetricsCategory.SocialValue}
                  input={input}
                  listenMetricElIds={[
                    ROICanvaMetricElId.MissonImpactMetric,
                    ROICanvaMetricElId.MissionAdvancementMetric,
                    ROICanvaMetricElId.ScopeOfImpactMetric,
                  ]}
                >
                  {someNotNull([roi?.missionImpact]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.MissonImpactMetric}
                      input={input}
                    >
                      <MissonImpactMetric
                        themeColor={themeColor?.Secondary}
                        missionImpact={roi?.missionImpact || ''}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([roi?.missionAdvancement]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.MissionAdvancementMetric}
                      input={input}
                    >
                      <MissionAdvancementMetric
                        themeColor={themeColor?.Complementary}
                        rate={Number(roi?.missionAdvancement)}
                      />
                    </CheckBoxMetric>
                  )}

                  {someNotNull([roi?.scopeOfImpact]) && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.ScopeOfImpactMetric}
                      input={input}
                    >
                      <ScopeOfImpactMetric
                        themeColor={themeColor?.Secondary}
                        scope={roi?.scopeOfImpact || []}
                      />
                    </CheckBoxMetric>
                  )}
                </CheckBoxCollapsible>
              )}

              {(selectedMetricsCategory || []).includes(
                ROICanvaMetricsCategory.MediaTracking,
              ) && (
                <CheckBoxCollapsible
                  title={ROICanvaMetricsCategory.MediaTracking}
                  input={input}
                  listenMetricElIds={[
                    ROICanvaMetricElId.ContentStreamMetric,
                    ROICanvaMetricElId.MediaExposureMetric,
                    ROICanvaMetricElId.SentimentMetric,
                    ROICanvaMetricElId.TrendingThemesMetric,
                    ROICanvaMetricElId.HeatMapMetric,
                    ROICanvaMetricElId.TopAuthorsMetric,
                    ROICanvaMetricElId.TopRetweetedMetric,
                    ROICanvaMetricElId.TotalPotentialReachMetric,
                  ]}
                >
                  {meltwaterData && !!meltwaterData.analyticsData.exports?.data.length && (
                    <CheckBoxMetric
                      metricId={ROICanvaMetricElId.ContentStreamMetric}
                      input={input}
                    >
                      <ContentStreamMetric
                        data={meltwaterData.analyticsData.exports?.data.slice(0, 3)}
                      />
                    </CheckBoxMetric>
                  )}
                  {meltwaterData &&
                    !!meltwaterData.analyticsData.analytics?.time_series.length && (
                      <CheckBoxMetric
                        metricId={ROICanvaMetricElId.MediaExposureMetric}
                        input={input}
                      >
                        <MediaExposureMetric
                          data={meltwaterData.analyticsData.analytics?.time_series}
                        />
                      </CheckBoxMetric>
                    )}
                  {meltwaterData &&
                    meltwaterData.analyticsData.analytics?.sentiment &&
                    (meltwaterData.analyticsData.analytics?.sentiment.positive.percent ||
                      meltwaterData.analyticsData.analytics?.sentiment.negative.percent ||
                      meltwaterData.analyticsData.analytics?.sentiment.neutral
                        .percent) && (
                      <CheckBoxMetric
                        metricId={ROICanvaMetricElId.SentimentMetric}
                        input={input}
                      >
                        <SentimentMetric
                          data={meltwaterData.analyticsData.analytics?.sentiment}
                        />
                      </CheckBoxMetric>
                    )}
                  {meltwaterData &&
                    !!meltwaterData.analyticsData.top_keyphrases?.keyphrases.length && (
                      <CheckBoxMetric
                        metricId={ROICanvaMetricElId.TrendingThemesMetric}
                        input={input}
                      >
                        <TrendingThemesMetric
                          data={meltwaterData.analyticsData.top_keyphrases?.keyphrases}
                        />
                      </CheckBoxMetric>
                    )}
                  {meltwaterData &&
                    !!meltwaterData.analyticsData.analytics?.top_countries.length && (
                      <CheckBoxMetric
                        metricId={ROICanvaMetricElId.HeatMapMetric}
                        input={input}
                      >
                        <HeatMapMetric
                          data={meltwaterData.analyticsData.analytics?.top_countries}
                        />
                      </CheckBoxMetric>
                    )}
                  {meltwaterData &&
                    !!meltwaterData.analyticsData.top_sources?.sources.length && (
                      <CheckBoxMetric
                        metricId={ROICanvaMetricElId.TopAuthorsMetric}
                        input={input}
                      >
                        <TopAuthorsMetric
                          data={meltwaterData.analyticsData.top_sources?.sources.slice(
                            0,
                            4,
                          )}
                        />
                      </CheckBoxMetric>
                    )}
                  {meltwaterData &&
                    !!meltwaterData.analyticsData.top_shared?.results.length && (
                      <CheckBoxMetric
                        metricId={ROICanvaMetricElId.TopRetweetedMetric}
                        input={input}
                      >
                        <TopRetweetedMetric
                          data={meltwaterData.analyticsData.top_shared?.results.slice(
                            0,
                            3,
                          )}
                        />
                      </CheckBoxMetric>
                    )}
                  {meltwaterData &&
                    !!meltwaterData.analyticsData.exports?.documentTypes.length && (
                      <CheckBoxMetric
                        metricId={ROICanvaMetricElId.TotalPotentialReachMetric}
                        input={input}
                      >
                        <TotalPotentialReachMetric
                          data={meltwaterData.analyticsData.exports?.documentTypes}
                        />
                      </CheckBoxMetric>
                    )}
                </CheckBoxCollapsible>
              )}

              {(selectedMetricsCategory || []).includes(
                ROICanvaMetricsCategory.SDGAlignment,
              ) && (
                <CheckBoxCollapsible
                  title={ROICanvaMetricsCategory.SDGAlignment}
                  input={input}
                  listenMetricElIds={[ROICanvaMetricElId.SDGAlignmentMetric]}
                >
                  {roi?.sdgGoals && roi.sdgGoals.length && (
                    <CheckBoxMetric
                      fillWidth
                      metricId={ROICanvaMetricElId.SDGAlignmentMetric}
                      input={input}
                    >
                      <SDGAlignmentMetric sdgGoals={roi.sdgGoals} />
                    </CheckBoxMetric>
                  )}
                </CheckBoxCollapsible>
              )}

              {(selectedMetricsCategory || []).includes(
                ROICanvaMetricsCategory.BaselineMetrics,
              ) && (
                <CheckBoxCollapsible
                  title={ROICanvaMetricsCategory.BaselineMetrics}
                  input={input}
                  listenMetricElIds={[
                    ROICanvaMetricElId.BaselineMetricBrandValue,
                    ROICanvaMetricElId.BaselineMetricStewardship,
                    ROICanvaMetricElId.BaselineMetricAmbassadorEngagement,
                    ROICanvaMetricElId.BaselineMetricPaidDonatedMedia,
                    ROICanvaMetricElId.BaselineMetricOwnedMedia,
                    ROICanvaMetricElId.BaselineMetricSocialMedia,
                    ROICanvaMetricElId.BaselineMetricEarnedMedia,
                  ]}
                >
                  {baseline['Brand Value'] != null && (
                    <CheckBoxMetric
                      fillWidth
                      metricId={ROICanvaMetricElId.BaselineMetricBrandValue}
                      input={input}
                    >
                      <BaselineMetric data={baseline['Brand Value']} />
                    </CheckBoxMetric>
                  )}
                  {baseline['Stewardship'] != null && (
                    <CheckBoxMetric
                      fillWidth
                      metricId={ROICanvaMetricElId.BaselineMetricStewardship}
                      input={input}
                    >
                      <BaselineMetric data={baseline['Stewardship']} />
                    </CheckBoxMetric>
                  )}
                  {baseline['Ambassador Engagement'] != null && (
                    <CheckBoxMetric
                      fillWidth
                      metricId={ROICanvaMetricElId.BaselineMetricAmbassadorEngagement}
                      input={input}
                    >
                      <BaselineMetric data={baseline['Ambassador Engagement']} />
                    </CheckBoxMetric>
                  )}
                  {baseline['Paid/Donated Media'] != null && (
                    <CheckBoxMetric
                      fillWidth
                      metricId={ROICanvaMetricElId.BaselineMetricPaidDonatedMedia}
                      input={input}
                    >
                      <BaselineMetric data={baseline['Paid/Donated Media']} />
                    </CheckBoxMetric>
                  )}
                  {baseline['Owned Media'] != null && (
                    <CheckBoxMetric
                      fillWidth
                      metricId={ROICanvaMetricElId.BaselineMetricOwnedMedia}
                      input={input}
                    >
                      <BaselineMetric data={baseline['Owned Media']} />
                    </CheckBoxMetric>
                  )}
                  {baseline['Social Media'] != null && (
                    <CheckBoxMetric
                      fillWidth
                      metricId={ROICanvaMetricElId.BaselineMetricSocialMedia}
                      input={input}
                    >
                      <BaselineMetric data={baseline['Social Media']} />
                    </CheckBoxMetric>
                  )}
                  {baseline['Earned Media'] != null && (
                    <CheckBoxMetric
                      fillWidth
                      metricId={ROICanvaMetricElId.BaselineMetricEarnedMedia}
                      input={input}
                    >
                      <BaselineMetric data={baseline['Earned Media']} />
                    </CheckBoxMetric>
                  )}
                </CheckBoxCollapsible>
              )}
            </>
          );
        }}
      </Field>
      <OnChange name="chartIds">
        {(value: FormProps['chartIds'], previous: FormProps['chartIds']) => {
          if (value === previous) return;
          if (value.length === ROICanvaMetricElIdList.length)
            return form.change('isAll', true);
          if (previous.length === ROICanvaMetricElIdList.length)
            form.change('isAll', false);
        }}
      </OnChange>
    </>
  );
};

const Title = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 14px;
`;

const Desc = styled(Title)`
  font-weight: 400;
  font-size: 16px;
  line-height: 148%;
`;

export default SelectCanvaMetricsSection;
