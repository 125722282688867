import React from 'react';
import styled from 'styled-components';

import { UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import UploadButton from 'components/ActionButtons/UploadButton';
import DownloadTemplate from 'components/DownloadTemplate';
import { fetchDownloadROITemplate } from 'store/ducks/roi/api';
import { ROIUIType } from 'interfaces/roi';

interface Props extends InjectedProps {
  handleAccess: (files: File[]) => Promise<void>;
  title: string;
  multiple?: boolean;
  description?: string;
  type: ROIUIType;
}

const ImportPartnersModal = ({
  onClose,
  handleAccess,
  title,
  multiple,
  description,
  type,
  ...modalProps
}: Props) => {
  useLockBodyScroll();

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 24px' }}
      style={{
        width: '100%',
        maxWidth: '559px',
        height: '448px',
        overflow: 'hidden',
      }}
    >
      <Title>{title}</Title>
      <DownloadTemplate
        fetchFile={() =>
          fetchDownloadROITemplate(type === 'organization' ? 'company' : 'team')
        }
      />
      <UploadCSVWrapper>
        <UploadButton multiple={multiple} uploadHandler={files => handleAccess(files)} />
        <Desc>
          {description ||
            'You may upload up to 10 Excel files per time. File size limit is 5 Mb. Only Excel files are allowed.'}
        </Desc>
      </UploadCSVWrapper>
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 28px;
  text-align: center;
`;

const UploadCSVWrapper = styled.div`
  height: 203px;
  border: 1px dashed #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 24px;
`;

const Desc = styled.div`
  color: #979797;
  line-height: 145%;
  text-align: center;
  margin-top: 18px;
`;

export default ImportPartnersModal;
