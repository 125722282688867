import TotalRow from 'components/TotalRow';
import { UIButton } from 'components/UI';
import { useModal } from 'hooks/common';
import { CampaignListReq } from 'interfaces/campaign';
import { PaginationMeta } from 'interfaces/common';
import React from 'react';
import styled from 'styled-components';
import AddNewCampaignModal from 'components/Campaign/modals/AddNewCampaignModal';

interface Props {
  meta: PaginationMeta;
  onSearch: (req: CampaignListReq) => void;
  onFetchCampaignList: (args: CampaignListReq) => void;
  disabledAdd: string;
}

const CampaignSearch = ({ meta, onFetchCampaignList, disabledAdd }: Props) => {
  const [showAddNewCampaignModal, hideAddNewCampaignModal] = useModal(
    () => (
      <AddNewCampaignModal
        handleAccess={() => {
          onFetchCampaignList({ page: 1 });
          hideAddNewCampaignModal();
        }}
        onClose={hideAddNewCampaignModal}
      />
    ),
    [],
  );

  return (
    <Root>
      <Header>
        <TotalRow
          title={`${meta.totalItems || 0} campaign${meta.totalItems > 1 ? 's' : ''}`}
          maxWidth="unset"
        >
          <Actions>
            <Button
              title="Add New Campaign"
              modifiers="primary"
              handleClick={showAddNewCampaignModal}
              disabled={disabledAdd === 'success' || disabledAdd === 'error'}
            />
          </Actions>
        </TotalRow>
      </Header>
    </Root>
  );
};

const Root = styled.div`
  margin-top: 28px;
`;

const Header = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Actions = styled.div`
  margin-left: auto;
  display: flex;
`;

const Button = styled(UIButton)`
  margin-left: 24px;
  width: 220px;
`;

export default CampaignSearch;
