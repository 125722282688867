import ProgressBar from 'components/UI/ProgressBar';
import { DashboardPricing } from 'interfaces/pricing';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { moneyFormatter } from 'utils/formatters';

interface Props {
  totalAssetValue: DashboardPricing['TotalAssetValue'];
}

const TotalAssetValueChart: FC<Props> = ({ totalAssetValue }) => {
  const maxValue = useMemo(() => {
    if (!totalAssetValue) return 0;

    const maxValue = [...totalAssetValue].sort(
      (a, b) => Number(b.totalAssetValue) - Number(a.totalAssetValue),
    )?.[0]?.totalAssetValue;

    return Number(maxValue) || 0;
  }, [totalAssetValue]);

  return (
    <Root>
      {totalAssetValue &&
        totalAssetValue.map(item => (
          <Item key={item.id}>
            <Text>
              <Value>
                {String(
                  moneyFormatter.from(Number(item.totalAssetValue), {
                    symbol: '$',
                  }),
                )}
              </Value>
              <Desc>{item.name}</Desc>
            </Text>
            <ThinProgressBar value={(Number(item.totalAssetValue) / maxValue) * 100} />
          </Item>
        ))}
    </Root>
  );
};

const Root = styled.div`
  max-width: 800px;
`;

const ThinProgressBar = ({ value }: { value: number }) => (
  <ProgressBar value={value} barHeight={9} />
);

const Item = styled.div`
  margin-bottom: 12px;
`;

const Text = styled.div`
  display: flex;
  padding-left: 8px;
  margin-bottom: 4px;
`;

const Value = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #000;
  min-width: 120px;
  margin-right: 24px;
  line-height: 145%;
`;

const Desc = styled.div`
  font-size: 12px;
  color: #605e5c;
`;

export default TotalAssetValueChart;
