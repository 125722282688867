export const typesOfInvestmentOptions = [
  {
    id: 'Grant',
    title: 'Grant',
  },
  {
    id: 'Employee Engagement',
    title: 'Employee Engagement',
  },
  {
    id: 'Cause Marketing (commercial co-venture)',
    title: 'Cause Marketing (commercial co-venture)',
  },
  {
    id: 'Sponsorship',
    title: 'Sponsorship',
  },
  {
    id: 'Social Enterprise',
    title: 'Social Enterprise',
  },
];

export const typesOfInvestmentThoughtStarter = {
  grant: 'Funding one of our core priority programs for tangible impact',
  employeeengagement:
    'Engaging your employees through our skills-based and virtual volunteer programs',
  causemarketing:
    'Aligning with one of your products, at point-of-sale or customer transactions',
  sponsorship:
    'Connecting with our stakeholders by sponsoring one of our signature events',
  socialenterprise:
    'Leveraging your expertise to generate product or service solutions for our mission',
};
