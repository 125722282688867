import {
  ageRangesOptions,
  ethnicityOptions,
  filterSectionTexts,
  genderOptions,
  incomeOptions,
} from 'constants/search';
import React from 'react';
import FormSection from '../FormSection';
import styled from 'styled-components';
import {
  FieldRadioButtonsLine,
  FieldTextInput,
  FiledMultiSelectWithCheckBoxes,
} from 'components/FormFields';
import { formatValueEnum } from 'components/UI/interfaces';
import { Field } from 'components/FinalForm';

const CustomerSection = () => {
  const { title } = filterSectionTexts.customer;
  return (
    <FormSection marginTop="16px" title={title}>
      <Row>
        <Field
          name="gender"
          label="Gender"
          component={FieldRadioButtonsLine}
          options={genderOptions}
        />
      </Row>
      <Row>
        <Field
          name="income"
          label={'Household Income'}
          component={FiledMultiSelectWithCheckBoxes}
          options={incomeOptions}
          placeholder={'Select'}
          formatValue={formatValueEnum.BASE_ARRAY}
        />
        <Field
          name="ethnicities"
          label="Ethnicity"
          component={FiledMultiSelectWithCheckBoxes}
          options={ethnicityOptions}
          placeholder={'Select'}
          formatValue={formatValueEnum.BASE_ARRAY}
        />
      </Row>

      <Row>
        <Field
          name="ageRanges"
          label="Age"
          component={FiledMultiSelectWithCheckBoxes}
          options={ageRangesOptions}
          placeholder={'Select'}
          formatValue={formatValueEnum.BASE_ARRAY}
        />
        <Field
          name="constituentBrandAffinity"
          label="Constituent Brand Affinity"
          component={FieldTextInput}
          placeholder={'Enter Constituent Brand Affinity'}
        />
      </Row>
    </FormSection>
  );
};

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    width: calc(50% - 8px);
  }
`;

export default CustomerSection;
