/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';

import { SelectOption } from 'components/UI/interfaces';
import { Link, useHistory } from 'react-router-dom';

interface Props {
  name: string;
  list: SelectOption[];
  queryKey: string;
  activeTab?: string;
  underlineTop?: number;
  path?: string;
}

const NavigationTabs = ({
  name,
  list,
  queryKey,
  activeTab,
  underlineTop,
  path,
}: Props) => {
  const { location, push } = useHistory();
  const parsed = queryString.parse(location.search);
  const activeQuery = parsed?.[queryKey];

  const getParams = useCallback(
    (value: SelectOption['value'] | undefined) =>
      queryString.stringify({ [queryKey]: value }),
    [],
  );

  useEffect(() => {
    if (activeTab && !parsed?.[queryKey]) {
      const params = getParams(activeTab);
      if (path === '/search') {
        push(
          `${location.pathname}?${params + '&path=' + path + '&type=Vetting Sessions'}`,
        );
      } else {
        push(`${location.pathname}?${params + '&path=' + path + '&type=Prospects'}`);
      }
    }
  }, []);

  return (
    <Root>
      <Name>{name}</Name>
      <List>
        {list.map(({ label, value }, index) => {
          const params = getParams(value);
          let type = '';
          if (path === '/search') {
            type = 'Vetting Sessions';
          } else {
            type = 'Prospects';
          }
          return (
            <Item key={index}>
              <StyledLink
                $isActive={activeQuery === value}
                $underlineTop={underlineTop}
                to={`${location.pathname}?${params + '&path=' + path + '&type=' + type}`}
              >
                {label}
              </StyledLink>
            </Item>
          );
        })}
      </List>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
`;

const List = styled.ul`
  display: flex;
`;

const Item = styled.li`
  font-size: 12px;
  line-height: 150%;

  &:not(:last-child) {
    margin-right: 22px;
  }
`;

const StyledLink = styled(Link)<{
  $isActive: boolean;
  $underlineTop: Props['underlineTop'];
}>`
  &::after {
    content: '';
    height: 2px;
    background: ${({ $isActive }) => ($isActive ? ' var(--blue)' : 'transparent')};
    display: block;
    margin-top: ${({ $underlineTop }) => $underlineTop || 2}px;
    transition: background 0.5s ease;
  }

  &:hover {
    &::after {
      content: '';
      height: 2px;
      background: var(--blue);
      display: block;
      margin-top: 2px;
    }
  }
`;

const Name = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
  margin-right: 26px;
  cursor: default;
`;

export default NavigationTabs;
