export const SET_ROI_LIST = 'roi/SET_ROI_LIST';
export const SET_ROI = 'roi/SET_ROI';
export const CREATE_ROI = 'roi/SET_ROI_CREATE';
export const UPDATE_ROI = 'roi/SET_ROI_UPDATE';
export const DELETE_ROI = 'roi/SET_ROI_DELETE';
export const SET_ROI_TEMPLATES = 'roi/SET_ROI_TEMPLATES';
export const SET_ROI_TEMPLATE_PREVIEW = 'roi/SET_ROI_TEMPLATE_PREVIEW';
export const SET_ROI_TEMPLATES_ITEM = 'roi/SET_ROI_TEMPLATE_SAVE';
export const TOGGLE_ROI_TEMPLATES_DISABLE = 'roi/TOGGLE_ROI_TEMPLATES_DISABLE';
export const SET_ROI_REPORT = 'roi/SET_ROI_REPORT';
export const SET_ROI_MELTWATER_DATA = 'roi/SET_ROI_MELTWATER_DATA';
export const SET_ROI_DASHBOARD = 'roi/SET_ROI_DASHBOARD';
export const SET_ROI_SUGGEST = 'roi/SET_ROI_SUGGEST';
export const SET_ROI_SDGGOALS = 'roi/SET_ROI_SDGGOALS';
export const SET_ROI_SURVEY = 'roi/SET_ROI_SURVEY';
export const SET_ROI_SURVEYS = 'roi/SET_ROI_SURVEYS';
export const SET_ROI_SURVEY_RESULT = 'roi/SET_ROI_SURVEY_RESULT';
export const SET_ROI_OLD = 'roi/SET_ROI_OLD';
export const SET_ROI_CANVA = 'roi/SET_ROI_CANVA_REPORT_TITLE';
export const DELETE_ROI_CANVA = 'roi/DELETE_ROI_CANVA';
export const SET_ROI_CANVA_TEMPLATE = 'roi/SET_ROI_CANVA_TEMPLATE';
export const SET_ROI_CANVA_TEMPLATE_PRIVATE = 'roi/SET_ROI_CANVA_TEMPLATE_PRIVATE';
export const SET_ROI_SURVEY_STATISTICS = 'roi/SET_SURVEY_STATISTICS';
export const SET_ROI_SURVEY_LIST = 'roi/SET_ROI_SURVEY_LIST';
export const SET_ROI_SURVEY_STATISTIC_BY_ID = 'roi/SET_ROI_SURVEY_STATISTIC_BY_ID';
export const DELETE_ROI_SURVEY_STATISTIC = 'roi/DELETE_ROI_SURVEY_STATISTIC';
