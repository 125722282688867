import { UIDivider } from 'components/UI';
import React from 'react';
import styled from 'styled-components';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';

interface Props {
  totalGiving: number;
  themeColor?: string;
}

const TotalGivingMetric = ({ totalGiving, themeColor }: Props) => {
  return (
    <Root $themeColor={themeColor}>
      <MetricTitle>Total Giving</MetricTitle>
      <MetricContent>
        <ValueWrapper>
          <div>Giving Participation Rate</div>
          <div>{(totalGiving && `${totalGiving}%`) || 'N/A'}</div>
        </ValueWrapper>
        <Divider type="horizontal" />
        <Desc>
          The value for Total Giving includes all of the social spend reported by your
          partner. The categories of social spend incorporate consumer cash, employee
          cash, overall cash and non-cash.
        </Desc>
      </MetricContent>
    </Root>
  );
};

const Root = styled(MetricRoot)`
  /* background: #1992b9; */
`;

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;

  & > div:last-child {
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
  }
`;

const Desc = styled.div`
  font-size: 14px;
  line-height: 1;
`;

const Divider = styled(UIDivider)`
  margin: 14px 0 10px;
  flex-shrink: 0;
`;

export default TotalGivingMetric;
