import React from 'react';
import FormSection from '../FormSection';
import { FieldRadioButtons, FiledPriorityRadioButton } from 'components/FormFields';
import { filterSectionTexts, variantFilterOptions } from 'constants/search';
import { Field } from 'components/FinalForm';
import { FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import styled from 'styled-components';
import IconTooltip from 'components/IconTooltip';
import { ReactSVG } from 'react-svg';
import UploadSVG from 'assets/images/icons/upload.svg';
import { FormApi } from 'final-form';

const PrioritySection = () => {
  const { priority } = filterSectionTexts;

  const handleResetPriority = (form: FormApi<any>) => {
    form.change('priority.crsFocus', undefined);
    form.change('priority.sdgGoal', undefined);
    form.change('priority.employeeEngagement', undefined);
    form.change('priority.partnership', undefined);
    form.change('priority.geographicLocation', undefined);
    form.change('priority.brandAffinities', undefined);
  };

  return (
    <FormSection {...priority}>
      <div style={{ display: 'none' }}>
        <Field
          name="variantForm"
          component={FieldRadioButtons}
          options={variantFilterOptions}
        />
      </div>

      <FormSpy>
        {({ form }) => (
          <div>
            <ResetPriority onClick={() => handleResetPriority(form)}>
              <ReactSVG src={UploadSVG} />
              Reset values
            </ResetPriority>
            <Field
              name="priority.crsFocus"
              label={
                <LabelWrapper>
                  Company CSR Focus
                  <IconTooltipBlue>
                    Corporate Social Responsibility Focus of the Company alignment between
                    your organization and the prospective companies.
                  </IconTooltipBlue>
                </LabelWrapper>
              }
              component={FiledPriorityRadioButton}
            />
            <Field
              name="priority.sdgGoal"
              label={
                <LabelWrapper>
                  SDG Goal
                  <IconTooltipBlue position="bottom-left">
                    Sustainable Development Goal alignment between your organization and
                    the prospective companies.
                  </IconTooltipBlue>
                </LabelWrapper>
              }
              component={FiledPriorityRadioButton}
            />
            <Field
              name="priority.employeeEngagement"
              label={
                <LabelWrapper>
                  Employee Engagement
                  <IconTooltipBlue>
                    Alignment of Employee Engagement between your organization and the
                    prospective companies.
                  </IconTooltipBlue>
                </LabelWrapper>
              }
              component={FiledPriorityRadioButton}
            />
            <Field
              name="priority.partnership"
              label={
                <LabelWrapper>
                  Type of Partnership
                  <IconTooltipBlue>
                    Partnership Type (Grant, Sponsorship, Cause Marketing, Employee
                    Engagement, or Social Enterprise) alignment between your organization
                    and the prospective companies.
                  </IconTooltipBlue>
                </LabelWrapper>
              }
              component={FiledPriorityRadioButton}
            />
            <Field
              name="priority.geographicLocation"
              label={
                <LabelWrapper>
                  Geographic Location
                  <IconTooltipBlue>
                    Geographical alignment between your organization and the prospective
                    companies.
                  </IconTooltipBlue>
                </LabelWrapper>
              }
              component={FiledPriorityRadioButton}
            />
            <Field
              name="priority.brandAffinities"
              label={
                <LabelWrapper>
                  Constituent Affinities
                  <IconTooltipBlue>
                    Constituent demographic and interest alignment between your
                    organization and the prospective companies.
                  </IconTooltipBlue>
                </LabelWrapper>
              }
              component={FiledPriorityRadioButton}
            />
            <OnChange name="priority">
              {(value, previous) => {
                if (value === previous) return;
                if (!value) return form.change('variantForm', 'Advanced');
                form.change('variantForm', 'Customize');
              }}
            </OnChange>
          </div>
        )}
      </FormSpy>
    </FormSection>
  );
};

const IconTooltipBlue = styled(IconTooltip)`
  margin-left: 4px;

  svg {
    width: 12px;
    height: 12px;
  }

  svg * {
    fill: var(--blue);
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ResetPriority = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  margin-bottom: 24px;
  align-items: center;
  gap: 6px;
  font-size: 12px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

export default PrioritySection;
