import React, { ReactNode } from 'react';
import ReactShowMoreText from 'react-show-more-text';
import styled from 'styled-components';

interface Props {
  lines?: number;
  width?: number;
  children: ReactNode;
}

const ShowMoreText = ({ children, lines = 2, width }: Props) => {
  return (
    <Root>
      <ReactShowMoreText
        lines={lines}
        more="See more"
        less="See less"
        anchorClass="my-anchor-css-class"
        expanded={false}
        width={width}
      >
        {children}
      </ReactShowMoreText>
    </Root>
  );
};

export const Root = styled.div`
  position: relative;
  & .my-anchor-css-class {
    color: var(--blue);
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default ShowMoreText;
