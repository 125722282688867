import React from 'react';
import styled from 'styled-components';
import { UIModalProps } from 'components/UI/interfaces';
import { UIModal } from 'components/UI';

interface BaseProps {
  html?: string;
  isloading?: boolean;
}

type Props = BaseProps & UIModalProps;

function HTMLPreviewModal({ onClose, isloading, html, ...modalProps }: Props) {
  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '28px 40px 40px' }}
      style={{
        width: '100%',
        maxWidth: '844px',
        top: '100px',
      }}
      overlayStyle={{
        overflow: 'auto',
      }}
      isloading={isloading}
      header={<ModalTitle>Preview</ModalTitle>}
    >
      <PreviewHtml title="html preview" sandbox="allow-same-origin" srcDoc={html} />
    </UIModal>
  );
}

const ModalTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  padding: 40px 0 16px;
`;

const PreviewHtml = styled.iframe`
  width: 100%;
  height: 620px;
`;

export default HTMLPreviewModal;
