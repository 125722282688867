import React from 'react';
import styled from 'styled-components';
import { moneyNumberFormat } from 'utils/formatters';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';
import StarWhiteCircleSVG from 'assets/images/icons/star-white.svg';
import { ReactSVG } from 'react-svg';

interface Props {
  value: number;
  themeColor?: string;
}

const PotentialCustomerValueMetric = ({ value, themeColor }: Props) => {
  return (
    <Root>
      <MetricTitle>Potential Customer Value</MetricTitle>
      <Content>
        <LogoBackground $themeColor={themeColor}>
          <ReactSVG src={StarWhiteCircleSVG} />
        </LogoBackground>
        <Value>{moneyNumberFormat(value)}</Value>
      </Content>
    </Root>
  );
};

const Root = styled(MetricRoot)``;

const Value = styled.div`
  font-weight: 500;
  font-size: 20px;
`;

const Content = styled(MetricContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const LogoBackground = styled.div<{ $themeColor?: string }>`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ $themeColor }) => $themeColor || '#2BAEE0'};
`;

export default PotentialCustomerValueMetric;
