import { useCallback, useState } from 'react';
import downloadFile from 'js-file-download';
import { SavedListExcelRes } from 'interfaces/common';
import Toast from 'services/Toast';

export default <T = string>(getFile: (query: T) => Promise<SavedListExcelRes>) => {
  const [isLoading, setIsLoading] = useState(false);
  const request = useCallback(
    async (query: T) => {
      try {
        setIsLoading(true);
        const data = await getFile(query).then(data => {
          setIsLoading(false);
          return data;
        });
        if (data.online) {
          const file = Uint8Array.from(window.atob(data.file), c => c.charCodeAt(0));
          downloadFile(Buffer.from(file), data.name);
          return;
        }
        window.open(data.url);
      } catch (error) {
        setIsLoading(false);
        Toast.info('Document downloading now, please click this button 2 minutes later.');
        return error;
      }
    },
    [getFile],
  );

  return [request, isLoading] as const;
};
