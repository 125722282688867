/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';

interface Props extends InjectedProps {
  children: React.ReactNode;
}

const FiltersFormModal = ({ onClose, isOpen, children, ...modalProps }: Props) => {
  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      hideCloseIcon
      isOpen={isOpen}
      style={{
        width: '100%',
        maxWidth: '1096px',
        height: '80vh',
      }}
      propsBodyCss={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
    >
      {children}
    </UIModal>
  );
};

export default FiltersFormModal;
