import ProgressBar from 'components/UI/ProgressBar';
import { DashboardROI } from 'interfaces/roi';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { moneyFormatter } from 'utils/formatters';

interface Props {
  data: DashboardROI['totalMediaImpressions'] | undefined;
}

const COLORS = [
  '#005A9E',
  'rgba(0, 90, 158, 0.9)',
  'rgba(0, 90, 158, 0.8)',
  'rgba(0, 90, 158, 0.7)',
  'rgba(0, 90, 158, 0.6)',
];

const TotalMediaImpressionsTabContent = ({ data }: Props) => {
  const maxValue = useMemo(() => {
    if (!data) return 0;

    const maxValue = [...data].sort((a, b) => Number(b.value) - Number(a.value))?.[0]
      ?.value;

    return Number(maxValue) || 0;
  }, [data]);

  return (
    <Root>
      {data &&
        data.map((item, i) => (
          <Item key={item.id}>
            <Text>
              <Value>
                {String(
                  moneyFormatter.from(Number(item.value), {
                    symbol: '',
                  }),
                )}
              </Value>
              <Desc>{item.name}</Desc>
            </Text>
            <ThinProgressBar
              color={COLORS[i]}
              value={(Number(item.value) / maxValue) * 100}
            />
          </Item>
        ))}
    </Root>
  );
};

const Root = styled.div`
  max-width: 800px;
`;

const ThinProgressBar = ({ value, color }: { value: number; color: string }) => (
  <ProgressBar color={color} value={value} barHeight={20} />
);

const Item = styled.div`
  margin-bottom: 16px;
`;

const Text = styled.div`
  display: flex;
  padding-left: 8px;
  margin-bottom: 8px;
`;

const Value = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #000;
  min-width: 120px;
  margin-right: 24px;
  line-height: 145%;
`;

const Desc = styled.div`
  font-size: 12px;
  color: #605e5c;
`;

export default TotalMediaImpressionsTabContent;
