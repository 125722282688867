import React from 'react';
import styled from 'styled-components';

const NotFound = () => {
  return (
    <Root>
      <Title>404</Title>
      <SecondaryTitle>Not Found</SecondaryTitle>
      <Description>The resource requested could not be found on this server!</Description>
    </Root>
  );
};

const Root = styled.div`
  height: calc(100% - 80px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-size: 128px;
  margin-bottom: 12px;
`;

const SecondaryTitle = styled.div`
  font-size: 32px;
  margin-bottom: 12px;
`;

const Description = styled.div`
  font-size: 16px;
`;

export default NotFound;
