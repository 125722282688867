/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { useEffect } from 'react';
import useOperation from './useOperation';
import { fetchAcceptInviteCompany } from 'store/ducks/companies/operations';

const useAcceptInviteCompany = () => {
  const { location } = useHistory();
  const companyId = location.pathname.split('/')[2];
  const [acceptInvite] = useOperation(fetchAcceptInviteCompany);

  const token = queryString.parse(location.search)?.invitationCompanyToken || undefined;

  useEffect(() => {
    token &&
      typeof token === 'string' &&
      companyId &&
      acceptInvite({
        id: companyId,
        token,
      });
  }, [companyId, token]);
};

export default useAcceptInviteCompany;
