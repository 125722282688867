import {
  GiveWorxROIReq,
  PartnershipsListReq,
  PartnershipsListRes,
} from 'interfaces/fundraising';
import { ROIAddTeamRes, ROIData } from 'interfaces/roi';
import { TeamCreateReq } from 'interfaces/team';
import { fetch } from 'services/ApiService';
import { paramsToQueryString } from 'utils';

export const fetchWorxROI = (param: GiveWorxROIReq) =>
  fetch('post', 'giveworxROI', { payload: param });

export const fetchGiveWorkUpdate = (param: GiveWorxROIReq) =>
  fetch<ROIData>('patch', 'giveworxROI', {
    payload: {
      ...param,
    },
  });

export const fetchUpdateWorxROI = (param: GiveWorxROIReq) =>
  fetch('patch', 'giveworxROI', { payload: param });

export const fetchPartnershipsList = ({ limit = 15, ...rest }: PartnershipsListReq) =>
  fetch<PartnershipsListRes>(
    'get',
    `giveworxROI/byTeams${paramsToQueryString({ limit, ...rest })}`,
  );

export const fetchParListByCompanies = ({ limit = 12, ...rest }: PartnershipsListReq) =>
  fetch<PartnershipsListRes>(
    'get',
    `giveworxROI/byCompanies${paramsToQueryString({ limit, ...rest })}`,
  );

export const fetchParMultipleDelete = (ids: string) =>
  fetch('delete', `giveworxROI/MultipleDelete${paramsToQueryString({ deleteIds: ids })}`);

export const fetchGiveROIAddTeam = (param: TeamCreateReq) =>
  fetch<ROIAddTeamRes>('post', `giveworxROI/addTeam`, { payload: param });

// No website validate
export const fetchGiveROIAddTeamNew = (param: TeamCreateReq) =>
  fetch<ROIAddTeamRes>('post', `giveworxROI/addTeamNew`, { payload: param });
