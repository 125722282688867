import { SearchJobTitle } from 'interfaces/contacts';
import * as types from './types';

export const setContactsJobTitles = (jobTitles: string[]) =>
  ({
    type: types.SET_CONTACTS_JOBTITLES,
    payload: jobTitles,
  } as const);

export const setSearchJobTitles = (jobTitles: SearchJobTitle[]) =>
  ({
    type: types.SET_SEARCH_CONTACTS,
    payload: jobTitles,
  } as const);
