import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import queryString from 'query-string';
import { ROIReportEditReportRoutePathsEnum } from 'constants/roi';
import styled from 'styled-components';
import { ExportButton } from 'components/ActionButtons';
import { UIButton, UIDivider, UILink, UILoadAvatar } from 'components/UI';
import BasketSVG from 'assets/images/icons/basket.svg';
import DownloadSVG from 'assets/images/icons/download.svg';
import { ReactSVG } from 'react-svg';
import { getROI } from 'store/ducks/roi/selectors';
import { useSelector } from 'react-redux';
import Toast from 'services/Toast';
import { useDownloadFile, useModal, useOperation } from 'hooks/common';
import { fetchROICanvaDelete, fetchROICanvaUpdate } from 'store/ducks/roi/operations';
import { ConfirmModal } from 'components/Modals';
import PaginationBlock from 'components/PaginationBlock';
import useFakePaginationStatus from 'hooks/common/useFakePagination';
import { ROICanva } from 'interfaces/roi';
import { fetchROIGenerateImpactDataExcel } from 'store/ducks/roi/api';
import PDFPreviewModal from 'components/Modals/PDFPreviewModal';
import EyeLightSVG from 'assets/images/icons/eye-light.svg';

const ROIReportResults = () => {
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const search = queryString.parse(window.location.search);

  const [editTitle, setEditTitle] = useState('');
  const [editCanvaId, setEditCanvaId] = useState('');
  const [deleteCanvaId, setDeleteCanvaId] = useState('');
  const [fileUrl, setFileUrl] = useState('');

  const [onFetchROICanvaUpdate] = useOperation(fetchROICanvaUpdate);
  const [onFetchROICanvaDelete] = useOperation(fetchROICanvaDelete);

  const exportExcel = useDownloadFile(fetchROIGenerateImpactDataExcel);

  const roi = useSelector(getROI);

  const { data, limit, page, total, changePage } = useFakePaginationStatus<ROICanva>({
    datasource: roi?.ROICanva || [],
    limit: 5,
    initialPage: Number(search.page),
  });

  const [showModal, hideModal] = useModal(() => {
    const deleteHandler = () => {
      onFetchROICanvaDelete(deleteCanvaId);
      hideModal();
    };

    return (
      <ConfirmModal
        name={'Delete Report'}
        textAccessBtn="Delete"
        description={'Are you sure you want to delete the report?'}
        accessHandler={deleteHandler}
        cancelHandler={hideModal}
        onClose={hideModal}
        hideCloseIcon
      />
    );
  }, [deleteCanvaId]);

  const [showPDFPreviewModal, hidePDFPreviewModal] = useModal(
    () => <PDFPreviewModal onClose={hidePDFPreviewModal} fileUrl={fileUrl} />,
    [fileUrl],
  );

  return (
    <Root>
      <Actions>
        <ExportButton
          name="Download Impact Data"
          uploadHandler={() => roi?.id && exportExcel(roi.id)}
        />
        <UIButton
          title="Create New Report"
          modifiers="primary"
          handleClick={() =>
            push(
              `${url}/${
                ROIReportEditReportRoutePathsEnum.Select_Report
              }?${queryString.stringify(search)}`,
            )
          }
        />
      </Actions>
      <Divider type="horizontal" />

      {roi && data && data.length ? (
        <div>
          <ListWrapper>
            {data.map(item => (
              <ReportCard key={item.id}>
                <ReportCover
                  $url={item.imageKey}
                  onClick={() => {
                    setFileUrl(item.fileKey);
                    showPDFPreviewModal();
                  }}
                >
                  <ReportCoverMask>
                    <ReactSVG src={EyeLightSVG} /> Show Preview
                  </ReportCoverMask>
                </ReportCover>
                <ReportActions>
                  <ReactSVG
                    src={BasketSVG}
                    onClick={() => {
                      setDeleteCanvaId(item.id);
                      showModal();
                    }}
                  />
                  <ReactSVG src={DownloadSVG} onClick={() => window.open(item.fileKey)} />
                </ReportActions>
                <ReportTitle>
                  {item.id === editCanvaId ? (
                    <Input
                      value={editTitle}
                      onChange={e => setEditTitle(e.target.value)}
                    />
                  ) : (
                    <ReportTitleText>{item.title}</ReportTitleText>
                  )}
                  {item.id === editCanvaId ? (
                    <UILink
                      modifier="blue"
                      onClick={() => {
                        if (!editTitle) return Toast.warn('Title cannot be empty.');
                        setEditTitle('');
                        setEditCanvaId('');
                        onFetchROICanvaUpdate({ ...item, title: editTitle });
                      }}
                    >
                      Submit
                    </UILink>
                  ) : (
                    <UILink
                      modifier="blue"
                      onClick={() => {
                        setEditTitle(item.title);
                        setEditCanvaId(item.id);
                      }}
                    >
                      Edit
                    </UILink>
                  )}
                </ReportTitle>
                <ReportDesc>{roi.companyName}</ReportDesc>
                {roi.isCompany
                  ? roi.team?.logoKey && (
                      <LoadLogo imgKey={roi.team.logoKey} loadFailedHideLogo />
                    )
                  : roi.company?.logo && (
                      <LoadLogo imgKey={roi.company.logo} loadFailedHideLogo />
                    )}
              </ReportCard>
            ))}
          </ListWrapper>
          <PaginationWrapper>
            <PaginationBlock
              page={page}
              pageLimit={limit}
              submitHandler={changePage}
              total={total}
            />
          </PaginationWrapper>
        </div>
      ) : (
        <NoReportsWrapper>No reports have been imported from Canva</NoReportsWrapper>
      )}
    </Root>
  );
};

const Root = styled.div`
  min-height: 570px;
  width: 100%;
  background: #fff;
  padding: 16px 24px;
  margin-top: 24px;
  position: relative;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * + * {
    margin-left: 16px;
  }
`;

const NoReportsWrapper = styled.div`
  font-weight: 600;
  font-size: 20px;
  margin-top: 250px;
  text-align: center;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

const ReportCard = styled.div`
  width: 163px;
`;

const ReportCoverMask = styled.div`
  position: absolute;
  background-color: #f3fcffa2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid var(--blue);
  transition: opacity 0.1s;
  opacity: 0;
`;

const ReportCover = styled.div<{ $url: string }>`
  width: 100%;
  height: 230px;
  background-size: cover;
  margin-bottom: 16px;
  position: relative;
  cursor: pointer;

  &:hover ${ReportCoverMask} {
    opacity: 1;
  }

  ${({ $url }) =>
    $url &&
    `
    background-image: url(${$url});
  `}
`;

const ReportActions = styled.div`
  display: flex;
  margin-bottom: 18px;

  & > * {
    cursor: pointer;
  }

  & > * + * {
    margin-left: 16px;
  }
`;

const Divider = styled(UIDivider)`
  margin: 16px 0 24px;
`;

const ReportTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  justify-content: space-between;
`;

const ReportDesc = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #898989;
  margin-bottom: 5px;
`;

const LoadLogo = styled(UILoadAvatar)`
  width: 46px;
  height: 46px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
`;

const Input = styled.input`
  border: none;
  border-bottom: 1px solid lightgray;
  width: 8em;
`;

const ReportTitleText = styled.div`
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 24px;
  right: 24px;
`;

export default ROIReportResults;
