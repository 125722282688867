import React, { useState } from 'react';
import styled from 'styled-components';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { Form, FormRenderProps } from 'react-final-form';
import PrioritySection from 'components/Forms/FilterForm/PrioritySection';
import { FilterTeamsData } from 'components/Forms/interfaces';
import UploadFileArea from 'components/UploadFileArea';
import DownloadTemplate from 'components/DownloadTemplate';
import { fetchDownloadTeamTemplate } from 'store/ducks/team/api';
import { FieldTextInput } from 'components/FormFields';
import { composeValidators } from 'utils';
import {
  fileExtensionValidator,
  maxLength,
  maxLength150,
  stringRules,
  required,
} from 'utils/validation';
import UIAsterisk from 'components/UI/UIAsterisk';
import { Field } from 'components/FinalForm';
import { fetchUploadTeamXLSX } from 'store/ducks/team/api';
import Toast from 'services/Toast';
import { fetchErrorHandler } from 'utils/errorHandlers';
import { BatchAnalysisExcelRow } from 'interfaces/team';

interface Props extends InjectedProps {
  handleAccess: (values: BatchAnalysisFormData) => void;
  title: string;
  submitLoading: boolean;
}

//add
// type BatchAnalysisFormData = Pick<FilterTeamsData, 'variantForm' | 'priority'> & {
//   files: File[];
//   rows: [];
//   name: string;
// };

type BatchAnalysisFormData = Pick<FilterTeamsData, 'variantForm' | 'priority'> & {
  files: File[];
  rows: BatchAnalysisExcelRow[];
  name: string;
};

const BatchAnalysisModal = ({
  onClose,
  handleAccess,
  title,
  submitLoading,
  ...modalProps
}: Props) => {
  useLockBodyScroll();
  const [isloading, setIsloading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 60px' }}
      style={{
        width: 'max-content',
        maxWidth: '1129px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Title>{title}</Title>
      <Form
        onSubmit={values => {
          console.log('form value:', values);
          handleAccess(values);
        }}
        initialValues={{ variantForm: 'Advanced' }}
        render={({
          handleSubmit,
          values,
          form,
        }: FormRenderProps<BatchAnalysisFormData>) => {
          const isCustomize = values.variantForm === 'Customize';
          return (
            <>
              {isCustomize && <PrioritySection />}
              <Field
                name="name"
                label={
                  <div>
                    Batch Name
                    <UIAsterisk />
                  </div>
                }
                component={FieldTextInput}
                validate={composeValidators<string>(required, maxLength(25), stringRules)}
              />
              <LimitWidthAndCenter isDisabled={isCustomize}>
                <UploadFileArea
                  isloading={isloading}
                  acceptValidator={fileExtensionValidator(
                    ['xlsx'],
                    'Only Excel files are allowed.',
                  )}
                  areaHeight={isCustomize ? 100 : 200}
                  desc="You may upload 1 Excel file per time. File size limit is 5 Mb. Only Excel files are allowed."
                  filelist={
                    values.files?.map(item => ({
                      label: item.name,
                      value: item.name,
                    })) || []
                  }
                  // onUpload={file => form.change('files', file)}
                  onUpload={file => {
                    console.log(file, 'file');
                    if (!file.length) return;
                    setIsloading(true);
                    fetchUploadTeamXLSX(file[0])
                      .then(value => {
                        form.change('files', file);
                        console.log(value[0], '123');
                        form.change('rows', value);
                        Toast.success('Uploaded successfully');
                        setIsDisabled(false);
                      })
                      .catch(fetchErrorHandler)
                      .finally(() => {
                        setIsloading(false);
                      });
                  }}
                  onDeleteFile={() => {
                    form.change('files', []);
                    setIsDisabled(true);
                  }}
                />
                <DownloadTemplate fetchFile={fetchDownloadTeamTemplate} />
                {!isCustomize && <div style={{ height: '140px' }} />}
                <Buttons>
                  <Button title="Cancel" handleClick={onClose} modifiers="secondary" />
                  <Button
                    title="Submit"
                    handleClick={handleSubmit}
                    modifiers="primary"
                    disabled={isDisabled}
                    isLoading={submitLoading}
                  />
                </Buttons>
              </LimitWidthAndCenter>
            </>
          );
        }}
      />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 28px;
  text-align: center;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Button = styled(UIButton)`
  width: 128px;
`;

const LimitWidthAndCenter = styled.div<{ isDisabled?: boolean }>`
  width: 720px;
  margin: 0 auto;

  ${({ isDisabled }) => isDisabled && 'width: 100%;'}
`;

export default BatchAnalysisModal;
