import React from 'react';
import styled from 'styled-components';

import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { ModifierBtn } from 'components/UI/interfaces';

interface BaseProps {
  accessHandler: () => void;
  cancelHandler: () => void;
  name: string;
  description?: string;
  textAccessBtn?: string;
  textCancelBtn?: string;
  accessModifiersBtn?: ModifierBtn | Array<ModifierBtn>;
  cancelModifiersBtn?: ModifierBtn | Array<ModifierBtn>;
  maxWidth?: number;
  descWidth?: string;
}

export type Props = BaseProps & InjectedProps;

const ConfirmModal = ({
  name,
  accessHandler,
  cancelHandler,
  description,
  textAccessBtn,
  textCancelBtn,
  onClose,
  accessModifiersBtn,
  cancelModifiersBtn,
  maxWidth,
  descWidth,
  ...modalProps
}: Props) => {
  useLockBodyScroll();

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 24px 24px' }}
      style={{
        width: '100%',
        maxWidth: maxWidth || 333,
      }}
    >
      <Title>{name}</Title>
      <Description style={{ width: descWidth || '' }} className="description">
        {description ?? 'Are you sure?'}
      </Description>
      <ButtonGroup>
        <Button
          handleClick={accessHandler}
          modifiers={accessModifiersBtn ?? 'dangerBg'}
          title={textAccessBtn ?? 'Access'}
        />
        <Button
          handleClick={cancelHandler}
          modifiers={cancelModifiersBtn ?? 'secondary'}
          title={textCancelBtn ?? 'Cancel'}
        />
      </ButtonGroup>
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  width: 234px;
  margin: 0 auto 8px;
  text-align: center;
`;

const Description = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  width: 256px;
  text-align: center;
  margin: 0 auto 40px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;

  button:first-child {
    margin-right: 8px;
  }
`;

const Button = styled(UIButton)`
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  width: 200px;
`;

export default ConfirmModal;
