import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { FieldTextInput } from 'components/FormFields';

import { UIButton } from 'components/UI';
import { required, email } from 'utils/validation';
import { AuthMFA } from 'interfaces/auth';
import { composeValidators } from 'utils';
import styled from 'styled-components';
import { AuthMFAFormProps } from 'interfaces/common';
import { Field } from 'components/FinalForm';

const MfaForm = ({
  onSubmit,
  onResendEmail,
}: AuthMFAFormProps & { onResendEmail: () => void }) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        submitting,
        pristine,
        hasValidationErrors,
      }: FormRenderProps<AuthMFA>) => {
        return (
          <form>
            <Title>Authentication</Title>

            <Desc>Please check your email for a verification code.</Desc>

            <Field
              name="code"
              label="Verification Code"
              component={FieldTextInput}
              placeholder="Enter the code sent to your email"
              validate={composeValidators<string>(required)}
            />

            <ButtonContainer>
              <Btn
                modifiers="primary"
                title="Resend Email"
                handleClick={onResendEmail}
                type="button"
                isLoading={submitting}
              />
              <Btn
                modifiers="primary"
                title="Submit"
                handleClick={handleSubmit}
                type="submit"
                disabled={pristine || submitting || hasValidationErrors}
                isLoading={submitting}
              />
            </ButtonContainer>
          </form>
        );
      }}
    />
  );
};

const Title = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px; // Add a 16px gap between buttons
`;

const Desc = styled.p`
  font-size: 16px;
  line-height: 155%;
  color: var(--black);
  margin-bottom: 30px;
`;

const Btn = styled(UIButton)`
  width: 100%;
  margin-top: 16px;
`;

export default MfaForm;
