import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { UIModalProps } from 'components/UI/interfaces';
import { UIButton, UIModal } from 'components/UI';
import { Form, FormRenderProps } from 'react-final-form';
import { Field } from 'components/FinalForm';
import { required } from 'utils/validation';
import FieldWYSIWYGEditor from 'components/FormFields/FieldWYSIWYGEditor';
import { FieldTextInput } from 'components/FormFields';
import {
  CustomizeTemplateGetReq,
  CustomizeTemplateGetRes,
  CustomizeTemplatePreviewReq,
  CustomizeTemplateSaveReq,
} from 'interfaces/gameplan';
import { ConfirmModal } from 'components/Modals';
import { useModal, useOperation } from 'hooks/common';
import GameplanTemplateMergeFieldModal from './GameplanTemplateMergeFieldModal';
// new stuff start
import { useSelector } from 'react-redux';
import { getTeam } from 'store/ducks/team/selectors';
import { fetchEmailTemplates } from 'store/ducks/team/operations';
import { teamSelectors } from 'store/ducks/team';
import { StateProps } from 'store/interfaces';
import { TemplateEmailPreview } from 'interfaces/common';
import DropdownList from 'components/DropdownList/DropdownList';
import { ReactSVG } from 'react-svg';
import EllipseSVG from 'assets/images/icons/ellipse.svg';
import { FormApi } from 'final-form';
// new stuff end

const ExplainImg =
  '<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="87.000000pt" height="104.000000pt" viewBox="0 0 87.000000 104.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,104.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"> <path d="M23 1033 l-23 -4 0 -495 c0 -414 2 -494 14 -494 7 0 16 -9 19 -20 5 -20 14 -20 416 -20 l411 0 0 355 c0 309 -2 355 -15 355 -13 0 -15 -44 -15 -335 l0 -335 -380 0 -380 0 0 480 0 480 280 0 c273 0 280 0 280 20 0 20 -6 20 -292 19 -161 -1 -303 -4 -315 -6z"/> <path d="M640 985 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 -7 -15 -15z"/> <path d="M680 945 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 -7 -15 -15z"/> <path d="M610 860 l0 -70 110 0 110 0 0 25 c0 16 -6 25 -15 25 -8 0 -15 -4 -15 -10 0 -6 -32 -10 -75 -10 l-75 0 0 55 c0 48 -2 55 -20 55 -18 0 -20 -7 -20 -70z"/> <path d="M720 905 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 -7 -15 -15z"/> <path d="M760 865 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 -7 -15 -15z"/> <path d="M160 700 c0 -19 7 -20 114 -20 122 0 146 4 146 26 0 11 -26 14 -130 14 -123 0 -130 -1 -130 -20z"/> <path d="M160 580 c0 -20 7 -20 300 -20 293 0 300 0 300 20 0 20 -7 20 -300 20 -293 0 -300 0 -300 -20z"/> <path d="M160 470 c0 -20 7 -20 300 -20 293 0 300 0 300 20 0 20 -7 20 -300 20 -293 0 -300 0 -300 -20z"/> <path d="M160 360 c0 -20 7 -20 300 -20 293 0 300 0 300 20 0 20 -7 20 -300 20 -293 0 -300 0 -300 -20z"/> <path d="M160 250 c0 -20 7 -20 300 -20 293 0 300 0 300 20 0 20 -7 20 -300 20 -293 0 -300 0 -300 -20z"/> <path d="M160 140 c0 -19 7 -20 114 -20 122 0 146 4 146 26 0 11 -26 14 -130 14 -123 0 -130 -1 -130 -20z"/> </g> </svg>';

interface BaseProps {
  onReset: (values: CustomizeTemplateGetReq) => void;
  onSave: (values: CustomizeTemplateSaveReq) => void;
  onPreview: (values: CustomizeTemplatePreviewReq) => void;
  template?: CustomizeTemplateGetRes;
  templatePreview?: CustomizeTemplateGetRes;
  isloading?: boolean;
  saveLoading?: boolean;
  noSubject?: boolean;
}

type Props = BaseProps & UIModalProps;

function CustomizeTemplateModal({
  onClose,
  onSave,
  onReset = () => {},
  onPreview,
  isloading,
  template,
  templatePreview,
  noSubject,
  saveLoading,
  ...modalProps
}: Props) {
  // new stuff start
  const [formApi, setFormApi] = useState<FormApi<
    CustomizeTemplateGetRes,
    Partial<CustomizeTemplateGetRes>
  > | null>(null);

  const [selectedTemplate, setSelectedTemplate] = useState<TemplateEmailPreview | null>(
    null,
  );
  const team = useSelector(getTeam);
  const emailTemplates = useSelector<StateProps, TemplateEmailPreview[]>(
    teamSelectors.getEmailTemplates,
  );
  const [loadEmailTemplates, isStartLoading] = useOperation(fetchEmailTemplates);

  useEffect(() => {
    if (team?.id) {
      loadEmailTemplates(team.id);
    }
  }, [team?.id, loadEmailTemplates]);

  const handleTemplateSelect = templateId => {
    const template = emailTemplates.find(t => t.id === templateId);
    if (template && formApi) {
      formApi.change('subject', template.subject);
      formApi.change('body', template.body);
    }
  };
  // new stuff end

  const [isPreview, setIsPreview] = useState(false);
  const [showConfirmModal, hideConfirmModal] = useModal(() => {
    return (
      <ConfirmModal
        accessHandler={() => {
          template && onReset({ templateType: template.templateType });
          hideConfirmModal();
        }}
        cancelHandler={hideConfirmModal}
        textAccessBtn="OK"
        textCancelBtn="No"
        name="Are you sure to reset this template?"
        onClose={hideConfirmModal}
      />
    );
  }, [template]);

  const [showInfoModal, hideInfoModal] = useModal(() => (
    <GameplanTemplateMergeFieldModal onClose={hideInfoModal} />
  ));

  const clearEditorRanges = () => {
    const iframeEl = document.querySelector(
      '#gameplan-template-modal-editor-edit iframe',
    ) as HTMLIFrameElement;
    if (!iframeEl) return;

    const selection = iframeEl.contentWindow?.getSelection();
    selection?.removeAllRanges();
  };

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '28px 40px 40px' }}
      style={{
        width: '100%',
        maxWidth: '844px',
        top: '100px',
      }}
      overlayStyle={{
        overflow: 'auto',
      }}
      isloading={isloading}
      // new stuff start
      header={
        <ModalHeader>
          <ModalTitle>Customize Template</ModalTitle>
          <TemplateDropdown>
            <DropdownList
              title={
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'var(--blue)',
                    fontSize: '16px',
                  }}
                >
                  Select Template{'  '}
                  <EllipseIcon src={EllipseSVG} style={{ marginLeft: '4px' }} />
                </span>
              }
              options={emailTemplates.map(t => ({
                label: t.name || 'Unnamed Template', // Provide a default label if t.name is undefined
                value: t.id,
              }))}
              disableArrow
              onClick={option => handleTemplateSelect(option)} // pass form here
            />
          </TemplateDropdown>
        </ModalHeader>
      }
      // new stuff end
    >
      {!isloading && template && (
        <>
          <div style={{ display: isPreview ? '' : 'none' }}>
            <Form
              initialValues={templatePreview}
              onSubmit={() => {}}
              render={() => {
                return (
                  <form>
                    {!noSubject && (
                      <Field
                        name="subject"
                        label="subject"
                        component={FieldTextInput}
                        placeholder="Enter Email Subject"
                        validate={required}
                        disabled={true}
                      />
                    )}
                    <Field
                      id="gameplan-template-modal-editor-view"
                      name="body"
                      label="body"
                      component={FieldWYSIWYGEditor}
                      placeholder="Enter text"
                      validate={required}
                      height={330}
                      disabled={true}
                    />
                    <UIButton
                      modifiers="secondary"
                      title="Cancel Preview"
                      handleClick={() => {
                        setIsPreview(false);
                      }}
                      type="button"
                    />
                  </form>
                );
              }}
            />
          </div>
          <div style={{ display: isPreview ? 'none' : '' }}>
            <Form
              initialValues={template}
              onSubmit={values => {
                onSave({ ...template, ...values });
              }}
              render={({
                handleSubmit,
                values,
                form, // new stuff
              }: FormRenderProps<CustomizeTemplateGetRes>) => {
                if (!formApi) setFormApi(form); // new stuff
                return (
                  <form>
                    {!noSubject && (
                      <Field
                        name="subject"
                        label="subject"
                        component={FieldTextInput}
                        placeholder="Enter Email Subject"
                        validate={required}
                      />
                    )}
                    <Field
                      id="gameplan-template-modal-editor-edit"
                      name="body"
                      label="body"
                      component={FieldWYSIWYGEditor}
                      placeholder="Enter text"
                      validate={required}
                      height={330}
                      toolbarCustomButtons={[
                        {
                          id: 'mergefield',
                          iconSVG: ExplainImg,
                          onClick: showInfoModal,
                        },
                      ]}
                    />

                    <BtnGroup>
                      <UIButton
                        modifiers="secondary"
                        title="Text Preview"
                        handleClick={() => {
                          onPreview({
                            templateType: template.templateType,
                            body: values.body,
                          });
                          setIsPreview(true);
                        }}
                        type="button"
                      />
                      <UIButton
                        modifiers="secondary"
                        title="Reset"
                        handleClick={() => showConfirmModal()}
                        type="button"
                      />
                      <UIButton
                        modifiers="primary"
                        title="Save"
                        isLoading={saveLoading}
                        handleClick={() => {
                          clearEditorRanges();
                          setTimeout(() => {
                            handleSubmit();
                          });
                        }}
                        type="button"
                      />
                    </BtnGroup>
                  </form>
                );
              }}
            />
          </div>
        </>
      )}
    </UIModal>
  );
}
// new stuff start
const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EllipseIcon = styled(ReactSVG)`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 30px;
`;

const TemplateDropdown = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto; // This will push the dropdown to the right
  margin-top: auto;
`;
// new stuff end

const ModalTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  padding: 40px 0 16px;
`;

const BtnGroup = styled.div`
  display: flex;

  & button:not(:last-child) {
    margin-right: 9px;
  }

  & button:last-child {
    margin-left: auto;
  }
`;

export default CustomizeTemplateModal;
