import CardGameplan from 'components/CardGameplan';
import { Gameplan, GameplanBase } from 'interfaces/gameplan';
import { TeamProfileData } from 'interfaces/team';
import GameplanMetricTooltip from 'pages/organizationUser/gameplan/components/GameplanMetricTooltip';
import React from 'react';
import styled from 'styled-components';

interface Props {
  data?: GameplanBase[];
  team: TeamProfileData;
  fetchGameplan: (gameplanId: string) => Promise<Gameplan>;
  fetchDeleteGameplanContact: (
    gameplanId: string,
  ) => Promise<{
    status: string;
  }>;
  onFetchSetGameplanNameAndClose: (param: {
    id: string;
    isClose: boolean;
    name: string;
  }) => void;
}

const GameplanDashboard = ({
  data,
  team,
  fetchGameplan,
  fetchDeleteGameplanContact,
  onFetchSetGameplanNameAndClose,
}: Props) => {
  return (
    <>
      {data && data.length > 0 ? (
        <GameplansWrapper>
          {data.map(item => (
            <CardGameplan
              key={item.id}
              gameplan={item}
              team={team}
              fetchGameplanApi={fetchGameplan}
              fetchDeleteGameplanContactApi={fetchDeleteGameplanContact}
              onFetchSetGameplanNameAndClose={onFetchSetGameplanNameAndClose}
            />
          ))}
        </GameplansWrapper>
      ) : null}
      <GameplanMetricTooltip />
    </>
  );
};

const GameplansWrapper = styled.div`
  display: flex;

  & > div {
    width: 536px;
    height: 668px;
  }

  & > div:not(:first-child) {
    margin-left: 24px;
  }
`;

export default GameplanDashboard;
