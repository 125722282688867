import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { FieldRenderProps } from 'react-final-form';

import FieldInput from '../FieldInput';
import { UITextInput } from 'components/UI';
import LinkIcon from 'assets/images/icons/link.svg';
import { useOperation } from 'hooks/common';

interface Props extends FieldRenderProps<string> {
  fetchInviteLinkOperation: () => () => Promise<unknown>;
}

const FieldInviteInput = (props: Props) => {
  const [generateInviteLink, , isGeneratingLink] = useOperation(
    props.fetchInviteLinkOperation,
  );

  const handleInviteLink = async () => {
    const link = await generateInviteLink(undefined);

    link && props.input.onChange(link);
  };

  return (
    <FieldInputWrapper>
      <FieldInput
        {...props}
        renderInput={props => (
          <InputWrapper>
            <Input {...props} />
            <button disabled={isGeneratingLink} onClick={handleInviteLink} type="button">
              <Icon src={LinkIcon} />
            </button>
          </InputWrapper>
        )}
      />
    </FieldInputWrapper>
  );
};

const InputWrapper = styled.div`
  position: relative;
`;

const Icon = styled(ReactSVG)`
  position: absolute;
  top: 0.7em;
  right: 1em;
`;

const FieldInputWrapper = styled.div`
  margin-bottom: -24px;
  width: calc(100% - 114px);
`;

const Input = styled(UITextInput)`
  border: 1px solid transparent;
  border-radius: 6px 0px 0px 6px;
  background: #f1f4f5;
  padding-right: 40px;
`;

export default FieldInviteInput;
