/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useModal, useOperation } from 'hooks/common';
import { fetchCompany } from 'store/ducks/companies/operations';
import { getCompanyById } from 'store/ducks/companies/selectors';
import { fetchCompanyContacts } from 'store/ducks/companies/operations';
import { getCompanyContacts } from 'store/ducks/companies/selectors';
import Jumbotron from 'components/Jumbotron';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import { StateProps } from 'store/interfaces';
import { CompanyContact, SearchCompany } from 'interfaces/companies';
import { fetchVerifyEmailStatus } from 'store/ducks/user/operations';
import { UIButton } from 'components/UI';
import {
  fetchGameplanCustomizeTemplate,
  fetchGameplanCustomizeTemplatePreview,
  fetchGameplanCustomizeTemplateReset,
  fetchGameplanCustomizeTemplateSave,
} from 'store/ducks/gameplan/operations';
import CustomizeTemplateModal from 'components/gameplan/CustomizeTemplateModal';
import {
  getGameplanCustomizeTemplate,
  getGameplanCustomizeTemplatePreview,
} from 'store/ducks/gameplan/selectors';
import { GamePlanEmailTemplateTypeEnum } from 'constants/gamePlan';
import ContactsTable from 'components/ContactsTable';

const Contacts = () => {
  const [onFetchContacts, isLoading] = useOperation(fetchCompanyContacts);
  const [onFetchCompany] = useOperation(fetchCompany);
  const [onFetchVerifyEmailStatus] = useOperation(fetchVerifyEmailStatus);
  const [onFetchGameplanCustomizeTemplate, , isCustomizeLoading] = useOperation(
    fetchGameplanCustomizeTemplate,
  );
  const [onFetchGameplanCustomizeTemplateSave] = useOperation(
    fetchGameplanCustomizeTemplateSave,
  );
  const [onFetchGameplanCustomizeTemplateReset] = useOperation(
    fetchGameplanCustomizeTemplateReset,
  );
  const [onFetchGameplanCustomizeTemplatePreview] = useOperation(
    fetchGameplanCustomizeTemplatePreview,
  );

  const gameplanCustomizeTemplate = useSelector(getGameplanCustomizeTemplate);
  const gameplanCustomizeTemplatePreview = useSelector(
    getGameplanCustomizeTemplatePreview,
  );

  const params: { companyId: string } = useParams();

  const contacts = useSelector<StateProps, CompanyContact[]>(getCompanyContacts);
  const company = useSelector<StateProps, SearchCompany>(
    getCompanyById(params.companyId),
  );

  const [showCustomizeTemplateModal, hideCustomizeTemplateModal] = useModal(() => {
    return (
      <CustomizeTemplateModal
        noSubject
        template={gameplanCustomizeTemplate}
        templatePreview={gameplanCustomizeTemplatePreview}
        isloading={isCustomizeLoading}
        onClose={hideCustomizeTemplateModal}
        onReset={templateType => onFetchGameplanCustomizeTemplateReset(templateType)}
        onSave={values => onFetchGameplanCustomizeTemplateSave(values)}
        onPreview={templateType => onFetchGameplanCustomizeTemplatePreview(templateType)}
      />
    );
  }, [gameplanCustomizeTemplate, isCustomizeLoading, gameplanCustomizeTemplatePreview]);

  useEffect(() => {
    onFetchContacts(params.companyId);
    onFetchCompany(params.companyId);
    onFetchVerifyEmailStatus(undefined);
  }, [params]);

  return (
    <ContentBlockWrapper
      loading={isLoading}
      header={
        <Jumbotron name="Company Contacts" back>
          <Button
            title="customize template"
            modifiers="secondary"
            handleClick={() => {
              onFetchGameplanCustomizeTemplate({
                templateType: GamePlanEmailTemplateTypeEnum.PitchTemplate,
              });
              showCustomizeTemplateModal();
            }}
          />
        </Jumbotron>
      }
    >
      {contacts && (
        <>
          <ContactsCount>{contacts.length} contacts</ContactsCount>
          {company && <ContactsTable rows={[...contacts]} />}
        </>
      )}
    </ContentBlockWrapper>
  );
};

const ContactsCount = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 16px;
`;

const Button = styled(UIButton)`
  margin-left: auto;
`;

export default Contacts;
