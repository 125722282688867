import {
  DashboardROI,
  ROIAddCompanyRes,
  ROIAddTeamRes,
  ROICreateReq,
  ROIData,
  ROIListReq,
  ROIListRes,
  ROIMeltwaterData,
  ROIReport,
  ROIDashboardSdgGoals,
  ROITemplate,
  ROISurvey,
  ROISurveyResult,
  ROICanvaReportResult,
  ROICanva,
  ROISurveyStatistics,
  ROISurveyListRes,
  ROISurveyListReq,
  ROISurveyStatistic,
  ROICanvaTemplatesReq,
  ROICanvaTemplatesRes,
  ROICanvaTemplatesCreateReq,
} from 'interfaces/roi';
import { fetch } from 'services/ApiService';
import { paramsToQueryString } from 'utils';
import StorageService from 'services/Storage';
import { CompanyCreateReq } from 'interfaces/companies';
import { TeamCreateReq } from 'interfaces/team';

export const fetchROIList = ({ limit = 12, ...rest }: ROIListReq) =>
  fetch<ROIListRes>('get', `ROI/byTeams${paramsToQueryString({ limit, ...rest })}`);

export const fetchROIListByCompanies = ({ limit = 12, ...rest }: ROIListReq) =>
  fetch<ROIListRes>('get', `ROI/byCompanies${paramsToQueryString({ limit, ...rest })}`);

export const fetchROICreate = (param: ROICreateReq) =>
  fetch<ROIData>('post', 'ROI', { payload: param });

export const fetchROI = (id: string) => fetch<ROIData>('get', `ROI/${id}`);

export const fetchROIUpdate = (param: Partial<ROIData>) =>
  fetch<ROIData>('patch', 'ROI', {
    payload: {
      ...param,
      longevityOfPartnership:
        param.longevityOfPartnership && Number(param.longevityOfPartnership),
    },
  });

export const fetchMultipleDelete = (ids: string) =>
  fetch<ROIData>(
    'delete',
    `ROI/MultipleDelete${paramsToQueryString({ deleteIds: ids })}`,
  );

export const fetchDownloadROITemplate = (type: 'company' | 'team') =>
  fetch<{ name: string; file: string }>(
    'get',
    `ROI/downLoadTemplate${paramsToQueryString({ type })}`,
  );

export const fetchUploadROIXLSX = (file: File) => {
  const data = new FormData();
  data.append('file', file, file?.name);

  return fetch<{ status: string; fileKey: string }>(
    'post',
    `ROI/upload-excel`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  );
};

export const fetchUploadROIXLSXByCompany = (file: File) => {
  const data = new FormData();
  data.append('file', file, file?.name);
  data.append('isCompany', 'true');

  return fetch<{ status: string; fileKey: string }>(
    'post',
    `ROI/upload-excel`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  );
};

export const fetchROIGenerateTemplates = (roiId: string) =>
  fetch<ROITemplate[]>('post', `ROI/generateTemplates/${roiId}`);

export const fetchROITemplates = (roiId: string) =>
  fetch<ROITemplate[]>('get', `ROI/template/list/${roiId}`);

export const fetchROITemplate = (templateId: string) =>
  fetch<ROITemplate>('get', `ROI/template/${templateId}`);

export const fetchROITemplatePreview = (templateId: string) =>
  fetch<string>('get', `ROI/template/preview?templateId=${templateId}`);

export const fetchROITemplateCompiled = (template: ROITemplate) =>
  fetch<string>('post', `ROI/template/compiled`, { payload: template });

export const fetchROITemplateSave = (template: ROITemplate) =>
  fetch<ROITemplate>('post', 'ROI/template/save', { payload: template });

export const fetchROITemplateListSave = (templateList: ROITemplate[]) =>
  fetch<ROITemplate[]>('post', 'ROI/template/saveList', {
    payload: templateList,
  });

export const fetchROITemplateReset = (templateId: string) =>
  fetch<ROITemplate>('post', `ROI/template/reset/${templateId}`);

export const fetchRROICalReport = (roiId: string) =>
  fetch<ROIReport>('patch', `ROI/${roiId}/cal`);

export const fetchROIGenerateReport = (roiId: string) =>
  fetch<ROIReport>('patch', `ROI/${roiId}/generate`);

export const fetchROIAddCompany = (param: CompanyCreateReq) =>
  fetch<ROIAddCompanyRes>('post', `ROI/addCompany`, { payload: param });

export const fetchROIAddTeam = (param: TeamCreateReq) =>
  fetch<ROIAddTeamRes>('post', `ROI/addTeam`, { payload: param });

// No website validate
export const fetchROIAddCompanyNew = (param: CompanyCreateReq) =>
  fetch<ROIAddCompanyRes>('post', `ROI/addCompanyNew`, { payload: param });

// No website validate
export const fetchROIAddTeamNew = (param: TeamCreateReq) =>
  fetch<ROIAddTeamRes>('post', `ROI/addTeamNew`, { payload: param });

export const fetchROIMeltwaterData = (roiId: string) =>
  fetch<ROIMeltwaterData>('get', `ROI/meltwater/${roiId}/LastAnalytics`);

export const fetchROIMeltwaterDataById = (analyticsId: string) =>
  fetch<ROIMeltwaterData>('get', `ROI/meltwater/${analyticsId}`);

export const fetchGenerateexcel = (roiId: string) =>
  fetch<{ name: string; file: string }>('patch', `ROI/${roiId}/generateexcel`);

export const fetchROIDashboard = () => fetch<DashboardROI>('get', 'ROI/dashboard');

export const fetchROISdgGoals = () => fetch<ROIDashboardSdgGoals>('get', 'ROI/sdggoals');

export const fetchDownloadROISurveyTeamTemplate = () =>
  fetch<{ name: string; file: string }>('get', 'ROI/survey/downloadTemplate');

export const fetchUploadROISurveyTemplate = (file: File) => {
  const data = new FormData();
  data.append('file', file, file?.name);

  return fetch<ROISurvey['contacts']>(
    'post',
    `ROI/survey/upload-excel`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  );
};

export const fetchROISurveyCreate = (params: ROISurvey) =>
  fetch<ROISurvey>('post', 'ROI/survey', { payload: params });

export const fetchROISurvey = (roiId: string) =>
  fetch<ROISurvey[]>('get', `ROI/${roiId}/survey`);

export const fetchROISurveyResult = (id: string) =>
  fetch<ROISurveyResult>('get', `ROI/survey/result/${id}`);

export const fetchROISurveyResultSubmit = (params: ROISurveyResult) =>
  fetch<ROISurveyResult>('post', 'ROI/survey/result', { payload: params });

export const fetchROIOld = (id: string) => fetch<ROIData>('get', `ROI/old/${id}`);

export const fetchROICanvaUpdate = (params: ROICanvaReportResult) =>
  fetch<ROICanva>('patch', `ROI/canva/${params.id}`, {
    payload: params,
  });

export const fetchROICanvaDelete = (id: string) =>
  fetch<ROICanva>('delete', `ROI/canva/${id}`);

export const fetchROICanvaTemplates = ({ limit = 6, ...params }: ROICanvaTemplatesReq) =>
  fetch<ROICanvaTemplatesRes>(
    'get',
    `ROI/canva/template${paramsToQueryString({ limit, ...params })}`,
  );

export const fetchROICanvaTemplatesCreate = (params: ROICanvaTemplatesCreateReq) =>
  fetch<ROICanvaTemplatesRes>('post', 'ROI/canva/template', { payload: params });

export const fetchROIGenerateImpactDataExcel = (roiId: string) =>
  fetch<{ name: string; file: string }>('get', `ROI/${roiId}/generate-impact-data-excel`);

export const fetchGetOktaUser = () =>
  fetch<{
    username: string;
    password: string;
  }>('get', 'canva/get-okta-user');

export const fetchCreateCanvaLog = (roiId: string) =>
  fetch('post', 'ROI/create-canva-log', { payload: { roiId } });

export const fetchROISurveyStatistics = () =>
  fetch<ROISurveyStatistics>('get', 'ROI/surveyStatistics');

export const fetchSurveySearch = ({ limit = 10, ...rest }: ROISurveyListReq) =>
  fetch<ROISurveyListRes>(
    'get',
    `ROI/survey/search${paramsToQueryString({ limit, ...rest })}`,
  );

export const fetchSurveyStatisticById = (id: string) =>
  fetch<ROISurveyStatistic>('get', `ROI/survey/statics/${id}`);

export const fetchSurveyDeleteMultiple = (deleteIds: string) =>
  fetch('delete', `ROI/survey/MultipleDelete${paramsToQueryString({ deleteIds })}`);
