import {
  CharitablesRes,
  IssueMappingSurveyListRes,
  IssueMappingSurveyResult,
  RecommendationData,
} from 'interfaces/issueMapping';
import * as types from './types';

export const setIssueMappingSurveyList = (list: IssueMappingSurveyListRes) =>
  ({
    type: types.SET_ISSUE_MAPPING_SURVEY_LIST,
    payload: list,
  } as const);

export const setIssueMappingSurveyResult = (result: IssueMappingSurveyResult) =>
  ({
    type: types.SET_ISSUE_MAPPING_SURVEY_RESULT,
    payload: result,
  } as const);

export const setRecommendation = (data: RecommendationData) =>
  ({
    type: types.SET_RECOMMENDATION,
    payload: data,
  } as const);

export const setCharitables = (data: CharitablesRes) =>
  ({
    type: types.SET_CHARITABLES,
    payload: data,
  } as const);
