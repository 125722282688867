import { TeamMemberRoles, User } from 'interfaces/auth';
import { useMemo } from 'react';

const useIsTSUser = (user: User) => {
  const isTSUser = useMemo(() => {
    return user.role === TeamMemberRoles.TEAMSNAP_USER && user.role !== undefined;
  }, [user.role]);

  return isTSUser;
};

export default useIsTSUser;
