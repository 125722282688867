import React from 'react';
import styled from 'styled-components';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';

interface Props {
  missionImpact: string;
  themeColor?: string;
}

const MissonImpactMetric = ({ missionImpact, themeColor }: Props) => {
  return (
    <MetricRoot>
      <MetricTitle $themeColor={themeColor || '#1992B9'}>Mission Impact</MetricTitle>
      <Content>{missionImpact}</Content>
    </MetricRoot>
  );
};

const Content = styled(MetricContent)`
  word-wrap: break-word;
`;

export default MissonImpactMetric;
