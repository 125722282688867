import { DashboardROI } from 'interfaces/roi';
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {
  OverallPartnerValueTabContent,
  OverallTabContent,
  ROITabContent,
  TotalFundsRaisedTabContent,
  TotalMediaImpressionsTabContent,
  TotalMediaValueTabContent,
} from 'pages/organizationUser/roi/components';

interface Props {
  data: DashboardROI | undefined;
}

const ROITabCharts = ({ data }: Props) => {
  return (
    <Tabs>
      <TabList>
        <Tab>Overall</Tab>
        <Tab>Total Funds Raised</Tab>
        <Tab>Total Media Value</Tab>
        <Tab>Overall Partner Value</Tab>
        <Tab>ROI</Tab>
        <Tab>Total Media Impressions</Tab>
      </TabList>
      <TabPanel>
        <OverallTabContent data={data?.overAllData} />
      </TabPanel>
      <TabPanel>
        <TotalFundsRaisedTabContent data={data?.totalFundsRaised.slice(0, 5)} />
      </TabPanel>
      <TabPanel>
        <TotalMediaValueTabContent data={data?.totalMediaValue.slice(0, 5)} />
      </TabPanel>
      <TabPanel>
        <OverallPartnerValueTabContent data={data?.totalPartnerValue.slice(0, 5)} />
      </TabPanel>
      <TabPanel>
        <ROITabContent data={data?.roi.slice(0, 5)} />
      </TabPanel>
      <TabPanel>
        <TotalMediaImpressionsTabContent data={data?.totalMediaImpressions.slice(0, 5)} />
      </TabPanel>
    </Tabs>
  );
};

export default ROITabCharts;
