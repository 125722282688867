import SearchSVG from 'assets/images/icons/search.svg';
import CloseSVG from 'assets/images/icons/close.svg';
import DropdownList from 'components/DropdownList/DropdownList';
import { UIDivider, UITextInput } from 'components/UI';
import React, { ReactNode } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { DropDownOption } from 'components/UI/interfaces';

interface SearchInputProps {
  onSearch: () => void;
  className?: string;
  children?: ReactNode;
  searchInputValue: string;
  onChangeInputValue: (val: string) => void;
  filterTypesOptions?: DropDownOption[];
  filterTypeValue?: string;
  onChangeFilterType?: (type: DropDownOption['value']) => void;
  placeholder?: string;
  bgColor?: string;
  onClear?: () => void;
}

const SearchInput = ({
  className,
  children,
  placeholder = 'Search',
  onSearch,
  searchInputValue,
  onChangeInputValue,
  filterTypesOptions,
  filterTypeValue,
  onChangeFilterType,
  bgColor,
  onClear,
}: SearchInputProps) => {
  return (
    <Root className={className}>
      {filterTypesOptions && (
        <>
          <FilterTypeDropdown
            title={
              <FilterTypeDropdownTitle>
                {filterTypesOptions.find(item => item.value === filterTypeValue)?.label}
              </FilterTypeDropdownTitle>
            }
            options={filterTypesOptions}
            onClick={value => onChangeFilterType?.(value)}
          />
          <Divider />
        </>
      )}

      <Input
        $filterTypesOptions={filterTypesOptions}
        value={searchInputValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChangeInputValue(event.target.value)
        }
        placeholder={placeholder}
        onKeyUp={e => e.key === 'Enter' && onSearch()}
        $bgColor={bgColor}
      />
      {onClear && <ClearIcon src={CloseSVG} onClick={onClear} />}
      <SearchIcon src={SearchSVG} onClick={onSearch} />
      {children}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
`;

const Input = styled(UITextInput)<{
  $filterTypesOptions: SearchInputProps['filterTypesOptions'];
  $bgColor?: string;
}>`
  background: ${({ $bgColor }) => $bgColor || `#fff`};
  border-radius: 6px;
  border-color: transparent;
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
  padding: 8px 42px 8px 20px;

  ${({ $filterTypesOptions }) =>
    $filterTypesOptions &&
    `
    padding-left: 146px; 
  `}

  &:focus {
    border-color: transparent;
  }
`;

const SearchIcon = styled(ReactSVG)`
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
  transform: translateY(-50%);
`;

const ClearIcon = styled(SearchIcon)`
  right: 42px;

  path {
    stroke: #737373;
  }
`;

const FilterTypeDropdown = styled(DropdownList)<{ $bgColor?: string }>`
  position: absolute;
  left: 12px;
  top: 2px;

  & .dropdown-panel {
    font-size: 12px;
    color: var(--black);
    width: 132px;
    top: 38px;
    right: -5px;
    background: ${({ $bgColor }) => $bgColor || `#fff`};
  }

  & .dropdown-list-item {
    padding-left: 12px;
  }
`;

const FilterTypeDropdownTitle = styled.div`
  line-height: 30px;
  width: 100px;
`;

const Divider = styled(UIDivider)`
  position: absolute;
  left: 130px;
  top: 6px;
`;

export default SearchInput;
