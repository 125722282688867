import React from 'react';

import { UIActionButton } from 'components/UI';
import { ActionButtonProps as InjectedProps } from 'interfaces/common';
import SaveIcon from 'assets/images/icons/save-list.svg';

interface BaseProps {
  saveHandler: () => void;
}

type Props = BaseProps & Partial<InjectedProps>;

const SaveListButton = ({
  saveHandler,
  name = 'Save List',
  icon = SaveIcon,
  ...rest
}: Props) => {
  return <UIActionButton {...rest} handleClick={saveHandler} icon={icon} name={name} />;
};

export default SaveListButton;
