import React from 'react';
import styled from 'styled-components';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { ColorPicker, useColor } from 'react-color-palette';

interface BaseProps {
  value: string;
  handleSubmit: (value: string) => void;
}

type Props = BaseProps & InjectedProps;

const SelectColorModal = ({ handleSubmit, onClose, value, ...modalProps }: Props) => {
  useLockBodyScroll();
  const [color, setColor] = useColor('hex', value);

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '0' }}
      style={{
        width: '100%',
        maxWidth: '520px',
      }}
    >
      <Content>
        <Title>Select Color</Title>
        <ColorPicker
          width={456}
          height={228}
          color={color}
          onChange={setColor}
          hideHSV
          dark
        />
        <Actions>
          <UIButton modifiers="secondary" title="Cancel" handleClick={onClose} />
          <UIButton
            modifiers="primary"
            title="Select"
            handleClick={() => {
              handleSubmit(color.hex);
              onClose();
            }}
          />
        </Actions>
      </Content>
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0 auto 8px;
`;

const Content = styled.div`
  margin: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

export default SelectColorModal;
