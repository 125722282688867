import React, { ReactNode } from 'react';
import { IThumbProps } from 'react-range/lib/types';

interface Props {
  thumbProps: IThumbProps;
  value: number;
  index: number;
  isDragged: boolean;
  modifyValueFunc?: (value: number) => ReactNode;
}

const rootStyles: React.CSSProperties = {
  height: '32px',
  width: '72px',
  borderRadius: '6px',
  backgroundColor: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'var(--black)',
  fontSize: '12px',
  border: '1px solid var(--secondaryBlue)',
  boxSizing: 'border-box',
};

const RangeThumb = ({ thumbProps, value, modifyValueFunc }: Props) => {
  return (
    <div
      {...thumbProps}
      style={{
        ...thumbProps.style,
        ...rootStyles,
      }}
    >
      {modifyValueFunc ? modifyValueFunc(value) : value}
    </div>
  );
};

export default RangeThumb;
