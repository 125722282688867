import React from 'react';
import { getTrackBackground } from 'react-range';
import { ITrackProps } from 'react-range/lib/types';

interface Props {
  trackProps: ITrackProps;
  children: React.ReactNode;
  isDragged?: boolean;
  disabled?: boolean;
  values: number[];
  min: number;
  max: number;
}

const RangeTrack = ({
  trackProps: { onMouseDown, onTouchStart, ref, style },
  children,
  values,
  min,
  max,
}: Props) => {
  return (
    <div
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      style={{
        ...style,
        height: '32px',
        display: 'flex',
        width: '100%',
      }}
    >
      <div
        ref={ref}
        style={{
          height: '2px',
          width: '100%',
          background: getTrackBackground({
            values,
            colors: ['#E8E8E8', 'var(--blue)', '#E8E8E8'],
            min,
            max,
          }),
          alignSelf: 'center',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default RangeTrack;
