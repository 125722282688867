import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { Link } from 'react-router-dom';
import { FieldTextInput, FieldPasswordInput, FieldCheckbox } from 'components/FormFields';
import { UIButton } from 'components/UI';
import { required, email } from 'utils/validation';
import { AuthData } from 'interfaces/auth';
import { composeValidators } from 'utils';
import styled, { css } from 'styled-components';
import { AuthFormProps } from 'interfaces/common';
import { Field } from 'components/FinalForm';

const LoginForm = ({ onSubmit, initialValues }: AuthFormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({
        handleSubmit,
        submitting,
        hasValidationErrors,
      }: FormRenderProps<AuthData>) => {
        return (
          <form>
            <Title>Welcome to Accelerist</Title>

            {/* <AuthNavigation /> */}
            <Field
              name="email"
              label="Email"
              component={FieldTextInput}
              placeholder="Enter email"
              validate={composeValidators<string>(required, email)}
            />

            <Field
              name="password"
              label="Password"
              type="password"
              component={FieldPasswordInput}
              validate={required}
              placeholder="Enter password"
            />

            <Forgot>
              <Field
                name="rememberMe"
                label="Remember"
                component={FieldCheckbox}
                labelCss={css`
                  font-size: 12px;
                `}
              />
              <LinkElem to="/reset">Forgot password?</LinkElem>
            </Forgot>

            <Btn
              modifiers="primary"
              title="Login"
              handleClick={handleSubmit}
              type="submit"
              disabled={submitting || hasValidationErrors}
              isLoading={submitting}
            />
          </form>
        );
      }}
    />
  );
};

const Title = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  margin-bottom: 20px;
  text-align: center;
`;

const LinkElem = styled(Link)`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
  &:hover {
    color: var(--black);
  }
`;

const Forgot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Btn = styled(UIButton)`
  width: 100%;
`;

export default LoginForm;
