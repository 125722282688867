import styled from 'styled-components';
import { UIButton, UILoadAvatar } from 'components/UI';
import { ReactSVG } from 'react-svg';

export const Root = styled.div`
  border-radius: 6px;
  width: 100%;
  margin-bottom: 20px;
  overflow: hidden;
`;

export const Body = styled.div`
  background: #fff;
  display: flex;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f2f2f2;
  padding: 40px;
  align-items: center;
`;

export const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const LoadImage = styled(UILoadAvatar)`
  border-radius: 8px;
  width: 100px;
  min-width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 26px;
`;

export const ImageEmpty = styled.div`
  border-radius: 8px;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 26px;
  background-color: #fff;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OrganizationTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OrganizationType = styled.p`
  font-size: 12px;
  line-height: 150%;
  margin-bottom: 14px;
`;

export const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SocialLink = styled.a`
  display: block;
  width: 20px;
  margin-right: 12px;
  & > div {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const EditButton = styled(UIButton)`
  width: 96px;
  font-size: 12px;
  padding: 8px 0;
`;

export const Desc = styled.div`
  display: flex;
  border-bottom: 1px solid #ebebeb;
`;

export const DescTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;

export const DescSection = styled.div`
  padding: 32px 40px 30px;
  width: 100%;
`;

export const PartnersWrapper = styled(DescSection)`
  border-left: 1px solid #ebebeb;
`;

export const ContributionsWrapper = styled.div`
  margin-bottom: 16px;
`;

export const DescLabel = styled.p`
  font-size: 12px;
  line-height: 150%;
  margin-bottom: 8px;
`;

export const DescValue = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
`;

export const PartnersList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

export const PartnersItem = styled.li`
  margin-right: 16px;
  margin-bottom: 16px;
  width: 94px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PartnersName = styled.div`
  width: 100%;
  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
`;

export const PartnersLogoWrapper = styled.div`
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 6px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PartnersImg = styled(UILoadAvatar)`
  border-radius: 6px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
`;

export const PartnersEmptyImg = styled.div`
  border-radius: 6px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
`;

export const ContactItem = styled.li`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 11px;
  }
`;

export const ContactIcon = styled(ReactSVG)`
  margin-right: 18px;
  width: 20px;
`;

export const ContactLink = styled.a`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;

export const AlignmentWrapper = styled.div`
  padding: 32px 40px 12px;
  border-bottom: 1px solid #ebebeb;
`;

export const AlignmentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const AlignmentItem = styled.img`
  width: 112px;
  height: 112px;
  margin-bottom: 16px;
`;

export const InfoWrapper = styled.div`
  padding: 40px 40px 30px;
`;

export const InfoTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  margin-bottom: 8px;
`;

export const InfoValue = styled.p`
  font-size: 16px;
  line-height: 155%;
  margin-bottom: 40px;
`;

export const OpportunitiesValueWrapper = styled.div`
  margin-bottom: 40px;
`;

export const OpportunitiesValue = styled.p`
  font-size: 16px;
  line-height: 155%;
  margin-bottom: 10px;
`;

export const MissionWrapper = styled.div`
  margin-bottom: 20px;
`;

export const MissionTitle = styled(InfoTitle)`
  margin-bottom: 12px;
`;

export const MissionSubTitle = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 12px;
`;

export const MissionValue = styled(InfoValue)`
  margin-bottom: 20px;
  word-break: break-word;
`;

export const ImpactValue = styled(InfoValue)`
  margin-bottom: 0;
`;

export const EmptyAlignment = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 15px;
  width: 112px;
  height: 112px;
  background: #f2f2f2;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  text-align: center;
  margin-bottom: 20px;
`;

export const EmptyPartners = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 183px;
  height: 116px;
  font-size: 12px;
  line-height: 150%;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 18px;
`;

export const EmptyPartnersImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: #f2f2f2;
  border-radius: 6px;
  margin-bottom: 8px;

  & img {
    width: 24px;
  }
`;

export const DemographicsDesc = styled.p`
  font-size: 16px;
  line-height: 155%;
  margin-bottom: 32px;
`;

export const Content = styled.div`
  padding: 32px 40px;
  border-right: 1px solid #ebebeb;
  width: 100%;
`;

export const ProgramBlock = styled.div`
  background: #f2f7fa;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 16px;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const ProgramExpenseRatioText = styled.span`
  font-size: 12px;
  line-height: 150%;
  color: #737373;
  display: flex;
  align-items: center;
`;

export const ProgramExpenseRatioValue = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
  margin-right: 8px;
`;

export const GuidestartWrapper = styled.div`
  display: flex;
`;

export const GuidestarBlock = styled.div`
  width: 50%;
  margin-right: 12px;
`;
export const GuidestarText = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
  color: var(--black);
`;
export const GuidestarLink = styled.a`
  font-size: 16px;
  line-height: 145%;
  color: #2baee0;
  width: 100%;
  word-wrap: break-word;
`;
export const GuidestarValue = styled.div`
  font-size: 16px;
  line-height: 145%;
  color: #01101e;
`;
