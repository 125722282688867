import { FormatNumber } from 'components/UI/format';
import { DashboardROI } from 'interfaces/roi';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Cell, Pie, PieChart, Tooltip, Legend } from 'recharts';

interface Props {
  data?: DashboardROI['overAllData'];
}

const COLORS = ['#4AD78B', '#CCCACA', '#E92D30'];

const OverallTabContent = ({ data }: Props) => {
  const pieData = useMemo(() => {
    if (!data)
      return [
        { name: 'Positive', value: 0 },
        { name: 'Neutral', value: 0 },
        { name: 'Negative', value: 0 },
      ];

    const totalValue =
      data.sentiment.positive + data.sentiment.neutral + data.sentiment.negative;
    return [
      { name: 'Positive', value: data.sentiment.positive / totalValue },
      { name: 'Neutral', value: data.sentiment.neutral / totalValue },
      { name: 'Negative', value: data.sentiment.negative / totalValue },
    ];
  }, [data]);

  return (
    <OverallWrapper>
      <OverallLeft>
        <OverallItem style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
          <OverallItemTitle>Total Funds Raised</OverallItemTitle>
          <OverallItemValue>
            {data?.totalFundsRaised ? (
              <FormatNumber value={data.totalFundsRaised} prefix="$" />
            ) : (
              'N/A'
            )}
          </OverallItemValue>
        </OverallItem>
        <OverallItem>
          <OverallItemTitle>Total Media Value</OverallItemTitle>
          <OverallItemValue>
            {data?.totalMediaValue ? (
              <FormatNumber value={data.totalMediaValue} prefix="$" decimalScale={0} />
            ) : (
              'N/A'
            )}
          </OverallItemValue>
        </OverallItem>
        <OverallItem>
          <OverallItemTitle>Total Media Impressions</OverallItemTitle>
          <OverallItemValue>
            {data?.totalMediaImpressions ? (
              <FormatNumber value={data?.totalMediaImpressions} />
            ) : (
              'N/A'
            )}
          </OverallItemValue>
        </OverallItem>
        <OverallItem>
          <OverallItemTitle>Overall Partner Value (NPO)</OverallItemTitle>
          <OverallItemValue>
            {data?.totalPartnerValue.npoValue ? (
              <FormatNumber value={data?.totalPartnerValue.npoValue} prefix="$" />
            ) : (
              'N/A'
            )}
          </OverallItemValue>
        </OverallItem>
        <OverallItem>
          <OverallItemTitle>Overall Partner Value (Corp)</OverallItemTitle>
          <OverallItemValue>
            {data?.totalPartnerValue.corpValue ? (
              <FormatNumber value={data?.totalPartnerValue.corpValue} prefix="$" />
            ) : (
              'N/A'
            )}
          </OverallItemValue>
        </OverallItem>
        <OverallItem>
          <OverallItemTitle>ROI (NPO)</OverallItemTitle>
          <OverallItemValue>
            {data?.roi.npoValue ? (
              <FormatNumber
                value={data?.roi.npoValue * 100}
                suffix="%"
                decimalScale={0}
              />
            ) : (
              'N/A'
            )}
          </OverallItemValue>
        </OverallItem>
        <OverallItem>
          <OverallItemTitle>ROI (Corp)</OverallItemTitle>
          <OverallItemValue>
            {data?.roi.corpValue ? (
              <FormatNumber
                value={data?.roi.corpValue * 100}
                suffix="%"
                decimalScale={0}
              />
            ) : (
              'N/A'
            )}
          </OverallItemValue>
        </OverallItem>
      </OverallLeft>
      <div>
        <OverallItemTitle>Sentiment</OverallItemTitle>
        <PieWrapper>
          <PieChart width={400} height={300}>
            <Pie
              dataKey="value"
              data={pieData}
              cx={200}
              cy={140}
              innerRadius={40}
              outerRadius={80}
            >
              {pieData.map((entry, index) => {
                return (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                );
              })}
            </Pie>
            <Legend iconType="circle" />
            <Tooltip formatter={value => `${(value * 100).toFixed(2)}%`} />
          </PieChart>
        </PieWrapper>
      </div>
    </OverallWrapper>
  );
};

const OverallWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  overflow-x: hidden;
`;

const OverallLeft = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px 18px;
`;

const OverallItem = styled.div``;

const OverallItemTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  margin-bottom: 6px;
  white-space: nowrap;
`;

const OverallItemValue = styled.div`
  background: #f9f9f9;
  border-radius: 4px;
  height: 53px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  text-align: center;
`;

const PieWrapper = styled.div`
  display: flex;
  justify-content: center;

  .recharts-legend-item-text {
    font-size: 12px;
    font-weight: 500;
  }
`;

export default OverallTabContent;
