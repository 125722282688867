import { UISuggestSingle } from 'components/UI';
import { FormError, FormLabel } from 'components/UI/form';
import React, { CSSProperties, useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled from 'styled-components';

interface FieldSuggestProps extends FieldRenderProps<{ name: string; id: string }> {
  handleGetSuggestions: (val: string) => void;
  style?: CSSProperties;
  onChangeByType?: (text: string) => void;
  isTypeInputToForm?: boolean;
  isShowTitle?: boolean;
}

const FieldSuggestSingle = ({
  input,
  meta,
  label,
  handleGetSuggestions,
  list,
  placeholder,
  isTypeInputToForm,
  onChangeByType,
  isShowTitle = false,
  ...props
}: FieldSuggestProps) => {
  const { value, onChange, onBlur } = input;
  const { error, submitError, touched, dirtySinceLastSubmit } = meta;
  const isError = (error && touched) || (submitError && !dirtySinceLastSubmit);

  const handleBlur = useCallback(() => {
    input.onBlur();
    if (onBlur) onBlur();
  }, [input, onBlur]);

  const onSetValue = suggestion => onChange(suggestion);
  const handleChangeByType = (text: string) => {
    onChangeByType?.(text);
    isTypeInputToForm && onChange({ name: text, id: '' });
  };

  return (
    <Field style={props.style}>
      {label && <FormLabel>{label}</FormLabel>}
      <UISuggestSingle
        list={list}
        placeholder={placeholder}
        value={value}
        handleGetSuggestions={handleGetSuggestions}
        handleSelectSuggest={onSetValue}
        handleBlur={handleBlur}
        onChangeByType={handleChangeByType}
        isShowTitle={isShowTitle}
        error={isError}
        {...props}
      />
      {isError && <Error meta={meta} />}
    </Field>
  );
};

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const Error = styled(FormError)`
  position: absolute;
`;

export default FieldSuggestSingle;
