import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { Link } from 'react-router-dom';
import { FieldTextInput, FieldPasswordInput } from 'components/FormFields';

import { UIButton } from 'components/UI';
import { required, email, passwordLength } from 'utils/validation';
import { AuthData } from 'interfaces/auth';
import { composeValidators } from 'utils';
import { AuthFormProps } from 'interfaces/common';
import { Field } from 'components/FinalForm';
import styled from 'styled-components';

const TSSignUpForm = ({ onSubmit, initialValues }: AuthFormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({
        handleSubmit,
        submitting,
        pristine,
        hasValidationErrors,
      }: FormRenderProps<AuthData>) => {
        return (
          <form>
            <Title>Welcome to Accelerist</Title>

            <Field
              name="email"
              label="Email"
              component={FieldTextInput}
              placeholder="Enter email"
              disabled={true}
              validate={composeValidators<string>(required, email)}
            />

            <Field
              name="password"
              label="Password"
              type="password"
              component={FieldPasswordInput}
              validate={composeValidators<string>(required, passwordLength)}
              placeholder="Enter password"
            />

            <Desc>
              <p>
                I agree that by clicking <strong>“Register”</strong> I accept the{' '}
                <LinkElem to="/terms">Terms Of Service</LinkElem> and{' '}
                <LinkElem to="/privacy-policy">Privacy Policy</LinkElem>
              </p>
            </Desc>

            <LoginLinkContainer>
              <p style={{ fontSize: '12px' }}>Already have an account?</p>
              <LinkElem to="/login">Login</LinkElem>
            </LoginLinkContainer>

            <Btn
              modifiers="primary"
              title="Register"
              handleClick={handleSubmit}
              type="submit"
              disabled={pristine || submitting || hasValidationErrors}
              isLoading={submitting}
            />
          </form>
        );
      }}
    />
  );
};

const Title = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  margin-bottom: 20px;
  text-align: center;
`;

const LinkElem = styled(Link)`
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  &:hover {
    text-decoration: underline;
  }
`;

const Desc = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 40px;

  & > p {
    font-size: 12px;
    text-align: center;
    line-height: 150%;
    color: var(--darkGray);
    & > strong {
      color: var(--black);
      font-weight: 500;
    }
  }
`;

const Btn = styled(UIButton)`
  width: 100%;
`;

const LoginLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export default TSSignUpForm;
