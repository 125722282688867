import { useCallback } from 'react';
import { useRemember } from 'react-remember';
import { handleLogout } from 'store/ducks/user/operations';

import useOperation from './useOperation';

const useLogout = () => {
  const [onLogout] = useOperation(handleLogout);
  const [, remember] = useRemember();

  const logout = useCallback(() => {
    remember({ loginData: {} });
    return onLogout(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    logout,
  };
};

export default useLogout;
