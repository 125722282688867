import React from 'react';
import styled from 'styled-components';
import { useModal } from 'hooks/common';
import SelectColorModal from './SelectColorModal';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const ColorPicker = ({ value, onChange }: Props) => {
  const [showModal, hideModal] = useModal(
    () => <SelectColorModal onClose={hideModal} value={value} handleSubmit={onChange} />,
    [value, onChange],
  );

  return <ColorBlock $color={value} onClick={showModal} />;
};

const ColorBlock = styled.div<{ $color: string }>`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 8px;
  border: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ $color }) => $color};
`;

export default ColorPicker;
