/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled from 'styled-components';
import { FormError } from 'components/UI/form';
import { FormLabel } from 'components/UI/form';
import { useModal } from 'react-modal-hook';
import SelectColorModal from './SelectColorModal';

interface Props {
  disabled?: boolean;
}

const FieldColorsPickerForReceiptMessage = ({
  input,
  label,
  meta,
  style,
  disabled,
}: FieldRenderProps<string[]> & Props) => {
  const { error, submitError, dirtySinceLastSubmit } = meta;
  const isError = (!disabled && error) || (submitError && !dirtySinceLastSubmit);

  const [selectedColorIndex, setSelectedColorIndex] = useState(0);

  const [showModal, hideModal] = useModal(() => {
    return (
      <SelectColorModal
        defaultSelectedColorIndex={selectedColorIndex}
        onClose={hideModal}
        value={input.value}
        handleSubmit={value => {
          input.onBlur();
          input.onChange(value[0]);
        }}
      />
    );
  }, [input.value, selectedColorIndex]);

  return (
    <Field style={style}>
      {label && <FormLabel>{label}</FormLabel>}
      <ColorBlocksWrapper>
        {([input.value] || []).map((item, i) => (
          <ColorBlock
            key={i}
            id={`color-group-picker-for-campaign-color-block-${i}`}
            $color={item ? item : 'black'}
            onClick={() => {
              if (disabled) return;
              setSelectedColorIndex(i);
              showModal();
            }}
          />
        ))}
      </ColorBlocksWrapper>

      {isError && <FormError meta={meta} />}
    </Field>
  );
};

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const ColorBlock = styled.div<{ $color: any }>`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 8px;
  border: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ $color }) => $color};
`;

const ColorBlocksWrapper = styled.div`
  display: flex;
  cursor: pointer;
  width: fit-content;
`;

export default FieldColorsPickerForReceiptMessage;
