import React, { useEffect } from 'react';
import styled from 'styled-components';
import TotalRow from 'components/TotalRow';
import Pagination from 'components/Pagination';
import {
  useDownloadFile,
  useModal,
  useOperation,
  useTableSelect,
  useSortStatus,
} from 'hooks/common';
import { ConfirmModal } from 'components/Modals';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { UIButton } from 'components/UI';
import AudienceTable from './PersonaAnalysisTable';
import {
  fetchAudienceCreate,
  fetchAudienceDelete,
  fetchAudienceList,
} from 'store/ducks/audience/operations';
import { getAudienceList } from 'store/ducks/audience/selectors';
import { AudienceListReq } from 'interfaces/audience';
import AudienceAnalysisRequestModal from './PersonaAnalysisRequestModal';
import {
  fetchDownloadAudienceRawReport,
  fetchDownloadAudienceReport,
} from 'store/ducks/audience/api';
import { UISpinner } from 'components/UI';
import useDownloadFileByOrigin from 'hooks/common/useDownloadFileByOrigin';

const PersonaAnalysisContent = () => {
  const { sortStatus, handleSwitch, setSortStatus } = useSortStatus([], {
    isSort: false,
  });

  const [onFetchAudienceList, , loading] = useOperation(fetchAudienceList);
  const [onFetchAudienceCreate] = useOperation(fetchAudienceCreate);
  const [onFetchAudienceDelete] = useOperation(fetchAudienceDelete);

  const downloadFile = useDownloadFileByOrigin(fetchDownloadAudienceReport);
  const downloadRawFile = useDownloadFile(fetchDownloadAudienceRawReport);

  const { items, meta } = useSelector(getAudienceList);

  const {
    isSelectedAll,
    selectedIds,
    handleSelectAll,
    handleSelectItem,
    handleClearSelected,
  } = useTableSelect({ data: items });

  const handleSearch = (params?: Partial<AudienceListReq>) => {
    const searchParams: AudienceListReq = {
      page: 1,
      orderType: sortStatus.status,
      order: sortStatus.name,
      ...params,
    };
    onFetchAudienceList(searchParams);
  };

  const [showAnalysisRequestModal, hideAnalysisRequestModal] = useModal(
    () => (
      <AudienceAnalysisRequestModal
        handleAccess={params =>
          onFetchAudienceCreate(params).then(() => {
            handleSearch({ page: Number(meta.currentPage) });
            hideAnalysisRequestModal();
          })
        }
        onClose={hideAnalysisRequestModal}
      />
    ),
    [meta.currentPage],
  );

  const [showDeleteModal, hideDeleteModal] = useModal(() => {
    const deleteHandler = () => {
      onFetchAudienceDelete(selectedIds).then(() => {
        handleSearch({ page: Number(meta.currentPage) || 1 });
      });
      handleClearSelected();
      hideDeleteModal();
    };

    return (
      <ConfirmModal
        name={'Delete'}
        textAccessBtn="Delete"
        description={`Are you sure you want to delete this Report?`}
        accessHandler={deleteHandler}
        cancelHandler={hideDeleteModal}
        onClose={hideDeleteModal}
        hideCloseIcon
      />
    );
  }, [selectedIds, meta]);

  useEffect(() => {
    if (!sortStatus?.name) return;
    handleSearch({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  useEffect(() => {
    if (!window.location.search) return handleSearch();
    const urlSearch = (queryString.parse(window.location.search) as unknown) as Partial<
      AudienceListReq
    >;

    urlSearch.order &&
      setSortStatus({ name: urlSearch.order, status: urlSearch.orderType || '' });
    handleSearch(urlSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Spinner />;

  return (
    <Body>
      <TotalRow
        maxWidth="1138px"
        title={`${meta.totalItems} Persona Analysis Request${
          meta.totalItems > 1 ? 's' : ''
        }`}
      >
        <UIButton
          title="Request Persona Analysis"
          modifiers="primary"
          handleClick={showAnalysisRequestModal}
        />
      </TotalRow>
      {items && items.length ? (
        <>
          <PaginationWrapper>
            <UIButton
              style={{ marginRight: '40px' }}
              modifiers={'danger'}
              title="Delete"
              disabled={!selectedIds.length}
              handleClick={showDeleteModal}
            />
            <Pagination
              total={meta.totalItems}
              pageLimit={Number(meta.itemsPerPage)}
              page={Number(meta.currentPage)}
              submitHandler={page => handleSearch({ page })}
            />
          </PaginationWrapper>
          <AudienceTable
            selectedAll={isSelectedAll}
            selectedIds={selectedIds}
            onCheckAll={handleSelectAll}
            onCheckRow={handleSelectItem}
            sortStatus={sortStatus}
            onSort={handleSwitch}
            onDownloadReport={downloadFile}
            onDownloadRawReport={downloadRawFile}
            rows={items}
          />
        </>
      ) : (
        <EmptyContent>
          There are currently no Persona Analysis reports available.
          <br /> Please click Request Persona Analysis to get started.
        </EmptyContent>
      )}
    </Body>
  );
};

const Body = styled.div`
  max-width: 1138px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
`;

const EmptyContent = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  margin-top: 268px;
`;

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 450px);
`;

export default PersonaAnalysisContent;
