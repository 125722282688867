/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Dispatch, SetStateAction, useCallback } from 'react';
import { fetch } from 'services/ApiService';

interface Props {
  setLoading?: Dispatch<React.SetStateAction<boolean>>;
  imgKey?: string;
}

interface SignedUrlResponse {
  result: string;
}

const useFileUrl = ({ setLoading, imgKey }: Props) => {
  const [fileUrl, setFileUrl] = useState<string | undefined>();

  const load = useCallback(async (imgKey: string) => {
    try {
      setLoading?.(true);

      const { result } = await fetch<SignedUrlResponse>('post', 'auth/get_signed_url', {
        payload: { key: imgKey },
      });

      setFileUrl(result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading?.(false);
    }
  }, []);

  useEffect(() => {
    if (!imgKey) return;
    if (/^http/.test(imgKey) || /^data:/.test(imgKey)) {
      setFileUrl(imgKey);
      return;
    }
    load(imgKey);
  }, [imgKey]);

  return {
    fileUrl,
    load,
    setFileUrl,
  };
};

export default useFileUrl;
