import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

const GlobalTooltip = () => <Tooltip type="light" effect="solid" multiline />;

const Tooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1;
  }
`;

export default GlobalTooltip;
