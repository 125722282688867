import React from 'react';
import styled from 'styled-components';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { Form } from 'react-final-form';
import { Field } from 'components/FinalForm';
import { FieldTextInput } from 'components/FormFields';
import UploadFileArea from 'components/UploadFileArea';
import { fetchDownloadCustomProspectingTemplate } from 'store/ducks/prospects/api';
import { UploadCustomProspectingExcelReq } from 'interfaces/prospects';
import Toast from 'services/Toast';
import DownloadTemplate from 'components/DownloadTemplate';

interface Props extends InjectedProps {
  handleAccess: (param: UploadCustomProspectingExcelReq) => void;
}

const CustomProspectingListModal = ({ onClose, handleAccess, ...modalProps }: Props) => {
  useLockBodyScroll();

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 0' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '828px',
        overflow: 'auto',
      }}
    >
      <Title>Custom Prospecting List</Title>
      <Form<UploadCustomProspectingExcelReq>
        onSubmit={values => {
          if (!values.file) {
            Toast.warn('No file selected.');
            return;
          }
          handleAccess(values);
        }}
        render={({ handleSubmit, form, values }) => (
          <FormBody>
            <Field
              label={<div>Prospecting List Name</div>}
              name="name"
              component={FieldTextInput}
            />
            <UploadFileArea
              name="Upload Excel List"
              accept=".xlsx"
              inputId="upload-consumer-list-form-upload"
              desc="You may upload 1 Excel file per time. File size limit is 5 Mb. Only Excel files are allowed."
              onUpload={files => {
                const file = files[0];
                if (!file) return;
                form.change('file', files[0]);
              }}
              onDeleteFile={() => form.change('file', undefined)}
              filelist={
                values.file
                  ? [
                      {
                        label: values.file.name,
                        value: values.file.name,
                      },
                    ]
                  : []
              }
            />
            <DownloadTemplate fetchFile={fetchDownloadCustomProspectingTemplate} />

            <BtnGroup>
              <Btn modifiers="secondary" title="Cancel" handleClick={onClose} />
              <Btn modifiers="primary" title="Submit" handleClick={handleSubmit} />
            </BtnGroup>
          </FormBody>
        )}
      />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 34px;
  text-align: center;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 56px;
`;

const Btn = styled(UIButton)`
  display: block;
  width: 87px;
  font-size: 14px;
  padding: 7px 0;
`;

const FormBody = styled.div`
  margin: 0 92px;
`;

export default CustomProspectingListModal;
