import { Field } from 'components/FinalForm';
import {
  FieldDatePicker,
  FieldTextInput,
  MaskedMoneyTextInput,
} from 'components/FormFields';
import { UIButton, UIModal } from 'components/UI';
import { FormRow } from 'components/UI/form';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { CampaignCreateReq } from 'interfaces/campaign';
import React, { useRef, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import styled from 'styled-components';
import { composeValidators, getDateCutOffTime, setDateGreaterLessDay } from 'utils';

import { FormApi } from 'final-form';
import {
  maxLength250,
  maxLength50,
  required,
  specialCharacters,
  integerValidator,
} from 'utils/validation';
import Toast from 'services/Toast';
import UIAsterisk from 'components/UI/UIAsterisk';
import { useOperation } from 'hooks/common';
import { fetchCampaignCreate } from 'store/ducks/campaign/operations';

interface Props extends InjectedProps {
  handleAccess: () => void;
}

interface FormProps {
  campaignName: string;
  campaignDescription: string;
  startDate: string;
  endDate: string;
  fundraisingGoal: string;
}

const AddNewCampaignModal = ({ onClose, handleAccess, ...modalProps }: Props) => {
  useLockBodyScroll();
  const formRef = useRef<FormApi | undefined>(undefined);

  const [onFetchCampaignCreate] = useOperation(fetchCampaignCreate);

  const [loading, setLoading] = useState(false);

  const addCampaignSubmit = (values: CampaignCreateReq) => {
    if (!values.campaignName || !values.startDate || !values.endDate) {
      Toast.warn('Please enter required information.');
      return;
    }
    setLoading(true);
    values.campaignDescription = values.campaignDescription || '';
    values.fundraisingGoal = values.fundraisingGoal || '';
    onFetchCampaignCreate({ ...values }).then((e: any) => {
      if (e?.payload?.status !== 200) {
        setLoading(false);
        if (e?.payload) {
          Toast.error(e?.payload?.msg);
        }
      }
      if (e?.payload?.status === 200) {
        Toast.success('Your form is saved successfully');
      }
      if (e?.payload) {
        setLoading(false);
        handleAccess();
      }
    });
  };

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 0' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '836px',
        overflow: 'unset',
      }}
    >
      <Title>Add New Campaign</Title>
      <Form
        onSubmit={(values: FormProps) => {
          addCampaignSubmit(values);
        }}
        render={({ handleSubmit, form, values }) => (
          <FormBody>
            <Field
              name="campaignName"
              label={
                <>
                  Campaign Name
                  <UIAsterisk />
                </>
              }
              component={FieldTextInput}
              validate={composeValidators<string>(
                required,
                maxLength50,
                specialCharacters,
              )}
            />
            <Field
              name="campaignDescription"
              label="Campaign Description"
              component={FieldTextInput}
              validate={maxLength250}
            />
            <FormRow>
              <Field
                name="startDate"
                label={
                  <>
                    Start Date
                    <UIAsterisk />
                  </>
                }
                parse={v => getDateCutOffTime(v)}
                component={FieldDatePicker}
                popperPlacement="top"
                dateFormat="MMM/dd/yyyy"
                maxDate={setDateGreaterLessDay(values.endDate, 'less')}
                validate={required}
              />
              <Field
                name="endDate"
                label={
                  <>
                    End Date
                    <UIAsterisk />
                  </>
                }
                parse={v => getDateCutOffTime(v)}
                component={FieldDatePicker}
                popperPlacement="top"
                dateFormat="MMM/dd/yyyy"
                minDate={setDateGreaterLessDay(values.startDate, 'greater')}
                validate={required}
              />
            </FormRow>
            <DollarInputWrapper>
              <Field
                name="fundraisingGoal"
                label="Fundraising Goal"
                component={MaskedMoneyTextInput}
                hidePrefix
                validate={integerValidator}
              />
              <DollarSubfix>$</DollarSubfix>
            </DollarInputWrapper>
            <FormSpy subscription={{}}>
              {({ form }) => {
                formRef.current = form;
                return null;
              }}
            </FormSpy>

            <BtnGroup>
              <Btn modifiers="secondary" title="Cancel" handleClick={onClose} />
              <Btn
                modifiers="primary"
                title="Save"
                isLoading={loading}
                handleClick={handleSubmit}
              />
            </BtnGroup>
          </FormBody>
        )}
      />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 46px;
  text-align: center;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Btn = styled(UIButton)`
  display: block;
  width: 87px;
  font-size: 14px;
  padding: 7px 0;
`;

const FormBody = styled.div`
  margin: 0 32px;
`;

const DollarInputWrapper = styled.div`
  position: relative;
`;

const DollarSubfix = styled.div`
  position: absolute;
  right: 12px;
  top: 34px;
`;

export default AddNewCampaignModal;
