/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { useOperation } from 'hooks/common';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import Jumbotron from 'components/Jumbotron';
import { sortTabs } from 'constants/search';
import Pagination from 'components/Pagination';
import List from 'components/List';
import TabsNavigation from 'components/NavigationTabs';
import EmptyCompany from 'components/EmptyCompany';
import { StateProps } from 'store/interfaces';
import { Prospect } from 'interfaces/prospects';
import { PaginationMeta } from 'interfaces/common';
import { fetchSavedListsTeams } from 'store/ducks/prospectsTeams/operations';
import {
  getSavedListsTeams,
  getSavedListsTeamsMeta,
} from 'store/ducks/prospectsTeams/selectors';
import CardProspectTeam from 'components/CardProspectTeam';
import { PAGE_WIDTH } from 'constants/common';

const Prospects = () => {
  const { location } = useHistory();
  const sortString = queryString.parse(location.search)?.sort?.toString();
  const [onfetchSavedLists, isLoading] = useOperation(fetchSavedListsTeams);
  const [sort, setSort] = useState<string>(sortString || 'alphabet');
  const [type, setType] = useState('Prospects');
  const [backPath, setBackPath] = useState('/dashboard');

  const savedLists = useSelector<StateProps, Prospect[]>(getSavedListsTeams);
  const meta = useSelector<StateProps, PaginationMeta>(getSavedListsTeamsMeta);
  const { currentPage, itemsPerPage, totalItems } = meta;

  const getInitialState = () => {
    const sortString = queryString.parse(location.search)?.sort;
    const pageString = queryString.parse(location.search)?.page;
    const typeString = queryString.parse(location.search)?.type;
    if (typeString) {
      if (typeString === 'vettingsessions') {
        setType('Vetting Sessions');
      }
      if (typeString === 'viewsavedlist') {
        setType('Vetting Sessions');
        setBackPath('/search');
      }
      if (typeString === 'Vetting Sessions') {
        setType('Vetting Sessions');
        setBackPath('/search');
      }
      if (typeString === 'Prospects') {
        setType('Vetting Sessions');
      }
    }
    if (sortString) {
      onfetchSavedLists({ page: Number(pageString) || 1, sort: sortString });
      return setSort(sort);
    } else {
      return onfetchSavedLists({ page: Number(pageString) || 1, sort });
    }
  };

  useEffect(() => {
    getInitialState();
  }, [sortString]);

  return (
    <ContentBlockWrapper
      header={<Jumbotron name={type} back backPath={backPath} />}
      loading={isLoading}
    >
      <Header>
        <TabsNavigation
          queryKey="sort"
          list={sortTabs}
          name="Sort by"
          activeTab={sort}
          path={backPath}
        />
        {totalItems > 0 && (
          <Pagination
            total={totalItems}
            pageLimit={Number(itemsPerPage)}
            page={Number(currentPage)}
            submitHandler={(page: number) => onfetchSavedLists({ page, sort })}
          />
        )}
      </Header>

      {savedLists.length > 0 ? (
        <List<Prospect, object> list={savedLists} component={CardProspectTeam} />
      ) : (
        <Empty title="lists" />
      )}
    </ContentBlockWrapper>
  );
};

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 23px;
  max-width: ${PAGE_WIDTH.NORMAL}px;
`;

const Empty = styled(EmptyCompany)`
  height: calc(100vh - 280px);
`;

export default Prospects;
