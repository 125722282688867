import CardCompany from 'components/CardCompany';
import { useOperation } from 'hooks/common';
import { SearchCompany } from 'interfaces/companies';
import { CardCompanyRankText } from 'interfaces/search';
import React from 'react';
import { fetchCompanyDisLike, fetchCompanyLike } from 'store/ducks/companies/operations';

interface Props {
  companies: SearchCompany[];
  rankText: CardCompanyRankText | undefined;
  isSelectedAllCompanies: boolean;
  selectedCompaniesId: string[];
  unselectedCompaniesId: string[];
  setIsSelectedAllCompanies: (value: boolean) => void;
  setUnselectedCompaniesId: (value: string[]) => void;
  setSelectedCompaniesId: (value: string[]) => void;
}

const CompanySearchResult = ({
  companies,
  rankText,
  isSelectedAllCompanies,
  selectedCompaniesId,
  unselectedCompaniesId,
  setIsSelectedAllCompanies,
  setUnselectedCompaniesId,
  setSelectedCompaniesId,
}: Props) => {
  const [onfetchCompanyDisLike] = useOperation(fetchCompanyDisLike);
  const [onfetchCompanyLike] = useOperation(fetchCompanyLike);

  const onDisLike = (id: string) => {
    onfetchCompanyDisLike(id);
  };

  const onLike = (id: string) => {
    onfetchCompanyLike(id);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        margin: '-12px -12px 0',
      }}
    >
      {companies.map(item => (
        <CardCompany
          key={item.id}
          {...item}
          setDisLikeHandler={onDisLike}
          setLikeHandler={onLike}
          showCheckbox={true}
          rankText={rankText}
          check={
            isSelectedAllCompanies ||
            (selectedCompaniesId.length
              ? selectedCompaniesId.includes(item.id)
              : unselectedCompaniesId.length
              ? !unselectedCompaniesId.includes(item.id)
              : false)
          }
          setSelectedCompany={id => {
            if (isSelectedAllCompanies) {
              setIsSelectedAllCompanies(false);
              setUnselectedCompaniesId([id]);
              return;
            }
            if (unselectedCompaniesId.length) {
              if (unselectedCompaniesId.includes(id)) {
                setUnselectedCompaniesId(
                  unselectedCompaniesId.filter(id => id !== item.id),
                );
              } else {
                setUnselectedCompaniesId([...unselectedCompaniesId, item.id]);
              }
              return;
            }
            if (selectedCompaniesId.includes(id)) {
              setSelectedCompaniesId(selectedCompaniesId.filter(id => id !== item.id));
            } else {
              setSelectedCompaniesId([...selectedCompaniesId, item.id]);
            }
          }}
        />
      ))}
    </div>
  );
};

export default CompanySearchResult;
