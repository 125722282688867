import { Batch, Prospect, SavedListBatchTeamRes, SavedLists } from 'interfaces/prospects';
import { SavedListRequestProps, SavedListCreateRequestByTeam } from 'interfaces/common';
import { fetch } from 'services/ApiService';
import { paramsToQueryString } from 'utils';
import { SuggestGameplansRes } from 'interfaces/gameplan';

export const fetchSavedListsTeams = ({ page, sort, limit = 12 }: SavedListRequestProps) =>
  fetch<SavedLists>(
    'get',
    `saved-list-team${paramsToQueryString({
      page,
      limit,
      sort,
    })}`,
  );

export const fetchSavedListTeams = (id: string) =>
  fetch<Prospect>('get', `saved-list-team/${id}`);

export const fetchSavedListTeamsExcel = (id: string) =>
  fetch<{ name: string; file: string }>('get', `saved-list-team/${id}/excel`);

export const fetchSavedListTeamsCreate = (data: SavedListCreateRequestByTeam) =>
  fetch<Prospect>('post', `saved-list-team`, {
    payload: data,
  });

export const fetchSavedListTeamsUpdate = (id: string, data: Prospect) =>
  fetch<Prospect>('patch', `saved-list-team/${id}`, {
    payload: data,
  });

export const fetchSavedListTeamsDestroy = (id: string) =>
  fetch<string>('delete', `saved-list-team/${id}`);

export const fetchSuggestSavedlistTeams = (name: string) =>
  fetch<SuggestGameplansRes>(
    'get',
    `saved-list-team/findSavedListTeam${paramsToQueryString({
      q: name,
      page: 1,
      limit: 12,
    })}`,
  );

export const fetchBatch = (name: string): Promise<Batch> =>
  Promise.resolve([{ id: 'qwer', organizationName: name }]);
// fetch<Batch>(
//   'get',
//   `campaign/getCampaignLink/isLink${paramsToQueryString({ campaignIds: name })}`,
// );

export const fetchSavedListsBatch = ({ page, sort, limit = 12 }: SavedListRequestProps) =>
  fetch<SavedListBatchTeamRes>(
    'get',
    `saved-list-team${paramsToQueryString({
      page,
      limit,
      sort,
    })}&type=batch-analysis`,
  );
