import React, { useEffect } from 'react';
import ShowMoreText from 'react-show-more-text';
import { ReactSVG } from 'react-svg';
import { goalAlignmentOptions } from 'constants/goalAlignment';
import { moneyFormatter } from 'utils/formatters';
import { LikeButton, UIDivider } from 'components/UI';
import CompanySideBar from '../../pages/organizationUser/companies/Company/CompanySideBar';
import RightSVG from 'assets/images/icons/right.svg';

import {
  Root,
  Header,
  HeaderInfo,
  CompanyTitle,
  CompanyTitleWrapper,
  CompanyType,
  Body,
  Content,
  SectionTitle,
  SectionSubTitle,
  Description,
  ContactsWrapper,
  ContactsItem,
  ContactsLink,
  ContactsIcon,
  ReportedWrapper,
  ReportedItem,
  ReportedRow,
  ReportedTitle,
  ReportedValue,
  SectionRow,
  ContactMoreLink,
  DemographicsDesc,
  ImpactSection,
  CharitablePartners,
  AlignmentList,
  AlignmentItem,
  ImpactRow,
  ImpactRowInner,
  ImpactValue,
  ContactsInner,
  DescriptionWrapper,
  ImageEmpty,
  SectionTitleWrapper,
  EmptyAlignment,
  LoadImage,
  LinkGroup,
  RightActions,
  ShortButton,
  ShortButtonText,
  EditBtn,
  TickerText,
  UpdateProfileButton,
  EnrichUpdateDate,
  TooltipWrapper,
} from '../../pages/organizationUser/companies/Company/styled';
import {
  SearchCompany,
  Scoops,
  News,
  TypesOfInvestment,
  ResCompanyMatch,
} from 'interfaces/companies';
import CompaniesTabs from '../../pages/organizationUser/companies/Company/CompaniesTabs';
import CompanyContactsTable from '../../pages/organizationUser/companies/Company/CompanyContactsTable';
import PrimaryInformation from '../../pages/organizationUser/companies/Company/PrimaryInformation';
import SecondaryInformation from '../../pages/organizationUser/companies/Company/SecondaryInformation';
import SocialMediaLinks from '../../pages/organizationUser/companies/Company/SocialMediaLinks';
import { useIsCompanyUser, useModal, useOperation } from 'hooks/common';
import { parseLink } from 'utils';
import { CRSFocus } from 'store/interfaces';

import PhoneIcon from 'assets/images/icons/phone.svg';
import MapPinIcon from 'assets/images/icons/map-pin.svg';
import WebSiteIcon from 'assets/images/icons/website.svg';
import EmptyCompany from 'assets/images/icons/empty-company.svg';
import BlockModal from 'components/Modals/BlockModal';
import CompanyBlockButton from '../../pages/organizationUser/companies/Company/CompanyBlockButton';
import {
  fetchCompanyBlock,
  fetchCompanyDisMeeting,
  fetchCompanyDisPartnership,
  fetchCompanyMeeting,
  fetchCompanyPartnership,
  fetchCompanyUnlock,
  fetchUpdateEnrichSingle,
} from 'store/ducks/companies/operations';
import { CustomizeTemplateGetReq } from 'interfaces/gameplan';
import { GamePlanEmailTemplateTypeEnum } from 'constants/gamePlan';
import { useHistory } from 'react-router';
import { selectUser } from 'store/ducks/user/selectors';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { format } from 'date-fns';
import useJumpToSupport from 'hooks/common/useJumpToSupport';

export interface CompanyViewProps {
  company: SearchCompany;
  news: News[];
  scoops?: Scoops[];
  matchs?: ResCompanyMatch['matchItems'];
  organizationName?: string;
  handleLike?: (id: string) => void;
  handleDisLike?: (id: string) => void;
  showCustomizeTemplateModal?: () => void;
  onFetchGameplanCustomizeTemplate?: (values: CustomizeTemplateGetReq) => Promise<void>;
  onFetchCompany?: (id: string) => Promise<void>;
  onfetchCompanyNews?: (id: string) => void;
}

const CompanyView = ({
  company,
  handleLike,
  handleDisLike,
  scoops,
  news,
  matchs,
  organizationName,
  showCustomizeTemplateModal,
  onFetchGameplanCustomizeTemplate,
  onFetchCompany,
  onfetchCompanyNews,
}: CompanyViewProps) => {
  const {
    id,
    logo,
    name,
    descriptionList,
    productsBrandDescription,
    cashContributions,
    employeeContributions,
    annualContributions,
    inKindContributions,
    website,
    phone,
    street,
    city,
    state,
    country,
    zipCode,
    ticker,
    contacts,
    like,
    revenue,
    industries,
    sdgGoals,
    genders,
    income,
    age,
    ethnicity,
    affiliation,
    nonprofit,
    purchase,
    brands,
    interests,
    competitors,
    employeeCount,
    parentName,
    crsFocus,
    typesOfInvestment,
    charitablePartners,
    socialMediaUrls,
    block,
    isMeeting,
    isPartnership,
    seekingOpportunities,
    impactGoals,
    corporateFoundation,
    foundationName,
    foundationDescription,
    enrichUpdateDate,
    canUpdateEnrich,
  } = company;

  const [onFetchBlock] = useOperation(fetchCompanyBlock);
  const [onFetchUnlock] = useOperation(fetchCompanyUnlock);
  const [onFetchMeeting] = useOperation(fetchCompanyMeeting);
  const [onFetchDisMeeting] = useOperation(fetchCompanyDisMeeting);
  const [onFetchCompanyPartnership] = useOperation(fetchCompanyPartnership);
  const [onFetchCompanyDisPartnership] = useOperation(fetchCompanyDisPartnership);
  const [onFetchUpdateEnrichSingle, , updateCompanyProfileLoading] = useOperation(
    fetchUpdateEnrichSingle,
  );

  const user = useSelector(selectUser);
  const isCompanyUser = useIsCompanyUser(user);

  const { push } = useHistory();

  const onSetFavorite = () => {
    if (like) {
      return handleDisLike?.(id);
    }
    return handleLike?.(id);
  };

  const { jumpToSupport } = useJumpToSupport();
  const [showBlockModal, hideBlockModal] = useModal(() => {
    return (
      <BlockModal
        onClose={hideBlockModal}
        name={name}
        handleSubmit={() => {
          hideBlockModal();
          onFetchBlock(id);
        }}
      />
    );
  });

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const linkWebSite = parseLink(website);
  // const linkPartnership = partnershipLink && parseLink(partnershipLink);

  return (
    <Root>
      <Header>
        <HeaderInfo>
          {logo ? (
            <LoadImage imgKey={logo} />
          ) : (
            <ImageEmpty>
              <ReactSVG src={EmptyCompany} />
            </ImageEmpty>
          )}

          <div>
            <CompanyTitleWrapper>
              <CompanyTitle>{name}</CompanyTitle>
              {!isCompanyUser && (
                <LikeButton
                  isActive={like}
                  withBorder={false}
                  setLikeHandler={onSetFavorite}
                />
              )}
              {socialMediaUrls && socialMediaUrls.length > 0 && (
                <SocialMediaLinks urls={socialMediaUrls} />
              )}
            </CompanyTitleWrapper>

            <CompanyType>
              {industries && industries.length > 0
                ? industries.join(', ')
                : 'No information'}
            </CompanyType>
            {ticker && <TickerText data-tip="Company Ticker">{ticker}</TickerText>}
          </div>
        </HeaderInfo>
        <RightActions>
          {!isCompanyUser && (
            <div>
              <ShortButton
                title={
                  <>
                    <ReactSVG src={RightSVG} />
                    <ShortButtonText>Partnership</ShortButtonText>
                  </>
                }
                modifiers={isPartnership ? 'primary' : 'secondary'}
                handleClick={() =>
                  isPartnership
                    ? onFetchCompanyDisPartnership(company.id)
                    : onFetchCompanyPartnership(company.id)
                }
              />
              <ShortButton
                title={
                  <>
                    <ReactSVG src={RightSVG} />
                    <ShortButtonText>Meeting</ShortButtonText>
                  </>
                }
                modifiers={isMeeting ? 'primary' : 'secondary'}
                handleClick={() =>
                  isMeeting ? onFetchDisMeeting(company.id) : onFetchMeeting(company.id)
                }
              />
            </div>
          )}
          {isCompanyUser && (
            <EditBtn
              handleClick={() => push(`/profile/edit`)}
              modifiers="secondary"
              title="Edit"
            />
          )}
          {!isCompanyUser && (
            <CompanyBlockButton
              block={block}
              handleClick={() => {
                if (block) {
                  onFetchUnlock(id);
                  return;
                }
                showBlockModal();
              }}
            />
          )}
        </RightActions>
      </Header>

      <Body>
        <Content>
          <ContactsWrapper>
            <ContactsInner>
              <ContactsItem>
                <ContactsIcon src={WebSiteIcon} />
                {website ? (
                  <ContactsLink href={linkWebSite} target="_blank">
                    {website}
                  </ContactsLink>
                ) : (
                  <p>No website</p>
                )}
              </ContactsItem>

              <ContactsItem>
                <ContactsIcon src={PhoneIcon} />

                {phone ? (
                  <ContactsLink href={`tel:${phone}`}>{phone}</ContactsLink>
                ) : (
                  <p>No phone number</p>
                )}
              </ContactsItem>
            </ContactsInner>

            <ContactsItem>
              <ContactsIcon src={MapPinIcon} />
              <p>
                {street && `${street} `}
                {city && `${city} `}
                {state && `${state} `}
                {country && `${country} `}
                {zipCode && `${zipCode}`}
                {!street && !city && !state && !country && !zipCode && 'No Address'}
              </p>
            </ContactsItem>
          </ContactsWrapper>

          {descriptionList && descriptionList.length > 0 && (
            <>
              <SectionSubTitle>Business Description</SectionSubTitle>

              <DescriptionWrapper>
                <ShowMoreText
                  lines={3}
                  more="see more"
                  less="see less"
                  className="descriptionSeeMore"
                  anchorClass="my-anchor-css-class"
                  expanded={false}
                >
                  {typeof descriptionList === 'string' ? (
                    <Description>{descriptionList}</Description>
                  ) : (
                    descriptionList.map((item, index) => (
                      <Description key={index}>{item.description}</Description>
                    ))
                  )}
                </ShowMoreText>
              </DescriptionWrapper>
            </>
          )}
          {productsBrandDescription && (
            <>
              <SectionSubTitle>Products & Brand Descriptions</SectionSubTitle>
              <DescriptionWrapper>
                <ShowMoreText
                  lines={3}
                  more="see more"
                  less="see less"
                  className="descriptionSeeMore"
                  anchorClass="my-anchor-css-class"
                  expanded={false}
                >
                  <Description>{productsBrandDescription}</Description>
                </ShowMoreText>
              </DescriptionWrapper>
            </>
          )}

          {((competitors && competitors.length > 0) || parentName) && (
            <CompaniesTabs competitors={competitors} parentName={parentName} />
          )}

          {(revenue || employeeCount) && (
            <ReportedWrapper>
              <ReportedRow>
                <ReportedItem>
                  <ReportedTitle>Revenue Reported</ReportedTitle>
                  <ReportedValue>
                    {revenue
                      ? Number(revenue) === 1000000000
                        ? moneyFormatter.from(Number(1000000000), { symbol: '$ ' }) + '+'
                        : moneyFormatter.from(Number(revenue), { symbol: '$ ' })
                      : '-'}
                  </ReportedValue>
                </ReportedItem>

                <ReportedItem>
                  <ReportedTitle>Employees Reported</ReportedTitle>
                  <ReportedValue>
                    {employeeCount
                      ? Number(employeeCount) === 10000
                        ? moneyFormatter.from(Number(10000), { symbol: '' }) + '+'
                        : moneyFormatter.from(Number(employeeCount), { symbol: '' })
                      : '-'}
                  </ReportedValue>
                </ReportedItem>
              </ReportedRow>
            </ReportedWrapper>
          )}

          {!isCompanyUser && contacts && contacts.length > 0 && (
            <>
              <SectionRow>
                <SectionSubTitle>Contacts</SectionSubTitle>
                <LinkGroup>
                  <ContactMoreLink
                    as="a"
                    onClick={() => {
                      onFetchGameplanCustomizeTemplate?.({
                        templateType: GamePlanEmailTemplateTypeEnum.PitchTemplate,
                      });
                      showCustomizeTemplateModal?.();
                    }}
                  >
                    customize template
                  </ContactMoreLink>
                  <UIDivider type="vertical" height={16} />
                  <ContactMoreLink to={`/company-contacts/${id}`}>
                    see more
                  </ContactMoreLink>
                </LinkGroup>
                {/* {isCompanyUser && (
                <LinkGroup>
                  <IconWrapper>
                    <ReactSVG
                      src={AddIcon}
                      onClick={() => push(`/profile/contacts/edit`)}
                    />
                  </IconWrapper>
                  <UIDivider type="vertical" height={16} />
                  {contacts && contacts.length > 0 && (
                    <ContactMoreLink to={`/profile/contacts`}>see more</ContactMoreLink>
                  )}
                </LinkGroup>
              )} */}
              </SectionRow>

              <CompanyContactsTable rows={contacts ? [...contacts].slice(0, 3) : []} />
            </>
          )}

          {corporateFoundation && (
            <>
              <SectionTitleWrapper>
                <SectionTitle>Corporate Foundation</SectionTitle>
              </SectionTitleWrapper>
              <SectionSubTitle>Corporate Foundation</SectionSubTitle>
              <DescriptionWrapper>{foundationName || 'N/A'}</DescriptionWrapper>
              <SectionSubTitle>Description</SectionSubTitle>
              <DescriptionWrapper>
                <Description>{foundationDescription}</Description>
              </DescriptionWrapper>
            </>
          )}

          <SectionTitleWrapper>
            <SectionTitle>
              Social Impact
              <TooltipWrapper>
                Companies are eligible for Social Impact enrichment based on the
                availability of data and the date of the last update. When a company is
                eligible for enrichment, a button to enrich the profile will be displayed.{' '}
              </TooltipWrapper>
            </SectionTitle>
            {canUpdateEnrich ? (
              <UpdateProfileButton
                modifiers="third"
                propsCss={{ fontSize: '14px', padding: '0px 10px' }}
                handleClick={() =>
                  onFetchUpdateEnrichSingle(company.id).then(() => {
                    onFetchCompany?.(id);
                    onfetchCompanyNews?.(id);
                  })
                }
                isLoading={updateCompanyProfileLoading}
                title="Update Social Impact"
              />
            ) : (
              enrichUpdateDate && (
                <EnrichUpdateDate>
                  Updated on {format(new Date(enrichUpdateDate), 'MMM dd, yyyy')}
                </EnrichUpdateDate>
              )
            )}
          </SectionTitleWrapper>
          <ImpactRow>
            {typesOfInvestment && typesOfInvestment.length > 0 && (
              <ImpactRowInner>
                <SectionSubTitle>Type of Investment</SectionSubTitle>
                <ImpactSection>
                  {typesOfInvestment && typesOfInvestment.length > 0 ? (
                    typesOfInvestment.map(({ id, title }: TypesOfInvestment) => (
                      <ImpactValue key={id}>{title}</ImpactValue>
                    ))
                  ) : (
                    <ImpactValue>No Information</ImpactValue>
                  )}
                </ImpactSection>
              </ImpactRowInner>
            )}
            {crsFocus && crsFocus.length > 0 && (
              <ImpactRowInner>
                <SectionSubTitle>CSR Focus</SectionSubTitle>

                <ImpactSection>
                  {crsFocus && crsFocus.length > 0 ? (
                    crsFocus.map(({ id, name }: CRSFocus) => (
                      <ImpactValue key={id}>{name}</ImpactValue>
                    ))
                  ) : (
                    <ImpactValue>No Information</ImpactValue>
                  )}
                </ImpactSection>
              </ImpactRowInner>
            )}
          </ImpactRow>

          {seekingOpportunities && seekingOpportunities.length > 0 && (
            <>
              <SectionSubTitle>Seeking Opportunities</SectionSubTitle>

              <DescriptionWrapper>
                <ShowMoreText
                  lines={3}
                  more="see more"
                  less="see less"
                  className="descriptionSeeMore"
                  anchorClass="my-anchor-css-class"
                  expanded={false}
                >
                  <Description>{seekingOpportunities[0].text}</Description>
                </ShowMoreText>
              </DescriptionWrapper>
            </>
          )}

          {impactGoals && impactGoals.length > 0 && (
            <>
              <SectionSubTitle>Impact Goals</SectionSubTitle>

              <DescriptionWrapper>
                <ShowMoreText
                  lines={3}
                  more="see more"
                  less="see less"
                  className="descriptionSeeMore"
                  anchorClass="my-anchor-css-class"
                  expanded={false}
                >
                  <Description>{impactGoals[0].text}</Description>
                </ShowMoreText>
              </DescriptionWrapper>
            </>
          )}

          {sdgGoals && sdgGoals.length > 0 && (
            <>
              <SectionTitleWrapper>
                <SectionTitle>SDG Goal Alignment</SectionTitle>
              </SectionTitleWrapper>
              {sdgGoals && sdgGoals.length > 0 ? (
                <AlignmentList>
                  {[...sdgGoals]
                    .map(item => goalAlignmentOptions[item])
                    .filter(item => item)
                    .sort((a, b) => a.id - b.id)
                    .map(item => (
                      <AlignmentItem key={item.id} src={item.img} alt={item.title} />
                    ))}
                </AlignmentList>
              ) : (
                <EmptyAlignment>No selected goal</EmptyAlignment>
              )}
            </>
          )}

          {(cashContributions ||
            employeeContributions ||
            annualContributions ||
            inKindContributions) && (
            <>
              <SectionSubTitle>Contributions</SectionSubTitle>
              <ReportedWrapper>
                <ReportedRow>
                  <ReportedItem>
                    <ReportedTitle>Cash Contributions</ReportedTitle>
                    <ReportedValue>
                      {cashContributions
                        ? moneyFormatter.from(Number(cashContributions), { symbol: '$ ' })
                        : '-'}
                    </ReportedValue>
                  </ReportedItem>

                  <ReportedItem>
                    <ReportedTitle>Employee Contributions</ReportedTitle>
                    <ReportedValue>
                      {employeeContributions
                        ? moneyFormatter.from(Number(employeeContributions), {
                            symbol: '$ ',
                          })
                        : '-'}
                    </ReportedValue>
                  </ReportedItem>
                </ReportedRow>
                <ReportedRow>
                  <ReportedItem>
                    <ReportedTitle>Total Social Contributions</ReportedTitle>
                    <ReportedValue>
                      {annualContributions
                        ? moneyFormatter.from(Number(annualContributions), {
                            symbol: '$ ',
                          })
                        : '-'}
                    </ReportedValue>
                  </ReportedItem>

                  <ReportedItem>
                    <ReportedTitle>In-Kind Contributions</ReportedTitle>
                    <ReportedValue>
                      {inKindContributions
                        ? moneyFormatter.from(Number(inKindContributions), {
                            symbol: '$ ',
                          })
                        : '-'}
                    </ReportedValue>
                  </ReportedItem>
                </ReportedRow>
              </ReportedWrapper>
            </>
          )}

          {charitablePartners && charitablePartners.length > 0 && (
            <>
              <SectionSubTitle>Charitable partners</SectionSubTitle>
              <CharitablePartners>
                {charitablePartners.map((partner, index) => (
                  <ImpactValue key={index}>{partner}</ImpactValue>
                ))}
              </CharitablePartners>
            </>
          )}
          {/* <SectionSubTitle>Charitable Focus & Programs</SectionSubTitle>
          {linkPartnership ? (
            <PartnershipLink
              href={linkPartnership}
              target="_blank"
            >{`Go to the company's website`}</PartnershipLink>
          ) : (
            <EmployeeValue>No Information</EmployeeValue>
          )}

          <SectionTitle>GRI Compliant</SectionTitle>
          <EmployeeValue>{employeeEngagementOpportunities ? 'Yes' : 'No'}</EmployeeValue> */}

          <SectionTitleWrapper>
            <SectionTitle>Customer Demographics</SectionTitle>
            {!isCompanyUser && (
              <UpdateProfileButton
                modifiers="third"
                propsCss={{ fontSize: '14px', padding: '0px 10px' }}
                handleClick={jumpToSupport}
                title="Request Profile Update"
              />
            )}
          </SectionTitleWrapper>
          {(genders ||
            income ||
            age ||
            ethnicity ||
            affiliation ||
            nonprofit ||
            purchase ||
            brands ||
            interests) && (
            <>
              <DemographicsDesc>
                Consumer data is representative of this brand’s consumers that follow them
                on social media. The rankings and lists represent the highest indexing (or
                most above average) attributes and preferences of this dataset.
              </DemographicsDesc>

              {(genders || income || age || ethnicity) && (
                <>
                  <SectionSubTitle>Primary Information</SectionSubTitle>
                  <PrimaryInformation
                    genders={genders}
                    income={income}
                    age={age}
                    ethnicity={ethnicity}
                  />
                </>
              )}

              {(affiliation || nonprofit || purchase || brands || interests) && (
                <>
                  <SectionSubTitle>Secondary Information</SectionSubTitle>
                  <SecondaryInformation
                    affiliation={affiliation}
                    nonprofit={nonprofit}
                    purchase={purchase}
                    brands={brands}
                    interests={interests}
                  />
                </>
              )}
            </>
          )}
        </Content>

        <CompanySideBar
          scoops={scoops}
          news={news}
          matchs={matchs}
          organizationName={organizationName}
        />
      </Body>
    </Root>
  );
};

export default CompanyView;
