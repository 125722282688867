import React, { useState } from 'react';
import styled from 'styled-components';

import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import Jumbotron from 'components/Jumbotron';
import { RadioButtonsLine } from 'components/UI';
import MembershipTable from 'components/MembershipTable';
import {
  membershipRatesOptions,
  membershipRows,
  membershipHeadTable,
} from 'constants/membership';
import { SelectOption } from 'components/UI/interfaces';
import { PAGE_WIDTH } from 'constants/common';

const Membership = () => {
  const [rate, setRate] = useState<SelectOption['value'] | undefined>('20');

  return (
    <ContentBlockWrapper header={<Jumbotron name="Upgrade Membership" />}>
      <Content>
        <Header>
          <HeaderTitle>
            Select the appropriate amount of revenue and we will select the relevant rates
            for you
          </HeaderTitle>
          <RadioButtonsLine
            options={membershipRatesOptions}
            value={rate}
            onChange={val => setRate(val)}
          />
        </Header>
        <MembershipTable rows={membershipRows} headCells={membershipHeadTable} />
      </Content>
    </ContentBlockWrapper>
  );
};

const Header = styled.div`
  margin-bottom: 34px;
  & ul {
    background: #fff;
    padding: 2px;
  }
`;

const HeaderTitle = styled.p`
  font-size: 12px;
  line-height: 150%;
  margin-bottom: 8px;
`;

const Content = styled.div`
  width: 100%;
  max-width: ${PAGE_WIDTH.NORMAL}px;
`;

export default Membership;
