/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { useOperation } from 'hooks/common';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import Jumbotron from 'components/Jumbotron';
import { sortTabs } from 'constants/search';
import Pagination from 'components/Pagination';
import List from 'components/List';
import TabsNavigation from 'components/NavigationTabs';
import EmptyCompany from 'components/EmptyCompany';
import { Prospect } from 'interfaces/prospects';
import { fetchSavedListsBatch } from 'store/ducks/prospectsTeams/operations';
import { getBatchTeam } from 'store/ducks/prospectsTeams/selectors';
import { PAGE_WIDTH } from 'constants/common';
import CardBatchTeam from 'components/CardBatchTeam';

const Prospects = () => {
  const { location } = useHistory();
  const sortString = queryString.parse(location.search)?.sort?.toString();
  const [onfetchSavedLists, isLoading] = useOperation(fetchSavedListsBatch);
  const [sort, setSort] = useState<string>(sortString || 'alphabet');

  const savedLists = useSelector(getBatchTeam);
  const { currentPage, itemsPerPage, totalItems } = savedLists.meta;

  const getInitialState = () => {
    const sortString = queryString.parse(location.search)?.sort;
    const pageString = queryString.parse(location.search)?.page;
    if (sortString) {
      onfetchSavedLists({ page: Number(pageString) || 1, sort: sortString });
      return setSort(sort);
    } else {
      return onfetchSavedLists({ page: Number(pageString) || 1, sort });
    }
  };

  useEffect(() => {
    getInitialState();
  }, [sortString]);

  return (
    <ContentBlockWrapper
      header={<Jumbotron name="Batch Analysis" back backPath={`/dashboard`} />}
      loading={isLoading}
    >
      <Header>
        <TabsNavigation queryKey="sort" list={sortTabs} name="Sort by" activeTab={sort} />
        {totalItems > 0 && (
          <Pagination
            total={totalItems}
            pageLimit={Number(itemsPerPage)}
            page={Number(currentPage)}
            submitHandler={(page: number) => onfetchSavedLists({ page, sort })}
          />
        )}
      </Header>

      {savedLists.items.length > 0 ? (
        <List<Prospect, object> list={savedLists.items} component={CardBatchTeam} />
      ) : (
        <Empty title="lists" />
      )}
    </ContentBlockWrapper>
  );
};

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 23px;
  max-width: ${PAGE_WIDTH.NORMAL}px;
`;

const Empty = styled(EmptyCompany)`
  height: calc(100vh - 280px);
`;

export default Prospects;
