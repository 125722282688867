/* eslint-disable react-hooks/exhaustive-deps */
import Jumbotron from 'components/Jumbotron';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import HandshakeSVG from 'assets/images/valuationReport/handshake.svg';
import ValuationSVG from 'assets/images/valuationReport/valuation.svg';
import AssetSVG from 'assets/images/valuationReport/asset.svg';
import ArrowDownHandleSVG from 'assets/images/icons/arrow-down-handle.svg';
import ChatSVG from 'assets/images/icons/chat.svg';
import Download16SVG from 'assets/images/icons/download16.svg';
import {
  ProgressBar,
  ProgressBarStep,
  UIDivider,
  UILoadAvatarBase64,
} from 'components/UI';
import BenchmarkLineSVG from 'assets/images/benchmark-line.svg';
import {
  AreasOfGrowthAssetTable,
  AssetMatrixAssetTable,
  AssetMatrixSponsorshipLevelTable,
} from 'pages/organizationUser/pricing/components/reportTable';
import Tooltip from 'components/IconTooltip';
import { ExportButton } from 'components/ActionButtons';
import { useModal, useOperation } from 'hooks/common';
import {
  fetchAdminNoteList,
  fetchReport,
  fetchUploadReportPDF,
} from 'store/ducks/pricing/operations';
import { getPricingAdminNotes } from 'store/ducks/pricing/selectors';
import { useHistory, useParams } from 'react-router';
import html2pdf from 'html2pdf.js/dist/html2pdf';
import { useSelector } from 'react-redux';
import { getReport } from 'store/ducks/pricing/selectors';
import { moneyFormatter } from 'utils/formatters';
import { fetchSignedUrl } from 'store/ducks/utility/api';
import { ReportTip } from 'constants/pricing';
import LogoImg from 'assets/images/Logo.svg';
import { AdminNoteModal } from '../components/modal';
import { format } from 'date-fns';

const ValuationReport: FC = () => {
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  const { push } = useHistory();
  const params = useParams<{ valuationId: string }>();

  const [onFetchReport, , isLoading] = useOperation(fetchReport);
  const [onFetchUploadReportPDF] = useOperation(fetchUploadReportPDF);
  const [onFetchAdminNoteList] = useOperation(fetchAdminNoteList);

  const report = useSelector(getReport);
  const filteredAssetGroups = useMemo(() => {
    if (!report) return [];

    return report.calculation.AssetGroups.map(group => {
      if (group.name === 'Owned Media') {
        return {
          ...group,
          children: group.children.filter(child => {
            if (
              child.Asset === 'Video Content' &&
              !report.ownedMedia.doesYourEventHaveVideoContentThatIsSponsorable
            ) {
              return false;
            }
            return true;
          }),
        };
      }
      return group;
    });
  }, [report]);
  const adminNoteList = useSelector(getPricingAdminNotes);

  const maxTangibleAssetImpression = useMemo(() => {
    const maxValue =
      report?.calculation.TangibleAssetValueDetail &&
      [...report.calculation.TangibleAssetValueDetail].sort(
        (a, b) => b.impression - a.impression,
      )[0].impression;

    return maxValue || 0;
  }, [report]);

  const maxTangibleAssetValue = useMemo(() => {
    const maxValue =
      report?.calculation.TangibleAssetValueDetail &&
      [...report.calculation.TangibleAssetValueDetail].sort(
        (a, b) => b.value - a.value,
      )[0].value;

    return maxValue || 0;
  }, [report]);

  const pdfFileUrl = useMemo(() => {
    if (!report?.file) {
      return;
    }
    return fetchSignedUrl(report.file).then(value => value.result);
  }, [report?.file]);

  const exportAndUploadPdf = useCallback(() => {
    if (pdfFileUrl) {
      pdfFileUrl.then(url => window.open(url));
      return;
    }

    const element = document.getElementById('generate-pdf-content');
    if (element) {
      setIsExportLoading(true);
      setIsGeneratingPDF(true);

      setTimeout(() => {
        const opt = {
          margin: [0, 0, 0, 0],
          filename: report?.name + '.pdf',
          image: { type: 'jpeg', quality: 1 },
          pagebreak: {
            mode: ['avoid-all'],
          },
          html2canvas: {
            useCORS: true,
          },
          jsPDF: {
            unit: 'pt',
            format: [963, Number(element.clientHeight) / 1.32 + 50],
            orientation: 'p',
          },
        };

        document.body.style.overflow = 'hidden';
        const worker = html2pdf()
          .set(opt)
          .from(element);

        worker.output('blob').then((x: Blob) => {
          const file = new File([x], `${report?.name || 'Pricing'}.pdf`);
          onFetchUploadReportPDF({ id: params.valuationId, file }).then(() => {
            setIsExportLoading(false);
            worker.save().then(() => {
              setIsGeneratingPDF(false);
              document.body.style.overflow = 'visible';
            });
          });
        });
      });
    }
  }, [params, report]);

  const [showAdminNoteModal, hideAdminNoteModal] = useModal(
    () => <AdminNoteModal onClose={hideAdminNoteModal} notes={adminNoteList} />,
    [adminNoteList],
  );

  useEffect(() => {
    onFetchReport(params.valuationId);
    onFetchAdminNoteList(params.valuationId);
  }, []);

  return (
    <ContentBlockWrapper
      loading={isLoading}
      header={
        <Jumbotron name={`${report?.name || ''} Report`} back backPath="/pricing" />
      }
    >
      <NavigationLinkBar>
        <NavigationLink onClick={() => push(`/pricing/edit/${params.valuationId}`)}>
          Basic Info
        </NavigationLink>
        <NavigationLink active>Valuation Report</NavigationLink>
        <ActionGroup>
          <ActionText
            icon={ChatSVG}
            name="Admin Note"
            uploadHandler={() => {
              onFetchAdminNoteList(params.valuationId);
              showAdminNoteModal();
            }}
          />
          <ActionText
            icon={Download16SVG}
            isloading={isExportLoading}
            name="Export to PDF"
            uploadHandler={exportAndUploadPdf}
          />
        </ActionGroup>
      </NavigationLinkBar>
      <OutPdf isShowPDFEl={isGeneratingPDF}>
        <Root id="generate-pdf-content">
          <InfoCard>
            <InfoImage src={HandshakeSVG} />
            <InfoDetail>
              <DetailRow>
                <DetailTitle>Current Minimum Threshold</DetailTitle>
                <DetailValue>
                  {moneyFormatter.from(Number(report?.currentMinimumThreshold), {
                    symbol: '$',
                  })}
                  {Number(report?.currentMinimumThreshold) <
                  Number(report?.suggestedMinimumThreshold) ? (
                    <ArrowDownIcon src={ArrowDownHandleSVG} />
                  ) : (
                    <ArrowUpIcon src={ArrowDownHandleSVG} />
                  )}
                </DetailValue>
              </DetailRow>
              <DetailDesc>{report?.calculation.PrescriptiveAnalytics}</DetailDesc>
              <UIDivider type="horizontal" />
              <DetailRow>
                <DetailTitle>
                  Potential Minimum Threshold
                  <Tip>{ReportTip['Potential Minimum Threshold']}</Tip>
                </DetailTitle>
                <DetailValue>
                  {moneyFormatter.from(
                    Number(report?.calculation.PotentialMinimumThreshold),
                    {
                      symbol: '$',
                    },
                  )}
                </DetailValue>
              </DetailRow>
              <UIDivider type="horizontal" />
              <DetailRow>
                <DetailTitle>
                  Suggested Minimum Threshold
                  <Tip>{ReportTip['Suggested Minimum Threshold']}</Tip>
                </DetailTitle>
                <DetailValue>
                  {moneyFormatter.from(
                    Number(report?.calculation.SuggestedMinimumThreshold),
                    {
                      symbol: '$',
                    },
                  )}
                </DetailValue>
              </DetailRow>
            </InfoDetail>
          </InfoCard>
          <InfoCard type="dark">
            <InfoImage src={ValuationSVG} />
            <InfoDetail>
              <DetailRow>
                <DetailTitle>
                  Intangible Asset Value
                  <Tip>{ReportTip['Intangible Asset Value']}</Tip>
                </DetailTitle>
                <DetailValue>
                  {moneyFormatter.from(
                    Number(report?.calculation.SuggestedMinimumThreshold),
                    {
                      symbol: '$',
                    },
                  )}
                </DetailValue>
              </DetailRow>
              <DetailRow>
                <DetailTitle>
                  Tangible Asset Value
                  <Tip>{ReportTip['Tangible Asset Value']}</Tip>
                </DetailTitle>
                <DetailValue>
                  {moneyFormatter.from(Number(report?.calculation.TangibleAssetValue), {
                    symbol: '$',
                  })}
                </DetailValue>
              </DetailRow>
              <UIDivider type="horizontal" />
              <DetailRow>
                <DetailTitle>
                  Total Asset Value
                  <Tip>{ReportTip['Total Asset Value']}</Tip>
                </DetailTitle>
                <DetailValue>
                  {moneyFormatter.from(Number(report?.calculation.TotalAssetValue), {
                    symbol: '$',
                  })}
                </DetailValue>
              </DetailRow>
            </InfoDetail>
          </InfoCard>

          <ChartCard>
            <ChartCardTitle>
              Intangible Asset Value
              <TipBlue>{ReportTip['Intangible Asset Value']}</TipBlue>
            </ChartCardTitle>
            {report?.calIntangibleAssetValues
              .filter(item => item.benchmark)
              .map(item => (
                <ChartCardRow key={item.type}>
                  <ChartCardLabel>
                    {item.type}
                    {ReportTip[item.type] && <TipBlue>{ReportTip[item.type]}</TipBlue>}
                  </ChartCardLabel>
                  <ChartCardValue>
                    <ProgressBarStep value={item.value * 10} benchmark={item.benchmark} />
                    <ChartCardDesc>{item.prescriptiveAnalytics}</ChartCardDesc>
                  </ChartCardValue>
                </ChartCardRow>
              ))}
            <ChartCardRow>
              <LegendList>
                <Legend>
                  <LegendColor color="#F36D25" />
                  <LegendText>Under Benchmark</LegendText>
                </Legend>
                <Legend>
                  <LegendColor color="#2BAEE0" />
                  <LegendText>On Benchmark</LegendText>
                </Legend>
                <Legend>
                  <LegendColor color="#28759C" />
                  <LegendText>Beyond Benchmark</LegendText>
                </Legend>
                <Legend>
                  <ReactSVG src={BenchmarkLineSVG} />
                  <LegendText>Benchmark</LegendText>
                </Legend>
              </LegendList>
            </ChartCardRow>
          </ChartCard>

          <ChartCard>
            <ChartCardTitle>Tangible Asset Value</ChartCardTitle>
            <ChartCardRow>
              <ChartCardLabel short></ChartCardLabel>
              <ChartCardValue>
                <ChartCardLabel bold>
                  Benchmark<TipBlue>Shown as share of asset portfolio</TipBlue>
                </ChartCardLabel>
              </ChartCardValue>
            </ChartCardRow>
            {report?.calculation.TangibleAssetValueDetail.map((item, i) => (
              <ChartCardRow key={i}>
                <ChartCardLabel short>
                  {item.type}
                  <TipBlue>
                    {(ReportTip[item.type] || '').replace(
                      '[nonprofit organization]',
                      report.team.organizationName,
                    )}
                  </TipBlue>
                </ChartCardLabel>
                <ChartCardValue>
                  <ProgressBarGroup>
                    <NormalBenchmarkProgressBar
                      value={item.benchmark * 100}
                      text={`${(item.benchmark * 100).toFixed(0)}%`}
                    />
                    <NormalYourAssetProgressBar
                      value={item.percentage * 100}
                      text={`${(item.percentage * 100).toFixed(0)}%`}
                    />
                  </ProgressBarGroup>
                </ChartCardValue>
              </ChartCardRow>
            ))}
            <ChartCardRow>
              <ChartCardLabel short></ChartCardLabel>
              <ChartCardValue>
                <ChartCardLabel bold>Impressions</ChartCardLabel>
              </ChartCardValue>
            </ChartCardRow>
            {report?.calculation.TangibleAssetValueDetail.map((item, i) => (
              <ChartCardRow key={i}>
                <ChartCardLabel short>
                  {item.type}
                  <TipBlue>{ReportTip[item.type]}</TipBlue>
                </ChartCardLabel>
                <ChartCardValue>
                  <NormalImpressionsProgressBar
                    value={(item.impression / maxTangibleAssetImpression) * 100}
                    text={String(moneyFormatter.from(item.impression, { symbol: '' }))}
                  />
                </ChartCardValue>
              </ChartCardRow>
            ))}
            <ChartCardRow>
              <ChartCardLabel short></ChartCardLabel>
              <ChartCardValue>
                <ChartCardLabel bold>Value</ChartCardLabel>
              </ChartCardValue>
            </ChartCardRow>
            {report?.calculation.TangibleAssetValueDetail.map((item, i) => (
              <ChartCardRow key={i}>
                <ChartCardLabel short>
                  {item.type}
                  <TipBlue>{ReportTip[item.type]}</TipBlue>
                </ChartCardLabel>
                <ChartCardValue>
                  <NormalValueProgressBar
                    value={(item.value / maxTangibleAssetValue) * 100}
                    text={String(
                      moneyFormatter.from(item.value, {
                        symbol: '$',
                      }),
                    )}
                  />
                </ChartCardValue>
              </ChartCardRow>
            ))}
            <ChartCardRow>
              <LegendList>
                <Legend>
                  <LegendColor color="#B3B3B3" />
                  <LegendText>Benchmark</LegendText>
                </Legend>
                <Legend>
                  <LegendColor color="#2BAEE0" />
                  <LegendText>Your Asset</LegendText>
                </Legend>
                <Legend>
                  <LegendColor color="#D3802F" />
                  <LegendText>Impression</LegendText>
                </Legend>
                <Legend>
                  <LegendColor color="#28759C" />
                  <LegendText>Value</LegendText>
                </Legend>
              </LegendList>
            </ChartCardRow>
          </ChartCard>

          <ChartCard>
            <ChartCardTitle>Asset Matrix</ChartCardTitle>
            <ChartCardRow>
              <AssetMatrixSponsorshipLevelTable
                headCells={[
                  'Sponsorship  Level',
                  'Tier  One',
                  'Tier  Two',
                  'Tier  Three',
                ]}
                rows={report?.calculation.SponsorshipLevels || []}
              />
            </ChartCardRow>
            <ChartCardRow>
              <AssetMatrixAssetTable
                headCells={['Asset', 'Value', 'Tier  One', 'Tier  Two', 'Tier  Three']}
                rows={filteredAssetGroups || []}
              />
            </ChartCardRow>
          </ChartCard>

          <ChartCard>
            <ChartCardTitle>Areas of Growth</ChartCardTitle>
            <ChartCardRow>
              <AreasOfGrowthAssetTable
                headCells={['Asset', 'Value', 'Take Action']}
                rows={report?.calculation.AreasOfGrowth || []}
              />
            </ChartCardRow>
          </ChartCard>

          <InfoCard type="dark">
            <InfoImage src={AssetSVG} />
            <InfoDetail>
              <DetailRow>
                <DetailTitle>Recommended Asset Development</DetailTitle>
              </DetailRow>
              <DetailDesc>
                We recommend the following additional assets for consideration and
                development:
              </DetailDesc>
              <DetailList>
                {report?.calculation.RecommendedAssetDevelopment.map(
                  item => item.recommend,
                )
                  .flat()
                  .map((item, i) => (
                    <DetailItem key={i}>{item}</DetailItem>
                  ))}
              </DetailList>
            </InfoDetail>
          </InfoCard>
          <div className="only-pdf">
            {adminNoteList.length && (
              <ChartCard>
                <ChartCardTitle>Admin Note</ChartCardTitle>
                <NoteList>
                  {adminNoteList.map(item => (
                    <NoteListItem key={item.id}>
                      <NoteListItemHead>
                        <NoteListItemAvatar imgKey={item.createUser.avatarKey} />
                        <div>{`${item.createUser.firstName} ${item.createUser.lastName}`}</div>
                        <NoteListItemDate>
                          {format(new Date(item.createdAt), 'MMM d, yyyy h:mm aaa')}
                        </NoteListItemDate>
                      </NoteListItemHead>
                      <NoteListItemContent>{item.message}</NoteListItemContent>
                    </NoteListItem>
                  ))}
                </NoteList>
              </ChartCard>
            )}
            <Logo src={LogoImg} />
          </div>
        </Root>
      </OutPdf>
    </ContentBlockWrapper>
  );
};

const Root = styled.div`
  margin-bottom: 100px;
`;
const OutPdf = styled.div<{ isShowPDFEl: boolean }>`
  .only-pdf {
    display: ${({ isShowPDFEl }) => (isShowPDFEl ? 'block' : 'none')} !important;
  }
`;

const InfoCard = styled.section<{ type?: 'light' | 'dark' }>`
  border-radius: 6px;
  padding: 48px 24px;
  display: flex;
  margin-bottom: 16px;

  background: ${({ type = 'light' }) =>
    type === 'dark'
      ? 'linear-gradient(90deg, #447E94 0%, #114D64 100%)'
      : 'linear-gradient(90deg, #2baee0 0%, #178fb5 100%)'};
`;

const InfoImage = styled(ReactSVG)`
  width: 388px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoDetail = styled.div`
  flex: 1;
  color: #fff;
`;

const DetailRow = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const DetailTitle = styled.div`
  display: flex;
  align-items: center;
  width: 546px;
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
`;

const DetailValue = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
`;

const DetailDesc = styled.p`
  font-size: 16px;
  line-height: 148%;
`;

const ChartCard = styled.section`
  background: #eff1f2;
  margin-bottom: 16px;
  border-radius: 6px;

  & > div:first-child {
    border-bottom: 1px solid #fff;
  }
`;

const ChartCardTitle = styled.div`
  display: flex;
  padding: 32px 24px;
  font-weight: 500;
`;

const ChartCardRow = styled.div`
  display: flex;
  padding: 15px 24px;
`;

const ChartCardLabel = styled.div<{ bold?: boolean; short?: boolean }>`
  width: ${({ short }) => (short ? 'calc(242px - 24px)' : 'calc(417px - 24px)')};
  display: flex;

  ${({ bold }) => bold && 'font-weight: 500;'}
`;

const ChartCardValue = styled.div`
  flex: 1;
`;

const ChartCardDesc = styled(DetailDesc)`
  color: var(--black);
  /* margin: 16px 0 0 48px; */
  margin-top: 16px;
`;

const LegendList = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;

  & > div {
    margin-right: 8px;
  }
`;

const LegendColor = styled.div<{ color: string }>`
  width: 44px;
  height: 20px;
  border-radius: 99em;
  background-color: ${({ color }) => color};
`;

const LegendText = styled.div``;

const ProgressBarGroup = styled.div`
  display: flex;
  flex-direction: column;

  & > div:not(:first-child) {
    margin-top: 4px;
  }
`;

const NormalBenchmarkProgressBar = ({ value, text }: { value: number; text: string }) => (
  <ProgressBar value={value} text={text} color="#B3B3B3" />
);

const NormalYourAssetProgressBar = ({ value, text }: { value: number; text: string }) => (
  <ProgressBar value={value} text={text} color="#2BAEE0" />
);

const NormalImpressionsProgressBar = ({
  value,
  text,
}: {
  value: number;
  text: string;
}) => <ProgressBar value={value} text={text} color="#D3802F" />;

const NormalValueProgressBar = ({ value, text }: { value: number; text: string }) => (
  <ProgressBar value={value} text={text} color="#28759C" />
);

const DetailList = styled.div`
  margin-top: 52px;
`;

const DetailItem = styled.div`
  height: 40px;
  border-bottom: 1px solid #fff;
  margin-top: 16px;
`;

const Tip = styled(Tooltip)`
  color: var(--black);
  font-size: 12px;
  line-height: 150%;
  display: inline-flex;
  margin-left: 16px;
  height: min-content;

  .body {
    width: 350px;
  }

  svg * {
    fill: #fff;
  }
`;

export const TipBlue = styled(Tip)`
  svg * {
    fill: var(--blue);
  }
`;

const ActionGroup = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const ArrowDownIcon = styled(ReactSVG)`
  margin-left: 18px;
`;

const ArrowUpIcon = styled(ArrowDownIcon)`
  transform: rotate(180deg);
`;

const Logo = styled(ReactSVG)`
  & > div {
    display: flex;
    justify-content: center;
  }
`;

const NavigationLinkBar = styled.div`
  height: 69px;
  background: #fff;
  border-radius: 6px;
  padding: 22px 24px;
  margin-bottom: 24px;
  display: flex;
`;

const NavigationLink = styled.div<{ active?: boolean }>`
  font-size: 16px;
  line-height: 155%;
  margin-right: 20px;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
    font-weight: 500;
    color: #2baee0; 
  `}

  &:hover {
    color: #2baee0;
  }
`;

const ActionText = styled(ExportButton)`
  &:not(:first-child) {
    margin-left: 16px;
  }
`;

const NoteList = styled.div`
  background-color: white;
  padding: 24px 40px;
`;
const NoteListItem = styled.div`
  margin-bottom: 24px;
  margin-right: 4px;
  background-color: white;
`;
const NoteListItemHead = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 150%;
`;
const NoteListItemAvatar = styled(UILoadAvatarBase64)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
`;
const NoteListItemDate = styled.div`
  margin-left: auto;
  color: #868686;
`;

const NoteListItemContent = styled.pre`
  font-size: 16px;
  line-height: 155%;
  margin-left: 40px;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

export default ValuationReport;
