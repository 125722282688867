import { Field } from 'components/FinalForm';
import { FieldRadioButtons, FieldTextInput } from 'components/FormFields';
import Jumbotron from 'components/Jumbotron';
import { UIButton } from 'components/UI';
import { useModal, useOperation } from 'hooks/common';
import { ContentBlockWrapper } from 'layouts';
import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import {
  fetchROISurveyResult,
  fetchROISurveyResultSubmit,
} from 'store/ducks/roi/operations';
import styled from 'styled-components';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { getROISurveyResult } from 'store/ducks/roi/selectors';
import { ROISurveyResult } from 'interfaces/roi';
import { addDays, isAfter } from 'date-fns';
import SuccessfullyModal from 'components/Modals/SuccessfullyModal';
import { integerValidator } from 'utils/validation';
import Toast from 'services/Toast';

const EmployeeSurvey = () => {
  const search = queryString.parse(window.location.search) as { id: string };
  const [isDeleted, setIsDeleted] = useState(false);

  const [onFetchROISurveyResult, , loading] = useOperation(fetchROISurveyResult);
  const [onFetchROISurveyResultSubmit] = useOperation(fetchROISurveyResultSubmit);

  const result = useSelector(getROISurveyResult);

  const [showSuccessfullyModal, hideSuccessfullyModal] = useModal(
    () => (
      <SuccessfullyModal
        title="Survey Submitted"
        subTitle="Your responses have been received. Thank you for your participation."
        handleSubmit={() => {
          hideSuccessfullyModal();
          window.close();
        }}
        onClose={hideSuccessfullyModal}
      />
    ),
    [],
  );

  // const corporationName = result?.roiSurvey.roi.companyName;
  // const organizationName = result?.roiSurvey.roi.isCompany
  //   ? result?.roiSurvey.roi.company?.name
  //   : result?.roiSurvey.roi.team?.organizationName;

  const isCompany = result?.roiSurvey.roi.isCompany;
  const corporationName = isCompany
    ? result?.roiSurvey.roi.isCompany
      ? result?.roiSurvey.roi.company?.name
      : result?.roiSurvey.roi.team?.organizationName
    : result?.roiSurvey.roi.companyName;

  const organizationName = isCompany
    ? result?.roiSurvey.roi.companyName
    : result?.roiSurvey.roi.isCompany
    ? result?.roiSurvey.roi.company?.name
    : result?.roiSurvey.roi.team?.organizationName;

  const isExpired = useMemo(() => {
    const endDate = result?.roiSurvey.endDate
      ? addDays(new Date(result?.roiSurvey.endDate), 1)
      : new Date(0);
    const currentDate = new Date();

    return isAfter(currentDate, endDate);
  }, [result]);

  useEffect(() => {
    if (!search) return;
    onFetchROISurveyResult(search.id).then(value => {
      if (value === 'Deleted') setIsDeleted(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isDeleted) {
    return (
      <ContentBlockWrapper
        loading={loading}
        header={<Jumbotron name="Employee Survey" />}
      >
        <ExpiredTextWrapper>This survey has been deleted.</ExpiredTextWrapper>
      </ContentBlockWrapper>
    );
  }

  if (isExpired) {
    return (
      <ContentBlockWrapper
        loading={loading}
        header={<Jumbotron name="Employee Survey" />}
      >
        <ExpiredTextWrapper>This survey has expired.</ExpiredTextWrapper>
      </ContentBlockWrapper>
    );
  }

  return (
    <ContentBlockWrapper loading={loading} header={<Jumbotron name="Employee Survey" />}>
      <Form<ROISurveyResult>
        initialValues={result}
        onSubmit={values => {
          if (
            values.heardIt ||
            values.participateIt ||
            values.supportIt ||
            values.feel ||
            values.feelMore
          ) {
            onFetchROISurveyResultSubmit(values).then(() => {
              showSuccessfullyModal();
            });
            return;
          }

          Toast.warn('Fill in at least one field');
        }}
        render={({ handleSubmit }) => (
          <Body>
            <Section>
              <SectionTitle>
                1. Have you heard of {corporationName}&apos;s partnership with{' '}
                {organizationName}?
              </SectionTitle>
              <Field
                name="heardIt"
                component={FieldRadioButtons}
                layout="vertical"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
              />
            </Section>
            <Section>
              <SectionTitle>
                2. Do you participate in {corporationName}&apos;s partnership with{' '}
                {organizationName} by volunteering, donating, or attending events?
              </SectionTitle>
              <Field
                name="participateIt"
                component={FieldRadioButtons}
                layout="vertical"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
              />
            </Section>
            <Section>
              <SectionTitle>
                3. How many hours have you volunteered with {organizationName}?
              </SectionTitle>
              <ShortInputWrapper>
                <Field
                  name="supportIt"
                  component={FieldTextInput}
                  inputCss={{ width: '90px' }}
                  validate={integerValidator}
                />
                <div>hrs</div>
              </ShortInputWrapper>
            </Section>

            <Section>
              <SectionTitle>
                4. How do you feel about {corporationName} supporting {organizationName}?
              </SectionTitle>
              <Field
                name="feel"
                component={FieldRadioButtons}
                options={[
                  { label: 'Negative', value: 'Negative' },
                  { label: 'Neutral', value: 'Neutral' },
                  { label: 'Positive', value: 'Positive' },
                ]}
              />
            </Section>
            <Section>
              <SectionTitle>
                5. Do you feel more loyal to {corporationName} because they support{' '}
                {organizationName}?
              </SectionTitle>
              <Field
                name="feelMore"
                component={FieldRadioButtons}
                layout="vertical"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                  { label: 'Not Sure', value: 'Unsure' },
                ]}
              />
            </Section>
            <ButtonWrapper>
              <SubmitButton
                modifiers="primary"
                title="Submit"
                handleClick={handleSubmit}
              />
            </ButtonWrapper>
          </Body>
        )}
      />
    </ContentBlockWrapper>
  );
};

const Section = styled.div`
  background-color: white;
  padding: 24px;
  margin-bottom: 24px;
`;

const SectionTitle = styled.div`
  margin-bottom: 34px;
`;

const Body = styled.div`
  max-width: 785px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SubmitButton = styled(UIButton)`
  padding: 9px 22px;
  font-size: 12px;
`;

const ExpiredTextWrapper = styled.div`
  font-weight: 600;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ShortInputWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div:first-child {
    width: unset;
    margin: 0 8px 0;
  }
`;

export default EmployeeSurvey;
