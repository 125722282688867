import React from 'react';
import { FieldRenderProps } from 'react-final-form';

import styled from 'styled-components';
import { UISwitch } from 'components/UI';
import { FormError } from 'components/UI/form';

type Props = FieldRenderProps<boolean> & {
  description?: string;
  className?: string;
  disabled?: boolean;
};

const FieldSwitcher = ({
  input,
  label,
  meta,
  description,
  className,
  disabled,
}: Props) => {
  const { error, submitError, touched, dirtySinceLastSubmit } = meta;
  const isError = (error && touched) || (submitError && !dirtySinceLastSubmit);

  return (
    <Field className={className}>
      <Label>
        <span>{label}</span>
        <UISwitch
          disabled={disabled}
          checked={input.value || false}
          onChange={input.onChange}
        />
      </Label>
      {description && <Desc>{description}</Desc>}

      {isError && <Error meta={meta} />}
    </Field>
  );
};

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const Label = styled.label`
  font-size: 16px;
  line-height: 155%;
  font-style: normal;
  font-weight: normal;
  color: var(--black);
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & span {
    margin-right: 36px;
  }
`;

const Desc = styled.div`
  font-size: 16px;
  line-height: 145%;
  color: #979797;
`;

const Error = styled(FormError)`
  position: absolute;
`;

export default FieldSwitcher;
