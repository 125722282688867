import React from 'react';
import styled from 'styled-components';

import { WrapperProps } from 'interfaces/common';

export interface Props {
  title: string;
  description?: string;
  marginTop?: string;
}

const FormSection = ({
  title,
  description,
  children,
  className,
  marginTop,
}: Props & WrapperProps) => {
  return (
    <Root marginTop={marginTop} className={className}>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
      {children}
    </Root>
  );
};

const Root = styled.section<{ marginTop?: string }>`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '32px')};
`;
const Description = styled.p`
  font-size: 12px;
  width: 637px;
  line-height: 150%;
  color: var(--black);
  margin-bottom: 32px;
`;
const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin-bottom: 16px;
`;

export default FormSection;
