import { useState, useMemo, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = { id: string; [k: string]: any };

interface Props<T extends Data> {
  data: T[];
  defaultSelected?: string[];
}

const useTableSelect = <T extends Data>({ data, defaultSelected }: Props<T>) => {
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState<T[]>([]);

  const selectedIds = useMemo(() => {
    return selectedItems.map(item => item.id);
  }, [selectedItems]);

  const handleSelectAll = (bool: boolean) => {
    setIsSelectedAll(bool);
  };

  const handleSelectItem = (id: string) => {
    if (isSelectedAll) setIsSelectedAll(false);

    const item = data.find(item => item.id === id);
    if (!item) return;

    const newSelectedItems = selectedItems.some(item => item.id === id)
      ? selectedItems.filter(item => item.id !== id)
      : [...selectedItems, item];

    setSelectedItems(newSelectedItems);

    if (newSelectedItems.length === data.length) return setIsSelectedAll(true);
  };

  const handleClearSelected = () => {
    setSelectedItems([]);
    setIsSelectedAll(false);
  };

  useEffect(() => {
    if (!isSelectedAll) return setSelectedItems([]);
    setSelectedItems(data);
  }, [data, isSelectedAll]);

  useEffect(() => {
    if (!defaultSelected) return;
    setSelectedItems(data.filter(item => defaultSelected.includes(item.id)));
  }, [data, defaultSelected]);

  return {
    isSelectedAll,
    selectedItems,
    selectedIds,
    handleSelectAll,
    handleSelectItem,
    handleClearSelected,
  };
};

export default useTableSelect;
