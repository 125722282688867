import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { SearchCompany } from 'interfaces/companies';
import EmptyCompany from 'assets/images/icons/empty-company.svg';
import { UILoadAvatar } from 'components/UI';

const CardFavoritesSmall = ({ id, name, logo, crsFocus, score }: SearchCompany) => {
  const csr = crsFocus && crsFocus.length > 0 && crsFocus.map(i => i.name).join('; ');

  return (
    <Root>
      <Content>
        {logo ? (
          <LoadImage imgKey={logo} />
        ) : (
          <ImageEmpty>
            <ReactSVG src={EmptyCompany} />
          </ImageEmpty>
        )}

        <div>
          <Name to={`company/${id}`}>{name}</Name>
          <Ranking>Priority Ranking {score || '-'}</Ranking>
        </div>
      </Content>

      <CsrTitle>CSR Focus</CsrTitle>
      <CsrList>
        {csr ? <CsrItem>{csr}</CsrItem> : <CsrItem>No information</CsrItem>}
      </CsrList>
    </Root>
  );
};

const Root = styled.section`
  width: 256px;
  min-height: 156px;
  background: #ffffff;
  border-radius: 6px;
  margin: 12px 12px;
  padding: 24px;
  font-size: 12px;
  line-height: 150%;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const LoadImage = styled(UILoadAvatar)`
  width: 48px;
  height: 48px;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 6px;
  margin-right: 12px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100%;
`;

export const ImageEmpty = styled.div`
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 6px;
  margin-right: 12px;
  min-width: 48px;
  & svg {
    width: 22px;
  }
`;

const Name = styled(Link)`
  display: inline-block;
  width: 140px;
  line-height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
`;

const Ranking = styled.p`
  color: var(--darkGray);
`;

const CsrTitle = styled.p`
  margin-bottom: 4px;
  color: var(--darkGray);
`;

const CsrList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const CsrItem = styled.li`
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default CardFavoritesSmall;
