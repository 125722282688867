import { PayoutReq } from 'interfaces/payout';
import { fetchErrorHandler } from 'utils/errorHandlers';
import * as api from './api';
import Toast from 'services/Toast';
import { Dispatch } from 'redux';

export const fetchPayout = (payout: PayoutReq) => async (dispatch: Dispatch) => {
  return api.fetchPayout({ ...payout });
};

export const fetchEligiblePayoutAmount = (
  campaignName: string,
  merchantId: number,
) => async () => {
  return { data: 1 };
  //return api.fetchEligiblePayoutAmount(campaignName, merchantId).catch(fetchErrorHandler);
};
