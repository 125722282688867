import {
  CampaignCopyReq,
  CampaignCreateReq,
  CampaignData,
  CampaignListReq,
  ReportListReq,
  CampaignListRes,
  ReportListRes,
  CampaignReportExportReq,
  CampaignReportListItem,
  CampaignReportListReq,
  CampaignShareCodeReq,
  CamParListReq,
  CampaignSavePartnersList,
  EinListRes,
  EinListRes1,
  PartnerByCampaign,
  SaveParList,
  CamParListRes,
  CampaignDataTS,
  FundsRaisedRes,
  CampaignShareQrCodeReq,
} from 'interfaces/campaign';
import { fetch } from 'services/ApiService';
import { paramsToQueryString } from 'utils';
import { GiveATWorkData, ShopInfoReq } from 'interfaces/fundraising';
import { CompanyCreateReq } from 'interfaces/companies';
import { ROIAddCompanyRes } from 'interfaces/roi';

export const fetchCampaignList = ({ limit = 15, ...rest }: CampaignListReq) =>
  fetch<CampaignListRes>(
    'get',
    `campaign/list${paramsToQueryString({ limit, ...rest })}`,
  );

export const fetchReportList = ({ limit = 15, ...rest }: ReportListReq) =>
  fetch<ReportListRes>(
    'get',
    `campaign/reportList${paramsToQueryString({ limit, ...rest })}`,
  );

// export const fetchCampaignList = ({ limit = 12, ...rest }: CampaignListReq) =>
//   Promise.resolve({ "items": [{ "id": "dc", "campaignName": "test", "campaignDescription": "cdsbfv", "startDate": "2022-07-19T07:34:07.131Z", "endDate": "2022-07-19T07:34:07.131Z", "fundraisingGoal": "2300" }, { "id": "fd", "campaignName": "test111", "campaignDescription": "fvhg", "startDate": "2022-07-19T07:34:07.131Z", "endDate": "2022-07-19T07:34:07.131Z", "fundraisingGoal": "5300" }], "meta": { "totalItems": 2, "itemCount": 2, "itemsPerPage": "12", "totalPages": 1, "currentPage": "1" } });

export const fetchCampaign = (id: string) => fetch<CampaignData>('get', `campaign/${id}`);

export const getCampaignPreviewUrl = (id: string) =>
  fetch<{ status: number; data: string; msg: string }>(
    'get',
    `campaign/previewUrl/${id}`,
  );

export const fetchCampaignCreate = (param: CampaignCreateReq) =>
  fetch<CampaignData>('post', 'campaign/addCampaign', { payload: param });

export const fetchMultipleDelete = (ids: string) =>
  fetch<CampaignData>(
    'delete',
    `Campaign/MultipleDelete${paramsToQueryString({ deleteIds: ids })}`,
  );

export const isAssociationCamp = (ids: string) =>
  fetch<CampaignData>(
    'get',
    `campaign/getCampaignLink/isLink${paramsToQueryString({ campaignIds: ids })}`,
  );

export const fetchCampaignUpdate = (param: Partial<CampaignData>) =>
  fetch<CampaignData | { msg: string; status: number }>('patch', 'campaign', {
    payload: {
      ...param,
    },
  });

export const fetchCampaignUpdateTS = (param: Partial<CampaignDataTS>) =>
  fetch<CampaignDataTS | { msg: string; status: number }>('patch', 'campaign/teamsnap', {
    payload: {
      ...param,
    },
  });

export const fetchTotalRaisedById = (id: string) =>
  fetch<FundsRaisedRes>('get', `campaign/getCampaignTotalRaisedById/${id}`);

export const fetchCampaignCopy = (params: CampaignCopyReq) =>
  fetch('post', 'campaign/copy', { payload: params });

export const saveConfigDonation = (param: any) =>
  fetch<any>('post', 'campaign/configDonation', { payload: param });

export const fetchGiveShopInfo = (id: string) =>
  fetch<ShopInfoReq>('get', `campaign/getConfigDonation/${id}`);

export const fetchPreviewInfo = param =>
  fetch(
    'get',
    param.partnerId
      ? `campaign/getConfigDonation/${param.id}?partnerId=${param.partnerId}`
      : `campaign/getConfigDonation/${param.id}`,
  );

export const fetchPreviewPage = (id: string) => fetch('get', `campaign/getPreview/${id}`);

export const fetchCamPartnersList = ({ limit = 10, ...rest }: CampaignListReq) =>
  fetch<CamParListRes>(
    'get',
    `campaign/partners/list${paramsToQueryString({ limit, ...rest })}`,
  );

export const updateCampPartners = (param: CampaignSavePartnersList) =>
  fetch<any>('post', 'campaign/partners/updatePartners', { payload: param });

export const fetchAtWorkConfig = (campaignID: string) =>
  fetch('get', `campaign/getAtworkConfig/${campaignID}`);

export const saveATWorkConfig = (param: GiveATWorkData | any) =>
  fetch<any>('post', 'campaign/atworkConfigSave', { payload: param });

export const fetchAtworkPartnersList = ({ limit = 10, ...rest }: CamParListReq) =>
  fetch<CamParListReq>(
    'get',
    `campaign/partners/atworkPartnersList${paramsToQueryString({ limit, ...rest })}`,
  );

export const updateAtworkPartners = (param: SaveParList) =>
  fetch('post', 'campaign/partners/updateAtworkPartners', { payload: param });

export const isAssociationParn = (ids: string) =>
  fetch('get', `giveworxROI/getPartnerLink/isLink${paramsToQueryString({ ids: ids })}`);

export const fetchGiveROIAddCompany = (param: CompanyCreateReq) =>
  fetch<ROIAddCompanyRes>('post', `giveworxROI/addCompany`, { payload: param });

// No website validate
export const fetchGiveROIAddNew = (param: CompanyCreateReq) =>
  fetch<ROIAddCompanyRes>('post', `giveworxROI/addCompanyNew`, { payload: param });

export const fetchEinList = (organizationName: string) =>
  fetch<EinListRes>(
    'get',
    `campaign/judgeEin/getList${paramsToQueryString({
      organizationName: organizationName,
    })}`,
  );

export const fetchEinDetails = (paypalGivingFundId: string) =>
  fetch<EinListRes1>(
    'get',
    `campaign/judgeEin/getInfoById${paramsToQueryString({
      paypalGivingFundId: paypalGivingFundId,
    })}`,
  );

export const fetchEinInfo = () => fetch<EinListRes1>('get', 'campaign/judgeEin/getInfo');

export const affirmEinInfo = () => fetch('post', 'campaign/judgeEin/choseCount');

export const fetchChangePpgf = (params: { paypalGivingFundId: string; teamId: string }) =>
  fetch<{ status: number; data: {}; msg: string }>(
    'get',
    `campaign/judgeEin/changePpgf${paramsToQueryString(params)}`,
  );

export const fetchAtworkPartnerPreviewUrl = (campaignId: string) =>
  fetch<{ status: number; data: string; msg: string }>(
    'get',
    `campaign/partners/getAtworkPartnerPreviewUrl${paramsToQueryString({ campaignId })}`,
  );

export const fetchDownloadConsumerTemplate = () =>
  fetch<{ name: string; file: string }>('get', 'campaign/downloadConsumerTemplate');

export const fetchCurrentAllCampaigns = () =>
  fetch<CampaignData[]>('get', 'campaign/currentCampaign');

export const fetchPartnerByCampaign = (campaignId: string) =>
  fetch<PartnerByCampaign[]>(
    'get',
    `campaign/getPartnerByCampaign${paramsToQueryString({ id: campaignId })}`,
  );

export const fetchCampaignReportList = (params: CampaignReportListReq) =>
  fetch<CampaignReportListItem[]>(
    'get',
    `campaign/reportList${paramsToQueryString(params)}`,
  );

export const fetchCampaignReportExport = (params: CampaignReportExportReq) =>
  fetch<{ name: string; file: string }>(
    'get',
    `campaign/downloadReport${paramsToQueryString(params)}`,
  );

export const fetchCampaignQRCode = (id: string) =>
  fetch<string>('get', `campaign/getQrCode/${id}`);

export const fetchCampaignByIdQRCode = (id: string) =>
  fetch<string>('get', `campaign/QrCode/${id}`);

export const fetchCampaignShareCode = (params: CampaignShareCodeReq) =>
  fetch('post', 'campaign/shareCode', { payload: params });

export const fetchCampaignShareQrCode = (params: CampaignShareQrCodeReq) =>
  fetch('post', 'campaign/shareQrCode', { payload: params });
