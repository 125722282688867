import React from 'react';
import styled from 'styled-components';
import { TableSortHeaderCell } from 'components/UI/table';
import { SortStatus } from 'hooks/common/useSortStatus';
import { CampaignReportListItem } from 'interfaces/campaign';
import { format } from 'date-fns';

interface Props {
  rows: CampaignReportListItem[];
  sortStatus: SortStatus;
  onSort: (colName: string) => void;
}

interface TableRowProps {
  row: CampaignReportListItem;
}

const tableWidth = {
  campaignNameWidth: 140,
  partnerNameWidth: 115,
  donorNameWidth: 115,
  donorEmailWidth: 100,
  postalCodeWidth: 115,
  transactionDateWidth: 125,
  donationGrossAmountWidth: 160,
  donationNetAmountWidth: 140,
  donorCoveredFeesWidth: 130,
  recurringFrequencyWidth: 120,
  payPalDonationWidth: 100,
  atWorkDonationWidth: 100,
} as const;

const {
  campaignNameWidth,
  partnerNameWidth,
  donorNameWidth,
  donorEmailWidth,
  postalCodeWidth,
  transactionDateWidth,
  donationGrossAmountWidth,
  donationNetAmountWidth,
  donorCoveredFeesWidth,
  recurringFrequencyWidth,
  payPalDonationWidth,
  atWorkDonationWidth,
} = tableWidth;

const ReportList = ({ rows, sortStatus, onSort }: Props) => {
  const TableRowWrapper = ({ row }: TableRowProps) => {
    const {
      campaignName,
      partnerName,
      donorName,
      donorEmail,
      postalCode,
      transactionDate,
      donationGrossAmount,
      donationNetAmount,
      donorCoveredFees,
      recurringFrequency,
      payPalDonation,
      atWorkDonation,
    } = row;

    return (
      <TableRow>
        <TableRowCell $bold>{campaignName}</TableRowCell>
        <TableRowCell>{partnerName}</TableRowCell>
        <TableRowCell>{donorName}</TableRowCell>
        <TableRowCell>{donorEmail}</TableRowCell>
        <TableRowCell>{postalCode}</TableRowCell>
        <TableRowCell>
          {transactionDate && format(new Date(transactionDate), 'MMM dd, yyyy')}
        </TableRowCell>
        <TableRowCell>{donationGrossAmount}</TableRowCell>
        <TableRowCell>{donationNetAmount}</TableRowCell>
        <TableRowCell>{donorCoveredFees}</TableRowCell>
        <TableRowCell>{recurringFrequency}</TableRowCell>
        <TableRowCell>{payPalDonation}</TableRowCell>
        <TableRowCell>{atWorkDonation}</TableRowCell>
        <TableRowCell />
      </TableRow>
    );
  };

  return (
    <Body>
      <TableWrapper>
        <Table>
          <colgroup>
            <col span={1} style={{ width: `${campaignNameWidth}px` }} />
            <col span={1} style={{ width: `${partnerNameWidth}px` }} />
            <col span={1} style={{ width: `${donorNameWidth}px` }} />
            <col span={1} style={{ width: `${donorEmailWidth}px` }} />
            <col span={1} style={{ width: `${postalCodeWidth}px` }} />
            <col span={1} style={{ width: `${transactionDateWidth}px` }} />
            <col span={1} style={{ width: `${donationGrossAmountWidth}px` }} />
            <col span={1} style={{ width: `${donationNetAmountWidth}px` }} />
            <col span={1} style={{ width: `${donorCoveredFeesWidth}px` }} />
            <col span={1} style={{ width: `${recurringFrequencyWidth}px` }} />
            <col span={1} style={{ width: `${payPalDonationWidth}px` }} />
            <col span={1} style={{ width: `${atWorkDonationWidth}px` }} />
          </colgroup>
          <TableHead>
            <TableHeadRow>
              <TableSortHeaderCell
                colName="campaignName"
                onClick={onSort}
                sortStatus={sortStatus}
              >
                Campaign Name
              </TableSortHeaderCell>
              <TableSortHeaderCell
                colName="partnerName"
                onClick={onSort}
                sortStatus={sortStatus}
              >
                Partner Name
              </TableSortHeaderCell>
              <TableHeadCell>Donor Name</TableHeadCell>
              <TableHeadCell>Donor Email</TableHeadCell>
              <TableHeadCell>Postal Code</TableHeadCell>
              <TableSortHeaderCell
                colName="transactionDate"
                onClick={onSort}
                sortStatus={sortStatus}
              >
                Transaction
                <br /> Date
              </TableSortHeaderCell>
              <TableHeadCell>Donation Gross Amount</TableHeadCell>
              <TableHeadCell>Donation Net Amount</TableHeadCell>
              <TableHeadCell>Donor Covered Fees</TableHeadCell>
              <TableHeadCell>Recurring Frequency</TableHeadCell>
              <TableHeadCell>PayPal Donation</TableHeadCell>
              <TableHeadCell>AtWork Donation</TableHeadCell>
              <TableHeadCell />
            </TableHeadRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((item, index) => <TableRowWrapper key={index} row={item} />)}
          </TableBody>
        </Table>
      </TableWrapper>
    </Body>
  );
};

const Body = styled.div`
  overflow: auto;
`;

const TableWrapper = styled.div`
  overflow: auto;
  height: 444px;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;
const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);
  background: #f4f4f4;
  position: sticky;
  top: 0;
  vertical-align: middle;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableRow = styled.tr`
  height: 100%;
`;

const TableRowCell = styled.td<{ $bold?: boolean }>`
  padding: 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }

  ${({ $bold }) => $bold && 'font-weight: 500;'}
`;

export default ReportList;
