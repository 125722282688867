import { Field } from 'components/FinalForm';
import {
  FieldSingleSelect,
  FieldSuggestSingle,
  FieldTextInput,
  MaskedMoneyTextInput,
} from 'components/FormFields';
import { CompanyCreateReq, SuggestCompany } from 'interfaces/companies';
import React, { useMemo } from 'react';
import { FieldRenderProps, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import {
  ButtonGroup,
  FieldDoubleRow,
  FieldWrapper,
  Title,
  ActionText,
  AddCompany,
  AddUser,
} from './styled';
import CloseIcon from 'assets/images/icons/close.svg';
import { email, required, webSiteLink } from 'utils/validation';
import { ManageFormData, ROIData } from 'interfaces/roi';
import { User } from 'interfaces/auth';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/ducks/user/selectors';
import AddANewCompany from '../modals/AddANewCompany';
import { useModal } from 'hooks/common';
import { ConfirmModal } from 'components/Modals';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import FormArrayDeleteIcon from 'components/UI/form/FormArrayDeleteIcon';
import { composeValidators } from 'utils';
import UIAsterisk from 'components/UI/UIAsterisk';
import { ROINavigationEnum } from 'constants/roi';
import { UIButton } from 'components/UI';

interface Props {
  roi: ROIData;
  disabled: boolean;
  handleGetCompanies: (val: string) => void;
  companiesSearchList: SuggestCompany[];
  teamMembers: User[];
  onSubmit: (values) => void;
  onCompanyCreate: (values: CompanyCreateReq) => void;
}

const ManageForm = ({
  roi,
  handleGetCompanies,
  companiesSearchList,
  teamMembers,
  onSubmit,
  onCompanyCreate,
  disabled,
}: Props) => {
  const { push } = useHistory();
  const user = useSelector(selectUser);
  const membersOptions = useMemo(
    () =>
      teamMembers.map(item => ({
        value: item.id,
        label:
          item.firstName || item.lastName
            ? `${item.firstName} ${item.lastName}`
            : 'no name',
      })),
    [teamMembers],
  );

  const pushToAddUser = () => push('/teams');

  const [showAddANewCompany, hideAddANewCompany] = useModal(() => {
    return (
      <AddANewCompany
        handleAccess={values => {
          onCompanyCreate(values);
          hideAddANewCompany();
        }}
        onClose={hideAddANewCompany}
      />
    );
  }, []);

  const [showAddANewUserModal, hideAddANewUserModal] = useModal(() => {
    return (
      <ConfirmModal
        accessHandler={hideAddANewUserModal}
        cancelHandler={() => {
          hideAddANewUserModal();
          pushToAddUser();
        }}
        accessModifiersBtn="secondary"
        cancelModifiersBtn="primary"
        textAccessBtn="Cancel"
        textCancelBtn="Continue"
        name="Add a new user"
        description="The form has not been saved. Do you want to continue?"
        onClose={hideAddANewUserModal}
      />
    );
  }, []);

  return (
    <Form
      initialValues={{
        ...roi,
        company: roi.companyName,
        accountOwner: disabled ? roi.accountOwner : roi.accountOwner || user.id,
      }}
      mutators={{
        ...arrayMutators,
      }}
      onSubmit={(values: ManageFormData) => {
        const company = values.company;
        const formData: Partial<ManageFormData> = { ...values };
        delete formData.company;

        let submitValue = { ...roi };
        // eslint-disable-next-line eqeqeq
        if (typeof company === 'string' && company != null) {
          submitValue = { ...submitValue, ...formData };
        } else {
          submitValue = {
            ...submitValue,
            ...formData,
            companyId: company.id,
            companyName: company.name,
          };
        }
        onSubmit(submitValue);
      }}
      render={({
        form: {
          mutators: { push: formArrPush },
          change,
        },
        handleSubmit,
        pristine,
        values,
      }) => (
        <>
          <Title>Basic Info</Title>
          <FieldWrapper>
            <Field name="company" validate={required}>
              {(props: FieldRenderProps<any>) => {
                const input = {
                  ...props.input,
                  onChange: (value: SuggestCompany) => {
                    if (!value) {
                      change('companyUrl', undefined);
                      change('CompanyAddress', undefined);
                      props.input.onChange(undefined);
                      return;
                    }

                    change('companyUrl', value.website);
                    change(
                      'CompanyAddress',
                      `${value.street ? `${value.street}, ` : ''}${
                        value.city ? `${value.city}, ` : ''
                      }${value.state ? `${value.state}, ` : ''}${
                        value.country ? `${value.country}, ` : ''
                      }${value.continent ? `${value.continent}, ` : ''}`,
                    );
                    props.input.onChange(value);
                  },
                };
                return (
                  <FieldSuggestSingle
                    {...props}
                    label={
                      <>
                        Company Name
                        <UIAsterisk />
                      </>
                    }
                    handleGetSuggestions={handleGetCompanies}
                    list={companiesSearchList}
                    placeholder="Search"
                    input={input}
                    disabled={disabled}
                  />
                );
              }}
            </Field>
            <AddCompany>
              <ActionText onClick={showAddANewCompany}>Add a new company</ActionText>
            </AddCompany>
          </FieldWrapper>
          <FieldWrapper>
            <Field
              name="companyUrl"
              label={
                <>
                  Company URL
                  <UIAsterisk />
                </>
              }
              component={FieldTextInput}
              validate={composeValidators<string>(required, webSiteLink)}
              disabled={disabled}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Field
              name="CompanyAddress"
              label="Company Address"
              component={FieldTextInput}
              disabled={disabled}
            />
          </FieldWrapper>
          <FieldDoubleRow>
            <Field
              name="annualFundraisingCommitment"
              label="Annual Fundraising Commitment"
              component={MaskedMoneyTextInput}
              disabled={disabled}
            />
          </FieldDoubleRow>
          <FieldDoubleRow>
            <Field
              name="accountOwner"
              label="Account Owner"
              component={FieldSingleSelect}
              options={membersOptions}
              placeholder="Select"
              disabled={disabled}
            />
            <AddUser>
              <ActionText
                onClick={() => (pristine ? pushToAddUser() : showAddANewUserModal())}
              >
                Add a new user
              </ActionText>
            </AddUser>
          </FieldDoubleRow>

          <Title>Contact</Title>
          <FieldArray name="contacts">
            {({ fields }) =>
              fields.map((name, index) => (
                <div key={index}>
                  <FieldDoubleRow>
                    <Field
                      name={`${name}.firstName`}
                      label={`Contact ${index + 1} First Name`}
                      component={FieldTextInput}
                      disabled={disabled}
                    />
                    <Field
                      name={`${name}.lastName`}
                      label={`Contact ${index + 1} Last Name`}
                      component={FieldTextInput}
                      disabled={disabled}
                    />
                  </FieldDoubleRow>
                  <FieldDoubleRow>
                    <Field
                      name={`${name}.jobTitle`}
                      label="Contact Title"
                      component={FieldTextInput}
                      disabled={disabled}
                    />
                    <Field
                      name={`${name}.email`}
                      label="Contact Email"
                      component={FieldTextInput}
                      validate={email}
                      disabled={disabled}
                    />
                    <DeleteBtn src={CloseIcon} onClick={() => fields.remove(index)} />
                  </FieldDoubleRow>
                </div>
              ))
            }
          </FieldArray>
          <ActionText onClick={() => formArrPush('contacts', undefined)}>
            Add {!!values.contacts?.length && 'another'} contact
          </ActionText>

          <ButtonGroup>
            <UIButton
              title="Cancel"
              modifiers="secondary"
              handleClick={() => push(`/roi?type=${ROINavigationEnum.Partnerships}`)}
            />
            <UIButton
              title="Save"
              modifiers="primary"
              handleClick={handleSubmit}
              disabled={disabled}
            />
          </ButtonGroup>
        </>
      )}
    />
  );
};

const DeleteBtn = styled(FormArrayDeleteIcon)`
  margin-top: 30px;
  margin-left: 12px;
  cursor: pointer;
  position: relative;
  top: -42px;
  left: 20px;
`;

export default ManageForm;
