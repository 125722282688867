import React from 'react';
import styled from 'styled-components';
import { UIModalProps } from 'components/UI/interfaces';
import { UIModal } from 'components/UI';
import SupportForm from 'components/Forms/SupportForm';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { useOperation } from 'hooks/common';
import { fetchSupportEmail } from 'store/ducks/companies/operations';

interface Props {
  companyName?: string;
}

function SupportModal({ onClose, companyName, ...modalProps }: Props & UIModalProps) {
  useLockBodyScroll();

  const [onFetchSupportEmail] = useOperation(fetchSupportEmail);

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '24px 40px' }}
      style={{
        width: '100%',
        maxWidth: '446px',
      }}
      header={<ModalTitle>Write to Support</ModalTitle>}
    >
      <SupportForm
        companyName={companyName}
        onSubmit={values => {
          onFetchSupportEmail({ subject: values.reason, content: values.text });
          onClose();
        }}
      />
    </UIModal>
  );
}

const ModalTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: var(--black);
  padding: 40px 0 16px;
`;

export default SupportModal;
