import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { WrapperProps } from 'interfaces/common';
import { UISpinner } from 'components/UI';
import { PAGE_WIDTH } from 'constants/common';

interface BaseProps {
  header?: ReactNode;
  loading?: boolean;
}

type Props = BaseProps & WrapperProps;

function ContentBlockWrapper({ children, header, loading }: Props) {
  return (
    <>
      {header}
      <Body>{loading ? <Spinner /> : <Content>{children}</Content>}</Body>
    </>
  );
}

const Body = styled.div`
  background: #f9f9f9;
  min-height: calc(100% - 176px);
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  width: 100%;
  max-width: ${PAGE_WIDTH.LARGE}px;
  margin: 0 auto;
  padding: 32px 0 20px;
  flex: 1;
  position: relative;
`;

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 201px);
`;

export default ContentBlockWrapper;
