import React, { FC } from 'react';
import ConfirmModal from '../ConfirmModal';
import { Props as ConfirmModalProps } from '../ConfirmModal/ConfirmModal';

const CannotPitchModal: FC<Omit<ConfirmModalProps, 'name' | 'onClose'>> = ({
  accessHandler,
  cancelHandler,
  ...restProps
}) => {
  return (
    <ConfirmModal
      {...restProps}
      accessHandler={accessHandler}
      cancelHandler={cancelHandler}
      name="Verify Email"
      description="Please verify your email address before sending the email"
      onClose={cancelHandler}
      accessModifiersBtn="third"
      textAccessBtn="Verify"
    />
  );
};

export default CannotPitchModal;
