/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useOperation } from 'hooks/common';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import Jumbotron from 'components/Jumbotron';
import List from 'components/List';
import { StateProps } from 'store/interfaces';
import { Prospect, SavedListBatchTeam } from 'interfaces/prospects';
import EmptyProspecting from 'components/EmptyProspecting';
import { getBatchTeam, getSavedListsTeams } from 'store/ducks/prospectsTeams/selectors';
import {
  fetchSavedListsTeams,
  fetchSavedListsBatch,
} from 'store/ducks/prospectsTeams/operations';
import CardProspectTeam from 'components/CardProspectTeam';
import { PAGE_WIDTH } from 'constants/common';
import { getCompanySubscription } from 'store/ducks/companies/selectors';
import CardBatchTeam from 'components/CardBatchTeam';

const Dashboard = () => {
  const [onFetchSavedLists, isLoadingSavedList] = useOperation(fetchSavedListsTeams);
  const [onFetchSavedBatchLists] = useOperation(fetchSavedListsBatch);

  const savedLists = useSelector<StateProps, Prospect[]>(getSavedListsTeams);
  const savedBatchLists = useSelector(getBatchTeam);
  const subscription = useSelector(getCompanySubscription);

  useEffect(() => {
    onFetchSavedLists({ page: 1, sort: 'alphabet' });
    onFetchSavedBatchLists({ page: 1, sort: 'alphabet' });
  }, []);

  return (
    <ContentBlockWrapper
      header={<Jumbotron name="Dashboard" />}
      loading={isLoadingSavedList}
    >
      <>
        {subscription?.isVetting && (
          <Section>
            <SectionHeader>
              <SectionTitle>Vetting Sessions</SectionTitle>

              <LinkElem to="/prospects?type=vettingsessions">see more</LinkElem>
            </SectionHeader>

            {savedLists && savedLists.length > 0 ? (
              <List<Prospect, object>
                list={savedLists.slice(0, 2)}
                component={CardProspectTeam}
              />
            ) : (
              <EmptyProspecting />
            )}
          </Section>
        )}
        <Section>
          <SectionHeader>
            <SectionTitle>Batch Analysis</SectionTitle>

            <LinkElem to="/SearchBatch">see more</LinkElem>
          </SectionHeader>

          {savedBatchLists && savedBatchLists.items.length > 0 ? (
            <List<SavedListBatchTeam, object>
              list={savedBatchLists.items.slice(0, 2)}
              component={CardBatchTeam}
            />
          ) : (
            <EmptyProspecting />
          )}
        </Section>
      </>
    </ContentBlockWrapper>
  );
};

const SectionHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
`;

const SectionTitle = styled.h4`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
`;

const LinkElem = styled(Link)`
  color: var(--blue);
  &:hover {
    text-decoration: underline;
  }
`;

const Section = styled.section`
  max-width: ${PAGE_WIDTH.NORMAL}px;
  margin-bottom: 40px;
`;

export default Dashboard;
