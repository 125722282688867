/* eslint-disable react-hooks/exhaustive-deps */
import { CropText, UICheckbox } from 'components/UI';
import React, { CSSProperties } from 'react';
import styled from 'styled-components/macro';
import { OnCheckParams } from 'components/UI/interfaces';
import { ContactCombineCompany } from 'interfaces/gameplan';
import { FixedSizeList } from 'react-window';
import { TableSortHeaderCell } from 'components/UI/table';
import { useSortStatus } from 'hooks/common';
import { PAGE_WIDTH } from 'constants/common';

interface Props {
  rows: ContactCombineCompany[];
  checkedAll: boolean;
  checkedContactsIds: string[];
  onCheckRow: ({ check, id }: OnCheckParams) => void;
  onCheckAll: (bool: boolean) => void;
}

interface TableRowProps {
  row: ContactCombineCompany;
  check: boolean;
  onCheck: ({ check, id }: OnCheckParams) => void;
  style: CSSProperties;
}

const userTableWidth = {
  column1: 125,
  column2: 138,
  column3: 281,
  column4: 180,
  column5: 220,
  column6: 118,
  column7: 138,
} as const;

const { column1, column2, column3, column4, column5, column6, column7 } = userTableWidth;

const TableRowWrapper = ({ row, check, onCheck, style }: TableRowProps) => {
  const {
    id,
    firstName,
    lastName,
    company,
    jobTitle,
    phone,
    email,
    managementLevel,
    jobFunction,
  } = row;

  return (
    <TableRow isDisable={row.usedIn60Days !== '0'} style={style}>
      <TableRowCell style={{ width: `${70}px` }}>
        {row.usedIn60Days === '0' ? (
          <UICheckbox check={check} handleClick={() => onCheck({ check: !check, id })} />
        ) : null}
      </TableRowCell>
      <TableRowCell style={{ width: `${column1}px` }}>
        <TableLinkWrapper width={column1 - 30}>
          <TableLink>{(firstName || '') + ' ' + (lastName || '')}</TableLink>
        </TableLinkWrapper>
      </TableRowCell>

      <TableRowCell style={{ width: `${column2}px` }}>
        <CropText width={column2 - 20} text={company.name} />
      </TableRowCell>

      <TableRowCell style={{ width: `${column3}px` }}>
        <TableLinkWrapper width={column3 - 40}>
          {jobTitle ? <TableLink title={jobTitle}>{jobTitle}</TableLink> : '-'}
        </TableLinkWrapper>
      </TableRowCell>

      <TableRowCell style={{ width: `${column4}px` }}>
        <p>{phone || '-'} </p>
      </TableRowCell>

      <TableRowCell style={{ width: `${column5}px` }}>
        <CropText width={column5 - 20} text={email || '-'} />
      </TableRowCell>

      <TableRowCell style={{ width: `${column6}px` }}>
        <TableLinkWrapper width={column6 - 10}>
          {managementLevel ? (
            <TableLink title={managementLevel}>{managementLevel}</TableLink>
          ) : (
            '-'
          )}
        </TableLinkWrapper>
      </TableRowCell>

      <TableRowCell style={{ width: `${column7}px` }}>
        <TableLinkWrapper width={column7 - 10}>
          {jobFunction ? <TableLink title={jobFunction}>{jobFunction}</TableLink> : '-'}
        </TableLinkWrapper>
      </TableRowCell>
    </TableRow>
  );
};

const GamePlanSelectContactTable = ({
  rows,
  onCheckRow,
  checkedAll,
  onCheckAll,
  checkedContactsIds,
}: Props) => {
  const { data, sortStatus, handleSwitch } = useSortStatus(rows);

  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${70}px` }} />
          <col span={1} style={{ width: `${column1}px` }} />
          <col span={1} style={{ width: `${column2}px` }} />
          <col span={1} style={{ width: `${column3}px` }} />
          <col span={1} style={{ width: `${column4}px` }} />
          <col span={1} style={{ width: `${column5}px` }} />
          <col span={1} style={{ width: `${column6}px` }} />
          <col span={1} style={{ width: `${column7}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell key="checkbox" style={{ width: `${70}px` }}>
              <UICheckbox
                check={checkedAll}
                handleClick={() => onCheckAll(!checkedAll)}
              />
            </TableHeadCell>
            <TableSortHeaderCell
              style={{ width: `${userTableWidth.column1}px` }}
              colName="firstName"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Full Name
            </TableSortHeaderCell>
            <TableSortHeaderCell
              style={{ width: `${userTableWidth.column2}px` }}
              colName="company.name"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Company
            </TableSortHeaderCell>
            <TableSortHeaderCell
              style={{ width: `${userTableWidth.column3}px` }}
              colName="jobTitle"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Title
            </TableSortHeaderCell>
            <TableSortHeaderCell
              style={{ width: `${userTableWidth.column4}px` }}
              colName="phone"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Phone
            </TableSortHeaderCell>
            <TableSortHeaderCell
              style={{ width: `${userTableWidth.column5}px` }}
              colName="email"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Email
            </TableSortHeaderCell>
            <TableSortHeaderCell
              style={{ width: `${userTableWidth.column6}px` }}
              colName="managementLevel"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Mangement Level
            </TableSortHeaderCell>
            <TableSortHeaderCell
              style={{ width: `${userTableWidth.column7}px` }}
              colName="jobFunction"
              sortStatus={sortStatus}
              onClick={handleSwitch}
            >
              Job Function
            </TableSortHeaderCell>
          </TableHeadRow>
        </TableHead>
        <TableBody>
          <FixedSizeList
            height={window.innerHeight - 490}
            itemCount={data.length}
            itemSize={59}
            width={PAGE_WIDTH.LARGE}
          >
            {({ index, style }) => (
              <TableRowWrapper
                style={style}
                row={data[index]}
                check={checkedContactsIds.includes(data[index].id)}
                onCheck={onCheckRow}
              />
            )}
          </FixedSizeList>
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableLinkWrapper = styled.div<{ width?: string | number }>`
  display: block;
  ${({ width }) => width && `width: ${width}px;`}
`;

const TableLink = styled.span`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TableWrapper = styled.div`
  margin-bottom: 36px;
  width: ${PAGE_WIDTH.LARGE}px;
`;

const Table = styled.table`
  width: 100%;
  display: block;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
  display: block;
`;

const TableHeadRow = styled.tr`
  height: 39px;
  display: block;
`;

const TableBody = styled.tbody`
  background: #ffffff;
  display: block;
  width: ${PAGE_WIDTH.LARGE}px;
  height: 100%;
  max-height: calc(100vh - 490px);
`;
const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);
  vertical-align: middle;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-right: 24px;
  }
`;

const TableRow = styled.tr<{ isDisable: boolean }>`
  height: 100%;
  width: 100%;
  display: block;
  border-bottom: 1px solid #f6f6f6;
  ${props => (props.isDisable ? 'background-color: #f4f4f4' : null)}
`;

const TableRowCell = styled.td`
  padding: 20px 0;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }
`;

export default GamePlanSelectContactTable;
