import * as api from './api';
import * as actions from './actions';
import { Dispatch } from 'redux';
import Toast from 'services/Toast';
import { SavedListCreateRequestByTeam } from 'interfaces/common';

export const fetchSavedListsTeams = ({ page, sort }) => async (dispatch: Dispatch) => {
  return api
    .fetchSavedListsTeams({ page, sort })
    .then(lists => {
      dispatch(actions.setSavedLists(lists));
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchSavedListTeams = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchSavedListTeams(id)
    .then(list => {
      dispatch(actions.setSavedList(list));
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchSavedListTeamsCreate = (data: SavedListCreateRequestByTeam) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchSavedListTeamsCreate(data)
    .then(list => {
      dispatch(actions.setSavedList(list));
      return list;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchSavedListTeamsUpdate = ({ id, data }) => async (dispatch: Dispatch) => {
  return api
    .fetchSavedListTeamsUpdate(id, data)
    .then(list => {
      dispatch(actions.setSavedList(list));
      return list;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchSavedListTeamsDestroy = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchSavedListTeamsDestroy(id)
    .then(savedListId => {
      dispatch(actions.removeSavedList(savedListId));
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchSuggestSavedlistTeams = (name: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchSuggestSavedlistTeams(name)
    .then(suggestSavedList => {
      dispatch(actions.setSuggestSavedList(suggestSavedList));
    })
    .catch(() => Toast.error('Error get suggest savedlist'));
};

// export const fetchBatch = (name: string) => async (dispatch: Dispatch) => {
//   return api
//     .fetchBatch(name)
//     .then(data => {
//       dispatch(actions.setBatch(data));
//     })
//     .catch(() => Toast.error('Error Batch'));
// };

export const fetchSavedListsBatch = ({ page, sort }) => async (dispatch: Dispatch) => {
  return api
    .fetchSavedListsBatch({ page, sort })
    .then(lists => {
      dispatch(actions.setSavedBatchLists(lists));
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};
