import { Field } from 'components/FinalForm';
import { FieldSingleSelect, FieldSuggest } from 'components/FormFields';
import { FormContactsSectionProps } from 'components/Forms/interfaces';
import { filterSectionTexts } from 'constants/search';
import React from 'react';
import styled from 'styled-components';
import FormSection from '../FormSection';

const ContactsSection = ({
  handleGetJobTitleSuggestions,
  jobTitlesSearchList,
}: FormContactsSectionProps) => {
  const { conatcts } = filterSectionTexts;
  return (
    <FormSection {...conatcts} hasUnsetHeight={true}>
      <Row>
        <Field
          name="contact"
          label="Contact Title"
          component={FieldSuggest}
          handleGetSuggestions={handleGetJobTitleSuggestions}
          placeholder="Search"
          list={jobTitlesSearchList}
        />
        <Field
          name="managementLevel"
          label="Management Level"
          component={FieldSingleSelect}
          placeholder="Select Management Level"
          options={[
            { value: 'Manager', label: 'Manager' },
            { value: 'C Level', label: 'C Level' },
            { value: 'Director', label: 'Director' },
            { value: 'VP', label: 'VP' },
          ]}
        />
      </Row>
    </FormSection>
  );
};

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    width: calc(50% - 8px);
  }
`;

export default ContactsSection;
