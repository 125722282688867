import {
  ContactCombineCompany,
  CustomizeEmailTemplateGetRes,
  CustomizeEmailTemplateSaveReq,
  CustomizeTemplateGetReq,
  CustomizeTemplateGetRes,
  CustomizeTemplatePreviewReq,
  CustomizeTemplateSaveReq,
  Gameplan,
  GameplanBase,
  GameplanByTeamRes,
  GameplanEmailPreviewReq,
  GameplansByTeamReq,
  GameplanStatus,
  NewGameplan,
  ReqChangeGameplanNameAndClose,
  ReqNewGameplan,
  ResGameplans,
  SuggestGameplansRes,
  UploadContactsReq,
  UploadContactsRes,
  UploadContactsXLSXRes,
} from 'interfaces/gameplan';
import { fetch } from 'services/ApiService';
import { paramsToQueryString } from 'utils';
import StorageService from 'services/Storage';
import FormData from 'form-data';

export const fetchSaveListContacts = (savelistId: string) =>
  fetch<ContactCombineCompany[]>('get', `saved-list/${savelistId}/contacts`);

export const fetchNewGameplan = ({ savelistId, gameplanType }: ReqNewGameplan) =>
  fetch<NewGameplan>('get', `game-plan/new/${gameplanType}/${savelistId}`);

export const fetchCreateGameplan = (gameplan: NewGameplan) =>
  fetch<GameplanBase>('post', 'game-plan', {
    payload: gameplan,
    checkRequestSize: true,
  });

export const fetchGameplansBySavelist = (savelistId: string) =>
  fetch<ResGameplans>('get', `game-plan/bySavedList/${savelistId}`);

export const fetchChangeGameplanNameAndClose = (param: ReqChangeGameplanNameAndClose) =>
  fetch<Gameplan>('patch', 'game-plan/updateNameAndStatus', { payload: param });

export const fetchUpdateGameplan = (gameplan: NewGameplan) =>
  fetch<GameplanBase>('patch', 'game-plan', {
    payload: gameplan,
    checkRequestSize: true,
  });

export const fetchDeleteGameplan = (gameplanId: string) =>
  fetch<{ status: string }>('delete', `game-plan/${gameplanId}`);

export const fetchGameplan = (
  gameplanId: string,
  onlyAnalytics: undefined | 'true' | 'false' = undefined,
) =>
  fetch<Gameplan>(
    'get',
    `game-plan/${gameplanId}${paramsToQueryString({ onlyAnalytics })}`,
  );

export const fetchDeleteGameplanContact = (gameplanContactId: string) =>
  fetch<{ status: string }>('delete', `game-plan/contact/${gameplanContactId}`);

export const fetchGameplanStatus = (gameplanId: string) =>
  fetch<GameplanStatus>('get', `game-plan/gamePlanStatus/${gameplanId}`);

export const fetchGameplansByTeam = (param: GameplansByTeamReq) =>
  fetch<GameplanByTeamRes>('get', `game-plan/byTeams${paramsToQueryString(param)}`);

export const fetchGameplanActivity = (gameplanId: string) =>
  fetch<{ name: string; file: string }>('get', `game-plan/activity/${gameplanId}`);

export const fetchGameplanAllActivity = () =>
  fetch<{ name: string; file: string }>('get', 'game-plan/allActivity');

export const fetchGameplanAllActivityByUser = () =>
  fetch<{ name: string; file: string }>('get', 'game-plan/allActivityByUser');

export const fetchSuggestGameplans = (name: string) =>
  fetch<SuggestGameplansRes>(
    'get',
    `game-plan/findGamePlan${paramsToQueryString({ q: name, page: 1, limit: 12 })}`,
  );

export const fetchGameplanCustomizeTemplate = (param: CustomizeTemplateGetReq) =>
  fetch<CustomizeTemplateGetRes>(
    'get',
    `game-plan/customizeTemplate${paramsToQueryString(param)}`,
  );

export const fetchEmailCustomizeTemplate = (param: CustomizeEmailTemplateSaveReq) =>
  fetch<CustomizeEmailTemplateGetRes>(
    'get',
    `game-plan/customizeTemplate${paramsToQueryString(param)}`,
  );

export const deleteEmailCustomizeTemplate = (id: string) =>
  fetch<string>('post', 'savedEmailTemplate/customizeTemplate/delete', {
    payload: id,
  });

export const fetchGameplanCustomizeTemplateSave = (param: CustomizeTemplateSaveReq) =>
  fetch<CustomizeTemplateGetRes>('post', 'game-plan/customizeTemplate/save', {
    payload: param,
    checkRequestSize: true,
  });

export const fetchEmailCustomizeTemplateSave = (param: CustomizeEmailTemplateSaveReq) =>
  fetch<CustomizeEmailTemplateGetRes>(
    'post',
    'savedEmailTemplate/customizeTemplate/save',
    {
      payload: param,
      checkRequestSize: true,
    },
  );

export const fetchGameplanCustomizeTemplateReset = (param: CustomizeTemplateGetReq) =>
  fetch<CustomizeTemplateGetRes>(
    'post',
    `game-plan/customizeTemplate/reset${paramsToQueryString(param)}`,
  );

export const fetchGameplanCustomizeTemplatePreview = (
  payload: CustomizeTemplatePreviewReq,
) =>
  fetch<CustomizeTemplateGetRes>('post', `game-plan/customizeTemplate/preview`, {
    payload,
  });

export const fetchEmailCustomizeTemplatePreview = (
  payload: CustomizeTemplatePreviewReq,
) =>
  fetch<CustomizeEmailTemplateGetRes>('post', `game-plan/customizeTemplate/preview`, {
    payload,
  });

export const fetchPitchEmailContent = (contactId: string) =>
  fetch<string>('get', `game-plan/customizeTemplate/getPitchEmail/${contactId}`);

export const fetchDownloadEmailIntroduce = (name: string) =>
  fetch<{ name: string; file: string }>(
    'get',
    `game-plan/downloadEmailIntroduce/${name}`,
  );

export const fetchGameplanEmailAnalytics = (gameplanId: string) =>
  fetch<Gameplan>('get', `game-plan/email-analytics/${gameplanId}`);

export const fetchGameplanEmailPreview = (payload: GameplanEmailPreviewReq) =>
  fetch<string>('post', 'game-plan/emailPreview', { payload });

export const fetchDownloadContactsTemplate = () =>
  fetch<{ name: string; file: string }>('get', 'team/downloadImportContactTemplate');

export const fetchUploadContactsXLSX = (file: File) => {
  const data = new FormData();
  data.append('file', file, file?.name);

  return fetch<UploadContactsXLSXRes>(
    'post',
    'game-plan/uploadContactsImport',
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  );
};

export const fetchSaveContactsXLSX = (
  payload: UploadContactsReq,
): Promise<UploadContactsRes> => {
  return fetch<UploadContactsRes>('post', 'game-plan/addCustomContactsImport', {
    payload,
  });
};
