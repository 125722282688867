import { ConfirmModal } from 'components/Modals';
import Pagination from 'components/Pagination';
import TotalRow from 'components/TotalRow';
import { UIButton, UISpinner } from 'components/UI';
import {
  useDownloadFile,
  useModal,
  useOperation,
  useTableSelect,
  useSortStatus,
} from 'hooks/common';
import {
  IssueMappingSurveyData,
  IssueMappingSurveyListReq,
} from 'interfaces/issueMapping';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchIssueMappingExportResult } from 'store/ducks/issueMapping/api';
import {
  fetchIssueMappingSurveyCreate,
  fetchIssueMappingSurveyDelete,
  fetchIssueMappingSurveyList,
  fetchIssueMappingSurveyUpdate,
} from 'store/ducks/issueMapping/operations';
import { getIssueMappingSurveyList } from 'store/ducks/issueMapping/selectors';
import styled from 'styled-components';
import EmployeeSurveyModal from './EmployeeSurveyModal';
import queryString from 'query-string';
import SurveysTable from './SurveysTable';

const SurveysContent = () => {
  const { sortStatus, handleSwitch, setSortStatus } = useSortStatus([], {
    isSort: false,
  });
  const [row, setRow] = useState<IssueMappingSurveyData | undefined>();

  const [onFetchIssueMappingSurveyCreate] = useOperation(fetchIssueMappingSurveyCreate);
  const [onFetchIssueMappingSurveyUpdate] = useOperation(fetchIssueMappingSurveyUpdate);
  const [onFetchIssueMappingSurveyDelete] = useOperation(fetchIssueMappingSurveyDelete);

  const { items, meta } = useSelector(getIssueMappingSurveyList);
  const [onFetchIssueMappingSurveyList, , loading] = useOperation(
    fetchIssueMappingSurveyList,
  );

  const downloadFile = useDownloadFile(fetchIssueMappingExportResult);

  const {
    isSelectedAll,
    selectedIds,
    handleSelectAll,
    handleSelectItem,
    handleClearSelected,
  } = useTableSelect({ data: items });

  const handleSearch = (params?: Partial<IssueMappingSurveyListReq>) => {
    const searchParams: IssueMappingSurveyListReq = {
      page: 1,
      orderType: sortStatus.status,
      order: sortStatus.name,
      ...params,
    };

    onFetchIssueMappingSurveyList(searchParams);
  };

  useEffect(() => {
    if (!window.location.search) return handleSearch();
    const urlSearch = (queryString.parse(window.location.search) as unknown) as Partial<
      IssueMappingSurveyListReq
    >;

    urlSearch.order &&
      setSortStatus({ name: urlSearch.order, status: urlSearch.orderType || '' });
    handleSearch(urlSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!sortStatus?.name) return;
    handleSearch({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  const [showDeleteModal, hideDeleteModal] = useModal(() => {
    const deleteHandler = () => {
      onFetchIssueMappingSurveyDelete(selectedIds).then(() => {
        handleSearch({ page: Number(meta.currentPage) || 1 });
      });
      handleClearSelected();
      hideDeleteModal();
    };

    return (
      <ConfirmModal
        name={'Delete'}
        textAccessBtn="Delete"
        description={`Are you sure you want to delete this Survey?`}
        accessHandler={deleteHandler}
        cancelHandler={hideDeleteModal}
        onClose={hideDeleteModal}
        hideCloseIcon
      />
    );
  }, [selectedIds, meta]);

  const [showEmployeeSurveyModal, hideEmployeeSurveyModal] = useModal(() => {
    return (
      <EmployeeSurveyModal
        survey={row}
        handleAccess={params => {
          if (!params.id) {
            onFetchIssueMappingSurveyCreate(params).then(() => {
              handleSearch({ page: 1 });
            });
            hideEmployeeSurveyModal();
            return;
          }
          onFetchIssueMappingSurveyUpdate(params).then(() => {
            handleSearch({ page: 1 });
          });
          hideEmployeeSurveyModal();
        }}
        onClose={() => {
          setRow(undefined);
          hideEmployeeSurveyModal();
        }}
        hideCloseIcon
      />
    );
  }, [row]);

  const handleEditRow = (row: IssueMappingSurveyData) => {
    setRow(row);
    showEmployeeSurveyModal();
  };

  if (loading) return <Spinner />;

  return (
    <>
      <TotalRow
        maxWidth="1138px"
        title={`${meta.totalItems} Survey${meta.totalItems > 1 ? 's' : ''}`}
      >
        <UIButton
          title="Create Employee Survey"
          modifiers="primary"
          handleClick={showEmployeeSurveyModal}
        />
      </TotalRow>
      {items && items.length ? (
        <>
          <PaginationWrapper>
            <UIButton
              style={{ marginRight: '40px' }}
              modifiers={'danger'}
              title="Delete"
              disabled={!selectedIds.length}
              handleClick={showDeleteModal}
            />
            <Pagination
              total={meta.totalItems}
              pageLimit={Number(meta.itemsPerPage)}
              page={Number(meta.currentPage)}
              submitHandler={page => handleSearch({ page })}
            />
          </PaginationWrapper>
          <SurveysTable
            selectedAll={isSelectedAll}
            selectedIds={selectedIds}
            onCheckAll={handleSelectAll}
            onCheckRow={handleSelectItem}
            sortStatus={sortStatus}
            onSort={handleSwitch}
            onDownloadReport={downloadFile}
            rows={items}
            onEditRow={handleEditRow}
          />
        </>
      ) : (
        <EmptyContent>
          There are currently no Surveys available.
          <br /> Please click Create Employee Survey to get started.
        </EmptyContent>
      )}
    </>
  );
};

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
`;

const EmptyContent = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  margin-top: 268px;
`;

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 450px);
`;

export default SurveysContent;
