import React, { useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { goalAlignmentOptions } from 'constants/goalAlignment';
import {
  Root,
  Header,
  HeaderInfo,
  CompanyTitle,
  CompanyTitleWrapper,
  CompanyType,
  ContactsItem,
  ContactsLink,
  ContactsIcon,
  AlignmentList,
  AlignmentItem,
  ImageEmpty,
  EmptyAlignment,
  LoadImage,
  RightActions,
  EditBtn,
  DescriptionWrapper,
  Description,
  ReportedWrapper,
  ReportedRow,
  ReportedItem,
  ReportedTitle,
  ReportedValue,
  SectionRow,
  LinkGroup,
  ContactMoreLink,
  ContactsInner,
  ContactsWrapper,
} from 'pages/organizationUser/companies/Company/styled';
import { SearchCompany } from 'interfaces/companies';
import SocialMediaLinks from 'pages/organizationUser/companies/Company/SocialMediaLinks';
import { useIsCompanyUser, useOperation } from 'hooks/common';
import { parseLink } from 'utils';
import { StateProps } from 'store/interfaces';
import PhoneIcon from 'assets/images/icons/phone.svg';
import MapPinIcon from 'assets/images/icons/map-pin.svg';
import WebSiteIcon from 'assets/images/icons/website.svg';
import EmptyCompany from 'assets/images/icons/empty-company.svg';
import { useHistory, useParams } from 'react-router';
import { selectUser } from 'store/ducks/user/selectors';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { fetchCompany } from 'store/ducks/companies/operations';
import { getCompanyById } from 'store/ducks/companies/selectors';
import { ContentBlockWrapper } from 'layouts';
import Jumbotron from 'components/Jumbotron';
import styled from 'styled-components';
import { ShowMoreText } from 'components/ShowMoreText';
import { getFullName, moneyFormatter } from 'utils/formatters';

const ROIViewCompany = () => {
  const [onFetchCompany, isLoading] = useOperation(fetchCompany);

  const params: { companyId: string } = useParams();
  const company = useSelector<StateProps, SearchCompany>(
    getCompanyById(params.companyId),
  );

  const user = useSelector(selectUser);
  const isCompanyUser = useIsCompanyUser(user);

  const { push } = useHistory();

  useEffect(() => {
    const id = params.companyId;
    onFetchCompany(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const {
    logo,
    name,
    industries,
    socialMediaUrls,
    revenue,
    employeeCount,
    phone,
    website,
    street,
    city,
    state,
    country,
    zipCode,
    sdgGoals,
    typesOfInvestment,
    crsFocus,
    descriptionList,
    contacts,
    id,
    charitablePartners,
    cashContributions,
    employeeContributions,
    annualContributions,
    inKindContributions,
  } = company || {};
  return (
    <ContentBlockWrapper
      loading={isLoading}
      header={<Jumbotron name={company?.name} back />}
    >
      <Root>
        <Header>
          <HeaderInfo>
            {logo ? (
              <LoadImage imgKey={logo} />
            ) : (
              <ImageEmpty>
                <ReactSVG src={EmptyCompany} />
              </ImageEmpty>
            )}

            <div>
              <CompanyTitleWrapper>
                <CompanyTitle>{name}</CompanyTitle>
              </CompanyTitleWrapper>

              <CompanyType>
                {industries && industries.length > 0
                  ? industries.join(', ')
                  : 'No information'}
              </CompanyType>
              {socialMediaUrls && socialMediaUrls.length > 0 && (
                <SocialMediaLinks urls={socialMediaUrls} />
              )}
            </div>
          </HeaderInfo>
          <RightActions>
            {isCompanyUser && (
              <EditBtn
                handleClick={() => push(`/profile/edit`)}
                modifiers="secondary"
                title="Edit"
              />
            )}
          </RightActions>
        </Header>

        <Content>
          <LimitWidth>
            {descriptionList && descriptionList.length > 0 && (
              <Section>
                <SectionTitle>Business Description Products</SectionTitle>
                <SectionSubTitle>Description</SectionSubTitle>
                <DescriptionWrapper>
                  <ShowMoreText lines={3}>
                    {typeof descriptionList === 'string' ? (
                      <Description>{descriptionList}</Description>
                    ) : (
                      descriptionList.map((item, index) => (
                        <Description key={index}>{item.description}</Description>
                      ))
                    )}
                  </ShowMoreText>
                </DescriptionWrapper>
              </Section>
            )}

            {(revenue || employeeCount) && (
              <Section>
                <SectionSubTitle>Reported</SectionSubTitle>
                <ReportedWrapper>
                  <ReportedRow>
                    <ReportedItem>
                      <ReportedTitle>Revenue Reported</ReportedTitle>
                      <ReportedValue>
                        {revenue
                          ? Number(revenue) === 1000000000
                            ? moneyFormatter.from(Number(1000000000), { symbol: '$ ' }) +
                              '+'
                            : moneyFormatter.from(Number(revenue), { symbol: '$ ' })
                          : '-'}
                      </ReportedValue>
                    </ReportedItem>

                    <ReportedItem>
                      <ReportedTitle>Employees Reported</ReportedTitle>
                      <ReportedValue>
                        {employeeCount
                          ? Number(employeeCount) === 10000
                            ? moneyFormatter.from(Number(10000), { symbol: '' }) + '+'
                            : moneyFormatter.from(Number(employeeCount), { symbol: '' })
                          : '-'}
                      </ReportedValue>
                    </ReportedItem>
                  </ReportedRow>
                </ReportedWrapper>
              </Section>
            )}
          </LimitWidth>

          {sdgGoals && sdgGoals.length > 0 && (
            <Section>
              <SectionTitle>SDG Goal Alignment</SectionTitle>
              {sdgGoals && sdgGoals.length > 0 ? (
                <AlignmentList>
                  {[...sdgGoals]
                    .map(item => goalAlignmentOptions[item])
                    .filter(item => item)
                    .sort((a, b) => a.id - b.id)
                    .map(item => (
                      <AlignmentItem key={item.id} src={item.img} alt={item.title} />
                    ))}
                </AlignmentList>
              ) : (
                <EmptyAlignment>No selected goal</EmptyAlignment>
              )}
            </Section>
          )}

          <LimitWidth>
            {contacts && contacts.length > 0 && (
              <Section>
                <Contacts>
                  <SectionRow>
                    <SectionSubTitle>Contacts</SectionSubTitle>
                    <LinkGroup>
                      <ContactMoreLink to={`/company-contacts/${id}`}>
                        see more
                      </ContactMoreLink>
                    </LinkGroup>
                  </SectionRow>

                  {contacts.slice(0, 3).map(item => (
                    <ContactsRow key={item.id}>
                      <div className="name">
                        <div>{getFullName(item.firstName, item.lastName)}</div>
                        <div>{item.jobTitle}</div>
                      </div>
                      <div className="phone">{item.phone}</div>
                      <div className="email">{item.email}</div>
                    </ContactsRow>
                  ))}
                </Contacts>
              </Section>
            )}
            <Section>
              <SectionSubTitle>Company Contacts</SectionSubTitle>
              <ContactsWrapper>
                <ContactsInner>
                  <ContactsItem>
                    <ContactsIcon src={WebSiteIcon} />
                    {website ? (
                      <ContactsLink href={parseLink(website)} target="_blank">
                        {website}
                      </ContactsLink>
                    ) : (
                      <p>No website</p>
                    )}
                  </ContactsItem>

                  <ContactsItem>
                    <ContactsIcon src={PhoneIcon} />

                    {phone ? (
                      <ContactsLink href={`tel:${phone}`}>{phone}</ContactsLink>
                    ) : (
                      <p>No phone number</p>
                    )}
                  </ContactsItem>
                </ContactsInner>

                <ContactsItem>
                  <ContactsIcon src={MapPinIcon} />
                  <p>
                    {street && `${street} `}
                    {city && `${city} `}
                    {state && `${state} `}
                    {country && `${country} `}
                    {zipCode && `${zipCode}`}
                    {!street && !city && !state && !country && !zipCode && 'No Address'}
                  </p>
                </ContactsItem>
              </ContactsWrapper>
            </Section>

            {(typesOfInvestment || crsFocus) && (
              <Section>
                <SectionTitle>Social Impact</SectionTitle>
                {typesOfInvestment && (
                  <>
                    <SectionSubTitle>Type of Investment</SectionSubTitle>
                    <SectionValue>
                      {typesOfInvestment?.map(item => item.title).join(', ')}
                    </SectionValue>
                  </>
                )}
                <SectionSubTitle>CRS Focus</SectionSubTitle>
                {crsFocus && (
                  <SectionValue>
                    {crsFocus?.map(item => item.name).join(', ')}
                  </SectionValue>
                )}
              </Section>
            )}

            {(charitablePartners ||
              cashContributions ||
              annualContributions ||
              inKindContributions) && (
              <Section>
                <SectionTitle>Charitable partners</SectionTitle>
                <SectionValue>{charitablePartners?.join(', ')}</SectionValue>

                <SectionSubTitle>Contributions</SectionSubTitle>
                <ReportedWrapper>
                  <ReportedRow>
                    <ReportedItem>
                      <ReportedTitle>Cash Contributions</ReportedTitle>
                      <ReportedValue>
                        {cashContributions
                          ? moneyFormatter.from(Number(cashContributions), {
                              symbol: '$ ',
                            })
                          : '-'}
                      </ReportedValue>
                    </ReportedItem>

                    <ReportedItem>
                      <ReportedTitle>Employee Contributions</ReportedTitle>
                      <ReportedValue>
                        {employeeContributions
                          ? moneyFormatter.from(Number(employeeContributions), {
                              symbol: '$ ',
                            })
                          : '-'}
                      </ReportedValue>
                    </ReportedItem>
                  </ReportedRow>
                  <ReportedRow>
                    <ReportedItem>
                      <ReportedTitle>Total Social Contributions</ReportedTitle>
                      <ReportedValue>
                        {annualContributions
                          ? moneyFormatter.from(Number(annualContributions), {
                              symbol: '$ ',
                            })
                          : '-'}
                      </ReportedValue>
                    </ReportedItem>

                    <ReportedItem>
                      <ReportedTitle>In-Kind Contributions</ReportedTitle>
                      <ReportedValue>
                        {inKindContributions
                          ? moneyFormatter.from(Number(inKindContributions), {
                              symbol: '$ ',
                            })
                          : '-'}
                      </ReportedValue>
                    </ReportedItem>
                  </ReportedRow>
                </ReportedWrapper>
              </Section>
            )}
          </LimitWidth>
        </Content>
      </Root>
    </ContentBlockWrapper>
  );
};

const Content = styled.div`
  padding: 32px 42px;
  background-color: white;
`;

const Section = styled.div`
  margin-bottom: 46px;
`;

const LimitWidth = styled.div`
  width: 650px;
`;

const Contacts = styled.div`
  width: 650px;
`;

const ContactsRow = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 150%;
  padding: 16px 0 12px;
  border-bottom: 1px solid #ebebeb;

  div {
    padding-right: 8px;
  }

  .name {
    width: 190px;

    & > div:nth-child(1) {
      font-weight: 500;
    }

    & > div:nth-child(2) {
      color: #737373;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .phone {
    width: 140px;
  }
`;

const SectionTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  margin-bottom: 16px;
`;

const SectionSubTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 8px;
`;

const SectionValue = styled.div`
  margin-bottom: 12px;
`;

export default ROIViewCompany;
