import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import CannotSavedIcon from 'assets/images/cannot-saved.svg';

const CannotSavedModal = ({ onClose, ...modalProps }: InjectedProps) => {
  useLockBodyScroll();

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      hideCloseIcon
      propsBodyCss={{ padding: 0 }}
      style={{
        width: '100%',
        maxWidth: '333px',
      }}
    >
      <Header>
        <Icon src={CannotSavedIcon} />
      </Header>
      <Body>
        <Title>This list cannot be saved.</Title>
        <Desc>Please set up filters.</Desc>

        <Btn modifiers="third" title="OK" handleClick={onClose} />
      </Body>
    </UIModal>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 174px;
  background: #f2f2f2;
`;

const Body = styled.div`
  padding: 24px;
`;

const Icon = styled(ReactSVG)`
  margin-right: 37px;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin-bottom: 8px;
  text-align: center;
`;

const Desc = styled.div`
  font-size: 12px;
  line-height: 150%;
  color: #bfbfbf;
  margin-bottom: 32px;
  text-align: center;
`;

export const ContactLink = styled.a`
  color: var(--blue);
  &:hover {
    text-decoration: underline;
  }
`;

const Btn = styled(UIButton)`
  display: block;
  width: 100%;
  font-size: 12px;
`;

export default CannotSavedModal;
