import React, { useCallback } from 'react';
import { UIButton } from 'components/UI';
import styled from 'styled-components';
import { FormTitle } from 'components/UI/form';
import { useHistory } from 'react-router';
import { useOperation } from 'hooks/common';
import { fetchAddNoContact } from 'store/ducks/team/operations';
import ContactForm from 'components/Forms/ContactForm';

interface Company {
  id: string;
  name?: string;
  website?: string;
  country?: string;
}

const NoContactTemplate = ({ onAddSuccess }) => {
  const [onSubmit] = useOperation(fetchAddNoContact);

  const submit = useCallback(
    async (values: Company) => {
      const result = await onSubmit(values);
      onAddSuccess(); // Call the callback after successful submission
    },
    [onAddSuccess],
  );

  return (
    <Root>
      <TitleAndButtonWrapper>
        <FormTitle>No Contact List</FormTitle>
      </TitleAndButtonWrapper>
      <ContactForm onSubmit={submit} />
    </Root>
  );
};

// Adjusted Btn for a less chunky appearance and aligned width
const Btn = styled(UIButton)`
  margin-top: 16px;
  height: 40px; // Adjust height as needed
  padding: 0 20px; // Adjust padding for less chunkiness
  align-self: center; // Align button vertically in the flex container
`;

// Flex container to align title and button
const TitleAndButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between; // Aligns children (title and button) on opposite ends
  align-items: center; // Centers children vertically
  width: 100%; // Ensure the container takes full width to align items properly
`;

const Root = styled.div`
  background: #ffffff;
  border-radius: 4px;
  padding: 24px 24px;
  margin-bottom: 24px;
`;

export default NoContactTemplate;
