import React from 'react';
import { Form, FormRenderProps, FieldRenderProps } from 'react-final-form';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import { UIButton } from 'components/UI';
import { maxLength80, required } from 'utils/validation';
import styled from 'styled-components';
import { PitchValues } from 'interfaces/common';
import FieldUploadFile from 'components/FormFields/FieldUploadFile';
import FieldWYSIWYGEditor from 'components/FormFields/FieldWYSIWYGEditor';
import { Field } from 'components/FinalForm';
import { FieldTextInput } from 'components/FormFields';
import { composeValidators } from 'utils';

export interface FormProps {
  onSubmit: (values: PitchValues) => OnSubmitReturnType;
  onSendTestEmail: (values: PitchValues) => OnSubmitReturnType;
  initialValues?: PitchValues;
  closeHandler: () => void;
  onClickPreviewEmail: (
    body: PitchValues['text'],
    subject: PitchValues['subject'],
  ) => void;
  onClickTemplateSelect: (subject: string, text: string) => void;
}

const PitchForm = ({
  onSubmit,
  initialValues,
  closeHandler,
  onSendTestEmail,
  onClickPreviewEmail,
  onClickTemplateSelect,
}: FormProps) => {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        submitting,
        hasValidationErrors,
        values,
      }: FormRenderProps<PitchValues>) => {
        return (
          <form>
            <HeaderPitchInner>
              <HeaderText>BCC</HeaderText>
              <Field name="bcc">
                {({ input }: FieldRenderProps<string>) => (
                  <UserInfo value={input.value} onChange={input.onChange} />
                )}
              </Field>
            </HeaderPitchInner>

            <HeaderPitchInner>
              <HeaderText>Subject</HeaderText>
              <Field
                name="subject"
                component={FieldTextInput}
                validate={composeValidators<string>(required, maxLength80)}
              />
            </HeaderPitchInner>

            <Field
              name="text"
              component={FieldWYSIWYGEditor}
              placeholder="Enter text"
              validate={required}
              label="Text"
              height={330}
            />

            <Field
              name="attachments"
              label="Attachs"
              component={FieldUploadFile}
              category="pitch"
            />

            <BtnGroup>
              <div>
                <Button
                  modifiers="secondary"
                  title="Cancel"
                  handleClick={closeHandler}
                  type="button"
                />
                <Button
                  modifiers="secondary"
                  title="Send Test Email"
                  handleClick={() => onSendTestEmail(values)}
                  type="button"
                />
                <Button
                  modifiers="secondary"
                  title="Preview"
                  handleClick={() => onClickPreviewEmail(values.text, values.subject)}
                  type="button"
                />
              </div>
              <Button
                modifiers="primary"
                title="Send"
                handleClick={handleSubmit}
                type="submit"
                disabled={submitting || hasValidationErrors}
                isLoading={submitting}
              />
            </BtnGroup>
          </form>
        );
      }}
    />
  );
};

const BtnGroup = styled.div`
  display: flex;

  & button:not(:last-child) {
    margin-right: 9px;
  }

  & button:last-child {
    margin-left: auto;
  }
`;

const Button = styled(UIButton)`
  width: 140px;
`;

const HeaderPitchInner = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const HeaderText = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
  margin-right: 50px;
  width: 29px;
`;

const UserInfo = styled.input`
  display: flex;
  width: 100%;
  max-width: 271px;
  justify-content: space-between;
  background: #e8e8e8;
  border-radius: 13px;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  padding: 4px 19px;
  cursor: default;
  border: none;
`;

export default PitchForm;
