import * as types from './types';
import {
  SearchCompany,
  Companies,
  CompanyContact,
  Scoops,
  News,
  ResCompanyMatch,
  SimilaritySearchCompany,
  CompaniesContacts,
  SubscriptionCompany,
  UpdateCompanyProfileRes,
  CompaniesOnlyIdName,
} from 'interfaces/companies';
import { User } from 'interfaces/auth';

export const setSearchCompanies = (companies: SearchCompany[]) =>
  ({
    type: types.SET_SEARCH_COMPANIES,
    payload: companies,
  } as const);

export const setCompanies = (companies: Companies) =>
  ({
    type: types.SET_COMPANIES,
    payload: companies,
  } as const);

export const setCompaniesOnlyIdName = (companies: CompaniesOnlyIdName) =>
  ({
    type: types.SET_COMPANIES_ONLY_ID_NAME,
    payload: companies,
  } as const);

export const setCompany = (company: SearchCompany) =>
  ({
    type: types.SET_COMPANY,
    payload: company,
  } as const);

export const setCompanyScoops = (scoops: Scoops[]) =>
  ({
    type: types.SET_COMPANY_SCOOPS,
    payload: scoops,
  } as const);

export const setCompanyNews = (news: News[]) =>
  ({
    type: types.SET_COMPANY_NEWS,
    payload: news,
  } as const);

export const setContacts = (contacts: CompanyContact[]) =>
  ({
    type: types.SET_CONTACTS,
    payload: contacts,
  } as const);

export const setCompanyFavorite = (id: string) =>
  ({
    type: types.SET_COMPANY_FAVORITE,
    payload: id,
  } as const);

export const setCompaniesFavorites = (companies: Companies) =>
  ({
    type: types.SET_COMPANIES_FAVORITES,
    payload: companies,
  } as const);

export const setCompaniesBlocked = (companies: Companies) =>
  ({
    type: types.SET_COMPANIES_BLOCKED,
    payload: companies,
  } as const);

export const setCompanyBlocked = (id: string) =>
  ({
    type: types.SET_COMPANY_BLOCKED,
    payload: id,
  } as const);

export const setCompaniesTopMatch = (companies: SearchCompany[]) =>
  ({
    type: types.SET_COMPANIES_TOPMATCH,
    payload: companies,
  } as const);

export const setCompanyMatchDetail = (MatchDetail: ResCompanyMatch) =>
  ({
    type: types.SET_COMPANY_MATCHDETAIL,
    payload: MatchDetail,
  } as const);

export const setContactsUsedIn60Days = (
  companyId: string[],
  contactId: string[],
  usedIn60Days: CompanyContact['usedIn60Days'],
) =>
  ({
    type: types.SET_CONTACTS_USED_IN_60DAYS,
    payload: {
      companyId,
      contactId,
      usedIn60Days,
    },
  } as const);

export const setCompanyCurrent = (company: SearchCompany) =>
  ({ type: types.SET_COMPANY_CURRENT, payload: company } as const);

export const setSimilaritySearchCompany = (companies: SimilaritySearchCompany[]) =>
  ({
    type: types.SET_SIMILARITYSEARCH,
    payload: companies,
  } as const);

export const setCompanyCurrentContacts = (contacts: CompanyContact[]) =>
  ({
    type: types.SET_COMPANY_CURRENT_CONTACTS,
    payload: contacts,
  } as const);
export const setCompanyCurrentContactCreate = (contacts: CompanyContact) =>
  ({
    type: types.CREATE_COMPANY_CURRENT_CONTACT,
    payload: contacts,
  } as const);
export const setCompanyCurrentContactUpdate = (contacts: CompanyContact) =>
  ({
    type: types.UPDATE_COMPANY_CURRENT_CONTACT,
    payload: contacts,
  } as const);
export const setCompanyCurrentContactDelete = (id: string) =>
  ({
    type: types.DELETE_COMPANY_CURRENT_CONTACT,
    payload: id,
  } as const);

export const clearSimilaritySearchCompany = () =>
  ({
    type: types.CLEAR_SIMILARITYSEARCHCOMPANY,
  } as const);

export const getTeamMembers = (teamMembers: User[]) =>
  ({
    type: types.GET_TEAM_MEMBERS,
    payload: teamMembers,
  } as const);

export const deleteTeamMembers = (id: string) =>
  ({
    type: types.DELETE_TEAM_MEMBERS,
    payload: id,
  } as const);

export const setLastMonthCompanies = (companies: Companies) =>
  ({
    type: types.SET_LAST_MONTH_COMPANIES,
    payload: companies,
  } as const);

export const setLastMonthCompanyMeeting = (id: string) =>
  ({
    type: types.SET_LAST_MONTH_COMPANY_MEETING,
    payload: id,
  } as const);

export const setLastMonthCompanyPartnership = (id: string) =>
  ({
    type: types.SET_LAST_MONTH_COMPANY_PARTNERSHIP,
    payload: id,
  } as const);

export const setLastMonthCompanyLike = (id: string) =>
  ({
    type: types.SET_LAST_MONTH_COMPANY_LIKE,
    payload: id,
  } as const);

export const setFavoritesCompanyMeeting = (id: string) =>
  ({
    type: types.SET_FAVORITES_COMPANY_MEETING,
    payload: id,
  } as const);

export const setFavoritesCompanyPartnership = (id: string) =>
  ({
    type: types.SET_FAVORITES_COMPANY_PARTNERSHIP,
    payload: id,
  } as const);

export const setCompaniesContacts = (contacts: CompaniesContacts) =>
  ({
    type: types.SET_COMPANIESE_CONTACTS,
    payload: contacts,
  } as const);

export const setCompanySubscription = (subscription: SubscriptionCompany) =>
  ({
    type: types.SET_SUBSCRIPTION,
    payload: subscription,
  } as const);

export const updateCompanyProfile = (profile: UpdateCompanyProfileRes) =>
  ({
    type: types.UPDATE_COMPANY_PROFILE,
    payload: profile,
  } as const);
