import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { NoSubscriptionsCompanyWrapper } from 'layouts';
import Route from './Route';
import NoSubscriptionCompany from 'pages/subscriptionCompany';

const NoSubscriptionsCompanyRoute = () => {
  return (
    <Switch>
      <Route path="/" layout={NoSubscriptionsCompanyWrapper}>
        <Route path="/no-subscription" component={NoSubscriptionCompany} />
        <Route path="/" component={() => <Redirect to="/no-subscription" />} />
      </Route>
    </Switch>
  );
};

export default NoSubscriptionsCompanyRoute;
