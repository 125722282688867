import { DoughnutChart } from 'components/Charts';
import React from 'react';
import styled from 'styled-components';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';

interface Props {
  employeeLoyalty: number;
  customerLoyalty: number;
  themeColor1?: string;
  themeColor2?: string;
}

const LoyaltyMetric = ({
  employeeLoyalty,
  customerLoyalty,
  themeColor1,
  themeColor2,
}: Props) => {
  return (
    <MetricRoot>
      <MetricTitle>Loyalty</MetricTitle>
      <Content>
        <ChartWrapper>
          <DoughnutChart
            value={employeeLoyalty}
            fontSize={14}
            fontColor="#343A40"
            bgColor="#F3F3F4"
            circularColor={themeColor1 || '#2BAEE0'}
            circularWidth={6}
            size={70}
          />
          Employee Loyalty
        </ChartWrapper>
        <ChartWrapper>
          <DoughnutChart
            value={customerLoyalty}
            fontSize={14}
            fontColor="#343A40"
            bgColor="#F3F3F4"
            circularColor={themeColor2 || '#18536A'}
            circularWidth={6}
            size={70}
          />
          Consumer Loyalty
        </ChartWrapper>
      </Content>
    </MetricRoot>
  );
};

const Content = styled(MetricContent)``;

const ChartWrapper = styled.div`
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 14px;
`;

export default LoyaltyMetric;
