import React from 'react';
import styled from 'styled-components';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';
import { ROIMeltwaterData } from 'interfaces/roi';
import { format } from 'date-fns';
import TwitterSquareImg from 'assets/images/icons/twitter-square.png';

interface Props {
  data?: ROIMeltwaterData['analyticsData']['top_shared']['results'];
}

const TopRetweetedMetric = ({ data }: Props) => {
  return (
    <MetricRoot>
      <MetricTitle>Top Retweeted (Top 3)</MetricTitle>
      <MetricContent>
        {data?.map(item => (
          <NewsRow key={item.external_id}>
            <NewsInfoTitle onClick={() => window.open(item.url)}>
              <NewsInfoLogo src={TwitterSquareImg} />
              {item.author.display_name}
            </NewsInfoTitle>
            <NewsInfoDesc>{item?.document_title}</NewsInfoDesc>
            <NewsInfoFooter>
              <NewsInfoDate>
                {format(new Date(item.created_at), 'd MMM y, h:ss')}
              </NewsInfoDate>
              <NewsActionGroup>
                <NewsAction>
                  {/* {formatNumber(item.author.twitter.followers)} Followers */}
                </NewsAction>
              </NewsActionGroup>
            </NewsInfoFooter>
          </NewsRow>
        ))}
      </MetricContent>
    </MetricRoot>
  );
};

const NewsRow = styled.div`
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: 6px;
  }
`;

const NewsInfoTitle = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  color: var(--black);
  align-items: center;
`;

const NewsInfoLogo = styled.img`
  margin-right: 10px;
  height: 12px;
  width: 12px;
`;

const NewsInfoDesc = styled.div`
  color: var(--darkGray);
  font-size: 12px;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 22px;
  margin-bottom: 2px;
`;

const NewsInfoFooter = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 1.2;
  padding-bottom: 2px;
  border-bottom: 1px solid #e8e8e8;
  margin-left: 22px;
`;

const NewsInfoDate = styled.div`
  color: var(--darkGray);
`;

const NewsActionGroup = styled.div`
  color: var(--blue);
  display: flex;
  margin-left: auto;
`;

const NewsAction = styled.div`
  margin-left: 10px;
`;

export default TopRetweetedMetric;
