import React, { useState } from 'react';
import styled from 'styled-components';
import { UIButton, UICheckbox, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { SearchCompany } from 'interfaces/companies';
import {
  UITable,
  UITableBody,
  UITableHead,
  UITableHeadCell,
  UITableHeadRow,
  UITableRow,
  UITableRowCell,
  UITableWrapper,
} from 'components/UI/table/styled';

interface Props extends InjectedProps {
  onAccess: (componies: SearchCompany) => void;
  list: SearchCompany[];
}

interface TableRowProps {
  row: SearchCompany;
  check: boolean;
  onCheckRow: (company: SearchCompany) => void;
}

const TableRowWrapper = ({ row, check, onCheckRow }: TableRowProps) => {
  const { name, website, city, state, id } = row;

  return (
    <UITableRow>
      <UITableRowCell style={{ width: `${70}px` }}>
        <UICheckbox check={check} handleClick={() => onCheckRow(row)} />
      </UITableRowCell>
      <UITableRowCell>{name + (id ? '' : '(new)')}</UITableRowCell>
      <UITableRowCell>{website}</UITableRowCell>
      <UITableRowCell>
        {city ? `${city}, ` : ''}
        {state ? state : ''}
      </UITableRowCell>
    </UITableRow>
  );
};

const ExistingPartnerModal = ({ onClose, onAccess, list, ...modalProps }: Props) => {
  useLockBodyScroll();

  const [company, setCompany] = useState<SearchCompany | undefined>(undefined);

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 24px' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '888px',
        overflow: 'unset',
        minHeight: '400px',
      }}
    >
      <Title>Existing Company Found</Title>
      <SecondaryTitle>
        We have found a match in our system based on the information you provided. Please
        make a selection below to continue.
      </SecondaryTitle>
      <TableWrapper>
        <UITable>
          <colgroup>
            <col style={{ width: `${70}px` }} />
            <col style={{ width: `${200}px` }} />
            <col style={{ width: `${200}px` }} />
            <col style={{ width: `${150}px` }} />
            <col />
          </colgroup>
          <UITableHead>
            <UITableHeadRow>
              <UITableHeadCell key="checkbox" style={{ width: `${70}px` }} />
              <UITableHeadCell>Company</UITableHeadCell>
              <UITableHeadCell>Webiste</UITableHeadCell>
              <UITableHeadCell>Location</UITableHeadCell>
              <UITableHeadCell></UITableHeadCell>
            </UITableHeadRow>
          </UITableHead>
          <UITableBody>
            {list.map(item => (
              <TableRowWrapper
                key={item.id}
                row={item}
                check={item.id === company?.id}
                onCheckRow={row =>
                  company?.id === row.id ? setCompany(undefined) : setCompany(row)
                }
              />
            ))}
          </UITableBody>
        </UITable>
      </TableWrapper>
      <BtnGroup>
        <Btn modifiers="secondary" title="Cancel" handleClick={onClose} />
        <Btn
          modifiers="primary"
          title="Continue"
          disabled={!company}
          handleClick={() => company && onAccess(company)}
        />
      </BtnGroup>
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 8px;
  text-align: center;
`;

const SecondaryTitle = styled.h3`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  margin: 0px auto 26px;
  text-align: center;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
  justify-self: flex-end;
`;

const Btn = styled(UIButton)`
  display: block;
  width: 87px;
  font-size: 14px;
  padding: 7px 0;
`;

const TableWrapper = styled(UITableWrapper)`
  height: 184px;
`;

export default ExistingPartnerModal;
