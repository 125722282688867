import React from 'react';
import styled from 'styled-components';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';

interface Props {
  constituentEngagement: number;
}

const ConstituentEngagementMetric = ({ constituentEngagement }: Props) => {
  return (
    <MetricRoot>
      <MetricTitle>Constituent Engagement</MetricTitle>
      <Content>
        <CorporateIntangibleAssetsStatusCard>
          {constituentEngagement === 0 && (
            <CorporateIntangibleAssetsStatusTitle style={{ color: '#F05658' }}>
              Low
            </CorporateIntangibleAssetsStatusTitle>
          )}
          {constituentEngagement === 10 && (
            <CorporateIntangibleAssetsStatusTitle style={{ color: '#E0A02A' }}>
              Medium
            </CorporateIntangibleAssetsStatusTitle>
          )}
          {constituentEngagement === 20 && (
            <CorporateIntangibleAssetsStatusTitle style={{ color: '#29AA26' }}>
              High
            </CorporateIntangibleAssetsStatusTitle>
          )}
          <ColorBarGroup>
            <ColorBarItem $color="#F05658" $active={constituentEngagement === 0} />
            <ColorBarItem $color="#E0A02A" $active={constituentEngagement === 10} />
            <ColorBarItem $color="#29AA26" $active={constituentEngagement === 20} />
          </ColorBarGroup>
          <ColorBarFieldTexts>
            <div>Low</div>
            <div>Medium</div>
            <div>High</div>
          </ColorBarFieldTexts>
        </CorporateIntangibleAssetsStatusCard>
      </Content>
    </MetricRoot>
  );
};

const Content = styled(MetricContent)`
  display: flex;
  justify-content: center;
`;

const CorporateIntangibleAssetsStatusCard = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  &:not(:first-child) {
    margin-top: 24px;
  }
`;

const CorporateIntangibleAssetsStatusTitle = styled.div`
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  color: #29aa26;
  margin-bottom: 16px;
`;

const ColorBarGroup = styled.div`
  display: flex;

  & > div:first-child {
    border-radius: 99em 0 0 99em;
  }

  & > div:last-child {
    border-radius: 0 99em 99em 0;
  }
`;

const ColorBarItem = styled.div<{ $color: string; $active: boolean }>`
  width: 80px;
  height: 14px;
  position: relative;
  margin-bottom: 8px;

  ${({ $color }) => $color && `background: ${$color};`}

  &::after {
    content: '';
    visibility: ${({ $active }) => ($active ? 'visible' : 'hidden')};
    position: absolute;
    top: -12px;
    left: calc(50% - 3px);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    ${({ $color }) => $color && `background: ${$color};`}
  }
`;

const ColorBarFieldTexts = styled.div`
  width: 240px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  justify-content: space-between;
`;

export default ConstituentEngagementMetric;
