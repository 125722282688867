import JumbotronEditTitle from 'components/JumbotronEditTitle/JumbotronEditTitle';
import { ConfirmModal } from 'components/Modals';
import { ManageForm } from 'components/roi/forms';
import Measure from 'components/roi/Measure';
import Steward from 'components/roi/Steward';
import { ROINavigationEnum } from 'constants/roi';
import { useModal, useOperation } from 'hooks/common';
import { SuggestCompany } from 'interfaces/companies';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import { debounce } from 'lodash';
import { parse } from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { fetchCompaniesSearch } from 'store/ducks/companies/operations';
import { getCompaniesSearch } from 'store/ducks/companies/selectors';
import {
  fetchROI,
  fetchROIAddCompany,
  fetchROIDelete,
  fetchROIUpdate,
} from 'store/ducks/roi/operations';
import { getROI } from 'store/ducks/roi/selectors';
import { fetchTeamMembers } from 'store/ducks/team/operations';
import { getTeamMembers } from 'store/ducks/team/selectors';
import { StateProps } from 'store/interfaces';
import styled from 'styled-components';

const ROIEdit = () => {
  const parsed = parse(window.location.search);
  const params = useParams<{ roiId: string }>();

  const { push } = useHistory();

  const [onSearchCompanies] = useOperation(fetchCompaniesSearch);
  const [onFetchROI] = useOperation(fetchROI);
  const [onFetchROIUpdate] = useOperation(fetchROIUpdate);
  const [onFetchTeamMembers] = useOperation(fetchTeamMembers);
  const [onFetchCompanyCreate] = useOperation(fetchROIAddCompany);
  const [onFetchROIDelete] = useOperation(fetchROIDelete);

  const companiesSearchList = useSelector<StateProps, SuggestCompany[]>(
    getCompaniesSearch,
  );
  const roi = useSelector(getROI);
  const teamMembers = useSelector(getTeamMembers);

  const handleSearchCompanies = (val: string) => {
    return onSearchCompanies(val);
  };

  const handleSearchCompaniesDebounce = useCallback(
    debounce(handleSearchCompanies, 800),
    [],
  );

  const [showConfirmModal, hideConfirmModal] = useModal(
    () => (
      <ConfirmModal
        name="Delete"
        description="Deleting this partnership will remove (with the inability to recover) all associated media tracking information and reporting.  Are you sure you want to delete this partnership?"
        textAccessBtn="Delete"
        textCancelBtn="Back to Page"
        accessHandler={() => {
          if (!roi) return;
          onFetchROIDelete([roi.id]);
          hideConfirmModal();
          push(`/roi?type=${ROINavigationEnum.Partnerships}`);
        }}
        onClose={hideConfirmModal}
        cancelHandler={hideConfirmModal}
      />
    ),
    [roi],
  );

  useEffect(() => {
    onFetchROI(params.roiId);
    onFetchTeamMembers(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <ContentBlockWrapper
      header={
        <JumbotronEditTitle
          name={roi?.name}
          back
          backPath={`/roi?type=${ROINavigationEnum.Partnerships}`}
          onSave={value => {
            if (!roi) return;
            onFetchROIUpdate(
              { ...roi, name: value },
              undefined,
              'Edit name successfully',
            );
          }}
          onDelete={showConfirmModal}
        />
      }
    >
      <Body>
        {roi && (
          <>
            {parsed?.type === 'Manage' && (
              <Panel>
                <ManageForm
                  roi={roi}
                  disabled={false}
                  handleGetCompanies={handleSearchCompaniesDebounce}
                  companiesSearchList={companiesSearchList}
                  teamMembers={teamMembers}
                  onSubmit={values =>
                    onFetchROIUpdate(values, undefined, 'Basic info saved successfully')
                  }
                  onCompanyCreate={values => onFetchCompanyCreate(values)}
                />
              </Panel>
            )}
            {parsed?.type === 'Steward' && (
              <Panel>
                <Steward />
              </Panel>
            )}
            {parsed?.type === 'Measure' && <Measure />}
          </>
        )}
      </Body>
    </ContentBlockWrapper>
  );
};

const Body = styled.div`
  max-width: 1095px;
`;

const Panel = styled.div`
  padding: 24px;
  background-color: #fff;
  border-radius: 6px;
  margin-top: 24px;
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  margin-bottom: 24px;
  display: flex;

  & > * {
    cursor: pointer;
  }
`;

export default ROIEdit;
