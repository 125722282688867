import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { UISpinner } from 'components/UI';
import FilterForm from 'components/Forms/FilterForm';
import { FilterData } from 'components/Forms/interfaces';
import { PAGE_WIDTH } from 'constants/common';
import { HandleSearchConfigParams } from 'pages/organizationUser/search/Search/Search';

interface Props {
  filterSubmitHandler: (
    formValues?: FilterData,
    config?: HandleSearchConfigParams,
  ) => void;
  isLoading: boolean;
  initialValues: FilterData;
  children?: ReactNode;
}

function SearchWrapper({
  children,
  filterSubmitHandler,
  isLoading,
  initialValues,
}: Props) {
  const [isVisibleFilters, setIsVisibleFilters] = useState(false);

  return (
    <Wrapper>
      <FilterForm
        onSubmit={filterSubmitHandler}
        isVisibleFilters={isVisibleFilters}
        toggleFilter={setIsVisibleFilters}
        initialValues={initialValues}
      >
        <Body>{isLoading ? <Spinner /> : <Content>{children}</Content>}</Body>
      </FilterForm>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const Body = styled.div`
  background: #f9f9f9;
  min-height: calc(100vh - 176px);
`;

const Content = styled.div`
  width: 100%;
  max-width: ${PAGE_WIDTH.LARGE}px;
  margin: 0 auto;
  padding: 32px 0 20px;
`;

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 201px);
`;

export default SearchWrapper;
