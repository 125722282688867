import { UICheckbox } from 'components/UI';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import CloseSVG from 'assets/images/icons/close.svg';
import { ReactSVG } from 'react-svg';

export type TreeData = {
  label: string;
  value: string;
  children?: TreeData;
}[];

interface RenderItemsProps {
  items: TreeData;
  selectedId: string[];
  onChecked: (id: string, isChecked: boolean) => void;
}

const checkInclude = (obj: TreeData[0], selectedValues: string[]) => {
  return obj.children
    ? obj.children.every(item => checkInclude(item, selectedValues))
    : selectedValues.includes(obj.value);
};

const RenderItems = (props: RenderItemsProps) => {
  const { items, selectedId, onChecked } = props;
  return (
    <div style={{ marginLeft: '32px' }}>
      {items.map(item => {
        const checked = item.children
          ? checkInclude(item, selectedId)
          : selectedId.includes(item.value);
        return (
          <div key={item.value}>
            <div style={{ display: 'flex', margin: '8px 0', alignItems: 'center' }}>
              <UICheckbox
                label=" "
                check={checked}
                handleClick={() => onChecked(item.value, !checked)}
              />
              {item.label}
            </div>
            {item.children && <RenderItems {...props} items={item.children} />}
          </div>
        );
      })}
    </div>
  );
};

interface Props {
  data: TreeData;
  selectedIds?: string[];
  onChange: (ids: string[]) => void;
  onSearchChange?: (search: string) => void;
}

const TreeSelect = ({
  data,
  onChange,
  selectedIds: controlledValues,
  onSearchChange,
}: Props) => {
  const [search, setSearch] = useState('');
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const filter = (data: TreeData, searchStr: string) => {
    const getChildren = (result: TreeData, curr: TreeData[0]) => {
      if (curr.label.toLowerCase().includes(searchStr.toLowerCase())) {
        result.push(curr);
        return result;
      }
      if (curr.children) {
        const children = curr.children.reduce(getChildren, []);
        if (children.length) result.push({ ...curr, children });
      }
      return result;
    };

    return data.reduce(getChildren, []);
  };

  const displayData = useMemo(() => {
    return filter(data, search);
  }, [data, search]);

  const handleCheck = (id: string, isChecked: boolean) => {
    const getOption = (options: TreeData, optionValue: string) => {
      let result: TreeData[0] | undefined;
      const findOption = (os: TreeData) => {
        for (const iterator of os) {
          if (iterator.value === optionValue) result = iterator;
          if (iterator.children) findOption(iterator.children);
        }
      };
      findOption(options);
      return result as TreeData[0];
    };

    const getOptionLeafNodeValues = (option: TreeData[0]): string[] => {
      if (!option.children) return [option.value];
      return option.children.map(item => getOptionLeafNodeValues(item)).flat(1);
    };

    const option = getOption(displayData, id);
    const ids = getOptionLeafNodeValues(option);

    if (controlledValues !== undefined) {
      if (isChecked) return onChange([...controlledValues, ...ids]);
      return onChange(controlledValues.filter(item => ![...ids].includes(item)));
    }

    if (isChecked) return setSelectedValues([...selectedValues, ...ids]);
    setSelectedValues(selectedValues.filter(item => ![...ids].includes(item)));
  };

  const handleSearch = (searchValue: string) => {
    setSearch(searchValue);
    onSearchChange?.(searchValue);
  };

  return (
    <div>
      <SearchWrapper>
        <Input
          value={search}
          onChange={e => handleSearch(e.target.value)}
          placeholder="Search"
        />
        {search && <ClearIcon src={CloseSVG} onClick={() => handleSearch('')} />}
      </SearchWrapper>
      <ListWrapper>
        <RenderItems
          items={displayData}
          selectedId={controlledValues || selectedValues}
          onChecked={handleCheck}
        />
      </ListWrapper>
    </div>
  );
};

const ListWrapper = styled.ul`
  max-height: 400px;
  overflow: auto;
  margin: 10px 0 0 -20px;
  font-size: 12px;
`;

const Input = styled.input`
  width: 100%;
  color: var(--black);
  font-size: 16px;
  line-height: 155%;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px 16px;
  outline: none;

  &:focus {
    border: 1px solid #2baee0;
  }

  &::placeholder {
    color: var(--darkGray);
  }
`;

const SearchWrapper = styled.div`
  position: relative;
`;

const ClearIcon = styled(ReactSVG)`
  position: absolute;
  top: 12px;
  right: 10px;
  cursor: pointer;

  & path {
    stroke: #000;
  }
`;

export default TreeSelect;
