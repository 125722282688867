import { UICheckbox, UIDivider, UILink } from 'components/UI';
import React, { ReactNode, useMemo, useState } from 'react';
import { FieldInputProps } from 'react-final-form';
import styled from 'styled-components';
import { checkContainsAnotherArrary, removeDuplicates } from 'utils';

interface Props {
  title: string;
  input: FieldInputProps<string[], HTMLElement>;
  listenMetricElIds: string[];
  children?: ReactNode;
}

const CheckBoxCollapsible = ({ title, input, listenMetricElIds, children }: Props) => {
  const [isCollapse, setCollapse] = useState(false);

  const check = useMemo(
    () => checkContainsAnotherArrary(input.value, listenMetricElIds),
    [input.value, listenMetricElIds],
  );

  const handleCheck = check =>
    check
      ? input.onChange(removeDuplicates([...input.value, ...listenMetricElIds]))
      : input.onChange(
          input.value.filter(
            item => !([...listenMetricElIds] as string[]).includes(item),
          ),
        );

  return (
    <Root>
      <Title>
        <UICheckbox label={title} check={check} handleClick={() => handleCheck(!check)} />
        <UILink modifier="blue" onClick={() => setCollapse(!isCollapse)}>
          {isCollapse ? 'see more' : 'see less'}
        </UILink>
      </Title>
      <Divider type="horizontal" />
      <Content
        style={{
          display: isCollapse ? 'none' : undefined,
        }}
      >
        {Array.isArray(children)
          ? children.some(item => item)
            ? children
            : 'There is no data available for this category.'
          : children || 'There is no data available for this category.'}
      </Content>
    </Root>
  );
};

const Root = styled.div``;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Divider = styled(UIDivider)`
  margin: 16px 0 20px;
`;

const Content = styled.div`
  margin-bottom: 40px;
  gap: 33px 23px;
  display: flex;
  flex-wrap: wrap;
`;

export default CheckBoxCollapsible;
