import styled from 'styled-components';

const FormItalic = styled.div`
  font-style: italic;
  color: #737373;
  font-size: 12px;
  font-weight: 400;
  margin: 10px 0;
`;

export default FormItalic;
