import { DoughnutChart } from 'components/Charts';
import React from 'react';
import styled from 'styled-components';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';

interface Props {
  rate: number;
  themeColor?: string;
}

const MissionAdvancementMetric = ({ rate, themeColor }: Props) => {
  return (
    <MetricRoot>
      <MetricTitle>Mission Advancement</MetricTitle>
      <Content>
        <DoughnutChart
          value={rate}
          fontSize={20}
          fontColor="#343A40"
          bgColor="#F3F3F4"
          circularColor={themeColor || '#EEC459'}
          circularWidth={10}
          size={150}
        />
      </Content>
    </MetricRoot>
  );
};

const Content = styled(MetricContent)`
  display: flex;
  justify-content: center;
`;

export default MissionAdvancementMetric;
