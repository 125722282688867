import React from 'react';
import Jumbotron from 'components/Jumbotron';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import styled from 'styled-components';
import NavigationBar from 'components/NavigationBar';
import queryString from 'query-string';
import { GivingNavigationEnum } from 'constants/giving';
import GivingCharitableOrganizations from './GivingCharitableOrganizations/GivingCharitableOrganizations';
import { useParams } from 'react-router';

const Giving = () => {
  const search = queryString.parse(window.location.search) as {
    type: GivingNavigationEnum;
  };

  return (
    <ContentBlockWrapper header={<Jumbotron name="Giving" />}>
      <Body>
        <div style={{ marginBottom: '30px' }}>
          <NavigationBar<GivingNavigationEnum>
            defaultTab={GivingNavigationEnum.Charitable_Organizations}
            list={[
              {
                label: 'Charitable Organizations',
                value: GivingNavigationEnum.Charitable_Organizations,
              },
            ]}
            queryKey="type"
          />
        </div>
        {(!search.type ||
          search.type === GivingNavigationEnum.Charitable_Organizations) && (
          <GivingCharitableOrganizations params={search} />
        )}
      </Body>
    </ContentBlockWrapper>
  );
};

const Body = styled.div`
  max-width: 1138px;
`;

export default Giving;
