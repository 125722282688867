import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

import { PaginationProps } from 'interfaces/common';
import { usePagination } from 'hooks/common';
import ArrowLeftSVG from 'assets/images/icons/arrow-left.svg';

const PaginationBlock = (props: PaginationProps) => {
  const { handleNext, handlePrev, isFirstPage, isLastPage } = usePagination(props);

  return (
    <Root>
      {!isFirstPage && (
        <ButtonAction onClick={handlePrev}>
          <ArrowLeftIcon src={ArrowLeftSVG} />
        </ButtonAction>
      )}

      <PaginationPage>{props.page}</PaginationPage>

      {!isLastPage && (
        <ButtonAction onClick={handleNext}>
          <ArrowRightIcon src={ArrowLeftSVG} />
        </ButtonAction>
      )}
    </Root>
  );
};

const ButtonAction = styled.button`
  width: 57px;
  height: 35px;
  background: #f2f2f2;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const PaginationPage = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: var(--black);
  cursor: default;
  background: var(--secondaryBlue);
  width: 49px;
  height: 35px;
  border-radius: 4px;
  margin: 0 21px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ArrowLeftIcon = styled(ReactSVG)``;

const ArrowRightIcon = styled(ArrowLeftIcon)`
  transform: rotate(180deg);
`;

export default PaginationBlock;
