import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { FieldRenderProps, Form, FormRenderProps } from 'react-final-form';
import History from 'services/History';
import {
  FieldTextInput,
  FieldSingleSelect,
  MaskedTextInput,
  FieldSuggest,
  FieldCheckBoxGroup,
  FieldSuggestPlaces,
  FiledMultiSelectWithCheckBoxes,
  FieldTextArea,
  MaskedMoneyTextInput,
  FieldSuggestSingle,
} from 'components/FormFields';
import Tooltip from 'components/IconTooltip';
import { SelectOption } from 'components/UI/interfaces';
import FieldRadioButtons from './FieldRadioButtons';
import UploadProfilePhoto from 'components/UploadProfilePhoto';
import FieldTextAriaGroup from './FieldTextAriaGroup';
import { UIButton } from 'components/UI';

import { moneyMask, phoneNumberMaskedProps } from 'utils/formatters';
import {
  required,
  maxLength100,
  email,
  webSiteLink,
  integerValidator,
  einValidator,
  anotherNotNullRequired,
} from 'utils/validation';
import { composeValidators, identityField } from 'utils';

import { ImpactGenome, TeamProfileData, TeamProfileDataForm } from 'interfaces/team';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import { SuggestCompany } from 'interfaces/companies';

import { typesOfInvestmentOptions } from 'constants/typesOfInvestment';
import { goalAlignmentOptions } from 'constants/goalAlignment';
import { constituentsOptions } from 'constants/constituentsOptions';
import { Field } from 'components/FinalForm';
import FieldUploadFile from 'components/FormFields/FieldUploadFile';
import FieldRatioInput from 'components/FormFields/FieldRatioInput/FieldRatioInput';
import { EinDataRes } from 'interfaces/campaign';
import IconTooltip from 'components/IconTooltip';
import { PaypalTypeEnum, paypalTypeOptions } from 'constants/team';
import { OnChange } from 'react-final-form-listeners';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { ReactSVG } from 'react-svg';
import AddSVG from 'assets/images/icons/blue-add-icon.svg';
import Copy from 'assets/images/icons/copy.svg';
import Toast from 'services/Toast';

export interface FormProps {
  onSubmit: (values: TeamProfileDataForm) => OnSubmitReturnType;
  initialValues: TeamProfileData | undefined;
  handleGetCompanies: (val: string) => void;
  companiesSearchList: SuggestCompany[];
  missions: SelectOption<number>[];
  handleLogoUpload: (obj: File) => Promise<string | void | number>;
  handleSearchFund: (val: string) => void;
  einSearchList: { name: string }[];
  payPalGivingFundCanUpdate: boolean;
  payPalGivingFundIfConfirm: boolean;
  programFocusOptions: SelectOption[];
  impactGenome: ImpactGenome[];
}

const ProfileForm = ({
  onSubmit,
  initialValues,
  handleGetCompanies,
  companiesSearchList,
  handleLogoUpload,
  missions,
  handleSearchFund,
  einSearchList,
  payPalGivingFundCanUpdate,
  payPalGivingFundIfConfirm,
  programFocusOptions,
  impactGenome,
}: FormProps) => {
  const initialFormValue: TeamProfileDataForm | undefined = useMemo(() => {
    if (!initialValues) {
      return;
    }

    const teamPartnerCompanies =
      initialValues?.teamPartnerCompanies?.map(item => ({
        id: item.companyId,
        name: item.companyName,
      })) || [];

    const programFocusAndCostPerOutcome: TeamProfileDataForm['programFocusAndCostPerOutcome'] = initialValues?.programFocus
      ?.split(';')
      .map((item, i) => ({
        costPerOutcome: initialValues.costPerOutcome?.split(',')[i],
        programFocus: item,
      })) || [{ costPerOutcome: '', programFocus: '' }];

    const beneficiaries_1 = initialValues.beneficiaries?.filter(value =>
      ['Men/Boys', 'Non-binary', 'Women/Girls'].includes(value),
    );
    const transformedArray1 = beneficiaries_1?.map(item => ({
      label: item,
      value: item,
    }));

    const beneficiaries_2 = initialValues.beneficiaries?.filter(value =>
      [
        'Adolescents (Secondary)',
        'Adults',
        'Birth to Young Children (Pre-K)',
        'Children (Elementary school)',
        'Early Adolescents (Middle school)',
        'Elderly',
        'Young Adults (College/Post-Secondary)',
      ].includes(value),
    );
    const transformedArray2 = beneficiaries_2?.map(item => ({
      label: item,
      value: item,
    }));

    const beneficiaries_3 = initialValues.beneficiaries?.filter(value =>
      [
        'Asian or Pacific Islander',
        'Black',
        'Hispanic/Latinx',
        'Indigenous',
        'Other Race/Ethnicity',
      ].includes(value),
    );
    const transformedArray3 = beneficiaries_3?.map(item => ({
      label: item,
      value: item,
    }));

    const beneficiaries_4 = initialValues.beneficiaries?.filter(value =>
      [
        'Active Military and/or Military Families',
        'English Language Learners (ELL)',
        'First-Generation College Students',
        'Homeless',
        'Incarcerated / formerly incarcerated',
        'Individuals with Disabilities',
        'Individuals with Mental Health Condition(s)',
        'Individuals with Physical Health Condition(s)',
        'LGBTQ+',
        'Low-Income or Economically Disadvantaged',
        'Military Veterans and/or Veteran Families',
        'Newcomers, Immigrants, and/or Refugees',
        'Unemployed',
      ].includes(value),
    );
    const transformedArray4 = beneficiaries_4?.map(item => ({
      label: item,
      value: item,
    }));

    return {
      ...initialValues,
      teamPartnerCompanies,
      programFocusAndCostPerOutcome,
      beneficiaries1: transformedArray1 || [],
      beneficiaries2: transformedArray2 || [],
      beneficiaries3: transformedArray3 || [],
      beneficiaries4: transformedArray4 || [],
      paypalType: initialValues?.paypalType || PaypalTypeEnum.PayPal_Giving_Fund,
    };
  }, [initialValues]);

  const copyInfo = (text: any) => {
    const el = document.createElement('input');
    el.setAttribute('value', text);
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    return Toast.success('Your information was copied successfully');
  };

  return (
    <Form
      onSubmit={values => {
        values.programFocus = values.programFocusAndCostPerOutcome
          .map(item => item?.programFocus)
          .join(';');
        values.costPerOutcome = values.programFocusAndCostPerOutcome
          .map(item => item?.costPerOutcome)
          .join();

        values.beneficiaries = [];
        if (values['beneficiaries1']) {
          for (let i = 0; i < values['beneficiaries1'].length; i++) {
            values.beneficiaries.push(values['beneficiaries1'][i]['value']);
          }
        }
        if (values['beneficiaries2']) {
          for (let i = 0; i < values['beneficiaries2'].length; i++) {
            values.beneficiaries.push(values['beneficiaries2'][i]['value']);
          }
        }
        if (values['beneficiaries3']) {
          for (let i = 0; i < values['beneficiaries3'].length; i++) {
            values.beneficiaries.push(values['beneficiaries3'][i]['value']);
          }
        }
        if (values['beneficiaries4']) {
          for (let i = 0; i < values['beneficiaries4'].length; i++) {
            values.beneficiaries.push(values['beneficiaries4'][i]['value']);
          }
        }
        onSubmit(values);
      }}
      initialValues={initialFormValue}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        handleSubmit,
        submitting,
        values,
        form,
      }: FormRenderProps<TeamProfileDataForm>) => {
        const primaryMissionsOptions = missions.filter(i => {
          if (values.secondaryMissionFocus) {
            return !values?.secondaryMissionFocus.find(
              item => item.toString() === i.value.toString(),
            );
          }
          return i;
        });

        const secondaryMissionsOptions = missions.filter((i: SelectOption<number>) => {
          if (values.primaryMissionFocus) {
            return !(values.primaryMissionFocus.value.toString() === i.value.toString());
          }
          return i;
        });

        return (
          <FormWrapper>
            <Header>
              <UploadProfilePhoto
                handleUpload={handleLogoUpload}
                imgKey={initialValues?.logoKey}
              />
            </Header>

            <Body>
              <FieldSection>
                <FieldTitleHeader>
                  <FieldTitle>
                    Type of Organization <span>*</span>
                  </FieldTitle>
                </FieldTitleHeader>

                <Row>
                  <Field
                    name="organizationName"
                    label="Organization Name"
                    component={FieldTextInput}
                    placeholder="Enter name"
                    validate={composeValidators<string>(required, maxLength100)}
                  />

                  <Field
                    name="numberConstituents"
                    label="Total Number of Constituents"
                    component={FieldSingleSelect}
                    placeholder="Select Number of Constituents"
                    options={constituentsOptions}
                  />
                </Row>

                <Row>
                  <Field
                    name="annualTotalContributions"
                    label="Annual Total Contributions"
                    mask={moneyMask}
                    component={MaskedTextInput}
                    placeholder="Enter contributions"
                    validate={composeValidators<string>(maxLength100)}
                  />
                  <Field
                    name="aieldActivity"
                    label="Why Statement"
                    component={FieldTextInput}
                    placeholder="We exist to reduce poverty"
                    validate={composeValidators<string>(maxLength100)}
                  />
                </Row>

                <Row>
                  <Field
                    name="twitterLink"
                    parse={identityField}
                    label="Organization Twitter"
                    component={FieldTextInput}
                    placeholder="Enter twitter link"
                    validate={composeValidators<string>(webSiteLink)}
                  />

                  <Field
                    name="linkedinLink"
                    parse={identityField}
                    label="Organization LinkedIn "
                    component={FieldTextInput}
                    placeholder="Enter linkedIn link"
                    validate={composeValidators<string>(webSiteLink)}
                  />
                </Row>

                <Row>
                  <Field
                    name="twitterHandle"
                    parse={identityField}
                    label="Organization Twitter Handle"
                    component={FieldTextInput}
                    placeholder="Enter twitter handle"
                  />
                  <Field
                    name="einStatus"
                    label="Status (EIN)"
                    component={FieldTextInput}
                    placeholder="Enter Status (EIN)"
                    validate={composeValidators(
                      einValidator,
                      anotherNotNullRequired('paypalGivingFundId'),
                    )}
                  />
                </Row>
                <Row>
                  <Field
                    name="paypalType"
                    label="Select PayPal Account type"
                    component={FieldSingleSelect}
                    placeholder="Select"
                    options={paypalTypeOptions}
                    disabled={!payPalGivingFundCanUpdate || !payPalGivingFundIfConfirm}
                    isClearable={false}
                  />
                  <OnChange name="paypalType">
                    {(value, previous) => {
                      if (value === previous) return;
                      const fillInDefultValue = () => {
                        setTimeout(() => {
                          form.change(
                            'paypalGivingFundName',
                            initialValues?.paypalGivingFundName,
                          );
                          form.change(
                            'paypalGivingFundId',
                            initialValues?.paypalGivingFundId,
                          );
                        });
                      };

                      if (value === '0' && !initialValues?.paypalType) {
                        fillInDefultValue();
                        return;
                      }
                      if (value !== initialValues?.paypalType) {
                        setTimeout(() => {
                          form.change('paypalGivingFundName', '');
                          form.change('paypalGivingFundId', '');
                        });
                        return;
                      }

                      fillInDefultValue();
                    }}
                  </OnChange>

                  {values.paypalType === PaypalTypeEnum.PayPal_Commerce && (
                    <Field
                      name="paypalGivingFundId"
                      label="PayPal Commerce"
                      component={FieldTextInput}
                      placeholder="Enter PayPal Commerce"
                      disabled={!payPalGivingFundCanUpdate || !payPalGivingFundIfConfirm}
                    />
                  )}

                  {(values.paypalType === PaypalTypeEnum.PayPal_Giving_Fund ||
                    !values.paypalType) && (
                    <Field name="paypalGivingFundName">
                      {(props: FieldRenderProps<any>) => {
                        const input = {
                          ...props.input,
                          onChange: (value: EinDataRes) => {
                            values.paypalGivingFundName = value.name;
                            if (!value) {
                              props.input.onChange(undefined);
                              return;
                            }

                            form.change('paypalGivingFundId', value.paypalGivingFundId);
                            form.change('paypalEin', value.ein);
                          },
                        };

                        return (
                          <div>
                            <FieldSuggestSingle
                              {...props}
                              label={
                                <LabelWrapper>
                                  PayPal Giving Fund
                                  <IconTooltipBlue>
                                    If you have a PayPal Giving Fund account, please enter
                                    the organization name in which the account is
                                    registered.
                                  </IconTooltipBlue>
                                </LabelWrapper>
                              }
                              handleGetSuggestions={handleSearchFund}
                              list={einSearchList}
                              placeholder="Search"
                              input={input}
                              isTypeInputToForm
                              isShowTitle={true}
                              disabled={
                                !payPalGivingFundCanUpdate || !payPalGivingFundIfConfirm
                              }
                            />
                            {input.value && (
                              <TableRowCell>
                                <IconLink
                                  src={Copy}
                                  onClick={() => {
                                    copyInfo(input.value);
                                  }}
                                />
                              </TableRowCell>
                            )}
                          </div>
                        );
                      }}
                    </Field>
                  )}
                </Row>
                <Row>
                  <Field
                    name="programExpenseRatio"
                    label="Program Expense Ratio"
                    component={FieldRatioInput}
                    placeholder="Enter Program Expense Ratio"
                  />
                </Row>
                <FieldArray name="programFocusAndCostPerOutcome">
                  {({ fields }) => {
                    return fields.map((name, index) => (
                      <div key={index}>
                        <Row>
                          <Field
                            name={`${name}.programFocus`}
                            label="Program Focus"
                            component={FieldSingleSelect}
                            options={programFocusOptions}
                            placeholder="Select"
                          />
                          <OnChange name={`${name}.programFocus`}>
                            {(value: string, previous) => {
                              if (value === previous) return;

                              const costPerOutcome = impactGenome.find(
                                item => item.programFocus === value,
                              )?.costPerOutcome;

                              setTimeout(() => {
                                form.change(
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  `${name}.costPerOutcome` as any,
                                  costPerOutcome,
                                );
                              });
                            }}
                          </OnChange>
                          {fields.value[index]?.programFocus && (
                            <Field
                              name={`${name}.costPerOutcome`}
                              label={
                                <LabelWrapper>
                                  Cost Per Outcome
                                  <IconTooltipBlue>
                                    Cost for this program to help one beneficiary achieve
                                    the verified outcome.
                                  </IconTooltipBlue>
                                </LabelWrapper>
                              }
                              component={MaskedMoneyTextInput}
                              validate={composeValidators(required, integerValidator)}
                              disabled
                            />
                          )}
                        </Row>

                        <ActionContentButtons>
                          {fields.value.length === index + 1 && (
                            <FormArrrayButton
                              modifiers="secondary"
                              title={
                                <ArrayButtonWrapper>
                                  <ReactSVG src={AddSVG} style={{ marginRight: 8 }} />
                                  Add Another Program Focus
                                </ArrayButtonWrapper>
                              }
                              handleClick={() => fields.push(undefined)}
                            />
                          )}
                          {fields.value.length > 1 && (
                            <FormArrrayButton
                              modifiers="danger"
                              title="Delete Program Focus"
                              handleClick={() => fields.remove(index)}
                            />
                          )}
                        </ActionContentButtons>
                      </div>
                    ));
                  }}
                </FieldArray>
              </FieldSection>

              <FieldSection>
                <FieldTitleHeader>
                  <FieldTitle>Beneficiaries</FieldTitle>
                </FieldTitleHeader>
                <Row>
                  <Field
                    name="beneficiaries1"
                    label="Gender"
                    component={FiledMultiSelectWithCheckBoxes}
                    placeholder="Select"
                    options={[
                      { label: 'Men/Boys', value: 'Men/Boys' },
                      { label: 'Non-binary', value: 'Non-binary' },
                      { label: 'Women/Girls', value: 'Women/Girls' },
                    ]}
                    isClearable={false}
                  />
                  <Field
                    name="beneficiaries2"
                    label="Age"
                    component={FiledMultiSelectWithCheckBoxes}
                    placeholder="Select"
                    options={[
                      {
                        label: 'Adolescents (Secondary)',
                        value: 'Adolescents (Secondary)',
                      },
                      { label: 'Adults', value: 'Adults' },
                      {
                        label: 'Birth to Young Children (Pre-K)',
                        value: 'Birth to Young Children (Pre-K)',
                      },
                      {
                        label: 'Children (Elementary school)',
                        value: 'Children (Elementary school)',
                      },
                      {
                        label: 'Early Adolescents (Middle school)',
                        value: 'Early Adolescents (Middle school)',
                      },
                      { label: 'Elderly', value: 'Elderly' },
                      {
                        label: 'Young Adults (College/Post-Secondary)',
                        value: 'Young Adults (College/Post-Secondary)',
                      },
                    ]}
                    isClearable={false}
                  />
                </Row>
                <Row>
                  <Field
                    name="beneficiaries3"
                    label="Race/Ethnicity"
                    component={FiledMultiSelectWithCheckBoxes}
                    placeholder="Select"
                    options={[
                      {
                        label: 'Asian or Pacific Islander',
                        value: 'Asian or Pacific Islander',
                      },
                      { label: 'Black', value: 'Black' },
                      {
                        label: 'Hispanic/Latinx',
                        value: 'Hispanic/Latinx',
                      },
                      { label: 'Indigenous', value: 'Indigenous' },
                      { label: 'Other Race/Ethnicity', value: 'Other Race/Ethnicity' },
                    ]}
                    isClearable={false}
                  />
                  <Field
                    name="beneficiaries4"
                    label="Other"
                    component={FiledMultiSelectWithCheckBoxes}
                    placeholder="Select"
                    options={[
                      {
                        label: 'Active Military and/or Military Families',
                        value: 'Active Military and/or Military Families',
                      },
                      {
                        label: 'English Language Learners (ELL)',
                        value: 'English Language Learners (ELL)',
                      },
                      {
                        label: 'First-Generation College Students',
                        value: 'First-Generation College Students',
                      },
                      {
                        label: 'Homeless',
                        value: 'Homeless',
                      },
                      {
                        label: 'Incarcerated / formerly incarcerated',
                        value: 'Incarcerated / formerly incarcerated',
                      },
                      {
                        label: 'Individuals with Disabilities',
                        value: 'Individuals with Disabilities',
                      },
                      {
                        label: 'Individuals with Mental Health Condition(s)',
                        value: 'Individuals with Mental Health Condition(s)',
                      },
                      {
                        label: 'Individuals with Physical Health Condition(s)',
                        value: 'Individuals with Physical Health Condition(s)',
                      },
                      { label: 'LGBTQ+', value: 'LGBTQ+' },
                      {
                        label: 'Low-Income or Economically Disadvantaged',
                        value: 'Low-Income or Economically Disadvantaged',
                      },
                      {
                        label: 'Military Veterans and/or Veteran Families',
                        value: 'Military Veterans and/or Veteran Families',
                      },
                      {
                        label: 'Newcomers, Immigrants, and/or Refugees',
                        value: 'Newcomers, Immigrants, and/or Refugees',
                      },
                      {
                        label: 'Unemployed',
                        value: 'Unemployed',
                      },
                    ]}
                    isClearable={false}
                  />
                </Row>
              </FieldSection>

              <FieldSection>
                <FieldTitleHeader>
                  <FieldTitle>Current Corporate Partners</FieldTitle>
                </FieldTitleHeader>
                <Field
                  name="teamPartnerCompanies"
                  label="Partners"
                  component={FieldSuggest}
                  handleGetSuggestions={handleGetCompanies}
                  placeholder="Search"
                  list={companiesSearchList}
                />
              </FieldSection>

              <FieldSection>
                <FieldTitleHeader>
                  <FieldTitle>Contact</FieldTitle>
                </FieldTitleHeader>
                <Row>
                  <Field
                    name="contactName"
                    label="Full Name"
                    component={FieldTextInput}
                    placeholder="Enter full name"
                    validate={composeValidators<string>(maxLength100)}
                  />

                  <Field
                    name="contactPhone"
                    label="Phone"
                    mask={phoneNumberMaskedProps.mask}
                    component={MaskedTextInput}
                    placeholder="Enter phone"
                    validate={composeValidators<string>(maxLength100)}
                  />
                </Row>
                <Row>
                  <Field
                    name="contactWebsite"
                    label="Website"
                    component={FieldTextInput}
                    placeholder="Enter website"
                    validate={composeValidators<string>(maxLength100)}
                  />
                  <Field
                    name="email"
                    label="Email"
                    component={FieldTextInput}
                    placeholder="Enter email"
                    validate={composeValidators<string>(email)}
                  />
                </Row>
              </FieldSection>

              <FieldSection>
                <FieldTitleHeader>
                  <FieldTitle>SDG Goal Alignment</FieldTitle>
                </FieldTitleHeader>
                <Field
                  name="goalAlignment"
                  component={FieldCheckBoxGroup}
                  options={Object.values(goalAlignmentOptions)}
                  parse={value => value.map(({ value: sdg }) => sdg)}
                  format={value => value?.map(sdg => goalAlignmentOptions[sdg]) || []}
                />
              </FieldSection>

              <FieldSection>
                <FieldTitleHeader>
                  <FieldTitle>Address</FieldTitle>
                </FieldTitleHeader>

                <Row>
                  <Field
                    name="address"
                    label="Address"
                    component={FieldSuggestPlaces}
                    parse={identityField}
                  />
                </Row>
              </FieldSection>

              <FieldSection>
                <FieldTitleHeader>
                  <FieldTitle>Types Of Investment</FieldTitle>
                </FieldTitleHeader>
                <Field
                  name="typesOfInvestment"
                  component={FieldCheckBoxGroup}
                  options={typesOfInvestmentOptions}
                />
              </FieldSection>

              <FieldSection>
                <FieldTitleHeader>
                  <FieldTitle>Social Followers Number</FieldTitle>
                </FieldTitleHeader>
                <Field
                  name="numberOfSocialFollowers"
                  component={FieldTextInput}
                  validate={integerValidator}
                />
              </FieldSection>

              <FieldSection>
                <FieldTitleHeader>
                  <FieldTitle>Minimum Commitment</FieldTitle>
                </FieldTitleHeader>
                <Field name="minimumCommitment" component={MaskedMoneyTextInput} />
              </FieldSection>

              <FieldSection>
                <FieldTitleHeader>
                  <FieldTitle>Mission</FieldTitle>
                </FieldTitleHeader>
                <Field
                  name="primaryMissionFocus"
                  label="Primary Mission Focus"
                  component={FieldSingleSelect}
                  placeholder="Select Mission"
                  options={primaryMissionsOptions}
                  format={v => v?.value}
                  objectField
                  validate={required}
                />

                <Field
                  name="secondaryMissionFocus"
                  label="Secondary Mission Focus"
                  component={FiledMultiSelectWithCheckBoxes}
                  placeholder={'Search'}
                  options={secondaryMissionsOptions}
                />
              </FieldSection>

              <FieldSection>
                <FieldTitleHeader>
                  <FieldTitle>Employee Engagement Opportunities</FieldTitle>
                </FieldTitleHeader>
                <Field
                  name="employeeEngagementOpportunities"
                  component={FieldRadioButtons}
                />
              </FieldSection>

              <FieldSection>
                <FieldTitleHeader>
                  <FieldTitle>Opportunities </FieldTitle>
                  <TooltipWrapper>
                    Describe your top 3 funding priorities at the moment.
                  </TooltipWrapper>
                </FieldTitleHeader>
                <Field name="opportunities" label="Text" component={FieldTextAriaGroup} />
              </FieldSection>

              <FieldSection>
                <FieldTitleHeader>
                  <FieldTitle>Impact Statements </FieldTitle>
                </FieldTitleHeader>
                <Field
                  name="impactStatements"
                  label="Text"
                  component={FieldTextAriaGroup}
                />
              </FieldSection>

              <FieldSection>
                <FieldTitleHeader>
                  <FieldTitle>Impact Story </FieldTitle>
                </FieldTitleHeader>
                <Field name="impactStory" label="Text" component={FieldTextArea} />
              </FieldSection>

              <FieldSection>
                <FieldTitleHeader>
                  <FieldTitle>Additional Materials</FieldTitle>
                </FieldTitleHeader>
                <Field
                  name="additionalMaterials"
                  label=""
                  component={FieldUploadFile}
                  category="additionalMaterials"
                />
              </FieldSection>

              <BtnWrapper>
                <Btn
                  modifiers="secondary"
                  title="Cancel"
                  type="button"
                  handleClick={() => History.back()}
                />
                <Btn
                  modifiers="primary"
                  title="Save"
                  handleClick={handleSubmit}
                  type="submit"
                  disabled={submitting}
                  isLoading={submitting}
                />
              </BtnWrapper>
            </Body>
          </FormWrapper>
        );
      }}
    />
  );
};

const FormWrapper = styled.form`
  border-radius: 6px;
  width: 100%;
  max-width: 844px;
  margin-bottom: 20px;
  overflow: hidden;
`;

const Body = styled.div`
  background: #fff;
  padding: 40px;
`;

const FieldSection = styled.div`
  margin-bottom: 32px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    width: calc(50% - 8px);
  }
`;

const FieldTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;

  & span {
    color: #e92d30;
  }
`;

const FieldTitleHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const TooltipWrapper = styled(Tooltip)`
  margin-left: 18px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f2f2f2;
  padding: 40px;
  align-items: center;
`;

const Btn = styled(UIButton)`
  width: 146px;
  margin-right: 8px;
`;

const BtnWrapper = styled.div`
  display: flex;
`;

const IconTooltipBlue = styled(IconTooltip)`
  margin-left: 4px;

  svg {
    width: 12px;
    height: 12px;
  }

  svg * {
    fill: var(--blue);
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ActionContentButtons = styled.div`
  display: flex;
  margin-bottom: 16px;
  gap: 16px;
`;

const ArrayButtonWrapper = styled.div`
  display: flex;
`;

const FormArrrayButton = styled(UIButton)`
  font-size: 12px;
  padding: 8px 10px;
`;

const TableRowCell = styled.td`
  position: absolute;
  float: left;
  margin-top: -59px;
  margin-left: 334px;
`;

const IconLink = styled(ReactSVG)`
  cursor: pointer;
  width: 24px;
`;
export default ProfileForm;
