import React from 'react';
import styled from 'styled-components';
import FieldUploadLogo from 'components/FormFields/FieldUploadLogo';
import { FormLabel, FormTitle } from 'components/UI/form';
import { FieldColorGroupPicker, FieldSingleSelect } from 'components/FormFields';
import { OnChange } from 'react-final-form-listeners';
import { Field } from 'components/FinalForm';
import { THEME_COLORS } from 'constants/common';
import { FormApi } from 'final-form';

interface SectionData {
  brandLogo: string;
  brandTheme: string;
  brandCustomColors: string[];
  brandFont: string;
}

interface Props<T extends SectionData> {
  handleSubmit: () => void;
  form: FormApi<T, Partial<T>>;
  values: T;
}

const SelectLabelColors = ({ title, colors }: { title: string; colors: string[] }) => {
  return (
    <SelectLabelColorsWrapper style={{ display: 'flex' }}>
      <div style={{ width: '164px' }}>{title}</div>
      {colors.map((item, i) => (
        <ColorBlock key={i} $color={item} />
      ))}
    </SelectLabelColorsWrapper>
  );
};

const BrandKitSection = <T extends SectionData>({
  handleSubmit,
  form,
  values,
}: Props<T>) => {
  return (
    <Root>
      <FormTitle>Brand Kit</FormTitle>
      <FormFieldTitle>Logo</FormFieldTitle>
      <Field name="brandLogo" component={FieldUploadLogo} />
      <OnChange name="brandLogo">
        {(value, previous) => {
          if (value !== previous) {
            handleSubmit();
          }
        }}
      </OnChange>

      <FormFieldTitle>Color</FormFieldTitle>
      <Frame>
        <Field
          name="brandTheme"
          label="Select theme"
          component={FieldSingleSelect}
          isClearable={false}
          options={Object.values(THEME_COLORS).map(item => ({
            label: <SelectLabelColors title={item.title} colors={item.value} />,
            value: item.title,
          }))}
        />
        <OnChange name="brandTheme">
          {(value, previous) => {
            if (value === previous) return;
            if (value !== THEME_COLORS.Custom_colors.title) return handleSubmit();
            form.change(
              'brandCustomColors',
              THEME_COLORS.Accelerist_theme.value.slice(0, 3),
            );
          }}
        </OnChange>
        {values.brandTheme === THEME_COLORS.Custom_colors.title && (
          <Field name="brandCustomColors" component={FieldColorGroupPicker} />
        )}
        <OnChange name="brandCustomColors">
          {(value, previous) => {
            if (JSON.stringify(value) !== JSON.stringify(previous)) {
              handleSubmit();
            }
          }}
        </OnChange>
      </Frame>

      <FormFieldTitle>Fonts</FormFieldTitle>
      <Frame>
        <Field
          name="brandFont"
          label="Select a default font style"
          component={FieldSingleSelect}
          isClearable={false}
          options={[
            { label: 'Rubik', value: 'Rubik' },
            { label: 'Roboto', value: 'Roboto' },
            { label: 'Open Sans', value: 'Open Sans' },
            { label: 'Montserrat', value: 'Montserrat' },
            { label: 'Poppins', value: 'Poppins' },
            { label: 'Source Sans Pro', value: 'Source Sans Pro' },
            { label: 'Raleway', value: 'Raleway' },
            { label: 'Inter', value: 'Inter' },
            { label: 'Playfair Display', value: 'Playfair Display' },
          ]}
        />
        <FormLabel>Font Family</FormLabel>
        <FontViewWrapper $font={values.brandFont} style={{ fontSize: '24px' }}>
          {values.brandFont}
        </FontViewWrapper>
        <FormLabel>Font Styles</FormLabel>
        <FontViewWrapper $font={values.brandFont}>
          <div style={{ fontSize: '28px' }}>Title / 28 Medium</div>
          <div style={{ fontSize: '16px' }}>Subtitle / 16 Medium</div>
          <div style={{ fontSize: '12px', fontWeight: 400 }}>Body / 12 Regular</div>
        </FontViewWrapper>
        <OnChange name="brandFont">
          {(value, previous) => {
            if (value !== previous) {
              handleSubmit();
            }
          }}
        </OnChange>
      </Frame>
    </Root>
  );
};

const Root = styled.div``;

const FormFieldTitle = styled.div`
  font-size: 16px;
  line-height: 155%;
  font-style: normal;
  font-weight: normal;
  color: var(--black);
  margin-bottom: 24px;
`;

const Frame = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 20px 20px 0;
  width: 653px;
  margin-bottom: 24px;

  & > div {
    max-width: 374px;
  }
`;

const FontViewWrapper = styled.div<{ $font: string }>`
  color: #000;
  margin-bottom: 24px;
  font-weight: 500;
  font-family: ${({ $font }) => `${$font}`};
`;

const ColorBlock = styled.div<{ $color: string }>`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-left: 8px;

  background-color: ${({ $color }) => $color};
`;

const SelectLabelColorsWrapper = styled.div`
  display: flex;
`;

export default BrandKitSection;
