import React, { useState } from 'react';
import styled from 'styled-components';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { Form } from 'react-final-form';
import { Field } from 'components/FinalForm';
import { FieldTextInput } from 'components/FormFields';
import UploadFileArea from 'components/UploadFileArea';
import Toast from 'services/Toast';
import { imageFileValidator, required, urlLink } from 'utils/validation';
import { uploadFile } from 'store/ducks/utility/api';
import { getFilenameByPath } from 'utils/formatters';
import UIAsterisk from 'components/UI/UIAsterisk';
import { composeValidators } from 'utils';
import { ROICanvaTemplatesCreateReq } from 'interfaces/roi';

interface Props extends InjectedProps {
  handleAccess: (param: ROICanvaTemplatesCreateReq) => void;
}

const UploadCustomTemplateModal = ({ onClose, handleAccess, ...modalProps }: Props) => {
  useLockBodyScroll();
  const [uploading, setUploading] = useState(false);
  const [filesize, setFilesize] = useState(0);

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '36px' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '828px',
        overflow: 'auto',
      }}
    >
      <Title>Custom Prospecting List</Title>
      <Form<ROICanvaTemplatesCreateReq>
        onSubmit={values => {
          if (!values.imageKey) {
            Toast.warn('No file selected.');
            return;
          }
          handleAccess(values);
        }}
        render={({ handleSubmit, form, values }) => (
          <>
            <Field
              label={
                <div>
                  Link Template
                  <UIAsterisk />
                </div>
              }
              name="targetUrl"
              component={FieldTextInput}
              placeholder="Enter URL:"
              validate={composeValidators<string>(required, urlLink)}
            />
            <UploadFileArea
              name="Upload Cover Template"
              accept="image/*"
              acceptValidator={imageFileValidator}
              isloading={uploading}
              inputId="upload-custom-template-upload"
              desc="You may upload an image in *.png,*.jpeg,*.jpg,*.bmp,*.svg. File size limit is 5 Mb."
              showFileListType="thumbnail"
              onUpload={files => {
                if (!files.length) return;
                setUploading(true);
                setFilesize(files[0].size);
                uploadFile(files[0], 'canva-template-cover')
                  .then(v => {
                    setUploading(false);
                    if (v.status === 'success') {
                      form.change('imageKey', v.fileKey);
                      return Toast.success('File uploaded successfully');
                    }
                    Toast.error('Error upload file');
                  })
                  .catch(() => setUploading(false));
              }}
              onDeleteFile={() => form.change('imageKey', undefined)}
              filelist={
                values.imageKey
                  ? [
                      {
                        label: getFilenameByPath(values.imageKey),
                        value: values.imageKey,
                        filesize: filesize,
                      },
                    ]
                  : []
              }
            />

            <BtnGroup>
              <Btn modifiers="secondary" title="Cancel" handleClick={onClose} />
              <Btn modifiers="primary" title="Complete" handleClick={handleSubmit} />
            </BtnGroup>
          </>
        )}
      />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 34px;
  text-align: center;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Btn = styled(UIButton)`
  display: block;
  width: 87px;
  font-size: 14px;
  padding: 7px 0;
`;

export default UploadCustomTemplateModal;
