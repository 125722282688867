export const ROIListDropdownOptions = [
  { label: 'Edit', value: 'Edit' },
  { label: 'Delete', value: 'Delete' },
];

export enum ROIEditNavigationEnum {
  Manage = 'Manage',
  Steward = 'Steward',
  Measure = 'Measure',
}

export enum ROINavigationEnum {
  Partnerships = 'Partnerships',
  Dashboard = 'Dashboard',
  Reporting = 'Reporting',
}

export enum ROIEditMeasureNavigationEnum {
  Tracking = 'Tracking',
  Impact_Report = 'Impact_Report',
}

export enum ROIReportEditNavigationEnum {
  Partner_Data_Intake = 'Partner_Data_Intake',
  Report = 'Report',
  Legacy_Reports = 'Legacy_Reports',
}

export enum ROIReportEditReportRoutePathsEnum {
  Select_Report = 'select-report',
  Create_Customize_Report = 'create-customize-report',
}

export enum ROIListTitleExplain {
  organization = 'Company',
  company = 'Organization',
}

export const OperationsSelectOptions = [
  { label: 'All Operations', value: 'All Operations' },
  { label: 'Steward', value: 'Steward' },
  { label: 'Measure', value: 'Measure' },
  { label: 'Manage', value: 'Manage' },
] as const;

export const TimerRangeSelectOptions = [
  { label: 'All Time', value: 'All Time' },
  { label: 'Last Year', value: 'Last Year' },
  { label: 'Last 3 Months', value: 'Last 3 Months' },
  { label: 'Last Month', value: 'Last Month' },
  { label: 'Last Week', value: 'Last Week' },
  { label: 'Yesterday', value: 'Yesterday' },
  { label: 'Today', value: 'Today' },
  { label: 'This Week', value: 'This Week' },
  { label: 'This Month', value: 'This Month' },
  { label: 'In Three Months', value: 'In Three Months' },
] as const;

export enum PartnershipGoalsEnum {
  'Brand Awareness' = 1,
  'Mission Awareness' = 2,
  'Fundraise' = 3,
  'Brand Reputation' = 4,
  'Call-to-Action' = 5,
  'Employee Engagement' = 6,
  'Other Stakeholder Engagement' = 7,
  'Media Exposure' = 8,
}

export const PartnershipGoalsOptions = [
  { label: 'Brand Awareness', value: PartnershipGoalsEnum['Brand Awareness'] },
  { label: 'Mission Awareness', value: PartnershipGoalsEnum['Mission Awareness'] },
  { label: 'Fundraise', value: PartnershipGoalsEnum['Fundraise'] },
  { label: 'Brand Reputation', value: PartnershipGoalsEnum['Brand Reputation'] },
  { label: 'Call-to-Action', value: PartnershipGoalsEnum['Call-to-Action'] },
  { label: 'Employee Engagement', value: PartnershipGoalsEnum['Employee Engagement'] },
  {
    label: 'Other Stakeholder Engagement',
    value: PartnershipGoalsEnum['Other Stakeholder Engagement'],
  },
  { label: 'Media Exposure', value: PartnershipGoalsEnum['Media Exposure'] },
] as const;

export const StewardshipElements = [
  { label: 'Basic', value: 'Basic' },
  { label: 'Intermediate', value: 'Intermediate' },
  { label: 'Premium', value: 'Premium' },
  { label: 'Custom', value: 'Custom' },
] as const;

export const StewardshipItems = [
  { label: 'Annual Kick-Off', value: 'Annual Kick-Off' },
  {
    label: 'Monthly Partner Newsletter',
    value: 'Monthly Partner Newsletter',
  },
  {
    label: 'Partner Feature in Monthly Partner Newsletter',
    value: 'Partner Feature in Monthly Partner Newsletter',
  },
  { label: 'Monthly Partner Meeting', value: 'Monthly Partner Meeting' },
  {
    label: 'Quarterly Partner Meeting',
    value: 'Quarterly Partner Meeting',
  },
  { label: 'Partner Toolkit', value: 'Partner Toolkit' },
  { label: 'VIP Event', value: 'VIP Event' },
  {
    label: 'Speaking Engagement at VIP Event',
    value: 'Speaking Engagement at VIP Event',
  },
  {
    label: 'Partner Appreciation Event',
    value: 'Partner Appreciation Event',
  },
  {
    label: 'Annual Partner Satisfaction Survey',
    value: 'Annual Partner Satisfaction Survey',
  },
  { label: 'Annual Review Meeting', value: 'Annual Review Meeting' },
  {
    label: 'Additional Custom Stewardship Activation',
    value: 'Additional Custom Stewardship Activation',
  },
  {
    label: 'Growth Opportunities Request',
    value: 'Growth Opportunities Request',
  },
  {
    label: 'Basic Impact Reporting Push Notification',
    value: 'Basic Impact Reporting Push Notification',
  },
  {
    label: 'ROI Impact Reporting Push Notification',
    value: 'ROI Impact Reporting Push Notification',
  },
  {
    label: 'Personalized Executive Thank You',
    value: 'Personalized Executive Thank You',
  },
] as const;

export enum ORIinvolvedEnum {
  Noinvolvement = 'No involvement',
  Somewhatinvolved = 'Somewhat involved',
  Veryinvolved = 'Very involved',
}

export enum TrackingStatusEnum {
  PendingAdmin = 'PendingAdmin',
  Complete = 'Complete',
}

export enum TrackingDashboardDocumentStatusEnum {
  positive = 'Positive',
  neutral = 'Neutral',
  negative = 'Negative',
}

export const ROICanvaMetricElId = {
  OverallPartnershipValueMetric: 'overall-partnership-value-metric-el',
  ReturnOnPartnershipMetric: 'return-on-partnership-metric-el',
  FundRaisedMetric: 'fund-raised-metric-el',
  TypesOfFundsRaisedMetric: 'types-of-funds-raised-el',
  CashMetric: 'cash-metric-el',
  FundraisingMetric: 'fundraising-metric-el',
  CorporateIntangibleAssetMetric: 'corporate-intangible-asset-metric-el',
  BottomLineImpactMetric: 'bottom-line-impact-metric-el',
  PotentialCustomerValueMetric: 'potential-customer-value-metric-el',
  PotentialTurnoverCostsSavedMetric: 'potential-turnover-costs-saved-metric-el',
  PaidMediaImpressionsMetricTelevision: 'paid-media-impressions-metric-television-el',
  PaidMediaImpressionsMetricPublication: 'paid-media-impressions-metric-publication-el',
  PaidMediaImpressionsMetricOutofHome: 'paid-media-impressions-metric-outofhome-el',
  PaidMediaImpressionsMetricDigital: 'paid-media-impressions-metric-digital-el',
  PaidMediaImpressionsMetricRadio: 'paid-media-impressions-metric-radio-el',
  OwnedMediaImpressionsMetricMagazine: 'owned-media-impressions-metric-magazine-el',
  OwnedMediaImpressionsMetricWebsite: 'owned-media-impressions-metric-website-el',
  OwnedMediaImpressionsMetricInOffice: 'owned-media-impressions-metric-in-office-el',
  OwnedMediaImpressionsMetricECommunication:
    'owned-media-impressions-metric-e-communication-el',
  OwnedMediaImpressionsMetricCollateral: 'owned-media-impressions-metric-collateral-el',
  OwnedMediaImpressionsMetricInStore: 'owned-media-impressions-metric-in-store-el',
  OwnedMediaImpressionsMetricEventParticipants:
    'owned-media-impressions-metric-event-participants-el',
  OwnedMediaImpressionsMetricEmployee: 'owned-media-impressions-metric-employee-el',
  SocialMediaImpressionsMetric: 'social-media-impressions-metric-el',
  EarnedMediaImpressionsMetric: 'earned-media-impressions-metric-el',
  ImpressionsMetric: 'impressions-metric-el',
  ValueMetric: 'value-metric-el',
  VolunteeringParticipationRateMetric: 'volunteering-participation-rate-metric-el',
  TypesOfVolunteeringMetric: 'types-of-volunteering-metric-el',
  ConstituentEngagementMetric: 'constituent-engagement-metric-el',
  LoyaltyMetric: 'loyalty-metric-el',
  ParticipationMetric: 'participation-metric-el',
  AmbassadorsMetric: 'ambassadors-metric-el',
  StewardshipActivitiesMetric: 'stewardship-activities-metric-el',
  MissonImpactMetric: 'misson-impact-metric-el',
  MissionAdvancementMetric: 'mission-advancement-metric-el',
  ScopeOfImpactMetric: 'scopeof-impact-metric-el',
  TotalGivingMetric: 'total-giving-metric-el',
  BusinessValueMetric: 'business-value-metric-el',
  BaselineMetricBrandValue: 'baseline-metric-brand-value-el',
  BaselineMetricStewardship: 'baseline-metric-stewardship-el',
  BaselineMetricAmbassadorEngagement: 'baseline-metric-ambassador-engagement-el',
  BaselineMetricPaidDonatedMedia: 'baseline-metric-paid-donated-media-el',
  BaselineMetricOwnedMedia: 'baseline-metric-owned-media-el',
  BaselineMetricSocialMedia: 'baseline-metric-social-media-el',
  BaselineMetricEarnedMedia: 'baseline-metric-earned-media-el',
  SDGAlignmentMetric: 'sdg-alignment-metric-el',
  ContentStreamMetric: 'content-stream-metric-el',
  MediaExposureMetric: 'media-exposure-metric-el',
  SentimentMetric: 'sentiment-metric-el',
  TrendingThemesMetric: 'trending-themes-metric-el',
  HeatMapMetric: 'heat-map-metric-el',
  TopAuthorsMetric: 'top-authors-metric-el',
  TopRetweetedMetric: 'top-retweeted-metric-el',
  TotalPotentialReachMetric: 'total-potential-reach-metric-el',
} as const;

export const ROICanvaMetricElIdList = Object.values(ROICanvaMetricElId);

export const ROICanvaMetricsCategory = {
  PartnershipValue: 'Partnership Value',
  Fundraising: 'Fundraising',
  BusinessValue: 'Business Value',
  CorporateIntangibleAssets: 'Corporate Intangible Assets',
  NonProfitIntangibleAssets: 'Non-Profit Intangible Assets',
  BottomLineImpact: 'Bottom Line Impact',
  SocialValue: 'Social Value',
  SDGAlignment: 'SDG Alignment',
  BaselineMetrics: 'Baseline Metrics',
  MediaTracking: 'Media Tracking',
} as const;

export const ROICanvaMetricsCategoryOptions = Object.values(
  ROICanvaMetricsCategory,
).map(item => ({ label: item, value: item }));

export enum ROISurveyListPageType {
  ConsumerSurvey = 'consumer-survey',
  EmployeeSurvey = 'employee-survey',
}
