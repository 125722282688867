import React, { FC } from 'react';
import styled from 'styled-components';

interface Props<T, V> {
  list: T[];
  component: FC<T>;
  componentProps?: V;
  isIndexKey?: boolean;
}

interface InheritsProps {
  id?: string;
}

function List<T extends InheritsProps, V extends object>({
  list,
  component: Component,
  isIndexKey,
  componentProps,
}: Props<T, V>): React.ReactElement<Props<T, V>> {
  return (
    <Root>
      {list.map((item, index) => (
        <Component key={isIndexKey ? index : item.id} {...item} {...componentProps} />
      ))}
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: -12px -12px 0;
`;

export default List;
