import { TextModal } from 'components/Modals';
import React from 'react';

interface Props {
  onClose: () => void;
}

const GameplanTemplateMergeFieldModal = ({ onClose }: Props) => (
  <TextModal
    onClose={onClose}
    title="Accelerist Customized Game Plan"
    content={
      <div>
        <h2>General</h2>
        <p>
          Customized emails can be created for each scheduled email in your Game Plan. The
          default text of the email will be displayed in the editor. The text and content
          of the email can be modified as needed for each message.
        </p>
        <h2>Merged Fields</h2>
        <p>
          The following fields can be added to the customized Game Plan emails. You can
          copy/paste the value in the Merge Field column directly into the email to
          achieve the value in the description field when the message is sent.
        </p>
        <table>
          <tr>
            <th>Merge Field</th>
            <th>Description</th>
          </tr>
          <tr>
            <td>&#123;&#123;user.firstName&#125;&#125;</td>
            <td>Logged-in user&apos;s first name</td>
          </tr>
          <tr>
            <td>&#123;&#123;user.lastName&#125;&#125;</td>
            <td>Logged-in user&apos;s last name</td>
          </tr>
          <tr>
            <td>&#123;&#123;user.jobTitle&#125;&#125;</td>
            <td>Logged-in user&apos;s jobTitle</td>
          </tr>
          <tr>
            <td>&#123;&#123;user.email&#125;&#125;</td>
            <td>Logged-in user&apos;s email</td>
          </tr>
          <tr>
            <td>&#123;&#123;organization.getOpportunities&#125;&#125;</td>
            <td>Organization&apos;s list of opportunities</td>
          </tr>
          <tr>
            <td>&#123;&#123;organization.aieldActivity&#125;&#125;</td>
            <td>Organization&apos;s Why Statement</td>
          </tr>
          <tr>
            <td>&#123;&#123;organization.getImpactStatements&#125;&#125;</td>
            <td>Organization&apos;s Impact Statement</td>
          </tr>
          <tr>
            <td>&#123;&#123;organization.getprimaryMissionFocus&#125;&#125;</td>
            <td>Organization&apos;s Primary Mission Focus</td>
          </tr>
          <tr>
            <td>&#123;&#123;organization.getImpactStatements1&#125;&#125;</td>
            <td>Organization&apos;s First Impact Statement</td>
          </tr>
          <tr>
            <td>&#123;&#123;organization.getImpactStory&#125;&#125;</td>
            <td>Organization&apos;s Impact Story</td>
          </tr>
          <tr>
            <td>&#123;&#123;organization.organizationName&#125;&#125;</td>
            <td>Organization&apos;s Name</td>
          </tr>
          <tr>
            <td>&#123;&#123;organization.contactWebsite&#125;&#125;</td>
            <td>Organization Contact&apos;s Website</td>
          </tr>
          <tr>
            <td>[contact]</td>
            <td>Recipient&apos;s first name</td>
          </tr>
          <tr>
            <td>[company name]</td>
            <td>Recipient Company’s name</td>
          </tr>
          <tr>
            <td>[CSR focus areas]</td>
            <td>Recipient Company’s CSR Focus Areas</td>
          </tr>
          <tr>
            <td>[types of investment]</td>
            <td>Recipient Company</td>
          </tr>
        </table>
        <h2>Example</h2>
        <p>Hello [contact],</p>
        <p>
          <div>
            Take a look at all of
            &#123;&#123;organization.organizationName&#125;&#125;&apos;s opportunities:
          </div>
          <div>&#123;&#123;organization.getOpportunities&#125;&#125;</div>
        </p>

        <p>
          We are excited to partner with you to
          &#123;&#123;organization.aieldActivity&#125;&#125;.
        </p>
        <p>
          <div>Sincerely,</div>
          <div>&#123;&#123;user.firstName&#125;&#125;</div>
        </p>
      </div>
    }
  />
);

export default GameplanTemplateMergeFieldModal;
