import React from 'react';
import { css } from 'styled-components';
import { FieldRenderProps } from 'react-final-form';

import { MillionRangeSlider } from 'components/UI/range';
import { IProps } from 'react-range/lib/types';
import FieldSection from '../FieldSection';

type Props = Partial<IProps> & FieldRenderProps<number[]>;

const labelCss = css`
  margin-bottom: 12px;
  display: block;
`;

const FieldMillionRangeSlider = ({ input, label, meta, ...selectProps }: Props) => {
  return (
    <FieldSection labelCss={labelCss} input={input} meta={meta} label={label}>
      <MillionRangeSlider
        {...selectProps}
        values={input.value}
        onChange={input.onChange}
      />
    </FieldSection>
  );
};

export default FieldMillionRangeSlider;
