import PaginationBlock from 'components/PaginationBlock';
import React from 'react';
import { Field, FieldRenderProps, useForm } from 'react-final-form';
import styled from 'styled-components';
import { ROICanvaTemplatesCreateReq, ROICanvaTemplatesRes } from 'interfaces/roi';
import { UIButton, UIDivider } from 'components/UI';
import { useModal } from 'hooks/common';
import UploadCustomTemplateModal from './components/UploadCustomTemplateModal';

interface Props {
  publicTemplates: ROICanvaTemplatesRes;
  onChangePublicTemplatePage: (page: number) => void;
  privateTemplates: ROICanvaTemplatesRes;
  onChangePrivateTemplatePage: (page: number) => void;
  onFetchROICanvaTemplatesCreate: (params: ROICanvaTemplatesCreateReq) => void;
}

const SelectCanvaTemplateSection = ({
  publicTemplates,
  onChangePublicTemplatePage,
  privateTemplates,
  onChangePrivateTemplatePage,
  onFetchROICanvaTemplatesCreate,
}: Props) => {
  const [showModal, hideModal] = useModal(() => (
    <UploadCustomTemplateModal
      onClose={hideModal}
      handleAccess={params => {
        onFetchROICanvaTemplatesCreate(params);
        hideModal();
      }}
    />
  ));

  const { items: publicList, meta: publicMeta } = publicTemplates;
  const { items: privateList, meta: privateMeta } = privateTemplates;

  const form = useForm();

  return (
    <>
      <ButtonWrapper>
        <UIButton
          modifiers="primary"
          title="Upload Custom Template"
          handleClick={showModal}
        />
      </ButtonWrapper>
      <Divider type="horizontal" />

      <Desc>Please select Design Template</Desc>
      <Title>Customize Partner Report</Title>
      <Field name="template">
        {({ input: { value, onChange } }: FieldRenderProps<string>) => {
          return (
            <ListWrapper>
              {publicList.map(item => (
                <ReportCover
                  key={item.id}
                  $url={item.imageKey}
                  $active={value === item.targetUrl}
                  onClick={() => {
                    form.change('templatePrivate', undefined);
                    onChange(item.targetUrl);
                  }}
                />
              ))}
            </ListWrapper>
          );
        }}
      </Field>
      <PaginationWrapper>
        <PaginationBlock
          page={Number(publicMeta.currentPage)}
          pageLimit={Number(publicMeta.itemsPerPage)}
          submitHandler={onChangePublicTemplatePage}
          total={publicMeta.totalItems}
        />
      </PaginationWrapper>
      <Title>Custom Template</Title>
      <Field name="templatePrivate">
        {({ input: { value, onChange } }: FieldRenderProps<string>) => {
          return (
            <ListWrapper>
              {privateList.map(item => (
                <ReportCover
                  key={item.id}
                  $url={item.imageKey}
                  $active={value === item.targetUrl}
                  onClick={() => {
                    form.change('template', undefined);
                    onChange(item.targetUrl);
                  }}
                />
              ))}
            </ListWrapper>
          );
        }}
      </Field>
      <PaginationWrapper>
        <PaginationBlock
          page={Number(privateMeta.currentPage)}
          pageLimit={Number(privateMeta.itemsPerPage)}
          submitHandler={onChangePrivateTemplatePage}
          total={privateMeta.totalItems}
          customUrlPageName="page2"
        />
      </PaginationWrapper>
    </>
  );
};

const Title = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 32px;
`;

const Desc = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 16px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  min-height: 246px;
`;

const ReportCover = styled.div<{ $url?: string; $active: boolean }>`
  width: 162px;
  height: 230px;
  background-size: cover;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  cursor: pointer;
  background-repeat: no-repeat;

  ${({ $url }) =>
    $url
      ? `
      background-image: url(${$url});
  `
      : `
      border-color: lightgray;
  `}

  ${({ $active }) =>
    $active &&
    `
    border: 1px solid var(--blue);
    box-shadow: 0 0 4px var(--blue);
  `}
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Divider = styled(UIDivider)`
  margin: 16px 0px 24px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default SelectCanvaTemplateSection;
