/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import styled from 'styled-components';
import { UIButton, UIModal } from 'components/UI';
import { SelectOption, UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { Form } from 'react-final-form';
import { Field } from 'components/FinalForm';
import { FiledMultiSelectWithCheckBoxes } from 'components/FormFields';

interface Props extends InjectedProps {
  isLoading?: boolean;
  handleAccess: (companyIds: string[]) => void;
  companiesOnlyIdNameOptions: SelectOption[];
}

const DeleteCompanyModal = ({
  onClose,
  handleAccess,
  isLoading,
  companiesOnlyIdNameOptions,
  ...modalProps
}: Props) => {
  useLockBodyScroll();

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{
        padding: '40px 0 20px',
      }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '559px',
        height: '455px',
      }}
    >
      <Title>Delete Company</Title>
      <Form<{ selectCompany: SelectOption[]; updateAll: boolean }>
        onSubmit={values => {
          if (values.updateAll) {
            handleAccess(companiesOnlyIdNameOptions.map(item => item.value as string));
            return;
          }
          handleAccess(values.selectCompany.map(item => item.value as string));
        }}
        render={({ handleSubmit }) => (
          <FormBody>
            <Field
              label="Select Company for Delete"
              name="selectCompany"
              component={FiledMultiSelectWithCheckBoxes}
              options={companiesOnlyIdNameOptions}
            />
            <BtnGroup>
              <Btn modifiers="secondary" title="Cancel" handleClick={onClose} />
              <Btn
                style={{ background: '#FFF5F5' }}
                modifiers="danger"
                title="Delete"
                isLoading={isLoading}
                handleClick={handleSubmit}
              />
            </BtnGroup>
          </FormBody>
        )}
      />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 46px;
  text-align: center;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Btn = styled(UIButton)`
  display: block;
  width: 87px;
  font-size: 14px;
  padding: 7px 0;
`;

const FormBody = styled.div`
  margin: 0 92px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default DeleteCompanyModal;
