import Jumbotron from 'components/Jumbotron';
import { useOperation } from 'hooks/common';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { fetchCampaignList } from 'store/ducks/campaign/operations';
import { getCampaignList } from 'store/ducks/campaign/selectors';
import styled from 'styled-components';
import MobileCampaigns from 'components/Campaign/CampaignListTeamSnap';
import { UISpinner } from 'components/UI';

const CampaignTS = () => {
  const [onFetchCampaignList, isLoading] = useOperation(fetchCampaignList);

  const { items } = useSelector(getCampaignList);

  const search = { type: 'Manage_Campaigns' };
  const { location } = useHistory();
  const Title = styled.h1`
    font-size: 32px; // Set the size as per your preference
    font-weight: bold;
    color: #333; // Set the color if you have a preference
    margin-bottom: 20px; // Space below the title
  `;

  useEffect(() => {
    const pageString = queryString.parse(location.search)?.page?.toString() || '';
    onFetchCampaignList({ page: Number(pageString) || 1 });
  }, [location.search, onFetchCampaignList]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <ContentBlockWrapper header={<Jumbotron name="Fundraising" />}>
      <MobileBody>
        <Title>Manage Campaign</Title>
        <br></br>
        {search.type === 'Manage_Campaigns' && (
          <>
            <MobileCampaigns
              campaigns={items.map(item => ({
                id: item.id,
                campaignName: item.campaignName || 'Unknown Campaign',
                giveworxCampaignId: item.giveworxCampaignId,
                startDate: item.startDate,
                endDate: item.endDate,
                goal: item.fundraisingGoal,
                description: item.campaignDescription,
                campaignColors: item.selectCampaignColors,
                customContentImageName:
                  item.customContentImageName || 'TSDefaultImage1.jpg',
              }))}
            />
          </>
        )}
      </MobileBody>
    </ContentBlockWrapper>
  );
};

const MobileBody = styled.div`
  max-width: 100%; // Adjust for mobile width
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default CampaignTS;
