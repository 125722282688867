import { useDownloadFile } from 'hooks/common';
import React from 'react';
import styled from 'styled-components';

interface Props {
  fetchFile: () => Promise<{
    name: string;
    file: string;
  }>;
}

const DownloadTemplate = ({ fetchFile }: Props) => {
  const downloadFile = useDownloadFile(fetchFile);

  return (
    <Root>
      Download Excel template <Here onClick={() => downloadFile('')}>here</Here>
    </Root>
  );
};

const Root = styled.div`
  background: #f3fcff;
  font-size: 16px;
  line-height: 145%;
  height: 66px;
  display: flex;
  align-items: center;
  padding: 24px;
  margin-bottom: 24px;
`;

const Here = styled.span`
  color: var(--blue);
  margin-left: 0.5em;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default DownloadTemplate;
