import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';

interface BaseProps {
  title?: ReactNode;
  description?: ReactNode;
  onClickOk?: () => void;
  buttonText?: string;
  buttonCancelText?: boolean;
}

export type Props = BaseProps & InjectedProps;

const InfoModal = ({
  title,
  description,
  onClose,
  onClickOk,
  isloading,
  buttonText,
  buttonCancelText = false,
  ...modalProps
}: Props) => {
  useLockBodyScroll();

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 24px 24px' }}
      style={{
        width: '100%',
        maxWidth: '380px',
      }}
      hideCloseIcon
    >
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
      {buttonCancelText ? (
        <ButtonGroup>
          <Button
            style={{ width: '133px' }}
            handleClick={onClose}
            modifiers={'secondary'}
            title={'Cancel'}
          />
          <Button
            style={{ width: '140px' }}
            modifiers={'primary'}
            title={buttonText || 'OK'}
            isLoading={isloading}
            handleClick={onClickOk}
          />
        </ButtonGroup>
      ) : (
        <Button
          modifiers="primary"
          title={buttonText || 'OK'}
          isLoading={isloading}
          handleClick={onClickOk}
        />
      )}
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0 auto 8px;
  text-align: center;
`;

const Description = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: var(--black);
  text-align: center;
  margin-bottom: 24px;
`;

const Button = styled(UIButton)`
  width: 100%;
  font-size: 12px;
`;
const ConfirmDiv = styled.div`
  text-align: center;
  width: 86%;
  margin: auto;
`;
const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding: 0;

  & > div {
    button:not(:first-child) {
      margin-left: 12px;
    }
  }
`;
const Btn = styled(UIButton)`
  width: 138px;
  font-size: 14px;
  padding: 7px 24px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;

  button:first-child {
    margin-right: 8px;
  }
`;
export default InfoModal;
