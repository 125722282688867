/* eslint-disable react-hooks/exhaustive-deps */
import { UIButton, UICheckbox } from 'components/UI';
import { ROIData, ROIListReq, ROIUIType } from 'interfaces/roi';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import DiamondSVG from 'assets/images/icons/diamond.svg';
import ChartLineSVG from 'assets/images/icons/chart-line.svg';
import ClipBoard from 'assets/images/icons/clipboard.svg';
import EllipseSVG from 'assets/images/icons/ellipse.svg';
import { ReactSVG } from 'react-svg';
import DropdownList from 'components/DropdownList/DropdownList';
import { format } from 'date-fns';
import { useModal, useOperation } from 'hooks/common';
import { ConfirmModal } from 'components/Modals';
import {
  ROIEditNavigationEnum,
  ROIListDropdownOptions,
  ROIListTitleExplain,
  ROIReportEditNavigationEnum,
} from 'constants/roi';
import { useHistory } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { PaginationMeta } from 'interfaces/common';
import Pagination from 'components/Pagination';
import { getUTCAsLocalDate } from 'utils';
import EditPartnerByTeamModal from './modals/EditPartnerByTeamModal';
import { fetchROIUpdate } from 'store/ducks/roi/operations';
import EditPartnerModal from './modals/EditPartnerModal';
import EyeSVG from 'assets/images/icons/eye-light.svg';

type Option = typeof ROIListDropdownOptions[number];

interface Props {
  meta: PaginationMeta;
  roiList: ROIData[];
  onROIDelete: (ids: string[]) => Promise<unknown>;
  onROISelect: (roiIds: string[]) => void;
  selectedROI: string[];
  onSearch: (req: ROIListReq) => void;
  searchValue: string;
  timerRange: string;
  type: ROIUIType;
}

const ROIList: FC<Props> = ({
  meta,
  roiList,
  selectedROI,
  onROIDelete,
  onROISelect,
  onSearch,
  searchValue,
  timerRange,
  type,
}) => {
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [roi, setRoi] = useState<ROIData | undefined>(undefined);

  const { push } = useHistory();

  const [onFetchROIUpdate] = useOperation(fetchROIUpdate);

  const [showConfirmModal, hideConfirmModal] = useModal(
    () => (
      <ConfirmModal
        name="Delete"
        description="Delete the selected partnership?"
        textAccessBtn="Delete"
        textCancelBtn="Back to Page"
        accessHandler={() => {
          onROIDelete(selectedROI);
          hideConfirmModal();
        }}
        onClose={hideConfirmModal}
        cancelHandler={hideConfirmModal}
      />
    ),
    [selectedROI],
  );

  const [showDeleteSingleModal, hideDeleteSingleModal] = useModal(
    () => (
      <ConfirmModal
        name="Delete"
        description="Deleting this partnership will remove (with the inability to recover) all associated media tracking information and reporting.  Are you sure you want to delete this partnership?"
        textAccessBtn="Delete"
        textCancelBtn="Back to Page"
        accessHandler={() => {
          onROIDelete([deleteId]);
          hideDeleteSingleModal();
        }}
        onClose={hideDeleteSingleModal}
        cancelHandler={hideDeleteSingleModal}
      />
    ),
    [deleteId],
  );

  const [showEditPartnerByTeamModal, hideEditPartnerByTeamModal] = useModal(
    () => (
      <EditPartnerByTeamModal
        roi={roi}
        handleAccess={values => {
          onFetchROIUpdate(values).then(() => hideEditPartnerByTeamModal());
        }}
        onClose={hideEditPartnerByTeamModal}
      />
    ),
    [roi],
  );

  const [showEditPartnerModal, hideEditPartnerModal] = useModal(
    () => (
      <EditPartnerModal
        roi={roi}
        handleAccess={values => {
          onFetchROIUpdate(values).then(() => hideEditPartnerModal());
        }}
        onClose={hideEditPartnerModal}
      />
    ),
    [roi],
  );

  const handleDrapdownListClick = useCallback(
    (option: string, roi: ROIData) => {
      switch (option) {
        case 'Edit':
          setRoi(roi);
          if (roi.isCompany) {
            showEditPartnerByTeamModal();
          } else {
            showEditPartnerModal();
          }
          break;
        case 'Delete':
          setDeleteId(roi.id);
          showDeleteSingleModal();
          break;
        default:
          break;
      }
    },
    [ROIListDropdownOptions],
  );

  const handleRowSelect = useCallback(
    (id: string) => {
      if (selectedROI.includes(id))
        return onROISelect(selectedROI.filter(item => item !== id));

      onROISelect([...selectedROI, id]);
    },
    [selectedROI],
  );

  useEffect(() => {
    if (isSelectAll) return onROISelect(roiList.map(item => item.id));

    onROISelect([]);
  }, [isSelectAll]);

  useEffect(() => {
    ReactTooltip.rebuild();
    return () => {
      ReactTooltip.hide();
    };
  }, []);

  return (
    <Root>
      <SelectWrapper>
        <SelectAll>
          <Checkbox
            check={isSelectAll}
            handleClick={() => setIsSelectAll(!isSelectAll)}
          />
          Select All
        </SelectAll>
        <Actions>
          <DeleteButton
            title="Delete"
            modifiers="danger"
            disabled={!selectedROI.length}
            handleClick={showConfirmModal}
          />
          {meta && meta.itemCount > 0 && (
            <Pagination
              total={meta.totalItems}
              pageLimit={Number(meta.itemsPerPage)}
              page={Number(meta.currentPage)}
              submitHandler={(page: number) =>
                onSearch({ page, q: searchValue, timerRange })
              }
            />
          )}
        </Actions>
      </SelectWrapper>
      <Body>
        <List>
          {roiList.map(item => (
            <ListItem key={item.id}>
              <Checkbox
                check={selectedROI.includes(item.id)}
                handleClick={() => handleRowSelect(item.id)}
              />
              <ItemBlock>
                <BlockTitle>
                  <TitleExplain>{ROIListTitleExplain[type]}</TitleExplain>
                  {item.companyName}
                </BlockTitle>
                <PartnershipDescription>
                  <TitleExplain>Partnership Description</TitleExplain>
                  {item.name || 'N/A'}
                </PartnershipDescription>
                <RenewalDate>
                  <TitleExplain>Renewal Date</TitleExplain>
                  {item.renewalDate
                    ? format(getUTCAsLocalDate(item.renewalDate), 'MMM dd, yyyy')
                    : 'N/A'}
                </RenewalDate>
                <Actions>
                  <TagGroup>
                    <IconLink
                      src={EyeSVG}
                      onClick={() =>
                        item.isCompany
                          ? push(`/roi/view-team/${item.teamId}`)
                          : push(`/roi/view-company/${item.companyId}`)
                      }
                    />
                    {type === 'organization' && (
                      <IconLink
                        data-tip="Steward"
                        src={DiamondSVG}
                        onClick={() =>
                          push(
                            `/roi/edit/${item.id}?type=${ROIEditNavigationEnum.Steward}`,
                          )
                        }
                      />
                    )}

                    <IconLink
                      data-tip="Tracking"
                      src={ChartLineSVG}
                      onClick={() => {
                        push(
                          // `/roi/edit/${item.id}?type=${ROIEditNavigationEnum.Measure}&secondaryType=${ROIEditMeasureNavigationEnum.Tracking}`,
                          `/roi/tracking/${item.id}`,
                        );
                      }}
                    />
                    <IconLink
                      data-tip="Partner Report"
                      src={ClipBoard}
                      onClick={() => {
                        if (type === 'company') {
                          push(
                            `/roi/report-edit/${item.id}?type=${ROIReportEditNavigationEnum.Partner_Data_Intake}`,
                          );
                          return;
                        }
                        push(
                          // `/roi/edit/${item.id}?type=${ROIEditNavigationEnum.Measure}&secondaryType=${ROIEditMeasureNavigationEnum.Impact_Report}`,
                          `/roi/report-edit/${item.id}?type=${ROIReportEditNavigationEnum.Partner_Data_Intake}`,
                        );
                      }}
                    />
                  </TagGroup>
                  <DropdownList
                    title={<EllipseIcon src={EllipseSVG} />}
                    options={ROIListDropdownOptions}
                    disableArrow
                    onClick={option => handleDrapdownListClick(option, item)}
                  />
                </Actions>
              </ItemBlock>
            </ListItem>
          ))}
        </List>
      </Body>
    </Root>
  );
};

const Root = styled.div``;

const Body = styled.div`
  overflow: auto;
  height: 540px;
`;

const List = styled.div`
  & > div:not(:first-child) {
    margin-top: 16px;
  }
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
`;

const ItemBlock = styled.div`
  width: 100%;
  height: 87px;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
`;

const BlockTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  width: 300px;
`;

const Checkbox = styled(UICheckbox)`
  width: 20px;
  height: 20px;
  margin-right: 16px;
`;

const Actions = styled.div`
  display: flex;
`;

const EllipseIcon = styled(ReactSVG)`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 30px;
`;

const ActionsValue = styled.div`
  font-size: 12px;
  vertical-align: bottom;
`;

const PartnershipDescription = styled(ActionsValue)`
  width: 320px;
`;
const RenewalDate = styled(ActionsValue)`
  width: 120px;
`;

const TagGroup = styled.div`
  display: flex;
  margin-right: 24px;

  & > div:not(:first-child) {
    margin-left: 14px;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const SelectAll = styled.div`
  display: flex;
  user-select: none;
`;

const DeleteButton = styled(UIButton)`
  width: 76px;
  margin-right: 24px;
`;

const TitleExplain = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
`;

const IconLink = styled(ReactSVG)`
  cursor: pointer;
`;

export default ROIList;
