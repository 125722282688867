import { Field } from 'components/FinalForm';
import { FieldWYSIWYGEditor } from 'components/FormFields';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps } from 'components/UI/interfaces';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import { ROITemplate } from 'interfaces/roi';
import React from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';

interface BaseProps {
  onSubmit: (values: ROITemplate) => OnSubmitReturnType;
  roiTemplate: ROITemplate;
}

type Props = BaseProps & UIModalProps;

function TemplateStewardshipActivationModal({
  onClose,
  onSubmit = () => {},
  roiTemplate,
  ...modalProps
}: Props) {
  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '28px 40px 40px' }}
      style={{
        width: '100%',
        maxWidth: '844px',
        top: '100px',
      }}
      overlayStyle={{
        overflow: 'auto',
      }}
      header={<ModalTitle>{roiTemplate.StewardshipName}</ModalTitle>}
    >
      <Form
        initialValues={{ ...roiTemplate }}
        onSubmit={(values: ROITemplate) => onSubmit({ ...roiTemplate, ...values })}
        render={({ handleSubmit }) => {
          return (
            <FormBody>
              <Field
                id="additional-custom-stewardship-activation-editor"
                name="body"
                label="Additional Custom Stewardship Activation"
                component={FieldWYSIWYGEditor}
                format={(value: string) =>
                  value
                    ? value.trim().startsWith('<p>')
                      ? value
                      : `<p>${value}</p>`
                    : '<p></p>'
                }
              />
              <ButtonGroup>
                <ButtonGroup>
                  <Button title="Cancel" modifiers="secondary" handleClick={onClose} />
                </ButtonGroup>
                <Button title="Save" modifiers="primary" handleClick={handleSubmit} />
              </ButtonGroup>
            </FormBody>
          );
        }}
      />
    </UIModal>
  );
}

const ModalTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  padding: 40px 0 16px;
`;

const FormBody = styled.div`
  position: relative;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;

  & > button:not(:first-child) {
    margin-left: 8px;
  }
`;

const Button = styled(UIButton)`
  width: 106px;
`;

export default TemplateStewardshipActivationModal;
