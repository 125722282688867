import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Toast from 'services/Toast';
import { ReactSVG } from 'react-svg';
import { UISpinner } from 'components/UI';
import { lessThan5MB } from 'utils/validation';
import { useFileUrl } from 'hooks/common';
import PlusSVG from 'assets/images/icons/plus.svg';
import { FieldRenderProps } from 'react-final-form';
import { uploadFile } from 'store/ducks/utility/api';
import CloseCircleGraySVG from 'assets/images/icons/close-circle-gray.svg';

const FieldUploadLogo = ({ input, ...rest }: FieldRenderProps<string>) => {
  const { value: imgKey, onChange } = input;
  const [loading, setLoading] = useState<boolean>(false);
  const { fileUrl, setFileUrl } = useFileUrl({ imgKey });

  const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files) {
      const file = event.target.files[0];
      const sizeError = lessThan5MB(file.size);
      setLoading(true);

      if (sizeError) {
        setLoading(false);
        return Toast.error(sizeError);
      }

      uploadFile(file, 'logos').then(v => {
        setLoading(false);
        if (v.status === 'success') {
          onChange(v.fileKey);
          setFileUrl(URL.createObjectURL(file));
          return Toast.success('File uploaded successfully');
        }
        Toast.error('Error upload file');
      });
    }
  };

  useEffect(() => {
    setFileUrl(imgKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgKey]);

  return (
    <Root>
      {loading ? (
        <Avatar>
          <UISpinner width={30} height={30} />
        </Avatar>
      ) : fileUrl ? (
        <AvatarImg
          htmlFor="upload-photo-file"
          style={{
            backgroundImage: `url(${fileUrl})`,
            // backgroundImage:
            // 'url(https://accelerist.s3.amazonaws.com/logos/44c4ccdb-350d-4b19-ae2d-32ddc6fd8bde/16ad59a6-5109-456f-891e-296304652bab.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIZDX3DWLMFIETGPA%2F20220624%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220624T081703Z&X-Amz-Expires=5000&X-Amz-Signature=e19cc68b471d7d5fb707618c5b563c85a2f6a46ff17ba08ec76a3a130a8f4985&X-Amz-SignedHeaders=host)',
          }}
        >
          <CloseIcon
            src={CloseCircleGraySVG}
            onClick={(e: MouseEvent) => {
              onChange(undefined);
              e.preventDefault();
            }}
          />
        </AvatarImg>
      ) : (
        <Avatar htmlFor="upload-photo-file">
          <EmptyAvatar src={PlusSVG} />
        </Avatar>
      )}

      <div>
        <Input
          id="upload-photo-file"
          onChange={onUpload}
          type="file"
          accept="image/*"
          {...rest}
        />
      </div>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Avatar = styled.label`
  width: 162px;
  height: 74px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
`;

const AvatarImg = styled(Avatar)`
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
`;

const EmptyAvatar = styled(ReactSVG)`
  & svg {
    width: 48px;
    height: 48px;
  }
`;

const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  display: inline-block;
`;

const CloseIcon = styled(ReactSVG)`
  position: absolute;
  right: -8px;
  top: -8px;
`;

export default FieldUploadLogo;
