import React, { useCallback, useMemo } from 'react';
import useDropdown from 'use-dropdown';
import { ReactSVG } from 'react-svg';
import ArrowDownIcon from 'assets/images/icons/arrow-down.svg';
import { SingleSelectProps } from './interfaces';
import styled from 'styled-components';

interface Props {
  numberRange?: [number, number];
  numbers?: number[];
  value: number;
  handleSelect: (num: number) => void;
  error?: boolean;
  disabled?: boolean;
  onBlur?: () => void;
}

const NumberSelect = ({
  numberRange,
  numbers,
  value,
  handleSelect,
  disabled = false,
  error,
  onBlur,
}: Props) => {
  const [containerRef, isOpen, open, close] = useDropdown();

  const numberOptions = useMemo(() => {
    if (!numberRange) return numbers || [];
    return [...Array(numberRange[1] - numberRange[0]).keys()].map(
      item => item + numberRange[0],
    );
  }, [numberRange, numbers]);

  const selectedNumber = numberOptions.find(number => number === value);

  const addItem = (item: number) => {
    handleSelect(item);
    close();
  };

  const toggleDropdown = useCallback(() => {
    if (disabled) return;

    if (isOpen) {
      close();
    } else {
      open();
    }
  }, [disabled, isOpen, open, close]);

  return (
    <Root className={`uiSelect__wrapper`} ref={containerRef}>
      <Trigger
        onClick={toggleDropdown}
        open={isOpen}
        disabled={disabled}
        error={error}
        tabIndex={-1}
        onBlur={() => onBlur?.()}
      >
        <Value disabled={disabled}>{selectedNumber}</Value>
        <Arrow src={ArrowDownIcon} open={isOpen} disabled={disabled} />
      </Trigger>
      {isOpen && (
        <ListWrapper>
          {numberOptions && numberOptions.length > 0 ? (
            <ul>
              {numberOptions
                .filter(item => !(item === selectedNumber))
                .map((item, index) => {
                  return (
                    <Item key={index} onClick={() => addItem(item)}>
                      {item}
                    </Item>
                  );
                })}
            </ul>
          ) : (
            <p className="uiSelect__empty">Empty list</p>
          )}
        </ListWrapper>
      )}
    </Root>
  );
};

const Root = styled.div`
  width: 56px;
  height: 32px;
  flex-shrink: 0;
  position: relative;

  .uiSelect__empty {
    padding: 20px 10px;
    text-align: center;
  }
`;

const Trigger = styled.div<{
  open?: boolean;
  disabled?: boolean;
  error?: SingleSelectProps['error'];
}>`
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0 8px;
  width: 100%;
  height: 100%;

  ${({ open }) =>
    open && `border-radius: 8px 8px 0 0; border-bottom: 1px solid transparent;`}
  ${({ disabled }) =>
    disabled && `background: #F9F9F9; border-color: #F9F9F9; cursor: not-allowed;`}
  ${({ error }) =>
    error &&
    `
      background: #FFF2F2;
      border: 1px solid var(--red);
      &:focus{
        border: 1px solid var(--red);
      }
    `}
`;

const Arrow = styled(ReactSVG)<{ open?: boolean; disabled?: boolean }>`
  transition: all 0.2s ease;
  ${({ open }) => open && `transform: rotate(-180deg);`}
  ${({ disabled }) => disabled && `svg path { stroke: #BFBFBF }`}
`;

const Value = styled.p<{ disabled?: boolean }>`
  font-size: 16px;
  line-height: 150%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ disabled }) => disabled && `color: #BFBFBF;`}
`;

const ListWrapper = styled.div`
  width: 62px;
  max-height: 180px;
  background: #ffffff;
  position: absolute;
  z-index: 9;
  top: 45px;
  left: 0;
  overflow-y: auto;
  border: 1px solid #e8e8e8;
  border-top: 0;
  border-radius: 0px 0px 8px 8px;
`;

const Item = styled.li`
  font-size: 16px;
  line-height: 150%;
  padding: 10px 16px;
  transition: all 0.2s ease;
  &:hover {
    cursor: pointer;
    background: #e9f9ff;
  }
`;

export default NumberSelect;
