import { Field } from 'components/FinalForm';
import {
  FieldDatePicker,
  FieldSingleSelect,
  FieldTextArea,
  FieldTextInput,
  FiledMultiSelectWithCheckBoxes,
  MaskedMoneyTextInput,
} from 'components/FormFields';
import { formatValueEnum } from 'components/UI/interfaces';
import UIAsterisk from 'components/UI/UIAsterisk';
import {
  PartnershipGoalsEnum,
  PartnershipGoalsOptions,
  ROINavigationEnum,
} from 'constants/roi';
import { ROIData, StewardFormData } from 'interfaces/roi';
import React, { useMemo } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { useHistory } from 'react-router';
import { getDateCutOffTime } from 'utils';
import { email, required } from 'utils/validation';
import {
  ActionText,
  ButtonGroup,
  FieldDoubleRow,
  FieldWrapper,
  Title,
  ViewStewardshipPlan,
  DeleteBtn,
} from './styled';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import CloseIcon from 'assets/images/icons/close.svg';
import { UIButton } from 'components/UI';

interface Props {
  roi: ROIData;
  disabled: boolean;
  onSubmit: (values: ROIData) => Promise<unknown>;
  onGenerate: (values: ROIData) => Promise<unknown>;
  onViewTemplates: (roiId: string) => void;
  onChangeRoiStatus: (check: boolean) => void;
}

const StewardForm = ({ roi, disabled, onSubmit, onGenerate, onViewTemplates }: Props) => {
  const { push } = useHistory();

  const initValue = useMemo(
    () => ({
      ...roi,
      partnershipGoals: roi.partnershipGoals?.map(item => item.PartnershipGoal),
      longevityOfPartnership:
        roi.longevityOfPartnership && Number(roi.longevityOfPartnership),
    }),
    [roi],
  );

  const processValues = (values: StewardFormData): ROIData => ({
    ...roi,
    ...values,
    partnershipGoals:
      values.partnershipGoals && values.partnershipGoals.length !== 0
        ? values.partnershipGoals.map(item => ({
            PartnershipGoal: item,
            Text: PartnershipGoalsEnum[item],
          }))
        : [],
    contacts: values.contacts?.filter(item => item),
  });

  return (
    <Form
      initialValues={initValue}
      mutators={{
        ...arrayMutators,
      }}
      onSubmit={values => {
        onSubmit(processValues(values));
      }}
      render={({
        handleSubmit,
        values,
        form: {
          mutators: { push: formArrPush },
        },
      }: FormRenderProps<StewardFormData>) => (
        <>
          <Title>Stewardship</Title>
          <FieldDoubleRow>
            <Field
              name="partnershipGoals"
              label="Partnership Goals"
              component={FiledMultiSelectWithCheckBoxes}
              visibleNumberToTooltip={3}
              formatValue={formatValueEnum.BASE_ARRAY}
              options={PartnershipGoalsOptions}
              disabled={disabled}
            />
            <Field
              name="renewalDate"
              label="Renewal Date"
              parse={v => getDateCutOffTime(v)}
              component={FieldDatePicker}
              disabled={disabled || roi.renewalDate}
            />
          </FieldDoubleRow>
          <FieldDoubleRow>
            <Field
              name="potentialCommitment"
              label={
                <>
                  Potential Commitment
                  <UIAsterisk />
                </>
              }
              component={MaskedMoneyTextInput}
              validate={required}
              disabled={disabled}
            />
            <Field
              name="renewalConfrim"
              label="Renewal Confirm"
              component={FieldSingleSelect}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              placeholder="Select"
              disabled={disabled}
            />
          </FieldDoubleRow>
          <FieldDoubleRow>
            <Field
              name="longevityOfPartnership"
              label={
                <>
                  Longevity of Partnership
                  <UIAsterisk />
                </>
              }
              component={FieldSingleSelect}
              options={[
                { label: '<1', value: 1 },
                { label: '1-3', value: 2 },
                { label: '4-10', value: 3 },
                { label: '11-20', value: 4 },
                { label: '21-30', value: 5 },
                { label: '31+', value: 6 },
              ]}
              placeholder="Search"
              validate={required}
              disabled={disabled}
            />
          </FieldDoubleRow>

          <FieldWrapper>
            <Field
              name="notes"
              label="Notes"
              component={FieldTextArea}
              disabled={disabled}
            />
          </FieldWrapper>

          <Title>Contact</Title>
          <FieldArray name="contacts">
            {({ fields }) =>
              fields.map((name, index) => (
                <div key={index}>
                  <FieldDoubleRow>
                    <Field
                      name={`${name}.firstName`}
                      label={`Contact ${index + 1} First Name`}
                      component={FieldTextInput}
                      disabled={disabled}
                    />
                    <Field
                      name={`${name}.lastName`}
                      label={`Contact ${index + 1} Last Name`}
                      component={FieldTextInput}
                      disabled={disabled}
                    />
                  </FieldDoubleRow>
                  <FieldDoubleRow>
                    <Field
                      name={`${name}.jobTitle`}
                      label="Contact Title"
                      component={FieldTextInput}
                      disabled={disabled}
                    />
                    <Field
                      name={`${name}.email`}
                      label="Contact Email"
                      component={FieldTextInput}
                      validate={email}
                      disabled={disabled}
                    />
                    <DeleteBtn src={CloseIcon} onClick={() => fields.remove(index)} />
                  </FieldDoubleRow>
                </div>
              ))
            }
          </FieldArray>
          <ActionText onClick={() => formArrPush('contacts', undefined)}>
            Add {!!values.contacts?.length && 'another'} contact
          </ActionText>

          <ButtonGroup>
            <UIButton
              title="Cancel"
              modifiers="secondary"
              handleClick={() => push(`/roi?type=${ROINavigationEnum.Partnerships}`)}
            />
            <UIButton
              title="Save"
              modifiers="secondary"
              disabled={disabled}
              handleClick={handleSubmit}
            />
            {(!roi?.templates || roi.templates.length === 0) && (
              <UIButton
                title="Generate Stewardship Plan"
                modifiers="primary"
                disabled={disabled}
                handleClick={() => {
                  onGenerate(processValues(values));
                }}
              />
            )}

            <ViewStewardshipPlan>
              {roi?.templates?.length !== 0 && (
                <ActionText onClick={() => onViewTemplates(roi.id)}>
                  View Stewardship Plan
                </ActionText>
              )}
            </ViewStewardshipPlan>
          </ButtonGroup>
        </>
      )}
    />
  );
};

export default StewardForm;
