import axios from 'axios';
import {
  Charitable,
  CharitablesReq,
  GivingDeleteReq,
  IssueMappingSurveyCreateReq,
  IssueMappingSurveyListReq,
  IssueMappingSurveyResult,
} from 'interfaces/issueMapping';
import { Dispatch } from 'redux';
import Toast from 'services/Toast';
import { fetchErrorHandler } from 'utils/errorHandlers';

import * as actions from './actions';
import * as api from './api';

export const fetchIssueMappingSurveyCreate = (
  params: IssueMappingSurveyCreateReq,
) => async () => {
  return api
    .fetchIssueMappingSurveyCreate(params)
    .then(() => {
      if (params.isDraft) {
        return Toast.success('Your survey has been saved');
      }
      Toast.success('Your request has been submitted successfully');
    })
    .catch(() => Toast.error('Error request'));
};

export const fetchIssueMappingSurveyUpdate = (
  params: IssueMappingSurveyCreateReq,
) => async () => {
  return api
    .fetchIssueMappingSurveyUpdate(params)
    .then(() => {
      if (params.isDraft) {
        return Toast.success('Your survey has been saved');
      }
      Toast.success('Your request has been submitted successfully');
    })
    .catch(() => Toast.error('Error request'));
};

export const fetchIssueMappingSurveyList = (params: IssueMappingSurveyListReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchIssueMappingSurveyList(params)
    .then(value => dispatch(actions.setIssueMappingSurveyList(value)))
    .catch(() => Toast.error('Error get Survey list'));
};

export const fetchIssueMappingSurveyDelete = (ids: string[]) => async () => {
  return api
    .fetchIssueMappingSurveyDelete(ids)
    .then(() => {
      Toast.success('Successfully deleted');
    })
    .catch(() => {
      Toast.error('Error delete reports');
    });
};

export const fetchIssueMappingSurveyResult = (id: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchIssueMappingSurveyResult(id)
    .then(result => {
      dispatch(actions.setIssueMappingSurveyResult(result));
    })
    .catch(err => {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 400) return 'Deleted';
      }
      Toast.error('Error get survey data');
    });
};

export const fetchIssueMappingSurveyResultSubmit = (
  params: IssueMappingSurveyResult,
) => async () => {
  return api
    .fetchIssueMappingSurveyResultSubmit(params)
    .then(() => {
      Toast.success('Thank you to participate in the survey');
    })
    .catch(() => {
      Toast.error('Submit failed');
    });
};

export const fetchRecommendation = () => async (dispatch: Dispatch) => {
  return api
    .fetchRecommendation()
    .then(data => dispatch(actions.setRecommendation(data)))
    .catch(() => {
      Toast.error('Error get recommendation');
    });
};

export const fetchCharitables = (params: CharitablesReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchCharitables(params)
    .then(data => {
      dispatch(actions.setCharitables(data));
    })
    .catch(fetchErrorHandler);
};

export const fetchGivingCreate = (charitables: Charitable[]) => async () => {
  return api.fetchGivingCreate(charitables).catch(fetchErrorHandler);
};

export const fetchGivingDelete = (params: GivingDeleteReq) => async () => {
  return api.fetchGivingDelete(params).catch(fetchErrorHandler);
};
