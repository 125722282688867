import { BatchListItem, GivingListRes } from 'interfaces/giving';
import * as types from './types';

export const setGivingList = (list: GivingListRes) =>
  ({
    type: types.SET_GIVING_LIST,
    payload: list,
  } as const);

export const setBatchList = (list: BatchListItem[]) =>
  ({
    type: types.SET_BATCH_LIST,
    payload: list,
  } as const);
