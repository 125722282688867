/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import OrganizationWrapper from 'layouts/OrganizationWrapper';
import { InviteUser, UserTable } from 'components/team';
import { headTableUsers } from 'constants/team';
import { useAcceptInviteTeam, useOperation } from 'hooks/common';
import { fetchTeamMembers } from 'store/ducks/team/operations';
import { useSelector } from 'react-redux';
import { StateProps } from 'store/interfaces';
import { UserPreview } from 'interfaces/common';
import { teamSelectors } from 'store/ducks/team';

const Users = () => {
  useAcceptInviteTeam();
  const [loadTeamMembers, isStartLoading] = useOperation(fetchTeamMembers);
  const users = useSelector<StateProps, UserPreview[]>(teamSelectors.getTeamMembers);

  useEffect(() => {
    loadTeamMembers(undefined);
  }, []);

  return (
    <OrganizationWrapper isLoading={isStartLoading}>
      <InviteUser />
      <UserTable rows={users} headCells={headTableUsers} />
    </OrganizationWrapper>
  );
};

export default Users;
