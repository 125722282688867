import axios from 'axios';
import { ROIEditNavigationEnum } from 'constants/roi';
import { CompanyCreateReq } from 'interfaces/companies';
import {
  ROICanva,
  ROICanvaTemplatesCreateReq,
  ROICanvaTemplatesReq,
  ROICreateReq,
  ROIData,
  ROIDisableReq,
  ROIListReq,
  ROISurvey,
  ROISurveyListReq,
  ROISurveyResult,
  ROITemplate,
} from 'interfaces/roi';
import { TeamCreateReq } from 'interfaces/team';
import { Dispatch } from 'redux';
import Toast from 'services/Toast';
import { StateProps } from 'store/interfaces';
import { fetchErrorHandler } from 'utils/errorHandlers';
import * as actions from './actions';
import * as api from './api';

export const fetchROIList = (param: ROIListReq) => async (dispatch: Dispatch) => {
  return api
    .fetchROIList(param)
    .then(value => dispatch(actions.setROIList(value)))
    .catch(() => Toast.error('Error get Partnership list'));
};

export const fetchROIListByCompanies = (param: ROIListReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchROIListByCompanies(param)
    .then(value => dispatch(actions.setROIList(value)))
    .catch(() => Toast.error('Error get Partnership list'));
};

export const fetchROICreate = (params: ROICreateReq) => async (dispatch: Dispatch) => {
  return api
    .fetchROICreate({ ...params, activeMeasure: true, activeStewardship: true })
    .then(roi => dispatch(actions.createROI(roi)))
    .catch(err => {
      return err.response.data.statusCode === 400
        ? Toast.error(err.response.data.message)
        : Toast.error('Error create Partnership');
    });
};

export const fetchROIDelete = (ids: string[]) => async (dispatch: Dispatch) => {
  return api
    .fetchMultipleDelete(ids.join())
    .then(() => dispatch(actions.deleteROI(ids)))
    .catch(() => Toast.error('Error delete Partnership'));
};

export const fetchROI = (id: string) => async (dispatch: Dispatch) => {
  return api.fetchROI(id).then(roi => dispatch(actions.setROI(roi)));
};

export const fetchROIUpdate = (
  roi: ROIData,
  hideToast?: boolean,
  toastSuccessText?: string,
) => async (dispatch: Dispatch) => {
  return api
    .fetchROIUpdate(roi)
    .then(roi => {
      dispatch(actions.setROI(roi));
      !hideToast && Toast.success(toastSuccessText || 'Partnership saved successfully');
    })
    .catch(() => Toast.error('Error save Partnership'));
};

export const fetchROIChangeStatus = ({ roiId, type, bool }: ROIDisableReq) => async (
  dispatch: Dispatch,
) => {
  switch (type) {
    case ROIEditNavigationEnum.Manage:
      return api.fetchROIUpdate({ id: roiId, disabled: !bool }).then(roi => {
        dispatch(actions.setROI(roi));
      });
    case ROIEditNavigationEnum.Steward:
      return api.fetchROIUpdate({ id: roiId, activeStewardship: bool }).then(roi => {
        dispatch(actions.setROI(roi));
      });
    case ROIEditNavigationEnum.Measure:
      return api.fetchROIUpdate({ id: roiId, activeMeasure: bool }).then(roi => {
        dispatch(actions.setROI(roi));
      });
    default:
      return;
  }
};

export const fetchROIGenerateTemplates = (roiId: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchROIGenerateTemplates(roiId)
    .then(templates => {
      dispatch(actions.setROITemplates(templates));
      Toast.success('Stewardship plan generated successfully');
    })
    .catch(err => {
      err.response.data.statusCode === 400
        ? Toast.error(err.response.data.message)
        : Toast.error('Error generate plan');
      return false;
    });
};

export const fetchROITemplates = (roiId: string) => async (dispatch: Dispatch) => {
  return api
    .fetchROITemplates(roiId)
    .then(templates => dispatch(actions.setROITemplates(templates)));
};

export const fetchROITemplatePreview = (templateId: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchROITemplatePreview(templateId)
    .then(previewStr => dispatch(actions.setROITemplatePreview(previewStr)));
};

export const fetchROITemplateCompiled = (template: ROITemplate) => async (
  dispatch: Dispatch,
) => {
  return api.fetchROITemplateCompiled(template).then(previewStr => {
    dispatch(actions.setROITemplatePreview(previewStr));
    dispatch(actions.setROITemplatesItem(template));
  });
};

export const fetchROITemplate = (templateId: string) => async (dispath: Dispatch) => {
  return api.fetchROITemplate(templateId).then(template => {
    dispath(actions.setROITemplatesItem(template));
    return template;
  });
};

export const fetchROITemplateSave = (template: ROITemplate) => async (
  dispath: Dispatch,
) => {
  return api
    .fetchROITemplateSave({ ...template, interval: Number(template.interval) })
    .then(template => {
      dispath(actions.setROITemplatesItem(template));
      Toast.success('Template Saved successfully');
    })
    .catch(() => Toast.error('Error save Template'));
};

export const fetchROITemplateListSave = (templateList: ROITemplate[]) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchROITemplateListSave(templateList)
    .then(templates => {
      dispatch(actions.setROITemplates(templates));
      Toast.success('Stewardship plan saved successfully');
    })
    .catch(() => Toast.error('Error save Stewardship plan'));
};

export const toggleROITemplatesDisable = (templateId: string) => async (
  dispatch: Dispatch,
) => {
  dispatch(actions.toggleROITemplatesDisable(templateId));
};

export const fetchROITemplateReset = (templateId: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchROITemplateReset(templateId)
    .then(template => dispatch(actions.setROITemplatesItem(template)));
};

export const fetchROICalReport = (roiId: string) => async (dispatch: Dispatch) => {
  return api
    .fetchRROICalReport(roiId)
    .then(report => dispatch(actions.setRoiReport(report)));
};

export const fetchROIGenerateReport = (roiId: string) => async (dispatch: Dispatch) => {
  return api.fetchROIGenerateReport(roiId).then(report => {
    dispatch(actions.setRoiReport(report));
  });
};

export const fetchROIReportSavePDF = (fileKey: string) => async (
  dispatch: Dispatch,
  getState: () => StateProps,
) => {
  const state = getState();
  const roi = state.roi.roi;
  if (!roi) return;

  return api
    .fetchROIUpdate({ ...roi, file: fileKey })
    .then(roi => dispatch(actions.setROI(roi)));
};

export const fetchROIAddCompany = (param: CompanyCreateReq) => async () => {
  return api
    .fetchROIAddCompany(param)
    .then(data => {
      if (!data.exist) Toast.success('Company successfully created');
      return data;
    })
    .catch(err => {
      Toast.error('Error create company');
      throw err;
    });
};

export const fetchROIAddTeam = (param: TeamCreateReq) => async () => {
  return api
    .fetchROIAddTeam(param)
    .then(data => {
      if (!data.exist) Toast.success('Organization successfully created');
      return data;
    })
    .catch(err => {
      Toast.error('Error create organization');
      throw err;
    });
};

export const fetchROIAddCompanyNew = (param: CompanyCreateReq) => async () => {
  return api
    .fetchROIAddCompanyNew(param)
    .then(data => {
      if (!data.exist) Toast.success('Company successfully created');
      return data;
    })
    .catch(err => {
      Toast.error('Error create company');
      throw err;
    });
};

export const fetchROIAddTeamNew = (param: TeamCreateReq) => async () => {
  return api
    .fetchROIAddTeamNew(param)
    .then(data => {
      if (!data.exist) Toast.success('Organization successfully created');
      return data;
    })
    .catch(err => {
      Toast.error('Error create organization');
      throw err;
    });
};

export const fetchROIMeltwaterData = (roiId: string) => async (dispatch: Dispatch) => {
  return api
    .fetchROIMeltwaterData(roiId)
    .then(meltwaterData => dispatch(actions.setROIMeltwaterData(meltwaterData)));
};

export const fetchROIMeltwaterDataById = (analyticsId: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchROIMeltwaterDataById(analyticsId)
    .then(meltwaterData => dispatch(actions.setROIMeltwaterData(meltwaterData)));
};

export const fetchROIDashboard = () => async (dispatch: Dispatch) => {
  return api
    .fetchROIDashboard()
    .then(data => dispatch(actions.setROIDashboard(data)))
    .catch(() => Toast.error('Error get ROI'));
};

export const fetchROISuggest = (q: string) => async (dispatch: Dispatch) => {
  return api.fetchROIList({ page: 1, q }).then(data => {
    dispatch(actions.setROISuggest(data.items));
  });
};

export const fetchROISdgGoals = () => async (dispatch: Dispatch) => {
  return api.fetchROISdgGoals().then(sdgGoals => {
    dispatch(actions.setROISdgGoals(sdgGoals));
  });
};

export const fetchROISurvey = (roiId: string) => async (dispatch: Dispatch) => {
  return api
    .fetchROISurvey(roiId)
    .then(values => {
      dispatch(actions.setROISurveys(values));
    })
    .catch(() => {
      Toast.error('Error get survey');
    });
};

export const fetchROISurveyCreate = (params: ROISurvey) => async (dispatch: Dispatch) => {
  return api
    .fetchROISurveyCreate(params)
    .then(survey => {
      dispatch(actions.setROISurvey(survey));
      Toast.success(
        'Your survey has been submitted successfully. As information is gathered, data will be visible on the Data Intake form and on your Dashboard.',
      );
    })
    .catch(() => {
      Toast.error('Failed to send');
    });
};

export const fetchROISurveyResult = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchROISurveyResult(id)
    .then(result => {
      dispatch(actions.setROISurveyResult(result));
    })
    .catch(err => {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 400) return 'Deleted';
      }
      Toast.error('Error get survey data');
    });
};

export const fetchROISurveyResultSubmit = (params: ROISurveyResult) => async () => {
  return api
    .fetchROISurveyResultSubmit(params)
    .then(() => {
      Toast.success('Thank you to participate in the survey');
    })
    .catch(() => {
      Toast.error('Submit failed');
    });
};

export const fetchROIOld = (id: string) => async (dispatch: Dispatch) => {
  return api.fetchROIOld(id).then(roi => dispatch(actions.setROIOld(roi)));
};

export const fetchROICanvaUpdate = (params: ROICanva) => async (dispatch: Dispatch) => {
  return api
    .fetchROICanvaUpdate(params)
    .then(data => dispatch(actions.setROICanva(data)));
};

export const fetchROICanvaDelete = (id: string) => async (dispatch: Dispatch) => {
  return api.fetchROICanvaDelete(id).then(() => dispatch(actions.deleteROICanva(id)));
};

export const fetchROICanvaTemplates = (params: ROICanvaTemplatesReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchROICanvaTemplates(params)
    .then(templates => {
      if (params.type === 'public')
        return dispatch(actions.setROICanvaTemplates(templates));
      if (params.type === 'private')
        dispatch(actions.setROICanvaTemplatesPrivate(templates));
    })
    .catch(() => Toast.error('Error get canva templates'));
};

export const fetchROICanvaTemplatesCreate = (
  params: ROICanvaTemplatesCreateReq,
) => async () => {
  return api.fetchROICanvaTemplatesCreate(params).catch(() => {
    Toast.error('Error create custom template');
  });
};

export const fetchGetOktaUser = () => async () => {
  return api.fetchGetOktaUser().catch(() => {
    Toast.error('Error get canva user');
  });
};

export const fetchCreateCanvaLog = (roiId: string) => async () => {
  return api.fetchCreateCanvaLog(roiId).catch(fetchErrorHandler);
};

export const fetchROISurveyStatistics = () => async (dispatch: Dispatch) => {
  return api
    .fetchROISurveyStatistics()
    .then(statistics => dispatch(actions.setROISurveyStatistics(statistics)))
    .catch(() => {
      Toast.error('Error get Statistics');
    });
};

export const fetchSurveySearch = (params: ROISurveyListReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchSurveySearch(params)
    .then(list => dispatch(actions.setROISurveyList(list)))
    .catch(() => {
      Toast.error('Error get survey list');
    });
};

export const fetchSurveyStatisticById = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchSurveyStatisticById(id)
    .then(statistic => dispatch(actions.setROISurveyStatisticById(statistic)))
    .catch(() => {
      Toast.error('Error get survey statistic');
    });
};

export const fetchSurveyDeleteMultiple = (deleteIds: string) => async () => {
  return api
    .fetchSurveyDeleteMultiple(deleteIds)
    .then(() => {
      // dispatch(actions.deleteROISurveyStatistic(deleteIds));
      Toast.success('Successfully deleted');
    })
    .catch(() => {
      Toast.error('Error delete survey');
    });
};
