import { DashboardPricing } from 'interfaces/pricing';
import {
  PotentialAssetValueChart,
  RecommendedPricingTable,
  TopAreasforGrowthTable,
  TotalAssetValueChart,
} from 'pages/organizationUser/pricing/components';
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled from 'styled-components';

interface Props {
  data: DashboardPricing;
}

const PricingDashboard = ({ data }: Props) => {
  return (
    <Root>
      <Tabs>
        <TabList>
          <Tab>Total Asset Value</Tab>
          <Tab>Potential Asset Value</Tab>
          <Tab>Recommended Pricing</Tab>
          <Tab>Top Areas for Growth</Tab>
        </TabList>
        <TabPanel>
          <TotalAssetValueChart totalAssetValue={data.TotalAssetValue.slice(0, 5)} />
        </TabPanel>
        <TabPanel>
          <PotentialAssetValueChart
            potentialAssetValue={data.PotentialAssetValue.slice(0, 5)}
          />
        </TabPanel>
        <TabPanel>
          <RecommendedPricingTable
            headCells={['Pricing', 'Tier  One', 'Tier  Two', 'Tier  Three']}
            rows={data.RecommendedPricing.slice(0, 5)}
          />
        </TabPanel>
        <TabPanel>
          <TopAreasforGrowthTable
            headCells={['Asset', 'Value']}
            rows={data.TopAreasForGrowth.slice(0, 5)}
          />
        </TabPanel>
      </Tabs>
    </Root>
  );
};

const Root = styled.div`
  padding: 24px;
  background-color: white;
  border-radius: 6px;
`;

export default PricingDashboard;
