export const SET_SEARCH_COMPANIES = 'companies/SET_SEARCH_COMPANIES';
export const SET_COMPANIES = 'companies/SET_COMPANIES';
export const SET_COMPANIES_ONLY_ID_NAME = 'companies/SET_COMPANIES_ONLY_ID_NAME';
export const SET_COMPANY = 'companies/SET_COMPANY';
export const SET_COMPANY_FAVORITE = 'companies/SET_COMPANY_FAVORITE';
export const SET_COMPANIES_FAVORITES = 'companies/SET_COMPANIES_FAVORITES';
export const SET_CONTACTS = 'companies/SET_CONTACTS';
export const SET_COMPANY_SCOOPS = 'companies/SET_COMPANY_SCOOPS';
export const SET_COMPANY_NEWS = 'companies/SET_COMPANY_NEWS';
export const SET_COMPANIES_BLOCKED = 'companies/SET_COMPANIES_BLOCKED';
export const SET_COMPANY_BLOCKED = 'companies/SET_COMPANY_BLOCKED';
export const SET_COMPANIES_TOPMATCH = 'companies/SET_COMPANIES_TOPMATCH';
export const SET_COMPANY_MATCHDETAIL = 'companies/SET_COMPANY_MATCHDETAIL';
export const SET_CONTACTS_USED_IN_60DAYS = 'companies/SET_CONTACTS_USED_IN_60DAYS';
export const SET_COMPANY_CURRENT = 'companies/SET_COMPANY_CURRENT';
export const SET_SIMILARITYSEARCH = 'companies/SET_SIMILARITYSEARCH';
export const SET_COMPANY_CURRENT_CONTACTS = 'companies/SET_CURRENT_CONTACTS';
export const CREATE_COMPANY_CURRENT_CONTACT = 'companies/CREATE_COMPANY_CURRENT_CONTACT';
export const UPDATE_COMPANY_CURRENT_CONTACT = 'companies/UPDATE_COMPANY_CURRENT_CONTACT';
export const DELETE_COMPANY_CURRENT_CONTACT = 'companies/DELETE_COMPANY_CURRENT_CONTACT';
export const CLEAR_SIMILARITYSEARCHCOMPANY = 'companies/CLEAR_SIMILARITYSEARCHCOMPANY';
export const SET_LAST_MONTH_COMPANIES = 'companies/SET_LAST_MONTH_COMPANIES';
export const SET_LAST_MONTH_COMPANY_MEETING = 'companies/SET_LAST_MONTH_COMPANY_MEETING';
export const SET_LAST_MONTH_COMPANY_PARTNERSHIP =
  'companies/SET_LAST_MONTH_COMPANY_PARTNERSHIP';
export const SET_LAST_MONTH_COMPANY_LIKE = 'companies/SET_LAST_MONTH_COMPANY_LIKE';
export const SET_FAVORITES_COMPANY_MEETING = 'companies/SET_FAVORITES_COMPANY_MEETING';
export const SET_FAVORITES_COMPANY_PARTNERSHIP =
  'companies/SET_FAVORITES_COMPANY_PARTNERSHIP';

export const GET_TEAM_MEMBERS = 'companies/GET_TEAM_MEMBERS';
export const DELETE_TEAM_MEMBERS = 'companies/DELETE_TEAM_MEMBERS';
export const SET_COMPANIESE_CONTACTS = 'companies/SET_COMPANIESE_CONTACTS';
export const SET_SUBSCRIPTION = 'companies/SET_SUBSCRIPTION';
export const UPDATE_COMPANY_PROFILE = 'companies/UPDATE_COMPANY_PROFILE';
