export const gameplanTemplateOptions = [
  { value: '66ab3340-5df8-484c-a561-8bddaec0b46b', label: 'Plain Text' },
  { value: '5d618da1-fbb6-4a06-835b-541ced246819', label: 'Banner, Button and Social' },
  { value: 'ac10866e-558c-4d63-9cdb-7f69c8155f59', label: 'Logo, Image & Button' },
];

export const pitchTemplateOptions = [
  {
    value: 'b89fb034-58b1-426a-95f0-89c8744b0c7c',
    label: 'Plain Text',
  },
  { value: 'ba5fe14a-8d26-490e-96bb-fe4263fee768', label: 'Logo, Banner, and Button' },
];
