import { ActionType, createReducer } from 'typesafe-actions';

import { UserData, AuthStatuses, User } from 'interfaces/auth';

import * as types from './types';
import * as actions from './actions';

type RootAction = ActionType<typeof actions>;

export default createReducer<UserData, RootAction>({
  user: {} as any,
  additionalInfo: {
    missions: [],
    industries: [],
    industriesTree: [],
    crsFocus: [],
    states: [],
    countries: [],
    cites: [],
    naicsCodeTree: [],
    teamRange: {
      annualtotalcontributionsmax: '0',
      annualtotalcontributionsmin: '0',
      numberofsocialfollowersmax: '0',
      numberofsocialfollowersmin: '0',
      minimumcommitmentmax: '0',
      minimumcommitmentmin: '0',
    },
  },
  verifyEmailStatus: undefined,
  stateByCountry: [],
  citesByStates: [],
})
  .handleType([types.SET_USER], (state, action) => ({
    ...state,
    user: { ...action.payload, authStatus: AuthStatuses.AUTHOREZED },
  }))
  .handleType([types.LOG_OUT], state => ({
    ...state,
    user: {
      authStatus: AuthStatuses.UNATHORIZED,
    } as User,
  }))
  .handleType([types.SET_ADDITIONAL_INFO], (state, action) => ({
    ...state,
    additionalInfo: action.payload,
  }))
  .handleType([types.SET_VERIFY_EMAIL_STATUS], (state, action) => ({
    ...state,
    verifyEmailStatus: action.payload,
  }))
  .handleType([types.SET_STATE_BY_COUNTRY], (state, action) => ({
    ...state,
    stateByCountry: action.payload,
  }))
  .handleType([types.SET_CITES_BY_STATES], (state, action) => ({
    ...state,
    citesByStates: action.payload,
  }));
