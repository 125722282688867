import React from 'react';
import styled from 'styled-components';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';

interface Props {
  didYouHaveAnyAmbassadors: boolean;
  accessToAgencies: boolean;
  themeColor?: string;
}

const AmbassadorsMetric = ({
  didYouHaveAnyAmbassadors,
  accessToAgencies,
  themeColor,
}: Props) => {
  return (
    <MetricRoot>
      <Title $themeColor={themeColor}>Ambassadors</Title>
      <MetricContent>
        {didYouHaveAnyAmbassadors && (
          <ImpactValue>ambassadors, executives, or influencers</ImpactValue>
        )}
        {accessToAgencies && (
          <ImpactValue>agencies, vendors or other portfolio brands</ImpactValue>
        )}
        {!didYouHaveAnyAmbassadors && !accessToAgencies && (
          <ImpactValue>No Information</ImpactValue>
        )}
      </MetricContent>
    </MetricRoot>
  );
};

const Title = styled(MetricTitle)`
  /* background-color: #caf0ff; */
`;

const ImpactValue = styled.p`
  font-size: 14px;
  line-height: 155%;
  position: relative;
  padding-left: 14px;

  &:before {
    content: '';
    width: 6px;
    min-width: 6px;
    height: 6px;
    background: var(--blue);
    border-radius: 100%;
    position: absolute;
    left: 0px;
    top: 6px;
  }
`;

export default AmbassadorsMetric;
