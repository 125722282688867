import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { Link } from 'react-router-dom';
import { FieldTextInput } from 'components/FormFields';
import { UIButton } from 'components/UI';
import { required, email } from 'utils/validation';
import { FundsAuthData } from 'interfaces/auth';
import { composeValidators } from 'utils';
import styled, { css } from 'styled-components';
import { AuthFundsFormProps } from 'interfaces/common';
import { Field } from 'components/FinalForm';

const FundsLoginForm = ({ onSubmit }: AuthFundsFormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        submitting,
        hasValidationErrors,
      }: FormRenderProps<FundsAuthData>) => {
        return (
          <form>
            <Title>Welcome to Accelerist</Title>

            {/* <AuthNavigation /> */}
            <Field
              name="email"
              label="Email"
              component={FieldTextInput}
              placeholder="Enter email"
              validate={composeValidators<string>(required, email)}
            />

            <Btn
              modifiers="primary"
              title="Login"
              handleClick={handleSubmit}
              type="submit"
              disabled={submitting || hasValidationErrors}
              isLoading={submitting}
            />
          </form>
        );
      }}
    />
  );
};

const Title = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  margin-bottom: 20px;
  text-align: center;
`;

const LinkElem = styled(Link)`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
  &:hover {
    color: var(--black);
  }
`;

const Forgot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Btn = styled(UIButton)`
  width: 100%;
`;

export default FundsLoginForm;
