import * as api from './api';
import {
  AcceptInviteData,
  CustomizeFilterTeamsRequestProps,
  SearchTeamsReq,
  TeamProfileData,
} from 'interfaces/team';
import * as actions from './actions';
import { Dispatch } from 'redux';
import Toast from 'services/Toast';
import axios from 'axios';
import { EmailDesignGetReq, SendGridTemplate } from 'interfaces/emailDesign';
import { fetchErrorHandler } from 'utils/errorHandlers';

export const fetchTeam = () => async (dispatch: Dispatch) => {
  return api
    .fetchTeam()
    .then(team => {
      dispatch(actions.setTeam(team));
      return team;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchTeamById = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchTeamById(id)
    .then(team => {
      dispatch(actions.setTeam(team));
      return team;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchTeamUpdate = (
  data: Omit<TeamProfileData, 'charitablePartners' | 'teamPartnerCompanies'> & {
    charitablePartners: string[];
    teamPartnerCompanies: { companyId: string; companyName: string }[];
  },
) => async (dispatch: Dispatch) => {
  return api
    .fetchTeamUpdate(data)
    .then(team => {
      dispatch(actions.setTeam(team));
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchTeamLogoUpload = (file: File) => async () => {
  return api
    .fetchTeamLogoUpload(file)
    .then(() => {
      return Toast.success('Logo successfully uploaded');
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchAddTeamMembers = (email: string) => async () => {
  try {
    await api.fetchAddTeamMembers(email);

    Toast.success('Email sent successfully');
  } catch (error) {
    if (axios.isAxiosError(error)) {
      error.response?.data.message === 'Validation failed' &&
        Toast.error('Email must be a valid email');
    }
  }
};

interface Company {
  id: string;
  name?: string;
  website?: string;
  country?: string;
}

export const fetchAddNoContact = (company: Company) => async () => {
  try {
    await api.fetchAddNoContact(company);

    Toast.success('Company added successfully');
  } catch (error) {
    Toast.error('Error adding company');
  }
};

export const fetchInviteLinkMember = () => async () => {
  try {
    const link = await api.fetchInviteLinkTeam();

    Toast.success('Invite link was generated successfully');

    return link;
  } catch (error) {
    console.error(error);
  }
};

export const fetchAcceptInvite = (params: AcceptInviteData) => async () => {
  try {
    const data = await api.fetchAcceptInvite(params);

    console.log(data);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      error.response?.data.error === 'Unauthorized' &&
        Toast.error('You are not authorized');
      error.response?.data.error === 'Forbidden' &&
        Toast.error(error.response.data.message);

      console.log(error);
    }
  }
};

export const fetchTeamMembers = () => async (dispatch: Dispatch) => {
  try {
    const data = await api.fetchTeamMembers();
    dispatch(actions.getTeamMembers(data));
  } catch (error) {
    console.log(error);
  }
};

export const fetchEmailTemplates = (teamId: string) => async (dispatch: Dispatch) => {
  try {
    const data = await api.fetchEmailTemplates(teamId);
    dispatch(actions.getEmailTemplates(data));
  } catch (error) {
    console.log(error);
  }
};

export const fetchNoContact = (teamId: string) => async (dispatch: Dispatch) => {
  try {
    console.log('Reached fetch contact');
    const data = await api.fetchNoContact(teamId);
    dispatch(actions.getNoContact(data));
  } catch (error) {
    console.log(error);
  }
};

export const deleteCompanyRow = (id: string) => async (dispatch: Dispatch) => {
  try {
    return api.deleteNoContact(id).then(id => {
      Toast.success('Company Removed');
    });
  } catch (error) {
    console.log(error);
  }
};

export const fetchDeleteTeamMembers = (id: string) => async (dispatch: Dispatch) => {
  try {
    await api.fetchDeleteTeamMembers(id);
    dispatch(actions.deleteTeamMembers(id));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      Toast.error(error.response?.data.message);
    }
  }
};

export const fetchSubscriptionTeams = () => async (dispatch: Dispatch) => {
  return api
    .fetchSubscriptionTeams()
    .then(subscription => {
      dispatch(actions.setTeamSubscription(subscription));
    })
    .catch(error => {
      throw error;
    });
};

export const fetchLastLogins = () => async (dispatch: Dispatch) => {
  return api
    .fetchLastLogins()
    .then(logins => {
      dispatch(actions.setLastLogins(logins));
      return logins;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchSentPitch = (params: {
  page: number;
  limit: number;
  q?: string;
}) => async (dispatch: Dispatch) => {
  return api
    .fetchSentPitch(params.page, params.limit, params.q)
    .then(sentPitch => dispatch(actions.setSentPitch(sentPitch)))
    .catch(() => Toast.error('Error get send pitch'));
};

export const fetchTeams = ({ limit = 12, ...params }: SearchTeamsReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchTeams({ limit, calScore: true, ...params })
    .then(teams => dispatch(actions.setTeams(teams)))
    .catch(() => {
      Toast.error('Error teams search');
    });
};

export const fetchTeamsSuggest = (q: string, isPayPalCommerce?: boolean) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchTeams({ q, limit: 12, page: 1, isPayPalCommerce, filterType: 'Best Match' })
    .then(teams => dispatch(actions.setTeamsSuggest(teams.items)))
    .catch(() => {
      Toast.error('Error get teams suggest');
    });
};

export const fetchTeamsSuggestWithEin = (q: string, isPayPalCommerce?: boolean) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchSearchTeamsWithEin({
      q,
      limit: 12,
      page: 1,
      isPayPalCommerce,
      filterType: 'Best Match',
    })
    .then(teams => dispatch(actions.setTeamsSuggest(teams.items)))
    .catch(() => {
      Toast.error('Error get teams suggest');
    });
};

export const fetchTeamsCustomizeFilter = (
  data: CustomizeFilterTeamsRequestProps,
) => async (dispatch: Dispatch) => {
  return api
    .fetchTeamsCustomizeFilter(data)
    .then(teams => {
      dispatch(actions.setTeams(teams));
      return teams;
    })
    .catch(() => {
      Toast.error('Error teams customize filter');
    });
};

export const fetchTeamNews = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchTeamNews(id)
    .then(news => {
      dispatch(actions.setTeamNews(news));
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchTeamNamesByIds = (ids: string[]) => async () => {
  return api.fetchTeamNamesByIds(ids).then(res => res.map(item => item.organizationName));
};

export const fetchEmailDesign = (params: EmailDesignGetReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchEmailDesign(params)
    .then(value => dispatch(actions.setEmailDesign(value)))
    .catch(() => {
      Toast.error('Error get EmailDesign');
    });
};

export const fetchEmailDesignUpdate = (params: SendGridTemplate) => async () => {
  return api
    .fetchEmailDesignUpdate(params)
    .then(() => Toast.success('Email Design save successfully'))
    .catch(() => {
      Toast.error('Error save EmailDesign');
    });
};

export const fetchEmailDesignCreate = (params: SendGridTemplate) => async () => {
  return api
    .fetchEmailDesignCreate(params)
    .then(() => Toast.success('Email Design create successfully'))
    .catch(() => {
      Toast.error('Error create EmailDesign');
    });
};

export const clearEmailDesign = () => async (dispatch: Dispatch) => {
  dispatch(actions.clearEmailDesign());
};

export const fetchPayPalGivingFundCanUpdate = () => async (dispatch: Dispatch) => {
  return api.fetchPayPalGivingFundCanUpdate().then(bool => {
    dispatch(actions.setTeamPayPalGivingFundCanUpdate(bool));
  });
};

export const fetchPayPalGivingFundIfConfirm = () => async (dispatch: Dispatch) => {
  return api.fetchPayPalGivingFundIfConfirm().then(bool => {
    dispatch(actions.setTeamPayPalGivingFundIfConfirm(bool));
  });
};

export const fetchImpactGenome = () => async (dispatch: Dispatch) => {
  return api
    .fetchImpactGenome()
    .then(data => {
      dispatch(actions.setImpactGenome(data));
    })
    .catch(fetchErrorHandler);
};
