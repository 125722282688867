import { FormatMoney, FormatMoneyOptions } from 'format-money-js';
import { DATE_FORMAT } from 'constants/date';
import { format, parse } from 'date-fns';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const moneyMask = createNumberMask({
  inputMode: 'numeric',
  maskOptions: {
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
  },
});

export const idMask = createNumberMask({
  prefix: 'ID ',
  integerLimit: 8,
  includeThousandsSeparator: false,
});

export const cardNumberMaskedProps = {
  mask: [
    /[\d*]/,
    /[\d*]/,
    /[\d*]/,
    /[\d*]/,
    ' ',
    /[\d*]/,
    /[\d*]/,
    /[\d*]/,
    /[\d*]/,
    ' ',
    /[\d*]/,
    /[\d*]/,
    /[\d*]/,
    /[\d*]/,
    ' ',
    /[\d*]/,
    /[\d*]/,
    /[\d*]/,
    /[\d*]/,
  ],
  parse: value => {
    return value && value.replace(/ /g, '');
  },
};

export const expirationMaskedProps = {
  mask: [/[\d]/, /[\d]/, ' ', '/', ' ', /[\d]/, /[\d]/, /[\d]/, /[\d]/],
  parse: value => {
    return value && value.replace(/ /g, '');
  },
};

export const phoneNumberMaskedProps = {
  mask: [
    '+',
    /\d/,
    ' ',
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    '-',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  parse: value => {
    return value && value.replace(/[ ()-]+/g, '');
  },
};

export const zipMask = [/\d/, /\d/, /\d/, /\d/, /\d/];

export const cvvMask = [/[\d*]/, /[\d*]/, /[\d*]/, /[\d*]/];

export const postalCodeMask = [/[\d*]/, /[\d*]/, /[\d*]/, /[\d*]/, /[\d*]/];

export const getRelativeTime = (dateISO: string, formatString: string) => {
  return format(parse(dateISO, DATE_FORMAT.BACKEND, new Date()), formatString);
};

export const capitalizeText = (text: string | null | undefined) => {
  return text ? text[0].toUpperCase() + text.slice(1).toLowerCase() : text;
};

export const cropHttps = (text: string | null | undefined) => {
  return text && text.replace('https://', '');
};

export const cropText = (text: string | undefined, num: number, endText = '...') => {
  if (!text) return text;

  if (text.length > num) return `${text.slice(0, num)}${endText}`;

  return text;
};

export const getFullName = (
  firstName: string | undefined | null,
  lastName: string | undefined | null,
) => (firstName && lastName ? `${firstName} ${lastName}` : 'No name');

export const moneyFormatter = new FormatMoney({
  decimals: 0,
});

export const formatDataForChart = (data: Record<string, number>) => {
  const objToArray = Object.entries(data);
  return objToArray.map(item => {
    return {
      name: item[0],
      value: item[1],
    };
  });
};

export const translateTFBool = (value: string) => {
  switch (value) {
    case 't':
      return 'Yes';
    case 'f':
      return 'No';
    default:
      return '';
  }
};

export const textToCapitalizeTitle = (text: string) => {
  if (!text) return '';

  const spaceText = text.replace(/[A-Z][a-z]+/g, value => ` ${value}`);
  return `${spaceText[0].toUpperCase()}${spaceText.slice(1)}`;
};

export const getFilenameByPath = (path: string) =>
  path.match(/[^./]+(\.\w+)+(?=$|\?)/)?.[0];

export const formatNumber = (number: number) =>
  Intl.NumberFormat('en', {
    // @ts-ignore
    notation: 'compact',
  }).format(number);

export const moneyNumberFormat = (
  num: unknown,
  option?: FormatMoneyOptions & { nullPlaceholder?: string },
) =>
  num == null || Number.isNaN(Number(num))
    ? option?.nullPlaceholder !== undefined
      ? option.nullPlaceholder
      : 'N/A'
    : moneyFormatter.from(Number(num), {
        symbol: '$',
        ...option,
      });

export const numberFormat = (num: unknown, option?: FormatMoneyOptions) =>
  Number.isNaN(Number(num)) ? 'N/A' : moneyFormatter.from(Number(num), option || {});
