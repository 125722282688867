import { UIActionText, UISwitch } from 'components/UI';
import { FormArrayDeleteIcon } from 'components/UI/form';
import styled from 'styled-components';

export const FieldWrapper = styled.div`
  max-width: 764px;
  position: relative;
`;
export const FieldDoubleRow = styled.div`
  display: flex;
  & > div {
    width: 374px;
  }

  & > div:first-child {
    margin-right: 16px;
  }
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  margin-bottom: 24px;
  display: flex;
`;

export const ButtonGroup = styled.div`
  display: flex;
  & > button:not(:first-child) {
    margin-left: 18px;
  }
`;

export const ActionText = styled(UIActionText)`
  margin-bottom: 24px;
`;

export const AddCompany = styled.div`
  position: absolute;
  right: -170px;
  top: 30px;
`;

export const AddUser = styled.div`
  padding-top: 32px;
`;

export const ViewStewardshipPlan = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-bottom: -24px;
`;

export const Switch = styled(UISwitch)`
  margin-left: 32px;
`;

export const DeleteBtn = styled(FormArrayDeleteIcon)`
  margin-top: 30px;
  margin-left: 12px;
  cursor: pointer;
  position: relative;
  top: -42px;
  left: 20px;
`;
