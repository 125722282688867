import React, { useMemo } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { FieldSwitcher } from 'components/FormFields';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import styled from 'styled-components';
import { Field } from 'components/FinalForm';
import { FormTitle } from 'components/UI/form';
import { TeamProfileData } from 'interfaces/team';
import { OnChange } from 'react-final-form-listeners';
import { UIDivider, UILink } from 'components/UI';

import { THEME_COLORS } from 'constants/common';
import BrandKitSection from './BrandKitSection';

export interface FormProps {
  initialValues: TeamProfileData;
  onSubmit: (values: TeamProfileData) => OnSubmitReturnType;
  downloadEmailIntroduce: (name: string) => void;
}

const SettingForm = ({ initialValues, onSubmit, downloadEmailIntroduce }: FormProps) => {
  const initial = useMemo(() => {
    return {
      ...initialValues,
      brandTheme: initialValues.brandTheme || THEME_COLORS.Accelerist_theme.title,
      brandFont: initialValues.brandFont || 'Rubik',
    };
  }, [initialValues]);

  return (
    <Form
      initialValues={initial}
      onSubmit={values => {
        onSubmit(values);
      }}
      render={({ handleSubmit, values, form }: FormRenderProps<TeamProfileData>) => {
        return (
          <FormStyled>
            <BrandKitSection form={form} values={values} handleSubmit={handleSubmit} />

            <FormTitle>Notification</FormTitle>
            <UnderLineWrapper>
              <Field
                name="isReceiveEmail"
                label="Company Match"
                component={FieldSwitcher}
                description="Send push notifications when companies match with at least 50% Priority Match score"
              />
              <OnChange name="isReceiveEmail">
                {(value, previous) => {
                  if (value !== previous) {
                    handleSubmit();
                  }
                }}
              </OnChange>
            </UnderLineWrapper>

            <FormTitle>Game Plan</FormTitle>
            <UnderLineWrapper>
              <Field
                name="isAnalyticsGamePlan"
                label="Game Plan Tracking"
                component={FieldSwitcher}
                description="Enable game plan tracking to see engagement metrics for all of your Game Plans"
              />
              <OnChange name="isAnalyticsGamePlan">
                {(value, previous) => {
                  if (value !== previous) {
                    handleSubmit();
                  }
                }}
              </OnChange>
              {values.isAnalyticsGamePlan && (
                <GameplanMetricWrapper>
                  <GameplanMetricTitle>
                    Step 1: Download Configuration File
                  </GameplanMetricTitle>
                  <Text>
                    To enable game plan tracking, please download the configuration file
                  </Text>
                  <Link
                    modifier="blue"
                    onClick={() => downloadEmailIntroduce('AcceleristGmailConfig.xml')}
                  >
                    Download Gmail configuration file
                  </Link>
                  <Link
                    modifier="blue"
                    onClick={() => downloadEmailIntroduce('AcceleristEmail.rwz')}
                  >
                    Download Outlook configuration file
                  </Link>
                  <UIDivider type="horizontal" />
                  <GameplanMetricTitle>Step 2: Email Account Set Up</GameplanMetricTitle>
                  <Text>
                    Import the configuration file you downloaded in step 1 to your Outlook
                    or Gmail account.
                  </Text>
                  <Link
                    modifier="blue"
                    onClick={() => downloadEmailIntroduce('GamePlanGmail.docx')}
                  >
                    Read Gmail Import Instruction
                  </Link>
                  <Link
                    modifier="blue"
                    onClick={() => downloadEmailIntroduce('GamePlanOutlook.docx')}
                  >
                    Read Outlook Import Instruction
                  </Link>
                </GameplanMetricWrapper>
              )}
            </UnderLineWrapper>
            <UnderLineWrapper>
              <Field
                name="autoReplyDeleteConfig"
                label="Recipients"
                component={FieldSwitcher}
                description="Remove recipients from Game Plan if they have replied to any game plan emails (excluding auto-replies)"
              />
              <OnChange name="autoReplyDeleteConfig">
                {(value, previous) => {
                  if (value !== previous) {
                    handleSubmit();
                  }
                }}
              </OnChange>
            </UnderLineWrapper>
          </FormStyled>
        );
      }}
    />
  );
};

const FormStyled = styled.form`
  background: #ffffff;
  border-radius: 4px;
  padding: 40px;
`;

const GameplanMetricWrapper = styled.div`
  background: #f3fcff;
  padding: 24px 40px;
  margin-bottom: 24px;
`;

const GameplanMetricTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin-bottom: 16px;
`;

const Text = styled.div`
  margin: 16px 0;
`;

const Link = styled(UILink)`
  font-size: 16px;
  display: block;
  margin-bottom: 10px;
`;

const UnderLineWrapper = styled.div`
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 24px;
`;

export default SettingForm;
