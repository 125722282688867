import { DATE_FORMAT } from 'constants/date';
import { format } from 'date-fns';
import { StateProps } from 'store/interfaces';
import { values } from 'lodash';

export const getTeam = (state: StateProps) => state.team.team;
export const getTeamMembers = state =>
  values(state.team.teamMembers).map(member => {
    return {
      ...member,
      lastLogin: member.loggedInAt
        ? format(new Date(member.loggedInAt), DATE_FORMAT.BACKEND)
        : null,
      linkedIn: null,
    };
  });

export const getEmailTemplates = (state: StateProps) => state.team.emailTemplates;
export const getNoContact = (state: StateProps) => state.team.noContact;
export const getSubscription = (state: StateProps) => state.team.subscription;
export const getLastLogins = (state: StateProps) => state.team.lastLogins;
export const getSentPitch = (state: StateProps) => state.team.sendPitch;
export const getTeams = (state: StateProps) => state.team.teams.items;
export const getTeamsMeta = (state: StateProps) => state.team.teams.meta;
export const getTeamNews = (state: StateProps) => state.team.news;
export const getEmailDesign = (state: StateProps) => state.team.emailDeisgn;
export const getTeamsSuggest = (state: StateProps) =>
  state.team.teamsSuggest.map(item => ({ ...item, name: item.organizationName }));
export const getPayPalGivingFundCanUpdate = (state: StateProps) =>
  state.team.payPalGivingFundCanUpdate;
export const getPayPalGivingFundIfConfirm = (state: StateProps) =>
  state.team.payPalGivingFundIfConfirm;
export const getImpactGenome = (state: StateProps) => state.team.impactGenome;
export const getProgramFocusOptions = (state: StateProps) =>
  state.team.impactGenome.map(item => ({
    label: item.programFocus,
    value: item.programFocus,
  }));
