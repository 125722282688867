import React from 'react';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import { parseLink } from 'utils';
import {
  Root,
  Body,
  Header,
  HeaderInfo,
  LoadImage,
  OrganizationTitle,
  OrganizationType,
  SocialLink,
  EditButton,
  Desc,
  DescTitle,
  DescSection,
  PartnersWrapper,
  ContributionsWrapper,
  DescLabel,
  DescValue,
  PartnersList,
  PartnersItem,
  PartnersImg,
  PartnersEmptyImg,
  ContactItem,
  ContactIcon,
  ContactLink,
  AlignmentWrapper,
  AlignmentList,
  AlignmentItem,
  InfoWrapper,
  InfoTitle,
  InfoValue,
  OpportunitiesValueWrapper,
  OpportunitiesValue,
  MissionWrapper,
  MissionTitle,
  MissionSubTitle,
  MissionValue,
  SocialWrapper,
  EmptyAlignment,
  EmptyPartners,
  EmptyPartnersImgWrapper,
  DemographicsDesc,
  Content,
  ProgramBlock,
  ProgramExpenseRatioText,
  ProgramExpenseRatioValue,
  GuidestartWrapper,
  GuidestarBlock,
  GuidestarText,
  GuidestarValue,
  ImageEmpty,
  PartnersLogoWrapper,
  PartnersName,
} from '../../pages/organizationUser/team/Profile/styled';
import EmptyPartnerIcon from 'assets/images/empty-partner-img.svg';
import UserIcon from 'assets/images/icons/user.svg';
import PhoneIcon from 'assets/images/icons/phone.svg';
import EmailIcon from 'assets/images/icons/email.svg';
import WebSiteIcon from 'assets/images/icons/website.svg';
import LinkedInIcon from 'assets/images/icons/linkedIn.svg';
import TwitterIcon from 'assets/images/icons/twitter.svg';
import { TeamProfileData } from 'interfaces/team';
import { News, TypesOfInvestment } from 'interfaces/companies';
import { goalAlignmentOptions } from 'constants/goalAlignment';
import { CropText, UIDollarNumber, UIPercentageNumber } from 'components/UI';
import PrimaryInformation from 'pages/organizationUser/companies/Company/PrimaryInformation';
import SecondaryInformation from 'pages/organizationUser/companies/Company/SecondaryInformation';

import EmptyCompany from 'assets/images/icons/empty-company.svg';
import DownloadFileTag from 'components/UI/DownloadFileTag';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/ducks/user/selectors';
import { useIsCompanyUser } from 'hooks/common';
import ProfileSideBar from '../../pages/organizationUser/team/Profile/ProfileSideBar';
import { moneyNumberFormat } from 'utils/formatters';

export interface ProfileViewProps {
  team: TeamProfileData;
  news: News[];
}

const ProfileView = ({ team, news }: ProfileViewProps) => {
  const {
    organizationName,
    twitterLink,
    linkedinLink,
    annualTotalContributions,
    numberConstituents,
    contactName,
    contactPhone,
    address,
    contactWebsite,
    goalAlignment,
    employeeEngagementOpportunities,
    opportunities,
    impactStatements,
    impactStory,
    zip,
    teamPartnerCompanies,
    aieldActivity,
    email,
    primaryMissionFocus,
    secondaryMissionFocus,
    logoKey,
    typesOfInvestment,
    genders,
    income,
    age,
    ethnicity,
    affiliation,
    nonprofit,
    purchase,
    brands,
    interests,
    additionalMaterials,
    numberOfSocialFollowers,
    minimumCommitment,
    einStatus,
    programs,
    programExpenseRatio,
    missionDescription,
    impact,
    guidestarTransparencyRanking,
    programFocus,
    costPerOutcome,
    beneficiaries,
  } = team;

  const cropWebSite = contactWebsite && parseLink(contactWebsite);

  const twitterLinkParse = twitterLink && parseLink(twitterLink);

  const linkedinLinkParse = linkedinLink && parseLink(linkedinLink);

  const programFocusAndCostPerOutcome = programFocus?.split(';').map((item, i) => ({
    costPerOutcome: costPerOutcome?.split(',')[i],
    programFocus: item,
  }));
  const user = useSelector(selectUser);
  const isCompanyUser = useIsCompanyUser(user);

  const getBeneficiariesValue = (beneficiariesList: any, type: number) => {
    if (type === 1) {
      const filteredList = beneficiariesList.filter(value =>
        ['Men/Boys', 'Non-binary', 'Women/Girls'].includes(value),
      );
      const result = filteredList.join(', ');
      return result;
    }
    if (type === 2) {
      const filteredList = beneficiariesList.filter(value =>
        [
          'Adolescents (Secondary)',
          'Adults',
          'Birth to Young Children (Pre-K)',
          'Children (Elementary school)',
          'Early Adolescents (Middle school)',
          'Elderly',
          'Young Adults (College/Post-Secondary)',
        ].includes(value),
      );
      const result = filteredList.join(', ');
      return result;
    }
    if (type === 3) {
      const filteredList = beneficiariesList.filter(value =>
        [
          'Asian or Pacific Islander',
          'Black',
          'Hispanic/Latinx',
          'Indigenous',
          'Other Race/Ethnicity',
        ].includes(value),
      );
      const result = filteredList.join(', ');
      return result;
    }

    if (type === 4) {
      const filteredList = beneficiariesList.filter(value =>
        [
          'Active Military and/or Military Families',
          'English Language Learners (ELL)',
          'First-Generation College Students',
          'Homeless',
          'Incarcerated / formerly incarcerated',
          'Individuals with Disabilities',
          'Individuals with Mental Health Condition(s)',
          'Individuals with Physical Health Condition(s)',
          'LGBTQ+',
          'Low-Income or Economically Disadvantaged',
          'Military Veterans and/or Veteran Families',
          'Newcomers, Immigrants, and/or Refugees',
          'Unemployed',
        ].includes(value),
      );
      const result = filteredList.join(', ');
      return result;
    }
  };

  return (
    <Root>
      <Header>
        <HeaderInfo>
          {logoKey ? (
            <LoadImage imgKey={logoKey} />
          ) : (
            <ImageEmpty>
              <ReactSVG src={EmptyCompany} />
            </ImageEmpty>
          )}

          <div>
            <OrganizationTitle>{organizationName || 'No information'}</OrganizationTitle>
            <OrganizationType>{aieldActivity || 'No information'}</OrganizationType>
            <SocialWrapper>
              {twitterLink && (
                <SocialLink href={twitterLinkParse} target="_blank">
                  <ReactSVG src={TwitterIcon} />
                </SocialLink>
              )}

              {linkedinLink && (
                <SocialLink href={linkedinLinkParse} target="_blank">
                  <ReactSVG src={LinkedInIcon} />
                </SocialLink>
              )}
            </SocialWrapper>
          </div>
        </HeaderInfo>

        {!isCompanyUser && (
          <Link to="/profile/edit">
            <EditButton modifiers="blue" title="Edit" />
          </Link>
        )}
      </Header>

      <Body>
        <Content>
          <Desc>
            <DescSection>
              <DescTitle>Organization</DescTitle>

              <ContributionsWrapper>
                <DescLabel>Annual Total Contributions</DescLabel>
                <DescValue>
                  <CropText
                    width={200}
                    text={
                      annualTotalContributions ? (
                        <UIDollarNumber value={annualTotalContributions} />
                      ) : (
                        'No information'
                      )
                    }
                  />
                </DescValue>
              </ContributionsWrapper>

              <ContributionsWrapper>
                <DescLabel>Number of Constituents</DescLabel>
                <DescValue> {numberConstituents || 'No information'}</DescValue>
              </ContributionsWrapper>

              <div>
                <DescLabel>EIN</DescLabel>
                <DescValue> {einStatus || 'No information'}</DescValue>
              </div>
            </DescSection>

            <PartnersWrapper>
              <DescTitle>Partners</DescTitle>

              {teamPartnerCompanies && teamPartnerCompanies.length > 0 ? (
                <PartnersList>
                  {teamPartnerCompanies.slice(0, 15).map(partner => (
                    <PartnersItem key={partner.id}>
                      <PartnersLogoWrapper>
                        {partner?.company?.logo ? (
                          <PartnersImg
                            title={partner.companyName}
                            imgKey={partner.company.logo}
                          />
                        ) : (
                          <PartnersEmptyImg
                            title={partner.companyName}
                            style={{
                              backgroundImage: `url(${EmptyCompany})`,
                            }}
                          />
                        )}
                      </PartnersLogoWrapper>
                      <PartnersName>{partner.companyName}</PartnersName>
                    </PartnersItem>
                  ))}
                </PartnersList>
              ) : (
                <EmptyPartners>
                  <EmptyPartnersImgWrapper>
                    <img src={EmptyPartnerIcon} alt="empty partner icon" />
                  </EmptyPartnersImgWrapper>
                  <p>No partners yet</p>
                </EmptyPartners>
              )}
            </PartnersWrapper>
          </Desc>

          <DescSection>
            <DescTitle>Contact</DescTitle>
            <ul>
              <ContactItem>
                <ContactIcon src={UserIcon} />
                {contactName || 'No Fullname'}
              </ContactItem>
              <ContactItem>
                <ContactIcon src={PhoneIcon} />
                {contactPhone ? (
                  <ContactLink href={`tel:${contactPhone}`}>{contactPhone}</ContactLink>
                ) : (
                  <CropText width={200} text="No phone number" />
                )}
              </ContactItem>

              <ContactItem>
                <ContactIcon src={EmailIcon} />
                {email ? (
                  <ContactLink href={`mailto:${email}`}>{email}</ContactLink>
                ) : (
                  <CropText width={200} text="No email" />
                )}
              </ContactItem>

              <ContactItem>
                <ContactIcon src={WebSiteIcon} />
                {contactWebsite ? (
                  <ContactLink href={cropWebSite} target="_blank">
                    {cropWebSite}
                  </ContactLink>
                ) : (
                  <CropText width={200} text="No website" />
                )}
              </ContactItem>
            </ul>
          </DescSection>

          <AlignmentWrapper>
            <DescTitle>SDG Goal Alignment</DescTitle>

            {goalAlignment && goalAlignment.length > 0 ? (
              <AlignmentList>
                {[...goalAlignment]
                  .sort(
                    (g1, g2) => goalAlignmentOptions[g1].id - goalAlignmentOptions[g2].id,
                  )
                  .map(sdg => (
                    <AlignmentItem
                      key={sdg}
                      src={goalAlignmentOptions[sdg].img}
                      alt={goalAlignmentOptions[sdg].title}
                    />
                  ))}
              </AlignmentList>
            ) : (
              <EmptyAlignment>No selected goal</EmptyAlignment>
            )}
          </AlignmentWrapper>
          <InfoWrapper>
            <InfoTitle>Beneficiaries</InfoTitle>
            <InfoValue>
              <>
                <MissionSubTitle>
                  {getBeneficiariesValue(beneficiaries, 1) ? 'Gender' : ''}
                </MissionSubTitle>
                <MissionValue>{getBeneficiariesValue(beneficiaries, 1)}</MissionValue>
                <MissionSubTitle>
                  {getBeneficiariesValue(beneficiaries, 2) ? 'Age' : ''}
                </MissionSubTitle>
                <MissionValue>{getBeneficiariesValue(beneficiaries, 2)}</MissionValue>
                <MissionSubTitle>
                  {getBeneficiariesValue(beneficiaries, 3) ? 'Race/Ethnicity' : ''}
                </MissionSubTitle>
                <MissionValue>{getBeneficiariesValue(beneficiaries, 3)}</MissionValue>
                <MissionSubTitle>
                  {getBeneficiariesValue(beneficiaries, 4) ? 'Other' : ''}
                </MissionSubTitle>
                <MissionValue>{getBeneficiariesValue(beneficiaries, 4)}</MissionValue>
              </>
            </InfoValue>
          </InfoWrapper>

          {(programExpenseRatio || programs) && (
            <AlignmentWrapper>
              <DescTitle>
                Program
                {programExpenseRatio && (
                  <ProgramExpenseRatioText>
                    <ProgramExpenseRatioValue>
                      <UIPercentageNumber value={programExpenseRatio} />
                    </ProgramExpenseRatioValue>
                    Program Expense Ratio
                  </ProgramExpenseRatioText>
                )}
              </DescTitle>
              {isCompanyUser &&
                (programs?.filter(item => item) || []).map((item, i) => (
                  <ProgramBlock key={i}>{item.text}</ProgramBlock>
                ))}
            </AlignmentWrapper>
          )}

          {guidestarTransparencyRanking && (
            <AlignmentWrapper>
              <DescTitle>Guidestar</DescTitle>
              <GuidestartWrapper>
                <GuidestarBlock>
                  <GuidestarText>Guidestar Transparency Ranking</GuidestarText>
                  <GuidestarValue>{guidestarTransparencyRanking}</GuidestarValue>
                </GuidestarBlock>
              </GuidestartWrapper>
            </AlignmentWrapper>
          )}

          <InfoWrapper>
            <InfoTitle>Program Focus</InfoTitle>
            <InfoValue>
              {programFocusAndCostPerOutcome?.map(item => (
                <>
                  {item.programFocus && (
                    <div style={{ marginBottom: '8px', fontWeight: 500 }}>
                      {item.programFocus}
                    </div>
                  )}
                  {item.costPerOutcome && (
                    <div style={{ marginBottom: '12px' }}>
                      Cost per Outcome: {moneyNumberFormat(item.costPerOutcome)}
                    </div>
                  )}
                </>
              ))}
            </InfoValue>
          </InfoWrapper>

          <InfoWrapper>
            <InfoTitle>Address</InfoTitle>
            <InfoValue>
              {address || 'No information'}
              {zip && (
                <>
                  <br />
                  Postal Code: {zip || 'No information'}
                </>
              )}
            </InfoValue>

            <DescTitle>Types Of Investment</DescTitle>

            {typesOfInvestment && typesOfInvestment.length > 0 ? (
              <MissionValue>
                {typesOfInvestment.map(({ title }: TypesOfInvestment, index) => (
                  <span key={index}>
                    {title}
                    {index + 1 < typesOfInvestment.length && `, `}
                  </span>
                ))}
              </MissionValue>
            ) : (
              <MissionValue>No Information</MissionValue>
            )}

            {numberOfSocialFollowers && (
              <>
                <InfoTitle>Social Followers Number</InfoTitle>
                <InfoValue>{numberOfSocialFollowers}</InfoValue>
              </>
            )}

            {minimumCommitment && (
              <>
                <InfoTitle>Minimum Commitment</InfoTitle>
                <InfoValue>${minimumCommitment}</InfoValue>
              </>
            )}

            {/* <InfoTitle>Constituent demo and Nonprofit affinities</InfoTitle>
          <InfoValue>
            {affinities && affinities.length > 0
              ? affinities.join(', ')
              : 'No information'}
          </InfoValue> */}

            {(primaryMissionFocus ||
              (secondaryMissionFocus && secondaryMissionFocus.length > 0)) && (
              <MissionWrapper>
                <MissionTitle>Mission</MissionTitle>
                {primaryMissionFocus && (
                  <>
                    <MissionSubTitle>Primary</MissionSubTitle>
                    <MissionValue>{primaryMissionFocus.label}</MissionValue>
                  </>
                )}

                {secondaryMissionFocus && secondaryMissionFocus.length > 0 && (
                  <>
                    <MissionSubTitle>Secondary</MissionSubTitle>
                    <MissionValue>
                      {secondaryMissionFocus?.map(
                        (item: { value: string | number; label: string }, index) => (
                          <span key={index}>
                            {item.label}
                            {index + 1 < secondaryMissionFocus.length && `, `}
                          </span>
                        ),
                      )}
                    </MissionValue>
                  </>
                )}
                {isCompanyUser && missionDescription && missionDescription.length > 0 && (
                  <>
                    <MissionSubTitle>Mission Description</MissionSubTitle>
                    <MissionValue>{missionDescription}</MissionValue>
                  </>
                )}
              </MissionWrapper>
            )}
            <InfoTitle>Employee Engagement Opportunities</InfoTitle>
            <InfoValue>{employeeEngagementOpportunities ? 'Yes' : 'No'}</InfoValue>

            {opportunities && opportunities.length > 0 && (
              <>
                <InfoTitle>Opportunities</InfoTitle>
                <OpportunitiesValueWrapper>
                  {opportunities.map((item: { text: string }, index) => (
                    <OpportunitiesValue key={index}>{item.text}</OpportunitiesValue>
                  ))}
                </OpportunitiesValueWrapper>
              </>
            )}
            {isCompanyUser && impact && impact.length > 0 && (
              <>
                <InfoTitle>Impact</InfoTitle>
                <OpportunitiesValueWrapper>
                  {impact
                    .filter(item => item)
                    .map((item: { text: string }, index) => (
                      <OpportunitiesValue key={index}>{item.text}</OpportunitiesValue>
                    ))}
                </OpportunitiesValueWrapper>
              </>
            )}
            {impactStatements && impactStatements.length > 0 && (
              <>
                <InfoTitle>Impact Statements</InfoTitle>
                <OpportunitiesValueWrapper>
                  {impactStatements.map((item: { text: string }, index) => (
                    <OpportunitiesValue key={index}>{item.text}</OpportunitiesValue>
                  ))}
                </OpportunitiesValueWrapper>
              </>
            )}

            {impactStory && (
              <>
                <InfoTitle>Impact Story</InfoTitle>
                <InfoValue>{impactStory}</InfoValue>
              </>
            )}

            {(genders ||
              income ||
              age ||
              ethnicity ||
              affiliation ||
              nonprofit ||
              purchase ||
              brands ||
              interests) && (
              <>
                <InfoTitle>Customer Demographics</InfoTitle>
                <DemographicsDesc>
                  Consumer data is representative of this brand’s consumers that follow
                  them on social media. The rankings and lists represent the highest
                  indexing (or most above average) attributes and preferences of this
                  dataset.
                </DemographicsDesc>

                {(genders || income || age || ethnicity) && (
                  <>
                    <MissionSubTitle>Primary Information</MissionSubTitle>
                    <PrimaryInformation
                      genders={genders}
                      income={income}
                      age={age}
                      ethnicity={ethnicity}
                    />
                  </>
                )}

                {(affiliation || nonprofit || purchase || brands || interests) && (
                  <>
                    <MissionSubTitle>Secondary Information</MissionSubTitle>
                    <SecondaryInformation
                      affiliation={affiliation}
                      nonprofit={nonprofit}
                      purchase={purchase}
                      brands={brands}
                      interests={interests}
                    />
                  </>
                )}
              </>
            )}

            {additionalMaterials && !isCompanyUser && (
              <>
                <InfoTitle>Additional Materials</InfoTitle>
                <InfoValue>
                  {additionalMaterials.map(item => (
                    <DownloadFileTag key={item} fileKey={item} />
                  ))}
                </InfoValue>
              </>
            )}
          </InfoWrapper>
        </Content>
        {isCompanyUser && <ProfileSideBar news={news} />}
      </Body>
    </Root>
  );
};

export default ProfileView;
