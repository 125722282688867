import { DoughnutChart } from 'components/Charts';
import React from 'react';
import styled from 'styled-components';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';

interface Props {
  employeeParticipation: number;
  customerParticipation: number;
  themeColor1?: string;
  themeColor2?: string;
}

const ParticipationMetric = ({
  employeeParticipation,
  customerParticipation,
  themeColor1,
  themeColor2,
}: Props) => {
  return (
    <MetricRoot>
      <MetricTitle>Participation</MetricTitle>
      <Content>
        <ChartWrapper>
          <DoughnutChart
            value={employeeParticipation}
            fontSize={14}
            fontColor="#343A40"
            bgColor="#F3F3F4"
            circularColor={themeColor1 || '#EEC459'}
            circularWidth={6}
            size={70}
          />
          Employee Participation
        </ChartWrapper>
        <ChartWrapper>
          <DoughnutChart
            value={customerParticipation}
            fontSize={14}
            fontColor="#343A40"
            bgColor="#F3F3F4"
            circularColor={themeColor2 || '#619FBE'}
            circularWidth={6}
            size={70}
          />
          Consumer Participation
        </ChartWrapper>
      </Content>
    </MetricRoot>
  );
};

const Content = styled(MetricContent)``;

const ChartWrapper = styled.div`
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 14px;
`;

export default ParticipationMetric;
