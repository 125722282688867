import React from 'react';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';
import { ROIMeltwaterData } from 'interfaces/roi';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

interface Props {
  data?: ROIMeltwaterData['analyticsData']['analytics']['time_series'];
}

const MediaExposureMetric = ({ data }: Props) => {
  return (
    <MetricRoot>
      <MetricTitle>Media Exposure (over time)</MetricTitle>
      <MetricContent>
        <LineChart
          width={320}
          height={156}
          data={data}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        >
          <CartesianGrid />
          <XAxis dataKey="date" tick={{ fontSize: '12px' }} />
          <YAxis tick={{ fontSize: '12px' }} />
          <Tooltip />
          <Legend
            formatter={() => 'Document count'}
            wrapperStyle={{ fontSize: '12px', fontWeight: 500, bottom: '-4px' }}
          />
          <Line
            type="monotone"
            dataKey="document_count"
            stroke="#2BAEE0"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </MetricContent>
    </MetricRoot>
  );
};

export default MediaExposureMetric;
