import React, { useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { UICheckbox } from 'components/UI';
import { UICheckboxProps } from 'components/UI/interfaces';

interface FieldCheckboxProps extends UICheckboxProps, FieldRenderProps<boolean> {}

const FieldCheckbox = ({ input, ...checkboxProps }: FieldCheckboxProps) => {
  const handleClick = useCallback(() => {
    input.onChange(!input.value);
  }, [input]);

  return <UICheckbox {...checkboxProps} check={input.value} handleClick={handleClick} />;
};

export default FieldCheckbox;
