import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { FieldRenderProps, Form, FormSpy } from 'react-final-form';
import { Field } from 'components/FinalForm';
import {
  FieldDatePicker,
  FieldSuggestSingle,
  FieldTextInput,
  MaskedMoneyTextInput,
} from 'components/FormFields';
import { required, webSiteLink } from 'utils/validation';
import { SuggestTeams, TeamCreateReq, TeamProfileData } from 'interfaces/team';
import { ROIAddTeamRes, ROICreateReq } from 'interfaces/roi';
import { FormRow } from 'components/UI/form';
import { composeValidators, getDateCutOffTime } from 'utils';
import { FormApi } from 'final-form';
import { useModal } from 'hooks/common';
import ExistingTeamFoundModal from './ExistingTeamFoundModal';
import UIAsterisk from 'components/UI/UIAsterisk';

interface Props extends InjectedProps {
  handleAccess: (params: ROICreateReq) => void;
  handleGetTeams: (val: string) => void;
  teamsSearchList: SuggestTeams;
  onTeamCreate: (values: TeamCreateReq) => Promise<ROIAddTeamRes>;
  onTeamCreateByChoose: (values: TeamCreateReq) => Promise<ROIAddTeamRes>;
}

interface FormData {
  name: string;
  team: TeamProfileData & { name: string };
  companyUrl: string;
  CompanyAddress: string;
  annualFundraisingCommitment: string;
  renewalDate: string;
}

const AddNewPartnerByTeamModal = ({
  onClose,
  handleGetTeams,
  teamsSearchList,
  handleAccess,
  isloading,
  onTeamCreate,
  onTeamCreateByChoose,
  ...modalProps
}: Props) => {
  useLockBodyScroll();

  const formRef = useRef<FormApi | undefined>(undefined);
  const [list, setList] = useState<TeamProfileData[]>([]);

  const [showModal, hideModal] = useModal(
    () => (
      <ExistingTeamFoundModal
        list={list}
        onAccess={async value => {
          let team = value;
          if (!value.id) {
            const res = await onTeamCreateByChoose({
              organizationName: value.organizationName,
              contactWebsite: value.contactWebsite,
            });
            if (res.exist === undefined) {
              team = res;
            }
          }
          formRef.current?.change('team', { ...team, name: team.organizationName });
          formRef.current?.change('companyUrl', team.contactWebsite);
          formRef.current?.change('CompanyAddress', team.address);
          hideModal();
        }}
        onClose={hideModal}
      />
    ),
    [list],
  );

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 0' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '836px',
        overflow: 'unset',
      }}
    >
      <Title>Add New Partner</Title>
      <Form<FormData>
        onSubmit={values => {
          if (!values.team.id) {
            onTeamCreate({
              organizationName: values.team.name,
              contactWebsite: values.companyUrl,
            }).then(data => {
              if (data.exist) {
                const teams: TeamProfileData[] = data.teams;
                setList(teams);
                showModal();
                return;
              }
              if (data.exist === undefined) {
                handleAccess({
                  ...values,
                  teamId: data.id,
                  companyName: data.organizationName,
                });
              }
            });
            return;
          }
          handleAccess({
            ...values,
            teamId: values?.team?.id,
            companyName: values?.team?.name,
          });
        }}
        render={({ handleSubmit, form, values }) => (
          <FormBody>
            <Field
              name="name"
              label="Partnership Description"
              component={FieldTextInput}
            />
            <FormRow>
              <Field
                name="team"
                validate={v => {
                  return v && v.name ? undefined : 'Required';
                }}
              >
                {(props: FieldRenderProps<any>) => {
                  const input = {
                    ...props.input,
                    onChange: (value: SuggestTeams[0]) => {
                      if (!value) {
                        form.change('companyUrl', undefined);
                        form.change('CompanyAddress', undefined);
                        props.input.onChange(undefined);
                        return;
                      }

                      if (value.id) {
                        setTimeout(() => {
                          form.change('companyUrl', value.contactWebsite);
                          form.change('CompanyAddress', `${value.address || undefined}`);
                        });
                      }
                      props.input.onChange(value);
                    },
                  };
                  return (
                    <FieldSuggestSingle
                      {...props}
                      label={
                        <>
                          Organization Name
                          <UIAsterisk />
                        </>
                      }
                      handleGetSuggestions={handleGetTeams}
                      list={teamsSearchList}
                      placeholder="Search"
                      input={input}
                      isTypeInputToForm
                      disabled={values?.team?.id}
                    />
                  );
                }}
              </Field>
              <Field
                name="companyUrl"
                label={
                  <>
                    Website
                    <UIAsterisk />
                  </>
                }
                component={FieldTextInput}
                validate={
                  values?.team?.id
                    ? () => undefined
                    : composeValidators<string>(required, webSiteLink)
                }
                disabled={values?.team?.id}
              />
            </FormRow>
            <Field
              name="CompanyAddress"
              label="Address"
              component={FieldTextInput}
              disabled={values?.team?.id}
            />
            <FormRow>
              <Field
                name="annualFundraisingCommitment"
                label="Fundraising Commitment"
                component={MaskedMoneyTextInput}
              />
              <Field
                name="renewalDate"
                label="Renewal Date"
                parse={v => getDateCutOffTime(v)}
                component={FieldDatePicker}
                popperPlacement="top"
              />
            </FormRow>

            <FormSpy subscription={{}}>
              {({ form }) => {
                formRef.current = form;
                return null;
              }}
            </FormSpy>

            <BtnGroup>
              <Btn modifiers="secondary" title="Cancel" handleClick={onClose} />
              <Btn
                modifiers="primary"
                title="Save"
                isLoading={isloading}
                handleClick={handleSubmit}
              />
            </BtnGroup>
          </FormBody>
        )}
      />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 46px;
  text-align: center;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Btn = styled(UIButton)`
  display: block;
  width: 87px;
  font-size: 14px;
  padding: 7px 0;
`;

const FormBody = styled.div`
  margin: 0 32px;
`;

export default AddNewPartnerByTeamModal;
