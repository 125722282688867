import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

interface Props extends NumberFormatProps {
  value: number;
}

const FormatNumber = ({ value, ...rest }: Props) => {
  return (
    <NumberFormat
      value={value}
      displayType="text"
      thousandSeparator={true}
      decimalScale={2}
      {...rest}
    />
  );
};

export default FormatNumber;
