import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import { CardTeamProps } from 'interfaces/common';
import { UIButton, UICheckbox, UIDollarNumber, UILoadAvatar } from 'components/UI';
import EmptyCompany from 'assets/images/icons/empty-company.svg';
import { CardCompanyRankText } from 'interfaces/search';
import { TeamProfileData } from 'interfaces/team';

const CardTeam = ({
  id,
  organizationName,
  logoKey,
  zip,
  address,
  contactPhone,
  score,
  primaryMissionFocus,
  annualTotalContributions,
  selectedTeamIds,
  setSelectedTeam,
  isSelectedCard,
  rankText = CardCompanyRankText['Priority Ranking'],
}: TeamProfileData & CardTeamProps) => {
  const { push } = useHistory();

  return (
    <Root>
      {Array.isArray(selectedTeamIds) && isSelectedCard && (
        <SelectedCheckbox
          check={selectedTeamIds.includes(id)}
          handleClick={() => setSelectedTeam?.(id)}
        />
      )}

      <Left>
        <ImageWrapper>
          {logoKey ? <LoadImage imgKey={logoKey} /> : <ReactSVG src={EmptyCompany} />}
        </ImageWrapper>

        <RankPriority>
          <RankText>{rankText}</RankText>
          <RankNumber>{score || '-'}</RankNumber>
        </RankPriority>
      </Left>
      <Right>
        <Top>
          <Name to={`/team-detail/${id}`}>{organizationName}</Name>
          <Address>
            {address && `${address} `}
            {zip && `${zip}`}
            {!address && !zip && 'No Address'}
          </Address>
          <Phone>{contactPhone || 'No phone'}</Phone>
        </Top>
        <Bottom>
          <Box>
            <BoxLeft>
              <DarkGrayText>Mission Focus</DarkGrayText>
              <CSRFocusWrapper>
                {primaryMissionFocus ? (
                  <CSRFocus>{primaryMissionFocus.label}</CSRFocus>
                ) : (
                  <CSRFocus>No information</CSRFocus>
                )}
              </CSRFocusWrapper>
            </BoxLeft>
            <BoxRight>
              <DarkGrayText textAlign="right">Annual Contributions</DarkGrayText>
              <BlackText>
                {annualTotalContributions ? (
                  <UIDollarNumber value={annualTotalContributions} />
                ) : (
                  '-'
                )}
              </BlackText>
            </BoxRight>
          </Box>
          <ButtonsGroup>
            <ProfileLink
              $isUnBlocked={false}
              asBtn={'a'}
              modifiers="third"
              handleClick={() => push(`/team-detail/${id}`)}
              title="Profile"
            />
          </ButtonsGroup>
        </Bottom>
      </Right>
    </Root>
  );
};

const SelectedCheckbox = styled(UICheckbox)`
  position: absolute;
  right: 8px;
  top: 8px;
`;

const Root = styled.section`
  width: 536px;
  min-height: 268px;
  background: #ffffff;
  border-radius: 6px;
  margin: 12px 12px;
  padding: 26px 32px;
  display: flex;
  position: relative;
`;

const ButtonsGroup = styled.div`
  display: flex;
  align-items: center;

  & button {
    margin-right: 8px;
  }
`;

const CSRFocusWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ProfileLink = styled(UIButton)<{ $isUnBlocked?: boolean }>`
  flex: 1;
  color: var(--blue);
  text-align: center;

  ${({ $isUnBlocked }) => `font-weight: ${$isUnBlocked ? 500 : 'normal'};`}
`;

const Box = styled.div`
  display: flex;
  margin-bottom: 14px;
  width: 100%;
`;

const Top = styled.div`
  min-height: 86px;
`;

const Bottom = styled.div``;

const BoxLeft = styled.div`
  max-width: 170px;
  width: 100%;
  border-right: 1px solid #e8e8e8;
  padding: 0 20px 0 0;
`;
const BoxRight = styled.div`
  padding: 0 0 0 20px;
  width: 100%;
`;

const Name = styled(Link)`
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 285px;
  &:hover {
    text-decoration: underline;
  }
`;

const BlackText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  text-align: right;
`;

const DarkGrayText = styled.p<{ textAlign?: 'center' | 'left' | 'right' }>`
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
  margin-bottom: 4px;

  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`}
`;

const Address = styled(DarkGrayText)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 36px;
  margin-top: 12px;
`;

const Phone = styled(DarkGrayText)`
  margin-bottom: 12px;
`;

const CSRFocus = styled(BlackText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Right = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Left = styled.div`
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 6px;
  width: 168px;
  height: 216px;
  margin-right: 16px;
`;

const ImageWrapper = styled.div`
  min-width: 166px;
  height: 156px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
`;

const LoadImage = styled(UILoadAvatar)`
  width: 104px;
  height: 104px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
`;

const RankPriority = styled.div`
  text-align: center;
`;

const RankText = styled.p`
  color: var(--darkGray);
  margin: 8px 0 2px;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
`;
const RankNumber = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
`;

export default CardTeam;
