import { GivingListReq } from 'interfaces/giving';
import { Dispatch } from 'redux';
import { fetchErrorHandler } from 'utils/errorHandlers';
import * as actions from './actions';
import * as api from './api';

export const fetchGivingList = (params: GivingListReq) => async (dispatch: Dispatch) => {
  return api
    .fetchGivingList(params)
    .then(value => dispatch(actions.setGivingList(value)))
    .catch(fetchErrorHandler);
};

export const fetchBatchList = () => async (dispatch: Dispatch) => {
  return api
    .fetchBatchList()
    .then(list => {
      dispatch(actions.setBatchList(list));
    })
    .catch(fetchErrorHandler);
};

export const fetchGivingUploadToSFTP = (savedListTeamId: string) => async () => {
  return api.fetchGivingUploadToSFTP(savedListTeamId).catch(fetchErrorHandler);
};
