/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { FieldSingleSelect } from 'components/FormFields';

import styled from 'styled-components';
import { Field } from 'components/FinalForm';
import { FormTitle } from 'components/UI/form';
import { EmailDesignGetReq, SendGridTemplate } from 'interfaces/emailDesign';
import { OnChange } from 'react-final-form-listeners';
import { paramsToQueryString } from 'utils';
import { fetch } from 'services/ApiService';
import { gameplanTemplateOptions, pitchTemplateOptions } from 'constants/emailDesign';
import { FormApi } from 'final-form';
import { required } from 'utils/validation';

interface FormProps {
  handleFetchEmailDesign: (params: EmailDesignGetReq) => void;
  handleChange: (param: Pick<SendGridTemplate, 'emailType' | 'templateId'>) => void;
}

interface FormData {
  template: string;
  type: SendGridTemplate['emailType'];
}

const EmailDesignSelects = ({ handleFetchEmailDesign, handleChange }: FormProps) => {
  const handleChangeType = (
    templateType: EmailDesignGetReq['templateType'],
    form: FormApi<FormData, Partial<FormData>>,
  ) => {
    fetch<SendGridTemplate>(
      'get',
      `sendGridTemplate/byTemplateType${paramsToQueryString({ templateType })}`,
    ).then(value => {
      let templateId = '';
      if (value) {
        templateId = value.templateId;
      } else {
        if (templateType === 'GamePlan') {
          templateId = gameplanTemplateOptions[0].value;
        } else {
          templateId = pitchTemplateOptions[0].value;
        }
      }

      form.change('template', templateId);
      handleFetchEmailDesign({
        templateId: templateId,
        templateType: templateType,
      });
    });
  };

  return (
    <>
      <Form
        onSubmit={() => {}}
        render={({ values, form, handleSubmit }: FormRenderProps<FormData>) => {
          return (
            <FormStyled id="email-design-selects" onSubmit={handleSubmit}>
              <FormTitle>Email Design</FormTitle>
              <Field
                name="type"
                label="Email Type"
                component={FieldSingleSelect}
                options={[
                  { value: 'GamePlan', label: 'Game Plan' },
                  { value: 'Pitch', label: 'Pitch' },
                ]}
                validate={required}
              />
              <OnChange name="type">
                {(value, previous) => {
                  if (!value) return;
                  if (value === previous) return;
                  form.change('template', '');
                  handleChangeType(value, form);
                  handleChange({ emailType: value, templateId: values.template });
                }}
              </OnChange>
              {values.type === 'GamePlan' && (
                <>
                  <Field
                    name="template"
                    label="Template"
                    component={FieldSingleSelect}
                    options={gameplanTemplateOptions}
                    validate={required}
                  />
                </>
              )}
              {values.type === 'Pitch' && (
                <Field
                  name="template"
                  label="Template"
                  component={FieldSingleSelect}
                  options={pitchTemplateOptions}
                  validate={required}
                />
              )}
              <OnChange name="template">
                {(value, previous) => {
                  if (!value || value === previous) return;
                  if (!values.type) return;
                  handleFetchEmailDesign({
                    templateType: values.type,
                    templateId: value,
                  });
                  handleChange({ emailType: values.type, templateId: value });
                }}
              </OnChange>
            </FormStyled>
          );
        }}
      />
    </>
  );
};

const FormStyled = styled.form`
  background: #ffffff;
  width: 374px;
`;

export default EmailDesignSelects;
