import { useOperation } from 'hooks/common';
import { fetchPayout } from 'store/ducks/payout/operations';
import { fetchTotalRaisedById } from 'store/ducks/campaign/operations';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import React from 'react';
import { UIButton } from 'components/UI';
import { PayoutReq } from 'interfaces/payout';
import { RecentlyAddedCompaniesDashboard } from '../dashboard/components';
import Toast from 'services/Toast';
import { useHistory, useParams } from 'react-router';
import { FundsRaisedRes } from 'interfaces/campaign';

interface CampaignTSPayoutProps {
  giveworxCampaignId: string;
}
// Styled components
const PayoutFormWrapper = styled.div`
  max-width: 400px;
  margin: 10px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const RadioButtonLabel = styled.label`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FormGroup = styled.div`
  margin-bottom: 10px;
`;
const InputWrapper = styled.div`
  margin-bottom: 10px;
`;

const StyledLabel = styled.label`
  display: block;
  font-weight: bold;
  padding: 5px 0px 5px 0px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
`;

const SubmitButton = styled.button`
  margin-top: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;
const ValidationMessage = styled.p`
  color: red;
  margin: 10px 0px;
  min-height: 20px;
`;

const BtnWrapper = styled.div`
  display: flex;
`;
const Btn = styled(UIButton)`
  width: auto;
  margin-right: 8px;
  padding-top: 10px;
`;
const StyledList = styled.ol`
  text-align: left;
`;
const StyledListItem = styled.li`
  margin-left: 20px;
  padding-top: 5px;
`;
const StyledText = styled.p`
  line-height: 1.5em; 
`;

const CampaignTSPayout: React.FC<CampaignTSPayoutProps> = () => {
  const { giveworxCampaignId } = useParams<{ giveworxCampaignId: string }>();
  const [selectedOption, setSelectedOption] = useState<string>('PayPal');
  const [recipient, setRecipient] = useState<string>('');
  const [validationMessage, setValidationMessage] = useState<string>(' ');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [FundsAvailable, setFundsAvailable] = useState<boolean>(false);
  const [onFetchPayout] = useOperation(fetchPayout);
  const [onFetchTotalRaisedById] = useOperation(fetchTotalRaisedById);
  const { push } = useHistory();

  const [payoutAmount, setPayoutAmount] = useState<number>(0);

  const [submitting, setsubmitting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result: FundsRaisedRes = (await onFetchTotalRaisedById(
        giveworxCampaignId,
      )) as FundsRaisedRes;
      if (result.status == 200 && result.data) {
        setPayoutAmount(Number(result.data.TotalRaised));
        setFundsAvailable(true);
      } else {
        setFundsAvailable(false);
      }
    };
    fetchData();
  }, []);

  const resetValidation = () => {
    setValidationMessage(' ');
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    resetValidation();
    setSelectedOption(event.target.value);
  };

  const handleRecipientChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRecipient(event.target.value);
  };

  const handleCancelClick = () => {
    push('/campaignTeamsnap');
  };

  const isValidEmail = (email: string) => {
    // Use a simple regular expression for basic email format validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const isValidVenmoUsername = (username: string) => {
    return username.length >= 5 && username.length <= 35;
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  });

  const handleSubmit = (event: React.FormEvent) => {
    setsubmitting(true);
    resetValidation();
    // Validation checks
    if (selectedOption === 'PayPal' && !isValidEmail(recipient)) {
      setsubmitting(false);
      setValidationMessage('Please enter a valid email address for PayPal.');
      event.preventDefault();
      return;
    }

    if (selectedOption === 'Venmo' && !isValidVenmoUsername(recipient)) {
      setsubmitting(false);
      setValidationMessage('Venmo username should be between 5 and 35 characters.');
      event.preventDefault();
      return;
    }
    event.preventDefault();

    let recipType = '';
    let recipWallet = '';
    switch (selectedOption) {
      case 'PayPal':
        recipType = 'EMAIL';
        recipWallet = 'PAYPAL';
        break;
      case 'Venmo':
        recipType = 'USER_HANDLE';
        recipWallet = 'VENMO';
        break;
    }

    // Perform your API call here
    const payoutData: PayoutReq = {
      note: 'Thanks for your support!',
      recipientType: recipType,
      receiver: recipient,
      senderItemId: '',
      recipientWallet: recipWallet,
      amount: payoutAmount,
      applicationContext: '',
      giveworxCampaignId: giveworxCampaignId,
    };

    onFetchPayout({ ...payoutData })
      .then(() => {
        setIsSuccess(true);
        Toast.success('Check your email to complete your fund transfer');
      })
      .catch(() => {
        Toast.error(
          'We are unable to complete your request. Please try again or contact teamsnapfundraising@accelerist.com',
        );
      });

    setSelectedOption('PayPal');
    setRecipient('');
    setsubmitting(false);
  };

  if (FundsAvailable) {
    return (
      <PayoutFormWrapper>
        <StyledLabel>Claim Your Fundraiser</StyledLabel>
        <StyledList>
          <StyledListItem>Choose a Payout Option</StyledListItem>
          <StyledListItem>Enter Email or Username</StyledListItem>
          <StyledListItem>
            Check your email - you will receive instructions from your chosen Payout
            Method
          </StyledListItem>
          <StyledListItem>
            Follow Payout method instructions to complete the fund transfer
          </StyledListItem>
        </StyledList>
        <div>
          {validationMessage && (
            <ValidationMessage>{validationMessage}</ValidationMessage>
          )}
        </div>
        {!isSuccess && (
          <InputWrapper>
            <RadioGroup>
              <RadioButtonLabel>
                <input
                  type="radio"
                  value="PayPal"
                  checked={selectedOption === 'PayPal'}
                  onChange={handleOptionChange}
                />
                PayPal
              </RadioButtonLabel>
              <RadioButtonLabel>
                <input
                  type="radio"
                  value="Venmo"
                  checked={selectedOption === 'Venmo'}
                  onChange={handleOptionChange}
                />
                Venmo
              </RadioButtonLabel>
            </RadioGroup>
            <FormGroup>
              <StyledLabel htmlFor="recipient-email">
                {selectedOption === 'PayPal' ? 'Paypal Email Address' : 'Venmo Username'}
              </StyledLabel>
              <StyledInput
                type={selectedOption === 'PayPal' ? 'email' : 'text'}
                id="recipient-email"
                value={recipient}
                onChange={handleRecipientChange}
                required
                pattern={
                  selectedOption === 'PayPal'
                    ? '[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}'
                    : undefined
                }
              />
            </FormGroup>
          </InputWrapper>
        )}
        <FormGroup>
          {isSuccess && (
            <StyledLabel>
              Thank You! You&apos;ve received instructions to complete your payout via the
              email attached to your PayPal or Venmo account. Check your email to finish
              receiving your funds.
            </StyledLabel>
          )}
          <BtnWrapper>
            <Btn
              modifiers={isSuccess ? 'primary' : 'secondary'}
              title={isSuccess ? 'Return to Campaign' : 'Cancel'}
              type="button"
              handleClick={handleCancelClick}
            />
            {!isSuccess && (
              <Btn
                modifiers="primary"
                title="Submit"
                handleClick={handleSubmit}
                type="submit"
                disabled={submitting}
                isLoading={submitting}
              />
            )}
          </BtnWrapper>
        </FormGroup>
      </PayoutFormWrapper>
    );
  }

  return (
    <PayoutFormWrapper>
      <div>
        <h2>Error: We are unable to complete your request.</h2>
        <br />
        <StyledText>There are no funds available in your account. Check with your Team Owner to confirm their claim of funds. If you believe there should be funds in your account, contact us at teamsnapfundraising@accelerist.com</StyledText>
      </div>
      <br />
      <Btn
        modifiers="primary"
        title="Return to Campaign"
        type="button"
        handleClick={handleCancelClick}
      />
    </PayoutFormWrapper>
  );
};

export default CampaignTSPayout;
