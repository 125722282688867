import axios from 'axios';
import Toast from 'services/Toast';

const fetchErrorHandler = <T>(error: T) => {
  if (!axios.isAxiosError(error)) return;

  const validationMessage =
    error.response?.data?.errors?.[0].constraints?.companyWebsiteExist ||
    error.response?.data?.errors?.[0].constraints?.userExistEmail;

  if (validationMessage) {
    Toast.error(validationMessage);
    return;
  }

  if (error.response?.data.message) {
    Toast.error(error.response.data.message);
  }
};

export { fetchErrorHandler };
