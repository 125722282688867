import React from 'react';
import styled from 'styled-components';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';
import { ReactSVG } from 'react-svg';
import { ROIMeltwaterData } from 'interfaces/roi';
import { TrackingDashboardDocumentStatusEnum } from 'constants/roi';
import { format } from 'date-fns';
import { formatNumber } from 'utils/formatters';
import NewSVG from 'assets/images/icons/news.svg';
import ForumsSVG from 'assets/images/icons/forums.svg';
import BlogsSVG from 'assets/images/icons/blogs.svg';
import FacebookSquareSVG from 'assets/images/icons/facebook-square.svg';
import RedditSVG from 'assets/images/icons/reddit.svg';
import TwitterBridSVG from 'assets/images/icons/twitter-brid.svg';
import InstagramSVG from 'assets/images/icons/instagram.svg';

const getIcon = (docItem: ROIMeltwaterData['analyticsData']['exports']['data'][0]) => {
  if (docItem.document_media_type === 'news' || docItem.source_name === 'news')
    return <NewsInfoLogo src={NewSVG} />;

  if (docItem.document_media_type === 'forums' || docItem.source_name === 'forums')
    return <NewsInfoLogo src={ForumsSVG} />;

  if (docItem.document_media_type === 'blogs' || docItem.source_name === 'blogs')
    return <NewsInfoLogo src={BlogsSVG} />;

  if (docItem.document_media_type === 'facebook' || docItem.source_name === 'facebook')
    return <NewsInfoLogo src={FacebookSquareSVG} />;

  if (docItem.document_media_type === 'reddit' || docItem.source_name === 'reddit')
    return <NewsInfoLogo src={RedditSVG} />;

  if (docItem.document_media_type === 'twitter' || docItem.source_name === 'twitter')
    return <NewsInfoLogo src={TwitterBridSVG} />;

  if (docItem.document_media_type === 'instagram' || docItem.source_name === 'instagram')
    return <NewsInfoLogo src={InstagramSVG} />;
};

interface Props {
  data?: ROIMeltwaterData['analyticsData']['exports']['data'];
}

const ContentStreamMetric = ({ data }: Props) => {
  return (
    <MetricRoot>
      <MetricTitle>Content Stream (Top 3)</MetricTitle>
      <MetricContent>
        {data?.map(item => (
          <NewsRow key={item.document_id} onClick={() => window.open(item.document_url)}>
            <NewsInfoTitle>
              {getIcon(item) || <EmptyInfoLogo />}
              <NewsInfoName>{item.document_authors?.[0]?.name}</NewsInfoName>
              <NewsInfoStatus
                type={TrackingDashboardDocumentStatusEnum[item.document_sentiment]}
              >
                {TrackingDashboardDocumentStatusEnum[item.document_sentiment]}
              </NewsInfoStatus>
            </NewsInfoTitle>
            <NewsInfoDesc>{item.document_title}</NewsInfoDesc>
            <NewsInfoFooter>
              <NewsInfoDate>
                {format(new Date(item.document_publish_date), 'd MMM y, h:ss')}
              </NewsInfoDate>
              <NewsActionGroup>
                <NewsAction>{formatNumber(item.source_reach)} Reach</NewsAction>
                <NewsAction>Social Echo</NewsAction>
              </NewsActionGroup>
            </NewsInfoFooter>
          </NewsRow>
        ))}
      </MetricContent>
    </MetricRoot>
  );
};

const NewsRow = styled.div`
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: 6px;
  }
`;

const NewsInfoTitle = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  color: var(--black);
  align-items: center;
`;
const NewsInfoName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 226px;
`;
const NewsInfoLogo = styled(ReactSVG)`
  margin-right: 10px;
  svg {
    height: 12px;
    width: 12px;
  }
`;
const EmptyInfoLogo = styled.div`
  width: 21px;
  height: 21px;
  margin-right: 10px;
`;
const NewsInfoStatus = styled.div<{ type: TrackingDashboardDocumentStatusEnum }>`
  margin-left: auto;

  &::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid transparent;
    display: inline-block;
    margin-left: 8px;

    ${({ type }) => {
      if (type === TrackingDashboardDocumentStatusEnum.positive) {
        return `border-color: #4AD78B`;
      }
      if (type === TrackingDashboardDocumentStatusEnum.negative) {
        return `border-color: #E92D30;`;
      }
      if (type === TrackingDashboardDocumentStatusEnum.neutral) {
        return `border-color: #c4c4c4;`;
      }
    }}
  }
`;
const NewsInfoDesc = styled.div`
  color: var(--darkGray);
  font-size: 12px;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 22px;
  margin-bottom: 2px;
`;
const NewsInfoFooter = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 1.2;
  padding-bottom: 2px;
  border-bottom: 1px solid #e8e8e8;
  margin-left: 22px;
`;
const NewsInfoDate = styled.div`
  color: var(--darkGray);
`;
const NewsActionGroup = styled.div`
  color: var(--blue);
  display: flex;
  margin-left: auto;
`;
const NewsAction = styled.div`
  margin-left: 10px;
`;
export default ContentStreamMetric;
