import SearchSuggest from 'components/SearchSuggest';
import TotalRow from 'components/TotalRow';
import { UIButton } from 'components/UI';
import { useModal, useOperation } from 'hooks/common';
import { PaginationMeta } from 'interfaces/common';
import { SuggestCompany } from 'interfaces/companies';
import { ROIUIType } from 'interfaces/roi';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchCompaniesSearch } from 'store/ducks/companies/operations';
import { getCompaniesSearch } from 'store/ducks/companies/selectors';
import {
  fetchGiveROIAddTeam,
  fetchGiveROIAddTeamNew,
  fetchParROISuggest,
} from 'store/ducks/fundraising/operations';
import { StateProps } from 'store/interfaces';
import styled from 'styled-components';
import AddNewPartnerModal from './modals/AddNewPartnerModal';
import { getQueryString } from 'utils';
import { getTeamsSuggest } from 'store/ducks/team/selectors';
import { fetchTeamsSuggestWithEin } from 'store/ducks/team/operations';
import {
  fetchGiveROIAddCompany,
  fetchGiveROIAddNew,
} from 'store/ducks/campaign/operations';

interface Props {
  meta?: PaginationMeta | undefined;
  searchValue?: string;
  type?: ROIUIType;
  onSearch?: any;
  onChangeSearchValue?: any;
  onFetchPartnersList?: any;
  isCompanyUser: boolean;
}

const PartnersSearch = ({ meta, onSearch, searchValue, onChangeSearchValue }: Props) => {
  const [onSearchCompanies] = useOperation(fetchCompaniesSearch);
  const [onFetchCompanyCreate, , companyCreateLoading] = useOperation(
    fetchGiveROIAddCompany,
  );
  const [onFetchCompanyCreateNew] = useOperation(fetchGiveROIAddNew);
  const [onFetchParROIs] = useOperation(fetchParROISuggest);
  const [onFetchTeamCreate, , teamCreateLoading] = useOperation(fetchGiveROIAddTeam);
  const [onFetchTeamCreateNew] = useOperation(fetchGiveROIAddTeamNew);
  const [onSearchTeams, , searchTeamsLoading] = useOperation(fetchTeamsSuggestWithEin);
  const [searchList, setSearchList] = useState([]);

  const teamsSearchList = useSelector(getTeamsSuggest);

  const companiesSearchList = useSelector<StateProps, SuggestCompany[]>(
    getCompaniesSearch,
  );

  const handleSearchCompanies = (val: string) => {
    return onSearchCompanies(val);
  };

  const handleSearchCompaniesDebounce = useCallback(
    debounce(handleSearchCompanies, 800),
    [],
  );

  const handleSearchTeams = (val: string) => {
    return onSearchTeams(val, true);
  };
  const handleSearchTeamsDebounce = useCallback(debounce(handleSearchTeams, 800), []);

  const handleSearchROIs = (val: string) => {
    onFetchParROIs(val).then(ele => {
      const a: any = [];
      ele?.items.forEach(item =>
        a.push({
          name: item.companyName,
          id: item.id,
        }),
      );
      setSearchList(a);
    });
  };

  const handleSearchDebounce = useCallback(debounce(handleSearchROIs, 800), []);

  const [showAddNewPartnerModal, hideAddNewPartnerModal] = useModal(
    () => (
      <AddNewPartnerModal
        handleAccess={() => {
          onSearch({
            page: 1,
            q: searchValue,
            sortType: '',
            sortBy: '',
            operation: '',
          });
          hideAddNewPartnerModal();
        }}
        onCompanyCreate={onFetchCompanyCreate}
        onCompanyCreateByChoose={onFetchCompanyCreateNew}
        onClose={hideAddNewPartnerModal}
        handleGetCompanies={handleSearchCompaniesDebounce}
        companiesSearchList={companiesSearchList}
        onTeamCreate={onFetchTeamCreate}
        onTeamCreateByChooses={onFetchTeamCreateNew}
        teamsSearchList={teamsSearchList}
        handleGetTeams={handleSearchTeamsDebounce}
        isloading={companyCreateLoading || teamCreateLoading}
        isSearchLoading={searchTeamsLoading}
      />
    ),
    [
      companiesSearchList,
      teamsSearchList,
      teamCreateLoading,
      companyCreateLoading,
      searchTeamsLoading,
    ],
  );

  useEffect(() => {
    handleSearchROIs(getQueryString('q') || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Root>
      <Header>
        <TotalRow
          title={`${meta?.totalItems || 0} partnership${
            meta?.totalItems || 0 > 1 ? 's' : ''
          }`}
          maxWidth="unset"
        >
          <Actions>
            <SearchPartner
              handleGetSuggestions={handleSearchDebounce}
              list={searchList}
              placeholder="Search Partners"
              searchValue={searchValue}
              onSearch={(value: string) => {
                onSearch({ page: 1, q: value });
                onChangeSearchValue(value);
              }}
            />
            <Button
              title="Add New Partner"
              modifiers="primary"
              handleClick={showAddNewPartnerModal}
            />
          </Actions>
        </TotalRow>
      </Header>
    </Root>
  );
};

const Root = styled.div`
  margin-top: 28px;
`;

const Header = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Actions = styled.div`
  margin-left: auto;
  display: flex;
`;

const Button = styled(UIButton)`
  margin-left: 24px;
  width: 220px;
`;

const SearchPartner = styled(SearchSuggest)`
  width: 436px;
  margin-left: 36px;
`;

export default PartnersSearch;
