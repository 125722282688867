import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { UIModalProps } from 'components/UI/interfaces';
import { UIModal } from 'components/UI';
import { useOperation } from 'hooks/common';
import success from 'assets/images/icons/success.svg';
import { ReactSVG } from 'react-svg';
import { UIButton } from 'components/UI';
import { useSelector } from 'react-redux';
import { getTeam } from 'store/ducks/team/selectors';
import { affirmEinInfo, fetchEinDetails } from 'store/ducks/campaign/operations';
import Toast from 'services/Toast';
import { PaypalTypeEnum } from 'constants/team';

interface Props {
  showSupport?: any;
}

function ConfirmEINModal({ onClose, showSupport, ...modalProps }: Props & UIModalProps) {
  const team = useSelector(getTeam);
  const [onFetchEIN] = useOperation(fetchEinDetails);
  const [affirmEin] = useOperation(affirmEinInfo);

  const [details, setEINDetails] = useState<any>('');
  const [submitting, setSubmitting] = useState(false);

  const init = () => {
    if (!team?.paypalGivingFundId) return;
    onFetchEIN(team?.paypalGivingFundId).then(ele => {
      setEINDetails(ele.data);
    });
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    setSubmitting(true);
    affirmEin({}).then((e: any) => {
      if (e) setSubmitting(false);
      if (e.status === 200) onClose();
      if (e.status !== 200) Toast.error(e.msg);
    });
  };

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: 0 }}
      style={{
        width: '100%',
        maxWidth: '336px',
      }}
    >
      <Title>
        <ReactSVG src={success} />
      </Title>
      <Contentn>
        <ContentTitlt>Confirm that the following information is correct:</ContentTitlt>
        <ContentDiv>
          <DataTitle>{details.name}</DataTitle>
          <DataVal>
            {details.city}, {details.state}, {details.country}
          </DataVal>
          <ContentData>
            <DataTitle>Registered EIN: </DataTitle>
            <DataVal>{details.ein}</DataVal>
          </ContentData>
          <ContentData>
            <DataTitle>
              {team?.paypalType === PaypalTypeEnum.PayPal_Commerce
                ? 'PayPal Commerce'
                : 'PayPal Giving Fund'}{' '}
              Account #:{' '}
            </DataTitle>
            <DataVal>{details.paypalGivingFundId}</DataVal>
          </ContentData>
        </ContentDiv>
        <DataVal style={{ marginBottom: 20 }}>
          If this information is not correct, please contact&nbsp;
          <div style={{ color: '#2BAEE0', cursor: 'pointer' }} onClick={showSupport}>
            Accelerist Support
          </div>
        </DataVal>
        <Btn
          style={{ width: '100%' }}
          modifiers="primary"
          title="Confirm"
          handleClick={handleSubmit}
          isLoading={submitting}
        />
      </Contentn>
    </UIModal>
  );
}

const Title = styled.div`
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  padding: 34px 0;
`;

const Contentn = styled.div`
  text-align: center;
  margin: 24px;
`;
const ContentTitlt = styled.h4`
  font-weight: 500;
  font-size: 16px;
`;
const ContentDiv = styled.div`
  margin: 20px 0;
  font-size: 12px;
`;
const ContentData = styled.div`
  display: flex;
  justify-content: center;
  margin: 4px 0;
`;
const DataTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
`;
const DataVal = styled.div`
  font-weight: 400;
  font-size: 12px;
`;
const Btn = styled(UIButton)`
  width: 146px;
  margin-right: 8px;
`;

export default ConfirmEINModal;
