import React from 'react';
import { UIActionButton } from 'components/UI';

import MailIcon from 'assets/images/icons/mail.svg';
import { ActionButtonProps as InjectedProps } from 'interfaces/common';
import useJumpToSupport from 'hooks/common/useJumpToSupport';

const SupportButton = ({
  name = 'Accelerist Support',
  icon = MailIcon,
  ...rest
}: Partial<InjectedProps>) => {
  // const [showModal, hideModal] = useModal(() => {
  //   return <SupportModal onClose={hideModal} />;
  // });

  const { jumpToSupport } = useJumpToSupport();

  return <UIActionButton {...rest} handleClick={jumpToSupport} icon={icon} name={name} />;
};

export default SupportButton;
