import styled from 'styled-components';

const UIActionText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--blue);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default UIActionText;
