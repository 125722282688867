import { Field } from 'components/FinalForm';
import { FieldDatePicker, FieldTextInput } from 'components/FormFields';
import { ConfirmModal } from 'components/Modals';
import { UIButton } from 'components/UI';
import { ROINavigationEnum } from 'constants/roi';
import { isAfter, subMonths } from 'date-fns';
import format from 'date-fns/format';
import { useModal } from 'hooks/common';
import { ROIData } from 'interfaces/roi';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { getUTCAsLocalDate } from 'utils';
import { notQuote } from 'utils/validation';
import { ButtonGroup, FieldDoubleRow } from './styled';

interface Props {
  disabled: boolean;
  roi: ROIData;
  onSave: (values: ROIData) => void;
  onTrack: (values: ROIData) => void;
}

const MeasureForm = ({ roi, disabled, onTrack, onSave }: Props) => {
  const { push } = useHistory();
  const [formValues, setFormValues] = useState<ROIData | undefined>(undefined);

  const getSubmitData = (values: ROIData) => {
    return {
      ...values,
      startDate: values.startDate && values.startDate.split('T')[0],
      endDate: values.endDate && values.endDate.split('T')[0],
    };
  };

  const [showModal, hideModal] = useModal(
    () => (
      <ConfirmModal
        name="Track Partnership"
        description="Once tracking information has been submitted, it cannot be changed. Are you sure you are ready to track partnership?"
        accessHandler={hideModal}
        cancelHandler={() => {
          formValues && onTrack(getSubmitData(formValues));
          hideModal();
        }}
        accessModifiersBtn="secondary"
        cancelModifiersBtn="primary"
        textAccessBtn="Cancel"
        textCancelBtn="Continue"
        onClose={hideModal}
      />
    ),
    [formValues],
  );

  return (
    <Form
      initialValues={{
        ...roi,
        startDate:
          roi.startDate && format(getUTCAsLocalDate(roi.startDate), 'yyyy-MM-dd'),
        endDate: roi.endDate && format(getUTCAsLocalDate(roi.endDate), 'yyyy-MM-dd'),
      }}
      onSubmit={(values: ROIData) => {
        setFormValues(values);
        showModal();
      }}
      render={({ handleSubmit, form, values }) => (
        <>
          <FieldDoubleRow>
            <Field
              name="campaignKeywords"
              label="Campaign Keywords"
              component={FieldTextInput}
              placeholder="separate keywords, hashtags and handles with comma"
              placeholderSize={13}
              validate={notQuote}
              disabled={disabled}
            />
            <Field
              name="campaignSpecificHashtags"
              label="Campaign-Specific Hashtags"
              component={FieldTextInput}
              placeholder="separate keywords, hashtags and handles with comma"
              placeholderSize={13}
              validate={notQuote}
              disabled={disabled}
            />
          </FieldDoubleRow>
          <FieldDoubleRow>
            <Field
              name="partnerSocialHandles"
              label="Partner Social Handles (include all networks) "
              component={FieldTextInput}
              placeholder="separate keywords, hashtags and handles with comma"
              placeholderSize={13}
              validate={notQuote}
              disabled={disabled}
            />
            <Field
              name="nonprofitSocialHandles"
              label="Nonprofit Social Handles (include all networks)"
              component={FieldTextInput}
              placeholder="separate keywords, hashtags and handles with comma"
              placeholderSize={13}
              validate={notQuote}
              disabled={disabled}
            />
          </FieldDoubleRow>
          <FieldDoubleRow>
            <Field
              name="influencerHandles"
              label="Influencer Handles (include celebrities, bloggers, experts, etc.) "
              component={FieldTextInput}
              placeholder="separate keywords, hashtags and handles with comma"
              placeholderSize={13}
              validate={notQuote}
              disabled={disabled}
            />
          </FieldDoubleRow>
          <FieldDoubleRow>
            <Field name="startDate">
              {({ input, meta }) => (
                <FieldDatePicker
                  label="Start Date"
                  input={{
                    ...input,
                    onChange: value => {
                      const isStartAfterEnd = isAfter(
                        new Date(value),
                        new Date(String(values.endDate)),
                      );

                      if (isStartAfterEnd) {
                        form.change('endDate', undefined);
                      }
                      input.onChange(value);
                    },
                  }}
                  meta={meta}
                  disabled={disabled}
                  showMonthDropdown
                  dateFormat="dd MMM yyyy"
                  minDate={subMonths(new Date(), 13)}
                />
              )}
            </Field>
            <Field
              name="endDate"
              label="End Date"
              component={FieldDatePicker}
              placeholder="select"
              disabled={disabled}
              showMonthDropdown
              dateFormat="dd MMM yyyy"
              minDate={new Date(String(values.startDate))}
            />
          </FieldDoubleRow>

          <ButtonGroup>
            <UIButton
              title="Cancel"
              modifiers="secondary"
              handleClick={() => push(`/roi?type=${ROINavigationEnum.Partnerships}`)}
            />
            {!roi.trackingStatus && (
              <>
                <UIButton
                  data-tip="save now and come back later to submit for tracking"
                  title="Save"
                  modifiers="secondary"
                  handleClick={() => onSave(getSubmitData(values))}
                  disabled={disabled}
                />
                <UIButton
                  title="Track Partnership"
                  modifiers="primary"
                  handleClick={handleSubmit}
                  disabled={disabled}
                />
              </>
            )}
            <ReactTooltip
              className="tooltip-info"
              place="top"
              type="info"
              effect="solid"
            />
          </ButtonGroup>
        </>
      )}
    />
  );
};

export default MeasureForm;
