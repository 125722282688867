import { PricingData, PricingListReq } from 'interfaces/pricing';
import { Dispatch } from 'redux';
import Toast from 'services/Toast';
import {
  setDashboardPricing,
  setPricing,
  setPricingList,
  setReport,
  setReportFile,
  setValuationDelete,
  setValuationSave,
  setAdminNoteList,
} from './actions';
import * as api from './api';
import * as objNoteApi from 'store/ducks/objNote/api';

export const fetchPricingList = (param: PricingListReq) => async (dispatch: Dispatch) => {
  return api
    .fetchPricingList(param)
    .then(value => dispatch(setPricingList(value)))
    .catch(() => Toast.error('Error get pricing list'));
};

export const fetchPricing = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchPricing(id)
    .then(value => dispatch(setPricing(value)))
    .catch(() => Toast.error('Error get valuation'));
};

export const fetchSaveValuation = (param: PricingData) => async (dispatch: Dispatch) => {
  return api
    .fetchSaveValuation(param)
    .then(value => dispatch(setValuationSave(value)))
    .catch(() => Toast.error('Error save valuation'));
};

export const fetchDeleteValuation = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchDeleteValuation(id)
    .then(() => dispatch(setValuationDelete(id)))
    .catch(() => Toast.error('Error delete valuation'));
};

export const fetchSubmitValuation = (id: string) => async () => {
  return api
    .fetchSubmitValuation(id)
    .then(() => Toast.success('submit valuation successfully'))
    .catch(() => Toast.error('Error submit valuation'));
};

export const fetchDashboradPricing = () => async (dispatch: Dispatch) => {
  return api
    .fetchDashboradPricing()
    .then(dashboardPricing => dispatch(setDashboardPricing(dashboardPricing)))
    .catch(() => Toast.error('Error get pricing'));
};

export const fetchReport = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchReport(id)
    .then(report => {
      dispatch(setReport(report));
    })
    .catch(() => Toast.error('Error get report data'));
};

export const fetchUploadReportPDF = ({ id, file }: { id: string; file: File }) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchUploadReportPDF(id, file)
    .then(v => {
      dispatch(setReportFile({ id, fileKey: v.fileKey }));
      return v;
    })
    .catch(() => Toast.success('Error export PDF'));
};

export const fetchRevertReport = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchRevertReport(id)
    .then(value => {
      dispatch(setValuationSave(value));
      Toast.success('Revert successful');
    })
    .catch(() => Toast.error('Error revert report'));
};

export const fetchAdminNoteList = (objectId: string) => async (dispatch: Dispatch) => {
  return objNoteApi
    .fetchObjNoteList(objectId)
    .then(list => dispatch(setAdminNoteList(list)));
};
