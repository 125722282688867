import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { UIRadioBtn } from 'components/UI';
import styled from 'styled-components';
import { SelectOption } from 'components/UI/interfaces';
import { FormError } from 'components/UI/form';

interface Props extends FieldRenderProps<boolean | string> {
  options: SelectOption[];
  layout?: 'horizontal' | 'vertical';
}

const FieldRadioButtons = ({ input, options, layout = 'horizontal', meta }: Props) => {
  const { value, onChange } = input;
  const { error, submitError, touched, dirtySinceLastSubmit } = meta;
  const isError = (error && touched) || (submitError && !dirtySinceLastSubmit);

  return (
    <Field>
      <CheckboxList $direction={layout}>
        {options.map((item, i) => (
          <UIRadioBtn
            key={i}
            isChecked={value === item.value}
            label={item.label}
            handleCheck={() => onChange(item.value)}
          />
        ))}
      </CheckboxList>
      {isError && <Error meta={meta} />}
    </Field>
  );
};

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const CheckboxList = styled.div<{ $direction: Props['layout'] }>`
  display: flex;
  width: 100%;
  max-width: 174px;

  ${({ $direction }) =>
    $direction === 'horizontal'
      ? `
      & > div:not(:first-child) {
        margin-left: 34px;
      }
      `
      : `
      flex-direction: column;
      & > div:not(:first-child) {
        margin-top: 34px;
      } 
      `}
`;

const Error = styled(FormError)`
  position: absolute;
`;

export default FieldRadioButtons;
