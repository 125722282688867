import * as api from './api';
import Toast from 'services/Toast';
import * as actions from './actions';
import { Dispatch } from 'redux';

export const fetchContactsJobTitles = () => async (dispatch: Dispatch) => {
  return api
    .fetchContactsJobTitles()
    .then(jobTitles => dispatch(actions.setContactsJobTitles(jobTitles)))
    .catch(() => Toast.error('Error get job titles'));
};

export const fetchSearchJobTitle = (q: string) => async (dispatch: Dispatch) => {
  return api
    .fetchSearchJobTitle(q)
    .then(data => dispatch(actions.setSearchJobTitles(data)))
    .catch(() => Toast.error('Error search jobTitle'));
};
