import React, { useEffect } from 'react';
import styled from 'styled-components';
import { UICheckbox } from 'components/UI';
import { TableSortHeaderCell } from 'components/UI/table';
import { SortStatus } from 'hooks/common/useSortStatus';
import { Charitable } from 'interfaces/issueMapping';
import { goalAlignmentOptions } from 'constants/goalAlignment';
import { moneyNumberFormat } from 'utils/formatters';
import ReactTooltip from 'react-tooltip';

type Row = (Charitable & { id: string })[];
interface Props {
  rows: Row;
  selectedIds: string[];
  onCheckRow: (id: string) => void;
  sortStatus: SortStatus;
  onSort: (colName: string) => void;
}

interface TableRowProps {
  row: Row[0];
  check: boolean;
  onCheckRow: Props['onCheckRow'];
}

const userTableWidth = {
  sdgWdith: 180,
  ProgramFocusAreaWidth: 180,
  nonprofitPartnerWidth: 180,
  costPerOutcomeWidth: 130,
  totalInvestmentWidth: 130,
  potentialOutcomeWidth: 180,
  givingWidth: 70,
} as const;

const {
  sdgWdith,
  ProgramFocusAreaWidth,
  nonprofitPartnerWidth,
  costPerOutcomeWidth,
  totalInvestmentWidth,
  potentialOutcomeWidth,
  givingWidth,
} = userTableWidth;

const TableRowWrapper = ({ row, check, onCheckRow }: TableRowProps) => {
  const { costPerOutcomeAvg, goalAlignment, organizationName, programFocus } = row.team;

  const goalAlignmentJoin = goalAlignment
    ?.map(item => goalAlignmentOptions[item].title)
    .join(', ');

  return (
    <TableRow>
      <TableRowCell $bold>{organizationName}</TableRowCell>
      <TableRowCell>{goalAlignmentJoin}</TableRowCell>
      <TableRowCell>{programFocus}</TableRowCell>
      <TableRowCell>{moneyNumberFormat(costPerOutcomeAvg)}</TableRowCell>
      <TableRowCell>{row.potentialOutcome}</TableRowCell>
      <TableRowCell>{moneyNumberFormat(row.totalInvestment)}</TableRowCell>

      <TableRowCell style={{ width: `${70}px` }}>
        <UICheckbox check={check} handleClick={() => onCheckRow(row.id)} />
      </TableRowCell>
    </TableRow>
  );
};

const CharitablesTable = ({
  rows,
  selectedIds,
  sortStatus,
  onCheckRow,
  onSort,
}: Props) => {
  useEffect(() => {
    ReactTooltip.rebuild();
    return () => {
      ReactTooltip.hide();
    };
  }, []);

  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${nonprofitPartnerWidth}px` }} />
          <col span={1} style={{ width: `${sdgWdith}px` }} />
          <col span={1} style={{ width: `${ProgramFocusAreaWidth}px` }} />
          <col span={1} style={{ width: `${costPerOutcomeWidth}px` }} />
          <col span={1} style={{ width: `${potentialOutcomeWidth}px` }} />
          <col span={1} style={{ width: `${totalInvestmentWidth}px` }} />
          <col span={1} style={{ width: `${givingWidth}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            <TableSortHeaderCell
              colName="organizationName"
              onClick={onSort}
              sortStatus={sortStatus}
            >
              Nonprofit Partner
            </TableSortHeaderCell>
            <TableHeadCell>SDG/Social Issues</TableHeadCell>
            <TableHeadCell>Program Focus Area</TableHeadCell>
            <TableSortHeaderCell
              colName="costPerOutcome"
              onClick={onSort}
              sortStatus={sortStatus}
            >
              Cost Per Outcome
            </TableSortHeaderCell>
            <TableHeadCell>Potential Outcome</TableHeadCell>
            <TableSortHeaderCell
              colName="totalInvestment"
              onClick={onSort}
              sortStatus={sortStatus}
            >
              Total Investment
            </TableSortHeaderCell>

            <TableHeadCell
              key="checkbox"
              style={{ width: `${70}px` }}
              data-tip="Please check the organizations you wish to Give"
            >
              Giving
            </TableHeadCell>
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map((item, index) => (
            <TableRowWrapper
              key={index}
              row={item}
              check={selectedIds.includes(item.id)}
              onCheckRow={onCheckRow}
            />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow: auto;
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 36px;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 12px;
  line-height: 150%;
  position: sticky;
  top: 0;
  color: var(--darkGray);
  vertical-align: middle;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;

const TableRow = styled.tr`
  height: 100%;
  background-color: #fff;
`;

const TableRowCell = styled.td<{ $bold?: boolean }>`
  padding: 14px 12px 14px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  ${({ $bold }) =>
    $bold &&
    `
    font-weight: 500; 
  `}

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }
`;

export default CharitablesTable;
