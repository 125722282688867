import React from 'react';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';
import { ROIMeltwaterData } from 'interfaces/roi';
import styled from 'styled-components';

interface Props {
  data?: ROIMeltwaterData['analyticsData']['top_keyphrases']['keyphrases'];
}

const TrendingThemesMetric = ({ data }: Props) => {
  return (
    <MetricRoot>
      <MetricTitle>Trending Themes</MetricTitle>
      <Content>
        <Text1>{data?.[0]?.keyphrase}</Text1>
        <Text2>{data?.[1]?.keyphrase}</Text2>
        <Text3>{data?.[2]?.keyphrase}</Text3>
        <Text4>{data?.[3]?.keyphrase}</Text4>
        <Text5>{data?.[4]?.keyphrase}</Text5>
        <Text6>{data?.[5]?.keyphrase}</Text6>
        <Text7>{data?.[6]?.keyphrase}</Text7>
      </Content>
    </MetricRoot>
  );
};

const Content = styled(MetricContent)`
  position: relative;
`;

const Text = styled.div`
  color: #2baee0;
  position: absolute;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

const Text1 = styled(Text)`
  top: 90px;
  left: 152px;
  font-size: 36px;
  width: 192px;
`;
const Text2 = styled(Text)`
  top: 60px;
  left: 66px;
  font-size: 22px;
  width: 212px;
`;
const Text3 = styled(Text)`
  top: 6px;
  left: 150px;
  font-size: 14px;
  width: 196px;
`;
const Text4 = styled(Text)`
  top: 146px;
  left: 16px;
  font-size: 12px;
  width: 134px;
`;
const Text5 = styled(Text)`
  top: 39px;
  left: 172px;
  font-size: 12px;
  width: 174px;
`;
const Text6 = styled(Text)`
  top: 110px;
  left: 21px;
  font-size: 12px;
  width: 130px;
`;
const Text7 = styled(Text)`
  top: 20px;
  left: 30px;
  font-size: 12px;
  width: 118px;
`;

export default TrendingThemesMetric;
