/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import OrganizationWrapper from 'layouts/OrganizationWrapper';
import { useSelector } from 'react-redux';
import { useOperation } from 'hooks/common';
import { getTeam } from 'store/ducks/team/selectors';
import { fetchTeam } from 'store/ducks/team/operations';
import { UISpinner } from 'components/UI';
import styled from 'styled-components';
import ProfileView from 'components/ProfileView';

const Profile = () => {
  const [onfetchTeam, isLoading] = useOperation(fetchTeam);

  const team = useSelector(getTeam);

  useEffect(() => {
    onfetchTeam(undefined);
  }, [getTeam]);

  if (!team) return null;
  return (
    <OrganizationWrapper>
      {isLoading ? <Spinner /> : <ProfileView team={team} news={[]} />}
    </OrganizationWrapper>
  );
};

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 242px);
`;

export default Profile;
