/* eslint-disable react-hooks/exhaustive-deps */
import { ExportButton } from 'components/ActionButtons';
import Jumbotron from 'components/Jumbotron';
import { useFileBase64Url, useOperation } from 'hooks/common';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { ReactSVG } from 'react-svg';
import {
  fetchROIMeltwaterData,
  fetchROIOld,
  fetchROIReportSavePDF,
} from 'store/ducks/roi/operations';
import { getROIMeltwaterData, getROIOld } from 'store/ducks/roi/selectors';
import styled from 'styled-components';
import PartnershipGoalsSVG from 'assets/images/roiReport/partnershipGoals.svg';
import SucessCircleSVG from 'assets/images/icons/success-circle.svg';
import ValuationSVG from 'assets/images/valuationReport/valuation.svg';
import EngagementSVG from 'assets/images/roiReport/engagement.svg';
import StewardshipActivationSVG from 'assets/images/roiReport/stewardshipActivation.svg';
import DownloadSVG from 'assets/images/icons/download.svg';
import { moneyFormatter } from 'utils/formatters';
import { ProgressBar, UIButton, UIDivider } from 'components/UI';
import StewardshipTable from 'components/roi/tables/StewardshipTable';
import PartnerActivationTable from 'components/roi/tables/PartnerActivationTable';
import html2pdf from 'html2pdf.js/dist/html2pdf';
import { fetchSignedUrl, uploadFile } from 'store/ducks/utility/api';
import Toast from 'services/Toast';
import { DashBoard } from 'components/roi/dashboard';
import Dropdown from 'components/Dropdown/Dropdown';
import useDownloadFile from 'hooks/common/useDownloadFile';
import { fetchGenerateexcel } from 'store/ducks/roi/api';

const ROIReport = () => {
  const params = useParams<{ roiId: string }>();
  const { go } = useHistory();

  const [imgKey, setImgKey] = useState('');
  const [logoKey, setLogoKey] = useState('');
  const [isExportLoading, setIsExportLoading] = useState(false);

  const { fileBase64Url } = useFileBase64Url({ imgKey });
  const { fileBase64Url: logoBase64Url } = useFileBase64Url({ imgKey: logoKey });

  const [onFetchROI] = useOperation(fetchROIOld);
  const [onFetchROIReportSavePDF] = useOperation(fetchROIReportSavePDF);
  const [onFetchROIMeltwaterData] = useOperation(fetchROIMeltwaterData);

  const roi = useSelector(getROIOld);
  const meltwaterData = useSelector(getROIMeltwaterData);

  const exportExcel = useDownloadFile(fetchGenerateexcel);

  const pdfFileUrl = useMemo(() => {
    if (!roi?.file) {
      return;
    }
    return fetchSignedUrl(roi.file).then(value => value.result);
  }, [roi?.file]);

  const exportAndUploadPdf = useCallback(() => {
    if (pdfFileUrl) {
      pdfFileUrl.then(url => window.open(url));
      return;
    }
    setIsExportLoading(true);
    const element = document.getElementById('generate-pdf-content');
    const opt = {
      margin: [-5, -5, 0, 0],
      filename: `${roi?.name} Report.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      pagebreak: {
        mode: ['avoid-all'],
      },
      html2canvas: {
        dpi: 192,
        scale: 2,
        onrendered: function() {},
        letterRendering: true,
        useCORS: true,
        onclone: element => {
          const svgElements: SVGGraphicsElement[] = Array.from(
            element.querySelectorAll('.heat-map-chart svg'),
          );
          svgElements.forEach(s => {
            const bBox = s.getBBox();
            s.setAttribute('x', String(bBox.x));
            s.setAttribute('y', String(bBox.y));
            s.setAttribute('width', String(bBox.width));
            s.setAttribute('height', String(bBox.height));
          });
        },
      },
      jsPDF: {
        unit: 'pt',
        format: [963, Number(element?.clientHeight) / 1.32 + 70],
        orientation: 'p',
      },
    };
    if (element) {
      const worker = html2pdf();
      const xx = worker
        .set(opt)
        .from(element)
        .output('blob');
      xx.then((x: Blob) => {
        const file = new File([x], `${roi?.name} Report.pdf`);
        uploadFile(file, 'roi-report').then(v => {
          if (v.status === 'success') {
            onFetchROIReportSavePDF(v.fileKey).then(() => {
              setIsExportLoading(false);
              worker
                .set(opt)
                .from(element)
                .save();
            });
            return;
          }
          setIsExportLoading(false);
          Toast.error('Error export PDF');
        });
      });
    }
  }, [params, roi]);

  const maxNonMediaItemsImpression = useMemo(() => {
    const maxValue =
      roi?.oldCalculation?.nonMediaItems &&
      [...roi?.oldCalculation?.nonMediaItems].sort(
        (a, b) => b.impression - a.impression,
      )[0].impression;

    return maxValue || 0;
  }, [roi]);

  const maxNonMediaItemsValue = useMemo(() => {
    const maxValue =
      roi?.oldCalculation?.nonMediaItems &&
      [...roi?.oldCalculation?.nonMediaItems].sort((a, b) => b.value - a.value)[0].value;

    return maxValue || 0;
  }, [roi]);

  const isShowSteward =
    !!roi?.stewardship?.DidYourPartnerSupportYouWithAnyOfTheFollowingOpportunities ||
    (!!roi?.stewardship?.stewardshipitems && !!roi?.stewardship?.stewardshipitems.length);

  const isShowAdditionalCustomStewardshipActivation = (() => {
    const template = roi?.templates?.find(
      item => item.StewardshipName === 'Additional Custom Stewardship Activation',
    );

    if (!template) return false;

    return !template.disabled;
  })();

  const isShowPartnerActivation =
    !!roi?.partnerActivation?.accessToAgencies ||
    !!roi?.partnerActivation?.engagementFromTheirAmbassadors ||
    !!roi?.partnerActivation?.paidMedia?.isPaidMedia ||
    !!roi?.partnerActivation?.ownedMedia?.isOwnedMedia ||
    !!roi?.paidMedia?.isPaidMedia ||
    !!roi?.ownedMedia?.isOwnedMedia;

  useEffect(() => {
    onFetchROI(params.roiId);
    onFetchROIMeltwaterData(params.roiId);
  }, []);

  useEffect(() => {
    if (!roi) return;
    setImgKey(roi.customizeReport?.imageKey || '');
    setLogoKey(roi.customizeReport?.logoKey || '');
  }, [roi]);

  return (
    <ContentBlockWrapper
      header={
        <Jumbotron
          name={
            <JumbotronTitleWrapper>
              <JumbotronBlock>
                <LogoImg src={logoBase64Url} />
              </JumbotronBlock>
              {roi?.name} Report
            </JumbotronTitleWrapper>
          }
        >
          <ExportWrapper>
            <ReactSVG src={DownloadSVG} />
            <Dropdown title="Export to PDF" style={{ marginLeft: '8px' }}>
              <DropdownBody>
                <ExportButton
                  name="Export to PDF"
                  isloading={isExportLoading}
                  uploadHandler={exportAndUploadPdf}
                />
                <ExportButton
                  name="Export to Excel"
                  uploadHandler={() => exportExcel(roi?.id || '')}
                />
              </DropdownBody>
            </Dropdown>
          </ExportWrapper>
        </Jumbotron>
      }
    >
      <Body>
        <ExportPDFWrapper id="generate-pdf-content">
          <InnerJumbotron
            id="pdf-content-header"
            name={
              <JumbotronTitleWrapper>
                <JumbotronBlock>
                  <LogoImg src={logoBase64Url} />
                </JumbotronBlock>
                {roi?.name} Report
              </JumbotronTitleWrapper>
            }
          />
          {fileBase64Url && <HeadImg src={fileBase64Url} />}
          {meltwaterData && <DashBoard meltwaterData={meltwaterData} />}
          <InfoCard>
            <InfoImage src={PartnershipGoalsSVG} />
            <InfoDetail>
              <DetailRow>
                <InfoTitle>Partnership Goals</InfoTitle>
              </DetailRow>
              <DetailRow>
                <GoalsGroup>
                  {roi?.oldCalculation?.partnershipGoalsInfo.partnershipGoals.map(
                    item => (
                      <GoalsItem key={item.PartnershipGoal}>
                        <ReactSVG src={SucessCircleSVG} />
                        {item.Text}
                      </GoalsItem>
                    ),
                  )}
                </GoalsGroup>
              </DetailRow>
            </InfoDetail>
          </InfoCard>

          <ChartCard>
            <ChartCardTitle>Mission Impact</ChartCardTitle>
            {roi?.oldCalculation?.missionImpact?.map((item, index) => (
              <ChartCardRow isBottomBorder key={index}>
                {item}
              </ChartCardRow>
            ))}
          </ChartCard>

          <InfoCard type="white">
            <InfoImage src={ValuationSVG} />
            <InfoDetail style={{ color: 'black' }}>
              <DetailRow>
                <InfoTitle>Total Funds Raised</InfoTitle>
                <DetailValue>
                  {moneyFormatter.from(Number(roi?.oldCalculation?.totalFundsRaised), {
                    symbol: '$',
                  })}
                </DetailValue>
              </DetailRow>
              <UIDivider type="horizontal" />
              <DetailRow>
                <InfoTitle>Total Media Value</InfoTitle>
                <DetailValue>
                  {moneyFormatter.from(Number(roi?.oldCalculation?.totalMediaValue), {
                    symbol: '$',
                  })}
                </DetailValue>
              </DetailRow>
            </InfoDetail>
          </InfoCard>

          <InfoCard type="dark">
            <InfoImage src={EngagementSVG} />
            <InfoDetail>
              <DetailRow>
                <InfoTitle>Engagement</InfoTitle>
              </DetailRow>
              <DetailRow>
                <DetailTitle>Executive Champions</DetailTitle>
                <DetailValue>
                  {roi?.ambassadors?.howInvolvedWereExecutiveChampionsInThisPartnership}
                </DetailValue>
              </DetailRow>
              <UIDivider type="horizontal" />
              <DetailRow>
                <DetailTitle>Celebrity Sentiment </DetailTitle>
                <DetailValue>
                  {roi?.ambassadors?.howInvolvedWereCelebritiesInThisPartnership}
                </DetailValue>
              </DetailRow>
              <UIDivider type="horizontal" />
              <DetailRow>
                <DetailTitle>Employee Engagement</DetailTitle>
                <DetailValue>
                  {roi?.ambassadors?.howInvolvedWereEmployeesInThisPartnership}
                </DetailValue>
              </DetailRow>
              <UIDivider type="horizontal" />
              <DetailRow>
                <DetailTitle>Consumer Sentiment </DetailTitle>
                <DetailValue>
                  {(
                    (roi?.oldCalculation?.ambassadorsEngaged?.consumerSentiment || 0) *
                    100
                  ).toFixed(0)}
                  %
                </DetailValue>
              </DetailRow>
            </InfoDetail>
          </InfoCard>

          {isShowSteward && (
            <>
              <ChartCard>
                <ChartCardTitle>Stewardship</ChartCardTitle>
                <ChartCardRow>
                  <StewardshipTable
                    headCells={['Outputs', 'Status']}
                    rows={roi?.oldCalculation?.stewardshipItems || []}
                  />
                </ChartCardRow>
              </ChartCard>
              {isShowAdditionalCustomStewardshipActivation && (
                <InfoCard type="gold">
                  <InfoImage src={StewardshipActivationSVG} />
                  <InfoDetail>
                    <DetailRow>
                      <InfoTitle>Additional Custom Stewardship Activation</InfoTitle>
                    </DetailRow>
                    <DetailRow>
                      <DetailDesc
                        dangerouslySetInnerHTML={{
                          __html:
                            roi?.templates?.find(
                              item =>
                                item.StewardshipName ===
                                'Additional Custom Stewardship Activation',
                            )?.body || '',
                        }}
                      />
                    </DetailRow>
                  </InfoDetail>
                </InfoCard>
              )}
            </>
          )}
          {isShowPartnerActivation && (
            <ChartCard>
              <ChartCardTitle>Partner Activation </ChartCardTitle>
              <ChartCardRow>
                <PartnerActivationTable
                  headCells={['Asset', 'Total Impressions', 'Total Value', 'Contributor']}
                  rows={roi?.oldCalculation?.partnerActivationItems || []}
                />
              </ChartCardRow>
            </ChartCard>
          )}

          <ChartCard>
            <ChartCardTitle>Impressions</ChartCardTitle>
            {roi?.oldCalculation?.nonMediaItems.map(item => (
              <ChartCardRow key={item.type}>
                <ChartCardLabel short bold>
                  {item.type}
                </ChartCardLabel>
                <ChartCardValue>
                  <ProgressBarGroup>
                    <NormalImpressionsProgressBar
                      value={(item.impression / maxNonMediaItemsImpression) * 100}
                      text={String(moneyFormatter.from(item.impression, { symbol: '' }))}
                    />
                  </ProgressBarGroup>
                </ChartCardValue>
              </ChartCardRow>
            ))}
          </ChartCard>
          <ChartCard>
            <ChartCardTitle>Value</ChartCardTitle>
            {roi?.oldCalculation?.nonMediaItems.map(item => (
              <ChartCardRow key={item.type}>
                <ChartCardLabel short bold>
                  {item.type}
                </ChartCardLabel>
                <ChartCardValue>
                  <ProgressBarGroup>
                    <NormalValueProgressBar
                      value={(item.value / maxNonMediaItemsValue) * 100}
                      text={String(moneyFormatter.from(item.value, { symbol: '$' }))}
                    />
                  </ProgressBarGroup>
                </ChartCardValue>
              </ChartCardRow>
            ))}
          </ChartCard>

          <ChartCard>
            <ChartCardTitle>Benchmarking</ChartCardTitle>
            <ChartCardRow>
              <BigCapsule>
                <BigCapsuleTitle>Overall Partnership Value</BigCapsuleTitle>
                <BigCapsuleContent>
                  <BigCapsuleItem>
                    <BigCapsuleItemValue>
                      {moneyFormatter.from(
                        Number(
                          roi?.oldCalculation?.intangiblePartnershipBenchmark
                            .overallPartnershipValuetoNonprofit,
                        ),
                        {
                          symbol: '$',
                        },
                      )}
                    </BigCapsuleItemValue>
                    <BigCapsuleItemLabel>To Nonprofit </BigCapsuleItemLabel>
                  </BigCapsuleItem>
                  <BigCapsuleItem>
                    <BigCapsuleItemValue>
                      {moneyFormatter.from(
                        Number(
                          roi?.oldCalculation?.intangiblePartnershipBenchmark
                            .overallPartnershipValuetoCompany,
                        ),
                        {
                          symbol: '$',
                        },
                      )}
                    </BigCapsuleItemValue>
                    <BigCapsuleItemLabel>To Company </BigCapsuleItemLabel>
                  </BigCapsuleItem>
                </BigCapsuleContent>
              </BigCapsule>
            </ChartCardRow>
            <ChartCardRow>
              <BigCapsule type="dark">
                <BigCapsuleTitle>Return on Partnership</BigCapsuleTitle>
                <BigCapsuleContent>
                  <BigCapsuleItem>
                    <BigCapsuleItemValue>
                      {(
                        roi?.oldCalculation?.intangiblePartnershipBenchmark &&
                        roi?.oldCalculation?.intangiblePartnershipBenchmark
                          .returnOnPartnershiptoNonprofit * 100
                      )?.toFixed(0)}
                      %
                    </BigCapsuleItemValue>
                    <BigCapsuleItemLabel>To Nonprofit </BigCapsuleItemLabel>
                  </BigCapsuleItem>
                  <BigCapsuleItem>
                    <BigCapsuleItemValue>
                      {(
                        roi?.oldCalculation?.intangiblePartnershipBenchmark &&
                        roi?.oldCalculation?.intangiblePartnershipBenchmark
                          .returnOnPartnershiptoCompany * 100
                      )?.toFixed(0)}
                      %
                    </BigCapsuleItemValue>
                    <BigCapsuleItemLabel>To Company </BigCapsuleItemLabel>
                  </BigCapsuleItem>
                </BigCapsuleContent>
                <BigCapsuleContent>
                  <BigCapsuleDesc>
                    {
                      roi?.oldCalculation?.intangiblePartnershipBenchmark
                        .revealThisPrescriptionCompany
                    }
                  </BigCapsuleDesc>
                </BigCapsuleContent>
              </BigCapsule>
            </ChartCardRow>
            {roi?.oldCalculation?.intangiblePartnershipBenchmark.items.map(item => (
              <ChartCardRow key={item.type}>
                <ChartCardLabel short bold>
                  {item.type}
                </ChartCardLabel>
                <ChartCardValue>
                  <ProgressBarGroup>
                    <NormalBenchmarkProgressBar
                      value={25}
                      text={`${moneyFormatter.from(Number(item.benchmark), {
                        symbol: item.isDollar ? '$' : '',
                      })}`}
                    />
                    {item.benchmark < item.value ? (
                      <NormalValueProgressBar
                        value={
                          (item.value / item.benchmark / 4) * 100 > 100
                            ? 100
                            : (item.value / item.benchmark / 4) * 100
                        }
                        text={`${moneyFormatter.from(Number(item.value), {
                          symbol: item.isDollar ? '$' : '',
                        })}`}
                      />
                    ) : item.benchmark === item.value ? (
                      <NormalYourAssetProgressBar
                        value={(item.value / item.benchmark / 4) * 100}
                        text={`${moneyFormatter.from(Number(item.value), {
                          symbol: item.isDollar ? '$' : '',
                        })}`}
                      />
                    ) : (
                      <>
                        <NormalImpressionsProgressBar
                          value={(item.value / item.benchmark / 4) * 100}
                          text={`${moneyFormatter.from(Number(item.value), {
                            symbol: item.isDollar ? '$' : '',
                          })}`}
                        />
                        <ChartCardDesc>{item.prescriptiveAnalytics}</ChartCardDesc>
                      </>
                    )}
                  </ProgressBarGroup>
                </ChartCardValue>
              </ChartCardRow>
            ))}
            <ChartCardRow>
              <LegendList>
                <Legend>
                  <LegendColor color="#EEC459" />
                  <LegendText>Under Benchmark</LegendText>
                </Legend>
                <Legend>
                  <LegendColor color="#2BAEE0" />
                  <LegendText>On Benchmark</LegendText>
                </Legend>
                <Legend>
                  <LegendColor color="#28759C" />
                  <LegendText>Beyond Benchmark</LegendText>
                </Legend>
                <Legend>
                  <LegendColor color="#B3B3B3" />
                  <LegendText>Benchmark</LegendText>
                </Legend>
              </LegendList>
            </ChartCardRow>
          </ChartCard>
        </ExportPDFWrapper>
        <UIButton
          title="Back"
          modifiers="secondary"
          handleClick={() => {
            go(-1);
            // push(
            //   `/roi/edit/${roi?.id}?type=${ROIEditNavigationEnum.Measure}&secondaryType=${ROIEditMeasureNavigationEnum.Impact_Report}`,
            // );
            window.scrollTo(0, 0);
          }}
        />
      </Body>
    </ContentBlockWrapper>
  );
};

const Body = styled.div`
  & #pdf-content-header {
    display: none;
  }
`;

const ExportWrapper = styled.div`
  margin-left: auto;
  display: flex;
`;

const JumbotronTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const JumbotronBlock = styled.div`
  width: 83px;
  height: 83px;
  background-color: var(--secondaryBlue);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 27px;
`;

const HeadImg = styled.img`
  width: 100%;
  height: 522px;
  border-radius: 6px;
  margin-bottom: 24px;
`;

const LogoImg = styled.img`
  border-radius: 6px;
`;

const InfoCard = styled.section<{ type?: 'light' | 'dark' | 'white' | 'gold' }>`
  border-radius: 6px;
  padding: 48px 24px;
  display: flex;
  margin-bottom: 16px;
  width: 100%;

  background: ${({ type = 'light' }) => {
    switch (type) {
      case 'light':
        return 'linear-gradient(90deg, #2baee0 0%, #178fb5 100%)';
      case 'dark':
        return 'linear-gradient(90deg, #447E94 0%, #114D64 100%)';
      case 'gold':
        return 'linear-gradient(90deg, #F08D1B -0.79%, #BA6A31 100%);';
      case 'white':
        return '#fff';
    }
  }};
`;

const InfoImage = styled(ReactSVG)`
  width: 388px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoDetail = styled.div`
  flex: 1;
  color: #fff;
`;

const InfoTitle = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 145%;
  width: 322px;
  white-space: nowrap;
`;

const DetailRow = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const DetailTitle = styled.div`
  display: flex;
  align-items: center;
  width: 546px;
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
`;

const DetailValue = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
`;

const DetailDesc = styled.p`
  font-size: 16px;
  line-height: 148%;
`;

const GoalsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const GoalsItem = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 56px 0 0;
  white-space: nowrap;

  & svg {
    margin-right: 14px;
  }
`;

const ChartCard = styled.section`
  background: #fff;
  margin-bottom: 16px;
  border-radius: 6px;

  & > div:first-child {
    border-bottom: 1px solid #e8e8e8;
  }
`;

const ChartCardTitle = styled.div`
  display: flex;
  padding: 32px 24px;
  font-weight: 500;
  font-size: 22px;
`;

const ChartCardRow = styled.div<{ isBottomBorder?: boolean }>`
  line-height: 150%;
  display: flex;
  padding: 15px 24px;
  white-space: pre-wrap;
  ${({ isBottomBorder }) => isBottomBorder && 'border-bottom: 1px solid #e8e8e8'}
`;

const ChartCardLabel = styled.div<{ bold?: boolean; short?: boolean }>`
  width: ${({ short }) => (short ? 'calc(242px - 24px)' : 'calc(417px - 24px)')};
  display: flex;

  ${({ bold }) => bold && 'font-weight: 500;'}
`;

const ChartCardValue = styled.div`
  flex: 1;
`;

const ChartCardDesc = styled(DetailDesc)`
  color: var(--black);
  margin-top: 16px;
`;

const ProgressBarGroup = styled.div`
  display: flex;
  flex-direction: column;

  & > div:not(:first-child) {
    margin-top: 4px;
  }
`;

const NormalBenchmarkProgressBar = ({ value, text }: { value: number; text: string }) => (
  <ProgressBar value={value} text={text} color="#B3B3B3" />
);

const NormalYourAssetProgressBar = ({ value, text }: { value: number; text: string }) => (
  <ProgressBar value={value} text={text} color="#2BAEE0" />
);

const NormalImpressionsProgressBar = ({
  value,
  text,
}: {
  value: number;
  text: string;
}) => <ProgressBar value={value} text={text} color="#EEC459" />;

const NormalValueProgressBar = ({ value, text }: { value: number; text: string }) => (
  <ProgressBar value={value} text={text} color="#28759C" />
);

const LegendList = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;

  & > div {
    margin-right: 8px;
  }
`;

const LegendColor = styled.div<{ color: string }>`
  width: 44px;
  height: 20px;
  border-radius: 99em;
  background-color: ${({ color }) => color};
`;

const LegendText = styled.div``;

const BigCapsule = styled(InfoCard)`
  width: 100%;
  margin: 0 auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 26px;
  padding: 48px 40px;
`;

const BigCapsuleTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 145%;
  margin-bottom: 45px;
`;
const BigCapsuleContent = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: 180px;
  }
`;
const BigCapsuleDesc = styled.div`
  font-size: 16px;
  line-height: 148%;
  margin-top: 38px;
`;
const BigCapsuleItem = styled.div`
  margin: 0 16px;
  display: flex;
  align-items: center;
`;
const BigCapsuleItemValue = styled.div`
  font-weight: bold;
  font-size: 32px;
  line-height: 155%;
`;
const BigCapsuleItemLabel = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  margin-left: 16px;
`;

const InnerJumbotron = styled(Jumbotron)`
  margin-bottom: 24px;
  border-radius: 6px;
`;

const ExportPDFWrapper = styled.div``;

const DropdownBody = styled.div`
  width: 190px;
  padding: 12px;
`;

export default ROIReport;
