export enum HTTPRequestStatusEnum {
  CANCELED = 'HTTP_REQUEST_STATUS_CANCELED',
  RE_REQUEST = 'HTTP_REQUEST_STATUS_RE_REQUEST',
}

export const PAGE_WIDTH = {
  LARGE: 1320,
  NORMAL: 1096,
} as const;

export const THEME_COLORS = {
  Accelerist_theme: {
    title: 'Accelerist theme',
    value: ['#2BAEE0', '#18536A', '#EEC459', '#CEEDF9'],
  },
  Custom_colors: { title: 'Custom colors', value: [] },
  Theme_01: {
    title: 'Theme 01',
    value: ['#364F6B', '#3FC1C9', '#F5F5F5', '#FC5185'],
  },
  Theme_02: {
    title: 'Theme 02',
    value: ['#F9A828', '#ECECEB', '#07617D', '#2E383F'],
  },
  Theme_03: {
    title: 'Theme 03',
    value: ['#AFFFFF', '#74DBEF', '#5E88FC', '#264E86'],
  },
  Theme_04: {
    title: 'Theme 04',
    value: ['#3498DB', '#ECF0F1', '#34495E', '#F1C40F'],
  },
  Theme_05: {
    title: 'Theme 05',
    value: ['#FA4659', '#FEFFE4', '#A3DE83', '#2EB872'],
  },
  Theme_06: {
    title: 'Theme 06',
    value: ['#364F6B', '#3FC1C9', '#FCE38A', '#FC5185'],
  },
  Theme_07: {
    title: 'Theme 07',
    value: ['#E4F9F5', '#30E3CA', '#11999E', '#40514E'],
  },
  Theme_08: {
    title: 'Theme 08',
    value: ['#384259', '#F73859', '#7AC7C4', '#C4EDDE'],
  },
  Theme_09: {
    title: 'Theme 09',
    value: ['#118DF0', '#0E2F56', '#FF304F', '#ECECDA'],
  },
  Theme_10: {
    title: 'Theme 10',
    value: ['#EB586F', '#D8E9F0', '#4AA0D5', '#454553'],
  },
};

export const Campaigns_COLORS = {
  Accelerist_theme: {
    title: 'Accelerist theme',
    value: ['#2BAEE0', '#18536A', '#EEC459', '#CEEDF9'],
  },
  Custom_colors: { title: 'Custom colors', value: [] },
  Theme_01: {
    title: 'Theme 01',
    value: ['#00838F', '#005662', '#F57C00', '#FFAD42'],
  },
  Theme_02: {
    title: 'Theme 02',
    value: ['#00796B', '#004C40', '#4F9A94', '#80CBC4'],
  },
  Theme_03: {
    title: 'Theme 03',
    value: ['#4527A0', '#000070', '#6200EA', '#A65DF9'],
  },
  Theme_04: {
    title: 'Theme 04',
    value: ['#1565C0', '#003C8F', '#1976D2', '#63A4FF'],
  },
  Theme_05: {
    title: 'Theme 05',
    value: ['#F26A8D', '#DD2D4A', '#880D1E', '#F49CBB'],
  },
};
