import MicrosoftImage from 'assets/images/icons/microsoft.png';
import { AgeRanges, Income } from 'interfaces/search';
import { transformArrayForOptions, transformEnumForOptions } from 'utils';

export const mockSearch = {
  industries: [
    {
      id: '1',
      name: 'Microsoft',
      icon: MicrosoftImage,
      rank: 64,
      address: '8502 Preston Rd. Inglewood, Maine 98380',
      phone: '(704) 555-0127',
      csrs: ['Health', 'Animals', 'Education'],
      revenue: '$ 4,434,476',
      like: false,
    },
    {
      id: '2',
      name: 'Test',
      icon: MicrosoftImage,
      rank: 64,
      address: '8502 Preston Rd. Inglewood, Maine 98380',
      phone: '(704) 555-0127',
      csrs: ['Health', 'Animals', 'Education'],
      revenue: '$ 4,434,476',
      like: true,
    },
    {
      id: '3223',
      name: 'Test1',
      icon: MicrosoftImage,
      rank: 64,
      address: '8502 Preston Rd. Inglewood, Maine 98380',
      phone: '(704) 555-0127',
      csrs: ['Health', 'Animals', 'Education'],
      revenue: '$ 4,434,476',
      like: false,
    },
    {
      id: '3',
      name: 'Test3',
      icon: MicrosoftImage,
      rank: 64,
      address: '8502 Preston Rd. Inglewood, Maine 98380',
      phone: '(704) 555-0127',
      csrs: ['Health', 'Animals', 'Education'],
      revenue: '$ 4,434,476',
      like: false,
    },
    {
      id: '4',
      name: 'Test4',
      icon: MicrosoftImage,
      rank: 64,
      address: '8502 Preston Rd. Inglewood, Maine 98380',
      phone: '(704) 555-0127',
      csrs: ['Health', 'Animals', 'Education'],
      revenue: '$ 4,434,476',
      like: false,
    },
    {
      id: '5',
      name: 'Test5',
      icon: MicrosoftImage,
      rank: 64,
      address: '8502 Preston Rd. Inglewood, Maine 98380',
      phone: '(704) 555-0127',
      csrs: ['Health', 'Animals', 'Education'],
      revenue: '$ 4,434,476',
      like: false,
    },
    {
      id: '6',
      name: 'Test6',
      icon: MicrosoftImage,
      rank: 64,
      address: '8502 Preston Rd. Inglewood, Maine 98380',
      phone: '(704) 555-0127',
      csrs: ['Health', 'Animals', 'Education'],
      revenue: '$ 4,434,476',
      like: false,
    },
    {
      id: '7',
      name: 'Test7',
      icon: MicrosoftImage,
      rank: 64,
      address: '8502 Preston Rd. Inglewood, Maine 98380',
      phone: '(704) 555-0127',
      csrs: ['Health', 'Animals', 'Education'],
      revenue: '$ 4,434,476',
      like: false,
    },
    {
      id: '8',
      name: 'Test8',
      icon: MicrosoftImage,
      rank: 64,
      address: '8502 Preston Rd. Inglewood, Maine 98380',
      phone: '(704) 555-0127',
      csrs: ['Health', 'Animals', 'Education'],
      revenue: '$ 4,434,476',
      like: false,
    },
  ],
  paginateInfo: {
    total: 32,
    page: 1,
    pageLimit: 15,
  },
};

export const mockProspects = {
  prospects: [
    {
      id: '1',
      name: 'Race for the Cure',
      filters: ['Travel Industry', '$500-$1B', 'National'],
      available: 230,
      pursued: 72,
    },
    {
      id: '2',
      name: 'Race for the Cure',
      filters: ['Travel Industry', '$500-$1B', 'National'],
      available: 230,
      pursued: 72,
    },
    {
      id: '3',
      name: 'Race for the Cure',
      filters: ['Travel Industry', '$500-$1B', 'National'],
      available: 230,
      pursued: 72,
    },
    {
      id: '4',
      name: 'Race for the Cure',
      filters: ['Travel Industry', '$500-$1B', 'National'],
      available: 230,
      pursued: 72,
    },
    {
      id: '5',
      name: 'Race for the Cure',
      filters: ['Travel Industry', '$500-$1B', 'National'],
      available: 230,
      pursued: 72,
    },
    {
      id: '6',
      name: 'Race for the Cure',
      filters: ['Travel Industry', '$500-$1B', 'National'],
      available: 230,
      pursued: 72,
    },
    {
      id: '7',
      name: 'Race for the Cure',
      filters: ['Travel Industry', '$500-$1B', 'National'],
      available: 230,
      pursued: 72,
    },
    {
      id: '8',
      name: 'Race for the Cure',
      filters: ['Travel Industry', '$500-$1B', 'National'],
      available: 230,
      pursued: 72,
    },
  ],
  paginateInfo: {
    total: 32,
    page: 1,
    pageLimit: 15,
  },
};

export const filterSectionTexts = {
  priority: {
    title: 'Priority',
    description: `Customize how important each of the following indicators are to your
    organization, and generate your own custom lead score. On a scale 1 (
    least important) - 10 (most important), rank each attribute bellow`,
  },
  company: {
    title: 'Company',
    isExtend: true,
  },
  conatcts: {
    title: 'Contacts',
  },
  customer: {
    title: 'Customer Demographics',
  },
};

export const filterTeamsSectionTexts = {
  ...filterSectionTexts,
  company: {
    title: 'Organization',
  },
};

export const variantFilterOptions = [
  {
    label: 'Advanced',
    value: 'Advanced',
  },
  {
    label: 'Customize',
    value: 'Customize',
  },
];

export const genderOptions = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Both',
    value: 'both',
  },
];

export const relationsOptions = [
  {
    label: 'Single',
    value: 'Single',
  },
  {
    label: 'Married',
    value: 'Married',
  },
];

export const industries = [
  'Agriculture',
  'Entertainment and Recreation',
  'Bars and Food Services',
];

export const industriesOptions = transformArrayForOptions(industries);

export const states: any = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const affinities = ['democrat', 'independent', 'republican'];
export const statesOptions = transformArrayForOptions(Object.values(states));
export const affinitiesOptions = transformArrayForOptions(affinities);

export const cdrFocus = ['Automobile', 'Human Services', 'Food Services', 'Sports'];
export const cdrFocusOptions = transformArrayForOptions(cdrFocus);

export const sortTabs = [
  {
    label: 'Alphabet',
    value: 'alphabet',
  },
  {
    label: 'Prospects Available',
    value: 'available',
  },
  {
    label: 'Last Activity',
    value: 'last-activity',
  },
];

export const ethnicity = [
  'african american',
  'asian',
  'hispanic',
  'middle eastern',
  'native american',
  'white',
];
export const incomeOptions = transformEnumForOptions(Income);
export const ageRangesOptions = transformEnumForOptions(AgeRanges);
export const ethnicityOptions = transformArrayForOptions(ethnicity, ' ');
export const revenueRangeOptions = [
  { label: 'Under $1 Million', value: '1' },
  { label: '$1 Million - $10 Million', value: '2' },
  { label: '$10 Million - $50 Million', value: '3' },
  { label: '$50 Million - $100 Million', value: '4' },
  { label: '$100 Million - $200 Million', value: '5' },
  { label: '$200 Million - $1 Billion', value: '6' },
  { label: 'Over $1 Billion', value: '7' },
];
export const fundraisingContributionOptions = [
  { label: 'Under $5,000', value: '1' },
  { label: '$5,000 - $25,000', value: '2' },
  { label: '$25,000 - $50,000', value: '3' },
  { label: '$50,000 - $75,000', value: '4' },
  { label: '$75,000 - $150,000', value: '5' },
  { label: '$150-000 - $250,000', value: '6' },
  { label: '$250,000 - $1 Million', value: '7' },
  { label: '$1 Million - $10 Million', value: '8' },
  { label: '$10 Million - $50 Million', value: '9' },
  { label: '$50 Million - $100 Million', value: '10' },
  { label: '$100 Million - $200 Million', value: '11' },
  { label: '$200 Million - $1 Billion', value: '12' },
  { label: 'Over $1 Billion', value: '13' },
];
