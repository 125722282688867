import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  /** 1..100 */
  value: number;
  color?: string;
  text?: ReactNode;
  className?: string;
  barHeight?: number;
}

const ProgressBar: FC<Props> = ({
  className,
  color,
  value,
  barHeight = 20,
  text = '',
}) => {
  return (
    <Root color={color} className={className}>
      <BarWrapper barHeight={barHeight}>
        <Bar value={value} />
        <BarText>{text}</BarText>
      </BarWrapper>
    </Root>
  );
};

const Root = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ color }) => {
    if (color) return `--color: ${color};`;

    return `--color: #28759c`;
  }}
`;

const Bar = styled.div<{ value: number }>`
  width: ${({ value }) => `${value}%`};
  border-radius: 99em;
  height: 100%;

  background-color: var(--color);
  display: flex;
  align-items: center;
  left: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 145%;
  color: var(--black);
`;

const BarText = styled.div`
  margin-left: 12px;
`;

const BarWrapper = styled.div<{ barHeight: Props['barHeight'] }>`
  background-color: transparent;

  height: ${({ barHeight }) => `${barHeight}px`};

  width: 100%;
  border-radius: 99em;
  display: flex;
  align-items: center;
`;

export default ProgressBar;
