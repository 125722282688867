import CampaignList from 'components/Campaign/CampaignList';
import Jumbotron from 'components/Jumbotron';
import NavigationBar from 'components/NavigationBar';
import { useIsCompanyUser, useModal, useOperation, useSortStatus } from 'hooks/common';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import queryString from 'query-string';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { fetchCampaignList, fetchEinInfo } from 'store/ducks/campaign/operations';
import { getCampaignList } from 'store/ducks/campaign/selectors';
import styled from 'styled-components';
import { CampaignData, CampaignListReq, headTable } from 'interfaces/campaign';
import CampaignSearch from 'components/Campaign/CampaignSearch';
import PartnersSearch from 'components/Fundraising/PartnersSearch';
import PartnersList from 'components/Fundraising/PartnersList';
import { PartnershipsListReq, PartnershipsListRes } from 'interfaces/fundraising';
import {
  fetchParListByCompanies,
  fetchPartnershipsList,
} from 'store/ducks/fundraising/operations';
import { selectUser } from 'store/ducks/user/selectors';
import { ROISurveyListReq } from 'interfaces/roi';
import PreviewContent from 'components/Fundraising/PreviewContent';
import { ReactSVG } from 'react-svg';
import warnError from 'assets/images/icons/warning-error.svg';
import warnSuccess from 'assets/images/icons/warning-success.svg';
import ConfirmEINModal from 'components/Campaign/modals/ConfirmEINModal';
import useJumpToSupport from 'hooks/common/useJumpToSupport';
import { FundraisingReport } from './FundraisingReport';

const Campaign = () => {
  const [onFetchCampaignList] = useOperation(fetchCampaignList);
  const [onFetchParListByCompanies] = useOperation(fetchParListByCompanies);
  const [onFetchPartnershipsList] = useOperation(fetchPartnershipsList);
  const [onGetEinInfo] = useOperation(fetchEinInfo);

  const { items, meta } = useSelector(getCampaignList);
  const { sortStatus, handleSwitch, setSortStatus } = useSortStatus<any>([], {
    isSort: false,
  });

  const [selectedCampaign, setSelectedCampaign] = useState<string[]>([]);
  const [selectedPartnership, setSelectedPartnership] = useState<string[]>([]);
  const search = queryString.parse(window.location.search);
  const { location, push } = useHistory();
  const [partnershipsData, setPartnershipsData] = useState<PartnershipsListRes>();
  const [searchQ, setSearchQ] = useState('');
  const [selectCampaignItem, setSelectCampaignItem] = useState<CampaignData>();
  const [displayEin, setDispalyEin] = useState('');

  const initPage = 1;

  const user = useSelector(selectUser);
  const isCompanyUser = useIsCompanyUser(user);

  const getEININFO = () => {
    onGetEinInfo({}).then(e => {
      if (e.status === 3021) setDispalyEin('error');
      if (e.status === 3031) setDispalyEin('success');
      if (e.status === 200) setDispalyEin('');
    });
  };

  useEffect(() => {
    if (!isCompanyUser) {
      getEININFO();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPartnershipsList = (
    page: number,
    q: string | undefined,
    sortType: string | undefined,
    sortBy: string | undefined,
    operation: string | undefined,
  ) => {
    const params = {
      page,
      q,
      sortType: sortStatus.status ? sortStatus.status : sortType,
      sortBy: sortStatus.name ? sortStatus.name : sortBy,
      operation,
    };
    if (isCompanyUser) {
      onFetchParListByCompanies(params).then(ele => {
        setPartnershipsData(ele);
      });
    } else {
      onFetchPartnershipsList(params).then(ele => {
        setPartnershipsData(ele);
      });
    }
  };

  useEffect(() => {
    const operation = queryString.parse(location.search)?.operation?.toString() || '';
    const q = queryString.parse(location.search)?.q?.toString() || '';
    const search = (queryString.parse(
      window.location.search,
    ) as unknown) as ROISurveyListReq;
    setSearchQ(q);
    getPartnershipsList(initPage, q, 'DESC', 'startDate', operation);
    search.order && setSortStatus({ name: search.order, status: search.orderType || '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  useEffect(() => {
    const pageString = queryString.parse(location.search)?.page?.toString() || '';
    onFetchCampaignList({ page: Number(pageString) || 1 });
  }, [location.search, onFetchCampaignList]);

  const handleSearch = useCallback(
    (values: CampaignListReq) => {
      onFetchCampaignList(values);
      push(
        `${location.pathname}?${queryString.stringify({
          ...values,
          type: 'Manage_Campaigns',
        })}`,
      );
      setSelectCampaignItem(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname],
  );

  const handlePartnerSearch = useCallback((values: PartnershipsListReq) => {
    getPartnershipsList(
      values.page,
      values.q,
      values.sortType,
      values.sortBy,
      values.operation,
    );
    push(
      `${location.pathname}?${queryString.stringify({
        ...values,
        type: 'Fundraising_Partners',
      })}`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { jumpToSupport } = useJumpToSupport();

  const [showConfirmModal, hideConfirmModal] = useModal(() => {
    return (
      <ConfirmEINModal
        onClose={() => {
          getEININFO();
          hideConfirmModal();
        }}
        showSupport={jumpToSupport}
      />
    );
  });

  if (search.type === 'Preview') return <PreviewContent />;

  return (
    <ContentBlockWrapper header={<Jumbotron name="Fundraising" />}>
      <Body $maxWidth={search.type === 'Report' ? '1270px' : '1120px'}>
        <NavigationBar
          list={[
            { label: 'Manage Campaigns', value: 'Manage_Campaigns' },
            { label: 'Fundraising Partners', value: 'Fundraising_Partners' },
            { label: 'Report', value: 'Report' },
          ]}
          queryKey="type"
          activeTab="Manage_Campaigns"
        />
        {displayEin === 'error' && (
          <EinContent style={{ background: '#F2DAD4' }}>
            <ReactSVG style={{ marginRight: 16 }} src={warnError} />
            <>
              To create a campaign, please go to your organization&apos;s &nbsp;
              <div
                style={{ color: 'red', cursor: 'pointer' }}
                onClick={() => push('/profile/edit')}
              >
                Profile Settings&nbsp;
              </div>{' '}
              to provide your EIN and PayPal account information.
            </>
          </EinContent>
        )}
        {displayEin === 'success' && (
          <EinContent>
            <ReactSVG style={{ marginRight: 16 }} src={warnSuccess} />
            <>
              <div
                style={{ color: '#2BAEE0', cursor: 'pointer' }}
                onClick={showConfirmModal}
              >
                Click here
              </div>
              &nbsp;to validate your organization&apos;s PayPal account to ensure proper
              funds disbursement.
            </>
          </EinContent>
        )}
        {search.type === 'Manage_Campaigns' && (
          <>
            <CampaignSearch
              meta={meta}
              onSearch={handleSearch}
              onFetchCampaignList={onFetchCampaignList}
              disabledAdd={displayEin}
            />
            <CampaignList
              rows={items}
              headCells={headTable}
              meta={meta}
              campaignList={items}
              onFetchCampaignList={onFetchCampaignList}
              onCampaignSelect={ids => setSelectedCampaign(ids)}
              selectedCampaign={selectedCampaign}
              onSearch={handleSearch}
              onCampaignDelete={async () => setSelectedCampaign([])}
              callJumpATWork={e =>
                push(
                  `/campaign/give-work?type=Fundraising&campaignId=${selectCampaignItem?.id ||
                    e?.id}`,
                )
              }
              callJumpGiveShop={e =>
                push(
                  `/campaign/give-shop?type=Fundraising&campaignId=${selectCampaignItem?.id ||
                    e?.id}&campaignName=${e?.campaignName}`,
                )
              }
              selectCampaignItem={e => setSelectCampaignItem(e)}
            />
          </>
        )}

        {search.type === 'Fundraising_Partners' && (
          <>
            <PartnersSearch
              meta={partnershipsData?.meta}
              onSearch={handlePartnerSearch}
              searchValue={searchQ}
              onChangeSearchValue={(val: string) => setSearchQ(val)}
              onFetchPartnersList={getPartnershipsList}
              isCompanyUser={isCompanyUser}
            />
            <PartnersList
              rows={partnershipsData?.items || []}
              meta={partnershipsData?.meta}
              partnershipsList={partnershipsData?.items || []}
              onPartnershipSelect={ids => setSelectedPartnership(ids)}
              selectedPartnership={selectedPartnership}
              onSearch={handlePartnerSearch}
              onPartnershipsDelete={async () => {
                setSelectedPartnership([]);
                getPartnershipsList(initPage, '', '', '', '');
              }}
              searchValue={searchQ}
              sortStatus={sortStatus}
              onSort={handleSwitch}
              isCompanyUser={isCompanyUser}
            />
          </>
        )}

        {search.type === 'Report' && <FundraisingReport />}
      </Body>
    </ContentBlockWrapper>
  );
};

const Body = styled.div<{ $maxWidth: string }>`
  max-width: ${({ $maxWidth }) => $maxWidth};
`;

const EinContent = styled.div`
  background: #dbf2fb;
  border-radius: 6px;
  margin: 32px 0;
  height: 85px;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-left: 24px;
`;

export default Campaign;
