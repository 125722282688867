import { Field } from 'components/FinalForm';
import { FieldTextInput } from 'components/FormFields';
import { UIButton, UIModal, UISpinner } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import { composeValidators } from 'utils';
import { email, required } from 'utils/validation';
import downloadFile from 'js-file-download';

interface Props extends InjectedProps {
  onSend: (values: FormProps) => void;
  qrCodeBase64: string;
  isQRCodeLoading: boolean;
  campaignName: string;
}

interface FormProps {
  email: string;
}

const CampaignQRCodeModal = ({
  onClose,
  onSend,
  qrCodeBase64,
  isloading,
  isQRCodeLoading,
  campaignName,
  ...modalProps
}: Props) => {
  useLockBodyScroll();

  const [isShare, setIsShare] = useState(false);

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '46px 0 8px' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '333px',
      }}
    >
      <Form
        onSubmit={(values: FormProps) => {
          onSend(values);
        }}
        render={({ handleSubmit }) => (
          <FormBody>
            <QRCodeWrapper>
              {isQRCodeLoading && <UISpinner />}
              {!isQRCodeLoading && <img alt="qr-code" src={qrCodeBase64} />}
            </QRCodeWrapper>
            <Title>QR Code</Title>
            <Desc>You can share or download your QR code in the following buttons</Desc>

            <BtnGroup>
              <Button
                modifiers="third"
                title="Share code"
                handleClick={() => setIsShare(!isShare)}
              />
              <Button
                modifiers="primary"
                title="Download code"
                isLoading={isQRCodeLoading}
                handleClick={() =>
                  fetch(qrCodeBase64)
                    .then(res => res.blob())
                    .then(blob => downloadFile(blob, `${campaignName}.png`))
                }
              />
            </BtnGroup>

            {isShare && (
              <>
                <Field
                  name="email"
                  label="Email"
                  component={FieldTextInput}
                  validate={composeValidators(required, email)}
                />
                <LargeButton
                  modifiers="primary"
                  title="Send"
                  isLoading={isloading}
                  handleClick={handleSubmit}
                />
              </>
            )}
          </FormBody>
        )}
      />
    </UIModal>
  );
};

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
`;

const Button = styled(UIButton)`
  font-size: 12px;
  width: 134px;
  padding: 8px 0;
`;

const FormBody = styled.div`
  margin: 0 24px;
`;

const QRCodeWrapper = styled.div`
  width: 170px;
  height: 170px;
  margin: 0 auto 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 16px;
`;

const Desc = styled.div`
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  margin-bottom: 24px;
`;

const LargeButton = styled(UIButton)`
  width: 100%;
  margin-bottom: 16px;
`;

export default CampaignQRCodeModal;
