import React from 'react';
import styled from 'styled-components';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';
import EarthSVG from 'assets/images/icons/earth.svg';
import { ReactSVG } from 'react-svg';

interface Props {
  scope: string[];
  themeColor?: string;
}

const ScopeOfImpactMetric = ({ scope, themeColor }: Props) => {
  return (
    <MetricRoot>
      <MetricTitle>Scope of Impact</MetricTitle>
      <Content>
        <Icon src={EarthSVG} $themeColor={themeColor} />
        <Value>{scope.join(', ')}</Value>
      </Content>
    </MetricRoot>
  );
};

const Content = styled(MetricContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Icon = styled(ReactSVG)<{ $themeColor?: string }>`
  margin-bottom: 24px;

  svg {
    width: 34px;
    height: 34px;
  }

  path {
    fill: ${({ $themeColor }) => $themeColor || '#2BAEE0'};
  }
`;

const Value = styled.div`
  max-width: 170px;
  font-weight: 500;
  text-align: center;
`;

export default ScopeOfImpactMetric;
