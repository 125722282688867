/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import OrganizationWrapper from 'layouts/OrganizationWrapper';
import { useOperation } from 'hooks/common';
import { fetchEmailTemplates } from 'store/ducks/team/operations';
import { useSelector } from 'react-redux';
import TemplateTable from 'components/team/TemplateTable';
import EmailTemplate from 'components/team/EmailTemplate';
import { getTeam } from 'store/ducks/team/selectors';
import { StateProps } from 'store/interfaces';
import { TemplateEmailPreview, TemplatePreview } from 'interfaces/common';
import { teamSelectors } from 'store/ducks/team';

const EmailTemplates = () => {
  const team = useSelector(getTeam);
  const [loadEmailTemplates, isStartLoading] = useOperation(fetchEmailTemplates);
  const emailTemplates = useSelector<StateProps, TemplateEmailPreview[]>(
    teamSelectors.getEmailTemplates,
  );

  useEffect(() => {
    // Check if team.id exists before fetching email templatess
    if (team?.id) {
      loadEmailTemplates(team.id);
    }
  }, [team?.id]);

  return (
    <OrganizationWrapper isLoading={isStartLoading}>
      <EmailTemplate />
      <TemplateTable rows={emailTemplates} />
    </OrganizationWrapper>
  );
};

export default EmailTemplates;
