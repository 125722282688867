import { Field } from 'components/FinalForm';
import {
  FieldDatePicker,
  FieldSingleSelect,
  FieldWYSIWYGEditor,
} from 'components/FormFields';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps } from 'components/UI/interfaces';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import { ROITemplate } from 'interfaces/roi';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import RefreshSVG from 'assets/images/icons/refresh.svg';
import { ReactSVG } from 'react-svg';
import { ConfirmModal } from 'components/Modals';
import { useModal } from 'hooks/common';
import { FormLabel } from 'components/UI/form';

interface BaseProps {
  onSubmit: (values: ROITemplate) => OnSubmitReturnType;
  roiTemplate: ROITemplate;
  templatePreview: string;
  onReset: (templateId: string) => void;
  onPreview: (template: ROITemplate) => void;
}

type Props = BaseProps & UIModalProps;

function TemplateModal({
  onClose,
  onSubmit = () => {},
  onReset,
  onPreview,
  roiTemplate,
  templatePreview,
  ...modalProps
}: Props) {
  const [templateId, setTemplateId] = useState('');
  const [showConfirmModal, hideConfirmModal] = useModal(() => {
    return (
      <ConfirmModal
        accessHandler={() => {
          onReset(templateId);
          hideConfirmModal();
        }}
        cancelHandler={hideConfirmModal}
        textAccessBtn="Yes"
        textCancelBtn="No"
        name="Are you sure to reset this template?"
        onClose={hideConfirmModal}
      />
    );
  }, [templateId]);

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '28px 40px 40px' }}
      style={{
        width: '100%',
        maxWidth: '844px',
        top: '100px',
      }}
      overlayStyle={{
        overflow: 'auto',
      }}
      header={<ModalTitle>{roiTemplate.subject}</ModalTitle>}
    >
      <Form
        initialValues={{ ...roiTemplate, preview: templatePreview }}
        onSubmit={(values: ROITemplate) => onSubmit({ ...roiTemplate, ...values })}
        render={({ handleSubmit, values }) => {
          return (
            <FormBody>
              <DoubelFieldWrapper>
                <Field
                  name="period"
                  label="Period"
                  component={FieldSingleSelect}
                  options={[
                    { label: 'Years', value: 'years' },
                    { label: 'Quarters', value: 'quarters' },
                    { label: 'Months', value: 'months' },
                  ]}
                />
                <Field
                  name="scheduledTime"
                  label="Scheduled Time"
                  component={FieldDatePicker}
                />
              </DoubelFieldWrapper>
              <Field
                id="roi-template-modal-editor"
                name="body"
                label="Template"
                component={FieldWYSIWYGEditor}
                format={(value: string) =>
                  value
                    ? value.trim().startsWith('<p>')
                      ? value
                      : `<p>${value}</p>`
                    : '<p></p>'
                }
              />
              <FormLabel>Preview</FormLabel>
              <Preview dangerouslySetInnerHTML={{ __html: templatePreview }} />
              <RefreshIcon src={RefreshSVG} onMouseUp={() => onPreview(values)} />

              <ButtonGroup>
                <ButtonGroup>
                  <Button title="Cancel" modifiers="secondary" handleClick={onClose} />
                  <Button
                    title="Reset"
                    modifiers="secondary"
                    handleClick={() => {
                      setTemplateId(roiTemplate.id);
                      showConfirmModal();
                    }}
                  />
                </ButtonGroup>
                <Button title="Save" modifiers="primary" handleClick={handleSubmit} />
              </ButtonGroup>
            </FormBody>
          );
        }}
      />
    </UIModal>
  );
}

const ModalTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  padding: 40px 0 16px;
`;

const FormBody = styled.div`
  position: relative;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;

  & > button:not(:first-child) {
    margin-left: 8px;
  }
`;

const Button = styled(UIButton)`
  width: 106px;
`;

const RefreshIcon = styled(ReactSVG)`
  position: absolute;
  right: 28px;
  bottom: 80px;
  cursor: pointer;
  transform: rotate(180deg);
  transition: transform 1s;
  z-index: 1;

  &:active {
    transform: rotate(0deg);
    transition: 0s;
  }
`;

const DoubelFieldWrapper = styled.div`
  display: flex;

  & > div {
    width: 343px;
  }

  & > div:not(:first-child) {
    margin-left: 24px;
  }
`;

const Preview = styled.div`
  height: 280px;
  overflow: auto;
  border: 1px solid #f1f1f1;
  border-radius: 6px;
  margin-bottom: 20px;
`;

export default TemplateModal;
