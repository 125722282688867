/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ContentBlockWrapper } from 'layouts';
import { useOperation } from 'hooks/common';
import { useParams } from 'react-router-dom';
import { debounce, isObject } from 'lodash';
import {
  fetchCompanyCurrentUpdate,
  fetchCompanyCurrent,
  fetchCompaniesSearch,
  fetchCompanyCurrentLogoUpload,
} from 'store/ducks/companies/operations';
import { StateProps, CRSFocus, AdditionalInfoProps } from 'store/interfaces';
import {
  getAdditionalInfoSelect,
  getCompaniesSearch,
  getCompanyCurrent,
} from 'store/ducks/companies/selectors';
import { getCrsFocus } from 'store/ducks/user/selectors';
import { SearchCompany, SuggestCompany } from 'interfaces/companies';
import CompanyProfileForm from 'components/CompanyProfileForm';
import Jumbotron from 'components/Jumbotron';
import History from 'services/History';

const ProfileEdit = () => {
  const [onfetchCompany] = useOperation(fetchCompanyCurrent);
  const [onSearchCompanies] = useOperation(fetchCompaniesSearch);

  const [updateCompanies] = useOperation(fetchCompanyCurrentUpdate);
  const [onCompanyLogoUpload] = useOperation(fetchCompanyCurrentLogoUpload);
  const params: { companyId: string } = useParams();

  const company = useSelector<StateProps, SearchCompany | undefined>(getCompanyCurrent);
  const additionalInfo = useSelector<StateProps, AdditionalInfoProps>(
    getAdditionalInfoSelect(),
  );

  const crsFocus = useSelector<StateProps, CRSFocus[]>(getCrsFocus);

  const companiesSearchList = useSelector<StateProps, SuggestCompany[]>(
    getCompaniesSearch,
  );

  useEffect(() => {
    onfetchCompany(params.companyId);
  }, [params.companyId]);

  const onSubmit = useCallback((values: SearchCompany) => {
    return updateCompanies(values).then(() => {
      History.back();
    });
  }, []);

  const initialValues = useMemo(() => {
    if (isObject(company)) {
      return company;
    }
  }, [company]);

  const handleSearchCompanies = (val: string) => {
    return onSearchCompanies(val);
  };

  const handleSearchCompaniesDebounce = useCallback(
    debounce(handleSearchCompanies, 800),
    [],
  );

  const handleCompanyLogoUpload = async (file: File) => await onCompanyLogoUpload(file);

  return (
    <ContentBlockWrapper header={<Jumbotron name="Company" back />}>
      {initialValues && (
        <CompanyProfileForm
          initialValues={initialValues}
          crsFocus={crsFocus}
          industries={additionalInfo.industries}
          onSubmit={onSubmit}
          companiesSearchList={companiesSearchList}
          handleGetCompanies={handleSearchCompaniesDebounce}
          handleUploadLogo={handleCompanyLogoUpload}
        />
      )}
    </ContentBlockWrapper>
  );
};

export default ProfileEdit;
