import { AuthWrapperProps } from 'interfaces/common';
import React from 'react';
import styled from 'styled-components';
import Header from 'components/HeaderTS';

function AuthenticatedTSWrapper({ children, location }: AuthWrapperProps) {
  return (
    <Root>
      <Header location={location} />
      {children}
    </Root>
  );
}

const Root = styled.div`
  width: 100%;
  height: 100%;
`;

export default AuthenticatedTSWrapper;
