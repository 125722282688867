import React from 'react';
import { IProps as InjectedProps } from 'react-range/lib/types';
import { RangeSliderProps } from '../interfaces';
import UIRangeSlider from './UIRangeSlider';
import { FormatMillionNumber } from '../format';

const STEP = 1;
const MIN = 1;
const MAX = 100000;
const HARDVALUES = [0, 100, 200, 300];

type Props = RangeSliderProps & Partial<InjectedProps>;

const MoneyRangeSlider = ({
  values = [],
  step = STEP,
  min = MIN,
  max = MAX,
  hardValues = HARDVALUES,
  onChange,
  ...rest
}: Props) => {
  const handleChange = (vals: number[]) => {
    const mappedValues = vals.map(index => hardValues[index]);
    onChange?.(mappedValues);
  };

  return (
    <UIRangeSlider
      values={values.map(value => hardValues.indexOf(value))}
      step={step}
      min={min}
      max={max}
      onChange={handleChange}
      modifyValueFunc={value => (
        <FormatMillionNumber
          value={hardValues[value]}
          prefix="$ "
          formatType="receipts-slider"
        />
      )}
      {...rest}
    />
  );
};

export default MoneyRangeSlider;
