import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

interface Props extends NumberFormatProps {
  value: number;
  formatType?: 'default' | 'receipts-slider';
}

const FormatMillionNumber = ({ value, formatType = 'default', ...rest }: Props) => {
  let formattedValue, suffix;

  switch (formatType) {
    case 'receipts-slider':
      // Custom formatting decimal points for gross receipts slider
      if (value >= 1000000000) {
        formattedValue = (value / 1000000000).toFixed(1);
        suffix = ' B';
      } else if (value >= 1000000) {
        formattedValue = (value / 1000000).toFixed(0);
        suffix = ' M';
      } else {
        formattedValue = (value / 1000).toFixed(0);
        suffix = ' K';
      }
      break;
    default:
      if (value >= 1000000000) {
        // For billion values
        formattedValue = (value / 1000000000).toFixed(1);
        suffix = ' B';
      } else if (value >= 1000000) {
        // For million values
        formattedValue = (value / 1000000).toFixed(1);
        suffix = ' M';
      } else {
        // For thousand values
        formattedValue = (value / 1000).toFixed(2);
        suffix = ' K';
      }
      break;
  }

  return (
    <NumberFormat
      value={formattedValue}
      displayType="text"
      thousandSeparator
      suffix={suffix}
      {...rest}
    />
  );
};

export default FormatMillionNumber;
