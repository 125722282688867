import { UISuggestOption } from 'components/UI/interfaces';
import UISuggestSingle from 'components/UI/UISuggestSingle';
import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import SearchIcon from 'assets/images/icons/search.svg';
import CloseSVG from 'assets/images/icons/close-toast.svg';

interface Props {
  list: UISuggestOption[];
  handleGetSuggestions: (value: string) => void;
  onSearch: (value: string) => void;
  searchValue?: string;
  placeholder?: string;
  className?: string;
}

const SearchSuggest = ({
  list,
  placeholder,
  className,
  searchValue,
  handleGetSuggestions,
  onSearch,
}: Props) => {
  const [suggestValue, setSuggestValue] = useState<UISuggestOption | undefined>(
    undefined,
  );
  const searchHandler = () => {
    if (!suggestValue?.name) {
      setSuggestValue(undefined);
      onSearch('');
      return;
    }
    onSearch(suggestValue?.name);
  };

  const handleClear = () => {
    setSuggestValue(undefined);
    onSearch('');
  };

  useEffect(() => {
    if (!searchValue) return;
    setSuggestValue({ id: '', name: searchValue });
  }, [searchValue]);

  return (
    <Root>
      <UISuggestSingle
        hideClearIcon
        className={className}
        handleGetSuggestions={handleGetSuggestions}
        onChangeByType={name => setSuggestValue({ id: '', name })}
        list={list}
        placeholder={placeholder || ''}
        handleSelectSuggest={setSuggestValue}
        value={suggestValue}
        onEnter={searchHandler}
      />
      {suggestValue?.name && <Clear src={CloseSVG} onClick={handleClear} />}
      <Icon src={SearchIcon} onClick={searchHandler} />
    </Root>
  );
};

const Root = styled.div`
  position: relative;
`;

const Icon = styled(ReactSVG)`
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
  transform: translateY(-50%);
`;

const Clear = styled(ReactSVG)`
  position: absolute;
  top: 14px;
  right: 40px;
  cursor: pointer;

  & svg {
    path {
      stroke: rgb(115, 115, 115);
    }
  }
`;

export default SearchSuggest;
