/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { UISpinner } from 'components/UI';
import UserIcon from 'assets/images/icons/user.svg';
import { useFileBase64Url } from 'hooks/common';

interface Props {
  className?: string;
  imgKey?: string;
  loading?: boolean;
}

const UILoadAvatar: FC<Props> = ({ className, imgKey, loading }: Props) => {
  const { fileBase64Url, setFileBase64Url } = useFileBase64Url({ imgKey });

  useEffect(() => {
    if (imgKey) {
      setFileBase64Url(imgKey);
    }
  }, [imgKey]);
  return (
    <Root>
      {loading ? (
        <Avatar className={className}>
          <UISpinner width={25} height={25} />
        </Avatar>
      ) : fileBase64Url ? (
        <Avatar className={className} as="img" src={fileBase64Url} />
      ) : (
        <Avatar className={className}>
          <EmptyAvatar src={UserIcon} />
        </Avatar>
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyAvatar = styled(ReactSVG)`
  & svg {
    width: 48px;
    height: 48px;
    & path {
      fill: var(--darkGray);
    }
  }
`;

export default UILoadAvatar;
