import UploadButton from 'components/ActionButtons/UploadButton';
import { Field } from 'components/FinalForm';
import {
  FieldDatePicker,
  FieldSingleSelect,
  FieldTextInput,
  FieldWYSIWYGEditor,
} from 'components/FormFields';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps } from 'components/UI/interfaces';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import { ROITemplate } from 'interfaces/roi';
import React from 'react';
import { Form } from 'react-final-form';
import Toast from 'services/Toast';
import { uploadFile } from 'store/ducks/utility/api';
import styled from 'styled-components';

interface BaseProps {
  onSubmit: (values: ROITemplate) => OnSubmitReturnType;
  roiTemplate: ROITemplate;
  templatePreview: string;
}

type Props = BaseProps & UIModalProps;

function PDFTemplateModal({
  onClose,
  onSubmit = () => {},
  roiTemplate,
  templatePreview,
  ...modalProps
}: Props) {
  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '28px 40px 40px' }}
      style={{
        width: '100%',
        maxWidth: '844px',
        top: '100px',
      }}
      overlayStyle={{
        overflow: 'auto',
      }}
      header={<ModalTitle>{roiTemplate.subject}</ModalTitle>}
    >
      <Form<any>
        initialValues={{ ...roiTemplate, preview: templatePreview }}
        onSubmit={(values: ROITemplate['extension']) =>
          onSubmit({
            ...roiTemplate,
            ...values,
          })
        }
        render={({ handleSubmit, form }) => {
          return (
            <FormBody>
              <ShortFieldWrapper>
                <Field
                  name="extension.yourNonProfit"
                  label="Subject Line"
                  component={FieldTextInput}
                />
                <SubjectLineText>‘s Monthly Newsletter</SubjectLineText>
              </ShortFieldWrapper>
              <DoubelFieldWrapper>
                <Field
                  name="period"
                  label="Period"
                  component={FieldSingleSelect}
                  options={[
                    { label: 'Years', value: 'years' },
                    { label: 'Quarters', value: 'quarters' },
                    { label: 'Months', value: 'months' },
                  ]}
                />
                <Field
                  name="scheduledTime"
                  label="Scheduled Time"
                  component={FieldDatePicker}
                />
              </DoubelFieldWrapper>
              <Field
                id="pdf-template-modal-editor-1"
                name="extension.missionAdvancements"
                label="Mission Advancements"
                component={FieldWYSIWYGEditor}
                height={128}
              />
              <Field
                id="pdf-template-modal-editor-2"
                name="extension.missionImpact"
                label="Mission Impact"
                component={FieldWYSIWYGEditor}
                height={128}
              />
              <Field
                id="pdf-template-modal-editor-3"
                name="extension.featuredPartner"
                label="Featured Partner"
                component={FieldWYSIWYGEditor}
                height={128}
              />
              <Field
                id="pdf-template-modal-editor-4"
                name="extension.upcomingEvents"
                label="Upcoming Events"
                component={FieldWYSIWYGEditor}
                height={128}
              />
              <UploadCSVWrapper>
                <UploadButton
                  name="Upload Image"
                  accept=".jpg,.jpeg,.png"
                  uploadHandler={files => {
                    if (!files.length) return Promise.resolve(undefined);

                    return uploadFile(files[0], 'roi-template').then(v => {
                      if (v.status === 'success') {
                        form.change('extension.imageKey', v.fileKey);
                        return Toast.success('File uploaded successfully');
                      }
                      Toast.error('Error upload file');
                    });
                  }}
                />
                <Desc>
                  Please upload a banner image for the newsletter.
                  <br />
                  File size limit is 5 Mb. Only jpg, png files allowed. The recommended
                  image size is 2030 by 1088 pixels.
                </Desc>
              </UploadCSVWrapper>
              <ButtonGroup>
                <Button title="Cancel" modifiers="secondary" handleClick={onClose} />
                <ButtonGroup>
                  <Button title="Save" modifiers="primary" handleClick={handleSubmit} />
                  <Button
                    title="View Template"
                    modifiers="third"
                    handleClick={() => {
                      const newTab = window.open();
                      if (!newTab) return;
                      newTab.document.body.innerHTML = templatePreview;
                    }}
                  />
                </ButtonGroup>
              </ButtonGroup>
            </FormBody>
          );
        }}
      />
    </UIModal>
  );
}

const ModalTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  padding: 40px 0 16px;
`;

const FormBody = styled.div`
  position: relative;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;

  & > button:not(:first-child) {
    margin-left: 8px;
  }
`;

const Button = styled(UIButton)`
  width: 106px;
  padding: 6px 0;
`;

const ShortFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 343px;
  position: relative;
`;

const DoubelFieldWrapper = styled.div`
  display: flex;

  & > div {
    width: 343px;
  }

  & > div:not(:first-child) {
    margin-left: 24px;
  }
`;

const SubjectLineText = styled.div`
  white-space: nowrap;
  margin-left: 6px;
  position: absolute;
  right: -165px;
`;

const UploadCSVWrapper = styled.div`
  height: 203px;
  border: 1px dashed #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 24px;
`;

const Desc = styled.div`
  color: #979797;
  line-height: 145%;
  text-align: center;
  margin-top: 18px;
  font-size: 14px;
`;

export default PDFTemplateModal;
