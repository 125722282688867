import CardGameplan from 'components/CardGameplan';
import Jumbotron from 'components/Jumbotron';
import TotalRow from 'components/TotalRow';
import { SingleSelect } from 'components/UI';
import { SelectOption } from 'components/UI/interfaces';
import { useOperation } from 'hooks/common';
import { Gameplan } from 'interfaces/gameplan';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchDeleteGameplanContact, fetchGameplan } from 'store/ducks/gameplan/api';
import {
  clearGameplan,
  fetchChangeGameplanNameAndClose,
  fetchGameplansBySavelist,
} from 'store/ducks/gameplan/operations';
import { getGameplans } from 'store/ducks/gameplan/selectors';
import { getTeam } from 'store/ducks/team/selectors';
import { StateProps } from 'store/interfaces';
import styled from 'styled-components/macro';

const GamePlanListBySavelist = () => {
  const params: { prospectsId: string; prospectsName: string } = useParams();
  params.prospectsName = decodeURIComponent(params.prospectsName);

  const [selected, setSelected] = useState('');

  const [onFetchGameplansBySavelist, , isGameplansLoading] = useOperation(
    fetchGameplansBySavelist,
  );
  const [onFetchChangeGameplanNameAndClose] = useOperation(
    fetchChangeGameplanNameAndClose,
  );
  const [onClearGameplan] = useOperation(clearGameplan);

  const gameplans = useSelector<StateProps, Gameplan[]>(getGameplans);
  const team = useSelector(getTeam);

  const selectedGameplan = useMemo(() => {
    if (!selected) {
      return;
    }
    return gameplans.find(item => item.id === selected);
  }, [selected, gameplans]);

  const gameplansOptions = useMemo(
    () => gameplans.map(item => ({ label: item.name, value: item.id })),
    [gameplans],
  );

  const handleSelect = (option: SelectOption | null) => {
    if (!option) {
      setSelected('');
      return;
    }
    setSelected(String(option.value));
  };

  useEffect(() => {
    if (!gameplans.length) {
      return;
    }
    setSelected(gameplans[0].id);
  }, [gameplans]);

  useEffect(() => {
    onFetchGameplansBySavelist(params.prospectsId);
    return () => {
      onClearGameplan(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentBlockWrapper
      header={<Jumbotron name={params.prospectsName} back={true} />}
      loading={isGameplansLoading}
    >
      <TotalRow
        title={`${gameplans.length} Game plan${gameplans.length > 1 ? 's' : ''}`}
      />
      <SelectWrapper>
        <SingleSelect
          placeholder="Select gameplan"
          options={gameplansOptions}
          value={selected}
          handleSelect={handleSelect}
          isClearable={false}
        />
      </SelectWrapper>
      {selectedGameplan && team && (
        <CardGameplan
          gameplan={selectedGameplan}
          team={team}
          onFetchSetGameplanNameAndClose={onFetchChangeGameplanNameAndClose}
          fetchDeleteGameplanContactApi={fetchDeleteGameplanContact}
          fetchGameplanApi={fetchGameplan}
        />
      )}
    </ContentBlockWrapper>
  );
};

const SelectWrapper = styled.div`
  width: 374px;
  margin-bottom: 24px;
`;

export default GamePlanListBySavelist;
