/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useOperation } from 'hooks/common';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import Jumbotron from 'components/Jumbotron';
import Reports from 'components/Reports';
import { SearchCompany } from 'interfaces/companies';
import { StateProps } from 'store/interfaces';
import { Prospect } from 'interfaces/prospects';
import {
  fetchCompaniesFavorites,
  fetchCompaniesTopMatch,
  fetchLastMonthCompanies,
} from 'store/ducks/companies/operations';
import { fetchSavedLists } from 'store/ducks/prospects/operations';
import { getSavedLists } from 'store/ducks/prospects/selectors';
import {
  getCompaniesFavorites,
  getCompaniesTopMatch,
  getLastMonthCompanies,
} from 'store/ducks/companies/selectors';
import { fetchTeam } from 'store/ducks/team/operations';
import {
  fetchChangeGameplanNameAndCloseByTeam,
  fetchGameplansByTeam,
} from 'store/ducks/gameplan/operations';
import { getGameplansByTeamItems } from 'store/ducks/gameplan/selectors';
import { GameplanBase } from 'interfaces/gameplan';
import { fetchGameplan, fetchDeleteGameplanContact } from 'store/ducks/gameplan/api';
import { fetchDashboradPricing } from 'store/ducks/pricing/operations';
import { getDashboardPricing } from 'store/ducks/pricing/selectors';
import { DashboardPricing } from 'interfaces/pricing';
import { getSubscription, getTeam } from 'store/ducks/team/selectors';
import {
  FavoritesDashboard,
  GameplanDashboard,
  PricingDashboard,
  ProspectingDashboard,
  RecentlyAddedCompaniesDashboard,
} from './components';
import { PAGE_WIDTH } from 'constants/common';
import useRedirect from 'hooks/common/useRedirect';

const Dashboard = () => {
  const [onFetchTeam, isFetchingTeam] = useOperation(fetchTeam);
  const [onFetchCompanies, isLoadingCompanies] = useOperation(fetchCompaniesFavorites);
  const [onFetchSavedLists, isLoadingSavedList] = useOperation(fetchSavedLists);
  const [onFetchTopMatch] = useOperation(fetchCompaniesTopMatch);
  const [onFetchGameplans] = useOperation(fetchGameplansByTeam);
  const [onFetchSetGameplanNameAndClose] = useOperation(
    fetchChangeGameplanNameAndCloseByTeam,
  );
  const [onFetchDashboardPricing] = useOperation(fetchDashboradPricing);
  const [onLastMonthCompanies] = useOperation(fetchLastMonthCompanies);

  const companies = useSelector<StateProps, SearchCompany[]>(getCompaniesFavorites);
  const savedLists = useSelector<StateProps, Prospect[]>(getSavedLists);
  const topMatch = useSelector<StateProps, SearchCompany[]>(getCompaniesTopMatch);
  const gameplans = useSelector<StateProps, GameplanBase[] | undefined>(
    getGameplansByTeamItems,
  );
  const dashboardPricing = useSelector<StateProps, DashboardPricing>(getDashboardPricing);
  const recentlyAddedCompanies = useSelector(getLastMonthCompanies);
  const subscription = useSelector(getSubscription);
  const team = useSelector(getTeam);

  useRedirect();

  useEffect(() => {
    if (!subscription) return;

    if (subscription.isProspecting) {
      onFetchCompanies(1);
      onFetchSavedLists({ page: 1, sort: 'alphabet' });
      onFetchTopMatch(undefined);
      onFetchGameplans({ page: 1, limit: 2 });
      onLastMonthCompanies({ page: 1, limit: 4 });
    }
    if (subscription.isPrices) {
      onFetchDashboardPricing(undefined);
    }
    onFetchTeam(undefined);
  }, [subscription]);

  return (
    <ContentBlockWrapper
      header={<Jumbotron name="Dashboard" />}
      loading={
        subscription.isProspecting &&
        (isLoadingCompanies || isLoadingSavedList || isFetchingTeam)
      }
    >
      {subscription.isProspecting && team && (
        <>
          <Section>
            <SectionHeader>
              <SectionTitle>Recently Added Companies</SectionTitle>
              <LinkElem to="/companies-recently-added">see more</LinkElem>
            </SectionHeader>
            <RecentlyAddedCompaniesDashboard data={recentlyAddedCompanies} />
          </Section>

          <Section>
            <SectionHeader>
              <SectionTitle>Prospecting Sessions</SectionTitle>
              <LinkElem to="/prospects">see more</LinkElem>
            </SectionHeader>
            <ProspectingDashboard data={savedLists} />
          </Section>

          <Section>
            <SectionHeader>
              <SectionTitle>Game Plan</SectionTitle>
              <LinkElem to="/gameplan/gameplans">see more</LinkElem>
            </SectionHeader>
            <GameplanDashboard
              data={gameplans}
              team={team}
              fetchDeleteGameplanContact={fetchDeleteGameplanContact}
              fetchGameplan={fetchGameplan}
              onFetchSetGameplanNameAndClose={onFetchSetGameplanNameAndClose}
            />
          </Section>
        </>
      )}

      <SectionRow>
        {subscription.isProspecting && (
          <Section>
            <SectionHeader>
              <SectionTitle>Favorites</SectionTitle>
              {companies && companies.length > 0 && (
                <LinkElem to="/company-favorites">see more</LinkElem>
              )}
            </SectionHeader>
            <FavoritesDashboard companies={companies} />
          </Section>
        )}
        <Section>
          <SectionHeader>
            <SectionTitle>Reports</SectionTitle>
          </SectionHeader>
          <Reports topMatch={topMatch} />
        </Section>
      </SectionRow>

      {/* {subscription.isRoi && (
        <Section>
          <SectionHeader>
            <SectionTitle>ROI</SectionTitle>
          </SectionHeader>
          <ROITabCharts data={dashboardROI} />
        </Section>
      )} */}

      {subscription.isPrices && (
        <Section>
          <SectionHeader>
            <SectionTitle>Pricing</SectionTitle>
          </SectionHeader>
          <PricingDashboard data={dashboardPricing} />
        </Section>
      )}
    </ContentBlockWrapper>
  );
};

const SectionHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
`;

const SectionTitle = styled.h4`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
`;

const LinkElem = styled(Link)`
  color: var(--blue);
  &:hover {
    text-decoration: underline;
  }
`;

const Section = styled.section`
  max-width: ${PAGE_WIDTH.NORMAL}px;
  margin-bottom: 40px;
`;

const SectionRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: ${PAGE_WIDTH.NORMAL}px;
  & ${Section} {
    max-width: 536px;
    width: 100%;
  }
`;

export default Dashboard;
