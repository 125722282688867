import DownloadIcon from 'assets/images/icons/download.svg';
import { UISpinner } from 'components/UI';
import { ActionButtonProps as InjectedProps } from 'interfaces/common';
import React, { ChangeEvent, forwardRef } from 'react';
import { ReactSVG } from 'react-svg';
import Toast from 'services/Toast';
import styled, { CSSProp } from 'styled-components';
import { lessThan5MB } from 'utils/validation';

interface BaseProps {
  uploadHandler: (files: File[]) => void;
  styleType?: 'button' | 'label';
  multiple?: boolean;
  filesLimit?: number;
  accept?: string;
  acceptValidator?: (filename: string) => boolean;
  inputId?: string;
  disabled?: boolean;
  fileSizeValidator?: (bytes: number) => string | undefined;
  allowUploadSameFile?: boolean;
}

type Props = BaseProps & Partial<InjectedProps>;

const UploadButton = forwardRef<HTMLLabelElement, Props>(
  (
    {
      uploadHandler,
      name = 'Upload Excel',
      icon = DownloadIcon,
      iconCss,
      isloading,
      styleType = 'button',
      multiple,
      filesLimit = 10,
      accept,
      acceptValidator,
      inputId = 'upload-button-input',
      disabled = false,
      fileSizeValidator = lessThan5MB,
      allowUploadSameFile,
    },
    ref,
  ) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (!files?.length) return;
      const sizeError = [...files].some(
        file => fileSizeValidator(file.size) !== undefined,
      );
      if (sizeError) {
        return Toast.error('File size must be less than 5 mb');
      }

      if (files.length > filesLimit) {
        return Toast.warn(`You may upload up to ${filesLimit} files per time`);
      }

      if (acceptValidator && [...files].some(file => !acceptValidator(file.name))) return;

      uploadHandler([...files]);
    };

    const handlePreUpload = () => {
      if (!allowUploadSameFile) return;

      const inputEl = document.querySelector<HTMLInputElement>(`#${inputId}`);
      if (!inputEl) return;
      inputEl.value = '';
    };

    return (
      <Root>
        {isloading && <Spinner />}
        {styleType === 'label' ? (
          <Label
            ref={ref}
            htmlFor={disabled ? '' : inputId}
            disabled={disabled}
            onClick={handlePreUpload}
          >
            <Icon css={iconCss} src={icon} />
            {name && <Text isloading={isloading}>{name}</Text>}
          </Label>
        ) : (
          <Btn
            ref={ref}
            htmlFor={disabled ? '' : inputId}
            disabled={disabled}
            onClick={handlePreUpload}
          >
            {name}
          </Btn>
        )}

        <Input
          id={inputId}
          multiple={multiple}
          type="file"
          onChange={handleChange}
          accept={accept || '.xlsx'}
        />
      </Root>
    );
  },
);
UploadButton.displayName = 'UploadButton';

const Root = styled.button`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  transition: 0.5s ease;
  position: relative;
`;

const Icon = styled(ReactSVG)<{ css?: CSSProp }>`
  transform: rotate(180deg);

  ${({ css }) => css}
`;

const Text = styled.span<{ isloading?: boolean }>`
  margin-left: 12px;
  ${({ isloading }) => isloading && 'opacity: 0.5'};

  &:hover {
    text-shadow: 0 0 0.01px var(--black), 0 0 0.01px var(--black);
  }
`;

const Spinner = styled(UISpinner)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  svg {
    height: 20px;
    width: 20px;
  }
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
    & svg path {
      fill: #E8E8E8;
    }
  `}
`;

const Btn = styled.label<{ disabled: boolean }>`
  padding: 0 24px;
  height: 36px;
  background-color: var(--secondaryBlue);
  font-size: 12px;
  line-height: 150%;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
  `}
`;

export default UploadButton;
