import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { ReactSVG } from 'react-svg';
import { UIDivider, UITextInput } from 'components/UI';

import FilterIcon from 'assets/images/icons/filter.svg';
import SearchIcon from 'assets/images/icons/search.svg';
import CloseIcon from 'assets/images/icons/close-toast.svg';
import DropdownList from 'components/DropdownList/DropdownList';
import { SearCompaniesRequestProps } from 'interfaces/common';
import { filterTypeOptions, filterTypeOrganizationOptions } from 'constants/company';

interface Props {
  searchHandler: () => void;
  toggleFilter?: () => void;
  isShowFiltersIcon?: boolean;
  searchInputValue: string;
  filterTypeValue: SearCompaniesRequestProps['filterType'];
  handleResetForm: () => void;
  handleChangeInputValue: (val: string) => void;
  handleChangeFilterType: (type: SearCompaniesRequestProps['filterType']) => void;
  isOrganization?: boolean;
}

const SearchWithFiltersInput = ({
  searchHandler,
  toggleFilter,
  isShowFiltersIcon = true,
  searchInputValue,
  filterTypeValue,
  handleChangeInputValue,
  handleChangeFilterType,
  handleResetForm,
  isOrganization = false,
}: Props) => {
  const { location, push } = useHistory();

  return (
    <SearchInputWrapper>
      <FilterTypeDropdown
        isOrganization={isOrganization}
        title={
          <FilterTypeDropdownTitle isOrganization={isOrganization}>
            {isOrganization
              ? filterTypeOrganizationOptions.find(item => item.value === filterTypeValue)
                  ?.label
              : filterTypeOptions.find(item => item.value === filterTypeValue)?.label}
          </FilterTypeDropdownTitle>
        }
        options={isOrganization ? filterTypeOrganizationOptions : filterTypeOptions}
        onClick={value => {
          handleResetForm();
          searchHandler();
          handleChangeFilterType(value as SearCompaniesRequestProps['filterType']);
        }}
      />
      <Divider isOrganization={isOrganization} />
      <Input
        value={searchInputValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeInputValue(event.target.value)
        }
        placeholder="What are you looking for?"
        onKeyUp={e => e.key === 'Enter' && searchHandler()}
      />
      <IconReset
        src={CloseIcon}
        onClick={() => {
          handleResetForm();
          searchHandler();
          push(`${location.pathname}`);
        }}
      />
      <IconSearch src={SearchIcon} onClick={searchHandler} />
      {isShowFiltersIcon && (
        <button onClick={toggleFilter}>
          <IconFilter src={FilterIcon} />
        </button>
      )}
    </SearchInputWrapper>
  );
};

const SearchInputWrapper = styled.div`
  height: 36px;
  width: 100%;
  max-width: 715px;
  position: relative;

  input {
    background: #f1f4f5;
  }
`;

const IconFilter = styled(ReactSVG)`
  position: absolute;
  top: 10px;
  right: 20px;
`;

const Input = styled(UITextInput)`
  background: #f3fcff;
  border-radius: 6px;
  border-color: transparent;
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
  padding: 8px 42px 8px 146px;
  font-style: italic;
  &:focus {
    border-color: transparent;
  }
`;

const IconSearch = styled(ReactSVG)`
  position: absolute;
  top: 10px;
  right: 56px;
  cursor: pointer;
`;

const IconReset = styled(ReactSVG)`
  position: absolute;
  top: 8px;
  right: 88px;
  cursor: pointer;
  & svg {
    width: 21px;
    height: 21px;
    & path {
      stroke: rgb(115, 115, 115);
    }
  }
`;

const FilterTypeDropdown = styled(DropdownList)<{ isOrganization?: boolean }>`
  position: absolute;
  left: 12px;
  top: 2px;

  & .dropdown-panel {
    font-size: 12px;
    color: var(--black);
    width: 132px;
    top: 38px;
    ${({ isOrganization }) => (isOrganization ? 'right: 9px;' : 'right: -5px;')}
    background: #f1f4f5;
  }

  & .dropdown-list-item {
    padding-left: 12px;
  }
  & .UHIJx {
    margin-left: 2px;
  }
`;

const FilterTypeDropdownTitle = styled.div<{ isOrganization?: boolean }>`
  line-height: 30px;

  ${({ isOrganization }) => (isOrganization ? 'width: 113px' : 'width: 100px')}
`;

const Divider = styled(UIDivider)<{ isOrganization?: boolean }>`
  position: absolute;
  top: 6px;
  ${({ isOrganization }) => (isOrganization ? 'left: 137px' : 'left: 130px')}
`;

export default SearchWithFiltersInput;
