/* eslint-disable @typescript-eslint/no-unused-vars */
import Axios, { Method, AxiosRequestConfig, AxiosPromise, CancelToken } from 'axios';
import { API_URL } from 'env';
import StorageService from 'services/Storage';
import Toast from 'services/Toast';
let apiDispatch = () => {};

export const provideDispatchToApiService = dispatch => {
  apiDispatch = dispatch;
};

export const baseUrl = API_URL;

export const apiUrl = `${baseUrl}/api/v1`;
export const adminUrl = `${baseUrl}/api/v1/admin`;

export const axios = Axios.create();

export const RequestPayloadTooLargeError = Symbol('Payload Too Large');
export const RequestOtherError = Symbol('Other Error');

export type RequestErrorText =
  | typeof RequestPayloadTooLargeError
  | typeof RequestOtherError;

const unauthorizedPaths = [
  '/login',
  '/signup',
  '/ts-signup',
  '/reset',
  '/mfa_route',
  '/funds',
  '/change_password',
  '/resend-new-password',
  '/canva-login',
  '/survey',
  '/unsubscribe',
];

interface FetchOptionsData {
  host?: string;
  unauthorized?: boolean;
  payload: any;
  checkRequestSize?: boolean;
}

type Header = {} | { Authorization: string };

const isCurrentAuthorizedPath = () => {
  const { search, pathname } = window.location;
  return !(
    search.includes('invitationToken') ||
    pathname === '/' ||
    unauthorizedPaths.some(item => pathname.startsWith(item))
  );
};

export const fetch = <O extends object | string | boolean | RequestErrorText>(
  method: Method,
  uri: string,
  options = {} as FetchOptionsData,
  headers?: any,
  cancelToken?: CancelToken,
): Promise<O> => {
  const instance = axios;
  const url = `${options.host || apiUrl}/${uri}`;

  if (options.payload && options.checkRequestSize) {
    const size10MB = 10 * 1024 * 1024;
    const payloadSize = JSON.stringify(options.payload).length;

    if (payloadSize > size10MB) {
      console.error('payload size too large:', payloadSize);
      return Promise.reject(RequestPayloadTooLargeError);
    }
  }

  const response: AxiosPromise<O> = instance({
    method,
    url,
    data: options.payload,
    headers: headers || { Authorization: `Bearer ${StorageService.getAccessToken()}` },
    cancelToken,
  } as AxiosRequestConfig);

  return response
    .then(res => res.data)
    .catch(err => {
      const status = err?.response?.status;
      if (status === 401) {
        if (isCurrentAuthorizedPath()) Toast.error('Unauthorized');

        setTimeout(() => {
          if (isCurrentAuthorizedPath()) window.location.href = '/login';
        }, 1500);
      }
      if (status === 404) {
        if (window.location.pathname !== '/404') window.location.href = '/404';
      }
      throw err;
    });
};
