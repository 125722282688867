export default `
.__react_component_tooltip {
    &.tooltip-info {
        background: #e8e8e8;
        font-size: 12px;
        line-height: 150%;
        box-shadow: 0px 20px 20px rgba(40, 31, 61, 0.02);
        color: var(--black);
        border-radius: 8px;

        &::after {
          border-top-color: #e8e8e8 !important;
          border-right-color: #e8e8e8 !important;
        }
    }

    &.type-light {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
}
`;
