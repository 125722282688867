import React from 'react';
import styled from 'styled-components';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { UploadCustomProspectingExcelRes } from 'interfaces/prospects';

interface Props extends InjectedProps {
  handleAccess: () => void;
  customProspectingResult: UploadCustomProspectingExcelRes | undefined;
}

const CustomProspectingResultsModal = ({
  onClose,
  handleAccess,
  customProspectingResult,
  ...modalProps
}: Props) => {
  useLockBodyScroll();

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 0' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '559px',
        overflow: 'auto',
      }}
    >
      <Body>
        <Title>Search Results</Title>
        <Total>{customProspectingResult?.savedNum} Companies saved to list</Total>
        <Desc>
          The following companies were not found in our database. Click Send to submit the
          list to Accelerist Support to be added to the database.
        </Desc>
        <List>
          {customProspectingResult?.notExistsCompanies.map(item => (
            <div key={item.website}>{item.companyName}</div>
          ))}
        </List>
        <BtnGroup>
          <UIButton title="Close" modifiers={'secondary'} handleClick={onClose} />
          <UIButton title="Send" modifiers={'primary'} handleClick={handleAccess} />
        </BtnGroup>
      </Body>
    </UIModal>
  );
};

const Body = styled.div`
  width: 380px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 24px;
  text-align: center;
`;

const Total = styled.div`
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 16px;
`;

const Desc = styled.div`
  margin-bottom: 45px;
  font-size: 12px;
`;

const List = styled.div`
  height: 100px;
  margin-bottom: 60px;
  font-size: 12px;
  overflow: auto;

  & > div {
    margin-bottom: 19px;
  }
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 56px;
`;

export default CustomProspectingResultsModal;
