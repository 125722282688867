/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AuthMFA } from 'interfaces/auth';
import { useOperation } from 'hooks/common';
import { useHistory, useLocation } from 'react-router';
import { fetchVerifyToken, sendEmailCode } from 'store/ducks/user/operations';
import AuthFormWrapper from 'components/AuthForms/AuthFormWrapper';
import MfaForm from 'components/AuthForms/MfaForm';
import StorageService from 'services/Storage';
import { useDispatch } from 'react-redux';
import * as actions from 'store/ducks/user/actions';

const Mfa = () => {
  const location = useLocation();
  const user = location.state?.user;
  const accessToken = location.state?.accessToken;
  const [onSubmit] = useOperation(fetchVerifyToken);
  const [resendEmail] = useOperation(sendEmailCode);
  const { push } = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      push('/login');
    }
  }, [user, push]);

  const submit = useCallback((values: AuthMFA) => {
    const mergedValues = {
      ...values,
      userId: user.id,
    };
    return onSubmit(mergedValues)
      .then(response => {
        StorageService.setItem('accessToken', accessToken);
        dispatch(actions.setUser(user));
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const handleEmail = useCallback(() => {
    const mergedValues = {
      email: user?.email,
      userId: user.id,
    };
    return resendEmail(mergedValues)
      .then(() => {
        // You can show a notification or toast to inform the user that the email has been resent
        console.log('Email has been resent!');
      })
      .catch(error => {
        console.error('Error resending email:', error);
        // Handle the error appropriately, like showing an error message to the user
      });
  }, []);

  return (
    <>
      <Wrapper>
        <MfaForm onSubmit={submit} onResendEmail={handleEmail} />
      </Wrapper>
      <LinkElem to="/login">Return to Login</LinkElem>
    </>
  );
};

const Wrapper = styled(AuthFormWrapper)`
  margin-bottom: 40px;
`;

const LinkElem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 138px;
  height: 36px;
  font-size: 12px;
  line-height: 150%;
  background: rgba(18, 36, 52, 0.15);
  border-radius: 6px;
  font-weight: 500;
  color: #fff;
  margin: 0 auto 28px;
  &:hover {
    background: rgba(18, 36, 52, 0.25);
  }
`;

export default Mfa;
