import { useModal, useOperation } from 'hooks/common';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import React, { useEffect, useMemo, useState, ChangeEvent } from 'react';
import {
  clearCampaignQRCode,
  fetchCampaignQRCode,
  fetchCampaignShareCode,
  fetchCamPartnersList,
  fetchGiveShopInfo,
  saveConfigDonation,
  updateCampPartners,
} from 'store/ducks/campaign/operations';
import styled, { css } from 'styled-components';
import { CamParListRes, ConfigDonation, headParTable } from 'interfaces/campaign';
import { UIButton, UIModal } from 'components/UI';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSelector } from 'react-redux';
import { getCampaignQRCode, getDonationInfo } from 'store/ducks/campaign/selectors';
import { Field } from 'components/FinalForm';
import {
  FieldCheckbox,
  FieldSingleSelect,
  FieldTextArea,
  FieldTextInput,
  MaskedMoneyTextInput,
} from 'components/FormFields';
import { FormRow } from 'components/UI/form';
import Toast from 'services/Toast';
import { uploadFile } from 'store/ducks/utility/api';
import UploadFileArea from 'components/UploadFileArea';
import { composeValidators, getQueryString } from 'utils';
import {
  CAMPAIN_IMAGE_FILE_EXTENSIONS,
  imageCampainFileValidator,
  integerValidator,
  decimalValidator,
  MaximumValue,
  maxLength,
  maxLength10,
  notQuote,
  notEmptyArray,
  numericalValidator,
  webURLSite,
  maxLength65,
  maxLength165,
  maxLength450,
} from 'utils/validation';
import { Campaigns_COLORS } from 'constants/common';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { getFilenameByPath } from 'utils/formatters';
import PartnersListInfo from './PartnersListInfo';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import PreviewContent from 'components/Fundraising/PreviewContent';
import UIAsterisk from 'components/UI/UIAsterisk';
import createDecorator from 'final-form-focus';
import { ContentBlockWrapper } from 'layouts';
import Jumbotron from 'components/Jumbotron';
import { FormApi, ValidationErrors } from 'final-form';
import CampaignQRCodeModal from './modals/CampaignQRCodeModal';
import FieldColorGroupPickerForCampaign from 'components/Campaign/components/FieldColorsPickerForConsumerFundraising';
import ReceiptMessage from 'components/Campaign/components/FieldColorsPickerForReceiptMessage';
import { format } from 'date-fns';
import NewPreviewContent from 'components/Fundraising/NewPreviewContent';
import FieldColorGroupPickerForEmployeeFundraisign from 'components/Campaign/components/FieldColorsPickerForEmployeeFundraising';
import { getCompanyCurrent } from 'store/ducks/companies/selectors';
import { StateProps } from 'store/interfaces';
import { SearchCompany } from 'interfaces/companies';

interface Props extends InjectedProps {
  isLoading?: boolean;
  // campaignId: string;
  callJumpPreview: () => void;
}

const SelectLabelColors = ({ title, colors }: { title: string; colors: string[] }) => {
  return (
    <SelectLabelColorsWrapper style={{ display: 'flex' }}>
      <span style={{ width: '164px' }}>{title}</span>
      {colors.map((item, i) => (
        <ColorBlock key={i} $color={item} />
      ))}
    </SelectLabelColorsWrapper>
  );
};

const focusOnError = createDecorator();

const GiveandShop = ({
  // campaignId,
  onClose,
  callJumpPreview,
  ...modalProps
}: Props) => {
  const { location, push } = useHistory();
  const initPage = 1;
  const campaignId = getQueryString('campaignId');
  const campaignName = getQueryString('campaignName');
  const tabType = getQueryString('type');
  const list = [
    { label: 'Configure Consumer Fundraising', value: 'Fundraising' },
    { label: 'Manage Partners', value: 'Partners' },
    { label: 'Preview Campaign', value: 'Preview' },
  ];

  const [onFetchCampaign, loading] = useOperation(fetchGiveShopInfo);
  const [submitConfigDonation, , submitConfigDonationLoading] = useOperation(
    saveConfigDonation,
  );
  // Campaign Partners List
  const [getCamPartnersList] = useOperation(fetchCamPartnersList);
  const [saveUpdateCampPartners, , saveUpdateCampPartnersLoading] = useOperation(
    updateCampPartners,
  );
  const [onFetchCampaignQRCode, , isQRCodeLoading] = useOperation(fetchCampaignQRCode);
  const [onFetchCampaignShareCode, , isShareCodeLoading] = useOperation(
    fetchCampaignShareCode,
  );
  const [onClearCampaignQRCode] = useOperation(clearCampaignQRCode);

  const giveandShopDetails = useSelector(getDonationInfo);
  const QRCodeBase64 = useSelector(getCampaignQRCode);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitLoadingSave, setSubmitLoadingSave] = useState(false);
  const [submitLoadingPublish, setSubmitLoadingPublish] = useState(false);
  const [formVules, setFormValue] = useState<any>();
  const [partnersInfo, setPartnerInfo] = useState<CamParListRes | undefined>(undefined);
  const [selectedPartnerIds, setSelectedPartnerIds] = useState<string[]>([]);
  const [uploadedLogoOfPartners, setUploadedLogoOfPartners] = useState<
    { id: string; fileKey: string }[]
  >([]);
  const [tabsIndex, setTabsIndex] = useState(initPage - 1);
  const [formToken, setFormToken] = useState('');
  const [activeTab, setActiveTab] = useState(tabType);
  const [uploadReqField, setUploadReqField] = useState<string | undefined>('');
  const [campaignPartnerId, setCampaignPartnerId] = useState('');
  const [updatedat, setUpdatedat] = useState(new Date());
  const [isShowResidue, setIsShowResidue] = useState(false);
  const [character, setCharacter] = useState(0);

  const [isShowResidue1, setIsShowResidue1] = useState(false);
  const [character1, setCharacter1] = useState(0);
  const [isShowResidue2, setIsShowResidue2] = useState(false);
  const [character2, setCharacter2] = useState(0);
  const [isShowResidue3, setIsShowResidue3] = useState(false);
  const [character3, setCharacter3] = useState(0);
  const [isShowResidue4, setIsShowResidue4] = useState(false);
  const [character4, setCharacter4] = useState(0);
  const [isShowResidue5, setIsShowResidue5] = useState(false);
  const [character5, setCharacter5] = useState(0);
  const [isShowResidue6, setIsShowResidue6] = useState(false);
  const [character6, setCharacter6] = useState(0);
  const [isShowResidue7, setIsShowResidue7] = useState(false);
  const [character7, setCharacter7] = useState(0);
  const [isShowResidue8, setIsShowResidue8] = useState(false);
  const [character8, setCharacter8] = useState(0);

  const [isBattleNet, setIsBattleNet] = useState(false);
  const company = useSelector<StateProps, SearchCompany | undefined>(getCompanyCurrent);

  const getCamParListInfo = (page: number) => {
    getCamPartnersList({ page, campaignId }).then(res => {
      if (!res) return;
      setPartnerInfo(res);
      const data: string[] = [];
      res.items.forEach(item => {
        if (item.selected === 1) data.push(item.id);
      });
      setSelectedPartnerIds(data);
      setUploadedLogoOfPartners([]);
    });
  };

  const handleUploadLogoOfParnter = (id: string, fileKey: string) => {
    setUploadedLogoOfPartners([...uploadedLogoOfPartners, { id, fileKey }]);
  };

  useEffect(() => {
    return () => {
      onClearCampaignQRCode(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCamParListInfo(initPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giveandShopDetails?.status !== 0]);

  useEffect(() => {
    if (company && company['isBattleNet']) {
      setIsBattleNet(true);
    } else {
      setIsBattleNet(false);
    }
    // setIsBattleNet(true);
    if (!campaignId) return;
    onFetchCampaign(campaignId).then(e => {
      if (!e) return;
      if (e['data']?.updatedAt) {
        setUpdatedat(e['data']?.updatedAt);
      }
      setFormToken(e.formToken);
    });
  }, [campaignId, onFetchCampaign, company]);

  const callBackEvent = () => {
    push('/campaign?type=Manage_Campaigns');
  };

  const goToManagePartners = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    hidePublishShopModal();
    const ulr = window.location.href;
    const newUrl = ulr.replace('Fundraising', 'Partners');
    window.location.href = newUrl;
  };

  const submitDonationRequest = (values: ConfigDonation) => {
    values = Object.fromEntries(
      Object.entries(values).map(([key, value]) => [key, value === null ? '' : value]),
    ) as ConfigDonation;
    setSubmitLoading(true);
    if (values.type == 1) {
      setSubmitLoadingSave(true);
    } else {
      setSubmitLoadingPublish(true);
    }
    // if (values.type === '2' && !formVules) return false;
    if (values?.selectCampaignType !== Campaigns_COLORS.Custom_colors.title) {
      Object.values(Campaigns_COLORS).forEach(eles => {
        if (eles.title === values.selectCampaignType) {
          values.selectCampaignColors = eles.value;
        }
      });
    }
    const params = new URLSearchParams();

    params.append('id', campaignId);
    params.append('formToken', formToken);
    Object.keys(values).forEach(key => {
      params.append(key, values[`${key}`]);
    });
    submitConfigDonation(params).then(e => {
      if (e?.status === 200) {
        Toast.success(`${values.type === '1' ? 'Saved' : 'Publish'} successfully.`);
      }
      if (e?.status !== 200) Toast.error(e?.msg);
      if (values.type == 1) {
        setSubmitLoadingSave(false);
      } else {
        setSubmitLoadingPublish(false);
      }
      onFetchCampaign(campaignId).then(e => {
        if (!e) return;
        setFormToken(e.formToken);
      });
      if (e) setSubmitLoading(false);
      values.type === '2' && values.status === 2 && callBackEvent();
      if (values.type == '2') {
        if (values.status == 0 || values.status == 1) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          showPublishModal();
        }
      }
    });
  };

  const [showConfirmModal, hideConfirmShopModal] = useModal(() => {
    return (
      <UIModal
        onClose={hideConfirmShopModal}
        {...modalProps}
        propsBodyCss={{ padding: '40px 0' }}
        hideCloseIcon
        style={{
          width: '100%',
          maxWidth: '516px',
          overflow: 'unset',
        }}
      >
        <ConfirmDiv>
          <HintText>Are you sure you want to leave this page without Saving?</HintText>
          <BtnGroup>
            <Btn
              modifiers="secondary"
              title="Cancel"
              handleClick={hideConfirmShopModal}
            />
            <Btn
              modifiers="primary"
              title="Yes"
              handleClick={() => {
                hideConfirmShopModal();
                callBackEvent();
              }}
            />
          </BtnGroup>
        </ConfirmDiv>
      </UIModal>
    );
  }, []);

  const [showSubmitModal, hideSubmitShopModal] = useModal(() => {
    return (
      <UIModal
        onClose={hideSubmitShopModal}
        {...modalProps}
        propsBodyCss={{ padding: '40px 0' }}
        hideCloseIcon
        style={{
          width: '100%',
          maxWidth: '516px',
          overflow: 'unset',
        }}
      >
        <ConfirmDiv>
          <HintText>
            Once submitted, you will not be able to edit this campaign further. Please
            confirm all information is correct and you wish you proceed.
          </HintText>
          <BtnGroup>
            <Btn modifiers="secondary" title="Cancel" handleClick={hideSubmitShopModal} />
            <Btn
              modifiers="primary"
              title="Publish"
              handleClick={() => {
                submitDonationRequest(formVules);
                hideSubmitShopModal();
              }}
            />
          </BtnGroup>
        </ConfirmDiv>
      </UIModal>
    );
  }, [formVules]);

  const [showQRCodeModal, hideQRCodeModal] = useModal(() => {
    const closeModal = () => {
      hideQRCodeModal();
      setCampaignPartnerId('');
      onClearCampaignQRCode(undefined);
    };
    return (
      <CampaignQRCodeModal
        qrCodeBase64={QRCodeBase64}
        isloading={isShareCodeLoading}
        isQRCodeLoading={isQRCodeLoading}
        campaignName={campaignName}
        onClose={closeModal}
        onSend={values => {
          onFetchCampaignShareCode({ ...values, partnerId: campaignPartnerId });
        }}
      />
    );
  }, [
    QRCodeBase64,
    isQRCodeLoading,
    campaignPartnerId,
    isShareCodeLoading,
    campaignName,
  ]);

  const initialValues = useMemo(
    () => ({
      ...giveandShopDetails,
      donorCoveredFees: giveandShopDetails?.donorCoveredFees === 'true',
      includeCoupon: giveandShopDetails?.includeCoupon === 'true',
      roundUp: giveandShopDetails?.roundUp === 'true',
      selectCampaignType:
        giveandShopDetails?.selectCampaignType ||
        Campaigns_COLORS?.Accelerist_theme?.title,
      shareDonorInformation:
        giveandShopDetails?.shareDonorInformation ||
        'I would like to receive more information about your organization',
      receiptMessage:
        giveandShopDetails?.receiptMessage ||
        'Together we have made an impact in the community.',
      customMessage:
        giveandShopDetails?.customMessage ||
        'The nonprofit organization is dedicated to improving the quality of life for others through outreach, education, and training.',
      receiptEmailMessage:
        giveandShopDetails?.receiptEmailMessage ||
        'Check your email for your donation receipt.',
    }),
    [giveandShopDetails],
  );

  const saveCampParInfo = () => {
    saveUpdateCampPartners({
      campaignId,
      partners: selectedPartnerIds.map(item => ({
        partnerId: item,
        partnerLogo:
          uploadedLogoOfPartners.find(uItem => uItem.id === item)?.fileKey ||
          (partnersInfo &&
            partnersInfo.items.find(pItem => pItem.id === item)?.partnerLogo),
      })),
    }).then(e => {
      if (e.status !== 200) {
        // Toast.error('Error save Partners. Missing PPGF information of NPO.');
        Toast.error(e?.msg);
        return;
      }
      Toast.success('The changes have been saved');
      if (e) {
        callBackEvent();
      }
    });
  };

  const switchTabEvent = key => {
    setActiveTab(key);
    push(
      `${location.pathname}?${queryString.stringify({
        type: key,
        campaignId,
        campaignName,
      })}`,
    );
  };

  const focusErrorField = (
    errors: ValidationErrors,
    setActiveTab: (tabKey: number) => void,
    submit?: () => void,
  ) => {
    if (!errors || !Object.keys(errors).length) return submit?.();

    const errorFields = Object.keys(errors);

    const configureDonationRequestFields = [
      'selectCampaignType',
      'selectCampaignFont',
      'selectCampaignColors',
      'setDonation1',
      'setDonation2',
      'donationRequestMessage',
      'customContentImageName',
      'donorCoveredFees',
      'donorCurrencySetting',
      'donorFixedFees',
      'roundUp',
    ];
    const donationFormFields = [
      'customMessage',
      'donationConfirmation',
      'shareDonorInformation',
      'learnMore',
    ];
    const donationReceiptFields = [
      'donationReceiptHeader',
      'receiptMessage',
      'donationReceiptImage',
    ];
    const donationEmailReceiptFields = [
      'receiptEmailMessage',
      'subject',
      'text',
      'closingStatement',
      'includeCoupon',
      'couponText',
      'couponImage',
      'couponCodes',
      'couponConfirmationMessage',
      'couponEmailReceiptMessage',
    ];

    const changeActiveTab = (key: number) => {
      setActiveTab(key);
      // TODO: REMOVE SUBMIT
      setTimeout(() => submit?.());
    };

    const checkErrorInFields = (fields: string[]) =>
      fields.some(item => errorFields.some(errItem => errItem.includes(item)));

    if (checkErrorInFields(configureDonationRequestFields)) return changeActiveTab(0);

    if (checkErrorInFields(donationFormFields)) return changeActiveTab(1);

    if (checkErrorInFields(donationReceiptFields)) return changeActiveTab(2);

    if (checkErrorInFields(donationEmailReceiptFields)) return changeActiveTab(3);
  };

  const BtnGroupHTML = (form?: FormApi<any>, errors?: ValidationErrors) => {
    const isDisabled = giveandShopDetails?.status === 2;
    return (
      <BtnGroup>
        <Btn
          modifiers="secondary"
          title="Cancel"
          handleClick={() => {
            !isDisabled ? showConfirmModal() : callBackEvent();
          }}
        />
        {activeTab === 'Fundraising' && (
          <div>
            {!isDisabled && (
              <Btn
                modifiers="secondary"
                title="Save"
                handleClick={() => {
                  form?.change('type', '1');
                  focusErrorField(errors, setTabsIndex, form?.submit);
                }}
                isLoading={submitLoadingSave}
              />
            )}
            {tabsIndex < 3 && (
              <Btn
                modifiers="secondary"
                title="Next"
                handleClick={() => {
                  setTabsIndex(tabsIndex + 1);
                }}
              />
            )}
            <Btn
              modifiers="primary"
              // title="Publish"
              title={isDisabled ? 'Republish' : 'Publish'}
              isLoading={submitLoadingPublish}
              handleClick={() => {
                form?.change('type', '2');
                focusErrorField(errors, setTabsIndex, form?.submit);
              }}
              style={{ margin: '0 12px' }}
              // disabled={isDisabled}
            />
          </div>
        )}
        {activeTab === 'Partners' && (
          <Btn
            modifiers="primary"
            title="Save"
            isLoading={saveUpdateCampPartnersLoading}
            handleClick={() => {
              saveCampParInfo();
            }}
          />
        )}
      </BtnGroup>
    );
  };

  const [showPublishModal, hidePublishShopModal] = useModal(() => {
    return (
      <UIModal
        onClose={hidePublishShopModal}
        hideCloseIcon={true}
        {...modalProps}
        propsBodyCss={{ padding: '40px 24px 24px' }}
        style={{
          width: '100%',
          maxWidth: 333,
        }}
      >
        <Title>Consumer Fundraising Campaign Published</Title>
        <Description className="description">
          You will now be redirected to the Manage Partners page where you will need to
          add at least 1 fundraising partner.
        </Description>
        <ButtonGroup>
          <Button
            style={{ width: '105px' }}
            handleClick={hidePublishShopModal}
            modifiers={'secondary'}
            title={'Close'}
          />
          <Button
            style={{ width: '93px' }}
            handleClick={goToManagePartners}
            modifiers={'primary'}
            title={'OK'}
          />
        </ButtonGroup>
      </UIModal>
    );
  }, [formVules]);

  return (
    <ContentBlockWrapper
      header={
        <Jumbotron
          name="Consumer Fundraising"
          back
          backPath="/campaign?type=Manage_Campaigns"
        />
      }
      loading={loading}
    >
      <Body>
        <Root>
          <List>
            {list.map(({ label, value }, index) => {
              if (value === 'Partners' && giveandShopDetails?.status !== 2) return false;
              if (value === 'Preview' && giveandShopDetails?.status === 0) return false;
              return (
                <Item key={index} onClick={() => switchTabEvent(value)}>
                  <StyledLink $isActive={activeTab === value}>{label}</StyledLink>
                </Item>
              );
            })}
            <LastUpdate>
              <span style={{ fontSize: '12px', color: '#737373' }}>Last Update </span>
              <span style={{ fontSize: '12px', color: '#122434' }}>
                &nbsp;
                {/* {format(getUTCAsLocalDate(updatedat), 'MMM dd yyyy')} */}
                {format(new Date(updatedat), 'MMM dd yyyy')}
              </span>
            </LastUpdate>
          </List>
        </Root>
        {activeTab === 'Fundraising' && (
          <FundraisingHTML>
            <Form
              initialValues={initialValues}
              onSubmit={(values: ConfigDonation) => {
                if (values.type === '1') {
                  submitDonationRequest(values);
                  return;
                }
                const requiredFields = [
                  'selectCampaignType',
                  'selectCampaignFont',
                  'setDonation1',
                  'setDonation2',
                  'donationRequestMessage',
                  'customContentImageName',
                  'donationConfirmation',
                  'learnMore',
                  'donationReceiptHeader',
                  'subject',
                  ...(values.includeCoupon ? ['couponText', 'couponImage'] : []),
                  ...(values.donorCoveredFees
                    ? // ? ['donorCurrencySetting', 'donorFixedFees']
                      []
                    : []),
                ];

                const validateErrors: { [k: string]: string } = {};
                requiredFields.forEach(item => {
                  if (!values[item]) validateErrors[item] = 'Required';
                });

                if (values.selectCampaignType === Campaigns_COLORS.Custom_colors.title) {
                  const selectCampaignColorsNotEmpty = notEmptyArray(isBattleNet ? 2 : 4)(
                    values.selectCampaignColors,
                  );

                  if (selectCampaignColorsNotEmpty)
                    validateErrors['selectCampaignColors'] = selectCampaignColorsNotEmpty;
                }

                if (Object.keys(validateErrors).length) {
                  focusErrorField(validateErrors, setTabsIndex);
                  return validateErrors;
                }

                setFormValue(values);
                // showSubmitModal();
                submitDonationRequest(values);
              }}
              decorators={[focusOnError]}
              render={({ form, values, errors, submitErrors }) => {
                const isDisabled = giveandShopDetails?.status === 2;
                return (
                  <form onSubmit={e => e.preventDefault()}>
                    <Tabs
                      style={{ padding: 0 }}
                      onSelect={index => setTabsIndex(index)}
                      selectedIndex={tabsIndex}
                      forceRenderTabPanel
                    >
                      <TabList style={{ borderBottom: 'none' }}>
                        <Tab>Configure Donation Request</Tab>
                        <Tab>Donation Form</Tab>
                        <Tab>Donation Receipt</Tab>
                        <Tab>Donation Email Receipt</Tab>
                      </TabList>
                      <TabPanel
                        style={{
                          display: tabsIndex === 0 ? 'block' : 'none',
                        }}
                      >
                        <FormStyled>
                          <FormRow>
                            {isBattleNet ? (
                              <Field
                                name="selectCampaignType"
                                label={<>Select Campaign Colors</>}
                                component={FieldTextInput}
                                initialValue="Custom colors"
                                disabled={true}
                              />
                            ) : (
                              <Field
                                name="selectCampaignType"
                                label={
                                  <>
                                    Select Campaign Colors
                                    <UIAsterisk />
                                  </>
                                }
                                component={FieldSingleSelect}
                                options={Object.values(Campaigns_COLORS).map(item => ({
                                  label: (
                                    <SelectLabelColors
                                      title={item.title}
                                      colors={item.value}
                                    />
                                  ),
                                  value: item.title,
                                }))}
                                // disabled={isDisabled}
                              />
                            )}
                            <OnChange name="selectCampaignType">
                              {(value, previous) => {
                                const title = Campaigns_COLORS?.Custom_colors.title;
                                if (value === previous) return;
                                if (value === title) {
                                  form.change(
                                    'selectCampaignColors',
                                    values?.selectCampaignColors
                                      ? values?.selectCampaignColors
                                      : [],
                                  );

                                  const colorPickerBlockEl = document.querySelector(
                                    '#color-group-picker-for-campaign-color-block-0',
                                  ) as HTMLElement | undefined;
                                  if (colorPickerBlockEl) colorPickerBlockEl.click();
                                }
                              }}
                            </OnChange>
                            <Field
                              name="selectCampaignFont"
                              label={
                                <>
                                  Select Campaign Font
                                  <UIAsterisk />
                                </>
                              }
                              component={FieldSingleSelect}
                              options={
                                isBattleNet
                                  ? [
                                      { label: 'Arial', value: 'Arial' },
                                      { label: 'Helvetica', value: 'Helvetica' },
                                      {
                                        label: 'Times New Roman',
                                        value: 'Times New Roman',
                                      },
                                      { label: 'Verdana', value: 'Verdana' },
                                      { label: 'Calibri', value: 'Calibri' },
                                      { label: 'Lato', value: 'Lato' },
                                      { label: 'Roboto', value: 'Roboto' },
                                      {
                                        label: 'Roboto Condensed',
                                        value: 'Roboto Condensed',
                                      },
                                      { label: 'Merriweather', value: 'Merriweather' },
                                      { label: 'Nunito', value: 'Nunito' },
                                      { label: 'Oswald', value: 'Oswald' },
                                      { label: 'DIN OT', value: 'DIN OT' },
                                    ]
                                  : [
                                      { label: 'Arial', value: 'Arial' },
                                      { label: 'Helvetica', value: 'Helvetica' },
                                      {
                                        label: 'Times New Roman',
                                        value: 'Times New Roman',
                                      },
                                      { label: 'Verdana', value: 'Verdana' },
                                      { label: 'Calibri', value: 'Calibri' },
                                      { label: 'Lato', value: 'Lato' },
                                      { label: 'Roboto', value: 'Roboto' },
                                      {
                                        label: 'Roboto Condensed',
                                        value: 'Roboto Condensed',
                                      },
                                      { label: 'Merriweather', value: 'Merriweather' },
                                      { label: 'Nunito', value: 'Nunito' },
                                    ]
                              }
                              defaultValue={'Arial'}
                            />
                          </FormRow>
                          <FormRow>
                            {values.selectCampaignType ===
                            Campaigns_COLORS.Custom_colors.title ? (
                              <Field
                                name="selectCampaignColors"
                                component={
                                  isBattleNet
                                    ? FieldColorGroupPickerForEmployeeFundraisign
                                    : FieldColorGroupPickerForCampaign
                                }
                                // disabled={isDisabled}
                              />
                            ) : (
                              <span />
                            )}
                          </FormRow>
                          <FormRow>
                            <DollarInputWrapper>
                              <Field
                                name="setDonation1"
                                label={
                                  <>
                                    Set Donation Amounts
                                    <UIAsterisk />
                                  </>
                                }
                                component={MaskedMoneyTextInput}
                                validate={composeValidators(
                                  integerValidator,
                                  maxLength(4),
                                )}
                                hidePrefix
                                // disabled={isDisabled}
                              />
                              <DollarSubfix>$</DollarSubfix>
                            </DollarInputWrapper>
                            <DollarInputWrapper>
                              <Field
                                name="setDonation2"
                                label={
                                  <>
                                    Set Donation Amounts
                                    <UIAsterisk />
                                  </>
                                }
                                component={MaskedMoneyTextInput}
                                validate={composeValidators(
                                  integerValidator,
                                  maxLength(4),
                                )}
                                hidePrefix
                                // disabled={isDisabled}
                              />
                              <DollarSubfix>$</DollarSubfix>
                            </DollarInputWrapper>
                          </FormRow>
                          <Field
                            name="donationRequestMessage"
                            label={
                              <>
                                Donation Request Message
                                <UIAsterisk />
                              </>
                            }
                            component={FieldTextArea}
                            placeholder="Maximum limit: 515 characters"
                            validate={maxLength(515)}
                            onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value) {
                                setCharacter(515 - e.target.value.length);
                              } else {
                                setCharacter(515);
                              }
                              setIsShowResidue(true);
                            }}
                            onBlur={() => {
                              setIsShowResidue(false);
                            }}
                            parse={(v: string) => {
                              setCharacter(515 - v.length < 0 ? 0 : 515 - v.length);
                              if (v.length >= 515) {
                                return v.substring(0, 515);
                              }
                              return v;
                            }}
                            // disabled={isDisabled}
                          />
                          {isShowResidue && (
                            <Residue>
                              <div></div>
                              <div style={{ color: '#656469', fontSize: '12px' }}>
                                {character} characters remaining
                              </div>
                            </Residue>
                          )}

                          <FieldTitle>
                            Custom Content
                            <UIAsterisk />
                          </FieldTitle>
                          {
                            <UploadFileArea
                              name="Upload File"
                              accept=".jpg,.jpeg,.png,.bmp,.svg,.mp4"
                              inputId="campaign-custom-content"
                              filelist={
                                values.customContentImageName
                                  ? [
                                      {
                                        label: getFilenameByPath(
                                          values.customContentImageName,
                                        ),
                                        value: values.customContentImageName,
                                      },
                                    ]
                                  : undefined
                              }
                              desc="You may upload an image (*.png,*.jpeg,*.jpg,*.bmp,*.svg) or video in mp4. File size limit is 5 Mb."
                              onUpload={files => {
                                if (!files.length) return;
                                const file = files[0];
                                const validationResult = CAMPAIN_IMAGE_FILE_EXTENSIONS.concat(
                                  ['mp4'],
                                ).includes(
                                  file.name
                                    .split('.')
                                    .pop()
                                    ?.toLowerCase() || '',
                                );
                                if (!validationResult) return false;
                                uploadFile(files[0], 'roi-report').then(v => {
                                  if (v.status === 'success') {
                                    form.change('customContentType', files[0].type);
                                    form.change('customContentImageName', v.fileKey);
                                    setUploadReqField('');
                                    return Toast.success('File uploaded successfully');
                                  }
                                  Toast.error('Error upload file');
                                });
                              }}
                              onDeleteFile={fileKey => {
                                form.change('customContentType', '');
                                form.change('customContentImageName', '');
                              }}
                              // disabled={isDisabled}
                              isRequired={
                                !!submitErrors?.['customContentImageName'] &&
                                !values['customContentImageName']
                              }
                            />
                          }
                          <CheckboxStyled>
                            <Field
                              name="donorCoveredFees"
                              label="Cover Donor Fees"
                              component={FieldCheckbox}
                              labelCss={css`
                                font-size: 12px;
                              `}
                              // disabled={isDisabled}
                            />
                          </CheckboxStyled>
                          <OnChange name="donorCoveredFees">
                            {(value, previous) => {
                              if (value === previous) return;
                              if (!value) {
                                form.change('donorCurrencySetting', '');
                                form.change('donorFixedFees', '');
                              }
                            }}
                          </OnChange>
                          <FormRow>
                            <DollarInputWrapper>
                              <Field
                                name="donorCurrencySetting"
                                label="Select an option to enter a Variable Fee or a Fixed Fee"
                                component={FieldSingleSelect}
                                options={[
                                  { label: 'Variable Fee', value: 'VariableFee' },
                                  { label: 'Fixed Fee', value: 'FixedFee' },
                                ]}
                                // disabled={isDisabled || !values.donorCoveredFees}
                                // disabled={!values.donorCoveredFees}
                                disabled={true}
                              />
                            </DollarInputWrapper>
                            <OnChange name="donorCurrencySetting">
                              {(value, previous) => {
                                if (value === previous) return;
                                if (value !== previous) form.change('donorFixedFees', '');
                              }}
                            </OnChange>
                            <DollarInputWrapper>
                              <Field
                                name="donorFixedFees"
                                label={`Enter a value (${
                                  values?.donorCurrencySetting === 'VariableFee'
                                    ? '%'
                                    : '$'
                                }) The value cannot exceed ${
                                  values?.donorCurrencySetting === 'VariableFee'
                                    ? 6
                                    : 9.99
                                }`}
                                component={MaskedMoneyTextInput}
                                hidePrefix
                                validate={composeValidators(e =>
                                  numericalValidator(
                                    e,
                                    values?.donorCurrencySetting === 'VariableFee'
                                      ? 6
                                      : 9.99,
                                  ),
                                )}
                                // disabled={isDisabled || !values.donorCoveredFees}
                                // disabled={!values.donorCoveredFees}
                                disabled={true}
                              />
                              <DollarSubfix>
                                {values?.donorCurrencySetting === 'VariableFee'
                                  ? '%'
                                  : '$'}
                              </DollarSubfix>
                            </DollarInputWrapper>
                          </FormRow>
                          <CheckboxStyled>
                            <Field
                              name="roundUp"
                              label="Enable RoundUp"
                              component={FieldCheckbox}
                              labelCss={css`
                                font-size: 12px;
                              `}
                              // disabled={isDisabled}
                            />
                          </CheckboxStyled>
                        </FormStyled>
                      </TabPanel>
                      <TabPanel
                        style={{
                          display: tabsIndex === 1 ? 'block' : 'none',
                        }}
                      >
                        <FormStyled>
                          <Field
                            name="customMessage"
                            label="Donation Form Custom Message"
                            component={FieldTextArea}
                            // disabled={isDisabled}
                            placeholder="Maximum limit: 655 characters"
                            validate={maxLength(655)}
                            onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value) {
                                setCharacter(655 - e.target.value.length);
                              } else {
                                setCharacter(655);
                              }
                              setIsShowResidue(true);
                            }}
                            onBlur={() => {
                              setIsShowResidue(false);
                            }}
                            parse={(v: string) => {
                              setCharacter(655 - v.length < 0 ? 0 : 655 - v.length);
                              if (v.length >= 655) {
                                return v.substring(0, 655);
                              }
                              return v;
                            }}
                          />
                          {isShowResidue && (
                            <Residue>
                              <div></div>
                              <div style={{ color: '#656469', fontSize: '12px' }}>
                                {character} characters remaining
                              </div>
                            </Residue>
                          )}
                          <Field
                            name="donationConfirmation"
                            label={
                              <>
                                Donation Confirmation
                                <UIAsterisk />
                              </>
                            }
                            component={FieldTextInput}
                            validate={maxLength(85)}
                            onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value) {
                                setCharacter1(85 - e.target.value.length);
                              } else {
                                setCharacter1(85);
                              }
                              setIsShowResidue1(true);
                            }}
                            onBlur={() => {
                              setIsShowResidue1(false);
                            }}
                            parse={(v: string) => {
                              setCharacter1(85 - v.length < 0 ? 0 : 85 - v.length);
                              if (v.length >= 85) {
                                return v.substring(0, 85);
                              }
                              return v;
                            }}
                            // disabled={isDisabled}
                          />
                          {isShowResidue1 && (
                            <Residue>
                              <div></div>
                              <div style={{ color: '#656469', fontSize: '12px' }}>
                                {character1} characters remaining
                              </div>
                            </Residue>
                          )}
                          <Field
                            name="shareDonorInformation"
                            label={
                              <>
                                How would you like to ask your donors for their
                                information?
                              </>
                            }
                            component={FieldTextInput}
                            validate={maxLength(100)}
                            onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value) {
                                setCharacter2(100 - e.target.value.length);
                              } else {
                                setCharacter2(100);
                              }
                              setIsShowResidue2(true);
                            }}
                            onBlur={() => {
                              setIsShowResidue2(false);
                            }}
                            parse={(v: string) => {
                              setCharacter2(100 - v.length < 0 ? 0 : 100 - v.length);
                              if (v.length >= 100) {
                                return v.substring(0, 100);
                              }
                              return v;
                            }}
                            // disabled={isDisabled}
                            placeholder="Would you like to share your information?"
                          />
                          {isShowResidue2 && (
                            <Residue>
                              <div></div>
                              <div style={{ color: '#656469', fontSize: '12px' }}>
                                {character2} characters remaining
                              </div>
                            </Residue>
                          )}
                          <Field
                            name="learnMore"
                            label={
                              <>
                                Where would you like to direct your donors to learn more
                                about your organization?
                                <UIAsterisk />
                              </>
                            }
                            component={FieldTextInput}
                            validate={composeValidators<string>(webURLSite)}
                            // disabled={isDisabled}
                            placeholder="Enter a URL to direct your donors to learn more about your organization."
                          />
                        </FormStyled>
                      </TabPanel>
                      <TabPanel
                        style={{
                          display: tabsIndex === 2 ? 'block' : 'none',
                        }}
                      >
                        <FormStyled>
                          <Field
                            name="donationReceiptHeader"
                            label={
                              <>
                                Donation Receipt Header
                                <UIAsterisk />
                              </>
                            }
                            component={FieldTextInput}
                            validate={maxLength(425)}
                            onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value) {
                                setCharacter1(425 - e.target.value.length);
                              } else {
                                setCharacter1(425);
                              }
                              setIsShowResidue1(true);
                            }}
                            onBlur={() => {
                              setIsShowResidue1(false);
                            }}
                            parse={(v: string) => {
                              setCharacter1(425 - v.length < 0 ? 0 : 425 - v.length);
                              if (v.length >= 425) {
                                return v.substring(0, 425);
                              }
                              return v;
                            }}
                            // disabled={isDisabled}
                          />
                          {isShowResidue1 && (
                            <Residue>
                              <div></div>
                              <div style={{ color: '#656469', fontSize: '12px' }}>
                                {character1} characters remaining
                              </div>
                            </Residue>
                          )}
                          <Field
                            name="receiptMessage"
                            label={<>Receipt Message</>}
                            component={FieldTextInput}
                            validate={maxLength(200)}
                            // disabled={isDisabled}
                            onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value) {
                                setCharacter(200 - e.target.value.length);
                              } else {
                                setCharacter(200);
                              }
                              setIsShowResidue(true);
                            }}
                            onBlur={() => {
                              setIsShowResidue(false);
                            }}
                            parse={(v: string) => {
                              setCharacter(200 - v.length < 0 ? 0 : 200 - v.length);
                              if (v.length >= 200) {
                                return v.substring(0, 200);
                              }
                              return v;
                            }}
                          />
                          {isShowResidue && (
                            <Residue>
                              <div></div>
                              <div style={{ color: '#656469', fontSize: '12px' }}>
                                {character} characters remaining
                              </div>
                            </Residue>
                          )}
                          <Field
                            label={<>Receipt Message Color</>}
                            name="receiptMessageFontColor"
                            component={ReceiptMessage}
                            // disabled={disable}
                          />
                          <FieldTitle>Donation Receipt Image</FieldTitle>
                          {
                            <UploadFileArea
                              name="Upload File"
                              accept=".jpg,.jpeg,.png,.bmp,.svg"
                              inputId="donation-receipt-image-upload-input"
                              filelist={
                                values.donationReceiptImage
                                  ? [
                                      {
                                        label: getFilenameByPath(
                                          values.donationReceiptImage,
                                        ),
                                        value: values.donationReceiptImage,
                                      },
                                    ]
                                  : undefined
                              }
                              desc="You may upload an image in *.png.*.jpeg.*.jpg.*.bmp.*.svg. File size limit is 5 Mb."
                              onUpload={files => {
                                if (!files.length) return;
                                if (!imageCampainFileValidator(files[0].name))
                                  return false;
                                uploadFile(files[0], 'roi-report').then(v => {
                                  if (v.status === 'success') {
                                    form.change('donationReceiptImage', v.fileKey);
                                    setUploadReqField('');
                                    return Toast.success('File uploaded successfully');
                                  }
                                  Toast.error('Error upload file');
                                });
                              }}
                              onDeleteFile={() => {
                                form.change('donationReceiptImage', '');
                              }}
                              // disabled={isDisabled}
                              isRequired={uploadReqField === 'donationReceiptImage'}
                            />
                          }
                        </FormStyled>
                      </TabPanel>
                      <TabPanel
                        style={{
                          display: tabsIndex === 3 ? 'block' : 'none',
                        }}
                      >
                        <FormStyled>
                          <Field
                            name="receiptEmailMessage"
                            label={<>Receipt Email Header</>}
                            component={FieldTextInput}
                            // disabled={isDisabled}
                            placeholder="What would you like the receipt to say?"
                            validate={maxLength(250)}
                            onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value) {
                                setCharacter3(250 - e.target.value.length);
                              } else {
                                setCharacter3(250);
                              }
                              setIsShowResidue3(true);
                            }}
                            onBlur={() => {
                              setIsShowResidue3(false);
                            }}
                            parse={(v: string) => {
                              setCharacter3(250 - v.length < 0 ? 0 : 250 - v.length);
                              if (v.length >= 250) {
                                return v.substring(0, 250);
                              }
                              return v;
                            }}
                          />
                          {isShowResidue3 && (
                            <Residue>
                              <div></div>
                              <div style={{ color: '#656469', fontSize: '12px' }}>
                                {character3} characters remaining
                              </div>
                            </Residue>
                          )}

                          <Field
                            name="subject"
                            label={
                              <>
                                Subject
                                <UIAsterisk />
                              </>
                            }
                            component={FieldTextInput}
                            validate={maxLength(50)}
                            onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value) {
                                setCharacter4(50 - e.target.value.length);
                              } else {
                                setCharacter4(50);
                              }
                              setIsShowResidue4(true);
                            }}
                            onBlur={() => {
                              setIsShowResidue4(false);
                            }}
                            parse={(v: string) => {
                              setCharacter4(50 - v.length < 0 ? 0 : 50 - v.length);
                              if (v.length >= 50) {
                                return v.substring(0, 50);
                              }
                              return v;
                            }}
                            // disabled={isDisabled}
                          />
                          {isShowResidue4 && (
                            <Residue>
                              <div></div>
                              <div style={{ color: '#656469', fontSize: '12px' }}>
                                {character4} characters remaining
                              </div>
                            </Residue>
                          )}
                          <Field
                            name="text"
                            label={<>Text</>}
                            component={FieldTextArea}
                            validate={maxLength(450)}
                            // disabled={isDisabled}
                            onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value) {
                                setCharacter(450 - e.target.value.length);
                              } else {
                                setCharacter(450);
                              }
                              setIsShowResidue(true);
                            }}
                            onBlur={() => {
                              setIsShowResidue(false);
                            }}
                            parse={(v: string) => {
                              setCharacter(450 - v.length < 0 ? 0 : 450 - v.length);
                              if (v.length >= 450) {
                                return v.substring(0, 450);
                              }
                              return v;
                            }}
                          />
                          {isShowResidue && (
                            <Residue>
                              <div></div>
                              <div style={{ color: '#656469', fontSize: '12px' }}>
                                {character} characters remaining
                              </div>
                            </Residue>
                          )}

                          <Field
                            name="closingStatement"
                            label="Closing Statement"
                            component={FieldTextInput}
                            // disabled={isDisabled}
                            validate={maxLength(500)}
                            onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value) {
                                setCharacter5(500 - e.target.value.length);
                              } else {
                                setCharacter5(500);
                              }
                              setIsShowResidue5(true);
                            }}
                            onBlur={() => {
                              setIsShowResidue5(false);
                            }}
                            parse={(v: string) => {
                              setCharacter5(500 - v.length < 0 ? 0 : 500 - v.length);
                              if (v.length >= 500) {
                                return v.substring(0, 500);
                              }
                              return v;
                            }}
                          />
                          {isShowResidue5 && (
                            <Residue>
                              <div></div>
                              <div style={{ color: '#656469', fontSize: '12px' }}>
                                {character5} characters remaining
                              </div>
                            </Residue>
                          )}

                          <CheckboxStyled>
                            <Field
                              name="includeCoupon"
                              label="Include a Coupon with my Campaign"
                              component={FieldCheckbox}
                              labelCss={css`
                                font-size: 12px;
                              `}
                              // disabled={isDisabled}
                            />
                          </CheckboxStyled>
                          <OnChange name="includeCoupon">
                            {(value, previous) => {
                              if (value === previous) return;
                              if (!value) {
                                form.change('couponText', '');
                                form.change('couponImage', '');
                                form.change('couponCodes', '');
                                form.change('couponConfirmationMessage', '');
                                form.change('couponEmailReceiptMessage', '');
                                setUploadReqField('');
                              }
                            }}
                          </OnChange>
                          <Field
                            name="couponText"
                            label={
                              <>
                                Coupon Text
                                {values.includeCoupon && <UIAsterisk />}
                              </>
                            }
                            component={FieldTextInput}
                            disabled={!values.includeCoupon}
                            validate={maxLength(150)}
                            onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value) {
                                setCharacter6(150 - e.target.value.length);
                              } else {
                                setCharacter6(150);
                              }
                              setIsShowResidue6(true);
                            }}
                            onBlur={() => {
                              setIsShowResidue6(false);
                            }}
                            parse={(v: string) => {
                              setCharacter6(150 - v.length < 0 ? 0 : 150 - v.length);
                              if (v.length >= 150) {
                                return v.substring(0, 150);
                              }
                              return v;
                            }}
                          />
                          {isShowResidue6 && (
                            <Residue>
                              <div></div>
                              <div style={{ color: '#656469', fontSize: '12px' }}>
                                {character6} characters remaining
                              </div>
                            </Residue>
                          )}
                          <FieldTitle>
                            Coupon Image
                            {values.includeCoupon && <UIAsterisk />}
                          </FieldTitle>
                          <UploadFileArea
                            name="Upload File"
                            accept=".jpg,.jpeg,.png,.bmp,.svg"
                            inputId="campaign-coupon-image"
                            filelist={
                              values.couponImage
                                ? [
                                    {
                                      label: getFilenameByPath(values.couponImage),
                                      value: values.couponImage,
                                    },
                                  ]
                                : undefined
                            }
                            desc="You may upload an image in *.png.*.jpeg.*.jpg.*.bmp.*.svg. File size limit is 5 Mb."
                            onUpload={files => {
                              if (!files.length) return;
                              if (!imageCampainFileValidator(files[0].name)) return false;
                              uploadFile(files[0], 'roi-report').then(v => {
                                if (v.status === 'success') {
                                  form.change('couponImage', v.fileKey);
                                  setUploadReqField('');
                                  return Toast.success('File uploaded successfully');
                                }
                                Toast.error('Error upload file');
                              });
                            }}
                            onDeleteFile={() => {
                              form.change('couponImage', '');
                            }}
                            // disabled={!values.includeCoupon}
                            isRequired={uploadReqField === 'couponImage'}
                          />
                          <Field
                            name="couponCodes"
                            label="Coupon Codes"
                            component={FieldTextInput}
                            placeholder="Maximum limit: 10 characters"
                            validate={composeValidators(maxLength10, notQuote)}
                            disabled={!values.includeCoupon}
                            onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value) {
                                setCharacter1(10 - e.target.value.length);
                              } else {
                                setCharacter1(10);
                              }
                              setIsShowResidue1(true);
                            }}
                            onBlur={() => {
                              setIsShowResidue1(false);
                            }}
                            parse={(v: string) => {
                              setCharacter1(10 - v.length < 0 ? 0 : 10 - v.length);
                              if (v.length >= 10) {
                                return v.substring(0, 10);
                              }
                              return v;
                            }}
                          />
                          {isShowResidue1 && (
                            <Residue>
                              <div></div>
                              <div style={{ color: '#656469', fontSize: '12px' }}>
                                {character1} characters remaining
                              </div>
                            </Residue>
                          )}
                          {/* <FieldTitle>Coupon Codes</FieldTitle> */}
                          {/* <UploadFileArea
                            name="Upload File"
                            accept=".csv,.xlsx"
                            inputId="campaign-coupon-codes"
                            filelist={
                              values.couponCodes
                                ? [
                                    {
                                      label: getFilenameByPath(values.couponCodes),
                                      value: values.couponCodes,
                                    },
                                  ]
                                : undefined
                            }
                            desc="You may upload a csv or xlsx format."
                            onUpload={files => {
                              if (!files.length) return;
                              const validationResult = ['csv', 'xlsx'].includes(
                                files[0].name
                                  .split('.')
                                  .pop()
                                  ?.toLowerCase() || '',
                              );
                              if (!validationResult) return false;
                              uploadFile(files[0], 'roi-report').then(v => {
                                if (v.status === 'success') {
                                  form.change('couponCodes', v.fileKey);
                                  return Toast.success('File uploaded successfully');
                                }
                                Toast.error('Error upload file');
                              });
                            }}
                            onDeleteFile={() => {
                              form.change('couponCodes', '');
                            }}
                            disabled={!values.includeCoupon}
                          /> */}
                          {/* <DownloadTemplate fetchFile={fetchDownloadConsumerTemplate} /> */}
                          <Field
                            name="couponConfirmationMessage"
                            label="Coupon Confirmation Message"
                            component={FieldTextInput}
                            disabled={!values.includeCoupon}
                            validate={maxLength(100)}
                            onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value) {
                                setCharacter7(100 - e.target.value.length);
                              } else {
                                setCharacter7(100);
                              }
                              setIsShowResidue7(true);
                            }}
                            onBlur={() => {
                              setIsShowResidue7(false);
                            }}
                            parse={(v: string) => {
                              setCharacter7(100 - v.length < 0 ? 0 : 100 - v.length);
                              if (v.length >= 100) {
                                return v.substring(0, 100);
                              }
                              return v;
                            }}
                          />
                          {isShowResidue7 && (
                            <Residue>
                              <div></div>
                              <div style={{ color: '#656469', fontSize: '12px' }}>
                                {character7} characters remaining
                              </div>
                            </Residue>
                          )}
                          <Field
                            name="couponEmailReceiptMessage"
                            label="Coupon Email Receipt Message"
                            component={FieldTextArea}
                            disabled={!values.includeCoupon}
                            validate={maxLength(100)}
                            onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value) {
                                setCharacter8(100 - e.target.value.length);
                              } else {
                                setCharacter8(100);
                              }
                              setIsShowResidue8(true);
                            }}
                            onBlur={() => {
                              setIsShowResidue8(false);
                            }}
                            parse={(v: string) => {
                              setCharacter8(100 - v.length < 0 ? 0 : 100 - v.length);
                              if (v.length >= 100) {
                                return v.substring(0, 100);
                              }
                              return v;
                            }}
                          />
                          {isShowResidue8 && (
                            <Residue>
                              <div></div>
                              <div style={{ color: '#656469', fontSize: '12px' }}>
                                {character8} characters remaining
                              </div>
                            </Residue>
                          )}
                        </FormStyled>
                      </TabPanel>
                    </Tabs>
                    {BtnGroupHTML(form, errors)}
                  </form>
                );
              }}
            />
          </FundraisingHTML>
        )}
        {activeTab === 'Partners' && (
          <TabContent>
            <PartnersListInfo
              isPreview={giveandShopDetails?.status === 2}
              callCamParListInfo={getCamParListInfo}
              onPartnerSelect={ids => setSelectedPartnerIds(ids)}
              selectedPartnerIds={selectedPartnerIds}
              headCells={headParTable}
              rows={partnersInfo?.items || []}
              onClickViewQRCode={campaignPartnerId => {
                onFetchCampaignQRCode(campaignPartnerId);
                setCampaignPartnerId(campaignPartnerId);
                showQRCodeModal();
              }}
              onUploadLogo={handleUploadLogoOfParnter}
            />
            {BtnGroupHTML(undefined)}
          </TabContent>
        )}
        {activeTab === 'Preview' && <PreviewContent />}
      </Body>
    </ContentBlockWrapper>
  );
};

const Root = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 32px;
`;
const TabContent = styled.div`
  background: #fff;
  padding: 24px;
`;
const List = styled.ul`
  display: flex;
  height: 69px;
  width: 100%;
  background: #fff;
  border-radius: 6px;
  padding: 0 24px;
`;
const Item = styled.li`
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 22px;
  }
`;
const FundraisingHTML = styled.div`
  background: #fff;
  padding: 30px;
`;
const StyledLink = styled.div<{
  $isActive: boolean;
}>`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 155%;
  border-bottom: 3px solid transparent;
  transition: border-color 0.5s ease, color 0.5s ease;

  ${({ $isActive }) =>
    $isActive &&
    `
    color: var(--blue);
    border-color: var(--blue);
  `}

  &:hover {
    color: var(--blue);
    border-color: var(--blue);
  }
`;

const Body = styled.div`
  max-width: 1120px;
  padding-bottom: 64px;
`;
const FieldTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 145%;
  color: #737373;
  margin: 0px auto 4px;
  font-family: 'Rubik';
`;

const DollarInputWrapper = styled.div`
  position: relative;
`;
const DollarSubfix = styled.div`
  position: absolute;
  right: 12px;
  top: 34px;
`;

const FormStyled = styled.div`
  background: #ffffff;
  border-radius: 4px;
  /* padding: 10px; */
`;

const ColorBlock = styled.div<{ $color: string }>`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-left: 8px;

  background-color: ${({ $color }) => $color};
`;

const SelectLabelColorsWrapper = styled.div`
  display: flex;
`;

const CheckboxStyled = styled.div`
  margin-bottom: 42px;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding: 0;

  & > div {
    button:not(:first-child) {
      margin-left: 12px;
    }
  }
`;

const Btn = styled(UIButton)`
  font-size: 14px;
  padding: 7px 24px;
`;

const ConfirmDiv = styled.div`
  text-align: center;
  width: 86%;
  margin: auto;
`;

const HintText = styled.div`
  margin-bottom: 68px;
`;

const LastUpdate = styled.div`
  position: absolute;
  margin-left: 919px;
  margin-top: 24px;
`;
const Description = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  width: 256px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 25px;
  margin-left: 15px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #122434;
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;

  button:first-child {
    margin-right: 8px;
  }
`;
const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  width: 234px;
  margin: 0 auto 8px;
  text-align: center;
  color: Black;
`;
const Button = styled(UIButton)`
  height: 40x;
  font-size: 14px;
  font-weight: 400;
  padding: 7px 0px;
`;

const Residue = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -24px;
  height: 24px;
`;

export default GiveandShop;
