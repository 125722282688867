import { useCallback } from 'react';
import downloadFile from 'js-file-download';
import Toast from 'services/Toast';

const useDownloadFileByOrigin = <T = string>(
  getFile: (query: T) => Promise<{ name: string; url: string }>,
  errorMessage?: string,
) => {
  const request = useCallback(
    async (query: T) => {
      try {
        const { url, name } = await getFile(query);
        const file = await fetch(url);
        const fileBlob = await file.blob();
        downloadFile(fileBlob, name);
      } catch (error) {
        errorMessage && Toast.error(errorMessage);
        return error;
      }
    },
    [errorMessage, getFile],
  );

  return request;
};

export default useDownloadFileByOrigin;
