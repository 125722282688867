import CardFavoritesSmall from 'components/CardFavoritesSmall';
import EmptyRecentlyAddedCompanies from 'components/EmptyRecentlyAddedCompanies';
import List from 'components/List';
import { Companies, SearchCompany } from 'interfaces/companies';
import React from 'react';

interface Props {
  data: Companies;
}

const RecentlyAddedCompaniesDashboard = ({ data }: Props) => {
  return data.items.length ? (
    <List<SearchCompany, object>
      list={data.items.slice(0, 4)}
      component={CardFavoritesSmall}
    />
  ) : (
    <EmptyRecentlyAddedCompanies />
  );
};

export default RecentlyAddedCompaniesDashboard;
