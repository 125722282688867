import { TeamMemberRoles, User } from 'interfaces/auth';
import { useMemo } from 'react';

const useIsCompanyUser = (user: User) => {
  const isCompanyUser = useMemo(() => {
    return (
      user.role === TeamMemberRoles.COMPANY_OWNER ||
      user.role === TeamMemberRoles.COMPANY_USER
    );
  }, [user.role]);

  return isCompanyUser;
};

export default useIsCompanyUser;
