import React, { useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { UICheckbox } from 'components/UI';
import styled from 'styled-components';
import { CheckBoxItem } from 'components/UI/interfaces';

interface FieldCheckboxGroupProps extends FieldRenderProps<[]> {
  options: CheckBoxItem[];
}

const FieldCheckBoxGroup = ({ input, options, ...props }: FieldCheckboxGroupProps) => {
  const { value, onChange } = input;

  const onSetValue = useCallback(
    val => {
      const isValue = value && !!value.find((item: CheckBoxItem) => item.id === val.id);

      if (isValue)
        return onChange(value.filter((item: CheckBoxItem) => item.id !== val.id));

      return onChange(value.length > 0 ? value.concat(val) : [val]);
    },
    [value, onChange],
  );

  return (
    <Field>
      <CheckboxList>
        {options.map(({ id, title, value: _value }: CheckBoxItem, index) => {
          const isCheck = value && !!value.find((i: CheckBoxItem) => i.id === id);
          return (
            <CheckboxItem
              {...props}
              key={id}
              check={isCheck}
              label={`${index + 1}. ${title}`}
              handleClick={() => onSetValue({ id, title, value: _value })}
            />
          );
        })}
      </CheckboxList>
    </Field>
  );
};

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const CheckboxList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const CheckboxItem = styled(UICheckbox)`
  margin-bottom: 16px;
  width: 50%;
`;

export default FieldCheckBoxGroup;
