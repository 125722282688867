import { IssueMappingStoreData } from 'interfaces/issueMapping';
import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as types from './types';

type RootAction = ActionType<typeof actions>;

export default createReducer<IssueMappingStoreData, RootAction>({
  surveyList: {
    items: [],
    meta: {
      currentPage: '',
      itemCount: 0,
      itemsPerPage: '',
      totalItems: 0,
      totalPages: 0,
    },
  },
  surveyResult: undefined,
  recommendation: undefined,
  charitables: {
    items: [],
    meta: {
      currentPage: '',
      itemCount: 0,
      itemsPerPage: '',
      totalItems: 0,
      totalPages: 0,
    },
  },
})
  .handleType([types.SET_ISSUE_MAPPING_SURVEY_LIST], (state, action) => ({
    ...state,
    surveyList: action.payload,
  }))
  .handleType([types.SET_ISSUE_MAPPING_SURVEY_RESULT], (state, action) => ({
    ...state,
    surveyResult: action.payload,
  }))
  .handleType([types.SET_RECOMMENDATION], (state, action) => ({
    ...state,
    recommendation: action.payload,
  }))
  .handleType([types.SET_CHARITABLES], (state, action) => ({
    ...state,
    charitables: action.payload,
  }));
