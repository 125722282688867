/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import OrganizationWrapper from 'layouts/OrganizationWrapper';
import { useOperation } from 'hooks/common';
import { fetchEmailTemplates, fetchNoContact } from 'store/ducks/team/operations';
import { useSelector } from 'react-redux';
import { getTeam } from 'store/ducks/team/selectors';
import { StateProps } from 'store/interfaces';
import { NoContact, TemplateEmailPreview, TemplatePreview } from 'interfaces/common';
import { teamSelectors } from 'store/ducks/team';
import NoContactTemplate from 'components/team/NoContactTemplate';
import NoContactTable from 'components/team/NoContactTable';

const NoContactList = () => {
  const team = useSelector(getTeam);
  const [loadNoContact, isStartLoading] = useOperation(fetchNoContact);
  const noContactCompanies = useSelector<StateProps, NoContact[]>(
    teamSelectors.getNoContact,
  );

  useEffect(() => {
    // Check if team.id exists before fetching email templatess
    if (team?.id) {
      loadNoContact(team.id);
    }
  }, [team?.id]);

  const handleAddSuccess = () => {
    if (team?.id) {
      loadNoContact(team.id); // Re-fetch no-contact data upon successful add
    }
  };

  return (
    <OrganizationWrapper isLoading={isStartLoading}>
      <NoContactTemplate onAddSuccess={handleAddSuccess} />
      <NoContactTable rows={noContactCompanies} />
    </OrganizationWrapper>
  );
};

export default NoContactList;
