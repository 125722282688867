/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { RadioButtonsLineProps, SelectOption } from './interfaces';
import styled from 'styled-components';

const RadioButtonsLine = ({ options, value, onChange }: RadioButtonsLineProps) => {
  const handleClick = (nexValue: SelectOption['value'] | undefined) => {
    onChange?.(nexValue);
  };

  return (
    <List>
      {options.map((option, index) => (
        <Item key={index}>
          <Button
            isActive={option.value === value}
            onClick={() => handleClick(option.value)}
            type="button"
          >
            {option.label}
          </Button>
        </Item>
      ))}
    </List>
  );
};

const List = styled.ul`
  background: #f8f8f8;
  border-radius: 6px;
  width: 100%;
  display: flex;
`;

const Item = styled.li`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Button = styled.button<{ isActive: boolean }>`
  height: 36px;
  width: 100%;
  border-radius: 6px;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);

  ${({ isActive }) =>
    isActive &&
    `background: #D4F3FF;
     border-radius: 6px;
     color: var(--black);
     transition: 0.3s ease-in;
  `}
`;

export default RadioButtonsLine;
