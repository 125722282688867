import { fetch } from 'services/ApiService';
import { PayoutReq } from 'interfaces/payout';
import StorageService from 'services/Storage';

export const fetchPayout = (param: PayoutReq) =>
  fetch(
    'post',
    'payout/create',
    {
      payload: {
        ...param,
      },
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `application/json`,
    },
  );

export const fetchEligiblePayoutAmount = (campaignName: string, merchantId: string) => {
  fetch<{ data: number }>(
    'get',
    `AtWork/GetTotalRaised?campaignName=${encodeURIComponent(
      campaignName,
    )}&merchantID=${merchantId}`,
  );
};
