import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled from 'styled-components';
import { SelectOption } from 'components/UI/interfaces';
import { FormError } from 'components/UI/form';
import { NumberSelect } from 'components/UI';

export interface RankItem {
  name: string;
  rank: number;
}

interface Props extends FieldRenderProps<RankItem[]> {
  options: SelectOption[];
}

const FieldRank = ({ input, options, meta }: Props) => {
  const { value, onChange } = input;
  const { error, submitError, touched, dirtySinceLastSubmit } = meta;
  const isError = (error && touched) || (submitError && !dirtySinceLastSubmit);

  const handleChange = (item: RankItem) => {
    if (!value && item.rank !== 0) return onChange([item]);

    const findIndex = value.findIndex(valueItem => valueItem.name === item.name);
    if (findIndex !== -1) {
      if (item.rank === 0)
        return onChange([...value.slice(0, findIndex), ...value.slice(findIndex + 1)]);

      return onChange([
        ...value.slice(0, findIndex),
        item,
        ...value.slice(findIndex + 1),
      ]);
    }

    if (item.rank === 0) return;
    onChange([...value, item]);
  };

  return (
    <Field>
      <OptionsList>
        {options.map(item => {
          const itemValue = value
            ? value.find(valueItem => valueItem.name === item.value)?.rank || 0
            : 0;

          const originNumbers = [...Array(options.length + 1).keys()];
          const numbers = value
            ? originNumbers.filter(num => !value.map(item => item.rank).includes(num))
            : originNumbers;

          return (
            <Option key={item.value}>
              <NumberSelect
                handleSelect={num => handleChange({ name: item.value, rank: num })}
                value={itemValue}
                numbers={[...numbers, itemValue]}
              />
              {item.label}
            </Option>
          );
        })}
      </OptionsList>

      {isError && <Error meta={meta} />}
    </Field>
  );
};

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const OptionsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px 30px;
`;

const Option = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Error = styled(FormError)`
  position: absolute;
`;

export default FieldRank;
