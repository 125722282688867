import React from 'react';
import styled from 'styled-components';

import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { Form } from 'react-final-form';
import { Field } from 'components/FinalForm';
import { FieldTextInput, MaskedMoneyTextInput } from 'components/FormFields';
import { required } from 'utils/validation';
import { useSelector } from 'react-redux';
import { getTeam } from 'store/ducks/team/selectors';

interface Props extends InjectedProps {
  isLoading?: boolean;
  handleAccess: ({ organizationName, minimumCommitment }) => void;
}

const SetupThresholdAmountModal = ({
  onClose,
  handleAccess,
  isLoading,
  ...modalProps
}: Props) => {
  useLockBodyScroll();

  const team = useSelector(getTeam);

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 0' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '559px',
        height: '367px',
        overflow: 'hidden',
      }}
    >
      <Title>Setup Organization Minimum Commitment</Title>
      <Form
        onSubmit={handleAccess}
        render={({ handleSubmit }) => (
          <FormBody>
            <Field
              label="Organization Name"
              name="organizationName"
              component={FieldTextInput}
              initialValue={team?.organizationName}
              disabled
            />
            <Field
              label="Minimum Commitment"
              name="minimumCommitment"
              component={MaskedMoneyTextInput}
              validate={required}
            />
            <BtnGroup>
              <Btn modifiers="secondary" title="Cancel" handleClick={onClose} />
              <Btn
                modifiers="primary"
                title="Save"
                isLoading={isLoading}
                handleClick={handleSubmit}
              />
            </BtnGroup>
          </FormBody>
        )}
      />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 46px;
  text-align: center;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Btn = styled(UIButton)`
  display: block;
  width: 87px;
  font-size: 14px;
  padding: 7px 0;
`;

const FormBody = styled.div`
  margin: 0 92px;
`;

export default SetupThresholdAmountModal;
