import giveShopSvg from 'assets/images/icons/give-and-shop.svg';
import Pagination from 'components/Pagination';
import { CropText, UIButton, UICheckbox } from 'components/UI';
import { format } from 'date-fns';
import { useModal, useOperation } from 'hooks/common';
import {
  CampaignData,
  CampaignListReq,
  CampListDropdownOptions,
} from 'interfaces/campaign';
import { PaginationMeta } from 'interfaces/common';
import React, { useCallback, useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { moneyFormatter } from 'utils/formatters';
import EditCampaignModal from './modals/EditCampaignModal';
import {
  fetchCampaignCopy,
  fetchCampaignDelete,
  fetchCampaignUpdate,
  isAssociationCamp,
} from 'store/ducks/campaign/operations';
import atWorkSvg from 'assets/images/icons/give-at-work.svg';
import { ConfirmModal } from 'components/Modals';
import DropdownListTwo from 'components/DropdownList/DropdownListTwo';
import EllipseSVG from 'assets/images/icons/ellipse.svg';
import ReactTooltip from 'react-tooltip';
import Toast from 'services/Toast';
import { getUTCAsLocalDate } from 'utils';

interface Props {
  meta: PaginationMeta;
  campaignList: CampaignData[];
  onFetchCampaignList: (args: CampaignListReq) => void;
  selectedCampaign: string[];
  onSearch: (req: CampaignListReq) => void;
  onCampaignSelect: (campaignIds: string[]) => void;
  onCampaignDelete: () => Promise<unknown>;
  rows: CampaignData[];
  headCells: string[];
  selectCampaignItem: (row: CampaignData) => void;
  callJumpATWork: (re: CampaignData) => void;
  callJumpGiveShop: (ele: CampaignData) => void;
}

interface TableRowProps {
  row: CampaignData;
  selectedCampaign: string[];
  handleRowSelect: (id: string) => void;
}

const tableWidth = {
  checkColumn: 20,
  oneColumn: 160,
  twoColumn: 240,
  thirdColumn: 150,
  fourColumn: 150,
  GiveShopColumn: 100,
  EditColumn: 88,
} as const;

const {
  checkColumn,
  oneColumn,
  twoColumn,
  thirdColumn,
  fourColumn,
  GiveShopColumn,
  EditColumn,
} = tableWidth;

const CampaignList = ({
  meta,
  campaignList,
  selectedCampaign,
  onCampaignSelect,
  onSearch,
  onCampaignDelete,
  rows,
  headCells,
  selectCampaignItem,
  callJumpATWork,
  callJumpGiveShop,
  onFetchCampaignList,
}: Props) => {
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [noAssocIds, setNoAssocIds] = useState<string[]>([]);
  const [deleLoading, setDeleLoading] = useState(false);

  const [fetchISAssociationCamp] = useOperation(isAssociationCamp);
  const [onFetchCampaignDelete] = useOperation(fetchCampaignDelete);

  const [onFetchCampaignUpdate] = useOperation(fetchCampaignUpdate);
  const [onFetchCampaignCopy] = useOperation(fetchCampaignCopy);

  const [loading, setLoading] = useState(false);

  const handleEditModalSubmit = (values: CampaignData) => {
    if (!values.campaignName || !values.startDate || !values.endDate) {
      Toast.warn('Please enter required information.');
      return;
    }
    setLoading(true);
    values.campaignDescription = values.campaignDescription || '';
    values.fundraisingGoal = values.fundraisingGoal || '';
    onFetchCampaignUpdate(values).then(e => {
      if (e) {
        setLoading(false);
      }
    });
  };

  const TableRowWrapper = ({ row, selectedCampaign, handleRowSelect }: TableRowProps) => {
    const {
      id,
      campaignName,
      campaignDescription,
      startDate,
      fundraisingGoal,
      potential,
    } = row;
    const [campaign, setCampaign] = useState<CampaignData | undefined>(undefined);
    const [showEditCampaign, hideEditCampaign] = useModal(
      () => (
        <EditCampaignModal
          campaign={campaign}
          handleAccess={values => {
            handleEditModalSubmit(values);
            hideEditCampaign();
          }}
          onClose={hideEditCampaign}
          isloading={loading}
        />
      ),
      [campaign],
    );

    const [showDuplicateCampaign, hideDuplicateCampaign] = useModal(
      () => (
        <EditCampaignModal
          campaign={campaign && { ...campaign, campaignName: undefined }}
          title="Duplicate Campaign"
          handleAccess={values => {
            onFetchCampaignCopy({ ...values, copyId: values.id }).then(() => {
              onFetchCampaignList({ page: 1 });
              hideDuplicateCampaign();
            });
          }}
          onClose={hideDuplicateCampaign}
        />
      ),
      [campaign],
    );

    const handleDrapdownListClick = useCallback(
      (option: string, row: CampaignData) => {
        switch (option) {
          case 'Edit':
            setCampaign(row);
            showEditCampaign();
            break;
          case 'Duplicate':
            setCampaign(row);
            showDuplicateCampaign();
            break;
          default:
            break;
        }
      },
      [showDuplicateCampaign, showEditCampaign],
    );
    useEffect(() => {
      ReactTooltip.rebuild();
      return () => {
        ReactTooltip.hide();
      };
    }, []);
    return (
      <TableRow>
        <TableRowCell>
          <Checkbox
            check={selectedCampaign.includes(id)}
            handleClick={() => handleRowSelect(id)}
          />
        </TableRowCell>
        <TableRowCell isAddWeight>
          <CropText text={campaignName} width={oneColumn} />
        </TableRowCell>
        <TableRowCell>
          <CropText width={twoColumn} text={campaignDescription} />
        </TableRowCell>
        <TableRowCell>
          <CropText
            width={thirdColumn}
            text={
              startDate ? format(getUTCAsLocalDate(startDate), 'MMM dd, yyyy') : 'N/A'
            }
          />
        </TableRowCell>
        <TableRowCell>
          <CropText
            text={moneyFormatter.from(Number(fundraisingGoal), { symbol: '$' })}
            width={fourColumn}
          />
        </TableRowCell>
        <TableRowCell>
          <CropText
            text={moneyFormatter.from(Number(potential), {
              symbol: '$',
            })}
            width={50}
          />
        </TableRowCell>
        <TableRowCell>
          <IconLink
            data-tip="Consumer Fundraising"
            src={giveShopSvg}
            onClick={() => {
              setCampaign(row);
              callJumpGiveShop(row);
            }}
          />
        </TableRowCell>
        <TableRowCell>
          <IconLink
            data-tip="Employee Fundraising"
            src={atWorkSvg}
            onClick={() => {
              callJumpATWork(row);
              selectCampaignItem(row);
            }}
          />
        </TableRowCell>
        <TableRowCell>
          <DropdownListTwo
            title={<EllipseIcon src={EllipseSVG} />}
            options={CampListDropdownOptions}
            disableArrow
            onClick={option => handleDrapdownListClick(option, row)}
          />
        </TableRowCell>
      </TableRow>
    );
  };

  const deleteCampaignEvent = ids => {
    onFetchCampaignDelete(ids).then(msg => {
      if (!msg) return;
      if (msg.status === 'success' && ids.length > 1) {
        Toast.success('The campaigns are deleted successfully');
      } else {
        Toast.success('The campaign is deleted successfully');
      }
      if (msg.status) {
        onCampaignDelete();
        setDeleLoading(false);
      }
    });
  };

  const [showConfirmModal, hideConfirmModal] = useModal(
    () => (
      <ConfirmModal
        name="Delete Campaign"
        description={`${
          selectedCampaign?.length > 1
            ? 'One or more of the selected campaigns are associated with a fundraising partnership and cannot be deleted.'
            : 'This partner is associated with a campaign and cannot be removed.'
        }`}
        textAccessBtn="OK"
        accessHandler={() => {
          if (noAssocIds.length > 0) {
            deleteCampaignEvent(noAssocIds);
            setDeleLoading(true);
          }
          hideConfirmModal();
        }}
        onClose={hideConfirmModal}
        cancelHandler={hideConfirmModal}
      />
    ),
    [noAssocIds],
  );

  const handleRowSelect = useCallback(
    (id: string) => {
      if (selectedCampaign.includes(id))
        return onCampaignSelect(selectedCampaign.filter(item => item !== id));

      onCampaignSelect([...selectedCampaign, id]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCampaign],
  );

  const deteleConfirm = (ids: string[]) => {
    fetchISAssociationCamp(ids).then((e: any) => {
      if (e?.data?.isLink) {
        setNoAssocIds(e.data.noLink);
        showConfirmModal();
      } else {
        setDeleLoading(true);
        deleteCampaignEvent(ids);
      }
    });
  };

  return (
    <Root>
      <SelectWrapper>
        <SelectAll>
          <Checkbox
            check={isSelectAll}
            handleClick={() => {
              setIsSelectAll(!isSelectAll);
              isSelectAll
                ? onCampaignSelect([])
                : onCampaignSelect(campaignList.map(item => item.id));
            }}
          />
          Select All
        </SelectAll>
        <Actions>
          <DeleteButton
            title="Delete"
            modifiers="danger"
            disabled={!selectedCampaign.length}
            handleClick={() => deteleConfirm(selectedCampaign)}
            isLoading={deleLoading}
          />
          {meta && meta.itemCount > 0 && (
            <Pagination
              total={meta.totalItems}
              pageLimit={Number(meta.itemsPerPage)}
              page={Number(meta.currentPage)}
              submitHandler={(page: number) => onSearch({ page })}
            />
          )}
        </Actions>
      </SelectWrapper>
      <Body>
        <TableWrapper>
          <Table>
            <colgroup>
              <col span={1} style={{ width: `${checkColumn}px` }} />
              <col span={1} style={{ width: `${oneColumn}px` }} />
              <col span={1} style={{ width: `${twoColumn}px` }} />
              <col span={1} style={{ width: `${thirdColumn}px` }} />
              <col span={1} style={{ width: `${fourColumn}px` }} />
              <col span={1} style={{ width: `${GiveShopColumn}px` }} />
              <col span={1} style={{ width: `${EditColumn}px` }} />
            </colgroup>
            <TableHead>
              <TableHeadRow>
                <TableHeadCell />
                {headCells.map((item, index) => (
                  <TableHeadCell key={index}>{item}</TableHeadCell>
                ))}
                <TableHeadCell />
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {rows.map(item => (
                <TableRowWrapper
                  key={item.id}
                  row={item}
                  selectedCampaign={selectedCampaign}
                  handleRowSelect={handleRowSelect}
                />
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </Body>
    </Root>
  );
};

const Root = styled.div``;

const Body = styled.div`
  overflow: auto;
`;

const Checkbox = styled(UICheckbox)`
  width: 20px;
  height: 20px;
  margin-right: 16px;
`;

const Actions = styled.div`
  display: flex;
`;

const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const SelectAll = styled.div`
  display: flex;
  user-select: none;
`;

const DeleteButton = styled(UIButton)`
  width: 76px;
  margin-right: 24px;
`;

const TableWrapper = styled.div`
  overflow: auto;
  height: 444px;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;
const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);
  background: #f4f4f4;
  position: sticky;
  top: 0;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableRow = styled.tr`
  height: 100%;
`;

const TableRowCell = styled.td<{ isAddWeight?: boolean; isNoAddBorder?: boolean }>`
  padding: 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }

  ${({ isAddWeight }) => isAddWeight && 'font-weight: 500;'}
  ${({ isNoAddBorder }) => isNoAddBorder && 'border-bottom: none;'}
`;

const EllipseIcon = styled(ReactSVG)`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 30px;
`;

const IconLink = styled(ReactSVG)`
  cursor: pointer;
  width: 24px;
`;

export default CampaignList;
