import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { FieldRenderProps, Form, FormSpy } from 'react-final-form';
import { Field } from 'components/FinalForm';
import {
  FieldDatePicker,
  FieldSuggestSingle,
  FieldTextInput,
  MaskedMoneyTextInput,
} from 'components/FormFields';
import {
  fileExtensionValidator,
  required,
  webSiteLink,
  maxLength9,
  integerValidator,
} from 'utils/validation';
import { CompanyCreateReq, SearchCompany, SuggestCompany } from 'interfaces/companies';
import { ROIAddCompanyRes, ROIAddTeamRes, ROIData } from 'interfaces/roi';
import { FormRow } from 'components/UI/form';
import { composeValidators, getDateCutOffTime, setDateGreaterLessDay } from 'utils';
import { useIsCompanyUser, useModal, useOperation } from 'hooks/common';
import ExistingPartnerModal from './ExistingPartnerModal';
import { FormApi } from 'final-form';
import { FieldWrapper } from 'components/roi/forms/styled';
import UploadFileArea from 'components/UploadFileArea';
import { getFilenameByPath } from 'utils/formatters';
import Toast from 'services/Toast';
import { uploadFile } from 'store/ducks/utility/api';
import { GiveWorxROIReq } from 'interfaces/fundraising';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/ducks/user/selectors';
import { SuggestTeams, TeamCreateReq, TeamProfileData } from 'interfaces/team';
import { fetchWorxROI } from 'store/ducks/fundraising/operations';
import useJumpToSupport from 'hooks/common/useJumpToSupport';
import UIAsterisk from 'components/UI/UIAsterisk';

interface Props extends InjectedProps {
  handleAccess: () => void;
  handleGetCompanies: (val: string) => void;
  companiesSearchList: SuggestCompany[];
  onCompanyCreate: (values: CompanyCreateReq) => Promise<ROIAddCompanyRes>;
  onCompanyCreateByChoose: (values: CompanyCreateReq) => Promise<ROIAddCompanyRes>;
  itemDetails?: ROIData;
  callType?: string;
  onTeamCreate: (values: TeamCreateReq) => Promise<ROIAddTeamRes>;
  onTeamCreateByChooses: (values: TeamCreateReq) => Promise<ROIAddTeamRes>;
  teamsSearchList: SuggestTeams;
  handleGetTeams: (val: string) => void;
  isSearchLoading: boolean;
}

interface FormProps {
  name: string;
  company: SearchCompany;
  companyUrl: string;
  CompanyAddress: string;
  annualFundraisingCommitment: string;
  renewalDate: string;
  partnerLogo?: string;
  startDate: string;
  endDate: string;
  team: TeamProfileData & { name: string };
}

const AddNewPartnerModal = ({
  onClose,
  handleGetCompanies,
  companiesSearchList,
  handleAccess,
  onCompanyCreate,
  onCompanyCreateByChoose,
  itemDetails,
  teamsSearchList,
  handleGetTeams,
  isloading,
  isSearchLoading,
  ...modalProps
}: Props) => {
  useLockBodyScroll();
  const formRef = useRef<FormApi | undefined>(undefined);
  const [list, setList] = useState<SearchCompany[]>([]);

  const user = useSelector(selectUser);
  const isCompanyUser = useIsCompanyUser(user);
  const [onFetchROICreate, , worxCreateLoading] = useOperation(fetchWorxROI);

  const submitEvent = (values: GiveWorxROIReq) => {
    const params = { ...values, isCompany: isCompanyUser };
    onFetchROICreate(params).then((e: any) => {
      if (e?.companyId) {
        Toast.success('Your form is saved successfully');
        handleAccess();
      }
    });
  };

  const { jumpToSupport } = useJumpToSupport();

  const [showModal, hideModal] = useModal(
    () => (
      <ExistingPartnerModal
        list={list}
        onAccess={async value => {
          let company = value;
          if (!value.id) {
            const res = await onCompanyCreateByChoose({
              name: value.name,
              website: value.website,
            });
            if (res.exist === undefined) {
              company = res;
            }
          }
          formRef.current?.change('company', company);
          formRef.current?.change('companyUrl', company.website);
          formRef.current?.change(
            'CompanyAddress',
            `${company.street ? `${company.street}, ` : ''}${
              company.city ? `${company.city}, ` : ''
            }${company.state ? `${company.state}, ` : ''}${
              company.country ? `${company.country}, ` : ''
            }${company.continent ? `${company.continent}, ` : ''}`,
          );
          hideModal();
        }}
        onClose={hideModal}
      />
    ),
    [list],
  );

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 0' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '836px',
        overflow: 'unset',
        top: '12%',
      }}
    >
      <Title>Add New Partner</Title>
      <Form
        initialValues={itemDetails}
        onSubmit={(values: FormProps) => {
          if (isCompanyUser && !values.team)
            return { team: 'Please select an organization from the drop-down list.' };

          const checkLogoExtension = ['jpg', 'jpeg', 'png', 'bmp', 'svg'].some(item =>
            values.partnerLogo?.toLowerCase().includes(item),
          );
          if (!checkLogoExtension) values.partnerLogo = undefined;

          if (!isCompanyUser) {
            if (!values?.company.id) {
              onCompanyCreate({
                name: values.company.name,
                website: values.companyUrl,
              }).then(data => {
                if (data.exist) {
                  const companies: SearchCompany[] = data.companies;
                  setList(companies);
                  showModal();
                  return;
                }
                if (data.exist === undefined) {
                  submitEvent({
                    ...values,
                    companyId: data.id,
                    companyName: data.name,
                  });
                }
              });
              return;
            }
            submitEvent({
              ...values,
              companyId: values?.company?.id,
              companyName: values?.company?.name,
            });
          } else {
            submitEvent({
              ...values,
              teamId: values?.team?.id,
              companyName: values?.team?.name,
            });
          }
        }}
        validate={() => {
          if (!isCompanyUser || isSearchLoading) return;
          if (!teamsSearchList.length) {
            return {
              team: (
                <span>
                  The organization you selected does not have a Giving Fund associated
                  with their account.{' '}
                  <span
                    style={{ fontWeight: 500, cursor: 'pointer' }}
                    onClick={jumpToSupport}
                  >
                    Contact Accelerist Support.
                  </span>
                  ,
                </span>
              ),
            };
          }
        }}
        render={({ handleSubmit, form, values }) => {
          return (
            <FormBody>
              <Field
                name={`${isCompanyUser ? 'team' : 'company'}`}
                validate={v => {
                  return v && v.name ? undefined : 'Required';
                }}
              >
                {(props: FieldRenderProps<any>) => {
                  const input = {
                    ...props.input,
                    onChange: (value: SuggestCompany | TeamProfileData) => {
                      if (!value) {
                        form.change('companyUrl', undefined);
                        form.change('CompanyAddress', undefined);
                        form.change('partnerLogo', undefined);
                        props.input.onChange(undefined);
                        return;
                      }

                      props.input.onChange(value);
                      if (!value.id) return;

                      if (isCompanyUser) {
                        const team = value as TeamProfileData;
                        form.change('companyUrl', team.contactWebsite);
                        form.change('CompanyAddress', team.address);
                        form.change('partnerLogo', team.logoKey);
                        return;
                      }

                      const company = value as SuggestCompany;
                      form.change('companyUrl', company.website);
                      form.change(
                        'CompanyAddress',
                        `${company.street ? `${company.street}, ` : ''}${
                          company.city ? `${company.city}, ` : ''
                        }${company.state ? `${company.state}, ` : ''}${
                          company.country ? `${company.country}, ` : ''
                        }${company.continent ? `${company.continent}, ` : ''}`,
                      );
                      form.change('partnerLogo', company.logo);
                    },
                  };

                  return (
                    <FieldSuggestSingle
                      {...props}
                      label={`${isCompanyUser ? 'Organization' : 'Company'} Name`}
                      handleGetSuggestions={e =>
                        isCompanyUser ? handleGetTeams(e) : handleGetCompanies(e)
                      }
                      list={isCompanyUser ? teamsSearchList : companiesSearchList}
                      placeholder="Search"
                      input={input}
                      isTypeInputToForm
                      disabled={isCompanyUser ? values?.team?.id : values?.company?.id}
                    />
                  );
                }}
              </Field>
              <FieldTitle>Partner image/Logo</FieldTitle>
              {
                <FieldWrapper>
                  <UploadFileArea
                    name="Upload File"
                    accept=".jpg,.jpeg,.png,.bmp,.svg"
                    acceptValidator={fileExtensionValidator(
                      ['jpg', 'jpeg', 'png', 'bmp', 'svg'],
                      'Must be jpg, jpeg, png, bmp, svg files',
                    )}
                    inputId="campaign-custom-content"
                    filelist={
                      values.partnerLogo &&
                      !(values.team?.logoKey || values.company?.logo)
                        ? [
                            {
                              label: getFilenameByPath(values.partnerLogo),
                              value: values.partnerLogo,
                            },
                          ]
                        : undefined
                    }
                    desc="You may upload an image in *.png.*.jpeg.*.jpg.*.bmp.*.svg. File size limit is 5 Mb."
                    onUpload={files => {
                      if (!files.length) return;
                      uploadFile(files[0], 'fundraising-partners').then(v => {
                        if (v.status === 'success') {
                          form.change('partnerLogo', v.fileKey);
                          return Toast.success('File uploaded successfully');
                        }
                        Toast.error('Error upload file');
                      });
                    }}
                    onDeleteFile={() => {
                      form.change('partnerLogo', '');
                    }}
                    disabled={!!values.team?.logoKey || !!values.company?.logo}
                  />

                  {(values.team?.logoKey || values.company?.logo) && (
                    <div style={{ color: 'var(--blue)', marginTop: '-16px' }}>
                      {isCompanyUser ? 'Organization' : 'Company'} logo already on file.
                    </div>
                  )}
                </FieldWrapper>
              }
              <Field
                placeholder="Enter Partner Description"
                name="name"
                label="Partner Description"
                component={FieldTextInput}
              />
              {isCompanyUser ? (
                <Field
                  name="companyUrl"
                  label="Organization Website"
                  component={FieldTextInput}
                  validate={composeValidators<string>(webSiteLink)}
                  disabled={isCompanyUser ? values?.team?.id : values?.company?.id}
                />
              ) : (
                <Field
                  name="companyUrl"
                  label={
                    <>
                      Company Website <UIAsterisk />
                    </>
                  }
                  component={FieldTextInput}
                  validate={composeValidators<string>(required, webSiteLink)}
                  disabled={isCompanyUser ? values?.team?.id : values?.company?.id}
                />
              )}

              <Field
                name="CompanyAddress"
                label="Address"
                component={FieldTextInput}
                disabled={isCompanyUser ? values?.team?.id : values?.company?.id}
              />
              <DollarInputWrapper>
                <Field
                  name="annualFundraisingCommitment"
                  label="Fundraising Commitment"
                  component={MaskedMoneyTextInput}
                  hidePrefix
                  validate={composeValidators<string>(
                    required,
                    integerValidator,
                    maxLength9,
                  )}
                />
                <DollarSubfix>$</DollarSubfix>
              </DollarInputWrapper>
              <FormRow>
                <Field
                  name="startDate"
                  label="Start Date"
                  parse={v => getDateCutOffTime(v)}
                  maxDate={setDateGreaterLessDay(values.endDate, 'less')}
                  component={FieldDatePicker}
                  popperPlacement="top"
                  validate={required}
                />
                <Field
                  name="endDate"
                  label="End Date"
                  parse={v => getDateCutOffTime(v)}
                  minDate={setDateGreaterLessDay(values.startDate, 'greater')}
                  component={FieldDatePicker}
                  popperPlacement="top"
                  validate={required}
                />
              </FormRow>

              <FormSpy subscription={{}}>
                {({ form }) => {
                  formRef.current = form;
                  return null;
                }}
              </FormSpy>

              <BtnGroup>
                <Btn modifiers="secondary" title="Cancel" handleClick={onClose} />
                <Btn
                  modifiers="primary"
                  title="Save"
                  isLoading={worxCreateLoading || isloading}
                  handleClick={handleSubmit}
                />
              </BtnGroup>
            </FormBody>
          );
        }}
      />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 46px;
  text-align: center;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Btn = styled(UIButton)`
  display: block;
  width: 87px;
  font-size: 14px;
  padding: 7px 0;
`;

const FormBody = styled.div`
  margin: 0 32px;
`;

const FieldTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 145%;
  color: #737373;
  margin: 0px auto 4px;
  font-family: 'Rubik';
`;

const DollarInputWrapper = styled.div`
  position: relative;
`;

const DollarSubfix = styled.div`
  position: absolute;
  right: 12px;
  top: 34px;
`;

export default AddNewPartnerModal;
