import { Field } from 'components/FinalForm';
import {
  FieldRadioButtons,
  FieldRank,
  FieldSingleSelect,
  FieldTextInput,
  FiledMultiSelectWithCheckBoxes,
} from 'components/FormFields';
import Jumbotron from 'components/Jumbotron';
import { UIButton } from 'components/UI';
import { useModal, useOperation } from 'hooks/common';
import { ContentBlockWrapper } from 'layouts';
import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { addDays, isAfter } from 'date-fns';
import SuccessfullyModal from 'components/Modals/SuccessfullyModal';
import { maxLength150, rankAllSelectedValidator, required } from 'utils/validation';
import {
  fetchIssueMappingSurveyResult,
  fetchIssueMappingSurveyResultSubmit,
} from 'store/ducks/issueMapping/operations';
import { getIssueMappingSurveyResult } from 'store/ducks/issueMapping/selectors';
import { IssueMappingSurveyResult } from 'interfaces/issueMapping';
import { formatValueEnum } from 'components/UI/interfaces';
import { RankItem } from 'components/FormFields/FieldRank/FieldRank';
import { composeValidators } from 'utils';

interface FormData extends Omit<IssueMappingSurveyResult, 'q7' | 'q8'> {
  q7: RankItem[];
  q8: RankItem[];
}

const causes = [
  'Animal Rights and Welfare',
  'Arts and Culture',
  'Civil and Social Action',
  'Environmental Sustainability',
  'Environmental Conservation',
  'Advocacy',
  'Natural Disaster Relief and Emergency Response',
  'International Development: Hunger and Poverty',
  'International Development: Women and Children',
  'International Development: Health',
  'Education, Employment and Training',
  'Youth Development and Education',
  'Research and Public Policy',
  'Health and Healthcare',
  'Mental Health',
  'Women and Girls',
  'Children and Family Services',
  'Hunger Relief (local)',
  'Community Development and Services',
  'LGBTQ Rights and Advocacy',
];

const IssueMappingEmployeeSurvey = () => {
  const search = queryString.parse(window.location.search) as { id: string };
  const [isDeleted, setIsDeleted] = useState(false);

  const [onFetchIssueMappingSurveyResult, , loading] = useOperation(
    fetchIssueMappingSurveyResult,
  );
  const [onFetchROISurveyResultSubmit] = useOperation(
    fetchIssueMappingSurveyResultSubmit,
  );

  const result = useSelector(getIssueMappingSurveyResult);

  const [showSuccessfullyModal, hideSuccessfullyModal] = useModal(
    () => (
      <SuccessfullyModal
        title="Survey Submitted"
        subTitle="Your responses have been received. Thank you for your participation."
        handleSubmit={() => {
          hideSuccessfullyModal();
          window.close();
        }}
        onClose={hideSuccessfullyModal}
      />
    ),
    [],
  );

  const corporationName = result?.survey.company.name;

  const isExpired = useMemo(() => {
    const endDate = result?.survey.endDate
      ? addDays(new Date(result?.survey.endDate), 1)
      : new Date(0);
    const currentDate = new Date();

    return isAfter(currentDate, endDate);
  }, [result]);

  const initialValues: FormData | undefined = useMemo(() => {
    if (!result) return undefined;
    return {
      ...result,
      q7: result?.q7?.map((item, index) => ({ name: item, rank: index + 1 })),
      q8: result?.q8?.map((item, index) => ({ name: item, rank: index + 1 })),
    };
  }, [result]);

  useEffect(() => {
    if (!search) return;
    onFetchIssueMappingSurveyResult(search.id).then(value => {
      if (value === 'Deleted') setIsDeleted(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isDeleted) {
    return (
      <ContentBlockWrapper
        loading={loading}
        header={<Jumbotron name="Employee Survey" />}
      >
        <ExpiredTextWrapper>This survey has been deleted.</ExpiredTextWrapper>
      </ContentBlockWrapper>
    );
  }

  if (result?.submitAt) {
    return (
      <ContentBlockWrapper
        loading={loading}
        header={<Jumbotron name="Employee Survey" />}
      >
        <ExpiredTextWrapper>Your response has already been submitted.</ExpiredTextWrapper>
      </ContentBlockWrapper>
    );
  }

  if (isExpired) {
    return (
      <ContentBlockWrapper
        loading={loading}
        header={<Jumbotron name="Employee Survey" />}
      >
        <ExpiredTextWrapper>This survey has expired.</ExpiredTextWrapper>
      </ContentBlockWrapper>
    );
  }

  return (
    <ContentBlockWrapper loading={loading} header={<Jumbotron name="Employee Survey" />}>
      <Form<FormData>
        initialValues={initialValues}
        onSubmit={values => {
          const submitData: IssueMappingSurveyResult = {
            ...values,
            q7: values.q7.sort((a, b) => a.rank - b.rank).map(item => item.name),
            q8: values.q8.sort((a, b) => a.rank - b.rank).map(item => item.name),
          };
          onFetchROISurveyResultSubmit(submitData).then(() => showSuccessfullyModal());
        }}
        render={({ handleSubmit, values }) => (
          <Body>
            <Section>
              <SectionTitle>
                1. Do you think it is important for {corporationName} to formally support
                a cause or charity?
              </SectionTitle>
              <Field
                name="q1"
                component={FieldRadioButtons}
                validate={required}
                layout="vertical"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
              />
              <Field
                name="q1Explain"
                component={FieldTextInput}
                validate={required}
                placeholder="Please explain"
              />
            </Section>
            <Section>
              <SectionTitle>
                2. What cause do you think {corporationName} should support as a brand?
              </SectionTitle>
              <Field
                name="q2"
                component={FieldSingleSelect}
                validate={required}
                layout="vertical"
                placeholder="Choose an option"
                options={[
                  'Animal Rights & Welfare',
                  'Arts & Education',
                  'Cancer',
                  "Children's Health",
                  'Education',
                  'Environment & Sustainability',
                  'Gender Equality',
                  'Gun Safety',
                  'Health',
                  'Human & Civil Rights/Social Justice',
                  'Human & Social Services',
                  'Humanitarian & Disaster Relief',
                  'Hunger Relief',
                  'International Development',
                  'LGBTQ Equality',
                  'Mental Health',
                  'Poverty',
                  'Veteran Affairs',
                  'Wildlife Conservation',
                  'Other',
                ].map(item => ({ label: item, value: item }))}
              />
            </Section>
            <Section>
              <SectionTitle>
                3. Why do you think that {corporationName} should support this particular
                cause?
              </SectionTitle>
              <Field
                name="q3"
                component={FieldTextInput}
                validate={required}
                placeholder="Please explain"
              />
            </Section>
            <Section>
              <SectionTitle>
                4. How do you as an individual want to engage with a cause or
                organization?
              </SectionTitle>
              <Field
                name="q4"
                component={FiledMultiSelectWithCheckBoxes}
                validate={required}
                formatValue={formatValueEnum.BASE_ARRAY}
                limit={3}
                labelledBy="Choose up to 3 options"
                options={[
                  'One-time or recurring giving/donation',
                  'Skills-based volunteering',
                  'Hands-on volunteering',
                  'Event participation',
                  'Raise funds through peer-to-peer fundraising',
                  'Awareness campaigns (i.e., social share for raising awareness)',
                  'Nothing',
                  'Other',
                ].map(item => ({ label: item, value: item }))}
              />
              {values.q4?.includes('Other') && (
                <Field
                  name="q4Explain"
                  component={FieldTextInput}
                  validate={composeValidators(required, maxLength150)}
                  placeholder="Please explain"
                />
              )}
            </Section>
            <Section>
              <SectionTitle>
                5. If {corporationName} formalizes a corporate cause partnership, what
                engagement opportunities do you want them to offer for employees?
              </SectionTitle>
              <Field
                name="q5"
                component={FiledMultiSelectWithCheckBoxes}
                validate={required}
                formatValue={formatValueEnum.BASE_ARRAY}
                limit={3}
                labelledBy="Choose up to 3 options"
                options={[
                  'One-time or recurring giving',
                  'Skills-based volunteering',
                  'Hands-on volunteering',
                  'Awareness campaigns (i.e. social share for raising awareness)',
                  'Fundraise through peer-to-peer fundraising',
                  'Paycheck deduction',
                  'Event',
                  'Time Off',
                  'Other',
                ].map(item => ({ label: item, value: item }))}
              />
              {values.q5?.includes('Other') && (
                <Field
                  name="q5Explain"
                  component={FieldTextInput}
                  validate={composeValidators(required, maxLength150)}
                  placeholder="Please explain"
                />
              )}
            </Section>
            <Section>
              <SectionTitle>
                6. Would you be more likely to give to a cause if {corporationName}{' '}
                matched your donation 1:1?
              </SectionTitle>
              <Field
                name="q6"
                component={FieldRadioButtons}
                validate={required}
                layout="vertical"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                  { label: 'Not Sure', value: 'Not Sure' },
                ]}
              />
            </Section>
            <Section>
              <SectionTitle>
                7. Please rank the following causes or organizations in order of
                importance to you, based on how passionate or not you are about them
                (where 1 is the most important).
              </SectionTitle>
              <Field
                name="q7"
                component={FieldRank}
                validate={composeValidators(
                  required,
                  rankAllSelectedValidator(causes.length),
                )}
                options={causes.map(item => ({ label: item, value: item }))}
              />
              <Field name="q7Explain" component={FieldTextInput} placeholder="Other" />
            </Section>
            <Section>
              <SectionTitle>
                8. Please rank the following causes based on where you believe{' '}
                {corporationName} should direct its Corporate Social Responsibility
                efforts (where 1 is the most important)
              </SectionTitle>
              <Field
                name="q8"
                component={FieldRank}
                validate={composeValidators(
                  required,
                  rankAllSelectedValidator(causes.length),
                )}
                options={causes.map(item => ({ label: item, value: item }))}
              />
              <Field name="q8Explain" component={FieldTextInput} placeholder="Other" />
            </Section>
            <ButtonWrapper>
              <SubmitButton
                modifiers="primary"
                title="Submit"
                handleClick={handleSubmit}
              />
            </ButtonWrapper>
          </Body>
        )}
      />
    </ContentBlockWrapper>
  );
};

const Section = styled.div`
  background-color: white;
  padding: 24px;
  margin-bottom: 24px;
`;

const SectionTitle = styled.div`
  margin-bottom: 34px;
`;

const Body = styled.div`
  max-width: 785px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SubmitButton = styled(UIButton)`
  padding: 9px 22px;
  font-size: 12px;
`;

const ExpiredTextWrapper = styled.div`
  font-weight: 600;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default IssueMappingEmployeeSurvey;
