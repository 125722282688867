import { useState, useCallback } from 'react';
import Toast from 'services/Toast';

interface Api<TPayload, TResponse> {
  (args: TPayload): Promise<TResponse>;
}

export default <TPayload, TResponse>(
  api: Api<TPayload, TResponse>,
  errMessage?: string,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TResponse | undefined>(undefined);

  const execute = useCallback(async (args: TPayload) => {
    setIsLoading(true);
    try {
      const data = await api(args);
      setData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      errMessage && Toast.error(errMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [execute, data, isLoading] as const;
};
