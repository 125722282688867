export default `
  .react-autosuggest__container{
    position: relative;
  }

  .react-autosuggest__input{
    width: 100%;
    color: var(--black);
    font-size: 16px;
    line-height: 155%;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 30px 10px 16px;
    outline: none;

    &:focus {
      border: 1px solid #2baee0;
    }

    &:-webkit-input-placeholder {
      color: var(--darkGray);
    }

    &:-moz-placeholder {
      color: var(--darkGray);
    }

    &:-ms-input-placeholder {
      color: var(--darkGray);
    }

    &:-moz-placeholder {
      color: var(--darkGray);
    }
  }

  .react-autosuggest__input--open{
    border-color: #2baee0;
    border-bottom: none;
    &:focus{
      border-bottom: none;
    }
  }

  .react-autosuggest__suggestions-container{
    position: absolute;
    width: 100%;
    top: 39px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list{
    background: #FFFFFF;
    border: 1px solid #2baee0;
    box-sizing: border-box;
    border-radius: 0px 0px 8px 8px;
    border-top: none;
    overflow: hidden;
  }

  .react-autosuggest__suggestion{
    font-size: 16px;
    line-height: 150%;
    padding: 10px 16px;
    transition: all 0.2s ease;
    &:hover {
      cursor: pointer;
      background: #e9f9ff;
    }
  }
`;
