/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { SupportModal } from 'components/Modals';
import { useOperation } from 'hooks/common';
import { AuthData } from 'interfaces/auth';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import useModal from 'hooks/common/useModal';
import { fetchChangePassword } from 'store/ducks/user/operations';
import AuthFormWrapper from 'components/AuthForms/AuthFormWrapper';
import NewPasswordForm from 'components/AuthForms/NewPasswordForm';

const NewPassword = () => {
  const [onSubmit] = useOperation(fetchChangePassword);
  const { location, push } = useHistory();

  const getToken = queryString.parse(location.search)?.passwordResetToken || undefined;

  const submit = useCallback((values: AuthData) => {
    return onSubmit({ ...values, token: getToken }).then(() => {
      push('/login');
    });
  }, []);

  const [openSupportModal, closeSupportModal] = useModal(() => (
    <SupportModal onClose={closeSupportModal} />
  ));

  return (
    <Wrapper>
      <Inner>
        <NewPasswordForm onSubmit={submit} />
      </Inner>
      <Btn onClick={openSupportModal}>Contact Support</Btn>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const Inner = styled(AuthFormWrapper)`
  margin: 0 auto 32px;
`;

const Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 145px;
  height: 36px;
  font-size: 12px;
  line-height: 150%;
  background: rgba(18, 36, 52, 0.15);
  border-radius: 6px;
  font-weight: 500;
  color: #fff;
  margin: 0 auto 28px;
  &:hover {
    background: rgba(18, 36, 52, 0.25);
  }
`;

export default NewPassword;
