import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useOperation } from 'hooks/common';
import { fetchUnsubscribeContact, fetchContactInfo } from 'store/ducks/user/operations';
import AuthFormWrapper from 'components/AuthForms/AuthFormWrapper';
import UnsubscribeForm from 'components/AuthForms/UnsubscribeForm';

const Unsubscribe = () => {
  const { id } = useParams<{ id: string }>();
  const [email, setEmail] = useState<string | null>(null);
  const [onSubmit] = useOperation(fetchUnsubscribeContact);
  const [unsubscribed, setUnsubscribed] = useState(false);

  useEffect(() => {
    const loadContactInfo = async () => {
      try {
        const response = await fetchContactInfo(id);
        setEmail(response.email);
        setUnsubscribed(response.alreadyUnsubscribed);
      } catch (error) {
        // Handle error
      }
    };

    loadContactInfo();
  }, [id]);

  const submit = useCallback(() => {
    return onSubmit(id).then(() => {
      setUnsubscribed(true);
    });
  }, [id, onSubmit]);

  return (
    <>
      <Wrapper>
        {!unsubscribed ? (
          <UnsubscribeForm email={email} onSubmit={submit} unsubscribed={unsubscribed} />
        ) : (
          <SuccessMessage>You have successfully unsubscribed.</SuccessMessage>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled(AuthFormWrapper)`
  margin-bottom: 40px;
`;

const SuccessMessage = styled.p`
  font-size: 16px;
  line-height: 155%;
  color: green;
  text-align: center;
  margin-top: 50px;
`;

export default Unsubscribe;
