import { UIButton, UICheckbox, UISpinner } from 'components/UI';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import { toJpeg } from 'html-to-image';
import { useHistory, useParams } from 'react-router';
import { useModal, useOperation } from 'hooks/common';
import { InfoModal } from 'components/Modals';
import { uploadFile } from 'store/ducks/utility/api';
import {
  fetchCreateCanvaLog,
  fetchGetOktaUser,
  fetchROICanvaTemplates,
  fetchROICanvaTemplatesCreate,
  fetchROIMeltwaterData,
  fetchROIUpdate,
} from 'store/ducks/roi/operations';
import { useSelector } from 'react-redux';
import {
  getROI,
  getROICanvaTemplates,
  getROICanvaTemplatesPrivate,
  getROIMeltwaterData,
} from 'store/ducks/roi/selectors';
import Toast from 'services/Toast';
import { selectUser } from 'store/ducks/user/selectors';
import Storage from 'services/Storage';
import { OktaAuth, toQueryString } from '@okta/okta-auth-js';
import {
  ROICanvaMetricsCategoryOptions,
  ROIReportEditNavigationEnum,
} from 'constants/roi';
import SelectCanvaTemplateSection from './SelectCanvaTemplateSection';
import SelectCanvaMetricsSection from './SelectCanvaMetricsSection';

export interface FormProps {
  isAll: boolean;
  chartIds: string[];
  template: string;
  templatePrivate: string;
  metricsCategory: string[];
}

const initialValues = {
  metricsCategory: ROICanvaMetricsCategoryOptions.map(item => item.value),
};

const ROICreateCustomizeReport = () => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [redirectionUrl, setRedirectionUrl] = useState('');
  const [isDontShowModal, setIsDontShowModal] = useState(false);

  const params: { roiId: string } = useParams();
  const { go, push } = useHistory();

  const [onFetchROIUpdate] = useOperation(fetchROIUpdate);
  const [onFetchROICanvaTemplates] = useOperation(fetchROICanvaTemplates);
  const [onFetchROICanvaTemplatesCreate] = useOperation(fetchROICanvaTemplatesCreate);
  const [onFetchOktaUser] = useOperation(fetchGetOktaUser);
  const [onFetchCreateCanvaLog] = useOperation(fetchCreateCanvaLog);
  const [onFetchROIMeltwaterData] = useOperation(fetchROIMeltwaterData);

  const roi = useSelector(getROI);
  const publicTemplates = useSelector(getROICanvaTemplates);
  const privateTemplates = useSelector(getROICanvaTemplatesPrivate);
  const user = useSelector(selectUser);
  const meltwaterData = useSelector(getROIMeltwaterData);

  const handleLoginOkta = useCallback(async (url: string) => {
    if (!roi) return;
    await onFetchCreateCanvaLog(roi.id);

    const account = await onFetchOktaUser(undefined);
    if (!account) return;

    const config = {
      issuer: 'https://dev-9317934.okta.com/oauth2/default',
    };

    const authClient = new OktaAuth(config);

    if (!account) return;
    authClient
      .signInWithCredentials({
        username: account.username,
        password: account.password,
      })
      .then(transaction => {
        if (transaction.status === 'SUCCESS') {
          window.open(
            authClient.getIssuerOrigin() +
              '/login/sessionCookieRedirect' +
              toQueryString({
                checkAccountSetupComplete: true,
                token: transaction.sessionToken,
                redirectUrl:
                  'https://dev-9317934.okta.com/home/dev-9317934_canva_2/0oa5s7n7s7xzDbhSY5d7/aln5s7yu8lPxPaQIc5d7',
              }),
          );

          push(`/roi/report-edit/${roi?.id}?type=${ROIReportEditNavigationEnum.Report}`);

          setTimeout(() => {
            window.open(url);
          }, 4000);
        } else {
          throw Error('Okta:We cannot handle the ' + transaction.status + ' status');
        }
      })
      .catch(err => {
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showModal, hideModal] = useModal(
    () => (
      <InfoModal
        title="Redirection Notice"
        description={
          <>
            <div>
              You will be redirected to Canva to complete the customization of your report
            </div>
            <Checkbox
              label="Don't show this message again"
              check={isDontShowModal}
              handleClick={() => setIsDontShowModal(!isDontShowModal)}
            />
          </>
        }
        onClickOk={() => {
          if (user && isDontShowModal)
            Storage.setItem(`hide-redirected-to-canva-modal-${user.email}`, 'true');
          handleLoginOkta(redirectionUrl);
          hideModal();
        }}
        onClose={() => hideModal()}
      />
    ),
    [redirectionUrl, isDontShowModal],
  );

  const handleFormSubmit = (values: FormProps) => {
    setLoading(true);

    const redirect = () => {
      setLoading(false);

      if (Storage.getItem(`hide-redirected-to-canva-modal-${user.email}`) === 'true')
        return handleLoginOkta(values.template || values.templatePrivate);

      setRedirectionUrl(values.template || values.templatePrivate);
      showModal();
    };

    if (!values.chartIds || !values.chartIds.length) {
      redirect();
      return;
    }

    const filekeysFetch = values.chartIds
      .map(item => document.getElementById(item))
      .filter((item): item is HTMLElement => !!item)
      .map(item => {
        const multiple = 2;
        const cloneEl = item.cloneNode(true) as HTMLElement;
        cloneEl.style.transform = `scale(${multiple})`;
        cloneEl.style.height = `${item.clientHeight}px`;
        cloneEl.style.width = `${item.clientWidth}px`;

        const generateEl = document.createElement('div');
        generateEl.style.height = `${item.clientHeight * multiple}px`;
        generateEl.style.width = `${item.clientWidth * multiple}px`;
        generateEl.style.display = 'flex';
        generateEl.style.justifyContent = 'center';
        generateEl.style.alignItems = 'center';
        generateEl.style.backgroundColor = '#fff';
        generateEl.appendChild(cloneEl);

        const wrapperEl = document.createElement('div');
        wrapperEl.style.position = 'absolute';
        wrapperEl.style.zIndex = '-1';
        wrapperEl.appendChild(generateEl);

        document.body.appendChild(wrapperEl);

        return wrapperEl;
      })
      .reduce((prev, curr, i) => {
        const generateEl = curr.firstChild as HTMLElement | null;
        if (!generateEl) return prev;

        const imageKey = toJpeg(generateEl).then(value => {
          const img = new Image();
          img.src = value;
          curr.remove();
          return fetch(value)
            .then(res => res.blob())
            .then(blob => new File([blob], `generate${i}.jpg`))
            .then(file => {
              return uploadFile(file, 'roi-create-customize-report').then(value => {
                if (value.status !== 'success') return null;
                return value.fileKey;
              });
            });
        });

        prev.push(imageKey);
        return prev;
      }, [] as Promise<string | null>[]);

    Promise.all(filekeysFetch)
      .then(arr => {
        return arr.filter((item): item is string => !!item);
      })
      .then(filekeys => {
        if (!roi) return;
        onFetchROIUpdate({ ...roi, canvaImages: filekeys }, true).then(() => {
          redirect();
        });
      })
      .catch(() => {
        setLoading(false);
        Toast.error('Failure to generate pictures');
      });
  };

  useEffect(() => {
    onFetchROICanvaTemplates({ page: 1, type: 'public' });
    onFetchROICanvaTemplates({ page: 1, type: 'private' });
    onFetchROIMeltwaterData(params.roiId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Root>
      <Form<FormProps>
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        render={({ form, values, handleSubmit }) => (
          <>
            <Content>
              {loading && (
                <>
                  <Spinner />
                  <Mask />
                </>
              )}

              {step === 0 && (
                <SelectCanvaTemplateSection
                  publicTemplates={publicTemplates}
                  onChangePublicTemplatePage={page =>
                    onFetchROICanvaTemplates({ page, type: 'public' })
                  }
                  privateTemplates={privateTemplates}
                  onChangePrivateTemplatePage={page =>
                    onFetchROICanvaTemplates({ page, type: 'private' })
                  }
                  onFetchROICanvaTemplatesCreate={params =>
                    onFetchROICanvaTemplatesCreate(params).then(() => {
                      onFetchROICanvaTemplates({ page: 1, type: 'private' });
                    })
                  }
                />
              )}
              {step === 1 && (
                <SelectCanvaMetricsSection
                  form={form}
                  values={values}
                  roi={roi}
                  meltwaterData={meltwaterData}
                />
              )}
            </Content>
            <Actions>
              <UIButton
                title="Back"
                modifiers={'secondary'}
                handleClick={() => (step === 0 ? go(-1) : setStep(0))}
              />

              <UIButton
                title="Next"
                modifiers={'primary'}
                handleClick={() => {
                  if (step === 0) {
                    if (!(values.template || values.templatePrivate))
                      return Toast.warn('You must select a template.');

                    return setStep(1);
                  }
                  handleSubmit();
                }}
              />
            </Actions>
          </>
        )}
      />
    </Root>
  );
};

const Root = styled.div``;

const Content = styled.div`
  width: 100%;
  min-height: 716px;
  background: #fff;
  margin-top: 24px;
  padding: 24px;
  position: relative;
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const Spinner = styled(UISpinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
`;

const Mask = styled.div`
  position: absolute;
  background: #fffa;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

const Checkbox = styled(UICheckbox)`
  justify-content: center;
  margin-top: 20px;
`;

export default ROICreateCustomizeReport;
