import * as types from './types';
import {
  TeamProfileData,
  SubscriptionProps,
  LastLoginData,
  SentPitch,
  SearchTeamsRes,
  SuggestTeams,
  ImpactGenome,
} from 'interfaces/team';
import { News } from 'interfaces/companies';
import { EmailDesignGetRes } from 'interfaces/emailDesign';
import { User } from 'interfaces/auth';
import { NoContact, TemplateEmailPreview, TemplatePreview } from 'interfaces/common';

export const setTeam = (team: TeamProfileData) =>
  ({
    type: types.SET_TEAM,
    payload: team,
  } as const);

export const getTeamMembers = (teamMembers: User[]) =>
  ({
    type: types.GET_TEAM_MEMBERS,
    payload: teamMembers,
  } as const);

export const getEmailTemplates = (templates: TemplateEmailPreview[]) =>
  ({
    type: types.GET_EMAIL_TEMPLATES,
    payload: templates,
  } as const);

export const getNoContact = (noContact: NoContact[]) =>
  ({
    type: types.GET_NO_CONTACT,
    payload: noContact,
  } as const);

export const deleteTeamMembers = (id: string) =>
  ({
    type: types.DELETE_TEAM_MEMBERS,
    payload: id,
  } as const);

export const setTeamSubscription = (subscription: SubscriptionProps) =>
  ({
    type: types.SET_SUBSCRIPTION,
    payload: subscription,
  } as const);

export const setLastLogins = (logins: LastLoginData[]) =>
  ({
    type: types.SET_LAST_LOGINS,
    payload: logins,
  } as const);

export const setSentPitch = (sentPitch: SentPitch) =>
  ({
    type: types.SET_SENT_PITCH,
    payload: sentPitch,
  } as const);

export const setTeams = (teams: SearchTeamsRes) =>
  ({
    type: types.SET_TEAMS,
    payload: teams,
  } as const);

export const setTeamNews = (news: News[]) =>
  ({
    type: types.SET_TEAM_NEWS,
    payload: news,
  } as const);

export const setEmailDesign = (emailDesign: EmailDesignGetRes) =>
  ({
    type: types.SET_EMAIL_DESIGN,
    payload: emailDesign,
  } as const);

export const clearEmailDesign = () => ({ type: types.CLEAR_EMAIL_DESIGN } as const);

export const setTeamsSuggest = (teams: SuggestTeams) =>
  ({
    type: types.SET_TEAMS_SUGGEST,
    payload: teams,
  } as const);

export const setTeamPayPalGivingFundCanUpdate = (canUpdate: boolean) =>
  ({
    type: types.SET_TEAM_PAYPALGIVINGFUND_CANUPDATE,
    payload: canUpdate,
  } as const);

export const setTeamPayPalGivingFundIfConfirm = (ifConfirm: boolean) =>
  ({
    type: types.SET_TEAM_PAYPALGIVINGFUND_IFCONFIRM,
    payload: ifConfirm,
  } as const);

export const setImpactGenome = (data: ImpactGenome[]) =>
  ({
    type: types.SET_IMPACT_GENOME,
    payload: data,
  } as const);

export const setNoContact = (data: NoContact[]) =>
  ({
    type: types.SET_NO_CONTACT,
    payload: data,
  } as const);
