import styled from 'styled-components';

const FormThreeRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    width: calc(33% - 8px);
  }
`;

export default FormThreeRow;
