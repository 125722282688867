import React, { useRef } from 'react';
import styled from 'styled-components';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { Form, FormSpy } from 'react-final-form';
import { Field } from 'components/FinalForm';
import {
  FieldDatePicker,
  FieldTextInput,
  MaskedMoneyTextInput,
} from 'components/FormFields';
import { required, webSiteLink } from 'utils/validation';
import { FormRow } from 'components/UI/form';
import { composeValidators, getDateCutOffTime } from 'utils';
import { FormApi } from 'final-form';
import UIAsterisk from 'components/UI/UIAsterisk';
import { ROIData } from 'interfaces/roi';

interface Props extends InjectedProps {
  isLoading?: boolean;
  roi?: ROIData;
  handleAccess: (params: ROIData) => void;
}

const EditPartnerModal = ({
  roi,
  onClose,
  handleAccess,
  isLoading,
  ...modalProps
}: Props) => {
  useLockBodyScroll();
  const formRef = useRef<FormApi | undefined>(undefined);

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 0' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '836px',
        overflow: 'unset',
      }}
    >
      <Title>Edit Partnership</Title>
      <Form
        initialValues={roi}
        onSubmit={(values: ROIData) => {
          handleAccess(values);
        }}
        render={({ handleSubmit }) => (
          <FormBody>
            <Field
              name="name"
              label="Partnership Description"
              component={FieldTextInput}
            />
            <FormRow>
              <Field
                name="companyName"
                label={
                  <>
                    Company Name
                    <UIAsterisk />
                  </>
                }
                component={FieldTextInput}
                disabled
              />

              <Field
                name="companyUrl"
                label={
                  <>
                    Website
                    <UIAsterisk />
                  </>
                }
                component={FieldTextInput}
                validate={composeValidators<string>(required, webSiteLink)}
                disabled
              />
            </FormRow>
            <Field
              name="CompanyAddress"
              label="Address"
              component={FieldTextInput}
              disabled
            />
            <FormRow>
              <Field
                name="annualFundraisingCommitment"
                label="Fundraising Commitment"
                component={MaskedMoneyTextInput}
              />
              <Field
                name="renewalDate"
                label="Renewal Date"
                parse={v => getDateCutOffTime(v)}
                component={FieldDatePicker}
                popperPlacement="top"
                disabled
              />
            </FormRow>

            <FormSpy subscription={{}}>
              {({ form }) => {
                formRef.current = form;
                return null;
              }}
            </FormSpy>

            <BtnGroup>
              <Btn modifiers="secondary" title="Cancel" handleClick={onClose} />
              <Btn
                modifiers="primary"
                title="Save"
                isLoading={isLoading}
                handleClick={handleSubmit}
              />
            </BtnGroup>
          </FormBody>
        )}
      />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 46px;
  text-align: center;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Btn = styled(UIButton)`
  display: block;
  width: 87px;
  font-size: 14px;
  padding: 7px 0;
`;

const FormBody = styled.div`
  margin: 0 32px;
`;

export default EditPartnerModal;
