import React from 'react';
import styled from 'styled-components';
import { moneyNumberFormat, numberFormat } from 'utils/formatters';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';

interface Props {
  total: number;
  value: number;
  themeColor?: string;
}

const SocialMediaImpressionsMetric = ({ total, value, themeColor }: Props) => {
  return (
    <MetricRoot>
      <MetricTitle>Social Media Impressions</MetricTitle>
      <Content>
        <ContentTitle>Impressions</ContentTitle>
        <ValueTitle>Total</ValueTitle>
        <Value>{numberFormat(total)}</Value>
        <ContentTitle>Value</ContentTitle>
        <Bar $themeColor={themeColor}>{moneyNumberFormat(value)}</Bar>
      </Content>
    </MetricRoot>
  );
};

const Content = styled(MetricContent)`
  display: flex;
  flex-direction: column;
`;

const ContentTitle = styled.div`
  color: #737373;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 12px;
`;

const Value = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
`;

const ValueTitle = styled.div`
  font-size: 12px;
  margin-bottom: 10px;
  color: #737373;
`;

const Bar = styled.div<{ $themeColor?: string }>`
  font-weight: 500;
  font-size: 14px;

  &::before {
    content: '';
    height: 10px;
    width: 200px;
    background-color: ${({ $themeColor }) => $themeColor || '#eec459'};
    display: inline-block;
    border-radius: 99em;
    margin-right: 12px;
  }
`;

export default SocialMediaImpressionsMetric;
