import React from 'react';
import { BlockButton } from './styled';

interface Props {
  block: boolean;
  handleClick: () => void;
}
const CompanyBlockButton = ({ block, handleClick }: Props) => {
  return (
    <BlockButton
      modifiers={block ? 'normal' : 'danger'}
      title={block ? 'Unblock' : 'Block'}
      handleClick={handleClick}
    />
  );
};

export default CompanyBlockButton;
