import React from 'react';
import styled from 'styled-components';
import { AuthWrapperProps } from 'interfaces/common';
import HeaderCompany from 'components/HeaderCompany';

function AuthenticatedCompanyWrapper({ children, location }: AuthWrapperProps) {
  return (
    <Root>
      <HeaderCompany location={location} />
      {children}
    </Root>
  );
}

const Root = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1200px;
`;

export default AuthenticatedCompanyWrapper;
