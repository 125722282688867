import { StateProps } from 'store/interfaces';

export const getROIList = (state: StateProps) => state.roi.roiList;
export const getROI = (state: StateProps) => state.roi.roi;
export const getROITemplates = (state: StateProps) => state.roi.roiTemplates;
export const getRoiTemplatePreview = (state: StateProps) => state.roi.templatePreview;
export const getROIReport = (state: StateProps) => state.roi.roiReport;
export const getROIMeltwaterData = (state: StateProps) => state.roi.roiMeltwaterData;
export const getROIDashboard = (state: StateProps) => state.roi.roiDashboard;
export const getROIsSuggest = (state: StateProps) =>
  state.roi.roiSuggest.map(item => ({ name: item.companyName, id: item.id }));
export const getROISdgGoals = (state: StateProps) => state.roi.roiSdgGoals;
export const getROISurveys = (state: StateProps) => state.roi.roiSurveys;
export const getROISurveyResult = (state: StateProps) => state.roi.roiSurveyResult;
export const getROIOld = (state: StateProps) => state.roi.roiOld;
export const getROICanvaTemplates = (state: StateProps) => state.roi.roiCanvaTemplates;
export const getROICanvaTemplatesPrivate = (state: StateProps) =>
  state.roi.roiCanvaTemplatesPrivate;
export const getROISurveyStatistics = (state: StateProps) =>
  state.roi.roiSurveyStatistics;
export const getROISurveyList = (state: StateProps) => state.roi.roiSurveyList;
export const getROISurveyStatistic = (state: StateProps) => state.roi.roiSurveyStatistic;
