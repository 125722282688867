import { SearchJobTitle } from 'interfaces/contacts';
import { fetch } from 'services/ApiService';
import { paramsToQueryString } from 'utils';

export const fetchContactsJobTitles = () => fetch<string[]>('get', 'contacts/jobTitle');

export const fetchSearchJobTitle = (q: string) =>
  fetch<SearchJobTitle[]>(
    'get',
    `contacts/jobTitleSuggested${paramsToQueryString({
      q,
    })}`,
  );

export const fetchContactsByIds = (ids: string[]) =>
  fetch<{ id: string; name: string }[]>(
    'get',
    `contacts/byIds${paramsToQueryString({ ids })}`,
  );
