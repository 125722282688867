/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import OrganizationWrapper from 'layouts/OrganizationWrapper';
import UserProfileForm from 'components/Forms/UserProfileForm';
import { UserProfile as UserProfileData } from 'components/Forms/interfaces';
import { StateProps } from 'store/interfaces';
import { selectUserPersonalInfo } from 'store/ducks/user/selectors';
import { useOperation } from 'hooks/common';
import { currentUserGet, fetchUpdatePersonalInfo } from 'store/ducks/user/operations';

const UserProfile = () => {
  const userPersonalInfo = useSelector<StateProps, UserProfileData>(
    selectUserPersonalInfo,
  );
  const [updatedPersonalInfo] = useOperation(fetchUpdatePersonalInfo);
  const [onFetchCurrentUser] = useOperation(currentUserGet);

  const onSubmit = useCallback((values: UserProfileData) => {
    return updatedPersonalInfo(values);
  }, []);

  useEffect(() => {
    onFetchCurrentUser(undefined);
  }, []);

  return (
    <OrganizationWrapper>
      <UserProfileForm initialValues={userPersonalInfo} onSubmit={onSubmit} />
    </OrganizationWrapper>
  );
};

export default UserProfile;
