/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import queryString from 'query-string';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import { GameplanFormData } from '../components/GamePlanTemplate';
import {
  clearEditGameplan,
  fetchCreateGameplan,
  fetchDeleteGameplan,
  fetchGameplan,
  fetchGameplanCustomizeTemplate,
  fetchGameplanCustomizeTemplatePreview,
  fetchGameplanCustomizeTemplateReset,
  fetchGameplanCustomizeTemplateSave,
  fetchGetNewGameplan,
  fetchNewGameplan,
  fetchSaveListContacts,
  fetchUpdateGameplan,
  updateNewGameplanTemplateByForm,
} from 'store/ducks/gameplan/operations';
import { useFetchData, useModal, useOperation } from 'hooks/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGameplan,
  getGameplanCustomizeTemplate,
  getGameplanCustomizeTemplatePreview,
  getNewGameplan,
  getSavelistContacts,
} from 'store/ducks/gameplan/selectors';
import { ContactCombineCompany, NewGameplan } from 'interfaces/gameplan';
import { StateProps } from 'store/interfaces';
import JumbotronEditTitle from 'components/JumbotronEditTitle/JumbotronEditTitle';
import CustomizeTemplateModal from 'components/gameplan/CustomizeTemplateModalAlt';
import GameplanForm from '../components/GameplanForm';
import { setSaveListContacts } from 'store/ducks/gameplan/actions';
import { ConfirmModal } from 'components/Modals';
import { getTeam } from 'store/ducks/team/selectors';
import { fetchTeam } from 'store/ducks/team/operations';
import HTMLPreviewModal from 'components/Modals/HTMLPreviewModal';
import { fetchGameplanEmailPreview } from 'store/ducks/gameplan/api';
import { debounce } from 'lodash';
import {
  fetchCompaniesSearch,
  fetchCompaniesSearchNoContact,
} from 'store/ducks/companies/operations';
import { getCompaniesSearch } from 'store/ducks/companies/selectors';
import Toast from 'services/Toast';

const EditGamePlan = () => {
  const [name, setName] = useState('');

  const dispatch = useDispatch();

  const params: { prospectsId: string; gameplanId?: string } = useParams();
  const { location, go } = useHistory();
  const search: { type?: string } = queryString.parse(location.search);

  const [onFetchContacts, , isContactsLoading] = useOperation(fetchSaveListContacts);
  const [onFetchNewGameplan, , isNewGameplanLoading] = useOperation(fetchNewGameplan);
  const [onFetchGetNewGameplan] = useOperation(fetchGetNewGameplan);
  const [onFetchCreateGameplan] = useOperation(fetchCreateGameplan);
  const [onFetchGameplan] = useOperation(fetchGameplan);
  const [onFetchUpdateGameplan] = useOperation(fetchUpdateGameplan);
  const [onFetchDeleteGameplan] = useOperation(fetchDeleteGameplan);
  const [onSearchCompanies] = useOperation(fetchCompaniesSearchNoContact);

  const [onFetchGameplanCustomizeTemplate, , isloading] = useOperation(
    fetchGameplanCustomizeTemplate,
  );
  const [
    onFetchGameplanCustomizeTemplateSave,
    ,
    customizeTemplateSaveLoading,
  ] = useOperation(fetchGameplanCustomizeTemplateSave);
  const [onFetchGameplanCustomizeTemplateReset] = useOperation(
    fetchGameplanCustomizeTemplateReset,
  );
  const [onFetchGameplanCustomizeTemplatePreview] = useOperation(
    fetchGameplanCustomizeTemplatePreview,
  );

  const [onUpdateNewGameplanTemplate] = useOperation(updateNewGameplanTemplateByForm);
  const [onClearEditGameplan] = useOperation(clearEditGameplan);

  const [onFetchGameplanEmailPreview, emailHtml, isEmailPreviewLoading] = useFetchData(
    fetchGameplanEmailPreview,
  );

  const gameplanCustomizeTemplate = useSelector(getGameplanCustomizeTemplate);
  const gameplanCustomizeTemplatePreview = useSelector(
    getGameplanCustomizeTemplatePreview,
  );

  const contacts = useSelector<StateProps, ContactCombineCompany[]>(getSavelistContacts);
  const newGameplan = useSelector<StateProps, NewGameplan>(getNewGameplan);
  const editGameplan = useSelector(getGameplan);
  const team = useSelector(getTeam);
  const companiesSearchList = useSelector(getCompaniesSearch);
  const [gamePlanNewName, setGamePlanNewName] = useState('');
  const [updatedBody, setUpdatedBody] = useState('');
  const [updatedSubject, setUpdatedSubject] = useState('');
  const [templateType, setTemplateType] = useState('');
  const [onFetchTeam] = useOperation(fetchTeam);

  const handleSearchCompanies = async (val: string) => {
    let teamId;
    if (!team?.id) {
      try {
        const updatedTeam = await onFetchTeam(undefined);
        teamId = updatedTeam.id;
      } catch (error) {
        console.error('Failed to fetch team:', error);
        return;
      }
    } else {
      teamId = team.id;
    }
    return onSearchCompanies(val, teamId);
  };

  const handleSearchCompaniesDebounce = useCallback(
    debounce(handleSearchCompanies, 800),
    [],
  );

  const gameplan = params.gameplanId ? editGameplan : newGameplan;

  const handleUpdateGamePlanTemplate = values => {
    setUpdatedBody(values.body);
    setUpdatedSubject(values.subject);
    setTemplateType(values.templateType); //new
  }; //new

  const [showCustomizeTemplateModal, hideCustomizeTemplateModal] = useModal(() => {
    return (
      <CustomizeTemplateModal
        template={gameplanCustomizeTemplate}
        templatePreview={gameplanCustomizeTemplatePreview}
        isloading={isloading}
        saveLoading={customizeTemplateSaveLoading}
        onClose={hideCustomizeTemplateModal}
        onReset={templateType => onFetchGameplanCustomizeTemplateReset(templateType)}
        onSave={values => onFetchGameplanCustomizeTemplateSave(values)}
        onPreview={values => onFetchGameplanCustomizeTemplatePreview(values)}
        updateGamePlanTemplate={values => handleUpdateGamePlanTemplate(values)} //new
      />
    );
  }, [
    gameplanCustomizeTemplate,
    isloading,
    gameplanCustomizeTemplatePreview,
    customizeTemplateSaveLoading,
  ]);

  const [showPreviewModal, hidePreviewModal] = useModal(() => {
    return (
      <HTMLPreviewModal
        html={emailHtml}
        isloading={isEmailPreviewLoading}
        onClose={hidePreviewModal}
      />
    );
  }, [emailHtml, isEmailPreviewLoading]);

  const [showConfirmModal, hideConfirmModal] = useModal(() => (
    <ConfirmModal
      accessHandler={() => {
        if (!params.gameplanId) return;
        onFetchDeleteGameplan(params.gameplanId);
        go(-1);
      }}
      cancelHandler={hideConfirmModal}
      name="Delete game plan"
      description="Are you sure to delete this game plan?"
      textAccessBtn="Delete"
      onClose={hideConfirmModal}
    />
  ));

  const initialValues = useMemo<GameplanFormData | {}>(() => {
    if (gameplan && gameplan.gamePlanTemplates) return gameplan;
    return {};
  }, [gameplan]);

  const allContacts = useMemo((): ContactCombineCompany[] => {
    if (gameplan?.gamePlanContacts?.every(item => !item.ownContact)) return contacts;

    const ownContacts: ContactCombineCompany[] = (
      gameplan?.gamePlanContacts?.filter(item => item.ownContact) || []
    ).map(item => ({
      ...item.contact,
      id: item.contact.email || '',
      isOwnContact: true,
      usedIn60Days: '0',
    }));
    const includeOwnContacts = [...contacts, ...ownContacts];

    return includeOwnContacts;
  }, [contacts, gameplan]);

  const defaultCheckedContactsId = useMemo(() => {
    return gameplan?.gamePlanContacts?.reduce((prev, curr) => {
      if (curr.contactId) {
        return [...prev, curr.contactId];
      }
      if (curr.ownContact && curr.ownContact.email) {
        return [...prev, curr.ownContact.email];
      }
      return prev;
    }, [] as string[]);
  }, [gameplan]);

  const handleCreateGameplan = (gameplan: NewGameplan) => {
    if (gamePlanNewName) {
      gameplan.name = gamePlanNewName;
    }
    return onFetchCreateGameplan({ ...gameplan, isClose: false });
  };
  const handleSaveDraftGameplan = (gameplan: NewGameplan) => {
    if (gamePlanNewName) {
      gameplan.name = gamePlanNewName;
    }
    return onFetchUpdateGameplan({ ...gameplan, isClose: true });
  };

  useEffect(() => {
    if (!gameplan) {
      return;
    }
    setName(gameplan.name);
  }, [gameplan?.name]);

  useEffect(() => {
    // create gameplan
    if (!params.gameplanId) {
      onFetchNewGameplan({
        savelistId: params.prospectsId,
        gameplanType: search.type || '',
      });
      onFetchContacts(params.prospectsId);
      return;
    }

    // edit gameplan
    onFetchContacts(params.prospectsId).then(contacts => {
      if (!params.gameplanId) return;
      onFetchGameplan(params.gameplanId).then(gameplan => {
        if (!contacts) return;
        const defaultSelectedContacts = gameplan?.gamePlanContacts.map(
          item => item.contactId,
        );
        // set selected contacts by gameplan
        dispatch(
          setSaveListContacts(
            contacts.map(item =>
              defaultSelectedContacts?.includes(item.id)
                ? { ...item, isSelected: true }
                : item,
            ),
          ),
        );
      });
    });
  }, []);

  useEffect(
    () => () => {
      onClearEditGameplan(undefined);
    },
    [],
  );

  const handleNewName = (name: string) => {
    if (name == '') {
      Toast.error('The title should not be empty.');
    }
    setGamePlanNewName(name);
  };

  return (
    <ContentBlockWrapper
      header={
        <JumbotronEditTitle
          name={name}
          onSave={name => setName(name)}
          disableDeleteBtn={!params.gameplanId}
          onDelete={showConfirmModal}
          newName={handleNewName}
          isShowEdit={false}
        />
      }
      loading={isContactsLoading || isNewGameplanLoading}
    >
      {gameplan && team && (
        <GameplanForm
          contacts={allContacts}
          initialValues={initialValues}
          defaultCheckedContactsId={defaultCheckedContactsId}
          search={search}
          gameplan={gameplan}
          team={team}
          companiesSearchList={companiesSearchList}
          onCreateGameplan={handleCreateGameplan}
          onSaveDraftGameplan={handleSaveDraftGameplan}
          onFetchGameplanCustomizeTemplate={onFetchGameplanCustomizeTemplate}
          onUpdateNewGameplanTemplate={onUpdateNewGameplanTemplate}
          onResetFormToDefault={() =>
            onFetchGetNewGameplan({
              savelistId: params.prospectsId,
              gameplanType: search.type || '',
            })
          }
          showCustomizeTemplateModal={showCustomizeTemplateModal}
          showPreviewModal={payload => {
            onFetchGameplanEmailPreview(payload);
            showPreviewModal();
          }}
          handleGetCompanies={handleSearchCompaniesDebounce}
          updatedBody={updatedBody}
          updatedSubject={updatedSubject}
          templateType={templateType}
        />
      )}
    </ContentBlockWrapper>
  );
};

export default EditGamePlan;
