import {
  AudienceAnalysisCreateReq,
  AudienceListReq,
  AudienceListRes,
} from 'interfaces/audience';
import { fetch } from 'services/ApiService';
import { paramsToQueryString } from 'utils';

export const fetchAudienceCreate = (params: AudienceAnalysisCreateReq) =>
  fetch('post', 'audience', { payload: params });

export const fetchAudienceList = ({
  limit = 12,
  ...params
}: AudienceListReq): Promise<AudienceListRes> =>
  fetch('get', `audience${paramsToQueryString({ limit, ...params })}`);

export const fetchAudienceDelete = (deleteIds: string[]): Promise<string> =>
  fetch(
    'delete',
    `audience/MultipleDelete${paramsToQueryString({
      deleteIds: deleteIds.join(),
    })}`,
  );

export const fetchDownloadAudienceTemplate = () =>
  fetch<{ name: string; file: string }>('get', 'audience/downloadTemplate');

export const fetchDownloadAudienceReport = (id: string) =>
  fetch<{ name: string; url: string }>('get', `audience/downloadReport/${id}`);

export const fetchDownloadAudienceRawReport = (id: string) =>
  fetch<{ name: string; file: string }>('get', `audience/downloadRawReport/${id}`);
