import { MembershipRow } from 'interfaces/common';
import React from 'react';
import styled from 'styled-components';

import DoneIcon from 'assets/images/done.svg';
import NotAvailableIcon from 'assets/images/not_available.svg';

interface Props {
  rows: MembershipRow[];
  headCells: { name: string; price: string }[];
}

interface TableRowProps {
  row: MembershipRow;
}

const userTableWidth = {
  oneColumn: 296,
  twoColumn: 298,
  thirdColumn: 298,
  fourColumn: 298,
} as const;

const { oneColumn, twoColumn, thirdColumn, fourColumn } = userTableWidth;

const getValue = (val: string | boolean) => {
  const isBoolean = typeof val == 'boolean';
  if (isBoolean) {
    if (val) {
      return <ImgValue src={DoneIcon} />;
    }
    return <ImgValue src={NotAvailableIcon} />;
  }
  return <Value>{val}</Value>;
};

const TableRowWrapper = ({ row }: TableRowProps) => {
  const { name, basic, pro, enterprise } = row;

  const basicValue = getValue(basic);
  const proValue = getValue(pro);
  const enterpriseValue = getValue(enterprise);

  return (
    <TableRow>
      <TableRowCell>
        <Title>{name}</Title>
      </TableRowCell>
      <TableRowCell>
        <ContentCenter>{basicValue}</ContentCenter>
      </TableRowCell>
      <TableRowCell>
        <ContentCenter>{proValue}</ContentCenter>
      </TableRowCell>
      <TableRowCell>
        <ContentCenter>{enterpriseValue}</ContentCenter>
      </TableRowCell>
    </TableRow>
  );
};

const MembershipTable = ({ rows, headCells }: Props) => {
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${oneColumn}px` }} />
          <col span={1} style={{ width: `${twoColumn}px` }} />
          <col span={1} style={{ width: `${thirdColumn}px` }} />
          <col span={1} style={{ width: `${fourColumn}px` }} />
        </colgroup>
        <TableHead>
          <tr>
            <TableHeadCell />
            {headCells.map((item, index) => (
              <TableHeadCell key={index}>
                <HeadWrapper>
                  <HeadTitle>{item.name}</HeadTitle>
                  <HeadPrice>{item.price}</HeadPrice>
                  <HeadSubTitle>Annual Pricing</HeadSubTitle>
                </HeadWrapper>
              </TableHeadCell>
            ))}
          </tr>
        </TableHead>
        <TableBody>
          {rows.map((item, index) => (
            <TableRowWrapper key={index} row={item} />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const HeadTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 8px;
`;

const HeadPrice = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 149px;
`;

const HeadSubTitle = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
`;

const ImgValue = styled.img`
  width: 20px;
  height: 20px;
`;

const Value = styled.p`
  font-size: 12px;
  line-height: 150%;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
`;

const ContentCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const TableWrapper = styled.div`
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #fff;
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;
const TableHeadCell = styled.th`
  text-align: center;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-right: 24px;
  }
`;

const TableRow = styled.tr`
  height: 100%;
  &:nth-child(odd) {
    background: #f8fdff;
  }
`;

const TableRowCell = styled.td`
  padding: 14px 0;
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }
`;

export default MembershipTable;
