import React, { useState } from 'react';
import { useModal } from 'hooks/common';

import { UIButton, UILink } from 'components/UI';
import { InfoModal } from 'components/Modals';
import useJumpToSupport from 'hooks/common/useJumpToSupport';
import { fetchUncommongivingLoginOktaUser } from 'store/ducks/giving/api';
import { OktaAuth, OktaAuthOptions, toQueryString } from '@okta/okta-auth-js';
import { fetchErrorHandler } from 'utils/errorHandlers';
import { CharitableOrganizations } from '../CharitableOrganizations';
import styled from 'styled-components';

interface Props {
  params: any;
}

const CharitableOrganizationsContent = ({ params }: Props) => {
  const { jumpToSupport } = useJumpToSupport();
  const jumpToUncommonGiving = async ({
    type,
  }: {
    type: 'wallet' | 'donate' | 'viewDonations';
  }) => {
    try {
      const { sessionToken, redirectUrl } = await fetchUncommongivingLoginOktaUser();
      const urlList = redirectUrl.split('.com');
      const config: OktaAuthOptions = {
        issuer: urlList[0] + '.com/oauth2/default',
      };
      const authClient = new OktaAuth(config);

      const relayState = {
        wallet: '%2Faccount%2Ffund%2Fwallet',
        donate: '%2Fgive%2Fnonprofit%2Fb6f5ce854102',
        viewDonations: '%2Faccount%2Fgive%2Foverview',
      };
      window.open(
        authClient.getIssuerOrigin() +
          '/login/sessionCookieRedirect' +
          toQueryString({
            checkAccountSetupComplete: true,
            token: sessionToken,
            redirectUrl: `${redirectUrl}?RelayState=${relayState[type]}&code=9lxzuZtK4JqFvFLa90iu`,
          }),
      );
    } catch (error) {
      fetchErrorHandler(error);
    }
  };

  const [showFundMyWalletModal, hideFundMyWalletModal] = useModal(() => (
    <InfoModal
      onClose={hideFundMyWalletModal}
      title="Fund My Wallet"
      description={
        <>
          You are being redirected to Accelerist&apos;s UncommonGiving Fund. If you have
          any issues, please log a{' '}
          <UILink onClick={jumpToSupport} modifier="blue">
            ticket
          </UILink>
          .
        </>
      }
      onClickOk={async () => {
        await jumpToUncommonGiving({
          type: 'wallet',
        });
        hideFundMyWalletModal();
      }}
    />
  ));
  return (
    <>
      <ActiveButtons>
        <UIButton
          modifiers={'primary'}
          title="Fund My Wallet"
          handleClick={showFundMyWalletModal}
          style={{ width: '200px', padding: '7px 20px' }}
        />
      </ActiveButtons>

      <CharitableOrganizations params={params} />
    </>
  );
};

const ActiveButtons = styled.div`
  display: flex;
  gap: 16px;
  justify-content: right;
`;

export default CharitableOrganizationsContent;
