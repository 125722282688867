import React, { ReactNode, useState } from 'react';
import { Form, FormRenderProps, FormSpy } from 'react-final-form';
import styled from 'styled-components';
import Jumbotron from 'components/Jumbotron';
import SearchWithFiltersInput from 'components/SearchWithFiltersInput';
import { UIButton } from 'components/UI';
import { FilterData } from '../interfaces';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import { Field } from 'components/FinalForm';
import { useSelector } from 'react-redux';
import { StateProps } from 'store/interfaces';
import { SimilaritySearchCompany } from 'interfaces/companies';
import { getSimilaritySearchCompany } from 'store/ducks/companies/selectors';
import { initialValuesFilter } from 'pages/organizationUser/search/Search/initialValuesFilter';
import { PAGE_WIDTH } from 'constants/common';
import FilterFormSection from './FilterFormSection';
import FiltersFormModal from './FiltersFormModal';
import { FormApi } from 'final-form';
import { HandleSearchConfigParams } from 'pages/organizationUser/search/Search/Search';

export interface FormProps {
  onSubmit: (
    formValues?: FilterData,
    config?: HandleSearchConfigParams,
  ) => OnSubmitReturnType;
  isVisibleFilters: boolean;
  toggleFilter: (val: boolean) => void;
  initialValues: FilterData;
  children?: ReactNode;
  hideHeader?: boolean;
}

const FilterForm = ({
  onSubmit,
  isVisibleFilters,
  toggleFilter,
  initialValues,
  children,
  hideHeader,
}: FormProps) => {
  const [isBestMath, setIsBestMath] = useState<boolean>(true);

  const similaritySearchCompany = useSelector<StateProps, SimilaritySearchCompany[]>(
    getSimilaritySearchCompany,
  );

  const handleResetFilterModalValue = (
    form: FormApi<FilterData, Partial<FilterData>>,
  ) => {
    [
      'logicType',
      'ids',
      'country',
      'industry',
      'location',
      'city',
      'primaryNaicsCode',
      'naicsCode',
      'revenueMin',
      'revenueMax',
      'revenueRange',
      'contributionRange',
      'zipCode',
      'totalAnnualContributors',
      'csrFocusIds',
      'corporateFoundation',
      'sdgGoals',
      'contact',
      'jobFunction',
      'managementLevel',
      'gender',
      'income',
      'ethnicities',
      'ageRanges',
      'variantForm',
      'priority.crsFocus',
      'priority.sdgGoal',
      'priority.employeeEngagement',
      'priority.partnership',
      'priority.geographicLocation',
      'priority.brandAffinities',
    ].forEach(item => form.change(item as keyof FilterData, initialValuesFilter[item]));
  };

  return (
    <Form
      onSubmit={values => {
        // filter falsy field
        const formValues = Object.fromEntries(
          Object.entries(values).filter(item => item[1]),
        );
        onSubmit({ ...formValues, q: values.q });
        toggleFilter(false);
      }}
      subscription={{ submitting: true }}
      initialValues={{ ...initialValues, filterType: 'Best Match' }}
      render={({ handleSubmit, submitting, form }: FormRenderProps<FilterData>) => {
        return (
          <>
            <Wrapper>
              {!hideHeader && (
                <Header>
                  <Jumbotron name="Search">
                    <div style={{ width: '715px' }}>
                      <FormSpy subscription={{ values: true }}>
                        {({ values }) => (
                          <>
                            <Field name="q" notRemoveWhenEmpty>
                              {() => (
                                <SearchWithFiltersInput
                                  searchHandler={handleSubmit}
                                  toggleFilter={() => toggleFilter(!isVisibleFilters)}
                                  searchInputValue={values.q}
                                  filterTypeValue={values.filterType}
                                  handleChangeInputValue={val => form.change('q', val)}
                                  handleResetForm={() => {
                                    initialValuesFilter['filterType'] = 'Best Match';
                                    form.reset(initialValuesFilter);
                                  }}
                                  handleChangeFilterType={type => {
                                    form.change('filterType', type);
                                    setIsBestMath(type === 'Best Match');
                                    if (type === 'Best Match') {
                                      setIsBestMath(true);
                                    } else {
                                      setIsBestMath(false);
                                    }
                                  }}
                                />
                              )}
                            </Field>
                          </>
                        )}
                      </FormSpy>

                      {isBestMath && similaritySearchCompany.length > 0 && (
                        <SugggestDiv>
                          Do you mean
                          {similaritySearchCompany.map((x, i) => (
                            <React.Fragment key={x.name}>
                              <SugggestURL
                                onClick={() => {
                                  form.getFieldState('q')?.change(x.name);
                                  handleSubmit();
                                }}
                              >
                                {x.name}
                              </SugggestURL>
                              {similaritySearchCompany.length !== i + 1 && ','}
                            </React.Fragment>
                          ))}
                          ?
                        </SugggestDiv>
                      )}
                    </div>
                  </Jumbotron>
                </Header>
              )}
              <FiltersFormModal
                isOpen={isVisibleFilters}
                onClose={() => toggleFilter(false)}
              >
                <Root>
                  <FilterFormSection />

                  <ButtonsGroup>
                    <Button
                      modifiers="secondary"
                      title="Cancel"
                      handleClick={() => toggleFilter(false)}
                      type="button"
                    />
                    <div>
                      <Button
                        modifiers="third"
                        title="Reset filters"
                        handleClick={() => handleResetFilterModalValue(form)}
                        type="button"
                      />
                      <Button
                        modifiers="primary"
                        title="Search"
                        handleClick={handleSubmit}
                        type="submit"
                        disabled={submitting}
                        isLoading={submitting}
                      />
                    </div>
                  </ButtonsGroup>
                </Root>
              </FiltersFormModal>
            </Wrapper>
            {children}
          </>
        );
      }}
    />
  );
};

const Wrapper = styled.div`
  background: #f9f9f9;
`;

const Header = styled.div`
  background: #fff;
`;

const ButtonsGroup = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;

    > button {
      margin-right: 8px;
    }
  }
`;

const Button = styled(UIButton)`
  font-size: 16px;
`;

const Root = styled.form`
  background: #ffffff;
  width: 100%;
  max-width: ${PAGE_WIDTH.NORMAL}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const SugggestDiv = styled.div`
  display: flex;
  max-width: ${PAGE_WIDTH.NORMAL}px;
  align-items: center;
  position: absolute;
`;
const SugggestURL = styled.a`
  font-size: 12px;
  color: var(--blue);
  margin-left: 6px;
  position: relative;
  bottom: -1px;
`;

export default FilterForm;
