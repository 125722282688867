/* eslint-disable react-hooks/exhaustive-deps */
import Jumbotron from 'components/Jumbotron';
import { UISpinner } from 'components/UI';
import { useOperation } from 'hooks/common';
import { News } from 'interfaces/companies';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import ProfileView from 'components/ProfileView';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { fetchTeamById, fetchTeamNews } from 'store/ducks/team/operations';
import { getTeam, getTeamNews } from 'store/ducks/team/selectors';
import { StateProps } from 'store/interfaces';
import styled from 'styled-components';

const TeamDetail = () => {
  const params: { teamId: string } = useParams();

  const [onfetchTeam, isLoading] = useOperation(fetchTeamById);
  const [onFetchTeamNews] = useOperation(fetchTeamNews);

  const team = useSelector(getTeam);
  const news = useSelector<StateProps, News[]>(getTeamNews);

  useEffect(() => {
    onfetchTeam(params.teamId);
    onFetchTeamNews(params.teamId);
  }, []);

  if (!team) return null;

  return (
    <ContentBlockWrapper header={<Jumbotron name={team.organizationName} back />}>
      {isLoading ? <Spinner /> : <ProfileView team={team} news={news} />}
    </ContentBlockWrapper>
  );
};

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 242px);
`;

export default TeamDetail;
