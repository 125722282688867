import React from 'react';
import styled from 'styled-components';
import { UIButton, UICheckbox, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { FieldRenderProps, Form } from 'react-final-form';
import { Field } from 'components/FinalForm';
import {
  FieldDatePicker,
  FieldTextArea,
  FieldTextInput,
  FieldUploadFile,
} from 'components/FormFields';
import { fileExtensionValidator, required } from 'utils/validation';
import UIAsterisk from 'components/UI/UIAsterisk';
import { useSelector } from 'react-redux';
import { getCompanyCurrent } from 'store/ducks/companies/selectors';
import { IssueMappingSurveyData } from 'interfaces/issueMapping';
import { FormItalic, FormLabel } from 'components/UI/form';
import UploadButton from 'components/ActionButtons/UploadButton';
import { fetchErrorHandler } from 'utils/errorHandlers';
import Toast from 'services/Toast';
import {
  fetchDownloadIssueMappingSurveyTemplate,
  fetchUploadIssueSurveyContacts,
} from 'store/ducks/issueMapping/api';
import { useDownloadFile } from 'hooks/common';

interface Props extends InjectedProps {
  handleAccess: (param: IssueMappingSurveyData) => void;
  survey?: IssueMappingSurveyData;
}

const EmployeeSurveyModal = ({ onClose, handleAccess, survey, ...modalProps }: Props) => {
  useLockBodyScroll();
  const downloadFile = useDownloadFile(fetchDownloadIssueMappingSurveyTemplate);

  const companyCurr = useSelector(getCompanyCurrent);

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 0' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '798px',
        height: '80vh',
        overflow: 'auto',
      }}
    >
      <Title>Create Employee Survey</Title>
      <Form<IssueMappingSurveyData>
        initialValues={{
          ...survey,
          emailMessage:
            survey?.emailMessage ||
            'We are working to uncover the causes and social issues that our employees and consumers care most about. Through these data-driven results, we will help to identify the right issues for our company to stand for and develop a strategic roadmap to align employee activities, marketing, and corporate social responsibility activities. Please take 5-10 minutes to respond to this survey in order to help inform us of social issues and employee activities you as an employee would like for us to explore.',
        }}
        onSubmit={values => {
          handleAccess({ ...values, type: 'employee' });
        }}
        render={({ handleSubmit, form, values, hasValidationErrors }) => (
          <FormBody>
            <FieldsContent>
              <Field
                label={
                  <div>
                    Survey Title <UIAsterisk />
                  </div>
                }
                name="title"
                component={FieldTextInput}
                validate={required}
              />
              <Field
                label={
                  <div>
                    Survey End Date <UIAsterisk />
                  </div>
                }
                name="endDate"
                component={FieldDatePicker}
                minDate={new Date()}
              />
              <FormTitle>Survey Email</FormTitle>
              <Field
                label={
                  <div>
                    Survey Email Subject <UIAsterisk />
                  </div>
                }
                name="emailSubject"
                component={FieldTextInput}
              />
              <Field
                label={
                  <div>
                    Survey Email Message <UIAsterisk />
                  </div>
                }
                name="emailMessage"
                component={FieldTextArea}
              />
              <Field
                name="logoKey"
                label="Company Logo"
                subtitle="The following file types are accepted: .png, .jpg, .jpeg"
                component={FieldUploadFile}
                hideIcon
                title="Upload"
                category="issue-mapping-employee-survey"
                htmlId="employee-survey-email-logo"
                isSingleFile
                accept=".png,.jpg,.jpeg"
                acceptValidator={fileExtensionValidator(
                  ['png', 'jpg', 'jpeg'],
                  'Only the following image files are allowed: .png,.jpg,.jpeg files',
                )}
              />
              <Field name="contacts">
                {({ input }: FieldRenderProps<IssueMappingSurveyData['contacts']>) => {
                  return (
                    <>
                      <FormLabel>
                        Upload employee List <UIAsterisk />
                      </FormLabel>
                      <FormItalic>
                        The following file types are accepted: .xlsx
                      </FormItalic>
                      <UploadButton
                        name="Upload"
                        accept=".xls,.xlsx,.csv"
                        allowUploadSameFile
                        acceptValidator={fileExtensionValidator(
                          ['xlsx'],
                          'Must be xlsx files',
                        )}
                        inputId="create-employee-survey-form-upload"
                        uploadHandler={files => {
                          if (!files.length) return Promise.resolve(undefined);

                          return fetchUploadIssueSurveyContacts(files[0])
                            .then(contacts => {
                              if (!contacts) return;
                              input.onChange(contacts);
                              return Toast.success('File uploaded successfully');
                            })
                            .catch(err => {
                              fetchErrorHandler(err);
                            });
                        }}
                      />
                    </>
                  );
                }}
              </Field>
              {values.contacts?.length && (
                <EmployeeListUploadedText>
                  {values.contacts.length} recipient{values.contacts.length > 1 && 's'}{' '}
                  {values.contacts.length > 1 ? 'are' : 'is'} uploaded.
                </EmployeeListUploadedText>
              )}
              <DownloadText>
                Download Excel template <span onClick={() => downloadFile('')}>here</span>
              </DownloadText>

              <FormTitle>Survey Questions</FormTitle>
              <QuestionText>
                The following questions will be included in the employee survey.
              </QuestionText>

              <QuestionText>
                1. Do you think it is important for {companyCurr?.name} to formally
                support a cause or charity?
              </QuestionText>
              <FakeRadioWrapper>
                <FakeRadio>Yes</FakeRadio>
                <FakeRadio>No</FakeRadio>
              </FakeRadioWrapper>
              <FakeInput>Please explain</FakeInput>
              <QuestionText>
                2. What cause do you think {companyCurr?.name} should support as a brand?
              </QuestionText>
              <FakeCheckboxList>
                {[
                  'Single',
                  'Animal Rights & Welfare',
                  'Arts & Education',
                  'Cancer',
                  "Children's Health",
                  'Education',
                  'Environment & Sustainability',
                  'Gender Equality',
                  'Gun Safety',
                  'Health',
                  'Human & Civil Rights/Social Justice',
                  'Human & Social Services',
                  'Humanitarian & Disaster Relief',
                  'Hunger Relief',
                  'International Development',
                  'LGBTQ Equality',
                  'Mental Health',
                  'Poverty',
                  'Veteran Affairs',
                  'Wildlife Conservation',
                  'Other',
                ].map(item => (
                  <li key={item}>
                    <UICheckbox />
                    {item}
                  </li>
                ))}
              </FakeCheckboxList>

              <QuestionText>
                3. Why do you think that {companyCurr?.name} should support this
                particular cause?
              </QuestionText>
              <FakeInput>Please explain</FakeInput>
              <QuestionText>
                4. How do you as an individual want to engage with a cause or
                organization?
              </QuestionText>
              <FakeCheckboxList>
                {[
                  'One-time or recurring giving/donation',
                  'Skills-based volunteering',
                  'Hands-on volunteering',
                  'Event participation',
                  'Raise funds through peer-to-peer fundraising',
                  'Awareness campaigns (i.e., social share for raising awareness)',
                  'Nothing',
                  'Other',
                ].map(item => (
                  <li key={item}>
                    <UICheckbox /> {item}
                  </li>
                ))}
              </FakeCheckboxList>

              <QuestionText>
                5. If {companyCurr?.name} formalizes a corporate cause partnership, what
                engagement opportunities do you want them to offer for employees?
              </QuestionText>
              <FakeCheckboxList>
                {[
                  'One-time or recurring giving',
                  'Skills-based volunteering',
                  'Hands-on volunteering',
                  'Awareness campaigns (i.e. social share for raising awareness)',
                  'Fundraise through peer-to-peer fundraising',
                  'Paycheck deduction',
                  'Event',
                  'Time Off',
                  'Other',
                ].map(item => (
                  <li key={item}>
                    <UICheckbox /> {item}
                  </li>
                ))}
              </FakeCheckboxList>

              <QuestionText>
                6. Would you be more likely to give to a cause if {companyCurr?.name}{' '}
                matched your donation 1:1?
              </QuestionText>
              <FakeRadioWrapper>
                <FakeRadio>Yes</FakeRadio>
                <FakeRadio>No</FakeRadio>
                <FakeRadio>Not Sure</FakeRadio>
              </FakeRadioWrapper>
              <QuestionText>
                7. Please rank the following causes or organizations in order of
                importance to you, based on how passionate or not you are about them
                (where 1 is the most important).
              </QuestionText>
              <FakeOrderList>
                {[
                  'Animal Rights and Welfare',
                  'Arts and Culture',
                  'Civil and Social Action',
                  'Environmental Sustainability',
                  'Environmental Conservation',
                  'Advocacy',
                  'Natural Disaster Relief and Emergency Response',
                  'International Development: Hunger and Poverty',
                  'International Development: Women and Children',
                  'International Development: Health',
                  'Education, Employment and Training',
                  'Youth Development and Education',
                  'Research and Public Policy',
                  'Health and Healthcare',
                  'Mental Health',
                  'Women and Girls',
                  'Children and Family Services',
                  'Hunger Relief (local)',
                  'Community Development and Services',
                  'LGBTQ Rights and Advocacy',
                ].map(item => (
                  <li key={item}>{item}</li>
                ))}
                <FakeInput>Other</FakeInput>
              </FakeOrderList>
              <QuestionText>
                8. Please rank the following causes based on where you believe{' '}
                {companyCurr?.name} should direct its Corporate Social Responsibility
                (where 1 is the most important).
              </QuestionText>
              <FakeOrderList>
                {[
                  'Animal Rights and Welfare',
                  'Arts and Culture',
                  'Civil and Social Action',
                  'Environmental Sustainability',
                  'Environmental Conservation',
                  'Advocacy',
                  'Natural Disaster Relief and Emergency Response',
                  'International Development: Hunger and Poverty',
                  'International Development: Women and Children',
                  'International Development: Health',
                  'Education, Employment and Training',
                  'Youth Development and Education',
                  'Research and Public Policy',
                  'Health and Healthcare',
                  'Mental Health',
                  'Women and Girls',
                  'Children and Family Services',
                  'Hunger Relief (local)',
                  'Community Development and Services',
                  'LGBTQ Rights and Advocacy',
                ].map(item => (
                  <li key={item}>{item}</li>
                ))}
                <FakeInput>Other</FakeInput>
              </FakeOrderList>
            </FieldsContent>

            <BtnGroup>
              <Btn modifiers="secondary" title="Cancel" handleClick={onClose} />
              <div>
                <Btn
                  modifiers="secondary"
                  title="Save as a Draft"
                  disabled={
                    (survey && survey?.status !== 'Inactive') || hasValidationErrors
                  }
                  handleClick={() => {
                    form.change('isDraft', true);
                    handleSubmit();
                  }}
                />
                <Btn
                  modifiers="primary"
                  title="Send Survey"
                  handleClick={() => {
                    form.change('isDraft', false);
                    handleSubmit();
                  }}
                  disabled={
                    (survey && survey?.status !== 'Inactive') ||
                    hasValidationErrors ||
                    ['endDate', 'emailSubject', 'emailMessage', 'contacts'].some(
                      item => !values[item],
                    )
                  }
                />
              </div>
            </BtnGroup>
          </FormBody>
        )}
      />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 46px;
  text-align: center;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;
    gap: 16px;
  }
`;

const Btn = styled(UIButton)`
  display: block;
  font-size: 14px;
`;

const FormBody = styled.div`
  margin: 0 62px;
`;

const FieldsContent = styled.div`
  margin: 0 82px;
`;

const FormTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: #000000;
  margin-bottom: 16px;
`;

const QuestionText = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.02em;
  margin-bottom: 24px;
`;

const FakeRadioWrapper = styled.div`
  display: flex;
  column-gap: 32px;
  margin-bottom: 16px;
`;

const FakeRadio = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &::before {
    content: '';
    border: 1px solid #c4c4c4;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    margin-bottom: 5px;
  }
`;

const FakeInput = styled.div`
  height: 46px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  margin-bottom: 16px;
  color: #737373;
  display: flex;
  align-items: center;
  padding: 12px;
`;

const DownloadText = styled.div`
  font-size: 12px;
  color: #737373;
  margin: 10px 0 24px;

  > span {
    color: var(--blue);
    cursor: pointer;
  }
`;

const FakeCheckboxList = styled.ul`
  height: 96px;
  overflow: auto;
  margin-bottom: 16px;
  font-size: 12px;

  li {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;
  }
`;

const FakeOrderList = styled(FakeCheckboxList)`
  height: 140px;

  li::before {
    content: '';
    height: 32px;
    width: 40px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
  }
`;

const EmployeeListUploadedText = styled.div`
  padding: 8px 0px;
  font-size: 12px;
  color: #737373;
`;

export default EmployeeSurveyModal;
