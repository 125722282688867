import { UIDivider } from 'components/UI';
import React from 'react';
import styled from 'styled-components';
import { moneyNumberFormat } from 'utils/formatters';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';

interface Props {
  consumers: number;
  employees: number;
  themeColor?: string;
}

const CashMetric = ({ consumers, employees, themeColor }: Props) => {
  return (
    <Root $themeColor={themeColor}>
      <MetricTitle>Fundraising: Cash</MetricTitle>
      <Content>
        <Value>
          <div>{moneyNumberFormat(consumers)}</div>
          <div>Consumers</div>
        </Value>
        <Divider type="vertical" />
        <Value>
          <div>{moneyNumberFormat(employees)}</div>
          <div>Employees</div>
        </Value>
      </Content>
    </Root>
  );
};

const Root = styled(MetricRoot)`
  /* background-color: #18536a; */
`;

const Content = styled(MetricContent)`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Divider = styled(UIDivider)`
  height: 130px;
  margin: 0;
  flex-shrink: 0;
`;

const Value = styled.div`
  font-size: 14px;
  width: 50%;

  & > div {
    text-align: center;
    font-weight: 500;
    margin-bottom: 8px;
  }
`;

export default CashMetric;
