import { combineReducers } from 'redux';
import { campaignReducer } from './campaign';
import { companiesReducer } from './companies';
import { contactsReducer } from './contacts';
import { gameplanReducer } from './gameplan';
import { pricingReducer } from './pricing';
import { prospectsReducer } from './prospects';
import { prospectsTeamsReducer } from './prospectsTeams';
import { roiReducer } from './roi';
import { searchReducer } from './search';
import { teamReducer } from './team';
import { userReducer } from './user';
import { issueMappingReducer } from './issueMapping';
import { audienceReducer } from './audience';
import { givingReducer } from './giving';

export const rootReducer = combineReducers({
  user: userReducer,
  search: searchReducer,
  team: teamReducer,
  companies: companiesReducer,
  prospects: prospectsReducer,
  gameplan: gameplanReducer,
  pricing: pricingReducer,
  roi: roiReducer,
  prospectsTeams: prospectsTeamsReducer,
  contacts: contactsReducer,
  campaign: campaignReducer,
  issueMapping: issueMappingReducer,
  audience: audienceReducer,
  giving: givingReducer,
});
