/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Avatar from 'react-avatar';

import Filters from 'components/Filters';
import { useFileUrl, useOperation } from 'hooks/common';
import { getFullName, capitalizeText } from 'utils/formatters';
import { Prospect } from 'interfaces/prospects';
import { fetchTeamNamesByIds } from 'store/ducks/team/operations';

const CardBatchTeam = ({
  id,
  name,
  filters,
  lastAuthor,
  updatedAt,
  prospectsAvailable,
}: Prospect) => {
  const [teamNames, setTeamNames] = useState<string[]>([]);

  const { fileUrl } = useFileUrl({ imgKey: lastAuthor?.avatarKey });

  const lastActivity = updatedAt && format(new Date(updatedAt), 'dd MMM yyyy');
  const userName = getFullName(lastAuthor?.firstName, lastAuthor?.lastName);
  const userRole = capitalizeText(lastAuthor?.role);

  const [onFetchTeamNamesByIds] = useOperation(fetchTeamNamesByIds);

  useEffect(() => {
    if (!filters.ids?.length) return;
    onFetchTeamNamesByIds((filters as any)?.ids || []).then(names => {
      setTeamNames(names);
    });
  }, [filters]);

  return (
    <Root>
      <Header>
        <Link to={`/SearchBatch/${id}`}>
          <Name>{name || 'No Name'}</Name>
        </Link>
      </Header>
      <Filters
        width={488}
        marginList="0 4px"
        filters={filters}
        take={3}
        companiesNames={teamNames}
      />
      <Content>
        <Block>
          <BlockName>№ of Prospects Available</BlockName>
          <BlockNumber>{prospectsAvailable}</BlockNumber>
        </Block>
      </Content>
      <Footer>
        <UserWrapper>
          <Avatar round name={userName} src={fileUrl} size="40" />
          <UserInfo>
            <UserName>{userName}</UserName>
            <UserRole>{userRole}</UserRole>
          </UserInfo>
        </UserWrapper>

        <div>
          <LastActivity>Last Activity</LastActivity>
          <LastActivityDate>{lastActivity}</LastActivityDate>
        </div>
      </Footer>
    </Root>
  );
};

const Root = styled.section`
  width: 536px;
  min-height: 312;
  background: #ffffff;
  border-radius: 6px;
  margin: 12px 12px;
  padding: 24px;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UserInfo = styled.div`
  margin-left: 12px;
`;

const UserWrapper = styled.div`
  display: flex;
`;

const UserName = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  margin-bottom: 4px;
`;

const UserRole = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
`;

const LastActivity = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
`;

const LastActivityDate = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
`;

const Header = styled.header`
  padding-bottom: 9px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 16px;
`;

const Name = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
`;

const Content = styled.main`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Block = styled.div`
  width: 157px;
  background: #f9f9f9;
  border-radius: 4px;
  height: 71px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const BlockName = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
  margin-bottom: 8px;
`;

const BlockNumber = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
`;

export default CardBatchTeam;
