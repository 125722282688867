export default `
.react-tabs {
  border-radius: 6px;
}
.react-tabs__tab-list {
  display: flex;
  border-bottom: 1px solid #e8e8e8;
}
.react-tabs__tab {
  display: flex;
  justify-content: center;
  margin-right: 24px;
  font-size: 16px;
  line-height: 150%;
  color: var(--darkGray);
  position: relative;
  padding-bottom: 6px;
  cursor: pointer;
  user-select: none;
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    width: 100%;
    height: 3px;
    background: transparent;
  }
  &:hover {
    color: var(--blue);
    &:after {
      background: var(--blue);
    }
  }
}
.react-tabs__tab--selected {
  color: var(--blue);
  &:after {
    background: var(--blue);
  }
}
.react-tabs__tab-panel--selected {
  margin-top: 18px;
  height: auto;
  overflow-y: auto;
}
`;
