import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { FieldRenderProps } from 'react-final-form';
import { FormError, FormLabel } from 'components/UI/form';

interface Props extends FieldRenderProps<string | number> {
  hidePrefix?: boolean;
  suffix?: string;
  tip?: string;
}

const MaskedMoneyTextInput = ({
  input,
  label,
  disabled,
  meta,
  hidePrefix,
  suffix,
  tip,
  ...rest
}: Props) => {
  const { value, onChange, onBlur } = input;
  const { error, touched, submitError, dirtySinceLastSubmit } = meta;
  const isError = (error && touched) || (submitError && !dirtySinceLastSubmit);

  return (
    <Field $disabled={disabled} $error={isError}>
      {label && <FormLabel>{label}</FormLabel>}
      <div data-tip={tip}>
        <NumberFormat
          onValueChange={val => onChange(val.value)}
          onBlur={onBlur}
          value={value}
          thousandSeparator={true}
          prefix={hidePrefix ? '' : '$'}
          suffix={suffix}
          disabled={disabled}
          {...rest}
        />
      </div>

      {isError && <Error meta={meta} />}
    </Field>
  );
};

const Field = styled.div<{ $disabled: boolean; $error: boolean }>`
  margin-bottom: 24px;
  width: 100%;

  & input {
    width: 100%;
    color: var(--black);
    font-size: 16px;
    line-height: 155%;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 16px;
    outline: none;

    ${({ $disabled }) =>
      $disabled &&
      `
      background: #F9F9F9;
      color: var(--gray);
      border: 1px solid transparent;
      cursor: not-allowed;
      &:focus{
        border: 1px solid transparent;
      }
    `}

    ${({ $error }) =>
      $error &&
      `
      background: #FFF2F2;
      border: 1px solid var(--red);
      &:focus{
        border: 1px solid var(--red) !important;
      }
    `}

    &:focus {
      border: 1px solid #2baee0;
    }

    &:-webkit-input-placeholder {
      color: var(--darkGray);
    }

    &:-moz-placeholder {
      color: var(--darkGray);
    }

    &:-ms-input-placeholder {
      color: var(--darkGray);
    }

    &:-moz-placeholder {
      color: var(--darkGray);
    }
  }
`;

const Error = styled(FormError)`
  position: absolute;
`;

export default MaskedMoneyTextInput;
