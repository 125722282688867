import { PartnershipsListRes } from 'interfaces/fundraising';
import { ROIListSuggest } from 'interfaces/roi';
import * as types from './types';

export const setPartnershipsList = (partnershipsList: PartnershipsListRes) =>
  ({
    type: types.SET_Partnerships_LIST,
    payload: partnershipsList,
  } as const);

export const setParROISuggest = (teams: ROIListSuggest) =>
  ({
    type: types.SET_PAR_ROI_SUGGEST,
    payload: teams,
  } as const);
