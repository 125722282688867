import React from 'react';
import styled from 'styled-components';

import { ProgressBar, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';

interface Props {
  progressValue: number;
  fileName: string;
}

const UPloadROIProgressModal = ({
  onClose,
  progressValue,
  fileName,
  ...modalProps
}: Props & InjectedProps) => {
  useLockBodyScroll();

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 46px', height: 'calc(100% - 23px - 28px)' }}
      style={{
        width: '100%',
        maxWidth: '559px',
        height: '180px',
        overflow: 'hidden',
      }}
    >
      <Title>Import Partners</Title>
      <Body>
        <ProgressBar value={progressValue} color="var(--blue)" barHeight={16} />
        <FileName>{fileName}</FileName>
      </Body>
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 28px;
  text-align: center;
`;

const Body = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const FileName = styled.div`
  font-size: 14px;
  line-height: 145%;
  color: #000;
  margin-top: 6px;
`;

export default UPloadROIProgressModal;
