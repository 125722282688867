import { StateProps } from 'store/interfaces';
import { createSelector } from 'reselect';

export const getSavedLists = (state: StateProps) => state.prospects.savedLists.items;
export const getSavedListsMeta = (state: StateProps) => state.prospects.savedLists.meta;

export const getSavedListById = (listId: string) =>
  createSelector([getSavedLists], lists => lists.find(({ id }) => listId === id));

export const getTotalGameplanInfo = (state: StateProps) =>
  state.prospects.savedLists.gameplanInfo;

export const getSuggestSavedList = (state: StateProps) =>
  state.prospects.suggestSavedLists.items.map(item => ({ id: item.id, name: item.name }));
