import { ExportButton } from 'components/ActionButtons';
import { Field } from 'components/FinalForm';
import { FieldDatePicker, FieldSingleSelect } from 'components/FormFields';
import Pagination from 'components/Pagination';
import TotalRow from 'components/TotalRow';
import { UIButton } from 'components/UI';
import { useDownloadFile, useOperation, useSortStatus } from 'hooks/common';
import useFakePaginationStatus from 'hooks/common/useFakePagination';
import { CampaignReportListItem, CampaignReportListReq } from 'interfaces/campaign';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useSelector } from 'react-redux';
import { fetchCampaignReportExport } from 'store/ducks/campaign/api';
import {
  clearFundraisingReport,
  fetchCampaignReportList,
  fetchCurrentAllCampaigns,
  fetchPartnerByCampaign,
  fetchReportList,
} from 'store/ducks/campaign/operations';
import {
  getCampaignReportList,
  getCampaignSelectOptions,
  getPartnersbyCampaignSelectOptions,
} from 'store/ducks/campaign/selectors';
import styled from 'styled-components';
import ReportList from './ReportList';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { getReportList } from 'store/ducks/campaign/selectors';
import Toast from 'services/Toast';
type FormData = Required<CampaignReportListReq>;

const FundraisingReport = () => {
  const { location, push } = useHistory();
  const [onFetchReportList, isLoadings] = useOperation(fetchReportList);
  const [lastFilter, setLastFilter] = useState<CampaignReportListReq | undefined>(
    undefined,
  );

  const [onFetchCurrentAllCampaigns] = useOperation(fetchCurrentAllCampaigns);
  const [onFetchPartnerByCampaign] = useOperation(fetchPartnerByCampaign);
  const [onFetchCampaignReportList] = useOperation(fetchCampaignReportList);
  const [onClearFundraisingReport] = useOperation(clearFundraisingReport);
  const [isCSVLoading, setIsCSVLoading] = useState(false);
  const [isXLSXLoading, setIsXLSXLoading] = useState(false);
  const [isRunReport, setIsRunReport] = useState(false);
  const { items, meta } = useSelector(getReportList);

  const campaignSelectOptions = useSelector(getCampaignSelectOptions);
  const partnersByCampaignSelectOptions = useSelector(getPartnersbyCampaignSelectOptions);
  const campaignReportList = useSelector(getCampaignReportList);

  const exportFile = useDownloadFile(fetchCampaignReportExport);

  const { data, sortStatus, handleSwitch } = useSortStatus(campaignReportList);
  const {
    data: pagedCampaignReportList,
    changePage,
    limit,
    page,
    total,
  } = useFakePaginationStatus<CampaignReportListItem>({
    datasource: data,
    initialPage: 1,
    limit: 15,
  });

  useEffect(() => {
    onFetchCurrentAllCampaigns(undefined);
    return () => {
      onClearFundraisingReport(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsRunReport(false);
  }, [items, isLoadings]);

  const onSearch = (page: number, values: any) => {
    const searchParams: CampaignReportListReq = {
      ...values,
      campaignId: values.campaignId === 'All' ? '' : values.campaignId,
      partnerId: values.partnerId === 'All' ? '' : values.partnerId,
      page: page,
    };
    onFetchReportList(searchParams).then(() => {
      setLastFilter(searchParams);
    });
  };

  return (
    <Root>
      <Form<FormData>
        onSubmit={values => {
          setIsRunReport(true);
          const pageString = queryString.parse(location.search)?.page?.toString() || '';
          const searchParams: CampaignReportListReq = {
            ...values,
            campaignId: values.campaignId === 'All' ? '' : values.campaignId,
            partnerId: values.partnerId === 'All' ? '' : values.partnerId,
            page: Number(pageString) || 1,
          };
          // onFetchCampaignReportList(searchParams).then(() => {
          //   setLastFilter(searchParams);
          // });
          onFetchReportList(searchParams).then(() => {
            setLastFilter(searchParams);
          });
        }}
        render={({ handleSubmit, form, values }) => {
          return (
            <>
              <TotalRow
                title={`${total || 0} Campaign${total > 1 ? 's' : ''}`}
                maxWidth="unset"
              >
                <Actions>
                  <Button
                    title="Clear filters"
                    modifiers="third"
                    handleClick={() => form.reset()}
                  />
                  <Button
                    title="Run Report"
                    modifiers="primary"
                    handleClick={handleSubmit}
                    isLoading={isRunReport}
                  />
                </Actions>
              </TotalRow>
              <FiltersBar>
                <Field
                  name="reportType"
                  label="Filters"
                  placeholder="Report Type"
                  component={FieldSingleSelect}
                  isClearable={false}
                  initialValue="Transaction Report"
                  options={[{ label: 'Transaction Report', value: 'Transaction Report' }]}
                />
                <Field
                  name="campaignId"
                  placeholder="Campaign Name"
                  component={FieldSingleSelect}
                  options={[{ label: 'All', value: 'All' }, ...campaignSelectOptions]}
                />
                <OnChange name="campaignId">
                  {(value, previous) => {
                    if (value === previous) return;
                    if (!value) return form.change('partnerId', '');
                    onFetchPartnerByCampaign(value === 'All' ? '' : value);
                    form.change('partnerId', '');
                  }}
                </OnChange>
                <Field
                  name="partnerId"
                  placeholder="Partner Name"
                  component={FieldSingleSelect}
                  options={[
                    { label: 'All', value: 'All' },
                    ...partnersByCampaignSelectOptions,
                  ]}
                />
                <Field
                  name="dateRange"
                  placeholder="Date Range"
                  component={FieldSingleSelect}
                  isClearable={false}
                  options={[
                    { label: 'All', value: 'All' },
                    { label: 'Last 6 months', value: 'Last6' },
                    { label: 'Current Month', value: 'Current' },
                    { label: 'Custom Date Range', value: 'Custom' },
                  ]}
                />
                {values.dateRange === 'Custom' && (
                  <>
                    <Field
                      name="startDate"
                      label="Start Date"
                      dateFormat="MM/dd/yyyy"
                      component={FieldDatePicker}
                    />
                    <Field
                      name="endDate"
                      label="End Date"
                      dateFormat="MM/dd/yyyy"
                      component={FieldDatePicker}
                    />
                  </>
                )}
              </FiltersBar>

              <Actions>
                <div className="export-group">
                  <ExportButton
                    name={
                      <>
                        Export <strong>.CSV</strong>
                      </>
                    }
                    uploadHandler={() => {
                      if (lastFilter) {
                        setIsCSVLoading(true);
                        exportFile({ ...lastFilter, exportType: 'csv' }).then(
                          (e: any) => {
                            if (e?.file) {
                              setIsCSVLoading(false);
                            }
                          },
                        );
                      }
                    }}
                    isloading={isCSVLoading}
                  />
                  <ExportButton
                    name={
                      <>
                        Export <strong>.XLSX</strong>
                      </>
                    }
                    uploadHandler={
                      () => {
                        if (lastFilter) {
                          setIsXLSXLoading(true);
                          exportFile({ ...lastFilter, exportType: 'xlsx' }).then(
                            (e: any) => {
                              if (e?.file) {
                                setIsXLSXLoading(false);
                              }
                            },
                          );
                        }
                      }
                      // lastFilter && exportFile({ ...lastFilter, exportType: 'xlsx' })
                    }
                    isloading={isXLSXLoading}
                  />
                </div>
                {/* <Pagination
                  total={total}
                  pageLimit={limit}
                  page={page}
                  submitHandler={changePage}
                /> */}
                {items && (
                  <Pagination
                    total={meta.totalItems}
                    pageLimit={Number(meta.itemsPerPage)}
                    page={Number(meta.currentPage)}
                    submitHandler={(page: number) => onSearch(page, values)}
                  />
                )}
              </Actions>
              <ReportList rows={items} onSort={handleSwitch} sortStatus={sortStatus} />
            </>
          );
        }}
      />
    </Root>
  );
};

const Root = styled.div`
  margin-top: 28px;
`;

const Actions = styled.div`
  margin-bottom: 26px;
  display: flex;
  justify-content: space-between;

  .export-group {
    display: flex;
    gap: 32px;
  }
`;

const Button = styled(UIButton)`
  margin-left: 24px;
  width: 160px;
  font-size: 16px;
`;

const FiltersBar = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-end;

  & > div {
    width: 190px;
  }

  & > div:nth-child(1) {
    width: 200px;
  }

  & > div:nth-child(4) {
    width: 204px;
  }

  .field-date-picker {
    width: 166px;
  }
`;

export default FundraisingReport;
