import React, { useEffect } from 'react';
import Jumbotron from 'components/Jumbotron';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { PieChart, Pie, Cell } from 'recharts';
import { useOperation } from 'hooks/common';
import { fetchROI, fetchSurveyStatisticById } from 'store/ducks/roi/operations';
import { useSelector } from 'react-redux';
import { getROI, getROISurveyStatistic } from 'store/ducks/roi/selectors';
import { ROISurvey } from 'interfaces/roi';
import { capitalizeText } from 'utils/formatters';

const COLORS_1 = ['#2BAEE0', '#E0A02A', '#AAB0B5'];
const COLORS_2 = ['#F05658', '#E0A02A', '#29AA26'];

const renderLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={'middle'} dominantBaseline="central">
      {value || ''}
    </text>
  );
};

const Chart = ({
  data,
  colors = COLORS_1,
}: {
  data: { name: string; value: number }[];
  colors?: string[];
}) => {
  if (data.every(item => !item.value)) return <div>No data available.</div>;
  return (
    <ChartRoot>
      <PieChart width={122} height={122}>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={60}
          labelLine={false}
          label={renderLabel}
          isAnimationActive={false}
        >
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </PieChart>

      <LegendGroup>
        {data.map((item, index) => (
          <div key={index}>
            <div
              className="color"
              style={{ background: colors[index % colors.length] }}
            />
            {item.name}
          </div>
        ))}
      </LegendGroup>
    </ChartRoot>
  );
};

const ROISurveyResponse = () => {
  const params = useParams() as {
    surveyId: string;
    roiId: string;
    surveyType: ROISurvey['type'];
  };

  const [onFetchSurveyStatisticById, , isStatisticLoading] = useOperation(
    fetchSurveyStatisticById,
  );
  const [onFetchROI, , isROILoading] = useOperation(fetchROI);

  const statistic = useSelector(getROISurveyStatistic);
  const roi = useSelector(getROI);
  const isCompany = roi?.isCompany;
  // const corporationName = roi?.companyName;
  // const organizationName = roi?.isCompany
  //   ? roi?.company?.name
  //   : roi?.team?.organizationName;

  const corporationName = isCompany
    ? roi?.isCompany
      ? roi.company?.name
      : roi?.team?.organizationName
    : roi?.companyName;
  const organizationName = isCompany
    ? roi?.companyName
    : roi?.isCompany
    ? roi.company?.name
    : roi?.team?.organizationName;

  useEffect(() => {
    onFetchSurveyStatisticById(params.surveyId);
    onFetchROI(params.roiId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentBlockWrapper
      loading={isStatisticLoading || isROILoading}
      header={<Jumbotron name={`${capitalizeText(params.surveyType)} Survey`} back />}
    >
      <Body>
        <Panel>
          <DataValueGroup>
            <DataValue>
              <div>No. of Recipients</div>
              <div className="value">{statistic?.totalRecipents || 'N/A'}</div>
            </DataValue>
            <DataValue>
              <div>No. of Responses</div>
              <div className="value">{statistic?.totalResponse || 'N/A'}</div>
            </DataValue>
            <DataValue>
              <div>Response Rate</div>
              <div className="value">
                {statistic?.totalResponseRate ? `${statistic.totalResponseRate}%` : 'N/A'}
              </div>
            </DataValue>
          </DataValueGroup>
        </Panel>
        <Panel>
          <div>
            1. Have you heard of {corporationName}&apos;s partnership with{' '}
            {organizationName}?
          </div>
          <Chart
            data={
              statistic?.firstChart.map(item => ({
                ...item,
                value: Number(item.value),
              })) || []
            }
          />
        </Panel>
        <Panel>
          {params.surveyType === 'consumer' ? (
            <div>
              2. Do you provide your time by volunteering with {organizationName}?
            </div>
          ) : (
            <div>
              2. Do you participate in {corporationName}&apos;s partnership with{' '}
              {organizationName} by volunteering, donating, or attending events?
            </div>
          )}
          <Chart
            data={
              statistic?.secondChart.map(item => ({
                ...item,
                value: Number(item.value),
              })) || []
            }
          />
        </Panel>
        <Panel>
          {params.surveyType === 'consumer' ? (
            <>
              <div>3. Do you donate your money to {organizationName}?</div>
              <Chart
                data={
                  Array.isArray(statistic?.thirdChart)
                    ? statistic?.thirdChart.map(item => ({
                        ...item,
                        value: Number(item.value),
                      })) || []
                    : []
                }
              />
            </>
          ) : (
            <>
              <div>3. How many hours have you volunteered with {organizationName}?</div>
              <Hours>
                <div>Total Volunteer Hours</div>
                <div>
                  {typeof statistic?.thirdChart === 'string'
                    ? `${statistic.thirdChart} hrs`
                    : 'N/A'}
                </div>
              </Hours>
            </>
          )}
        </Panel>
        <Panel>
          <div>
            4. How do you feel about {corporationName} supporting {organizationName}?
          </div>
          <Chart
            data={
              statistic?.fourthChart.map(item => ({
                ...item,
                value: Number(item.value),
              })) || []
            }
            colors={COLORS_2}
          />
        </Panel>
        <Panel>
          {params.surveyType === 'consumer' ? (
            <div>
              5. Do you feel more loyal to {corporationName} because of their support of{' '}
              {organizationName}?
            </div>
          ) : (
            <div>
              5. Do you feel more loyal to {corporationName} because they support{' '}
              {organizationName}?
            </div>
          )}
          <Chart
            data={
              statistic?.fifthChart.map(item => ({
                ...item,
                value: Number(item.value),
              })) || []
            }
          />
        </Panel>
      </Body>
    </ContentBlockWrapper>
  );
};

const Body = styled.div`
  max-width: 847px;
`;

const DataValueGroup = styled.div`
  display: flex;
  gap: 34px;
`;

const DataValue = styled.div`
  font-size: 16px;
  display: flex;
  gap: 16px;
  align-items: center;

  .value {
    font-weight: 500;
    font-size: 24px;
  }
`;

const Panel = styled.div`
  padding: 30px 24px;
  background-color: #fff;
  margin-bottom: 24px;

  & > div:first-child {
    margin-bottom: 34px;
  }
`;

const ChartRoot = styled.div`
  display: flex;
  align-items: center;
`;

const LegendGroup = styled.div`
  margin-left: 135px;

  & > div {
    display: flex;
  }

  .color {
    width: 44px;
    height: 20px;
    border-radius: 99em;
    margin: 0 15px 17px 0;
  }
`;

const Hours = styled.div`
  display: flex;
  width: 455px;
  font-weight: 500;
  font-size: 22px;
  justify-content: space-between;
`;

export default ROISurveyResponse;
