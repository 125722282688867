import { CompanyContact } from 'interfaces/companies';
import { createSelector } from 'reselect';
import {
  CRSFocus,
  IndustriesProps,
  IndustriesTreeProps,
  MissionProps,
  StateProps,
  StatesProps,
} from 'store/interfaces';
import { DATE_FORMAT } from 'constants/date';
import { format } from 'date-fns';
import { values } from 'lodash';
import { TreeData } from 'components/TreeSelect/TreeSelect';

export const getCompaniesSearch = (state: StateProps) => state.companies.companiesSearch;
export const getCompanies = state => state.companies.companies.items;
export const getCompaniesOnlyIdName = (state: StateProps) =>
  state.companies.companiesOnlyIdName.items;
export const getCompaniesFavorites = state => state.companies.favoritesCompanies.items;
export const getCompaniesFavoritesMeta = state => state.companies.favoritesCompanies.meta;
export const getCompaniesMeta = state => state.companies.companies.meta;
export const getCompanyContacts = state => state.companies.contacts;
export const getAdditionalInfo = (state: StateProps) => state.user.additionalInfo;
export const getCompanyScoops = state => state.companies.companyScoops;
export const getCompanyNews = state => state.companies.companyNews;
export const getCompaniesBlocked = state => state.companies.blockedCompanies.items;
export const getCompaniesBlockedMeta = state => state.companies.blockedCompanies.meta;
export const getCompaniesTopMatch = state => state.companies.topMatch;
export const getCompanyMatchDetail = state => state.companies.matchDetail;
export const getCompanyCurrent = (state: StateProps) => state.companies.companyCurrent;
export const getSimilaritySearchCompany = state =>
  state.companies.similaritySearchCompany;
export const getCompanyCurrentContacts = (state: StateProps) =>
  state.companies.companyCurrentContacts;
export const getLastMonthCompanies = (state: StateProps) =>
  state.companies.lastMonthCompanies;

export const getCompanyById = (companyId: string) =>
  createSelector([getCompanies], companies =>
    companies.find(({ id }) => companyId === id),
  );

export const getContactById = (contactId?: string) =>
  createSelector([getCompanyCurrentContacts], contacts =>
    contacts.find(({ id }: CompanyContact) => contactId === id),
  );

export const getAdditionalInfoSelect = () =>
  createSelector([getAdditionalInfo], obj => {
    return {
      ...obj,
      missions: obj.missions.map((item: MissionProps) => ({
        value: item.Id,
        label: item.Mission,
      })),
      industries: obj.industries?.map((item: IndustriesProps) => ({
        value: item.Name,
        label: item.Name,
      })),
      industriesTree: (function coverTree(list: IndustriesTreeProps[]): TreeData {
        return list.map(item =>
          item.children
            ? {
                label: item.name,
                value: String(item.id),
                children: coverTree(item.children),
              }
            : { label: item.name, value: String(item.id) },
        );
      })(obj.industriesTree) as TreeData,
      countries: obj.countries.map(item => ({
        value: item.country,
        label: item.country,
      })),
      states: obj.states
        .filter((item: StatesProps) => !!item.state)
        .map((item: StatesProps) => ({
          value: item.state,
          label: item.state,
        })),
      crsFocus: obj.crsFocus.map((item: CRSFocus) => ({
        value: item.id,
        label: item.name,
      })),
      cites: obj.cites.map(item => ({ value: item.city, label: item.city })),
      naicsCode: obj.naicsCodeTree
        .map(item => item.children)
        .flat(1)
        .map(item => ({
          label: `${item.code} ${item.name}`,
          value: item.code,
        })),
      primaryNaicsCode: obj.naicsCodeTree.map(item => ({
        label: `${item.code} ${item.name}`,
        value: item.code,
      })),
    };
  });

export const getTeamCompanyMembers = state =>
  values(state.companies.teamCompanyMembers).map(member => {
    return {
      ...member,
      lastLogin: member.loggedInAt
        ? format(new Date(member.loggedInAt), DATE_FORMAT.BACKEND)
        : null,
      linkedIn: null,
    };
  });

export const getCompaniesContacts = (state: StateProps) =>
  state.companies.companiesContacts;
export const getCompanySubscription = (state: StateProps) => state.companies.subscription;
