import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import styled, { css } from 'styled-components';
import History from 'services/History';
import { useSortStatus, useToggler } from 'hooks/common';
import useStateToggler from 'hooks/common/useStateToggler';
import { useOperation, useModal } from 'hooks/common';
import { ConfirmModal } from 'components/Modals';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import Filters from 'components/Filters';
import { ExportButton } from 'components/ActionButtons';
import Pagination from 'components/Pagination';
import List from 'components/List';
import { CardCompanyProps, PaginationMeta } from 'interfaces/common';
import CardCompany from 'components/CardCompany';
import { UIActionButton, UIButton } from 'components/UI';
import { ComingSoonModal } from 'components/Modals';
import {
  fetchCompanyLike,
  fetchCompanyDisLike,
  fetchCompaniesCustomizeFilter,
  fetchCompaniesNamesByIds,
  fetchCompaniesCustomizeFilterIds,
  fetchUpdateEnrich,
} from 'store/ducks/companies/operations';
import {
  fetchSavedList,
  fetchSavedListUpdate,
  fetchSavedLisDestroy,
} from 'store/ducks/prospects/operations';
import {
  getCompanies,
  getCompaniesContacts,
  getCompaniesMeta,
  getCompaniesOnlyIdName,
} from 'store/ducks/companies/selectors';
import { getSavedListById } from 'store/ducks/prospects/selectors';
import { Prospect } from 'interfaces/prospects';
import { SearchCompany } from 'interfaces/companies';
import { StateProps } from 'store/interfaces';
import JumbotronEditTitle from 'components/JumbotronEditTitle/JumbotronEditTitle';
import TotalRow from 'components/TotalRow';
import CannotPitchModal from 'components/Modals/CannotPitchModal';
import { fetchVerifyEmailStatus } from 'store/ducks/user/operations';
import { getVerifyEmailStatus } from 'store/ducks/user/selectors';
import { fetchSavedListExcel } from 'store/ducks/prospects/api';
import ContactsSelectedTable from 'pages/organizationUser/companies/Contacts/ContactsSelectedTable';
import { OnCheckParams, SelectOption } from 'components/UI/interfaces';
import SearchContactMultPitchButton from 'components/ActionButtons/SearchContactMultPitchButton';
import {
  getGameplanCustomizeTemplate,
  getGameplanCustomizeTemplatePreview,
} from 'store/ducks/gameplan/selectors';
import {
  fetchGameplanCustomizeTemplate,
  fetchGameplanCustomizeTemplatePreview,
  fetchGameplanCustomizeTemplateReset,
  fetchGameplanCustomizeTemplateSave,
} from 'store/ducks/gameplan/operations';
import { GamePlanEmailTemplateTypeEnum } from 'constants/gamePlan';
import { ReactSVG } from 'react-svg';
import EditSVG from 'assets/images/icons/edit.svg';
import UpdateSVG from 'assets/images/icons/upload.svg';
import BasketSVG from 'assets/images/icons/basket.svg';
import CustomizeTemplateModal from 'components/gameplan/CustomizeTemplateModal';
import { PAGE_WIDTH } from 'constants/common';
import EnrichCompanyProfileModal from '../components/EnrichCompanyProfileModal';
import Toast from 'services/Toast';
import FiltersEditModal from '../components/FiltersEditModal';
import { FilterData } from 'components/Forms/interfaces';
import { fetchCompaniesByIds } from 'store/ducks/companies/api';
import { filterDataConvertSearchParams } from 'utils/companiesUtils';
import DeleteCompanyModal from '../components/DeleteCompanyModal';
import useDownloadSavedListFile from 'hooks/common/useDownloadSavedListFile';

const editIconCss = css`
  margin-right: 10px;
`;

const ProspectsList = () => {
  const { push } = useHistory();
  const params: { prospectsId: string } = useParams();
  const savedList = useSelector<StateProps, Prospect | undefined>(
    getSavedListById(params.prospectsId),
  );
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [name, setName] = useState<string>('');
  const [isActiveEdit, toggleEdit] = useToggler(true);
  const [, , hideIsSelectAll] = useStateToggler(false);
  const [removeCompanyIds, setRemoveCompanyIds] = useState<string[]>([]);
  const [companiesNames, setCompaniesNames] = useState<string[]>([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedContactsIds, setCheckedContactsId] = useState<string[]>([]);
  const { sortStatus, handleSwitch } = useSortStatus([], {
    isSort: false,
  });
  const [filterCompanies, setFilterCompanies] = useState<SearchCompany[]>([]);

  const [onFetchCompanies, , isLoadingCompany] = useOperation(
    fetchCompaniesCustomizeFilter,
  );
  const [onFetchCompaniesOnlyIdName, , isLoadingCompaniesOnlyIdName] = useOperation(
    fetchCompaniesCustomizeFilterIds,
  );
  const [onFetchSavedList, isLoadingList] = useOperation(fetchSavedList);
  const [onFetchSavedListUpdate] = useOperation(fetchSavedListUpdate);
  const [onFetchSavedLisDestroy] = useOperation(fetchSavedLisDestroy);
  const [onfetchCompanyLike] = useOperation(fetchCompanyLike);
  const [onfetchCompanyDisLike] = useOperation(fetchCompanyDisLike);
  const [onFetchVerifyEmailStatus] = useOperation(fetchVerifyEmailStatus);
  const [onFetchCompaniesNamesByIds] = useOperation(fetchCompaniesNamesByIds);
  const [onFetchGameplanCustomizeTemplate, , isCustomizeTeamplateLoading] = useOperation(
    fetchGameplanCustomizeTemplate,
  );
  const [onFetchGameplanCustomizeTemplateSave] = useOperation(
    fetchGameplanCustomizeTemplateSave,
  );
  const [onFetchGameplanCustomizeTemplateReset] = useOperation(
    fetchGameplanCustomizeTemplateReset,
  );
  const [onFetchGameplanCustomizeTemplatePreview] = useOperation(
    fetchGameplanCustomizeTemplatePreview,
  );
  const [onFetchUpdateEnrich, , isUpdateEnrichLoading] = useOperation(fetchUpdateEnrich);

  const verifyEmailStatus = useSelector(getVerifyEmailStatus);
  const companies = useSelector<StateProps, SearchCompany[]>(getCompanies);
  const companiesOnlyIdName = useSelector(getCompaniesOnlyIdName);
  const { items: companiesContacts, meta: companiesContactsMeta } = useSelector(
    getCompaniesContacts,
  );
  const meta = useSelector<StateProps, PaginationMeta>(getCompaniesMeta);
  const { currentPage, itemsPerPage, totalItems } = meta;
  const gameplanCustomizeTemplate = useSelector(getGameplanCustomizeTemplate);
  const gameplanCustomizeTemplatePreview = useSelector(
    getGameplanCustomizeTemplatePreview,
  );

  const [hideComingModal] = useModal(() => {
    return <ComingSoonModal onClose={hideComingModal} />;
  });

  const filterEditValues = useMemo((): FilterData => {
    if (!savedList?.filters) return { q: '' };

    return {
      ...savedList.filters,
      ids: filterCompanies,
      contact: savedList.filters?.contact
        ?.split(',')
        .map(item => ({ name: item, id: item })),
    };
  }, [filterCompanies, savedList]);

  const [showFiltersEditModal, hideFiltersEditModal] = useModal(
    () => (
      <FiltersEditModal
        initialValues={filterEditValues}
        onClose={hideFiltersEditModal}
        filterSubmitHandler={formValues => {
          onFetchSavedListUpdate({
            id: params?.prospectsId,
            data: { ...savedList, filters: filterDataConvertSearchParams(formValues) },
          }).then(() => hideFiltersEditModal());
        }}
      />
    ),
    [filterEditValues],
  );

  const [exportExcel, isExportExcelLoading] = useDownloadSavedListFile(
    fetchSavedListExcel,
  );

  const downloadExcel = useCallback(() => {
    if (
      savedList?.filters.dataType === 'contact' &&
      companiesContactsMeta.totalItems > 10000
    ) {
      Toast.warn('You have exceeded the limit of 10,000 records.');
      return;
    }
    exportExcel(params.prospectsId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedList, companiesContactsMeta.totalItems, params.prospectsId]);

  const [showCustomizeTemplateModal, hideCustomizeTemplateModal] = useModal(() => {
    return (
      <CustomizeTemplateModal
        noSubject
        template={gameplanCustomizeTemplate}
        templatePreview={gameplanCustomizeTemplatePreview}
        isloading={isCustomizeTeamplateLoading}
        onClose={hideCustomizeTemplateModal}
        onReset={templateType => onFetchGameplanCustomizeTemplateReset(templateType)}
        onSave={values => onFetchGameplanCustomizeTemplateSave(values)}
        onPreview={templateType => onFetchGameplanCustomizeTemplatePreview(templateType)}
      />
    );
  }, [
    gameplanCustomizeTemplate,
    gameplanCustomizeTemplatePreview,
    isCustomizeTeamplateLoading,
  ]);

  const checkedContacts = useMemo(
    () => companiesContacts.filter(item => checkedContactsIds.includes(item.contact_id)),
    [checkedContactsIds, companiesContacts],
  );

  const companiesOnlyIdNameOptions = useMemo(
    (): SelectOption[] =>
      companiesOnlyIdName
        .filter(item => item.canUpdateEnrich)
        .map(item => ({ label: item.name, value: item.id })),
    [companiesOnlyIdName],
  );

  const onCheckRow = ({ check, id }: OnCheckParams) => {
    if (check) {
      const result = [...checkedContactsIds, id];
      setCheckedContactsId(result);
      return;
    }
    const result = [...checkedContactsIds].filter(item => item !== id);
    setCheckedContactsId(result);
  };

  const onCheckAll = (bool: boolean) => {
    setIsCheckedAll(bool);
    if (bool) {
      const result = companiesContacts.reduce((prev, curr) => {
        if (curr.usedIn60Days === '0') {
          prev.push(curr.contact_id);
          return prev;
        }
        return prev;
      }, [] as string[]);

      setCheckedContactsId(result);
      return;
    }
    setCheckedContactsId([]);
  };

  const removeCompanyHandler = useCallback(
    (companyId: string) => {
      if (removeCompanyIds.includes(companyId)) {
        setRemoveCompanyIds(removeCompanyIds.filter(id => id !== companyId));
      } else {
        setRemoveCompanyIds([...removeCompanyIds, companyId]);
      }
    },
    [removeCompanyIds],
  );

  const editHandler = (nameStr?: string) => {
    const id = params?.prospectsId;
    if (isActiveEdit) {
      if (nameStr && savedList) {
        const data = { ...savedList, name: nameStr };
        onFetchSavedListUpdate({ id, data }).then(() => {
          toggleEdit();
          setRemoveCompanyIds([]);
          hideIsSelectAll();
        });
      }

      return;
    }

    return toggleEdit();
  };

  const onDeleteCompanyFromSavedList = () => {
    const id = params?.prospectsId;
    let deleteIds: string[] = [];

    if (savedList) {
      const prospectsAvailable = savedList.prospectsAvailable - removeCompanyIds.length;

      if (savedList.filters.deleteIds && savedList.filters.deleteIds.length > 0) {
        deleteIds = [...savedList.filters.deleteIds];
      }
      onFetchSavedListUpdate({
        id,
        data: {
          ...savedList,
          filters: {
            ...savedList.filters,
            deleteIds: [...deleteIds, ...removeCompanyIds],
          },
          name: name || 'No name',
          prospectsAvailable,
        },
      }).then(() => {
        setRemoveCompanyIds([]);
        hideIsSelectAll();
      });
    }
  };

  useEffect(() => {
    if (!savedList?.filters.ids) return;
    fetchCompaniesByIds(savedList.filters.ids).then(values =>
      setFilterCompanies(values as SearchCompany[]),
    );
  }, [savedList]);

  useEffect(() => {
    if (savedList?.filters && !isLoadingCompany) {
      const pageString = queryString.parse(window.location.search)?.page;
      const searchParams = {
        filters: {
          page: Number(pageString) || 1,
          limit: 12,
          ...savedList?.filters,
        },
        ranks: savedList.filters.priority,
      };
      onFetchCompanies(searchParams).then(companies => {
        if (!(companies && companies.meta)) {
          return;
        }
        const meta = companies.meta as PaginationMeta;
        if (meta.totalItems !== savedList.prospectsAvailable) {
          onFetchSavedListUpdate({
            id: params.prospectsId,
            data: {
              name: savedList.name || 'No name',
              prospectsAvailable: meta.totalItems,
            },
          });
        }
      });
      if (savedList.filters.dataType !== 'contact') {
        onFetchCompaniesOnlyIdName({
          ...searchParams,
          filters: { ...searchParams.filters, limit: 10000 },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedList]);

  useEffect(() => {
    if (savedList?.name) {
      setName(savedList?.name);
    } else {
      toggleEdit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedList]);

  useEffect(() => {
    if (!savedList?.filters.ids) return;
    onFetchCompaniesNamesByIds(savedList.filters?.ids || []).then(names => {
      setCompaniesNames(names);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedList]);

  useEffect(() => {
    if (isActiveEdit) {
      inputRef.current?.focus?.();
    }
  }, [isActiveEdit]);

  useEffect(() => {
    const id = params?.prospectsId;
    onFetchSavedList(id);
    onFetchVerifyEmailStatus(undefined);
    onFetchGameplanCustomizeTemplate({
      templateType: GamePlanEmailTemplateTypeEnum.PitchTemplate,
    });
    toggleEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showEnrichModal, hideEnrichModal] = useModal(() => {
    return (
      <EnrichCompanyProfileModal
        handleAccess={companiesId => {
          onFetchUpdateEnrich(companiesId).then(() => {
            const id = params?.prospectsId;
            onFetchSavedList(id);
          });
          hideEnrichModal();
        }}
        onClose={hideEnrichModal}
        companiesOnlyIdNameOptions={companiesOnlyIdNameOptions}
      />
    );
  }, [companiesOnlyIdNameOptions]);

  const [showDeleteModal, hideModal] = useModal(() => {
    const id = params?.prospectsId;
    const deleteHandler = () => {
      onFetchSavedLisDestroy(id).then(() => {
        History.back();
      });
      hideModal();
    };

    return (
      <ConfirmModal
        name={'Delete saved list'}
        textAccessBtn="Delete"
        description={'Are you sure you want to delete the saved list?'}
        accessHandler={deleteHandler}
        cancelHandler={hideModal}
        onClose={hideModal}
        hideCloseIcon
      />
    );
  });

  const [showDeleteCompanyConfirmModal, hideDeleteCompanyConfirmModal] = useModal(() => {
    return (
      <ConfirmModal
        name="Delete Company?"
        textAccessBtn="Back to Pop Up"
        textCancelBtn="Delete"
        accessModifiersBtn="secondary"
        cancelModifiersBtn="danger"
        description={
          'Once deleted, it cannot be recovered. Are you sure you are ready to delete companies from current prospecting list?'
        }
        accessHandler={hideDeleteCompanyConfirmModal}
        cancelHandler={() => {
          onDeleteCompanyFromSavedList();
          hideDeleteCompanyConfirmModal();
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          hideDeleteCompanyModal();
        }}
        onClose={hideDeleteCompanyConfirmModal}
        hideCloseIcon
      />
    );
  }, [onDeleteCompanyFromSavedList]);

  const [showDeleteCompanyModal, hideDeleteCompanyModal] = useModal(() => {
    return (
      <DeleteCompanyModal
        handleAccess={companiesId => {
          setRemoveCompanyIds(companiesId);
          showDeleteCompanyConfirmModal();
        }}
        onClose={hideDeleteCompanyModal}
        companiesOnlyIdNameOptions={companiesOnlyIdName.map(item => ({
          label: item.name,
          value: item.id,
        }))}
      />
    );
  }, [companiesOnlyIdName]);

  const [showCannotPitchModal, hideCannotPitchModal] = useModal(() => {
    return (
      <CannotPitchModal
        accessHandler={() => push('/user-profile')}
        cancelHandler={hideCannotPitchModal}
      />
    );
  });

  const onDisLike = (id: string) => {
    onfetchCompanyDisLike(id);
  };

  const onLike = (id: string) => {
    onfetchCompanyLike(id);
  };

  return (
    <ContentBlockWrapper
      header={
        <JumbotronEditTitle
          name={name}
          editBtnName="Rename"
          isEdit={isActiveEdit}
          onSave={str => editHandler(str)}
          onEdit={editHandler}
          onCancel={toggleEdit}
          onDelete={showDeleteModal}
          maxWidth={
            savedList?.filters.dataType === 'contact'
              ? `${PAGE_WIDTH.LARGE}px`
              : undefined
          }
        />
      }
      loading={isLoadingCompany || isLoadingList || isLoadingCompaniesOnlyIdName}
    >
      <TotalRow
        maxWidth={savedList?.filters.dataType === 'contact' ? 'unset' : undefined}
        title={
          savedList?.filters.dataType !== 'contact'
            ? `${totalItems} ${totalItems > 1 ? 'companies' : 'company'}`
            : `${companiesContactsMeta.totalItems} ${
                companiesContactsMeta.totalItems > 1 ? 'contacts' : 'contact'
              }`
        }
      >
        <CreateGamePlanBtn
          title="Create a Game Plan"
          modifiers={['blue']}
          handleClick={() =>
            verifyEmailStatus
              ? push(`/gameplan/create/${params.prospectsId}`)
              : showCannotPitchModal()
          }
        />
      </TotalRow>
      {savedList && (
        <>
          <Filters
            marginList="0 14px"
            filters={savedList?.filters}
            companiesNames={companiesNames}
            isShowEdit={true}
            title={
              <>
                Filters
                <FilterIcon src={EditSVG} onClick={showFiltersEditModal} />
              </>
            }
          />

          {savedList.filters.dataType !== 'contact' ? (
            <Header>
              <Actions>
                <ExportButton
                  isloading={isExportExcelLoading}
                  iconCss={editIconCss}
                  uploadHandler={downloadExcel}
                />
                <UIActionButton
                  isloading={isUpdateEnrichLoading}
                  iconCss={editIconCss}
                  handleClick={showEnrichModal}
                  icon={UpdateSVG}
                  name="Update Social Impact"
                />
                <UIActionButton
                  iconCss={editIconCss}
                  handleClick={showDeleteCompanyModal}
                  icon={BasketSVG}
                  name="Delete Company"
                />
                {/* {removeCompanyIds.length > 0 && (
                  <>
                    <UIActionButton
                      iconCss={basketIconCss}
                      handleClick={onDeleteCompanyFromSavedList}
                      icon={BasketIcon}
                      name="Delete"
                    />
                  </>
                )} */}
              </Actions>
              {totalItems > 0 && (
                <Pagination
                  total={totalItems}
                  pageLimit={Number(itemsPerPage)}
                  page={Number(currentPage)}
                  submitHandler={page =>
                    onFetchCompanies({
                      filters: {
                        page,
                        limit: 12,
                        ...savedList?.filters,
                      },
                      ranks: savedList.filters.priority,
                    })
                  }
                />
              )}
            </Header>
          ) : (
            <Header maxWidth="unset">
              <Actions>
                <ExportButton
                  isloading={isExportExcelLoading}
                  iconCss={editIconCss}
                  uploadHandler={downloadExcel}
                />
                {/* {isActiveEdit && removeCompanyIds.length > 0 && (
                  <>
                    <UIActionButton
                      iconCss={basketIconCss}
                      handleClick={onDeleteCompanyFromSavedList}
                      icon={BasketIcon}
                      name="Delete"
                    />
                  </>
                )} */}
              </Actions>
              <ActionsContacts>
                <CustomizeTeamplateButton
                  title={
                    <TextIconWarpper>
                      <EditIcon src={EditSVG} /> Edit Email Template
                    </TextIconWarpper>
                  }
                  modifiers="third"
                  handleClick={() => {
                    onFetchGameplanCustomizeTemplate({
                      templateType: GamePlanEmailTemplateTypeEnum.PitchTemplate,
                    });
                    showCustomizeTemplateModal();
                  }}
                />
                {!!checkedContactsIds.length && (
                  <SearchContactMultPitchButton
                    contact={checkedContacts}
                    customizeTemplate={gameplanCustomizeTemplate}
                    onSendCallback={() => setCheckedContactsId([])}
                  />
                )}
                {companiesContactsMeta.totalItems > 0 && (
                  <Pagination
                    total={companiesContactsMeta.totalItems}
                    pageLimit={Number(companiesContactsMeta.itemsPerPage)}
                    page={Number(companiesContactsMeta.currentPage)}
                    submitHandler={page =>
                      onFetchCompanies({
                        filters: {
                          page,
                          limit: 12,
                          ...savedList?.filters,
                        },
                        ranks: savedList.filters.priority,
                      })
                    }
                  />
                )}
              </ActionsContacts>
            </Header>
          )}

          {savedList.filters.dataType !== 'contact' ? (
            <List<SearchCompany, CardCompanyProps>
              list={companies}
              component={CardCompany}
              isIndexKey
              componentProps={{
                showUpdateDate: true,
                setSelectedCompany: removeCompanyHandler,
                showCheckbox: false,
                setDisLikeHandler: onDisLike,
                setLikeHandler: onLike,
              }}
            />
          ) : (
            <ContactsSelectedTable
              rows={companiesContacts}
              selectedContactsIds={checkedContactsIds}
              unselectedContactsIds={[]}
              selectedAll={isCheckedAll}
              onCheckAll={onCheckAll}
              onCheckRow={onCheckRow}
              sortStatus={sortStatus}
              onSort={col => handleSwitch(col)}
            />
          )}
        </>
      )}
    </ContentBlockWrapper>
  );
};

const Actions = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 24px;
  }

  & button:not(:last-child) {
    margin-right: 24px;
  }
`;

const ActionsContacts = styled(Actions)`
  height: 40px;
`;

const Header = styled.header<{ maxWidth?: string }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 27px;
  max-width: ${({ maxWidth = `${PAGE_WIDTH.NORMAL}px` }) => maxWidth};
`;

const CreateGamePlanBtn = styled(UIButton)`
  width: 184px;
  font-size: 12px;
  font-weight: 400;
  padding: 8px 0;
`;

const CustomizeTeamplateButton = styled(UIButton)`
  margin-right: 16px;
`;

const EditIcon = styled(ReactSVG)`
  margin-right: 8px;
`;

const TextIconWarpper = styled.div`
  display: flex;
`;

const FilterIcon = styled(ReactSVG)`
  margin-left: 6px;
  cursor: pointer;
`;

export default ProspectsList;
