import { ShowMoreText } from 'components/ShowMoreText';
import { ROIMeltwaterData } from 'interfaces/roi';
import React from 'react';
import styled from 'styled-components';
import {
  DocumentCard,
  LineChart,
  PieChart,
  RandomTextCard,
  MapChart,
  BarChart,
  NewsCard,
  TotalPotentialReachChart,
} from '.';

interface Props {
  meltwaterData: ROIMeltwaterData;
}

const DashBoard = ({ meltwaterData }: Props) => {
  return (
    <>
      <CardGroupRow>
        <Card>
          <CardHeader>
            <CardTitle>Content Stream (Top 50)</CardTitle>
            <CardDesc>
              <ShowMoreText>
                The Content Stream display is a scrollable and clickable stream of media
                coverage from both social and news sources.
              </ShowMoreText>
            </CardDesc>
          </CardHeader>
          <CardBody>
            <DocumentCard data={meltwaterData.analyticsData.exports?.data} />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Media Exposure (over time)</CardTitle>
            <CardDesc>
              <ShowMoreText>
                The Media Exposure display provides insight into how media coverage is
                trending over time for the duration of the campaign or partnership. You
                can use this information to identify effective outreach, the most
                appropriate times to outreach in the future, and key moments created
                organically or through your media strategy.
              </ShowMoreText>
            </CardDesc>
          </CardHeader>
          <CardBody>
            <LineChart data={meltwaterData.analyticsData.analytics?.time_series} />
          </CardBody>
        </Card>
      </CardGroupRow>
      <CardGroupRow>
        <Card>
          <CardHeader>
            <CardTitle>Sentiment</CardTitle>
            <CardDesc>
              <ShowMoreText>
                The Sentiment display provides insight into how media coverage is broken
                down by positive and negative sentiment over time. This helps you
                understand and monitor changes in the emotion and perception behind
                engagement. Accelerist considers both neutral and positive content as
                total positive sentiment.
              </ShowMoreText>
            </CardDesc>
          </CardHeader>
          <CardBody>
            <PieChart data={meltwaterData.analyticsData.analytics?.sentiment} />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Trending Themes</CardTitle>
            <CardDesc>
              <ShowMoreText>
                The Trending Themes display provides insight into keywords and key phrases
                that are most frequently associated with your partnership or campaign
                media coverage. This helps you understand the conversation taking place,
                how individuals and users are engaging, and what keywords gained the most
                traction with your audiences. The information can be used to craft future
                messages and start conversations with target audiences.
              </ShowMoreText>
            </CardDesc>
          </CardHeader>
          <CardBody>
            <RandomTextCard
              data={meltwaterData.analyticsData.top_keyphrases?.keyphrases}
            />
          </CardBody>
        </Card>
      </CardGroupRow>
      <CardGroupRow>
        <Card>
          <CardHeader>
            <CardTitle>Heat Map</CardTitle>
            <CardDesc>
              <ShowMoreText>
                The Heat Map display provides insight into how coverage is broken down by
                country.
              </ShowMoreText>
            </CardDesc>
          </CardHeader>
          <CardBody className="heat-map-chart">
            <MapChart data={meltwaterData.analyticsData.analytics?.top_countries} />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Top Authors</CardTitle>
            <CardDesc>
              <ShowMoreText>
                The Top Authors display provides insight into how coverage is broken down
                by posters on a particular platform. This allows you to identify the
                people behind the content and understand how they are crafting or sharing
                relevant content in an authentic way.
              </ShowMoreText>
            </CardDesc>
          </CardHeader>
          <CardBody>
            <BarChart
              data={meltwaterData.analyticsData.top_sources?.sources.slice(0, 4)}
            />
          </CardBody>
        </Card>
      </CardGroupRow>
      <CardGroupRow>
        <Card>
          <CardHeader>
            <CardTitle>Top Retweeted</CardTitle>
            <CardDesc>
              <ShowMoreText>
                The Top Retweeted display shows tweets from those that have engaged with
                the campaign via Twitter and received engagement. It is ordered by highest
                social reach value.{' '}
              </ShowMoreText>
            </CardDesc>
          </CardHeader>
          <CardBody>
            <NewsCard data={meltwaterData.analyticsData.top_shared?.results} />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Total Potential Reach</CardTitle>
            <CardDesc>
              <ShowMoreText>
                The Potential Reach widget provides insight into the number of potential
                viewers that have been exposed to a set of media coverage during the
                duration of the campaign.
              </ShowMoreText>
            </CardDesc>
          </CardHeader>
          <CardBody>
            <TotalPotentialReachChart
              data={meltwaterData.analyticsData.exports?.documentTypes}
            />
          </CardBody>
        </Card>
      </CardGroupRow>
    </>
  );
};

const CardGroupRow = styled.div`
  display: flex;
`;

const Card = styled.div`
  width: 616px;
  border-radius: 6px;
  background: #fff;
  margin: 24px 24px 0 0;
  flex-shrink: 0;
`;

const CardHeader = styled.div`
  border-bottom: 1px solid #e8e8e8;
  padding: 18px 24px;
`;

const CardTitle = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 145%;
  color: var(--black);
  margin-bottom: 6px;
`;

const CardDesc = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
`;

const CardBody = styled.div`
  padding: 24px 12px 24px;
`;

export default DashBoard;
