/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { useOperation } from 'hooks/common';

import { fetchSavedLists } from 'store/ducks/prospects/operations';
import {
  getSavedLists,
  getSavedListsMeta,
  getTotalGameplanInfo,
} from 'store/ducks/prospects/selectors';

import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import Jumbotron from 'components/Jumbotron';
import { sortTabs } from 'constants/search';
import Pagination from 'components/Pagination';
import List from 'components/List';
import CardProspect from 'components/CardProspect';
import TabsNavigation from 'components/NavigationTabs';
import EmptyCompany from 'components/EmptyCompany';

import { StateProps } from 'store/interfaces';
import { Prospect } from 'interfaces/prospects';
import { Link } from 'react-router-dom';
import Divider from 'components/UI/Divider';
import { ExportButton } from 'components/ActionButtons';
import useDownloadFile from 'hooks/common/useDownloadFile';
import {
  fetchGameplanAllActivity,
  fetchGameplanAllActivityByUser,
} from 'store/ducks/gameplan/api';
import Dropdown from 'components/Dropdown/Dropdown';
import { PaginationMeta } from 'interfaces/common';
import { PAGE_WIDTH } from 'constants/common';

const Prospects = () => {
  const { location } = useHistory();
  const sortString = queryString.parse(location.search)?.sort?.toString();

  const [onfetchSavedLists, isLoading] = useOperation(fetchSavedLists);
  const [sort, setSort] = useState<string>(sortString || 'alphabet');

  const savedLists = useSelector<StateProps, Prospect[]>(getSavedLists);
  const totalGameplanInfo = useSelector(getTotalGameplanInfo);
  const meta = useSelector<StateProps, PaginationMeta>(getSavedListsMeta);
  const { currentPage, itemsPerPage, totalItems } = meta;

  const getInitialState = () => {
    const sortString = queryString.parse(location.search)?.sort;
    const pageString = queryString.parse(location.search)?.page;
    if (sortString) {
      onfetchSavedLists({ page: Number(pageString) || 1, sort: sortString });
      return setSort(sort);
    } else {
      return onfetchSavedLists({ page: Number(pageString) || 1, sort });
    }
  };

  const exportExcel = useDownloadFile(fetchGameplanAllActivity);
  const exportExcelByUser = useDownloadFile(fetchGameplanAllActivityByUser);

  useEffect(() => {
    getInitialState();
  }, [sortString]);

  return (
    <ContentBlockWrapper
      header={<Jumbotron name="Prospects" back backPath={`/dashboard`} />}
      loading={isLoading}
    >
      <GameplanStatusBar>
        <div>Total number of Game Plans</div>
        <GameplanStatus>
          <Link to="/gameplan/gameplans">{totalGameplanInfo.totalGamePlanCount}</Link>
          <Divider />
          <Link to="/gameplan/gameplans?isClose=false">
            {totalGameplanInfo.totalGamePlanOpenCount}
          </Link>
          <OpenText />

          <Dropdown title="Export" style={{ marginLeft: '8px' }}>
            <DropdownBody>
              <ExportButton
                iconCss={{ margin: '0 4px' }}
                name="Export All Gameplans"
                uploadHandler={() => exportExcel('')}
              />
              <ExportButton
                iconCss={{ margin: '0 4px' }}
                name="Export Your Gameplans"
                uploadHandler={() => exportExcelByUser('')}
              />
            </DropdownBody>
          </Dropdown>
        </GameplanStatus>
      </GameplanStatusBar>
      <Header>
        <TabsNavigation queryKey="sort" list={sortTabs} name="Sort by" activeTab={sort} />
        {totalItems > 0 && (
          <Pagination
            total={totalItems}
            pageLimit={Number(itemsPerPage)}
            page={Number(currentPage)}
            submitHandler={(page: number) => onfetchSavedLists({ page, sort })}
          />
        )}
      </Header>

      {savedLists.length > 0 ? (
        <List<Prospect, object> list={savedLists} component={CardProspect} />
      ) : (
        <Empty title="lists" />
      )}
    </ContentBlockWrapper>
  );
};

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 23px;
  max-width: ${PAGE_WIDTH.NORMAL}px;
`;

const Empty = styled(EmptyCompany)`
  height: calc(100vh - 280px);
`;

const GameplanStatusBar = styled.div`
  background-color: #fff;
  max-width: ${PAGE_WIDTH.NORMAL}px;
  height: 69px;
  border-radius: 6px;
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  display: flex;
  align-items: center;
  padding: 0 24px;
  justify-content: space-between;
`;

const GameplanStatus = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 148%;
`;

const OpenText = styled.div.attrs(() => ({ children: 'Open' }))`
  color: var(--blue);
  font-size: 12px;
  font-weight: 500;
  margin-left: 6px;
`;

const DropdownBody = styled.div`
  width: 190px;
  padding: 12px;
`;

export default Prospects;
