export const deploymentDayOptions = Array.from(Array(45), (_, i) => i + 1).map(index => ({
  label: `Day ${index}`,
  value: index,
}));

export enum GamePlanEmailTemplateTypeEnum {
  GetToKnowYou = 'Get to Know You',
  BriefFollowUp = 'Brief Follow-up',
  MissionContent = 'Mission Content',
  PhoneCallReminder1 = 'Phone Call Reminder1',
  PhoneCallReminder2 = 'Phone Call Reminder2',
  PhoneCallReminder3 = 'Phone Call Reminder3',
  PhoneCallReminder4 = 'Phone Call Reminder4',
  PitchTemplate = 'Pitch Template',
}

export const GameplanFilterOptions = [
  { label: 'All Status', value: 'All Status' },
  { label: 'Draft', value: 'Draft' },
  { label: 'Opened', value: 'Opened' },
  { label: 'Closed', value: 'Closed' },
] as const;

export enum GamePlanEmailStatusEnum {
  Active = 'Active',
  Bounced = 'Bounced',
  ManuallyRemoved = 'Manually Removed',
  Completed = 'Completed',
  Connected = 'Connected',
}
