import React, { useState } from 'react';
import styled from 'styled-components';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { Form, FormRenderProps } from 'react-final-form';
import UploadFileArea from 'components/UploadFileArea';
import DownloadTemplate from 'components/DownloadTemplate';
import {
  fetchDownloadContactsTemplate,
  fetchUploadContactsXLSX,
} from 'store/ducks/gameplan/api';
import { fileExtensionValidator } from 'utils/validation';
import Toast from 'services/Toast';
import { fetchErrorHandler } from 'utils/errorHandlers';
import { ContactsExcelRow } from 'interfaces/gameplan';

interface Props extends InjectedProps {
  handleAccess: (values: ContactsFormData) => void;
  title: string;
  submitLoading: boolean;
}

type ContactsFormData = {
  files: File[];
  rows: ContactsExcelRow[];
};

const ImportContactsModal = ({
  onClose,
  handleAccess,
  title,
  submitLoading,
  ...modalProps
}: Props) => {
  // useLockBodyScroll();
  const [isloading, setIsloading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 60px' }}
      style={{
        width: 'max-content',
        maxWidth: '1129px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Title>{title}</Title>
      <Form
        onSubmit={values => {
          handleAccess(values);
        }}
        render={({ handleSubmit, values, form }: FormRenderProps<ContactsFormData>) => {
          return (
            <>
              <LimitWidthAndCenter>
                <UploadFileArea
                  isloading={isloading}
                  acceptValidator={fileExtensionValidator(
                    ['xlsx'],
                    'Only Excel files are allowed.',
                  )}
                  areaHeight={200}
                  desc="You may upload 1 Excel file per time. File size limit is 5 Mb. Only Excel files are allowed."
                  filelist={
                    values.files?.map(item => ({
                      label: item.name,
                      value: item.name,
                    })) || []
                  }
                  onUpload={file => {
                    if (!file.length) return;
                    setIsloading(true);
                    fetchUploadContactsXLSX(file[0])
                      .then(value => {
                        form.change('files', file);
                        form.change('rows', value);
                        Toast.success('Uploaded successfully');
                        setIsDisabled(false);
                      })
                      .catch(fetchErrorHandler)
                      .finally(() => {
                        setIsloading(false);
                      });
                  }}
                  onDeleteFile={() => {
                    form.change('files', []);
                    setIsDisabled(true);
                  }}
                />
                <DownloadTemplate fetchFile={fetchDownloadContactsTemplate} />
                <Buttons>
                  <Button title="Cancel" handleClick={onClose} modifiers="secondary" />
                  <Button
                    title="Submit"
                    handleClick={handleSubmit}
                    modifiers="primary"
                    disabled={isDisabled}
                    isLoading={submitLoading}
                  />
                </Buttons>
              </LimitWidthAndCenter>
            </>
          );
        }}
      />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 28px;
  text-align: center;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Button = styled(UIButton)`
  width: 128px;
`;

const LimitWidthAndCenter = styled.div<{ isDisabled?: boolean }>`
  width: 720px;
  margin: 0 auto;
  ${({ isDisabled }) => isDisabled && 'width: 100%;'}
`;

export default ImportContactsModal;
