import JumbotronEditTitle from 'components/JumbotronEditTitle/JumbotronEditTitle';
import { ConfirmModal } from 'components/Modals';
import { UIAlert, UIButton } from 'components/UI';
import { PAGE_WIDTH } from 'constants/common';
import { useModal, useOperation } from 'hooks/common';
import { ValuationStatusEnum } from 'interfaces/pricing';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  fetchPricing,
  fetchRevertReport,
  fetchSaveValuation,
  fetchSubmitValuation,
} from 'store/ducks/pricing/operations';
import { getPricing } from 'store/ducks/pricing/selectors';
import styled from 'styled-components';
import { ValuationForm } from '../components';

const EditValuationDetail: FC = () => {
  const [onFetchSaveValuation] = useOperation(fetchSaveValuation);
  const [onFetchPricing, , isloading] = useOperation(fetchPricing);
  const [onFetchSubmitValuation] = useOperation(fetchSubmitValuation);
  const [onFetchRevertReport] = useOperation(fetchRevertReport);

  const { push } = useHistory();
  const params: { valuationId: string } = useParams();

  const pricing = useSelector(getPricing);

  const [showRevertModal, hideRevertModal] = useModal(() => (
    <ConfirmModal
      accessHandler={() => {
        onFetchRevertReport(params.valuationId);
        hideRevertModal();
      }}
      cancelHandler={hideRevertModal}
      name="Revert"
      description="By reverting the valuation, you will loose the generated report data."
      textAccessBtn="Revert"
      onClose={hideRevertModal}
    />
  ));

  useEffect(() => {
    onFetchPricing(params.valuationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentBlockWrapper
      header={
        <JumbotronEditTitle
          name={pricing && pricing.name}
          disableDeleteBtn
          onSave={value => {
            if (!pricing) return;
            onFetchSaveValuation({ ...pricing, name: value });
          }}
        />
      }
      loading={isloading}
    >
      <Root>
        <NavigationLinkBar>
          <NavigationLink active>Basic Info</NavigationLink>
          {pricing?.status === ValuationStatusEnum.Completed && (
            <NavigationLink onClick={() => push(`/pricing/report/${params.valuationId}`)}>
              Valuation Report
            </NavigationLink>
          )}
          <ActionGroup>
            {pricing?.status === ValuationStatusEnum.Completed && (
              <RevertBtn
                title="Revert"
                modifiers="danger"
                handleClick={showRevertModal}
              />
            )}
          </ActionGroup>
        </NavigationLinkBar>
        <Alert type="info">
          All inputs should be specific to what you are intending to value (i.e. If for
          the entire organization, input data points for your collective base of assets.
          If you are valuing a specific event or property, input ONLY data points specific
          to that property.
        </Alert>
        {pricing && (
          <ValuationForm
            pricing={pricing}
            handleFetchSaveValuation={onFetchSaveValuation}
            handleFetchSubmitValuation={onFetchSubmitValuation}
          />
        )}
      </Root>
    </ContentBlockWrapper>
  );
};

const Root = styled.div`
  max-width: ${PAGE_WIDTH.NORMAL}px;
`;

const Alert = styled(UIAlert)`
  margin-bottom: 16px;
`;

const NavigationLinkBar = styled.div`
  height: 69px;
  background: #fff;
  border-radius: 6px;
  padding: 22px 24px;
  margin-bottom: 24px;
  display: flex;
`;

const NavigationLink = styled.div<{ active?: boolean }>`
  font-size: 16px;
  line-height: 155%;
  color: #122434;
  margin-right: 20px;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
    font-weight: 500;
    color: #2baee0; 
  `}

  &:hover {
    color: #2baee0;
  }
`;

const ActionGroup = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const RevertBtn = styled(UIButton)`
  padding: 0;
  margin-right: 24px;
  height: 46px;
  width: 104px;
  font-size: 16px;
`;

export default EditValuationDetail;
