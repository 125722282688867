/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import styled, { css } from 'styled-components';

import History from 'services/History';
import { useToggler } from 'hooks/common';
import useStateToggler from 'hooks/common/useStateToggler';
import { useOperation, useModal } from 'hooks/common';

import { ConfirmModal } from 'components/Modals';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import Filters from 'components/Filters';
import { ExportButton } from 'components/ActionButtons';
import Pagination from 'components/Pagination';
import List from 'components/List';
import { CardTeamProps, PaginationMeta } from 'interfaces/common';
import { UIActionButton } from 'components/UI';
import { ComingSoonModal } from 'components/Modals';

import { Prospect } from 'interfaces/prospects';
import { StateProps } from 'store/interfaces';

import BasketIcon from 'assets/images/icons/basket.svg';
import JumbotronEditTitle from 'components/JumbotronEditTitle/JumbotronEditTitle';
import TotalRow from 'components/TotalRow';
import { getSavedListTeamsById } from 'store/ducks/prospectsTeams/selectors';
import { getTeams, getTeamsMeta } from 'store/ducks/team/selectors';
import { TeamProfileData } from 'interfaces/team';
import {
  fetchTeamNamesByIds,
  fetchTeamsCustomizeFilter,
} from 'store/ducks/team/operations';
import CardTeam from 'components/CardTeam';
import {
  fetchSavedListTeams,
  fetchSavedListTeamsDestroy,
  fetchSavedListTeamsUpdate,
} from 'store/ducks/prospectsTeams/operations';
import useDownloadFile from 'hooks/common/useDownloadFile';
import { fetchSavedListTeamsExcel } from 'store/ducks/prospectsTeams/api';
import { PAGE_WIDTH } from 'constants/common';

const editIconCss = css`
  margin-right: 10px;
`;

const basketIconCss = css`
  margin-right: 12px;
`;

const ProspectsList = () => {
  const { location } = useHistory();
  const params: { prospectsId: string } = useParams();
  const savedList = useSelector<StateProps, Prospect | undefined>(
    getSavedListTeamsById(params.prospectsId),
  );
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [name, setName] = useState<string>('');
  const [isActiveEdit, toggleEdit] = useToggler(true);
  const [, , hideIsSelectAll] = useStateToggler(false);
  const [removeTeamIds, setRemoveCompanyIds] = useState<string[]>([]);
  const [teamNames, setTeamNames] = useState<string[]>([]);

  const [onFetchCompanies, isLoadingCompany] = useOperation(fetchTeamsCustomizeFilter);
  const [onFetchSavedList, isLoadingList] = useOperation(fetchSavedListTeams);
  const [onFetchSavedListUpdate] = useOperation(fetchSavedListTeamsUpdate);
  const [onFetchSavedLisDestroy] = useOperation(fetchSavedListTeamsDestroy);
  const [onFetchTeamNamesByIds] = useOperation(fetchTeamNamesByIds);

  const companies = useSelector<StateProps, TeamProfileData[]>(getTeams);
  const meta = useSelector<StateProps, PaginationMeta>(getTeamsMeta);
  const { currentPage, itemsPerPage, totalItems } = meta;

  const [hideComingModal] = useModal(() => {
    return <ComingSoonModal onClose={hideComingModal} />;
  });

  const exportExcel = useDownloadFile(fetchSavedListTeamsExcel);

  const removeTeamHandler = useCallback(
    (companyId: string) => {
      if (removeTeamIds.includes(companyId)) {
        setRemoveCompanyIds(removeTeamIds.filter(id => id !== companyId));
      } else {
        setRemoveCompanyIds([...removeTeamIds, companyId]);
      }
    },
    [removeTeamIds],
  );

  const editHandler = (nameStr?: string) => {
    const id = params?.prospectsId;
    if (isActiveEdit) {
      if (nameStr && savedList) {
        const data = { ...savedList, name: nameStr };
        onFetchSavedListUpdate({ id, data }).then(() => {
          toggleEdit();
          setRemoveCompanyIds([]);
          hideIsSelectAll();
        });
      }

      return;
    }

    return toggleEdit();
  };

  const onDeleteCompanyFromSavedList = () => {
    const id = params?.prospectsId;
    let deleteIds: string[] = [];

    if (savedList) {
      const prospectsAvailable = savedList.prospectsAvailable - removeTeamIds.length;

      if (savedList.filters.deleteIds && savedList.filters.deleteIds.length > 0) {
        deleteIds = [...savedList.filters.deleteIds];
      }
      onFetchSavedListUpdate({
        id,
        data: {
          ...savedList,
          filters: {
            ...savedList.filters,
            deleteIds: [...deleteIds, ...removeTeamIds],
          },
          name: name || 'No name',
          prospectsAvailable,
        },
      }).then(() => {
        setRemoveCompanyIds([]);
        hideIsSelectAll();
      });
    }
  };

  useEffect(() => {
    const id = params?.prospectsId;
    onFetchSavedList(id);
    toggleEdit();
  }, []);

  useEffect(() => {
    if (savedList?.filters) {
      const pageString = queryString.parse(location.search)?.page;
      onFetchCompanies({
        filters: { page: Number(pageString) || 1, limit: 12, ...savedList?.filters },
        ranks: savedList.filters.priority,
      }).then(companies => {
        if (!(companies && companies.meta)) {
          return;
        }
        const meta = companies.meta as PaginationMeta;
        if (meta.totalItems !== savedList.prospectsAvailable) {
          onFetchSavedListUpdate({
            id: params.prospectsId,
            data: {
              name: savedList.name || 'No name',
              prospectsAvailable: meta.totalItems,
            },
          });
        }
      });
    }
  }, [savedList]);

  useEffect(() => {
    if (savedList?.name) {
      setName(savedList?.name);
    } else {
      toggleEdit();
    }
  }, [savedList]);

  useEffect(() => {
    if (!savedList?.filters.ids?.length) return;
    onFetchTeamNamesByIds(savedList.filters.ids).then(names => {
      setTeamNames(names);
    });
  }, [savedList]);

  useEffect(() => {
    if (isActiveEdit) {
      inputRef.current?.focus?.();
    }
  }, [isActiveEdit]);

  const [showDeleteModal, hideModal] = useModal(() => {
    const id = params?.prospectsId;
    const deleteHandler = () => {
      onFetchSavedLisDestroy(id).then(() => {
        History.back();
      });
      hideModal();
    };

    return (
      <ConfirmModal
        name={'Delete saved list'}
        textAccessBtn="Delete"
        description={'Are you sure you want to delete the saved list?'}
        accessHandler={deleteHandler}
        cancelHandler={hideModal}
        onClose={hideModal}
        hideCloseIcon
      />
    );
  });

  return (
    <ContentBlockWrapper
      header={
        <JumbotronEditTitle
          name={name}
          editBtnName="Rename"
          isEdit={isActiveEdit}
          onSave={str => editHandler(str)}
          onEdit={editHandler}
          onCancel={toggleEdit}
          onDelete={showDeleteModal}
        />
      }
      loading={isLoadingCompany || isLoadingList}
    >
      <TotalRow title={`${totalItems} organization${totalItems > 1 ? 's' : ''}`} />
      {savedList && (
        <>
          <Filters
            marginList="0 14px"
            filters={savedList?.filters}
            companiesNames={teamNames}
          />

          <Header>
            <Actions>
              <ExportButton
                iconCss={editIconCss}
                uploadHandler={() => exportExcel(params.prospectsId)}
              />
              {removeTeamIds.length > 0 && (
                <>
                  <UIActionButton
                    iconCss={basketIconCss}
                    handleClick={onDeleteCompanyFromSavedList}
                    icon={BasketIcon}
                    name="Delete"
                  />
                </>
              )}
            </Actions>
            {totalItems > 0 && (
              <Pagination
                total={totalItems}
                pageLimit={Number(itemsPerPage)}
                page={Number(currentPage)}
                submitHandler={page =>
                  onFetchCompanies({
                    filters: { page, limit: 12, ...savedList?.filters },
                    ranks: savedList.filters.priority,
                  })
                }
              />
            )}
          </Header>
          <List<TeamProfileData, CardTeamProps>
            list={companies}
            component={CardTeam}
            isIndexKey
            componentProps={{
              setSelectedTeam: removeTeamHandler,
              selectedTeamIds: removeTeamIds,
              isSelectedCard: false,
            }}
          />
        </>
      )}
    </ContentBlockWrapper>
  );
};

const Actions = styled.div`
  display: flex;

  & > div {
    margin-right: 24px;
  }

  & button {
    margin-right: 24px;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 27px;
  max-width: ${PAGE_WIDTH.NORMAL}px;
`;

export default ProspectsList;
