/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import Jumbotron from 'components/Jumbotron';
import styled from 'styled-components/macro';
import { ReactSVG } from 'react-svg';
import { UIButton, UISwitch } from 'components/UI';
import GamePlanTemplate from '../components/GamePlanTemplate';
import EditIcon from 'assets/images/icons/edit.svg';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import {
  getGameplan,
  getGameplanEmailAnalyticsContacts,
} from 'store/ducks/gameplan/selectors';
import { Gameplan } from 'interfaces/gameplan';
import { StateProps } from 'store/interfaces';
import { useModal, useOperation } from 'hooks/common';
import {
  clearEditGameplan,
  fetchChangeSingleGameplanNameAndClose,
  fetchDeleteGameplan,
  fetchGameplan,
  fetchGameplanEmailAnalytics,
} from 'store/ducks/gameplan/operations';
import { ConfirmModal } from 'components/Modals';
import arrayMutators from 'final-form-arrays';
import GameplanRecipientsModal from '../GameplanRecipientsModal/GameplanRecipientsModal';
import { getTeam } from 'store/ducks/team/selectors';
import { Field } from 'components/FinalForm';
import { FieldSwitcher } from 'components/FormFields';
import { OnChange } from 'react-final-form-listeners';

const ViewGamePlanTemplate = () => {
  const params: {
    gameplanName: string;
    gameplanId: string;
  } = useParams();
  const { go } = useHistory();

  const [name, setName] = useState(params.gameplanName);
  const [isEditTitle, setIsEditTitle] = useState(false);

  const [onFetchChangeGameplanNameAndClose] = useOperation(
    fetchChangeSingleGameplanNameAndClose,
  );
  const [onFetchGameplan, , isGameplanLoading] = useOperation(fetchGameplan);
  const [onFetchDeleteGameplan] = useOperation(fetchDeleteGameplan);
  const [onFetchGameplanEmailAnalytics, , isGameplanEmailAnalyticsLoading] = useOperation(
    fetchGameplanEmailAnalytics,
  );
  const [onClearEditGameplan] = useOperation(clearEditGameplan);

  const gameplan = useSelector<StateProps, Gameplan | undefined>(getGameplan);
  const team = useSelector(getTeam);
  const emailAnalyticsContacts = useSelector(getGameplanEmailAnalyticsContacts);

  const handelEditTitle = () => {
    onFetchChangeGameplanNameAndClose({ id: params.gameplanId, name });
    setIsEditTitle(false);
  };

  const handelChangeClose = useCallback(() => {
    onFetchChangeGameplanNameAndClose({
      id: params.gameplanId,
      name,
      isClose: !gameplan?.isClose,
    });
    setIsEditTitle(false);
  }, [gameplan]);

  const handelChangeAutoRemoveContacts = useCallback(() => {
    onFetchChangeGameplanNameAndClose({
      id: params.gameplanId,
      name,
      isClose: gameplan?.isClose,
      autoReplyDelete: !gameplan?.autoReplyDelete,
    });
    setIsEditTitle(false);
  }, [gameplan]);

  const toRouteGameplanList = () => {
    go(-1);
  };

  const [showConfirmModal, hideConfirmModal] = useModal(() => (
    <ConfirmModal
      accessHandler={() => {
        onFetchDeleteGameplan(params.gameplanId);
        toRouteGameplanList();
      }}
      cancelHandler={hideConfirmModal}
      name="Delete game plan"
      description="Are you sure to delete this game plan?"
      textAccessBtn="Delete"
      onClose={hideConfirmModal}
    />
  ));

  const [showGameplanRecipientsModal, hideGameplanRecipientsModal] = useModal(
    () => (
      <GameplanRecipientsModal
        onClose={hideGameplanRecipientsModal}
        contacts={emailAnalyticsContacts || []}
      />
    ),
    [emailAnalyticsContacts],
  );

  useEffect(() => {
    onFetchGameplan(params.gameplanId);
  }, []);

  useEffect(
    () => () => {
      onClearEditGameplan(undefined);
    },
    [],
  );

  if (!team) return null;

  return (
    <ContentBlockWrapper
      loading={isGameplanLoading}
      header={
        <Jumbotron name="" isWithName={false}>
          {isEditTitle ? (
            <Input
              value={name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setName(event.target.value)
              }
              placeholder="No name"
              autoFocus
            />
          ) : (
            <InputValue>{name}</InputValue>
          )}
          <GroupBtn>
            {/* <EditButton
              onClick={() => {
                if (isEditTitle) {
                  handelEditTitle();
                }
                setIsEditTitle(!isEditTitle);
              }}
            >
              {!isEditTitle ? (
                <>
                  <Icon src={EditIcon} /> Edit
                </>
              ) : (
                'Save'
              )}
            </EditButton> */}
            {isEditTitle ? (
              <CancelButton
                modifiers="danger"
                title="Cancel"
                handleClick={() => setIsEditTitle(false)}
              />
            ) : (
              <CancelButton
                modifiers="danger"
                title="Delete"
                handleClick={showConfirmModal}
              />
            )}
            <UISwitch
              isText
              checked={!gameplan?.isClose}
              onChange={() => handelChangeClose()}
            />
          </GroupBtn>
        </Jumbotron>
      }
    >
      <Header>
        <span>Email Template</span>
        {team?.isAnalyticsGamePlan && (
          <Btn
            title="View Recipients"
            modifiers="primary"
            isLoading={isGameplanEmailAnalyticsLoading}
            handleClick={() => {
              onFetchGameplanEmailAnalytics(params.gameplanId).then(() => {
                showGameplanRecipientsModal();
              });
            }}
          />
        )}
      </Header>
      <Form
        initialValues={gameplan}
        mutators={{
          ...arrayMutators,
        }}
        onSubmit={() => {}}
        render={() => (
          <>
            <Field
              name="autoReplyDelete"
              label="Remove recipients from Game Plan if they have replied to any game plan
                emails (excluding auto-replies)"
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              component={Switch}
            />
            <OnChange name="autoReplyDelete">
              {(value, previous) => {
                if (value !== previous) {
                  handelChangeAutoRemoveContacts();
                }
              }}
            </OnChange>
            <GamePlanTemplate
              isReadonly={true}
              team={team}
              emailAnalytics={gameplan?.emailTemplateAnalytics}
              gamePlanTemplates={gameplan?.gamePlanTemplates}
            />
            <BtnGroup>
              <BackBtn title="Back" handleClick={toRouteGameplanList} />
            </BtnGroup>
          </>
        )}
      ></Form>
    </ContentBlockWrapper>
  );
};

const BtnGroup = styled.div`
  display: flex;
  max-width: 1314px;
`;

const StepBtn = styled(UIButton)`
  width: 146px;
`;

const BorderBtn = styled(StepBtn)`
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  font-size: 16px;
`;

const BackBtn = styled(BorderBtn)`
  margin-right: 8px;
`;

const Input = styled.input`
  width: 920px;
  height: 48px;
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  border: none;
  color: var(--black);

  &:disabled {
    color: var(--black);
    background: transparent;
    text-overflow: ellipsis;
    pointer-events: none;
  }

  &:-webkit-input-placeholder {
    color: var(--darkGray);
  }

  &:-moz-placeholder {
    color: var(--darkGray);
  }

  &:-ms-input-placeholder {
    color: var(--darkGray);
  }

  &:-moz-placeholder {
    color: var(--darkGray);
  }
`;

const InputValue = styled.p`
  width: 920px;
  height: 48px;
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  border: none;
  color: var(--black);
  padding: 0px 2px;
`;

const GroupBtn = styled.div`
  display: flex;
`;

const Icon = styled(ReactSVG)`
  margin-right: 9px;
`;

const EditButton = styled.button`
  border-radius: 6px;
  border: 1px solid #2baee0;
  transition: all 0.2s ease;
  padding: 8px 0;
  width: 82px;
  color: var(--black);
  font-size: 12px;
  line-height: 150%;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #ebf9ff;
    color: #2baee0;
  }

  &:active {
    background: #caf0ff;
    color: #2baee0;
  }

  &:disabled {
    background: #ceedf9;
    border-color: rgba(43, 174, 224, 0.3);
    opacity: 0.5;
    color: #2baee0;
    cursor: not-allowed;
  }
`;

const CancelButton = styled(UIButton)`
  width: 76px;
  margin-right: 8px;
  ${props => (props.modifiers !== 'danger' ? 'color: var(--blue)' : null)}
`;

const Header = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Btn = styled(UIButton)`
  width: 207px;
`;

const Switch = styled(FieldSwitcher)`
  width: max-content;

  label > span {
    font-size: 16px;
    line-height: 145%;
    color: #979797;
  }
`;

export default ViewGamePlanTemplate;
