/* eslint-disable react-hooks/exhaustive-deps */
import GiveandShop from 'components/Campaign/GiveandShop';
import { NotFound } from 'components/NotFound';
import { UISpinner } from 'components/UI';
import { useOperation } from 'hooks/common';
import useRedirect from 'hooks/common/useRedirect';
import { AuthenticatedCompanyWrapper } from 'layouts';
import CanvaLogin from 'pages/auth/CanvaLogin';
import { UsersCompany, Password, UserProfile } from 'pages/companyUser/company';
import Profile from 'pages/companyUser/company/Profile';
import ProfileContacts from 'pages/companyUser/company/ProfileContacts';
import EditCompanyContact from 'pages/companyUser/company/ProfileContactsEdit';
import ProfileEdit from 'pages/companyUser/company/ProfileEdit';
import Setting from 'pages/companyUser/company/Setting';
import Dashboard from 'pages/companyUser/dashboard';
import { Giving } from 'pages/companyUser/giving/Giving';
import { IssueMapping } from 'pages/companyUser/issueMapping/IssueMapping';
import ROI from 'pages/companyUser/roi/ROI';
import { Search } from 'pages/companyUser/search';
import Prospects from 'pages/companyUser/search/Prospects';
import SearchBatch from 'pages/companyUser/search/SearchBatch';
import ProspectsList from 'pages/companyUser/search/ProspectsList';
import TeamDetail from 'pages/companyUser/search/TeamDetail';
import Campaign from 'pages/organizationUser/Fundraising';
import GiveATWork from 'pages/organizationUser/Fundraising/GiveATWork';
import { ROISlimReport } from 'pages/organizationUser/roi';
import ROIReportEdit from 'pages/organizationUser/roi/ROIReportEdit';
import { ROISurveyList } from 'pages/organizationUser/roi/ROISurveyList';
import { ROISurveyResponse } from 'pages/organizationUser/roi/ROISurveyResponse';
import ROITracking from 'pages/organizationUser/roi/ROITracking';
import { ROIViewTeam } from 'pages/organizationUser/roi/ROIViewTeam';
import ConsumerSurvey from 'pages/survey/ConsumerSurvey';
import EmployeeSurvey from 'pages/survey/EmployeeSurvey';
import IssueMappingEmployeeSurvey from 'pages/survey/IssueMappingEmployeeSurvey';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import { fetchSubscriptionCompany } from 'store/ducks/companies/operations';
import { getCompanySubscription } from 'store/ducks/companies/selectors';
import styled from 'styled-components';
import NoSubscriptionsCompanyRoute from './NoSubscriptionsCompanyRoute';
import Route from './Route';
import BatchList from 'pages/companyUser/search/BatchList';
import CampaignTS from 'pages/organizationUser/Fundraising/indexTeamSnap';

const AuthorizedCompanyRoutes = () => {
  const [onFetchSubscriptionCompany, isLoading] = useOperation(fetchSubscriptionCompany);

  const subscription = useSelector(getCompanySubscription);

  const isSubscription = useMemo(
    () =>
      subscription &&
      Object.entries(subscription)
        .filter(([key]) => key !== 'id' && key !== 'companyId')
        .some(([, value]) => value),
    [subscription],
  );

  useRedirect();

  useEffect(() => {
    onFetchSubscriptionCompany(undefined);
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return isSubscription ? (
    <Switch>
      <Route path="/canva-login" component={CanvaLogin} />

      <Route path="/" layout={AuthenticatedCompanyWrapper}>
        <Route path="/dashboard" component={Dashboard} />

        <Route path="/profile" exact component={Profile} />
        <Route path="/profile/edit" component={ProfileEdit} />
        <Route path="/profile/contacts" exact component={ProfileContacts} />
        <Route path="/profile/contacts/edit/:contactId?" component={EditCompanyContact} />
        <Route path="/user-profile" component={UserProfile} />
        <Route path="/password" component={Password} />
        <Route path="/teams" component={UsersCompany} />

        <Route path="/issue-mapping" component={IssueMapping} />

        <Route path="/giving" exact component={Giving} />

        <Route path="/roi" exact component={ROI} />
        <Route path="/roi/tracking/:roiId" component={ROITracking} />
        <Route path="/roi/report-edit/:roiId" component={ROIReportEdit} />
        <Route path="/roi/slim-report/:roiId" component={ROISlimReport} />
        <Route path="/roi/survey-list/:pageType" component={ROISurveyList} />
        <Route
          path="/roi/survey-response/:surveyId/:roiId/:surveyType"
          component={ROISurveyResponse}
        />
        <Route path="/roi/view-team/:teamId" component={ROIViewTeam} />

        <Route path="/campaign" exact component={Campaign} />
        <Route path="/campaign/give-work" exact component={GiveATWork} />
        <Route path="/campaign/give-shop" exact component={GiveandShop} />

        <Route path="/campaignTeamsnap" exact component={CampaignTS} />

        <Route path="/search" component={Search} />
        <Route path="/team-detail/:teamId" component={TeamDetail} />

        <Route path="/prospects" exact component={Prospects} />
        <Route path="/prospects/:prospectsId" component={ProspectsList} />

        <Route path="/SearchBatch" exact component={SearchBatch}></Route>
        <Route path="/SearchBatch/:SearchBatchId" component={BatchList} />

        <Route path="/survey/consumer" component={ConsumerSurvey} />
        <Route path="/survey/employee" component={EmployeeSurvey} />
        <Route
          path="/survey/issuemapping-employee"
          component={IssueMappingEmployeeSurvey}
        />

        <Route path="/setting" component={Setting} />

        <Route path="/404" component={NotFound} />
        <Route path="/" component={() => <Redirect to="/dashboard" />} />
      </Route>
    </Switch>
  ) : (
    <NoSubscriptionsCompanyRoute />
  );
};

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default AuthorizedCompanyRoutes;
