import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import EmptyImg from 'assets/images/icons/empty-company.svg';

export interface EmptyCompanyProps {
  className?: string;
  title?: string;
}

const EmptyCompany = ({ className, title = 'company' }: EmptyCompanyProps) => {
  return (
    <EmptyWrapper className={className}>
      <Icon src={EmptyImg} />
      <Title>No {title}</Title>
    </EmptyWrapper>
  );
};

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 6px;
`;

const Icon = styled(ReactSVG)`
  margin-bottom: 40px;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  margin-bottom: 8px;
`;

export default EmptyCompany;
