import React from 'react';
import { UIRadioBtnProps } from './interfaces';
import styled from 'styled-components';

function UIRadioBtn({
  label,
  handleCheck,
  isChecked = false,
  disabled,
  className,
}: UIRadioBtnProps) {
  return (
    <Wrapper className={className} onClick={handleCheck}>
      <Btn isChecked={isChecked} disabled={disabled} />
      <Label disabled={disabled}>{label}</Label>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
  cursor: pointer;
`;

const Btn = styled.div<{ isChecked?: boolean; disabled?: boolean }>`
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid var(--gray);
  box-sizing: border-box;
  border-radius: 100%;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  &:after {
    content: '';
    display: flex;
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 100%;
  }

  &:hover {
    border-color: var(--blue);
    &:after {
      background-color: var(--blue);
    }
  }

  ${({ isChecked }) =>
    isChecked &&
    `
  border-color: var(--blue);
    &:after {
      background-color: var(--blue);
    }
  `}

  ${({ isChecked, disabled }) =>
    ((disabled && isChecked) || disabled) &&
    `
    border-color: #E8E8E8;
      &:after {
        background-color: #fff;
      }
    `}
`;

const Label = styled.p<{ disabled?: boolean }>`
  font-size: 16px;
  line-height: 155%;

  ${({ disabled }) => disabled && `color: var(--gray);`}
`;

export default UIRadioBtn;
