import React from 'react';
import { UIButton } from 'components/UI';
import styled from 'styled-components';
import { FormTitle } from 'components/UI/form';
import { useHistory } from 'react-router';
import { useModal, useOperation } from 'hooks/common';
import {
  fetchCustomizeEmailTemplateSave,
  fetchEmailCustomizeTemplate,
  fetchGameplanCustomizeTemplate,
  fetchGameplanCustomizeTemplatePreview,
  fetchGameplanCustomizeTemplateReset,
  fetchGameplanCustomizeTemplateSave,
} from 'store/ducks/gameplan/operations';
import { GamePlanEmailTemplateTypeEnum } from 'constants/gamePlan';
import { useSelector } from 'react-redux';
import {
  getEmailCustomizeTemplate,
  getGameplanCustomizeTemplate,
  getGameplanCustomizeTemplatePreview,
} from 'store/ducks/gameplan/selectors';
import CustomizeEmailTemplateModal from './CustomizeEmailTemplateModal';

const EmailTemplate = () => {
  const pristine = false; // Example state
  const submitting = false; // Example state
  const hasValidationErrors = false; // Example state
  const [onFetchEmailCustomizeTemplate, , isCustomizeLoading] = useOperation(
    fetchEmailCustomizeTemplate,
  );
  const [onFetchCustomizeEmailTemplateSave] = useOperation(
    fetchCustomizeEmailTemplateSave,
  );
  const [onFetchGameplanCustomizeTemplateReset] = useOperation(
    fetchGameplanCustomizeTemplateReset,
  );
  // const [onFetchGameplanCustomizeTemplatePreview] = useOperation(
  //   fetchGameplanCustomizeTemplatePreview,
  // );
  const emailCustomizeTemplate = useSelector(getEmailCustomizeTemplate);
  const gameplanCustomizeTemplatePreview = useSelector(
    getGameplanCustomizeTemplatePreview,
  );
  const history = useHistory();
  const [showCustomizeTemplateModal, hideCustomizeTemplateModal] = useModal(() => {
    return (
      <CustomizeEmailTemplateModal
        template={emailCustomizeTemplate}
        isloading={isCustomizeLoading}
        onClose={hideCustomizeTemplateModal}
        //onReset={templateType => onFetchGameplanCustomizeTemplateReset(templateType)}
        onSave={values => {
          onFetchCustomizeEmailTemplateSave(values).then(() => {
            const currentPath = history.location.pathname;
            history.push('/temporary-route'); // navigate to a temporary route
            history.replace(currentPath); // immediately replace it with the current one
          });
        }}

        //onPreview={templateType => onFetchGameplanCustomizeTemplatePreview(templateType)}
      />
    );
  }, [emailCustomizeTemplate, isCustomizeLoading, gameplanCustomizeTemplatePreview]);

  const handleSubmit = () => {
    onFetchEmailCustomizeTemplate({
      templateType: GamePlanEmailTemplateTypeEnum.PitchTemplate,
    });
    showCustomizeTemplateModal();
  };

  return (
    <Root>
      <TitleAndButtonWrapper>
        <FormTitle>Email Templates</FormTitle>
        <Btn
          modifiers="primary"
          title="Create Template"
          handleClick={handleSubmit}
          type="submit"
          disabled={pristine || submitting || hasValidationErrors}
          isLoading={submitting}
        />
      </TitleAndButtonWrapper>
    </Root>
  );
};

// Adjusted Btn for a less chunky appearance and aligned width
const Btn = styled(UIButton)`
  margin-top: 16px;
  height: 40px; // Adjust height as needed
  padding: 0 20px; // Adjust padding for less chunkiness
  align-self: center; // Align button vertically in the flex container
`;

// Flex container to align title and button
const TitleAndButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between; // Aligns children (title and button) on opposite ends
  align-items: center; // Centers children vertically
  width: 100%; // Ensure the container takes full width to align items properly
`;

const Root = styled.div`
  background: #ffffff;
  border-radius: 4px;
  padding: 24px 24px;
  margin-bottom: 24px;
`;

export default EmailTemplate;
