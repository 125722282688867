import { UIDivider } from 'components/UI';
import React from 'react';
import styled from 'styled-components';
import { moneyNumberFormat } from 'utils/formatters';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';

interface Props {
  salesDriven: number;
  savedInMitigatedRisk: number;
  themeColor?: string;
}

const BottomLineImpactMetric = ({
  salesDriven,
  savedInMitigatedRisk,
  themeColor,
}: Props) => {
  return (
    <Root $themeColor={themeColor}>
      <MetricTitle>Bottom Line Impact</MetricTitle>
      <Content>
        <Value>
          <div>Sales Driven</div>
          <div>{moneyNumberFormat(salesDriven)}</div>
        </Value>
        <Divider type="horizontal" />
        <Value>
          <div>Saved in Mitigated Risk</div>
          <div>{moneyNumberFormat(savedInMitigatedRisk)}</div>
        </Value>
      </Content>
    </Root>
  );
};

const Root = styled(MetricRoot)`
  /* background: #18536a; */
`;

const Value = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;

  & > div:first-child {
    flex: 1;
  }
`;

const Content = styled(MetricContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Divider = styled(UIDivider)`
  margin: 30px 0;
  flex-shrink: 0;
`;

export default BottomLineImpactMetric;
