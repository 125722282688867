import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { UIModalProps } from 'components/UI/interfaces';
import { UIButton, UIModal } from 'components/UI';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import { PitchProps, PitchValues } from 'interfaces/common';
import { cropText } from 'utils/formatters';
import PitchForm from 'components/Forms/PitchForm';
import { useFetchData, useModal, useOperation } from 'hooks/common';
import { Form } from 'react-final-form';
import { Field } from 'components/FinalForm';
import { FieldTextInput } from 'components/FormFields';
import JumpAlertLink from 'components/JumpAlertLink';
import { fetchEmailPreview } from 'store/ducks/utility/api';
import HTMLPreviewModal from '../HTMLPreviewModal';
import { ReactSVG } from 'react-svg';
import { useSelector } from 'react-redux';
import { getTeam } from 'store/ducks/team/selectors';
import { fetchEmailTemplates } from 'store/ducks/team/operations';
import { teamSelectors } from 'store/ducks/team';
import { StateProps } from 'store/interfaces';
import { TemplateEmailPreview } from 'interfaces/common';
import DropdownList from 'components/DropdownList/DropdownList';
import EllipseSVG from 'assets/images/icons/ellipse.svg';
import { FormApi } from 'final-form';

interface BaseProps {
  onSubmit: (values: PitchValues) => OnSubmitReturnType;
  onSendTestEmail: (values: PitchValues, testEmail: string) => void;
}

type Props = BaseProps & UIModalProps & PitchProps;

const nameLength = 15;
const emailLength = 18;

function PitchModal({
  onClose,
  pitch,
  onSubmit = () => {},
  onSendTestEmail,
  ...modalProps
}: Props) {
  const [onFetchEmailPreview, emailHtml, isEmailPreviewLoading] = useFetchData(
    fetchEmailPreview,
  );

  const [selectedTemplate, setSelectedTemplate] = useState<TemplateEmailPreview | null>(
    null,
  );
  const team = useSelector(getTeam);
  const emailTemplates = useSelector<StateProps, TemplateEmailPreview[]>(
    teamSelectors.getEmailTemplates,
  );
  const [loadEmailTemplates, isStartLoading] = useOperation(fetchEmailTemplates);

  useEffect(() => {
    if (team?.id) {
      loadEmailTemplates(team.id);
    }
  }, [team?.id, loadEmailTemplates]);

  const [pitchFormValues, setPitchFormValues] = useState<PitchValues | undefined>(
    undefined,
  );
  const { recipient, sender } = pitch;

  const senderName = cropText(sender.name, nameLength);
  const senderEmail = cropText(sender.email, emailLength);

  const [showTestEmailModal, hideTestEmailModal] = useModal(
    () => (
      <TestEmailModal onClose={hideTestEmailModal} hideCloseIcon>
        <Form
          onSubmit={values => {
            pitchFormValues && onSendTestEmail(pitchFormValues, values.testEmail);
            hideTestEmailModal();
          }}
          render={({ handleSubmit }) => (
            <div>
              <TestEmailInputTitle>Send Test Email</TestEmailInputTitle>
              <Field
                name="testEmail"
                label="Test Email Recipient"
                component={FieldTextInput}
              />
              <ButtonWrapper>
                <UIButton
                  style={{ width: '100px' }}
                  title="Close"
                  modifiers="secondary"
                  handleClick={hideTestEmailModal}
                />
                <UIButton
                  style={{ width: '100px' }}
                  title="Send"
                  modifiers="primary"
                  handleClick={handleSubmit}
                />
              </ButtonWrapper>
            </div>
          )}
        />
      </TestEmailModal>
    ),
    [pitchFormValues],
  );

  const [showPreviewModal, hidePreviewModal] = useModal(() => {
    return (
      <HTMLPreviewModal
        html={emailHtml}
        isloading={isEmailPreviewLoading}
        onClose={hidePreviewModal}
      />
    );
  }, [emailHtml, isEmailPreviewLoading]);

  const handleTemplateSelect = templateId => {
    const template = emailTemplates.find(t => t.id === templateId);
    console.log(template);
    setPitchFormValues(values => ({
      subject: template?.subject ?? '',
      text: template?.body ?? '',
      attachments: values?.attachments ?? [],
      bcc: values?.bcc ?? '',
    }));
  };

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '28px 40px 40px' }}
      style={{
        width: '100%',
        maxWidth: '844px',
        top: '100px',
      }}
      overlayStyle={{
        overflow: 'auto',
      }}
      header={
        <ModalHeader>
          <ModalTitle>Pitch</ModalTitle>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginBottom: '4px' }}>
              <AlertLink path="/email-design">Email Design</AlertLink>
            </div>
            <TemplateDropdown>
              <DropdownList
                title={
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      color: 'var(--blue)',
                      fontSize: '16px',
                    }}
                  >
                    Select Template{'  '}
                    <EllipseIcon src={EllipseSVG} style={{ marginLeft: '4px' }} />
                  </span>
                }
                options={emailTemplates.map(t => ({
                  label: t.name || 'Unnamed Template', // Provide a default label if t.name is undefined
                  value: t.id,
                }))}
                disableArrow
                onClick={option => handleTemplateSelect(option)} // pass form here
              />
            </TemplateDropdown>
          </div>
        </ModalHeader>
      }
    >
      <HeaderPitch>
        <HeaderPitchInner>
          <HeaderText>To</HeaderText>
          <UserInfoList>
            {recipient.map(item => (
              <UserInfo key={item.email}>
                <span data-tip={item.name}>{cropText(item.name, nameLength)}</span>

                <span data-tip={item.email}>{cropText(item.email, emailLength)}</span>
              </UserInfo>
            ))}
          </UserInfoList>
        </HeaderPitchInner>
        <HeaderPitchInner>
          <HeaderText>From</HeaderText>
          <UserInfo>
            <span data-tip={sender.name}>{senderName}</span>

            <span data-tip={sender.email}>{senderEmail}</span>
          </UserInfo>
        </HeaderPitchInner>
        <ReactTooltip className="tooltip-info" place="top" type="info" effect="solid" />
      </HeaderPitch>
      <PitchForm
        onSubmit={onSubmit}
        onSendTestEmail={values => {
          setPitchFormValues(values);
          showTestEmailModal();
        }}
        closeHandler={onClose}
        initialValues={
          pitchFormValues || {
            attachments: pitch.attachments,
            text: pitch.text,
            bcc: '',
            subject: 'Social Impact Partnership – Accelerist VIP',
          }
        }
        onClickPreviewEmail={(body, subject) => {
          onFetchEmailPreview({
            body,
            subject,
            attachments: [],
            emailTo: recipient[0]?.email || '',
          });
          showPreviewModal();
        }}
        onClickTemplateSelect={handleTemplateSelect} // This is not directly applicable. Adjusted approach below.
      />
    </UIModal>
  );
}

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EllipseIcon = styled(ReactSVG)`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 30px;
`;

const TemplateDropdown = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto; // This will push the dropdown to the right
  margin-top: auto;
`;

const ModalTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  padding: 40px 0 16px;
  display: flex;
`;

const HeaderPitch = styled.div`
  margin-bottom: 12px;
`;

const HeaderPitchInner = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const HeaderText = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
  margin-right: 50px;
  width: 29px;
`;

const UserInfo = styled.div`
  display: flex;
  width: 100%;
  max-width: 271px;
  justify-content: space-between;
  background: #e8e8e8;
  border-radius: 13px;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  padding: 4px 19px;
  cursor: default;
  margin-right: 6px;
  white-space: nowrap;

  & > span:first-child {
    margin-right: 8px;
  }
`;

const UserInfoList = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
`;

const TestEmailModal = styled(UIModal)`
  width: 576px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TestEmailInputTitle = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 46px;
  text-align: center;
`;

const AlertLink = styled(JumpAlertLink)`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export default PitchModal;
