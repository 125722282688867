import {
  Charitable,
  CharitablesReq,
  CharitablesRes,
  GivingDeleteReq,
  IssueMappingSurveyCreateReq,
  IssueMappingSurveyData,
  IssueMappingSurveyListReq,
  IssueMappingSurveyListRes,
  IssueMappingSurveyResult,
  RecommendationData,
} from 'interfaces/issueMapping';
import { fetch } from 'services/ApiService';
import { paramsToQueryString } from 'utils';
import StorageService from 'services/Storage';

export const fetchIssueMappingSurveyCreate = (params: IssueMappingSurveyCreateReq) =>
  fetch('post', 'issueSurvey', { payload: params });

export const fetchIssueMappingSurveyUpdate = (params: IssueMappingSurveyCreateReq) =>
  fetch('patch', 'issueSurvey', { payload: params });

export const fetchIssueMappingSurveyList = ({
  limit = 12,
  ...params
}: IssueMappingSurveyListReq) =>
  fetch<IssueMappingSurveyListRes>(
    'get',
    `issueSurvey${paramsToQueryString({ limit, ...params })}`,
  );

export const fetchIssueMappingSurveyDelete = (deleteIds: string[]) =>
  fetch(
    'delete',
    `issueSurvey/MultipleDelete${paramsToQueryString({
      deleteIds: deleteIds.join(),
    })}`,
  );

export const fetchDownloadIssueMappingSurveyTemplate = () =>
  fetch<{ name: string; file: string }>('get', 'issueSurvey/downloadTemplate');

export const fetchIssueMappingExportResult = (id: string) =>
  fetch<{ name: string; file: string }>('get', `issueSurvey/export-result/${id}`);

export const fetchUploadIssueSurveyContacts = (file: File) => {
  const data = new FormData();
  data.append('file', file, file?.name);

  return fetch<IssueMappingSurveyData['contacts']>(
    'post',
    `issueSurvey/upload-excel`,
    {
      payload: data,
    },
    {
      Authorization: `Bearer ${StorageService.getAccessToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  );
};

export const fetchIssueMappingSurveyResult = (id: string) =>
  fetch<IssueMappingSurveyResult>('get', `issueSurvey/result/${id}`);

export const fetchIssueMappingSurveyResultSubmit = (params: IssueMappingSurveyResult) =>
  fetch('post', `issueSurvey/result`, { payload: params });

export const fetchRecommendation = () =>
  fetch<RecommendationData>('get', 'recommendation/current');

export const fetchCharitables = ({ limit = 10, ...params }: CharitablesReq) =>
  fetch<CharitablesRes>(
    'get',
    `recommendation/charitables${paramsToQueryString({ limit, ...params })}`,
  );

export const fetchGivingCreate = (charitables: Charitable[]) =>
  fetch('post', 'giving', { payload: charitables });

export const fetchGivingDelete = (params: GivingDeleteReq) =>
  fetch('delete', `giving/Delete`, { payload: params });
