import React, { useMemo } from 'react';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';
import { ROIMeltwaterData } from 'interfaces/roi';
import styled from 'styled-components';
import { BarChart, Bar, XAxis, Legend, YAxis, LabelList } from 'recharts';
import { numberFormat } from 'utils/formatters';

interface Props {
  data?: ROIMeltwaterData['analyticsData']['exports']['documentTypes'];
}

const renderCustomizedLabel = props => {
  const { value, y, width, height } = props;
  return (
    <text x={width + 4} y={y + height / 2 + 4} fontSize={12}>
      {numberFormat(value)}
    </text>
  );
};

const TotalPotentialReachMetric = ({ data }: Props) => {
  const maxValue = useMemo(() => {
    const max = data?.sort((a, b) => b.source_reach - a.source_reach)[0].source_reach;
    if (!max) return undefined;
    return max + max * 0.35;
  }, [data]);

  return (
    <MetricRoot>
      <MetricTitle>Total Potential Reach</MetricTitle>
      <Content>
        {data && (
          <BarChart
            width={320}
            height={156}
            data={data}
            layout="vertical"
            margin={{ top: 5 }}
            barCategoryGap={8}
          >
            <XAxis type="number" domain={[0, maxValue]} hide />
            <YAxis type="category" dataKey="document_media_type" hide />
            <Legend
              iconType="circle"
              formatter={() => 'Source Search'}
              iconSize={6}
              wrapperStyle={{ fontSize: '12px', fontWeight: 500, bottom: '-4px' }}
            />
            <Bar
              isAnimationActive={false}
              dataKey="source_reach"
              fill="#28759C"
              radius={4}
              maxBarSize={32}
              label={({ y, index }) => {
                return (
                  <text y={y} fill="#666" textAnchor="start" dy={-4} fontSize="12px">
                    {data?.[index].document_media_type}
                  </text>
                );
              }}
            >
              <LabelList position="right" content={renderCustomizedLabel} />
            </Bar>
          </BarChart>
        )}
      </Content>
    </MetricRoot>
  );
};

const Content = styled(MetricContent)`
  position: relative;
`;

export default TotalPotentialReachMetric;
