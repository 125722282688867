import React, { useMemo } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { FieldInput, FieldUploadFile } from 'components/FormFields';
import { OnSubmitReturnType } from 'interfaces/finalForm';

import styled from 'styled-components';
import { Field } from 'components/FinalForm';
import { UIButton } from 'components/UI';
import { SendGridTemplate } from 'interfaces/emailDesign';
import { gameplanTemplateOptions, pitchTemplateOptions } from 'constants/emailDesign';
import { urlLink } from 'utils/validation';

export interface FormProps {
  initialValues: SendGridTemplate;
  selectValues: Pick<SendGridTemplate, 'emailType' | 'templateId'>;
  handleSubmit: (values: SendGridTemplate) => OnSubmitReturnType;
}

interface DisplayFields {
  hasLogo: boolean;
  hasBanner: boolean;
  hasButton: boolean;
  hasUrl: boolean;
}

const EmailDesignForm = ({ initialValues, selectValues, handleSubmit }: FormProps) => {
  const { hasLogo, hasBanner, hasButton, hasUrl } = useMemo((): DisplayFields => {
    const isEqualGameplanOptions = (index: number) =>
      selectValues.templateId === gameplanTemplateOptions[index].value;
    const isEqualPitchOptions = (index: number) =>
      selectValues.templateId === pitchTemplateOptions[index].value;

    const defaultObj: DisplayFields = {
      hasLogo: false,
      hasBanner: false,
      hasButton: false,
      hasUrl: false,
    };

    if (!selectValues.emailType || !selectValues.templateId) return defaultObj;
    if (isEqualGameplanOptions(0) || isEqualPitchOptions(0)) return defaultObj;
    if (isEqualGameplanOptions(1))
      return { ...defaultObj, hasBanner: true, hasButton: true, hasUrl: true };

    return { ...defaultObj, hasLogo: true, hasBanner: true, hasButton: true };
  }, [selectValues]);

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({
          handleSubmit,
        }: FormRenderProps<
          SendGridTemplate & { template: string; type: SendGridTemplate['emailType'] }
        >) => {
          return (
            <FormStyled>
              <>
                {hasLogo && (
                  <Field
                    name="logo"
                    label="Logo"
                    component={FieldUploadFile}
                    hideIcon
                    title="Upload"
                    category="email-design"
                    htmlId="email-design-logo"
                    isSingleFile
                  />
                )}
                {hasBanner && (
                  <Field
                    name="banner"
                    label="Banner Image"
                    component={FieldUploadFile}
                    hideIcon
                    title="Upload"
                    category="email-design"
                    htmlId="email-design-banner"
                    isSingleFile
                  />
                )}
                {hasButton && (
                  <>
                    <Field
                      name="buttonLabel"
                      label="Button Label"
                      component={FieldInput}
                    />
                    <Field name="buttonUrl" label="Button URL" component={FieldInput} />
                  </>
                )}

                {hasUrl && (
                  <>
                    <Field
                      name="facebookUrl"
                      label="Facebook URL"
                      component={FieldInput}
                      validate={urlLink}
                    />
                    <Field
                      name="twitterUrl"
                      label="Twitter URL"
                      component={FieldInput}
                      validate={urlLink}
                    />
                    <Field
                      name="instagramUrl"
                      label="Instagram URL"
                      component={FieldInput}
                      validate={urlLink}
                    />
                    <Field
                      name="pinterestUrl"
                      label="Pinterest URL"
                      component={FieldInput}
                      validate={urlLink}
                    />
                    <Field
                      name="linkedinUrl"
                      label="Linkedin URL"
                      component={FieldInput}
                      validate={urlLink}
                    />
                  </>
                )}
              </>

              <Button
                modifiers="primary"
                title="Save"
                handleClick={() => {
                  document
                    .getElementById('email-design-selects')
                    ?.dispatchEvent(
                      new Event('submit', { cancelable: true, bubbles: true }),
                    );

                  handleSubmit();
                }}
              />
            </FormStyled>
          );
        }}
      />
    </>
  );
};

const FormStyled = styled.div`
  background: #ffffff;
  width: 374px;
`;

const Button = styled(UIButton)`
  width: 146px;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 40px;
  margin-bottom: 20px;
`;

export default EmailDesignForm;
