import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { WrapperProps } from 'interfaces/common';
import { UISpinner } from 'components/UI';
import { PAGE_WIDTH } from 'constants/common';

interface Props {
  isLoading?: boolean;
}

function OrganizationWrapper({ isLoading, children }: Props & WrapperProps) {
  return (
    <>
      <Header>Organization Profile</Header>
      <Body>
        <Inner>
          <NavList>
            <LinkElem to="/profile">Profile</LinkElem>
            <LinkElem to="/user-profile">User Profile</LinkElem>
            <LinkElem to="/teams">Users</LinkElem>
            <LinkElem to="/password">Password</LinkElem>
            <LinkElem to="/setting">Settings</LinkElem>
            <LinkElem to="/email-design">Email Design</LinkElem>
            <LinkElem to="/email-templates">Email Templates</LinkElem>
            <LinkElem to="/no-contact">No Contact List</LinkElem>
          </NavList>
          <Content>{isLoading ? <Spinner /> : children}</Content>
        </Inner>
      </Body>
    </>
  );
}

const Spinner = styled(UISpinner)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 242px);
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: ${PAGE_WIDTH.LARGE}px;
  margin: 0 auto;
  background: #ffffff;
  height: 96px;
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  color: var(--black);
`;

const Body = styled.div`
  background: #f9f9f9;
  min-height: calc(100% - 176px);
`;

const Inner = styled.div`
  width: 100%;
  max-width: ${PAGE_WIDTH.LARGE}px;
  padding: 0 20px;
  margin: 0 auto;
  padding-top: 33px;
  height: 100%;
  display: flex;
  align-items: flex-start;
`;

const NavList = styled.div`
  background: #ffffff;
  border-radius: 6px;
  padding: 22px 0;
  width: 100%;
  max-width: 204px;
  margin-right: 47px;
  flex-shrink: 0;
`;

const LinkElem = styled(NavLink)`
  display: block;
  font-size: 16px;
  line-height: 155%;
  color: var(--darkGray);
  padding: 8px 40px;

  &:hover {
    background: #caf0ff;
    color: var(--black);
  }

  &.active {
    background: #caf0ff;
    color: var(--black);
  }
`;

const Content = styled.div`
  width: 100%;
`;

export default OrganizationWrapper;
