import lock from 'assets/images/icons/partner-lock.svg';
import lockDisable from 'assets/images/icons/partner-lock-disable.svg';
import { ConfirmModal, InfoModal } from 'components/Modals';
import { CropText, UICheckbox } from 'components/UI';
import { format } from 'date-fns';
import { useModal } from 'hooks/common';
import React, { useCallback, useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import Toast from 'services/Toast';
import linkSvg from 'assets/images/icons/partner-link.svg';
import linkDisable from 'assets/images/icons/partner-link-disable.svg';
import eyeLight from 'assets/images/icons/eye-light.svg';
import TotalRow from 'components/TotalRow';
import QRSVG from 'assets/images/icons/qr.svg';
import QRMagnifierSVG from 'assets/images/icons/qr-magnifier.svg';
import pictureSVG from 'assets/images/icons/picture.svg';
import pictureCircleSVG from 'assets/images/icons/picture-circle.svg';
import UploadButton from 'components/ActionButtons/UploadButton';
import { uploadFile } from 'store/ducks/utility/api';
import { fileExtensionValidator } from 'utils/validation';
import { CamParListItem } from 'interfaces/campaign';

interface Props {
  isPreview?: boolean;
  callCamParListInfo: (page: number) => void;
  onPartnerSelect: (Ids: string[]) => void;
  selectedPartnerIds: string[];
  headCells: string[];
  rows: CamParListItem[];
  callType?: string;
  onClickViewQRCode?: (campaignPartnerId: string) => void;
  onUploadLogo?: (id: string, fileKey: string) => void;
}

interface TableRowProps {
  row: CamParListItem;
  selectedPartnerIds: string[];
  handleRowSelect: (id: string) => void;
}

const tableWidth = {
  checkColumn: 10,
  oneColumn: 108,
  twoColumn: 210,
  thirdColumn: 100,
  fourColumn: 80,
  GiveShopColumn: 100,
  EditColumn: 88,
} as const;

const { oneColumn, twoColumn, thirdColumn } = tableWidth;

const PartnersListInfo = ({
  isPreview,
  onPartnerSelect,
  selectedPartnerIds,
  headCells,
  rows,
  callType,
  onClickViewQRCode,
  onUploadLogo,
}: Props) => {
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [viewToken, setViewToken] = useState<string | undefined>(undefined);
  const [companyCode, setCompanyCode] = useState<string | undefined>(undefined);
  const [selectId, setSelectId] = useState('');

  const handleRowSelect = useCallback(
    (id: string) => {
      if (selectedPartnerIds.includes(id))
        return onPartnerSelect(selectedPartnerIds.filter(item => item !== id));

      onPartnerSelect([...selectedPartnerIds, id]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPartnerIds],
  );

  const [showTokenModal, hideTokenModal] = useModal(
    () => (
      <ConfirmModal
        name={callType !== 'atWork' ? 'Integration Token' : 'Employee Fundraising URL'}
        description={viewToken}
        textAccessBtn="Close"
        textCancelBtn="Copy"
        accessHandler={() => {
          hideTokenModal();
        }}
        onClose={hideTokenModal}
        cancelHandler={() => {
          const getSelection = window.getSelection;
          const copyEle = document.querySelector('.description');
          if (!copyEle) return;
          const range = document.createRange();
          getSelection()?.removeAllRanges();
          range.selectNode(copyEle);
          getSelection()?.addRange(range);
          const copyStatus = document.execCommand('Copy');
          if (copyStatus) {
            Toast.success(
              `You have successfully copied the ${
                callType !== 'atWork' ? 'token' : 'URL'
              }.`,
            );
          } else {
            Toast.error('Copy the failure');
          }
          getSelection()?.removeAllRanges();
          hideTokenModal();
        }}
        maxWidth={496}
        descWidth="100%"
      />
    ),
    [viewToken],
  );

  const [showConfirmModal, hideConfirmModal] = useModal(
    () => (
      <ConfirmModal
        name="Remove Partner"
        description="Are you sure you want to remove this partner from your campaign?"
        textAccessBtn="Yes"
        textCancelBtn="No, Cancel"
        accessHandler={() => {
          onPartnerSelect(selectedPartnerIds.filter(item => item !== selectId));
          hideConfirmModal();
        }}
        onClose={hideConfirmModal}
        cancelHandler={hideConfirmModal}
      />
    ),
    [selectedPartnerIds, selectId],
  );

  const [showCompanyCodeModal, hideCompanyCodeModal] = useModal(
    () => (
      <InfoModal
        onClose={hideCompanyCodeModal}
        title="Company Code"
        description={
          <div style={{ fontSize: '20px', fontWeight: 600 }}>{companyCode}</div>
        }
        onClickOk={hideCompanyCodeModal}
      />
    ),
    [companyCode],
  );

  const TableRowWrapper = ({
    row,
    selectedPartnerIds,
    handleRowSelect,
  }: TableRowProps) => {
    const {
      id,
      companyName,
      name,
      startDate,
      endDate,
      token,
      selected,
      previewUrl,
      campaignPartnerId,
      partnerLogo,
      companyCode,
    } = row;

    const isCheck = selectedPartnerIds.includes(id);

    return (
      <TableRow>
        <TableRowCell>
          <Checkbox
            check={isCheck}
            handleClick={() => {
              if (isPreview && isCheck) {
                if (selected === 1) {
                  setSelectId(id);
                  showConfirmModal();
                  return;
                }
                onPartnerSelect(selectedPartnerIds.filter(item => item !== id));
              } else {
                handleRowSelect(id);
              }
            }}
          />
        </TableRowCell>
        <TableRowCell isAddWeight>
          <CropText text={companyName} width={oneColumn} />
        </TableRowCell>
        <TableRowCell>
          <CropText width={twoColumn} text={name} />
        </TableRowCell>
        <TableRowCell>
          <CropText
            width={thirdColumn - 20}
            text={startDate ? format(new Date(startDate), 'MMM dd, yyyy') : 'N/A'}
          />
        </TableRowCell>
        <TableRowCell>
          <CropText
            width={thirdColumn - 20}
            text={endDate ? format(new Date(endDate), 'MMM dd, yyyy') : 'N/A'}
          />
        </TableRowCell>
        <TableRowCell>
          <IconsWrapper>
            {callType !== 'atWork' && (
              <>
                <UploadButton
                  uploadHandler={files => {
                    if (!files.length) return Promise.resolve(undefined);

                    uploadFile(files[0], 'campaign-partner-logo').then(v => {
                      if (v.status === 'success') {
                        onUploadLogo?.(id, v.fileKey);
                        return Toast.success('The file uploaded successfully');
                      }
                      Toast.error('Error upload file');
                    });
                  }}
                  styleType="label"
                  name=""
                  inputId={`consumer-upload-manage-partner-input-${id}`}
                  disabled={!isCheck}
                  accept=".png,.jpeg,.jpg"
                  acceptValidator={fileExtensionValidator(
                    ['png', 'jpeg', 'jpg'],
                    'Only the following image files are allowed: .png, .jpg, .jpeg',
                  )}
                  icon={partnerLogo ? pictureCircleSVG : pictureSVG}
                  iconCss={{
                    width: '30px',
                    height: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
                <Icon
                  $noPointer={!(selected === 1 && token)}
                  src={selected === 1 && token ? lock : lockDisable}
                  onClick={() => {
                    if (selected === 1 && token) {
                      setViewToken(token);
                      showTokenModal();
                    }
                  }}
                />
                <Icon
                  style={{ visibility: selected !== 1 && 'hidden' }}
                  src={QRSVG}
                  onClick={() => onClickViewQRCode?.(campaignPartnerId)}
                />
                <Icon
                  style={{ visibility: selected !== 1 && 'hidden' }}
                  src={QRMagnifierSVG}
                  onClick={() => {
                    setCompanyCode(companyCode);
                    showCompanyCodeModal();
                  }}
                />
              </>
            )}
            {callType === 'atWork' && (
              <>
                <UploadButton
                  uploadHandler={files => {
                    if (!files.length) return Promise.resolve(undefined);

                    uploadFile(files[0], 'campaign-partner-logo').then(v => {
                      if (v.status === 'success') {
                        onUploadLogo?.(id, v.fileKey);
                        return Toast.success('The file uploaded successfully');
                      }
                      Toast.error('Error upload file');
                    });
                  }}
                  styleType="label"
                  name=""
                  inputId={`consumer-upload-manage-partner-input-${id}`}
                  disabled={!isCheck}
                  accept=".png,.jpeg,.jpg"
                  acceptValidator={fileExtensionValidator(
                    ['png', 'jpeg', 'jpg'],
                    'Only the following image files are allowed: .png, .jpg, .jpeg',
                  )}
                  icon={partnerLogo ? pictureCircleSVG : pictureSVG}
                  iconCss={{
                    width: '30px',
                    height: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
                <Icon
                  style={{ visibility: !previewUrl && 'hidden' }}
                  src={eyeLight}
                  onClick={() => {
                    window.open(previewUrl);
                  }}
                />
                <Icon
                  src={selected === 1 && previewUrl ? linkSvg : linkDisable}
                  $noPointer={!(selected === 1 && previewUrl)}
                  onClick={() => {
                    if (selected === 1 && previewUrl) {
                      setViewToken(previewUrl);
                      showTokenModal();
                    }
                  }}
                />
              </>
            )}
          </IconsWrapper>
        </TableRowCell>
      </TableRow>
    );
  };

  return (
    <Root>
      <TotalRow
        title={`${rows?.length || 0} partnership${rows?.length || 0 > 1 ? 's' : ''}`}
        maxWidth="unset"
      ></TotalRow>
      <SelectWrapper>
        <SelectAll>
          <Checkbox
            check={isSelectAll}
            handleClick={() => {
              setIsSelectAll(!isSelectAll);
              isSelectAll
                ? onPartnerSelect([])
                : onPartnerSelect(rows.map(item => item.id));
            }}
          />
          Select All
        </SelectAll>
      </SelectWrapper>
      <Body>
        <TableWrapper>
          <Table>
            <TableHead>
              <TableHeadRow>
                <TableHeadCell />
                {headCells.map((item, index) => (
                  <TableHeadCell key={index}>{item}</TableHeadCell>
                ))}
                <TableHeadCell />
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {rows.map(item => (
                <TableRowWrapper
                  key={item.id}
                  row={item}
                  selectedPartnerIds={selectedPartnerIds}
                  handleRowSelect={handleRowSelect}
                />
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </Body>
    </Root>
  );
};

const Root = styled.div``;

const Body = styled.div`
  overflow: auto;
`;

const Checkbox = styled(UICheckbox)`
  width: 20px;
  height: 20px;
  margin-right: 16px;
`;

const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const SelectAll = styled.div`
  display: flex;
  user-select: none;
`;

const TableWrapper = styled.div``;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;
const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);
  background: #f4f4f4;
  position: sticky;
  top: 0;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableRow = styled.tr`
  height: 100%;
`;

const TableRowCell = styled.td<{ isAddWeight?: boolean; isNoAddBorder?: boolean }>`
  padding: 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }

  ${({ isAddWeight }) => isAddWeight && 'font-weight: 500;'}
  ${({ isNoAddBorder }) => isNoAddBorder && 'border-bottom: none;'}
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const Icon = styled(ReactSVG)<{ $noPointer: boolean }>`
  ${({ $noPointer }) => !$noPointer && 'cursor: pointer'};
`;

export default PartnersListInfo;
