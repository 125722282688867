import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import React from 'react';
import styled from 'styled-components';
import { CampaignData } from 'interfaces/campaign';
import { UIButton, UIModal } from 'components/UI';
import { Form } from 'react-final-form';
import { Field } from 'components/FinalForm';
import {
  FieldDatePicker,
  FieldTextInput,
  MaskedMoneyTextInput,
} from 'components/FormFields';
import { composeValidators, getDateCutOffTime, setDateGreaterLessDay } from 'utils';
import { FormRow } from 'components/UI/form';
import {
  maxLength250,
  maxLength50,
  required,
  specialCharacters,
  integerValidator,
} from 'utils/validation';
import UIAsterisk from 'components/UI/UIAsterisk';

interface Props extends InjectedProps {
  campaign?: CampaignData;
  title?: string;
  handleAccess: (values: Required<CampaignData>) => void;
}

const EditCampaignModal = ({
  campaign,
  onClose,
  handleAccess,
  title = 'Edit Campaign',
  isloading,
  ...modalProps
}: Props) => {
  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 0' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '836px',
        overflow: 'unset',
      }}
    >
      <Title>{title}</Title>
      <Form
        initialValues={campaign}
        onSubmit={values => handleAccess(values as Required<CampaignData>)}
        render={({ handleSubmit, values }) => (
          <FormBody>
            <Field
              name="campaignName"
              label={
                <>
                  Campaign Name <UIAsterisk />
                </>
              }
              component={FieldTextInput}
              validate={composeValidators<string>(
                required,
                maxLength50,
                specialCharacters,
              )}
            />
            <Field
              name="campaignDescription"
              label="Campaign Description"
              component={FieldTextInput}
              validate={maxLength250}
            />
            <FormRow>
              <Field
                name="startDate"
                label="Start Date"
                parse={v => getDateCutOffTime(v)}
                format={v => getDateCutOffTime(v)}
                dateFormat="MMM/dd/yyyy"
                component={FieldDatePicker}
                popperPlacement="top"
                maxDate={setDateGreaterLessDay(values.endDate, 'less')}
              />
              <Field
                name="endDate"
                label="End Date"
                parse={v => getDateCutOffTime(v)}
                format={v => getDateCutOffTime(v)}
                dateFormat="MMM/dd/yyyy"
                component={FieldDatePicker}
                popperPlacement="top"
                minDate={setDateGreaterLessDay(values.startDate, 'greater')}
              />
            </FormRow>
            <DollarInputWrapper>
              <Field
                name="fundraisingGoal"
                label="Fundraising Goal"
                component={MaskedMoneyTextInput}
                hidePrefix
                validate={integerValidator}
              />
              <DollarSubfix>$</DollarSubfix>
            </DollarInputWrapper>

            <BtnGroup>
              <Btn modifiers="secondary" title="Cancel" handleClick={onClose} />
              <Btn
                modifiers="primary"
                title="Save"
                isLoading={isloading}
                handleClick={handleSubmit}
              />
            </BtnGroup>
          </FormBody>
        )}
      />
    </UIModal>
  );
};

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Btn = styled(UIButton)`
  display: block;
  width: 87px;
  font-size: 14px;
  padding: 7px 0;
`;

const FormBody = styled.div`
  margin: 0 32px;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 46px;
  text-align: center;
`;

const DollarInputWrapper = styled.div`
  position: relative;
`;
const DollarSubfix = styled.div`
  position: absolute;
  right: 12px;
  top: 34px;
`;

export default EditCampaignModal;
