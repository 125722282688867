import {
  CampaignCopyReq,
  CampaignCreateReq,
  CampaignData,
  CampaignListReq,
  ReportListReq,
  CampaignReportListReq,
  CampaignSavePartnersList,
  CampaignShareCodeReq,
  CamParListReq,
  EinListRes,
  EinListRes1,
  SaveParList,
  CampaignDataTS,
  CampaignShareQrCodeReq,
} from 'interfaces/campaign';
import { CompanyCreateReq } from 'interfaces/companies';
import { Dispatch } from 'redux';
import Toast from 'services/Toast';
import { fetchErrorHandler } from 'utils/errorHandlers';
import * as actions from './actions';
import * as api from './api';

export const fetchCampaignList = (param: CampaignListReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchCampaignList(param)
    .then(value => dispatch(actions.setCampaignList(value)))
    .catch(() => Toast.error('Error get Campaign list'));
};

export const fetchCampaignCreate = (params: CampaignCreateReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchCampaignCreate({ ...params })
    .then(campaign => dispatch(actions.createCampaign(campaign)))
    .catch(err => {
      return err.response.data.statusCode === 400
        ? Toast.error(err.response.data.message)
        : Toast.error('Enter all fields before the form can be saved');
    });
};

export const fetchCampaignUpdate = (
  campaign: CampaignData,
  hideToast?: boolean,
  toastSuccessText?: string,
) => async (dispatch: Dispatch) => {
  return api
    .fetchCampaignUpdate(campaign)
    .then(res => {
      if ('msg' in res) {
        Toast.error(res.msg);
        return;
      }
      dispatch(actions.setCampaign(campaign));
      !hideToast && Toast.success(toastSuccessText || 'Campaign saved successfully');
      return campaign;
    })
    .catch(err => {
      return err.response.data.statusCode === 400
        ? Toast.error(err.response.data.message)
        : Toast.error('Enter all fields before the form can be saved');
    });
};

export const fetchCampaignUpdateTS = (
  campaign: CampaignDataTS,
  hideToast?: boolean,
  toastSuccessText?: string,
) => async (dispatch: Dispatch) => {
  return api
    .fetchCampaignUpdateTS(campaign)
    .then(res => {
      if ('msg' in res) {
        Toast.error(res.msg);
        return;
      }
      dispatch(actions.setCampaign(campaign));
      !hideToast && Toast.success(toastSuccessText || 'Campaign saved successfully');
      return campaign;
    })
    .catch(err => {
      return err.response.data.statusCode === 400
        ? Toast.error(err.response.data.message)
        : Toast.error('Enter all fields before the form can be saved');
    });
};

export const fetchCampaignCopy = (params: CampaignCopyReq) => async () => {
  return api.fetchCampaignCopy(params).catch(fetchErrorHandler);
};

export const fetchCampaignDelete = (ids: string[]) => async (dispatch: Dispatch) => {
  return api
    .fetchMultipleDelete(ids.join())
    .then(response => {
      dispatch(actions.deleteCampaign(ids));
      return response;
    })
    .catch(() => {
      Toast.error('Error delete Campaign');
    });
};

export const isAssociationCamp = (ids: string[]) => async () => {
  return api
    .isAssociationCamp(ids.join())
    .then(response => {
      return response;
    })
    .catch(() => Toast.error('Error delete Campaign'));
};

export const fetchCampaign = (id: string) => async (dispatch: Dispatch) => {
  return api.fetchCampaign(id).then(campaign => dispatch(actions.setCampaign(campaign)));
};

export const fetchCampaignPreviewUrl = (id: string) => async (dispatch: Dispatch) => {
  return api
    .getCampaignPreviewUrl(id)
    .then(res => {
      if (res.status !== 200) return Toast.error(res.msg);

      dispatch(actions.setCampaignPreviewUrl(res.data));
      return res.data;
    })
    .catch(fetchErrorHandler);
};

export const saveConfigDonation = (parmas: any) => async (dispatch: Dispatch) => {
  return api
    .saveConfigDonation(parmas)
    .then(response => {
      dispatch(actions.saveConfigDonat(response));
      return response;
    })
    .catch(err => {
      return err.response.data.statusCode === 400
        ? Toast.error(err.response.data.message)
        : Toast.error('Enter all fields before the form can be saved');
    });
};

export const fetchGiveShopInfo = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchGiveShopInfo(id)
    .then(response => {
      dispatch(actions.getDonationDetails(response));
      return response;
    })
    .catch(fetchErrorHandler);
};

export const fetchPreviewInfo = param => async () => {
  return api
    .fetchPreviewInfo(param)
    .then(response => {
      return response;
    })
    .catch(fetchErrorHandler);
};

export const fetchPreviewPage = (id: string) => async () => {
  return api
    .fetchPreviewPage(id)
    .then((response: any) => {
      if (response?.status === 200) window.open(response.data);
    })
    .catch(err => {
      return Toast.error(err?.response?.msg);
    });
};

export const fetchCamPartnersList = (param: CamParListReq) => async () => {
  return api
    .fetchCamPartnersList(param)
    .then(value => {
      return value;
    })
    .catch(() => {
      Toast.error('Error get Partners list');
    });
};

export const updateCampPartners = (param: CampaignSavePartnersList) => async () => {
  return api
    .updateCampPartners(param)
    .then(value => {
      return value;
    })
    .catch(() => Toast.error('Error update select partners '));
};

export const fetchAtWorkConfig = id => async () => {
  return api
    .fetchAtWorkConfig(id)
    .then(response => {
      return response;
    })
    .catch(() => Toast.error('Error get config info'));
};

export const saveATWorkConfig = param => async () => {
  return api.saveATWorkConfig(param).then(response => {
    return response;
  });
};

export const fetchAtworkPartnersList = (param: CamParListReq) => async () => {
  return api
    .fetchAtworkPartnersList(param)
    .then(response => {
      return response;
    })
    .catch(() => Toast.error('Error get partners list'));
};

export const updateAtworkPartners = (param: SaveParList) => async () => {
  return api
    .updateAtworkPartners(param)
    .then(value => {
      return value;
    })
    .catch(fetchErrorHandler);
};

export const isAssociationParn = (ids: string[]) => async () => {
  return api
    .isAssociationParn(ids.join())
    .then(response => {
      return response;
    })
    .catch(() => Toast.error('Get error'));
};

export const fetchGiveROIAddCompany = (param: CompanyCreateReq) => async () => {
  return api
    .fetchGiveROIAddCompany(param)
    .then(data => {
      if (!data.exist) Toast.success('Company successfully created');
      return data;
    })
    .catch(err => {
      Toast.error('Error create company');
      throw err;
    });
};

export const fetchGiveROIAddNew = (param: CompanyCreateReq) => async () => {
  return api
    .fetchGiveROIAddNew(param)
    .then(data => {
      if (!data.exist) Toast.success('Company successfully created');
      return data;
    })
    .catch(err => {
      Toast.error('Error create company');
      throw err;
    });
};

export const fetchEinList = (name: string) => async (dispatch: Dispatch) => {
  return api
    .fetchEinList(name)
    .then((data: EinListRes) => {
      dispatch(actions.setEinSuggest(data));
      return data;
    })
    .catch(err => {
      Toast.error('Error get list');
      throw err;
    });
};

export const fetchEinDetails = (id: string) => async () => {
  return api
    .fetchEinDetails(id)
    .then((data: EinListRes1) => {
      return data;
    })
    .catch(err => {
      Toast.error('Error get list');
      throw err;
    });
};

export const fetchEinInfo = () => async () => {
  return api
    .fetchEinInfo()
    .then(data => {
      return data;
    })
    .catch(err => {
      Toast.error('Error get list');
      throw err;
    });
};

export const affirmEinInfo = () => async () => {
  return api
    .affirmEinInfo()
    .then(data => {
      return data;
    })
    .catch(err => {
      Toast.error('Error get list');
      throw err;
    });
};

export const fetchChangePpgf = (params: {
  paypalGivingFundId: string;
  teamId: string;
}) => async () => {
  return api.fetchChangePpgf(params).catch(fetchErrorHandler);
};

export const fetchAtworkPartnerPreviewUrl = (campaignId: string) => async (
  dispatch: Dispatch,
) => {
  return api.fetchAtworkPartnerPreviewUrl(campaignId).then(res => {
    if (res.status !== 200) return Toast.error(res.msg);
    dispatch(actions.setAtworkPartnerPreviewUrl(res.data));
  });
};

export const fetchCurrentAllCampaigns = () => async (dispatch: Dispatch) => {
  return api
    .fetchCurrentAllCampaigns()
    .then(campaigns => {
      dispatch(actions.setCurrentAllCampaigns(campaigns));
    })
    .catch(fetchErrorHandler);
};

export const fetchPartnerByCampaign = (campaignId: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchPartnerByCampaign(campaignId)
    .then(partners => {
      dispatch(actions.setPartnersByCampaign(partners));
    })
    .catch(fetchErrorHandler);
};

export const fetchCampaignReportList = (params: CampaignReportListReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchCampaignReportList(params)
    .then(reports => {
      dispatch(actions.setCampaignReports(reports));
      if (!reports.length) Toast.info('No eligible records are found.');
    })
    .catch(fetchErrorHandler);
};

export const fetchReportList = (param: ReportListReq) => async (dispatch: Dispatch) => {
  return api
    .fetchReportList(param)
    .then(value => {
      dispatch(actions.setReportList(value));
      if (!value?.items) {
        Toast.info('No eligible records are found.');
      }
    })
    .catch(fetchErrorHandler);
};

export const fetchCampaignQRCode = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchCampaignQRCode(id)
    .then(base64Str => {
      dispatch(actions.setCampaignQRCode(base64Str));
    })
    .catch(fetchErrorHandler);
};

//used for Teamsnap flow
export const fetchCampaignByIdQRCode = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchCampaignByIdQRCode(id)
    .then(base64Str => {
      dispatch(actions.setCampaignQRCode(base64Str));
    })
    .catch(fetchErrorHandler);
};

export const fetchCampaignShareCode = (params: CampaignShareCodeReq) => async () => {
  return api
    .fetchCampaignShareCode(params)
    .then(() => {
      Toast.success('The QR code has been shared succesfully');
    })
    .catch(fetchErrorHandler);
};

export const fetchCampaignShareQrCode = (params: CampaignShareQrCodeReq) => async () => {
  return api
    .fetchCampaignShareQrCode(params)
    .then(() => {
      Toast.success('The QR code has been shared succesfully');
    })
    .catch(fetchErrorHandler);
};

export const clearCampaignQRCode = () => async (dispatch: Dispatch) => {
  dispatch(actions.clearCampaignQRCode());
};

export const clearFundraisingReport = () => async (dispatch: Dispatch) => {
  dispatch(actions.clearFundraisingReport());
};

export const fetchTotalRaisedById = (giveworxCampaignId: string) => async () => {
  return api
    .fetchTotalRaisedById(giveworxCampaignId)
    .then(data => {
      return data;
    })
    .catch(() => Toast.info('Unable to get total raised.'));
};
