import { goalAlignmentOptions } from 'constants/goalAlignment';
import { ROIData } from 'interfaces/roi';
import React from 'react';
import styled from 'styled-components';
import { MetricContent, MetricRoot, MetricTitle } from './styleds';

interface Props {
  sdgGoals: ROIData['sdgGoals'];
}

const SDGAlignmentMetric = ({ sdgGoals }: Props) => {
  return (
    <Root>
      <MetricTitle>SDG Alignment</MetricTitle>
      <MetricContent>
        {sdgGoals && sdgGoals.length > 0 && (
          <AlignmentList>
            {[...sdgGoals]
              .map(item => goalAlignmentOptions[item])
              .filter(item => item)
              .sort((a, b) => a.id - b.id)
              .map(item => (
                <AlignmentItem key={item.id} src={item.img} alt={item.title} />
              ))}
          </AlignmentList>
        )}
      </MetricContent>
    </Root>
  );
};

const Root = styled(MetricRoot)`
  width: unset;
  height: unset;
`;

const AlignmentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const AlignmentItem = styled.img`
  width: 112px;
  height: 112px;
`;

export default SDGAlignmentMetric;
