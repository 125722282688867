import React from 'react';
import styled from 'styled-components';
import List from 'components/List';
import CardFavoritesSmall from 'components/CardFavoritesSmall';
import { SearchCompany } from 'interfaces/companies';
import EmptyFavorites from 'components/EmptyFavorites';

export interface FavoritesProps {
  companies: SearchCompany[];
}

const FavoritesDashboard = ({ companies }: FavoritesProps) => {
  return (
    <>
      {companies.length > 0 ? (
        <List<SearchCompany, object>
          list={companies.slice(0, 6)}
          component={CardFavoritesSmall}
        />
      ) : (
        <Empty />
      )}
    </>
  );
};

const Empty = styled(EmptyFavorites)`
  height: calc(100% - 50px);
`;

export default FavoritesDashboard;
