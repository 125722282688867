import { UICheckbox } from 'components/UI';
import { useBrandKits } from 'hooks/common';
import React, { ReactNode } from 'react';
import { FieldInputProps } from 'react-final-form';
import styled from 'styled-components';

interface Props {
  metricId: string;
  input: FieldInputProps<string[], HTMLElement>;
  children?: ReactNode;
  fillWidth?: boolean;
}

const CheckBoxMetric = ({ children, metricId, input, fillWidth }: Props) => {
  const { themeFont } = useBrandKits();

  return (
    <Root $fillWidth={fillWidth}>
      <UICheckbox
        check={input.value.includes(metricId)}
        handleClick={() => {
          if (input.value.includes(metricId)) {
            input.onChange(input.value.filter(value => value !== metricId));
            return;
          }
          input.onChange(input.value ? [...input.value, metricId] : [metricId]);
        }}
      />
      <Metirc
        id={metricId}
        $fillWidth={fillWidth}
        style={{ fontFamily: themeFont || 'inherit' }}
      >
        {children}
      </Metirc>
    </Root>
  );
};

const Root = styled.div<{ $fillWidth?: boolean }>`
  display: flex;
  align-items: self-start;
  gap: 12px;

  ${({ $fillWidth }) => $fillWidth && `width: 100%;`}
`;

const Metirc = styled.div<{ $fillWidth?: boolean }>`
  ${({ $fillWidth }) => $fillWidth && `width: 100%;`}
`;

export default CheckBoxMetric;
