import { TeamStoreData } from 'interfaces/team';
import { ActionType, createReducer } from 'typesafe-actions';

import { userTypes, userActions } from '../user';
import * as types from './types';
import * as actions from './actions';

type RootAction = ActionType<typeof actions | typeof userActions>;

const initialState: TeamStoreData = {
  team: undefined,
  teamMembers: [],
  emailTemplates: [],
  subscription: {
    name: '',
  },
  lastLogins: [],
  sendPitch: {
    items: [],
    meta: {
      currentPage: '',
      itemCount: 0,
      itemsPerPage: '',
      totalItems: 0,
      totalPages: 0,
    },
  },
  teams: {
    items: [],
    meta: {
      currentPage: '',
      itemCount: 0,
      itemsPerPage: '',
      totalItems: 0,
      totalPages: 0,
    },
  },
  news: [],
  emailDeisgn: {
    attribute: {},
    template: '',
  },
  teamsSuggest: [],
  payPalGivingFundCanUpdate: false,
  payPalGivingFundIfConfirm: false,
  impactGenome: [],
  noContact: [],
};

export default createReducer<TeamStoreData, RootAction>(initialState)
  .handleType(types.SET_TEAM, (state, action) => ({
    ...state,
    team: action.payload,
  }))
  .handleType(types.SET_LAST_LOGINS, (state, action) => ({
    ...state,
    lastLogins: action.payload,
  }))
  .handleType(types.GET_TEAM_MEMBERS, (state, action) => {
    return {
      ...state,
      teamMembers: action.payload,
    };
  })
  .handleType(types.GET_EMAIL_TEMPLATES, (state, action) => {
    return {
      ...state,
      emailTemplates: action.payload,
    };
  })
  .handleType(types.GET_NO_CONTACT, (state, action) => {
    return {
      ...state,
      noContact: action.payload,
    };
  })
  .handleType([types.DELETE_TEAM_MEMBERS], (state, action) => {
    return {
      ...state,
      teamMembers: state.teamMembers.filter(item => item.id !== action.payload),
    };
  })
  .handleType([userTypes.LOG_OUT], () => initialState)
  .handleType([types.SET_SUBSCRIPTION], (state, action) => ({
    ...state,
    subscription: action.payload,
  }))
  .handleType([types.SET_SENT_PITCH], (state, action) => ({
    ...state,
    sendPitch: action.payload,
  }))
  .handleType([types.SET_TEAMS], (state, action) => ({
    ...state,
    teams: action.payload,
  }))
  .handleType([types.SET_TEAM_NEWS], (state, action) => ({
    ...state,
    news: action.payload,
  }))
  .handleType([types.SET_EMAIL_DESIGN], (state, action) => ({
    ...state,
    emailDeisgn: action.payload,
  }))
  .handleType([types.CLEAR_EMAIL_DESIGN], state => ({
    ...state,
    emailDeisgn: initialState.emailDeisgn,
  }))
  .handleType([types.SET_TEAMS_SUGGEST], (state, action) => ({
    ...state,
    teamsSuggest: action.payload,
  }))
  .handleType([types.SET_TEAM_PAYPALGIVINGFUND_CANUPDATE], (state, action) => ({
    ...state,
    payPalGivingFundCanUpdate: action.payload,
  }))
  .handleType([types.SET_TEAM_PAYPALGIVINGFUND_IFCONFIRM], (state, action) => ({
    ...state,
    payPalGivingFundIfConfirm: action.payload,
  }))
  .handleType([types.SET_IMPACT_GENOME], (state, action) => ({
    ...state,
    impactGenome: action.payload,
  }))
  .handleType([types.SET_NO_CONTACT], (state, action) => ({
    ...state,
    noContact: action.payload,
  }));
