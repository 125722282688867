import React from 'react';
import { Location } from 'history';
import styled from 'styled-components';
import { WrapperProps } from 'interfaces/common';
import HeaderCompany from 'components/HeaderCompany';

interface Props {
  location: Location;
}

function NoSubscriptionsCompanyWrapper({ children, location }: Props & WrapperProps) {
  return (
    <Root>
      <HeaderCompany location={location} />
      <Body>{children}</Body>
    </Root>
  );
}
const Root = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1200px;
`;

const Body = styled.div`
  background: #e8e8e8;
  padding: 20px;
  height: calc(100% - 80px);
`;

export default NoSubscriptionsCompanyWrapper;
