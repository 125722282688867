import React from 'react';
import styled from 'styled-components';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { FieldRenderProps, Form } from 'react-final-form';
import { Field } from 'components/FinalForm';
import { FieldTextInput } from 'components/FormFields';
import { fileExtensionValidator, required } from 'utils/validation';
import UploadFileArea from 'components/UploadFileArea';
import { fetchDownloadAudienceTemplate } from 'store/ducks/audience/api';
import { uploadFile } from 'store/ducks/utility/api';
import Toast from 'services/Toast';
import { AudienceAnalysisCreateReq } from 'interfaces/audience';
import { getFilenameByPath } from 'utils/formatters';
import { FormError } from 'components/UI/form';
import DownloadTemplate from 'components/DownloadTemplate';
import UIAsterisk from 'components/UI/UIAsterisk';

interface Props extends InjectedProps {
  handleAccess: (param: AudienceAnalysisCreateReq) => void;
}

const AudienceAnalysisRequestModal = ({
  onClose,
  handleAccess,
  ...modalProps
}: Props) => {
  useLockBodyScroll();

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '40px 0' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '828px',
      }}
    >
      <Title>Persona Analysis Request</Title>
      <Form<AudienceAnalysisCreateReq>
        onSubmit={values => {
          handleAccess(values);
        }}
        render={({ handleSubmit, values }) => (
          <FormBody>
            <Field
              label={
                <>
                  Persona Analysis Name <UIAsterisk />
                </>
              }
              name="name"
              component={FieldTextInput}
              validate={required}
            />
            <Field name="userAttachment" label="Upload employee List" validate={required}>
              {({ input, meta }: FieldRenderProps<string>) => {
                return (
                  <>
                    <UploadFileArea
                      name="Upload Excel List"
                      accept=".xls"
                      inputId="audience-analysis-request-form-upload"
                      desc="Please upload a single XLS file containing a list of email addresses. File size limit is 5 Mb."
                      acceptValidator={fileExtensionValidator(
                        ['xls'],
                        'Must be xls files',
                      )}
                      filelist={
                        values.userAttachment
                          ? [
                              {
                                label: getFilenameByPath(values.userAttachment),
                                value: values.userAttachment,
                              },
                            ]
                          : undefined
                      }
                      onUpload={files => {
                        if (!files.length) return;
                        uploadFile(files[0], 'audience-request').then(v => {
                          if (v.status === 'success') {
                            input.onChange(v.fileKey);
                            return Toast.success('File uploaded successfully');
                          }
                          Toast.error('Error upload file');
                        });
                      }}
                      onDeleteFile={() => {
                        input.onChange(undefined);
                      }}
                    />
                    {meta.touched && <Error meta={meta} />}
                    <DownloadTemplate fetchFile={fetchDownloadAudienceTemplate} />
                  </>
                );
              }}
            </Field>

            <BtnGroup>
              <Btn modifiers="secondary" title="Cancel" handleClick={onClose} />
              <Btn modifiers="primary" title="Submit" handleClick={handleSubmit} />
            </BtnGroup>
          </FormBody>
        )}
      />
    </UIModal>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 34px;
  text-align: center;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 56px;
`;

const Btn = styled(UIButton)`
  display: block;
  width: 87px;
  font-size: 14px;
  padding: 7px 0;
`;

const FormBody = styled.div`
  margin: 0 92px;
`;

const Error = styled(FormError)`
  position: relative;
  top: -20px;
`;

export default AudienceAnalysisRequestModal;
