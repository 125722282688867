import React, { FC, ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import Toast from 'services/Toast';
import { ReactSVG } from 'react-svg';

import { UISpinner } from 'components/UI';
import UserIcon from 'assets/images/icons/user.svg';
import { lessThan5MB } from 'utils/validation';
import { useFileUrl } from 'hooks/common';

interface Props {
  handleUpload: (file: File) => Promise<string | void | number>;
  imgKey?: string;
}

interface SignedUrlResponse {
  result: string;
}

const UploadProfilePhoto: FC<Props> = ({ handleUpload, imgKey, ...rest }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { fileUrl, setFileUrl } = useFileUrl({ imgKey });

  const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target && event?.target?.files) {
      const file = event.target.files[0];
      const sizeError = lessThan5MB(file.size);
      setLoading(true);

      if (sizeError) {
        setLoading(false);
        return Toast.error(sizeError);
      }

      handleUpload(file)
        .then(() => {
          setFileUrl(URL.createObjectURL(file));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          Toast.error('Error');
        });
    }
  };

  return (
    <Root>
      {loading ? (
        <Avatar>
          <UISpinner width={30} height={30} />
        </Avatar>
      ) : fileUrl ? (
        <AvatarImg
          style={{
            backgroundImage: `url(${fileUrl})`,
          }}
        />
      ) : (
        <Avatar>
          <EmptyAvatar src={UserIcon} />
        </Avatar>
      )}

      <div>
        <ValidateFile>Photo should be no more than 5 mb</ValidateFile>

        <div>
          <Input
            id="upload-photo-file"
            onChange={onUpload}
            type="file"
            accept="image/*"
            {...rest}
          />
          <label htmlFor="upload-photo-file">
            <Btn>Upload Photo</Btn>
          </label>
        </div>
      </div>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.div`
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
`;

const AvatarImg = styled(Avatar)`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const EmptyAvatar = styled(ReactSVG)`
  & svg {
    width: 48px;
    height: 48px;
    & path {
      fill: var(--darkGray);
    }
  }
`;

const ValidateFile = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
  margin-bottom: 8px;
`;

const Btn = styled.div`
  font-size: 12px;
  line-height: 150%;
  width: 150px;
  padding: 8px 0;
  color: var(--black);
  background: #caf0ff;
  border-radius: 6px;
  text-align: center;
  &:hover {
    cursor: pointer;
    background: #daf5ff;
  }

  &:active {
    background: #b6ebff;
  }
`;

const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  display: inline-block;
`;

export default UploadProfilePhoto;
