import React from 'react';
import styled from 'styled-components';
import EmptySubscription from 'components/EmptySubscription';

const NoSubscription = () => {
  return <Empty />;
};

const Empty = styled(EmptySubscription)`
  height: 100%;
`;

export default NoSubscription;
