/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { UIButton, UIModal } from 'components/UI';
import { UIModalProps as InjectedProps } from 'components/UI/interfaces';
import useLockBodyScroll from 'hooks/common/useLockBodyScroll';
import { FilterData } from 'components/Forms/interfaces';
import { Form } from 'react-final-form';
import FilterFormSection from 'components/Forms/FilterForm/FilterFormSection';
import styled from 'styled-components';

interface Props extends InjectedProps {
  filterSubmitHandler: (values: FilterData) => void;
  initialValues: FilterData | undefined;
}

const FiltersEditModal = ({
  onClose,
  filterSubmitHandler,
  initialValues,
  ...modalProps
}: Props) => {
  useLockBodyScroll();

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '24px' }}
      hideCloseIcon
      style={{
        width: '100%',
        maxWidth: '1095px',
        height: '80vh',
      }}
    >
      <Form<FilterData>
        initialValues={initialValues}
        onSubmit={filterSubmitHandler}
        render={({ handleSubmit }) => {
          return (
            <>
              <FilterFormSection />
              <ButtonWrapper>
                <Button modifiers="secondary" title="Cancel" handleClick={onClose} />
                <Button
                  modifiers="primary"
                  title="Save & Refresh"
                  handleClick={handleSubmit}
                />
              </ButtonWrapper>
            </>
          );
        }}
      />
    </UIModal>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled(UIButton)`
  padding: 12px 33px;
`;

export default FiltersEditModal;
