import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { CropText, UICheckbox, UILink } from 'components/UI';
import { SearchCompaniesContact } from 'interfaces/companies';
import LinkedInIcon from 'assets/images/icons/linkedIn.svg';
import { parseLink } from 'utils';
import { OnCheckParams } from 'components/UI/interfaces';
import SearchContactPitchButton from 'components/ActionButtons/SearchContactPitchButton';
import { Link } from 'react-router-dom';
import { TableSortHeaderCell } from 'components/UI/table';
import { SortStatus } from 'hooks/common/useSortStatus';

interface Props {
  rows: SearchCompaniesContact[];
  selectedAll: boolean;
  selectedContactsIds: string[];
  unselectedContactsIds: string[];
  onCheckRow: ({ check, id }: OnCheckParams) => void;
  onCheckAll: (bool: boolean) => void;
  sortStatus: SortStatus;
  onSort: (colName: string) => void;
}

interface TableRowProps {
  row: SearchCompaniesContact;
  check: boolean;
  disabledPitch: boolean;
  onCheckRow: ({ check, id }: OnCheckParams) => void;
}

const userTableWidth = {
  nameWidth: 165,
  linkedinWidth: 88,
  companyWidth: 100,
  jobtitleWidth: 260,
  phoneWidth: 150,
  emailWidth: 150,
  scoreWidth: 120,
  actionsWidth: 151,
} as const;

const {
  nameWidth,
  linkedinWidth,
  jobtitleWidth,
  phoneWidth,
  emailWidth,
  scoreWidth,
  actionsWidth,
  companyWidth,
} = userTableWidth;

const TableRowWrapper = ({ row, check, onCheckRow, disabledPitch }: TableRowProps) => {
  const {
    contact_firstName,
    contact_lastName,
    contact_jobTitle,
    contact_linkedinUrl,
    contact_phone,
    contact_email,
    contact_contactAccuracyScore,
    contact_id,
    company_name,
    company_id,
    contact_jobFunction,
    contact_managementLevel,
  } = row;

  return (
    <TableRow>
      <TableRowCell style={{ width: `${70}px` }}>
        {row.usedIn60Days === '0' ? (
          <UICheckbox
            check={check}
            handleClick={() => onCheckRow({ check: !check, id: contact_id })}
          />
        ) : null}
      </TableRowCell>
      <TableRowCell isNoAddBorder isAddWeight>
        {contact_firstName}
      </TableRowCell>
      <TableRowCell>{contact_lastName}</TableRowCell>
      <TableRowCell>
        <CompanyLink as={Link} to={`/company/${company_id}`}>
          {company_name}
        </CompanyLink>
      </TableRowCell>
      <TableRowCell>{contact_jobTitle}</TableRowCell>
      <TableRowCell>{contact_jobFunction}</TableRowCell>
      <TableRowCell>{contact_managementLevel}</TableRowCell>
      <TableRowCell>
        {contact_linkedinUrl ? (
          <SocialLink href={parseLink(contact_linkedinUrl)} target="_blank">
            <ReactSVG src={LinkedInIcon} />
          </SocialLink>
        ) : (
          <SocialLinkEmpty>-</SocialLinkEmpty>
        )}
      </TableRowCell>
      <TableRowCell>
        {contact_phone ? (
          <ContactLink href={`tel:${contact_phone}`} $width={phoneWidth}>
            {contact_phone}
          </ContactLink>
        ) : (
          <CropText width={phoneWidth} text="No phone" />
        )}
      </TableRowCell>
      <TableRowCell>
        {contact_email ? (
          <ContactLink href={`mailto:${contact_email}`} $width={phoneWidth}>
            {contact_email}
          </ContactLink>
        ) : (
          <CropText width={emailWidth} text="No email" />
        )}
      </TableRowCell>

      <TableRowCell>{contact_contactAccuracyScore}</TableRowCell>

      <TableRowCell>
        <SearchContactPitchButton contact={row} disabled={disabledPitch} />
      </TableRowCell>
    </TableRow>
  );
};

const ContactsSelectedTable = ({
  rows,
  selectedAll,
  onCheckAll,
  selectedContactsIds,
  unselectedContactsIds,
  onCheckRow,
  onSort,
  sortStatus,
}: Props) => {
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col style={{ width: `${70}px` }} />
          <col style={{ width: `${nameWidth}px` }} />
          <col style={{ width: `${nameWidth}px` }} />
          <col style={{ width: `${companyWidth}px` }} />
          <col style={{ width: `${jobtitleWidth}px` }} />
          <col style={{ width: `${jobtitleWidth}px` }} />
          <col style={{ width: `${jobtitleWidth}px` }} />
          <col style={{ width: `${linkedinWidth}px` }} />
          <col style={{ width: `${phoneWidth}px` }} />
          <col style={{ width: `${emailWidth}px` }} />
          <col style={{ width: `${scoreWidth}px` }} />
          <col style={{ width: `${actionsWidth}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell key="checkbox" style={{ width: `${70}px` }}>
              <UICheckbox
                check={selectedAll}
                handleClick={() => onCheckAll(!selectedAll)}
              />
            </TableHeadCell>
            <TableSortHeaderCell
              colName="firstName"
              onClick={onSort}
              sortStatus={sortStatus}
            >
              First Name
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="lastName"
              onClick={onSort}
              sortStatus={sortStatus}
            >
              Last Name
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="companyName"
              onClick={onSort}
              sortStatus={sortStatus}
            >
              Company
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="jobTitle"
              onClick={onSort}
              sortStatus={sortStatus}
            >
              Title
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="jobFunction"
              onClick={onSort}
              sortStatus={sortStatus}
            >
              Job Function
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="managementLevel"
              onClick={onSort}
              sortStatus={sortStatus}
            >
              Management Level
            </TableSortHeaderCell>
            <TableHeadCell>LinkedIn</TableHeadCell>
            <TableSortHeaderCell colName="phone" onClick={onSort} sortStatus={sortStatus}>
              Phone
            </TableSortHeaderCell>
            <TableSortHeaderCell colName="email" onClick={onSort} sortStatus={sortStatus}>
              Email
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="contactAccuracyScore"
              onClick={onSort}
              sortStatus={sortStatus}
            >
              Accuracy Score
            </TableSortHeaderCell>
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(item => (
            <TableRowWrapper
              key={item.contact_id}
              row={item}
              check={
                selectedAll ||
                (selectedContactsIds.length
                  ? selectedContactsIds.includes(item.contact_id)
                  : unselectedContactsIds.length
                  ? !unselectedContactsIds.includes(item.contact_id)
                  : false)
              }
              disabledPitch={
                selectedAll ||
                !!selectedContactsIds.length ||
                !!unselectedContactsIds.length
              }
              onCheckRow={onCheckRow}
            />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow: auto;
  margin-bottom: 30px;
`;

const Table = styled.table``;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);
  vertical-align: middle;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-right: 24px;
  }
`;

const TableRow = styled.tr`
  height: 100%;
`;

const TableRowCell = styled.td<{ isAddWeight?: boolean; isNoAddBorder?: boolean }>`
  box-sizing: border-box;
  padding: 20px 6px 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }

  ${({ isAddWeight }) => isAddWeight && 'font-weight: 500;'}
  ${({ isNoAddBorder }) => isNoAddBorder && 'border-bottom: none;'}
`;

export const ContactLink = styled.a<{ $width: number }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

  width: ${({ $width }) => `${$width}px`};

  &:hover {
    text-decoration: underline;
  }
`;

export const SocialLink = styled.a`
  display: block;
  width: 100%;
  & > div {
    svg {
      width: 20px;
      height: 20px;
      margin: 0 auto;
    }
  }
`;

export const SocialLinkEmpty = styled.p`
  margin: none;
  width: 100%;
  text-align: center;
`;

const CompanyLink = styled(UILink)`
  font-weight: unset;
`;

export default ContactsSelectedTable;
