/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getVerifyEmailStatus, selectUser } from 'store/ducks/user/selectors';
import { UIButton } from 'components/UI';
import { checkNoContact, fetchContactPitch } from 'store/ducks/companies/operations';
import { StateProps } from 'store/interfaces';
import { CompanyContact } from 'interfaces/companies';
import { VerifyEmailStatus } from 'interfaces/auth';
import { useModal, useOperation } from 'hooks/common';
import { PitchModal } from 'components/Modals';
import { sendEmail } from 'store/ducks/utility/api';
import { PitchValues } from 'interfaces/common';
import Toast from 'services/Toast';
import CannotPitchModal from 'components/Modals/CannotPitchModal';
import { useHistory } from 'react-router';
import { fetchPitchEmailContent } from 'store/ducks/gameplan/operations';

interface PitchButtonProps {
  contact: CompanyContact;
  disabled?: boolean;
}

const PitchButton = ({ contact, disabled }: PitchButtonProps) => {
  const { push } = useHistory();

  const [pitchEmailContent, setPitchEmailContent] = useState('');

  const [onFetchPitchContact] = useOperation(fetchContactPitch);
  const [onFetchPitchEmailContent] = useOperation(fetchPitchEmailContent);

  const user = useSelector(selectUser);
  const verifyEmailStatus = useSelector<StateProps, VerifyEmailStatus>(
    getVerifyEmailStatus,
  );

  const { firstName, email, usedIn60Days, companyId, id } = contact;

  const [onCheckNoContact] = useOperation(checkNoContact);
  const [checkResult, setCheckResult] = useState(false);

  useEffect(() => {
    if (onCheckNoContact) {
      onCheckNoContact(companyId).then(result => {
        setCheckResult(Boolean(result));
      });
    }
  }, [onCheckNoContact]); //deploy

  const handleSendEmail = (param: PitchValues, testEmail?: string) => {
    sendEmail([
      {
        bcc: param.bcc,
        emailTo: testEmail || email || '',
        subject: param.subject,
        attachments: param.attachments,
        body: param.text,
      },
    ])
      .then(({ status }) => {
        if (status !== 'success') return Toast.success('Fail send email');

        if (!testEmail) onFetchPitchContact([{ companyId, contactId: id }]);

        Toast.success('Email sent successfully');
      })
      .catch(() => Toast.error('Error send email'));
  };

  const [showPitchModal, hidePitchModal] = useModal(
    () => (
      <PitchModal
        pitch={{
          attachments: [],
          recipient: [{ name: firstName, email }],
          sender: { name: user.firstName ?? undefined, email: user.email },
          text: pitchEmailContent,
        }}
        onClose={hidePitchModal}
        onSubmit={values => {
          hidePitchModal();
          handleSendEmail(values);
        }}
        onSendTestEmail={(values, testEmail) => handleSendEmail(values, testEmail)}
      />
    ),
    [pitchEmailContent],
  );

  const [showCannotPitchModal, hideCannotPitchModal] = useModal(() => {
    return (
      <CannotPitchModal
        accessHandler={() => push('/user-profile')}
        cancelHandler={hideCannotPitchModal}
      />
    );
  });

  const handleGetPitchEmailContent = useCallback(() => {
    onFetchPitchEmailContent(contact.id).then(v => {
      if (v) {
        setPitchEmailContent(v);
      }

      setTimeout(() => {
        showPitchModal();
      }, 0);
    });
  }, []);

  return (
    <Btn
      modifiers="third"
      disabled={checkResult || usedIn60Days !== '0' || disabled}
      handleClick={() => {
        verifyEmailStatus ? handleGetPitchEmailContent() : showCannotPitchModal();
      }}
      title={usedIn60Days !== '0' ? 'Active Pitch' : 'Pitch'}
    />
  );
};

const Btn = styled(UIButton)`
  width: 96px;
  height: 28px;
  padding: 0;
`;

export default PitchButton;
