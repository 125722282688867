import React from 'react';
import { UITextInput } from 'components/UI';
import FieldInput from '../FieldInput';
import { FieldRenderProps } from 'react-final-form';

interface FieldTextInputProps extends FieldRenderProps<string> {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FieldTextInput = ({ onChange, ...props }: FieldTextInputProps) => (
  <FieldInput
    {...props}
    renderInput={inputProps => (
      <UITextInput
        {...inputProps}
        onChange={event => {
          onChange && onChange(event); // Call the custom onChange handler
          inputProps.onChange(event); // Call the original onChange handler from react-final-form
        }}
      />
    )}
  />
);

export default FieldTextInput;
