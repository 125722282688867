/* eslint-disable react-hooks/exhaustive-deps */
import { ROIEditMeasureNavigationEnum, TrackingStatusEnum } from 'constants/roi';
import { useModal, useOperation } from 'hooks/common';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  fetchROIGenerateReport,
  fetchROIMeltwaterData,
  fetchROIUpdate,
} from 'store/ducks/roi/operations';
import { getROI, getROIMeltwaterData } from 'store/ducks/roi/selectors';
import styled from 'styled-components';
import { MeasureForm, ImpactReportForm } from './forms';
import queryString from 'query-string';
import { UITipIconText, UIActionText } from 'components/UI';
import InfoBlueSVG from 'assets/images/icons/info-blue.svg';
import { ReactSVG } from 'react-svg';
import LinkSVG from 'assets/images/icons/link.svg';
import { ShareReportModal } from './modals';

const Measure = () => {
  const [navigationType, setNavigationType] = useState<ROIEditMeasureNavigationEnum>(
    ROIEditMeasureNavigationEnum.Tracking,
  );

  const { push } = useHistory();
  const search = queryString.parse(window.location.search);
  const params = useParams<{ roiId: string }>();

  const [onFetchROIUpdate] = useOperation(fetchROIUpdate);
  const [onFetchRoiGenerateReport] = useOperation(fetchROIGenerateReport);
  const [onFetchROIMeltwaterData] = useOperation(fetchROIMeltwaterData);

  const handleChangeNavigationType = (type: ROIEditMeasureNavigationEnum) => {
    setNavigationType(type);
    push(`${window.location.pathname}?type=${search.type}&secondaryType=${type}`);
  };

  const roi = useSelector(getROI);
  const meltwaterData = useSelector(getROIMeltwaterData);

  const [showShareReportModal, hideShareReportModal] = useModal(
    () => (
      <ShareReportModal
        onClose={hideShareReportModal}
        generateUrl={() =>
          `${window.location.host}?redirectTo=/roi/report/${params.roiId}`
        }
      />
    ),
    [params.roiId],
  );

  useEffect(() => {
    if (!search.secondaryType) return;

    setNavigationType(search.secondaryType as ROIEditMeasureNavigationEnum);
  }, [search]);

  useEffect(() => {
    onFetchROIMeltwaterData(params.roiId);
  }, []);

  if (!roi) return null;

  return (
    <Root>
      <Title>
        Measure
        {(search.secondaryType === ROIEditMeasureNavigationEnum.Tracking ||
          !search.secondaryType) &&
          (roi.trackingStatus === TrackingStatusEnum.Complete && !!meltwaterData ? (
            <ActionText onClick={() => push(`/roi/tracking-dashboard/${roi.id}`)}>
              View Tracking
            </ActionText>
          ) : (
            (roi.trackingStatus === TrackingStatusEnum.PendingAdmin ||
              (roi.trackingStatus === TrackingStatusEnum.Complete && !meltwaterData)) && (
              <TipText icon={InfoBlueSVG}>Tracking request is sent</TipText>
            )
          ))}
        {search.secondaryType === ROIEditMeasureNavigationEnum.Impact_Report &&
          roi.calculation && (
            <>
              <ActionText
                onClick={() => {
                  push(`/roi/report/${roi.id}`);
                  window.scrollTo(0, 0);
                }}
              >
                View Valuation Report
              </ActionText>
              <LinkIcon src={LinkSVG} onClick={showShareReportModal} />
            </>
          )}
      </Title>
      <NavigationBar>
        {/* <NavigationItem
          active={navigationType === ROIEditMeasureNavigationEnum.Tracking}
          onClick={() =>
            handleChangeNavigationType(ROIEditMeasureNavigationEnum.Tracking)
          }
        >
          Tracking
        </NavigationItem> */}
        <NavigationItem
          active={navigationType === ROIEditMeasureNavigationEnum.Impact_Report}
          onClick={() =>
            handleChangeNavigationType(ROIEditMeasureNavigationEnum.Impact_Report)
          }
        >
          Valuation Report
        </NavigationItem>
      </NavigationBar>
      {roi && (
        <Panel>
          {navigationType === ROIEditMeasureNavigationEnum.Tracking && (
            <MeasureForm
              roi={roi}
              disabled={false}
              onSave={onFetchROIUpdate}
              onTrack={values =>
                onFetchROIUpdate({
                  ...values,
                  trackingStatus: TrackingStatusEnum.PendingAdmin,
                })
              }
            />
          )}
          {navigationType === ROIEditMeasureNavigationEnum.Impact_Report && (
            <ImpactReportForm
              roi={roi}
              disabled={false}
              onSubmit={values =>
                onFetchROIUpdate(
                  values,
                  undefined,
                  'Valuation Report info saved successfully',
                )
              }
              onGenerate={values => {
                onFetchROIUpdate(values, true).then(() =>
                  onFetchRoiGenerateReport(values.id).then(() =>
                    push(`/roi/report/${roi.id}`),
                  ),
                );
              }}
            />
          )}
        </Panel>
      )}
    </Root>
  );
};

const Root = styled.div`
  background-color: #fff;
  border-radius: 6px;
  margin-top: 24px;
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  padding: 24px;
  display: flex;
`;

const NavigationBar = styled.div`
  width: 100%;
  height: 70px;
  padding: 0 24px 0;
  display: flex;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;

  & > div:not(:first-child) {
    margin-left: 24px;
  }
`;

const NavigationItem = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  border-bottom: 3px solid transparent;
  transition: border-color 0.5s ease, color 0.5s ease;
  user-select: none;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
    color: var(--blue);
    border-color: var(--blue);
  `}

  &:hover {
    color: var(--blue);
    border-color: var(--blue);
  }
`;

const Panel = styled.div`
  padding: 24px;
  background-color: #fff;
  border-radius: 6px;
  margin-top: 24px;
`;

const ActionText = styled(UIActionText)`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const TipText = styled(UITipIconText)`
  margin-left: auto;
`;

const LinkIcon = styled(ReactSVG)`
  margin-left: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;

    path {
      fill: var(--darkGray);
    }
  }
`;

export default Measure;
