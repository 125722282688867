import * as types from './types';
import { User, AdditionalInfo, VerifyEmailStatus } from 'interfaces/auth';

export const setUser = (user: User) =>
  ({
    type: types.SET_USER,
    payload: user,
  } as const);

export const setAdditionalInfo = (additionalInfo: AdditionalInfo) =>
  ({
    type: types.SET_ADDITIONAL_INFO,
    payload: additionalInfo,
  } as const);

export const logout = () => ({ type: types.LOG_OUT } as const);

export const setVerifyEmailStatus = (status: VerifyEmailStatus) =>
  ({
    type: types.SET_VERIFY_EMAIL_STATUS,
    payload: status,
  } as const);

export const setStateByCountry = (states: string[]) =>
  ({
    type: types.SET_STATE_BY_COUNTRY,
    payload: states,
  } as const);

export const setGetCitesByStates = (cites: string[]) =>
  ({
    type: types.SET_CITES_BY_STATES,
    payload: cites,
  } as const);
