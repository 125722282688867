/* eslint-disable react-hooks/exhaustive-deps */
import { useOperation } from 'hooks/common';
import { CompanyWrapper } from 'layouts';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  fetchCompanyCurrent,
  fetchCompanyCurrentUpdate,
} from 'store/ducks/companies/operations';
import { getCompanyCurrent } from 'store/ducks/companies/selectors';
import SettingForm from './components/SettingForm';

const Setting = () => {
  const [onFetchCompany, isLoading] = useOperation(fetchCompanyCurrent);
  const [onUpdateCompany] = useOperation(fetchCompanyCurrentUpdate);

  const company = useSelector(getCompanyCurrent);

  useEffect(() => {
    onFetchCompany(undefined);
  }, []);

  return (
    <CompanyWrapper isLoading={isLoading}>
      <SettingForm
        initialValues={company}
        onSubmit={values => onUpdateCompany(values, { hideToast: true })}
      />
    </CompanyWrapper>
  );
};

export default Setting;
