import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { GuestWrapper } from 'layouts';
import {
  Login,
  ForgotPassword,
  NewPassword,
  PopupResetPassword,
  Mfa,
  TSSignUp,
} from 'pages/auth';
import Route from './Route';
import { useAcceptInviteTeam } from 'hooks/common';
import useAcceptInviteCompany from 'hooks/common/useAcceptInviteCompany';
import ConsumerSurvey from 'pages/survey/ConsumerSurvey';
import EmployeeSurvey from 'pages/survey/EmployeeSurvey';
import IssueMappingEmployeeSurvey from 'pages/survey/IssueMappingEmployeeSurvey';
import { ClaimFundsLogin, ClaimFundsMfa } from 'pages/claimFunds';
import Unsubscribe from 'pages/auth/Unsubscribe';

const UnauthorizedRoutes = () => {
  useAcceptInviteTeam();
  useAcceptInviteCompany();

  return (
    <Switch>
      <Route path="/login" layout={GuestWrapper} component={Login} />

      {/* <Route path="/signup" layout={GuestWrapper} component={SignUp} /> */}
      <Route
        path="/unsubscribe/contact/:id"
        layout={GuestWrapper}
        component={Unsubscribe}
      />
      <Route path="/ts-signup" layout={GuestWrapper} component={TSSignUp} />
      <Route path="/reset" layout={GuestWrapper} component={ForgotPassword} />
      <Route path="/mfa_route" layout={GuestWrapper} component={Mfa} />
      <Route path="/funds_login" layout={GuestWrapper} component={ClaimFundsLogin} />
      <Route path="/change_password" layout={GuestWrapper} component={NewPassword} />
      <Route
        path="/resend-new-password"
        layout={GuestWrapper}
        component={PopupResetPassword}
      />
      <Route path="/survey/consumer" component={ConsumerSurvey} />
      <Route path="/survey/employee" component={EmployeeSurvey} />
      <Route
        path="/survey/issuemapping-employee"
        component={IssueMappingEmployeeSurvey}
      />
      <Route
        path="/"
        component={() => <Redirect to={`/login${window.location.search}`} />}
      />
    </Switch>
  );
};

export default UnauthorizedRoutes;
