import CardGameplan from 'components/CardGameplan/CardGameplan';
import Jumbotron from 'components/Jumbotron';
import Pagination from 'components/Pagination';
import TotalRow from 'components/TotalRow';
import { useOperation } from 'hooks/common';
import { GameplanByTeamRes } from 'interfaces/gameplan';
import ContentBlockWrapper from 'layouts/ContentBlockWrapper';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchDeleteGameplanContact, fetchGameplan } from 'store/ducks/gameplan/api';
import {
  fetchChangeGameplanNameAndCloseByTeam,
  fetchGameplansByTeam,
} from 'store/ducks/gameplan/operations';
import {
  getGameplansByTeamItems,
  getGameplansByTeamMeta,
} from 'store/ducks/gameplan/selectors';
import { StateProps } from 'store/interfaces';
import styled from 'styled-components';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { SingleSelect } from 'components/UI';
import SearchInput from 'components/SearchInput';
import { GameplanFilterOptions } from 'constants/gamePlan';
import { getTeam } from 'store/ducks/team/selectors';
import GameplanMetricTooltip from '../components/GameplanMetricTooltip';

const GamePlanListBySavelist = () => {
  const [isClose, setIsClose] = useState<'false' | 'true' | ''>('');
  const [isDraft, setIsDraft] = useState<'false' | 'true' | ''>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterType, setFilterType] = useState<string | undefined>('Game Plan');
  const [filterSelectedValue, setFilterSelectedValue] = useState('All Status');
  const { location, push } = useHistory();
  const queryIsClose = queryString.parse(location.search)?.isClose?.toString() || '';
  const queryIsDraft = queryString.parse(location.search)?.isDraft?.toString() || '';
  const queryQ = queryString.parse(location.search)?.q?.toString() || '';
  const queryFilterField =
    queryString.parse(location.search)?.filterField?.toString() || '';

  const [onFetchGameplansByTeam, , isGameplansLoading] = useOperation(
    fetchGameplansByTeam,
  );
  const [onFetchSetGameplanNameAndClose] = useOperation(
    fetchChangeGameplanNameAndCloseByTeam,
  );

  const gameplans = useSelector<StateProps, GameplanByTeamRes['items'] | undefined>(
    getGameplansByTeamItems,
  );
  const gameplansMeta = useSelector<StateProps, GameplanByTeamRes['meta'] | undefined>(
    getGameplansByTeamMeta,
  );
  const team = useSelector(getTeam);

  const onSubmit = ({
    searchValue,
    filterType,
    isClose,
    isDraft,
  }: {
    searchValue: string;
    filterType?: string;
    isClose: string;
    isDraft: string;
  }) => {
    onFetchGameplansByTeam({
      page: 1,
      limit: 6,
      isClose,
      isDraft,
      q: searchValue,
      filterField: filterType === 'Email' ? 'email' : '',
    })?.then(() =>
      push(
        `${location.pathname}?${queryString.stringify({
          isClose,
          isDraft,
          q: searchValue,
          filterField: filterType,
        })}`,
      ),
    );
  };

  const handleChangeFilter = (
    selectedValue: typeof GameplanFilterOptions[number]['value'],
  ) => {
    setFilterSelectedValue(selectedValue);
    let closeString = isClose;
    let draftString = isDraft;
    switch (selectedValue) {
      case 'Opened':
        closeString = 'false';
        draftString = 'false';
        break;
      case 'Closed':
        closeString = 'true';
        draftString = 'false';
        break;
      case 'All Status':
        closeString = '';
        draftString = '';
        break;
      case 'Draft':
        closeString = 'true';
        draftString = 'true';
        break;
    }
    setIsClose(closeString);
    setIsDraft(draftString);
    onSubmit({
      filterType: selectedValue,
      isClose: closeString,
      isDraft: draftString,
      searchValue,
    });
  };

  useEffect(() => {
    onFetchGameplansByTeam({
      page: 1,
      limit: 6,
      isClose: queryIsClose,
      isDraft: queryIsDraft,
      q: queryQ,
      filterField: queryFilterField === 'Email' ? 'email' : '',
    });
    queryIsClose && setIsClose(queryIsClose as typeof isClose);
    queryIsDraft && setIsDraft(queryIsDraft as typeof isDraft);
    queryQ && setSearchValue(queryQ);
    queryFilterField && setFilterType(queryFilterField);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentBlockWrapper
      header={<Jumbotron name="Game Plans" back={true} />}
      loading={isGameplansLoading}
    >
      <Header>
        <TotalRow
          title={`${gameplansMeta?.totalItems || 0} Game plan${
            (gameplansMeta?.totalItems || 0) > 1 ? 's' : ''
          }`}
        />
        <Search
          onChangeInputValue={v => setSearchValue(v)}
          searchInputValue={searchValue}
          onSearch={() => onSubmit({ filterType, isClose, isDraft, searchValue })}
          filterTypesOptions={[
            { label: 'Game Plan', value: 'Game Plan' },
            { label: 'Email', value: 'Email' },
          ]}
          filterTypeValue={filterType}
          onChangeFilterType={type => setFilterType(type)}
        />
      </Header>

      <Filter>
        <Name>Filters</Name>
        <FieldGroup>
          <div style={{ width: '137px' }}>
            <SingleSelect
              placeholder="Select"
              handleSelect={(v: unknown) => {
                handleChangeFilter((v as typeof GameplanFilterOptions[number]).value);
              }}
              value={filterSelectedValue}
              options={GameplanFilterOptions}
              isClearable={false}
            />
          </div>
          {gameplansMeta && gameplansMeta.itemCount > 0 && (
            <Pagination
              total={gameplansMeta.totalItems}
              pageLimit={Number(gameplansMeta.itemsPerPage)}
              page={Number(gameplansMeta.currentPage)}
              submitHandler={(page: number) =>
                onFetchGameplansByTeam({
                  page,
                  limit: 6,
                  isClose: queryIsClose,
                  isDraft: queryIsDraft,
                  q: queryQ,
                  filterField: queryFilterField === 'Email' ? 'email' : '',
                })
              }
            />
          )}
        </FieldGroup>
      </Filter>
      {gameplans && team && (
        <CardGameplanWrapper>
          {gameplans.map(item => (
            <CardGameplan
              key={item.id}
              gameplan={item}
              team={team}
              fetchGameplanApi={fetchGameplan}
              fetchDeleteGameplanContactApi={fetchDeleteGameplanContact}
              onFetchSetGameplanNameAndClose={onFetchSetGameplanNameAndClose}
            />
          ))}
        </CardGameplanWrapper>
      )}
      <GameplanMetricTooltip />
    </ContentBlockWrapper>
  );
};

const CardGameplanWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 24px;
`;

const Header = styled.section`
  display: flex;
  width: 1120px;
  justify-content: space-between;
  align-items: flex-start;
`;

const Filter = styled.section`
  margin-bottom: 24px;
`;

const Name = styled.div`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
  margin-bottom: 8px;
`;

const FieldGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1120px;
`;

const Search = styled(SearchInput)`
  width: 365px;
  flex-shrink: 0;
`;

export default GamePlanListBySavelist;
