import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Recommendations from './Recommendations';
import BatchAnalysis from './BatchAnalysis';
import { useHistory } from 'react-router';
import queryString from 'query-string';

const CharitableOrganizations = (params: any) => {
  const { push } = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const handleSelectTab = (tab: number) => {
    setTabIndex(tab);
    const newSearchValue = {
      ...queryString.parse(window.location.search),
      tabIndex: tab,
    };
    push(`${window.location.pathname}?${queryString.stringify(newSearchValue)}`);
  };
  useEffect(() => {
    const searchTabIndex = queryString.parse(window.location.search)?.tabIndex;
    if (searchTabIndex) setTabIndex(Number(searchTabIndex));
  }, []);
  return (
    <>
      <Section>
        <Tabs selectedIndex={tabIndex} onSelect={handleSelectTab}>
          <TabList>
            <Tab>Recommendations</Tab>
            <Tab>Batch Analysis</Tab>
          </TabList>
          <TabPanel>
            <Recommendations rows={params} />
          </TabPanel>
          <TabPanel>
            <BatchAnalysis rows={params} />
          </TabPanel>
        </Tabs>
      </Section>
    </>
  );
};

const Section = styled.div`
  background-color: #f9f9f9;
  border-radius: 6px;
  margin-bottom: 40px;
  margin-top: -22px;
`;

export default CharitableOrganizations;
