import { PricingData } from 'interfaces/pricing';

export const mockValuationList: PricingData[] = [
  // { id: 1, type: 'Completed', formProgress: 100, category: 'Property' },
  // { id: 2, type: 'Required', formProgress: 50, category: 'Property' },
  // { id: 3, type: 'Required', formProgress: 20, category: undefined },
  // { id: 4, type: 'Pending', formProgress: 100, category: 'Organization' },
];

export const CustomAssetsOptions = [
  {
    value: 'Custom Earned Media Asset',
    label: 'Custom Earned Media Asset',
  },
  {
    value: 'Custom Owned Media Asset',
    label: 'Custom Owned Media Asset',
  },
  {
    value: 'Custom Collateral Asset',
    label: 'Custom Collateral Asset',
  },
  {
    value: 'Custom Activation Asset',
    label: 'Custom Activation Asset',
  },
  {
    value: 'Custom Shared Media Asset',
    label: 'Custom Shared Media Asset',
  },
];

export const ReportTip = {
  'Potential Minimum Threshold': `Potential Minimum Threshold is the increased minimum threshold that your organization can expect if the opportunities for growth are addressed successfully.`,
  'Suggested Minimum Threshold': `Suggested Minimum Threshold is what we like to call the“the minimum price of admission.” Reflecting your organization’s intangible value, this is the value that a company gains from yourpartnership simply by being associated with you –through use of your brand, logo, and name. Itis the minimum amount that a corporationshould be asked for to align with your brand in a way that requires the least amount of support from the organization.The minimum threshold does not take into account your tangible media assets. Any media assets that you leverage toward the partnership count as value added onto this minimum.`,
  'Intangible Asset Value': `Intangible Value is a measure of those assets that are not physical or otherwise tangible in nature. Examples of intangible assets include the goodwill, brand recognition, relationships, knowledge, culture, and intellectual capital, and other assets that your organization has earned and accrued. In this analysis, intangible value is based on your organization details, such as fundraising income and brand awareness, constituent data, mission data, such as mission-affected audience and positive change on mission, and audience loyalty and activation`,
  'Tangible Asset Value': `Tangible Asset Value is a measure of those media assets that your organization can leverage for a partnership. Media assets include media ambassadors, paid media, earned media, owned media, and shared or custom media assets.`,
  'Total Asset Value': `Your organization’s Total Asset Value is a composite measure of both Intangible Value and Tangible Media assets.`,
  'Industry Ranking': `A partner wants to know they have aligned with a best-in-class organization - one that is fiscally-responsible, with a good reputation, has the capacity to support them and can clearly communicate their impact.`,
  'Unaided Brand Awareness': `A partner wants to know that your brand is well-established and resonates with audiences. Being able to demonstrate brand awareness lets your partner know that you can create memorable connections with consumers and build relationships and recognition in a way that is self-sustaining. `,
  'Aided Brand Awareness': `A partner wants to know that your brand is well-established and resonates with audiences. Being able to demonstrate brand awareness lets your partner know that you can create memorable connections with consumers and build relationships and recognition in a way that is self-sustaining. `,
  'Constituent Data': `The consumer will always be king. When considering the right causes to support, many companies seek to align with nonprofits who have a shared target audience. Nonprofits with a deep understanding and ability to communicate their constituent persona have a strong advantage to other potential nonprofit partners in a competitive marketplace. `,
  'Community Impact': `Companies know that doing good is no longer good enough. The social good landscape now demands a higher standard, with expectation that the impact of corporate social investments is being measured and reported to stakeholders. Companies are looking to partner with nonprofits who measure their community impact and can clearly communicate it, so they too can share the impact they are making to their own audiences.`,
  'Sense of Urgency': `A partner wants to know that your nonprofit cause appeals to a sense of urgency in consumers – and the partner itself.  Your mission relies on receiving support right now. A partner wants to work with an effective organization that is having an impact on the cause in real time. Being able to communicate that urgency is crucial to standing out and attracting the funds you need to accomplish your work.`,
  'Audience Loyalty': `A partner wants to know that you have a consistent and reliable audience base with which to support various aspects of the partnership. Being able to steward loyal relationships year after year means that you and your partner are not starting from scratch each year to attract authentic audience engagement. `,
  'Audience Activation': `A partner wants to know if you can engage an audience across multiple channels or if you are relying on attracting a unique audience every time. Being able to demonstrate that you have earned a loyal and active core base who support your entire operation is an important piece of stable partnership support. `,
  'Brand Stability': ` In an increasingly competitive nonprofit landscape, a partner wants to know that your brand is stable enough to support the partnership long-term. Being able to demonstrate that you can overcome existing nonprofit industry challenges will position you as a strong partner. `,
  'Fiscal Responsibility': `Companies want to align with nonprofits that are fiscally responsible and good stewards of their philanthropic dollars.  `,
  'Scope and Reach': ``,
  'Degree of Partner Clutter': `A partner wants to know that you have room for them at the table. Do you have so many partners that you are stretched thin? Or instead do you have a clear plan for how each partner can specifically align with you and have an impact? Being able to demonstrate that you can focus on and devote the resources necessary to the partnership is a must.`,
  Strategy: `A partner wants to know that you are thinking about ‘what’s next’ – because they certainly are! It is important to demonstrate that you have a vision to drive impact for the next year, as well as three- to five-years into the future. Being able to communicate that strategy clearly to partners is critical to instilling confidence and scaling up your impact across multiple years. `,
  'Influencer Support': `A partner wants to know that you can activate genuine connections with your audience through brand ambassadors whose values match your organization. Influencers of any size magnify your reach and can help your organization connect to a loyal and engaged audience. Being able to demonstrate a deliberate and effective influencer or celebrity strategy increases the potential value of your partnership.`,
  'Brand Exposure': `Corporate sponsors and partners are interested in aligning with causes that are creating buzz in the marketplace through their various networks, like Influencers`,
  'Paid Media': `While most nonprofit organizations do not invest in paid media to support their corporate partners, many corporations have and will leverage their own media spends on their partners' behalf.  `,
  'Earned Media': `Corporate sponsors and partners are interested in aligning with causes that are creating buzz in the marketplace.  `,
  'Owned Media': `While [nonprofit organization] has a few owned assets that may be leveraged for partnerships, the organization further build and leverage additional opportunities to remain competitive within corporate partner funraising. `,
  'Shared Media': `With the world’s transformation to digital communication and extreme dependency on social media for connectivity, corporate partners turn to their nonprofit partners to help them elevate their social exposure.  Social partner activation is one of the most effective channels nonprofits can connect their passionate individual and corporate supporters.  `,
  'Sponsor Percentage Take of Cross-Package Value': `The value of the amount of exposure each individual sponsor receives, when afforded multiple assets alongside multiple partners.`,
  'Total Level Value': `The value of the collective set of assets recommended at each giving threshold`,
  'Recommended Pricing/Partner': `Accelerist's recommended  threshold price, considering an industry best practice of offering partners 1.5x more value than they invest.`,
  'Number of Potential Partners/Level':
    'The number of recommended potential partners to ensure full value of the aligned asset level.',
};
