import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { FieldTextInput } from 'components/FormFields';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import { UIButton } from 'components/UI';
import { confirmPassword, passwordLength } from 'utils/validation';
import { PasswordData } from '../interfaces';

import styled from 'styled-components';
import { Field } from 'components/FinalForm';
import { FormTitle } from 'components/UI/form';

export interface FormProps {
  onSubmit: (values: PasswordData) => OnSubmitReturnType;
}

const PasswordForm = ({ onSubmit }: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        submitting,
        pristine,
        hasValidationErrors,
        form,
      }: FormRenderProps<PasswordData>) => {
        return (
          <FormStyled>
            <FormTitle>Password</FormTitle>
            <Row>
              <Field
                name="password"
                label="New Password"
                component={FieldTextInput}
                placeholder="Start typing"
                validate={passwordLength}
              />
              <Field
                name="passwordConfirmation"
                label="Confirm Password"
                component={FieldTextInput}
                placeholder="Start typing"
                validate={(value: string) =>
                  confirmPassword(value, form.getState().values)
                }
              />
            </Row>

            <Btn
              modifiers="primary"
              title="Save"
              handleClick={handleSubmit}
              type="submit"
              disabled={pristine || submitting || hasValidationErrors}
              isLoading={submitting}
            />
          </FormStyled>
        );
      }}
    />
  );
};

const FormStyled = styled.form`
  background: #ffffff;
  border-radius: 4px;
  padding: 40px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    width: calc(50% - 8px);
  }
`;

const Btn = styled(UIButton)`
  margin-top: 16px;
  width: 146px;
`;

export default PasswordForm;
