import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { FieldTextInput, FieldTextArea, FieldSingleSelect } from 'components/FormFields';
import { UIButton } from 'components/UI';
import { required } from 'utils/validation';
import { reasonOptions } from 'constants/company';
import { SupportData } from '../interfaces';

import styled from 'styled-components';
import { Field } from 'components/FinalForm';

export interface FormProps {
  companyName?: string;
  onSubmit: (params: SupportData) => void;
}

const SupportForm = ({ companyName, onSubmit }: FormProps) => {
  return (
    <Form
      onSubmit={values => {
        onSubmit(values);
      }}
      initialValues={{
        companyName,
        reason: 'Profile Update Request',
        text: companyName ? `For  ${companyName}` : '',
      }}
      render={({ handleSubmit }: FormRenderProps<SupportData>) => {
        return (
          <div>
            {companyName ? (
              <Field
                name="reason"
                label="Reason"
                component={FieldTextInput}
                placeholder="Enter reason"
                validate={required}
              />
            ) : (
              <Field
                name="reason"
                label="Reason"
                component={FieldSingleSelect}
                placeholder="Select reason"
                validate={required}
                options={reasonOptions}
              />
            )}

            <Field
              name="text"
              label="Text"
              component={FieldTextArea}
              placeholder="Enter text"
              validate={required}
              height={90}
            />

            {/* <a
              href={`mailto:members@accelerist.com?subject=${
                values.reason
              }&body=${encodeURIComponent(values.text)}`}
            > */}
            <Btn
              modifiers="primary"
              title="Send"
              type="button"
              handleClick={handleSubmit}
            />
            {/* </a> */}
          </div>
        );
      }}
    />
  );
};

const Btn = styled(UIButton)`
  width: 100%;
  height: 36px;
  padding: 5px 0;
  margin-top: 8px;
  font-size: 12px;
`;

export default SupportForm;
