/* eslint-disable react-hooks/exhaustive-deps */
import { SaveListButton, SupportButton } from 'components/ActionButtons';
import CardTeam from 'components/CardTeam';
import EmptyCompany from 'components/EmptyCompany';
import { FilterTeamsData } from 'components/Forms/interfaces';
import List from 'components/List';
import { CannotSavedModal } from 'components/Modals';
import Pagination from 'components/Pagination';
import { PAGE_WIDTH } from 'constants/common';
import { useModal, useOperation } from 'hooks/common';
import { CardTeamProps, PaginationMeta } from 'interfaces/common';
import { CardCompanyRankText } from 'interfaces/search';
import { TeamProfileData } from 'interfaces/team';
import { SearchTeamsWrapper } from 'layouts';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { animateScroll as scroll } from 'react-scroll';
import { fetchSavedListTeamsCreate } from 'store/ducks/prospectsTeams/operations';
import { fetchTeams, fetchTeamsCustomizeFilter } from 'store/ducks/team/operations';
import { getTeams, getTeamsMeta } from 'store/ducks/team/selectors';
import { getTeamRange } from 'store/ducks/user/selectors';
import { StateProps } from 'store/interfaces';
import styled from 'styled-components';
import { decodeBase64, encodeBase64 } from 'utils';
import { initialValuesFilter } from './initialValuesFilter';
import { fetchSimilaritySearchCompany } from 'store/ducks/companies/operations';
import { UIButton } from 'components/UI';
import { Prospect } from 'interfaces/prospects';
import { getSavedListsTeams } from 'store/ducks/prospectsTeams/selectors';
import { fetchSavedListsTeams } from 'store/ducks/prospectsTeams/operations';

interface SearchParams {
  filters?: FilterTeamsData;
  page?: number;
}

type SearchParamsStringify = { [key in keyof SearchParams]: string };

const Search = () => {
  const { location, push } = useHistory();
  const teamRange = useSelector(getTeamRange);
  const [initialValues, setInitialValues] = useState<FilterTeamsData>(
    initialValuesFilter,
  );

  const [onFetchCompanies, , isLoading] = useOperation(fetchTeams);
  const [onFetchCustomizeFilter, , isCustomizeLoading] = useOperation(
    fetchTeamsCustomizeFilter,
  );
  const [onFetchSavedListTeamsCreate] = useOperation(fetchSavedListTeamsCreate);

  const companies = useSelector<StateProps, TeamProfileData[]>(getTeams);
  const meta = useSelector<StateProps, PaginationMeta>(getTeamsMeta);
  const totalRef = useRef<HTMLHeadingElement | null>(null);
  const [onfetchSimilaritySearchCompany] = useOperation(fetchSimilaritySearchCompany);
  const [onfetchSavedLists, isLoadings] = useOperation(fetchSavedListsTeams);
  const savedLists = useSelector<StateProps, Prospect[]>(getSavedListsTeams);

  const { currentPage, itemsPerPage, totalItems } = meta;

  const urlSearchParams = useMemo((): SearchParams => {
    const searchParams = queryString.parse(location.search) as SearchParamsStringify;
    return {
      filters: searchParams.filters && JSON.parse(decodeBase64(searchParams.filters)),
      page: Number(searchParams.page),
    };
  }, [queryString.parse(location.search)]);

  const [showCannotSavedModal, hideCannotSavedModal] = useModal(() => {
    return <CannotSavedModal onClose={hideCannotSavedModal} />;
  });

  const joinDefaultTeamRange = useCallback(
    (obj: FilterTeamsData): FilterTeamsData => ({
      ...obj,
      numberOfSocialFollowers: [0, Number(teamRange.numberofsocialfollowersmax)],
      minimumCommitment: [0, Number(teamRange.minimumcommitmentmax)],
      grossReceiptsRange: [0, Number(teamRange.annualtotalcontributionsmax)],
    }),
    [teamRange],
  );

  const getInitialState = () => {
    const filtersString = queryString.parse(location.search)?.filters;
    const pageString = queryString.parse(location.search)?.page;
    if (filtersString) {
      const param = JSON.parse(decodeBase64(filtersString?.toString()));
      onFetchCompanies({ page: Number(pageString) || 1, ...param });
      return setInitialValues(param);
    } else {
      onFetchCompanies({ page: Number(pageString) || 1 });
    }
    onfetchSavedLists({ page: Number(pageString) || 1, sort: 'alphabet' });
    return setInitialValues(joinDefaultTeamRange(initialValues));
  };

  useEffect(() => {
    setInitialValues(joinDefaultTeamRange(initialValues));
  }, [teamRange]);

  useEffect(() => {
    getInitialState();
  }, []);

  const onSearch = (values?: FilterTeamsData, limit = 12, page = 1) => {
    const base64 = encodeBase64(JSON.stringify(values));
    push(`${location.pathname}?${queryString.stringify({ filters: base64 })}`);
    if (values?.q && values.filterType === 'Best Match') {
      onfetchSimilaritySearchCompany(values?.q);
    }
    if (values?.variantForm === 'Customize') {
      const { priority, ...rest } = values;
      onFetchCustomizeFilter({
        filters: {
          ...rest,
          limit,
          page,
        },
        ranks: { ...priority },
      }).then(() => {
        const offsetTop = totalRef.current?.offsetTop;
        offsetTop && scroll.scrollTo(offsetTop - 32);
      });
    } else {
      onFetchCompanies({ page: 1, ...values }).then(() => {
        const offsetTop = totalRef.current?.offsetTop;
        offsetTop && scroll.scrollTo(offsetTop - 32);
      });
    }
  };

  const submitHandlerPagination = (page: number) => {
    const filtersString = queryString.parse(location.search)?.filters;

    if (filtersString) {
      const param: FilterTeamsData = JSON.parse(decodeBase64(filtersString?.toString()));
      if (param.variantForm === 'Customize') {
        return onSearch(param, undefined, page);
      }
      return onFetchCompanies({ page, ...param });
    }

    return onFetchCompanies({ page });
  };

  const saveHandler = () => {
    const filtersString = queryString.parse(location.search)?.filters;
    if (filtersString) {
      const param = JSON.parse(decodeBase64(filtersString?.toString()));

      return onFetchSavedListTeamsCreate({
        prospectsAvailable: totalItems,
        filters: param,
      }).then(result => {
        return push(`prospects/${result?.id}`);
      });
    }

    return showCannotSavedModal();
  };

  return (
    <SearchTeamsWrapper
      filterSubmitHandler={values => {
        if (values) {
          delete values.ids;
        }
        onSearch(values);
      }}
      isLoading={isLoading || isCustomizeLoading}
      initialValues={initialValues}
    >
      <Header>
        <Total ref={totalRef}>{`Found ${totalItems} organization${
          totalItems > 1 ? 's' : ''
        }`}</Total>
        {savedLists.length > 0 ? (
          <Actions>
            <Button
              title="View Saved List"
              modifiers="third"
              handleClick={() => {
                push('/prospects?type=viewsavedlist');
              }}
            />
          </Actions>
        ) : (
          ''
        )}
      </Header>
      <HeaderList>
        <SuggestionActions>
          {companies.length > 0 && (
            <>
              <SaveListButton saveHandler={saveHandler} />
            </>
          )}
          <SupportButton />
        </SuggestionActions>
        {totalItems > 0 && (
          <Pagination
            total={totalItems}
            pageLimit={Number(itemsPerPage)}
            page={Number(currentPage)}
            submitHandler={(page: number) => submitHandlerPagination(page)}
          />
        )}
      </HeaderList>

      {companies.length > 0 ? (
        <List<TeamProfileData, CardTeamProps>
          list={companies}
          component={CardTeam}
          isIndexKey
          componentProps={{
            rankText:
              urlSearchParams.filters?.variantForm === 'Customize'
                ? CardCompanyRankText['Customize Ranking']
                : undefined,
          }}
        />
      ) : (
        <Empty />
      )}
    </SearchTeamsWrapper>
  );
};

const SuggestionActions = styled.div`
  display: flex;

  button:nth-child(-n + 2) {
    margin-right: 40px;
  }
`;

const Empty = styled(EmptyCompany)`
  height: calc(100vh - 330px);
`;

const Total = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  font-family: Rubik;
  font-style: normal;
  margin-bottom: 26px;
`;

const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 27px;
  max-width: ${PAGE_WIDTH.NORMAL}px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${PAGE_WIDTH.NORMAL}px;
`;

const Actions = styled.div``;

const Button = styled(UIButton)``;

export default Search;
