/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled from 'styled-components';
import { FormError } from 'components/UI/form';
import { FormLabel } from 'components/UI/form';
import PlusBlackSVG from 'assets/images/icons/plus-black.svg';
import { ReactSVG } from 'react-svg';
import ColorPicker from 'components/ColorPicker';

const FieldColorGroupPicker = ({
  input,
  label,
  meta,
  style,
  ...selectProps
}: FieldRenderProps<string[]>) => {
  const { error, submitError, touched, dirtySinceLastSubmit } = meta;
  const isError =
    (!selectProps.disabled && error && touched) || (submitError && !dirtySinceLastSubmit);

  const handleAdd = () => {
    input.onChange(input.value ? [...input.value, '#ffffff'] : ['#ffffff']);
  };

  const handleChange = (value: string, index: number) => {
    if (!input.value) return;

    const colors = [
      ...input.value.slice(0, index),
      value,
      ...input.value.slice(index + 1),
    ];
    input.onChange(colors);
  };

  return (
    <Field style={style}>
      {label && <FormLabel>{label}</FormLabel>}
      <ColorBlocksWrapper>
        {(input.value || []).map((item, i) => (
          <ColorPicker key={i} value={item} onChange={value => handleChange(value, i)} />
        ))}
        {input.value?.length < 4 && (
          <ColorBlock $color="white" onClick={handleAdd}>
            <ReactSVG src={PlusBlackSVG} />
          </ColorBlock>
        )}
      </ColorBlocksWrapper>

      {isError && <FormError meta={meta} />}
    </Field>
  );
};

const Field = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const ColorBlock = styled.div<{ $color: string }>`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 8px;
  border: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ $color }) => $color};
`;

const ColorBlocksWrapper = styled.div`
  display: flex;
`;

export default FieldColorGroupPicker;
