import { CropText, UIButton } from 'components/UI';
import { UserPreview } from 'interfaces/common';
import React from 'react';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import { DATE_FORMAT } from 'constants/date';
import {
  getFullName,
  capitalizeText,
  getRelativeTime,
  cropHttps,
} from 'utils/formatters';
import { useModal, useOperation, useFileUrl } from 'hooks/common';
import { ConfirmModal } from 'components/Modals';
import { fetchDeleteTeamMembers } from 'store/ducks/team/operations';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/ducks/user/selectors';

interface Props {
  rows: UserPreview[];
  headCells: string[];
}

interface TableRowProps {
  row: UserPreview;
}

const userTableWidth = {
  oneColumn: 52,
  twoColumn: 138,
  thirdColumn: 181,
  fourColumn: 122,
  fiveColumn: 108,
  sixColumn: 119,
} as const;

const {
  oneColumn,
  twoColumn,
  thirdColumn,
  fourColumn,
  fiveColumn,
  sixColumn,
} = userTableWidth;

const TableRowWrapper = ({ row }: TableRowProps) => {
  const user = useSelector(selectUser);
  const {
    id,
    lastName,
    firstName,
    lastLogin,
    linkedinLink,
    avatarKey,
    email,
    role,
  } = row;
  const { fileUrl } = useFileUrl({ imgKey: avatarKey });
  const [deleteTeamMembers, , isDeleting] = useOperation(fetchDeleteTeamMembers);

  const [showModal, hideModal] = useModal(() => {
    const deleteHandler = () => {
      deleteTeamMembers(id);
      hideModal();
    };

    return (
      <ConfirmModal
        name={'Delete user'}
        textAccessBtn="Delete"
        description={'Are you sure you want to delete the user?'}
        accessHandler={deleteHandler}
        cancelHandler={hideModal}
        onClose={hideModal}
        hideCloseIcon
      />
    );
  });

  const formattedLastLogin = lastLogin
    ? getRelativeTime(lastLogin, DATE_FORMAT.LAST_LOGIN_FORMAT)
    : '-';
  const capitalizeRole = capitalizeText(role);

  const userName = getFullName(firstName, lastName);

  return (
    <TableRow>
      <TableRowCell isNoAddBorder>
        <Avatar round name={userName} src={fileUrl} size="40" />
      </TableRowCell>
      <TableRowCell isAddWeight>
        <CropText width={twoColumn - 20} text={userName} />
      </TableRowCell>
      <TableRowCell>
        <CropText width={thirdColumn - 20} text={email} />
      </TableRowCell>
      <TableRowCell>
        {
          <LinkedInWrapper width={fourColumn - 10}>
            {linkedinLink ? (
              <LinkedInLink href={linkedinLink} target="_blank">
                {cropHttps(linkedinLink)}
              </LinkedInLink>
            ) : (
              '-'
            )}
          </LinkedInWrapper>
        }
      </TableRowCell>
      <TableRowCell>{capitalizeRole}</TableRowCell>
      <TableRowCell isAddWeight>{formattedLastLogin}</TableRowCell>
      <TableRowCell>
        {user?.role === 'owner' && user.id !== id && (
          <DeleteButton
            isLoading={isDeleting}
            handleClick={showModal}
            modifiers="danger"
            title="Delete"
          />
        )}
      </TableRowCell>
    </TableRow>
  );
};

const UserTable = ({ rows, headCells }: Props) => {
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col span={1} style={{ width: `${oneColumn}px` }} />
          <col span={1} style={{ width: `${twoColumn}px` }} />
          <col span={1} style={{ width: `${thirdColumn}px` }} />
          <col span={1} style={{ width: `${fourColumn}px` }} />
          <col span={1} style={{ width: `${fiveColumn}px` }} />
          <col span={1} style={{ width: `${sixColumn}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            {headCells.map((item, index) => (
              <TableHeadCell colSpan={index === 0 ? 2 : undefined} key={index}>
                {item}
              </TableHeadCell>
            ))}
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(item => (
            <TableRowWrapper key={item.id} row={item} />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const LinkedInWrapper = styled.div<{ width?: string | number }>`
  display: block;
  ${({ width }) => width && `width: ${width}px;`}
`;

const LinkedInLink = styled.a`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;

const TableWrapper = styled.div`
  overflow: auto;
  height: 444px;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 39px;
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;
const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--darkGray);

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableRow = styled.tr`
  height: 100%;
`;

const TableRowCell = styled.td<{ isAddWeight?: boolean; isNoAddBorder?: boolean }>`
  padding: 20px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }

  ${({ isAddWeight }) => isAddWeight && 'font-weight: 500;'}
  ${({ isNoAddBorder }) => isNoAddBorder && 'border-bottom: none;'}
`;

const DeleteButton = styled(UIButton)`
  width: 76px;
  height: 36px;
  padding: 0;
`;

export default UserTable;
