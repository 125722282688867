import * as api from './api';
import * as actions from './actions';
import { Dispatch } from 'redux';
import Toast from 'services/Toast';
import { SavedListCreateRequestProps } from 'interfaces/common';
import {
  UploadCustomProspectingExcelReq,
  UploadCustomProspectingExcelRes,
} from 'interfaces/prospects';

export const fetchSavedLists = ({ page, sort }) => async (dispatch: Dispatch) => {
  return api
    .fetchSavedLists({ page, sort })
    .then(lists => {
      dispatch(actions.setSavedLists(lists));
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchSavedList = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchSavedList(id)
    .then(list => {
      dispatch(actions.setSavedList(list));
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchSavedListCreate = (data: SavedListCreateRequestProps) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchSavedListCreate(data)
    .then(list => {
      dispatch(actions.setSavedList(list));
      return list;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchSavedListUpdate = ({ id, data }) => async (dispatch: Dispatch) => {
  return api
    .fetchSavedListUpdate(id, data)
    .then(list => {
      dispatch(actions.setSavedList(list));
      return list;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchSavedLisDestroy = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchSavedLisDestroy(id)
    .then(savedListId => {
      dispatch(actions.removeSavedList(savedListId));
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export const fetchSuggestSavedlist = (name: string) => async (dispatch: Dispatch) => {
  return api
    .fetchSuggestSavedlist(name)
    .then(suggestSavedList => {
      dispatch(actions.setSuggestSavedList(suggestSavedList));
    })
    .catch(() => Toast.error('Error get suggest savedlist'));
};

export const fetchUploadCustomProspectingExcel = (
  params: UploadCustomProspectingExcelReq,
) => async () => {
  return api
    .fetchUploadCustomProspectingExcel(params)
    .then(value => {
      Toast.success('Successfully submitted.');
      return value;
    })
    .catch(() => {
      Toast.error('Error file upload');
    });
};

export const fetchSendNotExistsCompany = (
  companys: UploadCustomProspectingExcelRes['notExistsCompanies'],
) => async () => {
  return api
    .fetchSendNotExistsCompany(companys)
    .then(() => {
      Toast.success('Sent successfully');
    })
    .catch(() => {
      Toast.error('Failed to send');
    });
};
