export const membershipRatesOptions = [
  {
    label: 'Less than $ 20M',
    value: '20',
  },
  {
    label: '$ 21 - 50M',
    value: '21-50',
  },
  {
    label: '$ 51 - 200M',
    value: '51-200',
  },
  {
    label: 'More than $ 200M',
    value: '>200M',
  },
];

export const membershipRows = [
  {
    name: 'Benchmarking',
    basic: true,
    pro: true,
    enterprise: true,
  },
  {
    name: 'Audience: Analysis',
    basic: '1',
    pro: 'Up to 3',
    enterprise: 'Up to 5',
  },
  {
    name: 'Pricing',
    basic: '1',
    pro: 'Up to 2',
    enterprise: 'Up to 3',
  },
  {
    name: 'Prospects',
    basic: 'Search, Filters, Lists',
    pro: 'Export',
    enterprise: 'Export',
  },
  {
    name: 'Lead Scoring',
    basic: true,
    pro: 'Export',
    enterprise: 'Export',
  },
  {
    name: 'Tracking',
    basic: false,
    pro: '2 Partners',
    enterprise: '4 Partners',
  },
  {
    name: 'Catalog NG',
    basic: false,
    pro: '2 Partners',
    enterprise: '4 Partners',
  },
  {
    name: 'ROI',
    basic: false,
    pro: '2 Partners',
    enterprise: '4 Partners',
  },
];

export const membershipHeadTable = [
  {
    name: 'Basic',
    price: '$ 5,850',
  },
  {
    name: 'PRO',
    price: '$ 9,425',
  },
  {
    name: 'Enterprise',
    price: '$ 15,275',
  },
];
