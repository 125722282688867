import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import { UIButton, UICheckbox } from 'components/UI';
import { TableSortHeaderCell } from 'components/UI/table';
import { SortStatus } from 'hooks/common/useSortStatus';
import { AudienceData } from 'interfaces/audience';

type Row = AudienceData[];
interface Props {
  rows: Row;
  selectedAll: boolean;
  selectedIds: string[];
  onCheckRow: (id: string) => void;
  onCheckAll: (bool: boolean) => void;
  sortStatus: SortStatus;
  onSort: (colName: string) => void;
  onDownloadReport: (id: string) => void;
  onDownloadRawReport: (id: string) => void;
}

interface TableRowProps {
  row: Row[0];
  check: boolean;
  onCheckRow: Props['onCheckRow'];
  onDownloadReport: Props['onDownloadReport'];
  onDownloadRawReport: Props['onDownloadRawReport'];
}

const userTableWidth = {
  nameWidth: 230,
  statusWidth: 150,
  dateWidth: 180,
  actionWidth: 180,
} as const;

const { nameWidth, statusWidth, dateWidth, actionWidth } = userTableWidth;

const TableRowWrapper = ({
  row,
  check,
  onCheckRow,
  onDownloadReport,
  onDownloadRawReport,
}: TableRowProps) => {
  return (
    <TableRow>
      <TableRowCell style={{ width: `${70}px` }}>
        <UICheckbox check={check} handleClick={() => onCheckRow(row.id)} />
      </TableRowCell>
      <TableRowCell>{row.name}</TableRowCell>
      <TableRowCell>
        {row.createdAt && format(new Date(row.createdAt), 'MMM dd, yyyy')}
      </TableRowCell>
      <TableRowCell>
        <Tag $color={row.status === 'Completed' ? '#279B48' : '#868686'}>
          {row.status}
        </Tag>
      </TableRowCell>
      <TableRowCell>
        <Button
          title="Download Report"
          modifiers={'blue'}
          disabled={!row.adminAttachment}
          handleClick={() => onDownloadReport(row.id)}
        />
      </TableRowCell>
      <TableRowCell>
        <Button
          title="Download Raw Data"
          modifiers={'blue'}
          disabled={!row.rawAttachment}
          handleClick={() => onDownloadRawReport(row.id)}
        />
      </TableRowCell>
    </TableRow>
  );
};

const AudienceTable = ({
  rows,
  selectedAll,
  selectedIds,
  sortStatus,
  onCheckAll,
  onCheckRow,
  onSort,
  onDownloadReport,
  onDownloadRawReport,
}: Props) => {
  return (
    <TableWrapper>
      <Table>
        <colgroup>
          <col style={{ width: `${70}px` }} />
          <col span={1} style={{ width: `${nameWidth}px` }} />
          <col span={1} style={{ width: `${dateWidth}px` }} />
          <col span={1} style={{ width: `${statusWidth}px` }} />
          <col span={1} style={{ width: `${actionWidth}px` }} />
          <col span={1} style={{ width: `${actionWidth}px` }} />
        </colgroup>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell key="checkbox" style={{ width: `${70}px` }}>
              <UICheckbox
                check={selectedAll}
                handleClick={() => onCheckAll(!selectedAll)}
              />
            </TableHeadCell>
            <TableSortHeaderCell colName="name" onClick={onSort} sortStatus={sortStatus}>
              List Name
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="createdAt"
              onClick={onSort}
              sortStatus={sortStatus}
            >
              Request Date
            </TableSortHeaderCell>
            <TableSortHeaderCell
              colName="status"
              onClick={onSort}
              sortStatus={sortStatus}
            >
              Status
            </TableSortHeaderCell>

            <TableHeadCell />
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map((item, index) => (
            <TableRowWrapper
              key={index}
              row={item}
              check={selectedIds.includes(item.id)}
              onCheckRow={onCheckRow}
              onDownloadReport={onDownloadReport}
              onDownloadRawReport={onDownloadRawReport}
            />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  overflow: auto;
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  background: #f4f4f4;
`;

const TableHeadRow = styled.tr`
  height: 36px;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 12px 0 9px;
  font-size: 12px;
  line-height: 150%;
  position: sticky;
  top: 0;
  color: var(--darkGray);
  vertical-align: middle;

  &:first-child {
    border-radius: 4px 0 0 0;
    padding-left: 24px;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
    padding-left: 24px;
  }
`;

const TableBody = styled.tbody`
  background: #ffffff;
`;

const TableRow = styled.tr`
  height: 100%;
  background-color: #fff;
`;

const TableRowCell = styled.td`
  padding: 14px 0;
  border-bottom: 1px solid #f6f6f6;
  font-size: 0.75rem;
  line-height: 150%;
  color: var(--black);
  vertical-align: middle;

  &:first-child {
    padding-left: 24px;

    & > div {
      margin-right: 12px;
    }
  }

  &:last-child {
    padding-right: 24px;
  }
`;

const Tag = styled.div<{ $color: string }>`
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 12px;
  width: fit-content;
  border: 1px solid ${({ $color }) => $color};
  color: ${({ $color }) => $color};
`;

const Button = styled(UIButton)`
  white-space: nowrap;
  font-size: 12px;
`;

export default AudienceTable;
