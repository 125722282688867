/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getVerifyEmailStatus, selectUser } from 'store/ducks/user/selectors';
import { UIButton } from 'components/UI';
import { fetchContactPitch } from 'store/ducks/companies/operations';
import { StateProps } from 'store/interfaces';
import { SearchCompaniesContact } from 'interfaces/companies';
import { VerifyEmailStatus } from 'interfaces/auth';
import { useModal, useOperation } from 'hooks/common';
import { PitchModal } from 'components/Modals';
import { sendEmail } from 'store/ducks/utility/api';
import { PitchValues } from 'interfaces/common';
import Toast from 'services/Toast';
import CannotPitchModal from 'components/Modals/CannotPitchModal';
import { useHistory } from 'react-router';
import { CustomizeTemplateGetRes } from 'interfaces/gameplan';

interface PitchButtonProps {
  contact: SearchCompaniesContact[];
  customizeTemplate?: CustomizeTemplateGetRes;
  onSendCallback?: () => void;
}

const SearchContactMultPitchButton = ({
  contact,
  customizeTemplate,
  onSendCallback,
}: PitchButtonProps) => {
  const { push } = useHistory();

  const [onFetchPitchContact] = useOperation(fetchContactPitch);

  const user = useSelector(selectUser);
  const verifyEmailStatus = useSelector<StateProps, VerifyEmailStatus>(
    getVerifyEmailStatus,
  );

  const contactNames = useMemo(
    () => contact.map(item => item.contact_firstName).join(', '),
    [contact],
  );
  const emailNames = useMemo(() => contact.map(item => item.contact_email).join(', '), [
    contact,
  ]);

  const handleSendEmail = (param: PitchValues, testEmail?: string) => {
    sendEmail(
      contact.slice(0, testEmail ? 1 : contact.length).map(item => ({
        bcc: param.bcc,
        emailTo: testEmail || item.contact_email || '',
        subject: param.subject,
        attachments: param.attachments,
        body: param.text,
        contactId: item.contact_id,
      })),
    )
      .then(({ status }) => {
        if (status !== 'success') return Toast.success('Fail send email');

        if (!testEmail)
          onFetchPitchContact(
            contact.map(item => ({
              companyId: item.company_id,
              contactId: item.contact_id,
            })),
          ).then(onSendCallback);

        Toast.success('Email sent successfully');
      })
      .catch(() => Toast.error('Error send email'));
  };

  const [showPitchModal, hidePitchModal] = useModal(
    () => (
      <PitchModal
        pitch={{
          attachments: [],
          recipient: contact.map(item => ({
            name: item.contact_firstName,
            email: item.contact_email,
          })),
          sender: { name: user.firstName ?? undefined, email: user.email },
          text: customizeTemplate?.body || '',
        }}
        onClose={hidePitchModal}
        onSubmit={values => {
          hidePitchModal();
          handleSendEmail(values);
        }}
        onSendTestEmail={(values, testEmail) => handleSendEmail(values, testEmail)}
      />
    ),
    [customizeTemplate, contactNames, emailNames],
  );

  const [showCannotPitchModal, hideCannotPitchModal] = useModal(() => {
    return (
      <CannotPitchModal
        accessHandler={() => push('/user-profile')}
        cancelHandler={hideCannotPitchModal}
      />
    );
  });

  const handleGetPitchEmailContent = useCallback(() => {
    showPitchModal();
  }, []);

  return (
    <Btn
      modifiers="third"
      handleClick={() => {
        verifyEmailStatus ? handleGetPitchEmailContent() : showCannotPitchModal();
      }}
      title="Pitch"
    />
  );
};

const Btn = styled(UIButton)`
  padding: 10px 24px;
  width: 68px;
  padding: 0;
`;

export default SearchContactMultPitchButton;
