import { useCallback, useState } from 'react';

const useStateToggler = (initialState = false) => {
  const [state, setState] = useState<boolean>(initialState);

  const showHandler = () => setState(true);
  const hideHandler = () => setState(false);
  const toggle = useCallback(() => {
    setState(!state);
  }, [state]);

  return [state, showHandler, hideHandler, toggle] as const;
};

export default useStateToggler;
