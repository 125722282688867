/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import AuthFormWrapper from 'components/AuthForms/AuthFormWrapper';
import { FundsAuthData } from 'interfaces/auth';
import { useOperation } from 'hooks/common';
import { fetchFundsSignIn } from 'store/ducks/user/operations';
import { useHistory } from 'react-router-dom';
import FundsLoginForm from 'components/ClaimFundsForms/FundsLoginForm';

const ClaimFundsLogin = () => {
  const history = useHistory();

  const [onLogin] = useOperation(fetchFundsSignIn);

  const login = useCallback((values: FundsAuthData) => {
    return onLogin(values, history);
  }, []);

  return (
    <Wrapper>
      <FundsLoginForm onSubmit={login} />
    </Wrapper>
  );
};

const Wrapper = styled(AuthFormWrapper)`
  margin-bottom: 40px;
`;

export default ClaimFundsLogin;
