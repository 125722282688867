import { UITextInput } from 'components/UI';
import { UITextInputProps } from 'components/UI/UITextInput';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  prefix?: () => ReactNode;
  suffix?: () => ReactNode;
}

interface InputProps {
  isPrefix: boolean;
  isSuffix: boolean;
  error?: boolean;
}

const PresuffixInput: FC<Props & UITextInputProps> = ({
  prefix,
  suffix,
  className,
  error,
  ...restProps
}) => {
  return (
    <Root className={className}>
      <PrefixWrapper>{prefix && prefix()}</PrefixWrapper>
      <Input {...restProps} isPrefix={!!prefix} isSuffix={!!suffix} error={error} />
      <SuffixWrapper>{suffix && suffix()}</SuffixWrapper>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
`;

const Input = styled(UITextInput)<InputProps>`
  background: #fff;

  ${({ isPrefix }) => (isPrefix ? 'padding: 10px 20px 10px 42px' : null)};
  ${({ isSuffix }) => (isSuffix ? 'padding: 10px 42px 10px 20px' : null)};

  ${({ error }) =>
    error &&
    `
      background: #FFF2F2;
      border: 1px solid var(--red);
      &:focus{
        border: 1px solid var(--red);
      }
    `}

  ${({ disabled }) =>
    disabled &&
    `
      background: #F9F9F9;
      color: var(--gray);
      border: 1px solid transparent;
      cursor: not-allowed;
      &:focus{
        border: 1px solid transparent;
      }
    `}
`;

const PrefixWrapper = styled.div`
  height: 46px;
  width: 46px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

const SuffixWrapper = styled(PrefixWrapper)`
  right: 0;
  top: 0;
`;

export default PresuffixInput;
