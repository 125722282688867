import { Field } from 'components/FinalForm';
import { FieldInput, FieldSuggestSingle } from 'components/FormFields';
import { UIButton } from 'components/UI';
import UIOpenModal from 'components/UI/UIOpenModal';
import { SuggestCompany } from 'interfaces/companies';
import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import styled from 'styled-components';
import { composeValidators } from 'utils';
import { email, required } from 'utils/validation';

interface Props {
  onClose: () => void;
  onSubmit: (values: any) => void;
  isOpen: boolean;
  handleGetCompanies: (val: string) => void;
  companiesSearchList: SuggestCompany[];
}

const AddOwnContactPop = ({
  isOpen,
  onClose,
  onSubmit,
  companiesSearchList,
  handleGetCompanies,
}: Props) => {
  const initialValues = {};
  return (
    <UIOpenModal
      onClose={onClose}
      propsBodyCss={{ padding: '28px 40px 40px' }}
      style={{
        width: '100%',
        maxWidth: '844px',
        top: '100px',
      }}
      overlayStyle={{
        overflow: 'auto',
      }}
      isOpen={isOpen}
      header={<ModalTitle>Add Contact</ModalTitle>}
    >
      <Form
        initialValues={initialValues}
        keepDirtyOnReinitialize
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          submitting,
          hasValidationErrors,
          form,
        }: FormRenderProps<any>) => {
          return (
            <form>
              <Field
                name="company"
                label="Company"
                component={FieldSuggestSingle}
                placeholder="Select Company"
                handleGetSuggestions={handleGetCompanies}
                list={companiesSearchList}
                validate={required}
              />
              <OnChange name="company">
                {(value, previous) => {
                  if (value !== previous) form.change('companyId', value?.id);
                }}
              </OnChange>
              <Row>
                <Field
                  name="firstName"
                  component={FieldInput}
                  placeholder="Enter First Name"
                  validate={required}
                  label="First Name"
                />

                <Field
                  name="lastName"
                  component={FieldInput}
                  placeholder="Enter Last Name"
                  validate={required}
                  label="Last Name"
                />
              </Row>
              <Row>
                <Field
                  name="email"
                  component={FieldInput}
                  placeholder="Email"
                  validate={composeValidators<string>(required, email)}
                  label="Email"
                />

                <Field
                  name="jobTitle"
                  component={FieldInput}
                  placeholder="Job Title"
                  label="Job Title"
                />
              </Row>
              <BtnGroup>
                <UIButton
                  modifiers="secondary"
                  title="Cancel"
                  handleClick={onClose}
                  type="button"
                />
                <UIButton
                  modifiers="primary"
                  title="Add"
                  handleClick={handleSubmit}
                  type="submit"
                  disabled={submitting || hasValidationErrors}
                  isLoading={submitting}
                />
              </BtnGroup>
            </form>
          );
        }}
      />
    </UIOpenModal>
  );
};

const ModalTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  padding: 20px 0 16px;
`;
const BtnGroup = styled.div`
  display: flex;
  width: 517px;
  justify-content: space-between;

  & button:first-child {
    margin-right: 9px;
  }

  & button {
    flex: 1;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    width: calc(50% - 8px);
  }
`;

export default AddOwnContactPop;
